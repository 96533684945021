import { Button, LinkButton } from '@grafana/ui';
import TooltipConfirmationComponent from 'features/Assertions/components/TooltipConfirmation/TooltipConfirmation.component';
import useDeleteGrafanaSlo from 'features/Slo/hooks/useDeleteGrafanaSlo';
import { ROUTES } from 'global-constants';
import React from 'react';
import { prefixRoute } from 'utils/utils.routing';

interface SloItemActionsProps {
  uuid: string;
}

const SloItemActions: React.FC<SloItemActionsProps> = ({ uuid }) => {
  const { mutate: deleteGrafanaSlo, isLoading: isDeletingGrafanaSlo } = useDeleteGrafanaSlo();

  return (
    <div className="absolute right-6 top-6 flex items-center gap-2">
      <LinkButton
        icon="pen"
        size="sm"
        variant="secondary"
        href={prefixRoute(ROUTES.SLO_EDIT.replace(':id', uuid))}
      ></LinkButton>
      <TooltipConfirmationComponent action={() => deleteGrafanaSlo(uuid)}>
        <Button
          icon={isDeletingGrafanaSlo ? 'spinner' : 'trash-alt'}
          size="sm"
          variant="secondary"
          disabled={isDeletingGrafanaSlo}
        >
          {isDeletingGrafanaSlo ? 'Deleting...' : ''}
        </Button>
      </TooltipConfirmationComponent>
    </div>
  );
};

export default SloItemActions;
