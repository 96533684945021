/**
 *
 * AssertionsSummaryView
 *
 */

import React, { memo, FunctionComponent, useRef, useMemo } from 'react';
import AssertionsSummaryItem from '../AssertionsSummaryItem/AssertionsSummaryItem.component';
import TableHeader from '../../../../components/TableHeader/TableHeader.component';
import TimelineSerifsComponent from 'components/TimelineSerifs/TimelineSerifs.component';
import TimelineTicksComponent from 'components/TimelineTicks/TimelineTicks.component';
import { TIMELINE_STEPS_COUNT } from '../../constants';
import CoordinateLinesComponent from '../CoordinateLines/CoordinateLines.component';
import useRangeSelection from './hooks/useRangeSelection';
import RangePickerLineComponent from '../../../../components/RangePickerLine/RangePickerLine.component';
import useLinesPosition from './hooks/useLinesPosition';
import { getTimeFromCoordinates } from 'helpers/Time.helper';
import useSelectAssertionsSummary from './hooks/useSelectAssertionsSummary';
import useDebounceValue from 'hooks/useDebounceValue';
import useSelectedTime from './hooks/useSelectedTime';
import AssertionsSummaryGraphComponent from '../AssertionsSummaryGraph/AssertionsSummaryGraph.component';
import AssertionsSummaryResizeHandleComponent from '../AssertionsSummaryResizeHandle/AssertionsSummaryResizeHandle.component';
import useSummaryLeftPanel from '../../hooks/useSummaryLeftPanel';
import { useIntl } from 'react-intl';
import messages from './messages';
import { AXIS_PADDING_RIGHT } from 'app/constants';
import AssertionsSortByComponent from '../AssertionsSortBy/AssertionsSortBy.component';
import { AssertionSummaryResProcessed } from 'asserts-types';
import { stringToDate } from 'helpers/Date.helper';

interface IProps {
  start: number | string;
  end: number | string;
  changeDateRange: (newStart: number | string, newEnd: number | string) => void;
  data: AssertionSummaryResProcessed | undefined;
}

const AssertionsSummaryView: FunctionComponent<IProps> = ({
  start,
  end,
  changeDateRange,
  data,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const timelineRef = useRef<HTMLDivElement>(null);
  const { formatMessage } = useIntl();

  const { width: leftPanelWidth } = useSummaryLeftPanel();

  const leftOffset = leftPanelWidth;

  const startForSummary = data?.timeWindow.start || stringToDate(start).valueOf();
  const endForSummary = data?.timeWindow.end || stringToDate(end).valueOf();

  const { selectingRange, selectedStartX, selectedEndX } = useRangeSelection({
    start: startForSummary,
    end: endForSummary,
    containerRef,
    leftOffset,
    rightOffset: AXIS_PADDING_RIGHT,
    onChange: changeDateRange,
  });

  const { mouseX, hovered } = useLinesPosition({
    containerRef,
    offsetLeft: leftPanelWidth,
  });

  const currentDate = useMemo(
    () =>
      getTimeFromCoordinates({
        mouseX,
        tapeEl: timelineRef.current,
        start: startForSummary,
        end: endForSummary,
      }),
    [endForSummary, mouseX, startForSummary],
  );

  const {
    selectCurrentTime,
    selectedTime,
    selectedMouseX,
    resetSelectedTime,
  } = useSelectedTime({
    currentDate,
    mouseX,
  });

  const assertionsSummaryData = useSelectAssertionsSummary({
    currentDate:
      hovered || selectedTime
        ? selectedTime?.valueOf() || currentDate.valueOf()
        : undefined,
    data,
  });

  const assertionsSummaryDataDebounced = useDebounceValue(
    assertionsSummaryData,
    100,
  );

  return (
    <>
      {!selectingRange && (
        <CoordinateLinesComponent
          top={timelineRef.current?.getBoundingClientRect().top || 0}
          left={mouseX}
          show={hovered}
          selectedMouseX={selectedMouseX}
          resetSelectedTime={resetSelectedTime}
          currentDate={selectedTime || currentDate}
        />
      )}
      {selectingRange && containerRef.current && (
        <RangePickerLineComponent
          start={startForSummary}
          end={endForSummary}
          mouseX={selectedEndX}
          selectedStartX={selectedStartX}
          tapeEl={containerRef.current}
          leftOffset={leftOffset}
          rightOffset={AXIS_PADDING_RIGHT}
        />
      )}
      <AssertionsSummaryGraphComponent
        start={data?.timeWindow.start || start}
        end={data?.timeWindow.end || end}
        assertionsSummary={assertionsSummaryDataDebounced}
        currentDate={selectedTime || currentDate}
      />
      <div
        className="overflow-overlay w-full"
        ref={containerRef}
        style={{ userSelect: selectingRange ? 'none' : 'auto' }}
        onClick={() => (hovered ? selectCurrentTime() : null)}
      >
        <p
          className="text-xs text-secondary absolute z-[99] -top-[20px] -translate-x-1/2"
          style={{
            left: `calc(${leftPanelWidth / 2}px + 50%)`,
          }}
        >
          {formatMessage(messages.hint)}
        </p>
        <div
          className="grid sticky top-0 z-[10]"
          style={{
            gridTemplateColumns: `${leftPanelWidth}px auto ${AXIS_PADDING_RIGHT}px`,
          }}
        >
          <TableHeader className="pl-8">
            <AssertionsSortByComponent />
          </TableHeader>
          <div ref={timelineRef}>
            <TableHeader>
              <TimelineSerifsComponent start={startForSummary} end={endForSummary} />
            </TableHeader>
          </div>
          <div className="bg-panel" />
        </div>
        <div
          className="absolute inset-y-0 z-[-1]"
          style={{
            right: AXIS_PADDING_RIGHT,
            left: leftPanelWidth,
          }}
        >
          <TimelineTicksComponent count={TIMELINE_STEPS_COUNT} />
        </div>
        <AssertionsSummaryResizeHandleComponent />
        <div>
          {assertionsSummaryDataDebounced.map((item) => (
            <AssertionsSummaryItem
              key={item.id}
              item={item}
              start={startForSummary}
              end={endForSummary}
              timeStepInterval={data?.timeStepIntervalMs}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default memo(AssertionsSummaryView);
