import { unionBy } from 'lodash';
import { useMemo } from "react";
import { MONITORING_COLUMNS } from "../constants";
import { useAppSelector } from 'app/store';
import { Entity, GraphCustomData, KpiGroup } from 'asserts-types';
import { deleteSystemEntityProperties } from 'helpers/Entity.helper';

interface IProps {
  entityList: Entity[];
  activeEntityConnections: Entity[];
  graphData: GraphCustomData;
}
export default function useKpiSelector({
  entityList,
  activeEntityConnections,
  graphData,
}: IProps) {
  const kpiColumns = useAppSelector((state) => {
    let columns: Record<string, string[]> = {};

    state.entities.kpiSettings.kpisByEntityType.forEach((item) => {
      item.kpis.forEach((propertyName) => {
        if (!columns[propertyName]) {
          columns[propertyName] = [item.type];
        } else {
          columns[propertyName] = unionBy(columns[propertyName], [item.type]);
        }
      });
    });
    return columns;
  });

  const selectedColumns = useAppSelector((state) =>
    state.entities.selectedKpi.concat(state.entities.entityListColumns),
  );

  const activeEntity = useAppSelector((state) => state.entities.activeEntity);

  const columnOptions = useMemo(() => {
    let columns: Record<string, string[]> = {};

    const list = activeEntity ? activeEntityConnections : entityList;

    list.forEach((item) => {
      const propertiesToShow = deleteSystemEntityProperties(item.properties);

      Object.keys(propertiesToShow).forEach((propertyName) => {
        if (!columns[propertyName]) {
          columns[propertyName] = [item.type];
        } else {
          columns[propertyName] = unionBy(columns[propertyName], [item.type]);
        }
      });
    });
    return columns;
  }, [activeEntity, activeEntityConnections, entityList]);

  const entityListColumns = useMemo(() => {
    return selectedColumns.filter(
      (columnName) =>
        Object.assign({}, columnOptions, kpiColumns)[columnName] ||
        MONITORING_COLUMNS.includes(columnName),
    );
  }, [columnOptions, kpiColumns, selectedColumns]);

  const kpiListColumns = useMemo(
    () => entityListColumns.filter((columnName) => kpiColumns[columnName]),
    [entityListColumns, kpiColumns],
  );

  const listKpis = useAppSelector((state) => {
    let result: KpiGroup['kpis'] = [];
    state.entities.kpiSettings.kpiGroups.forEach((group) => {
      result = unionBy(result, group?.kpis, 'name');
    });
    return result;
  });

  const filteredKpis = useAppSelector((state) => {
    let result: string[] = [];
    graphData.nodes.forEach((node) => {
      const kpisToAdd = state.entities.kpiSettings.kpisByEntityType.find(
        (item) => item.type === node.entityType,
      )?.kpis;
      if (kpisToAdd) {
        result = unionBy(result, kpisToAdd);
      }
    });
    return result;
  });

  const numericProperties = useMemo(() => {
    const result: string[] = [];

    graphData.nodes.forEach((item) => {
      Object.keys(item.properties).forEach((key) => {
        if (
          typeof item.properties[key] === 'number' &&
          !result.includes(key) &&
          key.toLowerCase() !== 'discovered' &&
          key.toLowerCase() !== 'updated'
        ) {
          result.push(key);
        }
      });
    });
    return result;
  }, [graphData.nodes]);

  const bubbleViewPanels = useAppSelector((state) => {
    const allProps = filteredKpis.concat(numericProperties);
    return state.entities.selectedKpi
      .concat(state.entities.bubbleViewPanels)
      .filter((name) => allProps.includes(name));
  });

  return {
    entityListColumns,
    columnOptions,
    kpiListColumns,
    listKpis,
    filteredKpis,
    numericProperties,
    bubbleViewPanels,
  };
}
