/**
 *
 * RulesInfoSection
 *
 */

import React, { memo, FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import useAssertionDetails from 'hooks/useAssertionDetails';
import CodeBlockComponent from 'components/CodeBlock/CodeBlock.component';
import { LoadingPlaceholder } from '@grafana/ui';

interface IProps {
  assertionName?: string;
  labels?: Record<string, string>;
  onDataLoaded?: () => void;
}

const RulesInfoSection: FunctionComponent<IProps> = ({
  assertionName,
  labels,
  onDataLoaded,
}) => {
  const intl = useIntl();

  const { data, isFetching } = useAssertionDetails({
    assertionName,
    labels,
    onSuccess: onDataLoaded,
  });

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {isFetching ? (
        <LoadingPlaceholder text="Loading..." className="py-8 m-0 text-center" />
      ) : (
        <div>
          <p className="text-lg divider-b pb-1 mb-3">Rules</p>
          <div className="flex flex-col gap-4">
            <div className="space-y-1">
              <p className="text-sm text-secondary font-bold">{intl.formatMessage(messages.expression)}</p>
              <CodeBlockComponent>{data?.expression}</CodeBlockComponent>
            </div>
            {data?.interval && (
              <div className="space-y-1">
                <p className="text-sm text-secondary font-bold block">{intl.formatMessage(messages.for)}</p>
                <CodeBlockComponent>{data?.interval}</CodeBlockComponent>
              </div>
            )}
            {!!data?.labels && !!Object.keys(data?.labels).length && (
              <div className="space-y-1">
                <p className="text-sm text-secondary font-bold block">{intl.formatMessage(messages.labels)}</p>
                {Object.keys(data?.labels).map((key, index) => (
                  <p key={index}>
                    <span className="text-secondary">{key}=</span>
                    <span className="font-bold">{data.labels[key]}</span>
                  </p>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(RulesInfoSection);
