/**
 *
 * ThresholdChart
 *
 */

import React, { memo, FunctionComponent, useMemo } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import LineRangeChart from 'components/LineRangeChart/LineRangeChart';
import { SloType, SloObjective } from 'asserts-types';
import useTargetThresholds from '../../hooks/useTargetThresholds';
import useGrafanaQueryRange from 'hooks/useGrafanaQueryRange';
import useAppTimeRange from 'hooks/useAppTimeRange';
import GlobalDateRangePicker from 'features/App/components/GlobalDateRangePicker/GlobalDateRangePicker';

interface IProps {
  query: string;
  sloType: SloType;
  objectives: SloObjective[];
  kind: SloType;
}

const PreviewSloChart: FunctionComponent<IProps> = ({
  query,
  sloType,
  objectives,
  kind,
}) => {
  const intl = useIntl();

  const { timeRange } = useAppTimeRange();

  const { data: chartData, isLoading: isChartLoading } = useGrafanaQueryRange({
    start: typeof timeRange.raw.from === 'string' ? timeRange.raw.from : timeRange.from.valueOf(),
    end: typeof timeRange.raw.to === 'string' ? timeRange.raw.to : timeRange.to.valueOf(),
    query,
    enabled: true,
  });

  const isRequest = sloType === SloType.Request;

  const processedChartData = isRequest
    ? chartData?.metrics.map((m) => ({ ...m, name: 'Availability' }))
    : chartData?.metrics;

  const thresholds = useTargetThresholds({
    chartValues: chartData?.metrics[0]?.values,
    objectives,
    kind,
  });

  const minY = useMemo(() => {
    let values: number[] = [];
    if (isRequest) {
      chartData?.metrics.forEach(metric => {
        metric.values.forEach(point => {
          if (point.value === 0 || point.value) {
            values.push(point.value);
          }
        });
      });

      return Math.min(...(values.length ? values : [1])) - 1;
    }
    return undefined;
  }, [chartData?.metrics, isRequest]);

  return (
    <>
      <p className="text-center font-bold">{intl.formatMessage(messages[kind])}</p>
      <div className="flex justify-end">
        <GlobalDateRangePicker />
      </div>
      {(!chartData?.metrics.length || isChartLoading) && (
        <p className="text-center flex items-center justify-center mt-4 h-[200px] text-secondary text-xs">
          {isChartLoading && intl.formatMessage(messages.loading)}
          {!chartData?.metrics.length && !isChartLoading && intl.formatMessage(messages.noData)}
        </p>
      )}

      {!!chartData?.metrics.length && (
        <div className="h-[200px] w-full relative mt-4">
          <LineRangeChart
            data={processedChartData || []}
            padding={[10, 50, 30, 0]}
            positionY="right"
            minX={chartData.metrics[0]?.values[0]?.time}
            maxX={timeRange.to.valueOf()}
            timeStepInterval={chartData.timeStepInterval}
            type="line"
            showLegend
            nullAsZero={false}
            thresholds={thresholds}
            showCrosshairs
            maxY={isRequest ? 100 : undefined}
            minY={minY}
          />
        </div>
      )}
    </>
  );
};

export default memo(PreviewSloChart);
