/**
 *
 * EntityAdvancedSearchName
 *
 */

import React, { memo, FunctionComponent, useRef, useEffect } from 'react';
import EditOutlinedIcon from 'assets/material-icons/edit_FILL0_wght400_GRAD0_opsz24.svg';
import { useDispatch } from 'react-redux';
import useAdvancedSearchModified from '../../hooks/useAdvancedSearchModified';
import { useAppSelector } from 'app/store';
import { setSearchName } from 'features/Entities/Entities.slice';
import DotBadge from 'components/DotBadge/DotBadge.component';
import { IconButton } from 'components/IconButton/IconButton.component';

interface IProps {}

const EntityAdvancedSearchName: FunctionComponent<IProps> = () => {
  // const { formatMessage } = useIntl();

  const dispatch = useDispatch();

  const searchDefinition = useAppSelector(
    (state) => state.entities.searchDefinition,
  );

  const searchName = useAppSelector(
    (state) => state.entities.searchName,
  );

  const inputRef = useRef<HTMLInputElement>(null);

  const handleEditClick = () => {
    inputRef.current?.focus();
  };

  useEffect(() => {
    if (
      searchDefinition?.boundDescription &&
      searchDefinition?.filterCriteria?.length
    ) {
      dispatch(setSearchName(searchDefinition?.boundDescription));
    }
  }, [
    searchDefinition?.boundDescription,
    dispatch,
    searchDefinition?.filterCriteria?.length,
  ]);

  const { isModified } = useAdvancedSearchModified();

  return (
    <div className="flex items-center gap-1 p-8">
      <div className="relative">
        <DotBadge show={isModified}>
          <span className={`text-lg font-bold pointer-events-none block max-w-[300px] h-[30px] invisible`}>
            {searchName ? <>{searchName}&nbsp;</> : 'Add search name here'}
          </span>
        </DotBadge>

        <input
          type="text"
          value={searchName}
          placeholder="Add search name here"
          className="outline-none text-lg font-bold absolute inset-0 bg-transparent"
          onChange={(e) => dispatch(setSearchName(e.currentTarget.value))}
          ref={inputRef}
        />
      </div>
      <IconButton onClick={handleEditClick}>
        <EditOutlinedIcon />
      </IconButton>
    </div>
  );
}

export default memo(EntityAdvancedSearchName);
