import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import errorMessages from '../../../app/errorMessages';
import { setSearchDefinition } from '../Entities.slice';
import { useAppSelector } from 'app/store';
import { filterFilterCriteria } from 'helpers/FilterCriteria.helper';
import useDefinitionAutocomplete from 'hooks/useDefinitionAutocomplete';
import { ProcessedEntityResponse } from 'asserts-types';
import { useQuery } from '@tanstack/react-query';
import { fetchEntities, searchEntities } from 'services/Entity.service';
import SnackbarHelper from 'helpers/Snackbar.helper';
import { stringToDate } from 'helpers/Date.helper';
import { setTimeRange } from 'features/App/App.slice';

const USE_ENTITIES_QUERY_KEY = 'entities-list';

export default function useEntities() {
  const dispatch = useDispatch();

  const selectedEnv = useAppSelector((state) => state.app.selectedEnv);
  const selectedSite = useAppSelector((state) => state.app.selectedSite);

  const start = useAppSelector((state) => state.app.start);
  const end = useAppSelector((state) => state.app.end);

  const searchDefinition = useAppSelector(
    (state) => state.entities.searchDefinition,
  );

  const typeFilter = useAppSelector((state) => state.entities.typeFilter);
  const search = useAppSelector((state) => state.entities.search);
  const nameSearchQuery = useAppSelector(
    (state) => state.entities.nameSearchQuery,
  );

  const searchObject = useAppSelector((state) => state.entities.searchObject);

  // advanced search
  const filterCriteria = useMemo(
    () => filterFilterCriteria(searchObject.filterCriteria),
    [searchObject.filterCriteria],
  );

  const {
    data: definitions,
    isFetching: isFetchingDefinition,
    isStale: isDefinitionStale,
  } = useDefinitionAutocomplete({
    query: search || '',
    max: 1,
    enabled: Boolean(search),
    onSuccess: (data) => {
      if (!data.length) {
        SnackbarHelper.error(errorMessages.noDefinitionFound);
      }
    },
  });

  const definitionLoadedFromSearch = useMemo(
    () => (search && !isDefinitionStale ? definitions?.[0] : undefined),
    [definitions, isDefinitionStale, search],
  );
  // setting definitionLoadedFromSearch to store
  useEffect(() => {
    if (definitionLoadedFromSearch) {
      dispatch(setSearchDefinition(definitionLoadedFromSearch));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [definitionLoadedFromSearch]);

  const select = useCallback(
    (data: ProcessedEntityResponse): ProcessedEntityResponse => {
      const entities = data.data.entities.filter(
        (item) =>
          typeFilter.indexOf(item.type) === -1 &&
          item.name?.toLowerCase().indexOf(nameSearchQuery?.toLowerCase()) !==
            -1,
      );

      return {
        ...data,
        data: { ...data.data, entities },
      };
    },
    [nameSearchQuery, typeFilter],
  );

  const query = useQuery(
    [
      USE_ENTITIES_QUERY_KEY,
      start,
      end,
      searchDefinition,
      filterCriteria,
      selectedEnv,
      selectedSite,
    ],

    async () => {
      if (filterCriteria?.length) {
        const data = await searchEntities(filterCriteria, stringToDate(start).valueOf(), stringToDate(end).valueOf());

        return data;
      }

      if (searchDefinition && !searchDefinition.filterCriteria) {
        const data = await fetchEntities(
          searchDefinition,
          stringToDate(start).valueOf(),
          stringToDate(end).valueOf(),
        );
        return data;
      }

      return Promise.reject();
    },
    {
      select,
      enabled:
        Boolean(searchDefinition || filterCriteria.length) &&
        !isFetchingDefinition,
      keepPreviousData: true,
      cacheTime: Infinity,
      // staleTime: 0,
      staleTime: typeof start === 'string' ? 0 : Infinity,
    },
  );

  // if time criteria is adjusted by BE we need to change timerange
  useEffect(() => {
    if (query.data?.adjustedTimeCriteria) {
      dispatch(setTimeRange(query.data.timeCriteria));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.data?.adjustedTimeCriteria]);

  return { ...query, isFetching: query.isFetching || isFetchingDefinition };
}
