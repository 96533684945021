import React, {
  FunctionComponent,
  useEffect,
  useCallback,
  useMemo,
  // useMemo,
} from 'react';

import { Graph, Item } from '@antv/g6';

import GraphinGraph from '../../../../components/GraphinGraph/GraphinGraph.component';
import { setGraphTypeScopeFilter, toggleGraphTypeScopeFilter, setActiveEntity } from '../../Entities.slice';
import { useIntl } from 'react-intl';
import messages from './messages';
import useWindowSize from '../../../../hooks/useWindowSize';
import { connect, ConnectedProps } from 'react-redux';

import { Entity, GraphCustomData, GraphCustomNode } from 'asserts-types';
import { CustomContextMenuItem } from 'components/GraphinGraph/components/GraphContextMenu/GraphContextMenu.component';
import useGraphNodeRelations from 'components/GraphNodeRelations/useGraphNodeRelations';
import { addEntityToWorkbench } from 'features/Assertions/Assertions.slice';
import { convertToEntity } from 'helpers/Graph.helper';
import GraphNodeRelationsComponent from 'components/GraphNodeRelations/GraphNodeRelations.component';
import CountGroupedEntitiesComponent from 'components/CountGroupedEntities/CountGroupedEntities.component';
import { DEFAULT_FORCE_LAYOUT_OPTIONS } from 'global-constants';
import { setActiveEntityDetails } from 'features/App/App.slice';

const connector = connect(
  (state: RootState) => ({
    activeNode: state.entities.activeNode,
    graphPadding: state.entities.graphPadding,
    graphTypeScopeFilter: state.entities.graphTypeScopeFilter,
  }),
  {
    setActiveEntityDetails,
    addEntityToWorkbench,
    setGraphTypeScopeFilter,
    toggleGraphTypeScopeFilter,
    setActiveEntity,
  }
);
interface IProps {
  graphData: GraphCustomData;
  isFetching: boolean;
  lastUpdateTime: number | undefined;
  setGraphRef: (ref?: Graph) => void;
  graphRef: Graph | undefined;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

const EntitiesGraph: FunctionComponent<IProps & PropsFromRedux> = ({
  activeNode,
  graphRef,
  setActiveEntityDetails,
  addEntityToWorkbench,
  graphPadding,
  graphTypeScopeFilter,
  setGraphTypeScopeFilter,
  toggleGraphTypeScopeFilter,
  lastUpdateTime,
  setActiveEntity,
  graphData,
  isFetching,
  setGraphRef,
}) => {
  const intl = useIntl();
  const { width, height } = useWindowSize();

  useEffect(() => {
    if (!activeNode) {
      graphRef?.fitCenter();
    }
    // eslint-disable-next-line
  }, [width, height]);

  useEffect(() => {
    if (graphRef) {
      const currentZoom = graphRef?.getZoom();
      graphRef?.fitView(graphPadding);
      graphRef?.zoomTo(currentZoom || 1);
      if (activeNode) {
        const activeNodeItem = graphRef.findById(activeNode.id);
        if (activeNodeItem) {
          graphRef.focusItem(activeNodeItem);
        }
      }
    }
    // eslint-disable-next-line
  }, [graphPadding]);

  useEffect(() => {
    return () => {
      setGraphRef();
    };
    // eslint-disable-next-line
  }, []);

  const {
    isEditRelationsMode,
    enterEditMode,
    exitEditMode,
    graphDataProcessed,
    sourceNode,
    destinationNode,
    swapNodes,
    changeRelationType,
    relationType,
    mutateAsync: saveRelation,
    isLoading: isSavingRelation,
  } = useGraphNodeRelations({ graphData, graph: graphRef });

  const showKpi = useCallback(
    (item: Item) => {
      setActiveEntityDetails({
        type: item.get('model').entityType,
        name: item.get('model').label,
        scope: item.get('model').scope,
      });
    },
    [setActiveEntityDetails]
  );

  const onRef = useCallback((c) => setGraphRef(c), [setGraphRef]);

  const handleAddToWorkbenchClick = useCallback(
    (item: Item) => {
      const entity = convertToEntity(item.get('model') as GraphCustomNode);
      if (entity) {
        addEntityToWorkbench(entity);
      }
    },
    [addEntityToWorkbench]
  );

  const contextMenuOptions: CustomContextMenuItem[] = useMemo(() => {
    const options = [
      {
        key: 'menu-item-2',
        title: intl.formatMessage(messages.showKPI),
        onClick: showKpi,
      },
      {
        key: 'menu-item-3',
        title: intl.formatMessage(messages.addToWorkbench),
        onClick: handleAddToWorkbenchClick,
      },
    ];

    return isEditRelationsMode ? [] : options;
    //eslint-disable-next-line
  }, [intl, handleAddToWorkbenchClick, showKpi, isEditRelationsMode]);

  const handleConnectedItemClick = (entity: Entity | undefined) => {
    if (entity && entity.activeConnectedEntityType) {
      setActiveEntity({ entity });
    }
  };

  if (isFetching) {
    return null;
  }

  return (
    <div className="flex relative grow h-full divider-r divider-b" data-tour-target="entities-graph">
      <GraphinGraph
        onRef={onRef}
        data={graphDataProcessed}
        layout={DEFAULT_FORCE_LAYOUT_OPTIONS}
        contextMenuOptions={contextMenuOptions}
        showConnectedItemsInContextMenu
        disableContextMenu={isEditRelationsMode}
        lastUpdateTime={lastUpdateTime}
        onConnectedItemClick={handleConnectedItemClick}
      />
      {!isEditRelationsMode && (
        <CountGroupedEntitiesComponent
          graphData={graphData}
          activeFilter={graphTypeScopeFilter}
          onClick={toggleGraphTypeScopeFilter}
          setFilter={setGraphTypeScopeFilter}
          maxVisibleCount={10}
        />
      )}
      <GraphNodeRelationsComponent
        isEditRelationsMode={isEditRelationsMode}
        onOpen={enterEditMode}
        onClose={exitEditMode}
        onSave={saveRelation}
        isSaving={isSavingRelation}
        sourceNode={sourceNode}
        destinationNode={destinationNode}
        onSwapNodes={swapNodes}
        onChangeRelationType={changeRelationType}
        relationType={relationType}
      />
    </div>
  );
};

export default connector(EntitiesGraph);
