import { Entity } from 'asserts-types';
import { config, useReturnToPrevious } from '@grafana/runtime';
import React, { HTMLProps, ReactElement, cloneElement, forwardRef, isValidElement } from 'react';
import { ButtonProps } from '@grafana/ui';
import { stringToDate } from 'helpers/Date.helper';
import useKubernetes from './hooks/useKubernetes';

export interface OpenInKubernetesTriggerProps {
  children: ReactElement<ButtonProps>;
  entity: Pick<Entity, 'scope' | 'type' | 'properties' | 'name'>;
  start: string | number;
  end: string | number;
  metricsDataSourceUID: string;
  logsDataSourceUID: string;
}

export const OpenInKubernetesTrigger = forwardRef<
  HTMLElement,
  Omit<HTMLProps<HTMLElement>, 'start'> & OpenInKubernetesTriggerProps
>(function PopoverTrigger({ children, entity, start, end, metricsDataSourceUID, logsDataSourceUID, ...props }, ref) {
  const [promName] =
    Object.entries(config.datasources).find(([key, value]) => value.uid === metricsDataSourceUID) || [];
  const [lokiName] = Object.entries(config.datasources).find(([key, value]) => value.uid === logsDataSourceUID) || [];

  const { navigationUrlPart } = useKubernetes({
    entity,
    start,
    end,
  });

  const setReturnToPrevious = useReturnToPrevious();

  const from = typeof start === 'string' ? start : stringToDate(start).toISOString();
  const to = typeof end === 'string' ? end : stringToDate(end).toISOString();

  const href = `/a/grafana-k8s-app/navigation/${navigationUrlPart}?promName=${promName}&lokiName=${lokiName}&from=${from}&to=${to}`;

  if (entity.type === 'Service' && !entity.properties.workload_type) {
    return null;
  }

  const onClick = () => {
    setReturnToPrevious('Asserts');
  }

  if (isValidElement(children) && navigationUrlPart) {
    return cloneElement(children, {
      ref,
      href,
      onClick,
      ...props,
    });
  }

  return null;
});
