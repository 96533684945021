import { TimeRange, dateTimeParse, toUtc } from '@grafana/data';
import { useAppSelector } from 'app/store';
import { setTimeRange } from 'features/App/App.slice';
import { stringToDate } from 'helpers/Date.helper';
import { useDispatch } from 'react-redux';

export default function useAppTimeRange() {
  const start = useAppSelector((state) => state.app.start);
  const end = useAppSelector((state) => state.app.end);
  const dispatch = useDispatch();

  let from = dateTimeParse(start);
  let to = dateTimeParse(end);

  let timeRange: TimeRange = {
    from,
    to,
    raw: {
      from: typeof start === 'string' ? start : from,
      to: typeof end === 'string' ? end : to,
    },
  };

  // in case dateTimeParse can't parse string trying Asserts method stringToDate
  if (!from.isValid() || !to.isValid()) {
    from = dateTimeParse(stringToDate(start).valueOf());
    to = dateTimeParse(stringToDate(end).valueOf());

    timeRange = {
      from,
      to,
      raw: {
        from,
        to,
      },
    };
  }

  const onChange = (timeRange: TimeRange) => {
    dispatch(
      setTimeRange({
        start: typeof timeRange.raw.from === 'string' ? timeRange.raw.from : timeRange.raw.from.valueOf(),
        end: typeof timeRange.raw.to === 'string' ? timeRange.raw.to : timeRange.raw.to.valueOf(),
      })
    );
  };

  const onZoom = () => {
    const factor = 2;
    const timespan = to.valueOf() - from.valueOf();
    const center = to.valueOf() - timespan / 2;

    let end = center + (timespan * factor) / 2;
    const start = center - (timespan * factor) / 2;
    end = end > Date.now() ? Date.now() : end;
    dispatch(
      setTimeRange({
        start,
        end,
      })
    );
  };

  const onMove = (direction: 'back' | 'forward') => {
    const range = {
      start: toUtc(from.valueOf()),
      end: toUtc(to.valueOf()),
    };

    const timespan = (range.end.valueOf() - range.start.valueOf()) / 2;
    let end: number, start: number;

    if (direction === 'back') {
      end = range.end.valueOf() - timespan;
      start = range.start.valueOf() - timespan;
    } else if (direction === 'forward') {
      end = range.end.valueOf() + timespan;
      start = range.start.valueOf() + timespan;
      if (end > Date.now() && range.end.valueOf() < Date.now()) {
        end = Date.now();
        start = range.start.valueOf();
      }
    } else {
      end = range.end.valueOf();
      start = range.start.valueOf();
    }

    dispatch(
      setTimeRange({
        start,
        end,
      })
    );
  };

  return { timeRange, onChange, onZoom, onMove };
}
