/**
 *
 * GraphNodeRcaButtons
 *
 */

import React, { memo, FunctionComponent } from 'react';
import WbOpenIcon from 'icons/WbOpenIcon';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearWorkbench, setWorkbenchEntities } from 'features/Assertions/Assertions.slice';
import { Entity } from 'asserts-types';
import { prefixRoute } from 'utils/utils.routing';
import { ROUTES, TRACKING_FEATURES } from 'global-constants';
import { Button, useStyles2 } from '@grafana/ui';
import TrackingHelper from 'helpers/Tracking.helper';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import { setQueryParamsWereRead } from 'features/App/App.slice';

interface IProps {
  entityInfo: Pick<Entity, 'name' | 'type' | 'scope'>;
  trackingFeature?: TRACKING_FEATURES;
  source?: string;
}

const GraphNodeRcaButtons: FunctionComponent<IProps> = ({ entityInfo, trackingFeature, source }) => {
  const dispatch = useDispatch();
  const styles = useStyles2(getStyles);
  const navigate = useNavigate();

  return (
    <Button
      fullWidth
      className={styles.button}
      fill="outline"
      onClick={() => {
        dispatch(clearWorkbench());
        dispatch(setQueryParamsWereRead(true)); // we need to switch the mode so asserts app will use redux store as a source of data not the query params
        dispatch(
          setWorkbenchEntities([
            {
              name: entityInfo.name,
              type: entityInfo.type,
              scope: entityInfo.scope,
            },
          ])
        );
        TrackingHelper.trackNavigateToWorkbench(
          trackingFeature ?? TRACKING_FEATURES.TROUBLESHOOT_IN_WORKBENCH_BTN,
          source
        );
        navigate(prefixRoute(ROUTES.RCA_WORKBENCH), { state: { timestamp: Date.now() } });
      }}
    >
      <WbOpenIcon />
      <span className={styles.margin}>Troubleshoot in Workbench</span>
    </Button>
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    button: css({
      justifyContent: 'center',
      alignItems: 'center',
    }),
    margin: css({
      marginLeft: theme.spacing(1),
    }),
  };
}

export default memo(GraphNodeRcaButtons);
