import { combineReducers, configureStore, createSlice } from '@reduxjs/toolkit';

import { TypedUseSelectorHook, useSelector } from 'react-redux';
import appSlice from 'features/App/App.slice';
import entitiesSlice from 'features/Entities/Entities.slice';
import assertionsSlice from 'features/Assertions/Assertions.slice';
import topInsightsSlice from 'features/TopInsights/TopInsights.slice';
import manageAssertionsSlice from 'features/ManageAssertions/ManageAssertions.slice';
import incidentsSlice from 'features/Incidents/Incidents.slice';
import sloSlice from 'features/Slo/Slo.slice';
import assertionSearchSlice from 'features/AssertionSearch/AssertionSearch.slice';
import manageSlo from 'features/ManageSlo/ManageSlo.slice';
import storage from 'redux-persist/lib/storage';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  PersistConfig,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import pluginJson from 'plugin.json';
import { AssertionsContainerState, EntityContainerState, TopInsightsContainerState } from 'asserts-types';

// persist configs
const topInsightsPersistConfig: PersistConfig<TopInsightsContainerState> = {
  key: `${pluginJson.id}:topInsights`,
  storage,
  whitelist: ['topInsightsSearches', 'pinnedSearches', 'expandedSearches'],
};

const assertionsPersistConfig: PersistConfig<AssertionsContainerState> = {
  key: `${pluginJson.id}:assertions`,
  storage,
  whitelist: ['showWithAssertions', 'chartTooltip', 'hideOldAssertions', 'sort'],
};

const entitiesPersistConfig: PersistConfig<EntityContainerState> = {
  key: `${pluginJson.id}:entities`,
  storage,
  whitelist: [
    'showRelationships',
    'showNodeNames',
    'showFullNodeNameText',
    'showCallRates',
    'highlightSelected',
    'topSearches',
  ],
};

// TODO: remove this when Trace component is isolated
// https://raintank-corp.slack.com/archives/C04FRQ2S0AV/p1707819395750789
const exploreSlice = createSlice({ name: 'explore', reducers: {}, initialState: { panes: [] } });
// TODO: remove this when Trace component is isolated
const userSlice = createSlice({ name: 'user', reducers: {}, initialState: { timeZone: '' } });

const reducer = combineReducers({
  topInsights: persistReducer(topInsightsPersistConfig, topInsightsSlice),
  assertions: persistReducer(assertionsPersistConfig, assertionsSlice),
  entities: persistReducer(entitiesPersistConfig, entitiesSlice),

  app: appSlice,
  incidents: incidentsSlice,
  manageAssertions: manageAssertionsSlice,
  slo: sloSlice,
  manageSlo: manageSlo,
  assertionSearch: assertionSearchSlice,
  // TODO: remove this when Trace component is isolated
  explore: exploreSlice.reducer,
  // TODO: remove this when Trace component is isolated
  user: userSlice.reducer,
});

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export default store;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const persistor = persistStore(store);
