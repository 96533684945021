/*
 *
 * ManageSlo constants
 *
 */

import { Comparator, SelectOption, SloObjective } from 'asserts-types';

export const DEFAULT_OBJECTIVE: SloObjective = {
  ratio: 0,
  value: 0,
  name: '',
  window: {
    days: 0,
  },
};

export const rateRegex = /rate\(.{0,}\)/;

export const THRESHOLD_COMPARATOR_OPTIONS: SelectOption[] = [
  { label: 'Greater Than (>)', value: Comparator.GreaterThan },
  {
    label: 'Greater Than Or Equal To (>=)	',
    value: Comparator.GreaterThanOrEqualTo,
  },
  { label: 'Equal To (==)	', value: Comparator.EqualTo },
  { label: 'Not Equal To (!=)', value: Comparator.NotEqualTo },
  { label: 'Less Than Or Equal To (<=)', value: Comparator.LessThanOrEqualTo },
  { label: 'Less Than (<)', value: Comparator.LessThan },
];
