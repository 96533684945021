/**
 *
 * ThresholdValueTooltip
 *
 */

import React, { memo, FunctionComponent, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import { stringToDate } from 'helpers/Date.helper';
import { getQueryTimeRange } from 'helpers/Time.helper';
import Decimal from 'decimal.js';
import useDebounceValue from 'hooks/useDebounceValue';
import requestOptions from '../../config/assertions_request.json';
import resourceOptions from '../../config/assertions_resource.json';
import useGrafanaQuery from 'hooks/useGrafanaQuery';
import { Button, Input } from '@grafana/ui';

interface IProps {
  assertion: string | undefined;
  labels: Record<string, string>;
  start: number | string;
  end: number | string;
  onApply: (v: string) => void;
}

const DEFAULT_P_VALUE = '95';
const assertionOptions = requestOptions.concat(resourceOptions);

const getValidPValue = (value: string) => {
  if (value && +value > 99) {
    return '99';
  }
  if (value && +value < 1) {
    return '1';
  }
  if (!value) {
    return DEFAULT_P_VALUE;
  }
  return value;
};

const ThresholdValueTooltip: FunctionComponent<IProps> = ({
  labels,
  start = 'now-24h',
  end = 'now',
  assertion,
  onApply,
}) => {
  const { formatMessage } = useIntl();
  const memoStartTime = useMemo(() => stringToDate(start).valueOf(), [start]);
  const memoEndTime = useMemo(() => stringToDate(end).valueOf(), [end]);

  const [pValue, setPValue] = useState(DEFAULT_P_VALUE);

  const pValueDebounced = useDebounceValue(getValidPValue(pValue), 400);

  const assertionMetricValue = assertionOptions.find(
    (a) => a.value === assertion,
  )?.metricValue;

  let assertionQuery = `${assertionMetricValue}{${Object.entries(labels)
    .map(([key, value]) => `${key}="${value}"`)
    .join(', ')}}`;

  if (assertion === 'asserts:error_log:threshold') {
    const { job } = labels;

    const labelsForLog = {
      job,
      asserts_request_type: 'logger',
      asserts_request_context: 'error',
    };
    assertionQuery = `${assertionMetricValue}{${Object.entries(labelsForLog)
      .map(([key, value]) => `${key}="${value}"`)
      .join(', ')}}`;
  }

  const queryTimeRange = useMemo(
    () => getQueryTimeRange(memoStartTime, memoEndTime),
    [memoStartTime, memoEndTime],
  );

  const { data: maxData, isFetching: isFetchingMax } = useGrafanaQuery({
    query: `max(max_over_time(${assertionQuery}${queryTimeRange}))`,
    enabled: !!assertionQuery,
  });

  const { data: pData, isFetching: isFetchingP } = useGrafanaQuery({
    query: `max(quantile_over_time(${new Decimal(
      pValueDebounced || DEFAULT_P_VALUE,
    )
      .dividedBy(100)
      .toNumber()}, ${assertionQuery}${queryTimeRange}))`,
    enabled: !!assertionQuery,
  });

  const pDataProcessed = pData?.[0]?.value?.[1]
    ? new Decimal(pData?.[0].value?.[1]).toDecimalPlaces(3).toString()
    : undefined;

  const maxDataProcessed = maxData?.[0]?.value?.[1]
    ? new Decimal(maxData?.[0].value?.[1]).toDecimalPlaces(3).toString()
    : undefined;

  const handlePValueBlur = () => {
    setPValue(getValidPValue(pValue));
  };

  const showSeconds = [
    'asserts:latency:average:threshold',
    'asserts:latency:p99:threshold',
  ].includes(assertion || '');

  if (!pDataProcessed && !maxDataProcessed && !isFetchingP && !isFetchingMax) {
    return <div>{formatMessage(messages.noData)}</div>;
  }

  return (
    <div className="grid grid-cols-3 gap-y-1 gap-x-2 items-center">
      <div className="flex items-center gap-1 justify-end">
        <span className="text-sm">P</span>
        <Input
          className="w-[80px] px-2 py-1 text-sm "
          value={pValue}
          onChange={(e) => setPValue(e.currentTarget.value)}
          onBlur={handlePValueBlur}
          type="number"
        />
        <span>:</span>
      </div>
      <span className="text-sm whitespace-nowrap">
        {isFetchingP ? (
          <span>{formatMessage(messages.loading)}</span>
        ) : (
          <>
            <span className="font-bold">{pDataProcessed}</span>
            {showSeconds && <span className="text-secondary"> seconds</span>}
          </>
        )}
      </span>
      <Button
        size="sm"
        fill="text"
        className="ml-2 justify-center w-[70px]"
        disabled={!pDataProcessed}
        onClick={() => pDataProcessed && onApply(pDataProcessed)}
      >
        {formatMessage(messages.apply)}
      </Button>
      <span className="text-sm text-right">Max:</span>
      <span className="text-sm whitespace-nowrap">
        {isFetchingMax ? (
          <span>{formatMessage(messages.loading)}</span>
        ) : (
          <>
            <span className="font-bold">{maxDataProcessed}</span>
            {showSeconds && <span className="text-secondary"> seconds</span>}
          </>
        )}
      </span>
      <Button
        size="sm"
        className="ml-2 justify-center w-[70px]"
        fill="text"
        disabled={!maxDataProcessed}
        onClick={() => maxDataProcessed && onApply(maxDataProcessed)}
      >
        {formatMessage(messages.apply)}
      </Button>
    </div>
  );
};

export default memo(ThresholdValueTooltip);
