/**
 *
 * AddCustomDashboard
 *
 */

import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Controller } from 'react-hook-form';
import errorMessages from 'app/errorMessages';
import messages from './messages';
import useLinkCustomDashboardForm from 'features/CustomDashboards/hooks/useLinkCustomDashboardForm';
import useEntityTypes from 'hooks/useEntityTypes';
import { BracesPlugin, Button, Field, QueryField, Select, SlatePrism, Switch } from '@grafana/ui';
import useGrafanaSearch from 'hooks/useGrafanaSearch';
import QueryPreviewScene from 'components/QueryPreviewScene/QueryPreviewScene';
import { useCustomDashboards } from 'features/CustomDashboards/hooks/useCustomDashboards';
import FormControlLabel from 'components/FormControlLabel/FormControlLabel.component';
import useDebounceValue from 'hooks/useDebounceValue';

interface IProps {}

export default function LinkCustomDashboardForm() {
  const intl = useIntl();

  const { watch, onSubmit, control, isLoading: saving } = useLinkCustomDashboardForm();
  const { data: customDashboards } = useCustomDashboards();
  const [dashboardQuery, setDashboardQuery] = useState('');
  const query = useDebounceValue(dashboardQuery, 300);

  const watchQuery = watch('query');

  const { data: entityTypesData, isFetching: isFetchingEntityTypes } = useEntityTypes();
  const { data: dashboards, isFetching: isFetchingDashboards } = useGrafanaSearch({
    query,
    type: 'dash-db',
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="flex flex-col gap-1 pb-6">
        <Controller
          key={saving + 'dashboardUri'}
          name="dashboardUri"
          control={control}
          rules={{
            required: intl.formatMessage(errorMessages.required),
            validate: (v) =>
              !customDashboards?.map((s) => s.dashboardUri).includes(v) ||
              intl.formatMessage(messages.dashboardURIUnique),
          }}
          render={({ field, fieldState }) => {
            return (
              <Field
                invalid={!!fieldState.error}
                error={fieldState.error?.message}
                label={intl.formatMessage(messages.dashboardURI)}
                className="max-w-[700px]"
              >
                <Select
                  {...field}
                  onChange={(v) => {
                    field.onChange(v?.value || '');
                  }}
                  onInputChange={(query) => setDashboardQuery(query)}
                  options={dashboards?.map((o) => ({ label: o.title, value: o.url }))}
                  disabled={saving}
                  isLoading={isFetchingDashboards}
                  isClearable
                />
              </Field>
            );
          }}
        />
        <Controller
          key={saving + 'entityType'}
          name="entityType"
          control={control}
          rules={{ required: intl.formatMessage(errorMessages.required) }}
          render={({ field, fieldState }) => (
            <Field
              invalid={!!fieldState.error}
              error={fieldState.error?.message}
              label={intl.formatMessage(messages.entityType)}
              className="max-w-[700px]"
            >
              <Select
                {...field}
                onChange={(v) => field.onChange(v?.value || '')}
                options={
                  entityTypesData?.entities?.map((o: any) => ({ label: o.entityType, value: o.entityType })) || []
                }
                disabled={saving}
                isLoading={isFetchingEntityTypes}
                isClearable
              />
            </Field>
          )}
        />
        <div className="flex flex-col">
          <Controller
            name="query"
            control={control}
            render={({ field }) => (
              <Field label={intl.formatMessage(messages.prometheusQuery)} className="max-w-[700px]">
                <QueryField
                  {...field}
                  additionalPlugins={[
                    BracesPlugin(),
                    SlatePrism({
                      onlyIn: (node: any) => node.type === 'code_block',
                      getSyntax: () => 'promql',
                    }),
                  ]}
                  portalOrigin="."
                  placeholder="Enter query"
                  query={field.value}
                  disabled={saving}
                />
              </Field>
            )}
          />
          {watchQuery && (
            <div className="h-[350px] pb-6">
              <QueryPreviewScene query={watchQuery} />
            </div>
          )}
        </div>
        <Controller
          name="overrideEntityUri"
          control={control}
          render={({ field }) => (
            <FormControlLabel control={<Switch {...field} />} label={intl.formatMessage(messages.makeAsPrimary)} />
          )}
        />
      </div>
      <Button type="submit" disabled={saving}>
        {intl.formatMessage(messages.addNew)}
      </Button>
    </form>
  );
}
