import { useMutation } from '@tanstack/react-query';
import { apiHttpService } from 'app/api-http-service';
import { ConnectedDataForm, ConnectedDataItem } from 'asserts-types';
import { TRACKING_EVENTS } from 'global-constants';
import TrackingHelper from 'helpers/Tracking.helper';
import useBackendStatus from 'hooks/useBackendStatus';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useVendorsList } from './useVendorsList';

export const DEFAULT_ENV_LABEL = 'cluster';

export function useConnectedDataForm(preloadedItem?: ConnectedDataItem) {
  const { refetch: refetchBackendStatus } = useBackendStatus();

  const { data: vendors } = useVendorsList();

  const { handleSubmit, setValue, watch, control, reset, getValues } = useForm<ConnectedDataForm>({
    defaultValues: { vendors: [], filters: [], envLabel: DEFAULT_ENV_LABEL, envLabelValues: [], siteLabelValues: [] },
  });

  useEffect(() => {
    if (preloadedItem?.envLabel && vendors) {
      reset({
        ...preloadedItem,
        //making sure that only the vendors that are available in the list are preloaded
        vendors: preloadedItem.vendors.filter((v) => vendors.includes(v)),
      });
    }
    //eslint-disable-next-line
  }, [preloadedItem, vendors]);

  const mutation = useMutation({
    mutationFn: (data: Partial<ConnectedDataForm>) => {
      const stackEnablePromise = apiHttpService.post(
        '/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/stack/enable',
        {
          vendors: data.vendors,
          groups: [
            {
              filters: data.filters?.filter((f) => f.name && f.values.length),
              envLabel: data.envLabel,
              siteLabel: data.siteLabel,
              envLabelValues: data.envLabelValues,
              siteLabelValues: data.siteLabelValues,
            },
          ],
        }
      );

      const enableIntegrationsPromise = apiHttpService.post(
        '/api/plugins/grafana-asserts-app/resources/install-integrations',
        {
          integrations: data.vendors?.map((v) => ({
            integration: v,
            AlertsDisabled: false,
          })),
        }
      );

      return Promise.all([stackEnablePromise, enableIntegrationsPromise]);
    },
    onSuccess: (res, data) => {
      TrackingHelper.trackEvent(TRACKING_EVENTS.ONBOARDING_SUCCESS);
      refetchBackendStatus();
    },
  });

  const submit = (data: ConnectedDataForm) => {
    mutation.mutateAsync(data);
  };

  return {
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    formData: watch(),
    getValues,
    onSubmit: handleSubmit(submit),
    isSubmitting: mutation.isLoading,
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
  };
}
