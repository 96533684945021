
import { useSelector } from 'react-redux';
import GraphHelper from '../helpers/Graph.helper';
import { stringToDate } from '../helpers/Date.helper';
import { GraphCustomData } from 'asserts-types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { fetchEntityAssertionsGraph } from 'services/Assertion.service';

interface IProps {
  start: number | string;
  end: number | string;
}

export default function useAssertionsGraph({
  start,
  end,
  onSuccess,
}: IProps & Pick<UseQueryOptions<GraphCustomData>, 'onSuccess'>) {
  const workbenchEntities = useSelector(
    (state: RootState) => state.assertions.workbenchEntities,
  );

  return useQuery(
    ['assertions-graph', workbenchEntities, start, end],
    async () => {
      const res = await fetchEntityAssertionsGraph(
        workbenchEntities,
        stringToDate(start).valueOf(),
        stringToDate(end).valueOf(),
      );
      const graphData = GraphHelper.convertToGraphData(res);
      return {
        ...graphData,
        nodes: graphData.nodes.map((node) => ({ ...node, cluster: 1 })),
      } as GraphCustomData;
    },
    {
      enabled: Boolean(workbenchEntities.length),
      onSuccess,
      staleTime: typeof start === 'string' ? 0 : Infinity,
    },
  );
}
