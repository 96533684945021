/*
 * CustomDashboards Messages
 *
 * This contains all the text for the CustomDashboards container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'features.CustomDashboards';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Dashboards',
  },
  customDash: {
    id: `${scope}.customDash`,
    defaultMessage: 'Link Dashboard',
  },
  search: {
    id: `${scope}.search`,
    defaultMessage: 'Search Dashboards',
  },
  entityType: {
    id: `${scope}.entityType`,
    defaultMessage: 'Entity Type',
  },
  dashName: {
    id: `${scope}.dashName`,
    defaultMessage: 'Dashboard Name',
  },
  query: {
    id: `${scope}.query`,
    defaultMessage: 'Query',
  },
  datasource: {
    id: `${scope}.datasource`,
    defaultMessage: 'Datasource',
  },
  emptyList: {
    id: `${scope}.emptyList`,
    defaultMessage: 'No items here.',
  },
  primary: {
    id: `${scope}.primary`,
    defaultMessage: 'Primary',
  },
});
