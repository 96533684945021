/*
 * ConnectedEntitiesMenuItem Messages
 *
 * This contains all the text for the ConnectedEntitiesMenuItem component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.ConnectedEntitiesMenuItem';

export default defineMessages({
  addToWb: {
    id: `${scope}.addToWb`,
    defaultMessage: 'Add to Workbench',
  },
});
