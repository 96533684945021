import React, { FunctionComponent, memo, useCallback, useMemo } from 'react';
import EntityList from '../EntityList/EntityList.component';
import { useIntl } from 'react-intl';
import messages from '../../messages';
import { Entity, Order, EntitiesTab, KpiGroup, ActiveEntityDetailsPayload } from 'asserts-types';
import EntityListItem from '../EntityListItem/EntityListItem.component';
import { Graph, Item } from '@antv/g6';
import ExpandedEntityList from '../ExpandedEntityList/ExpandedEntityList.component';
import { orderBy as _orderBy } from 'lodash';
import ResizableBox from '../../../../components/ResizableBox/ResizableBox.component';
import GraphHelper from '../../../../helpers/Graph.helper';
import EntitiesResultsInfoComponent from '../EntitiesResultsInfo/EntitiesResultsInfo.component';
import EntitiesSortByComponent from '../EntitiesSortBy/EntitiesSortBy.component';
import { PanelContainer } from '@grafana/ui';

interface IProps {
  entityList: Entity[];
  activeEntityConnections: Entity[];
  activeEntity: Entity | undefined;
  isFetchingEntities: boolean;
  graphRef: Graph | undefined;
  setActiveEntityDetails: (value?: ActiveEntityDetailsPayload) => void;
  activeView: EntitiesTab;
  order: Order;
  orderBy: string;
  entityListColumns: string[];
  kpiListColumns: string[];
  listKpis: KpiGroup['kpis'];
  filteredKpis: string[];
  columnsOptions: Record<string, string[]>;
  lastUpdateTime: number | undefined;
}

const EntitiesNavigator: FunctionComponent<IProps> = ({
  entityList,
  activeEntity,
  activeEntityConnections,
  isFetchingEntities,
  graphRef,
  setActiveEntityDetails,
  activeView,
  order,
  orderBy,
  entityListColumns,
  listKpis,
  kpiListColumns,
  filteredKpis,
  columnsOptions,
  lastUpdateTime,
}) => {
  const intl = useIntl();

  const listLabel = useMemo(
    () =>
      activeEntity && activeEntityConnections.length
        ? //prettier-ignore
          intl.formatMessage(messages.connections, {count: activeEntityConnections.length})
        : undefined,
    [activeEntity, activeEntityConnections.length, intl],
  );

  const list = useMemo(
    () => (activeEntity ? activeEntityConnections : entityList),
    [activeEntity, activeEntityConnections, entityList],
  );

  const sortedList = useMemo(() => _orderBy(list, [orderBy], [order]), [
    list,
    order,
    orderBy,
  ]);

  const handleItemMouseState = useCallback(
    (item: Entity, isMouseOutState?: boolean) => {
      const node = GraphHelper.convertToNode(item);
      if (node) {
        const activeNodeItem = graphRef?.findById(node.id);

        if (activeNodeItem) {
          // graphRef?.focusItem(activeNodeItem, true);
          graphRef
            ?.findAll<Item>('node', () => true)
            .forEach((item) => graphRef?.setItemState(item, 'selected', false));
          graphRef?.setItemState(activeNodeItem, 'selected', !isMouseOutState);
        }
      }
    },
    [graphRef],
  );

  return (
    <ResizableBox
      type="flex"
      handle="right"
      initialSize="25%"
      disabled={activeView === 'list'}
      minSize="25%"
      className={`grow scroll-smooth h-full flex flex-col ${
        isFetchingEntities ? 'pointer-events-none' : 'pointer-events-auto'
      } ${isFetchingEntities ? 'opacity-50' : 'opacity-100'} ${
        activeView === 'list' ? 'p-0' : 'pr-8 pt-8'
      }`}
      collapsable
      collapsedTitle={intl.formatMessage(messages.navigator)}
    >
      {activeView === 'graph' && (
        <div className="flex items-baseline justify-between mb-4">
          <EntitiesResultsInfoComponent
            entityListLength={
              activeEntity ? activeEntityConnections.length : entityList.length
            }
            isFetching={isFetchingEntities}
          />
          {!activeEntity && <EntitiesSortByComponent />}
        </div>
      )}
      {activeEntity && activeView === 'graph' && (
        <PanelContainer>
          <EntityListItem
            item={activeEntity}
            onMouseEnter={() => handleItemMouseState(activeEntity)}
            onMouseOut={() => handleItemMouseState(activeEntity, true)}
            active
            setActiveEntityDetails={setActiveEntityDetails}
            lastUpdateTime={lastUpdateTime}
          />
        </PanelContainer>
      )}

      {activeView === 'graph' && graphRef && (
        <EntityList
          setActiveEntityDetails={setActiveEntityDetails}
          activeEntity={activeEntity}
          list={sortedList}
          label={listLabel}
          handleItemMouseState={handleItemMouseState}
          lastUpdateTime={lastUpdateTime}
        />
      )}
      {activeView === 'list' && (
        <ExpandedEntityList
          activeEntityConnections={activeEntityConnections}
          entityList={entityList}
          isFetching={isFetchingEntities}
          entityListColumns={entityListColumns}
          listKpis={listKpis}
          kpiListColumns={kpiListColumns}
          filteredKpis={filteredKpis}
          columnsOptions={columnsOptions}
          lastUpdateTime={lastUpdateTime}
        />
      )}
    </ResizableBox>
  );
};

export default memo(EntitiesNavigator);
