import React from 'react';
import { Card, LinkButton, LoadingPlaceholder, Tag } from '@grafana/ui';
import TempoIcon from 'assets/icons/tempo_icon.svg';
import { PluginPage } from '@grafana/runtime';
import ConfigurationTopMenu from '../ConfigurationTopMenu/ConfigurationTopMenu';
import { prefixRoute } from 'utils/utils.routing';
import { ROUTES } from 'global-constants';
import useTraceConfigs from './hooks/useTraceConfigs';
import { useDataSources } from 'hooks/useDataSources';

const addTracesPath = prefixRoute([ROUTES.CONFIGURATION, ROUTES.CONFIGURATION_TRACES_ADD].join('/'));

const getTracesEditUrl = (name: string) =>
  prefixRoute([ROUTES.CONFIGURATION, ROUTES.CONFIGURATION_TRACES_EDIT.replace(':name', name)].join('/'));

export default function TracesConfg() {
  const { data: traceConfigs, isFetching } = useTraceConfigs();
  const { data: tracesDatasources } = useDataSources({ type: 'tempo' });

  return (
    <PluginPage
      renderTitle={() => <h1>Configuration</h1>}
      pageNav={{ text: 'Traces' }}
      actions={
        <LinkButton icon="plus" href={addTracesPath}>
          Add traces config
        </LinkButton>
      }
    >
      <ConfigurationTopMenu />
      <div className="mt-8">
        {isFetching && <LoadingPlaceholder text="Loading..." className="pt-20 text-center" />}
        {!isFetching && (
          <ul className="list-none">
            {traceConfigs?.traceEnvConfigDtos?.map((item) => (
              <li key={item.name}>
                <Card href={getTracesEditUrl(item.name)}>
                  <Card.Heading>{item.name}</Card.Heading>
                  <Card.Figure>
                    <TempoIcon />
                  </Card.Figure>
                  <Card.Meta>
                    {!!item.envsForTrace?.length && <span>Environments: {item.envsForTrace?.join(', ')}</span>}
                    {!!item.sitesForTrace?.length && <span>Sites: {item.sitesForTrace?.join(', ')}</span>}
                    {item.defaultConfig && <Tag name="Default" colorIndex={1} />}
                    {item.traceConfig.dataSource && (
                      <span>{tracesDatasources?.find((ds) => ds.uid === item.traceConfig.dataSource)?.name}</span>
                    )}
                  </Card.Meta>
                </Card>
              </li>
            ))}
          </ul>
        )}
      </div>
    </PluginPage>
  );
}
