/**
 *
 * EntityDetailsMenu
 *
 */

import React, { memo, FunctionComponent, useState, useCallback, useEffect } from 'react';
import CloseIcon from 'assets/material-icons/close_FILL0_wght400_GRAD0_opsz24.svg';
import { setActiveEntityDetails } from 'features/App/App.slice';
import messages from './messages';
import { useIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import { addEntityToWorkbench } from '../../../Assertions/Assertions.slice';
import AssertsCircle from '../../../Entities/components/AssertsCircle/AssertsCircle.component';
import EnvSiteTagComponent from 'components/EnvSiteTag/EnvSiteTag.component';
import TagLabel from 'components/TagLabel/TagLabel';
import AddToWorkbenchButtonComponent from '../../../Assertions/components/AddToWorkbenchButton/AddToWorkbenchButton.component';
import WbOpenIcon from 'icons/WbOpenIcon';
import EntityDetailsLoadingPlaceholderComponent from '../EntityDetailsLoadingPlaceholder/EntityDetailsLoadingPlaceholder.component';
import { Entity, EntityDash, ListTabs } from 'asserts-types';
import { prefixRoute } from 'utils/utils.routing';
import { ROUTES, TRACKING_FEATURES } from 'global-constants';
import { LoadingBar, Tab, TabsBar } from '@grafana/ui';
import { Tooltip } from 'components/Tooltip/Tooltip.component';
import ConnectedEntitiesMenuPopover from 'features/Assertions/components/ConnectedEntitiesMenu/ConnectedEntitiesMenuPopover.component';
import { IconButton } from 'components/IconButton/IconButton.component';
import TrackingHelper from 'helpers/Tracking.helper';
import { isAppEnabled } from 'utils/app-plugin';
import useKpiDisplayConfig from 'hooks/useKpiDisplayConfig';
import { ensureActiveTabExists } from './ensureTabActiveTabExists';
import { APP_PLUGIN_IDS, K8S_SUPPORTED_ENTITY_TYPES } from 'app/constants';
import { isCspEntityDashboardAvailable } from 'extensions/csp/utils';
import { isAppO11yAvailable } from 'extensions/appO11y-app/utils';
import { isEmpty } from 'lodash';
import SettingsIcon from 'icons/SettingsIcon';
import { isFrontendO11yAvailable } from 'extensions/frontendO11y-app/utils';

interface IProps {
  activeTab: ListTabs;
  setActiveTab: (tab: ListTabs) => void;
  entity: Entity | undefined;
  dashboards: Record<string, EntityDash>;
  isFetchingEntity: boolean;
  isK8sAvailable: boolean;
  isFetchingK8sData: boolean;
}

const connector = connect(
  (state: RootState) => ({
    activeEntityDetails: state.app.activeEntityDetails,
  }),
  {
    addEntityToWorkbench,
    setActiveEntityDetails,
  }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

const EntityDetailsMenu: FunctionComponent<IProps & PropsFromRedux> = ({
  activeTab,
  setActiveTab,
  setActiveEntityDetails,
  addEntityToWorkbench,
  activeEntityDetails,
  entity,
  dashboards,
  isFetchingEntity,
  isK8sAvailable,
  isFetchingK8sData,
}) => {
  const intl = useIntl();

  // use k8s as a default tab if it's available and it's not a service (because for service the main view will be Service KPI)
  useEffect(() => {
    if (
      activeEntityDetails?.type &&
      K8S_SUPPORTED_ENTITY_TYPES.includes(activeEntityDetails.type) &&
      activeEntityDetails?.type !== 'Service' &&
      isK8sAvailable
    ) {
      setActiveTab('k8s');
    }
    if (!isEmpty(dashboards) && isAppO11yAvailable(entity) && !dashboards.kpi) {
      setActiveTab('app-o11y');
    }
    if (isFrontendO11yAvailable(entity) && !dashboards.kpi) {
      setActiveTab('frontend-o11y');
    }
  }, [isK8sAvailable, activeEntityDetails?.type, setActiveTab, entity, dashboards]);

  const [copiedTitle, setCopiedTitle] = useState(false);
  const navigate = useNavigate();

  const openInWorkbench = () => {
    if (entity) {
      addEntityToWorkbench(entity);
      TrackingHelper.trackNavigateToWorkbench(TRACKING_FEATURES.ENTITY_DETAILS_DRAWER);
      navigate(prefixRoute(ROUTES.RCA_WORKBENCH), { state: { timestamp: Date.now() } });
      setActiveEntityDetails();
    }
  };

  const handlePropertyClick = useCallback((text: string) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text);
      setCopiedTitle(true);
    }
  }, []);

  const { data: kpiViewConfig } = useKpiDisplayConfig();

  useEffect(() => {
    const ensured = ensureActiveTabExists(activeTab, kpiViewConfig);
    if (ensured !== activeTab) {
      setActiveTab(ensured as ListTabs);
    }
  }, [setActiveTab, activeTab, kpiViewConfig]);

  const isCspDashboardAvailable = isCspEntityDashboardAvailable(entity);
  const isCspEnabled = isAppEnabled(APP_PLUGIN_IDS.CSP_APP);

  return (
    <div className={`relative h-[120px] divider-b flex flex-col justify-between shrink-0`}>
      {entity && !isFetchingEntity ? (
        <>
          <div className="pt-4 pl-4 pr-16">
            <div className="flex items-center gap-10">
              <div className="flex items-center gap-4 min-w-0">
                <AssertsCircle entity={entity} size={60} />
                <div className="min-w-0">
                  <Tooltip
                    content={
                      <>
                        <div>{entity.name}</div>
                        <div className="tooltip-copy-state">
                          {copiedTitle
                            ? intl.formatMessage(messages.textCopied)
                            : intl.formatMessage(messages.copyHint)}
                        </div>
                      </>
                    }
                    placement="top"
                  >
                    <span
                      onClick={() => handlePropertyClick(entity.name)}
                      className="font-bold whitespace-nowrap overflow-hidden text-ellipsis text-base max-w-full inline-block hover:cursor-pointer hover:underline"
                    >
                      {entity.name}
                    </span>
                  </Tooltip>
                  {entity.scope?.env && <EnvSiteTagComponent scope={entity.scope} />}
                </div>
                <TagLabel entityType={entity.type} properties={entity.properties} scope={entity.scope} />
              </div>
              <div className="flex items-center">
                <Tooltip content={intl.formatMessage(messages.openInWorkbench)}>
                  <IconButton onClick={openInWorkbench} variant="primary">
                    <WbOpenIcon />
                  </IconButton>
                </Tooltip>
                <AddToWorkbenchButtonComponent entityKeys={[entity]} />
                <ConnectedEntitiesMenuPopover name={entity.name} type={entity.type} scope={entity.scope} />
                <Tooltip content={'Customize entity display'}>
                  <IconButton
                    onClick={() => navigate(prefixRoute(`${ROUTES.CONFIGURATION}/${ROUTES.CONFIGURATION_DISPLAY}`))}
                    variant="primary"
                  >
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
        </>
      ) : (
        <EntityDetailsLoadingPlaceholderComponent />
      )}

      <div className="absolute bottom-0 inset-x-4 flex justify-end overflow-overlay">
        <TabsBar hideBorder>
          {kpiViewConfig?.logsView && (
            <Tab onChangeTab={() => setActiveTab('logs')} active={activeTab === 'logs'} label="Logs" />
          )}
          {kpiViewConfig?.tracesView && (
            <Tab onChangeTab={() => setActiveTab('traces')} active={activeTab === 'traces'} label="Traces" />
          )}
          {activeEntityDetails?.alertName && (
            <Tab onChangeTab={() => setActiveTab('metrics')} active={activeTab === 'metrics'} label="Metrics" />
          )}
          {Object.keys(dashboards).map((tabName) => (
            <Tab
              key={tabName}
              active={activeTab === tabName}
              onChangeTab={() => setActiveTab(tabName as ListTabs)}
              label={dashboards[tabName]?.dashName}
              value={tabName}
            />
          ))}
          {kpiViewConfig?.k8sAppView && isK8sAvailable && (
            <Tab onChangeTab={() => setActiveTab('k8s')} active={activeTab === 'k8s'} label={'Kubernetes'} />
          )}
          {isAppO11yAvailable(entity) && kpiViewConfig?.appO11yAppView && (
            <Tab
              onChangeTab={() => setActiveTab('app-o11y')}
              active={activeTab === 'app-o11y'}
              label={'Application observability'}
            />
          )}
          {isFrontendO11yAvailable(entity) && kpiViewConfig?.frontendO11yAppView && (
            <Tab
              onChangeTab={() => setActiveTab('frontend-o11y')}
              active={activeTab === 'frontend-o11y'}
              label={'Real User Monitoring'}
            />
          )}
          {isCspDashboardAvailable && isCspEnabled && kpiViewConfig?.awsAppView && (
            <Tab
              onChangeTab={() => setActiveTab('csp')}
              active={activeTab === 'csp'}
              label={`${entity?.properties?.service_type ?? 'Service Instance'} Overview`}
            />
          )}
          {kpiViewConfig?.propertiesView && (
            <Tab
              onChangeTab={() => setActiveTab('info')}
              active={activeTab === 'info'}
              label={'Properties'}
              value="info"
            />
          )}
        </TabsBar>
      </div>
      <IconButton className="absolute right-2 top-4" onClick={() => setActiveEntityDetails()}>
        <CloseIcon color={'action'} onClick={() => setActiveEntityDetails()} />
      </IconButton>
      <div className="bottom-0 absolute inset-x-0">
        {(isFetchingEntity || isFetchingK8sData) && <LoadingBar width={300} />}
      </div>
    </div>
  );
};

export default connector(memo(EntityDetailsMenu));
