/*
 * Slo Messages
 *
 * This contains all the text for the Slo container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'features.Slo';

export default defineMessages({
  availabilitySlo: {
    id: `${scope}.availabilitySlo`,
    defaultMessage: 'Availability SLO',
  },
  target: {
    id: `${scope}.target`,
    defaultMessage: 'Target',
  },
  actual: {
    id: `${scope}.target`,
    defaultMessage: 'Actual',
  },
  errorBudget: {
    id: `${scope}.errorBudget`,
    defaultMessage: 'Error Budget Available',
  },
  budgetUsed: {
    id: `${scope}.budgetUsed`,
    defaultMessage: 'Budget Used',
  },
  recentBudgetUsage: {
    id: `${scope}.recentBudgetUsage`,
    defaultMessage: 'Recent Budget Usage',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Name',
  },
  errorBudgetOne: {
    id: `${scope}.errorBudget`,
    defaultMessage: 'Error Budget Available (1 hour)',
  },
  numberOfIncidents: {
    id: `${scope}.numberOfIncidents`,
    defaultMessage: 'Incidents in Window',
  },
  sli: {
    id: `${scope}.sli`,
    defaultMessage: 'SLI',
  },
  complianceWindow: {
    id: `${scope}.complianceWindow`,
    defaultMessage: 'Compliance Window',
  },
  incidentStatus: {
    id: `${scope}.incidentStatus`,
    defaultMessage: 'Current Incident Status',
  },
  emptySlo: {
    id: `${scope}.emptySlo`,
    defaultMessage: 'List of SLO is empty',
  },
  addSlo: {
    id: `${scope}.addSlo`,
    defaultMessage: 'Create SLO to get started',
  },
  addSloBtn: {
    id: `${scope}.addSloBtn`,
    defaultMessage: 'Create SLO',
  },
  noResults: {
    id: `${scope}.noResults`,
    defaultMessage: 'No results for the given filter or search criteria.',
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Edit',
  },
});
