import React, { FunctionComponent } from 'react';

import { ConnectedProps, connect } from 'react-redux';
import AssertionSearchRules from './components/AssertionSearchRules/AssertionSearchRules.component';
import { addRule, setEntityType } from './AssertionSearch.slice';
import AssertionSearchResult from './components/AssertionSearchResult/AssertionSearchResult.component';
import {
  addEntityToWorkbench,
  addEntitiesToWorkbench,
  setShowSearch,
  removeEntitiesFromWorkbench,
} from '../Assertions/Assertions.slice';
import useAssertionsSearch from './hooks/useAssertionsSearch';
import { useAppSelector } from 'app/store';
import { LoadingBar, PanelContainer } from '@grafana/ui';

const connector = connect(
  (state: RootState) => ({
    workbenchEntities: state.assertions.workbenchEntities,
  }),
  {
    addRule,
    setEntityType,
    addEntityToWorkbench,
    removeEntitiesFromWorkbench,
    addEntitiesToWorkbench,
    setShowSearch,
  },
);

type PropsFromRedux = ConnectedProps<typeof connector>;

const AssertionSearch: FunctionComponent<PropsFromRedux> = ({
  addRule,
  setEntityType,
  addEntityToWorkbench,
  workbenchEntities,
  removeEntitiesFromWorkbench,
  addEntitiesToWorkbench,
  setShowSearch,
}) => {
  const { refetch, isFetching, data, isFetched } = useAssertionsSearch();
  const assertionSearch = useAppSelector((state) => state.assertionSearch.search);

  return (
    <PanelContainer
      className={`absolute top-0 bg-paper right-0 bottom-0 z-[999] shadow-md w-[408px] border-t-0 overflow-overlay`}
    >      
      <AssertionSearchRules
        addRule={addRule}
        assertionSearch={assertionSearch}
        setEntityType={setEntityType}
        searchAssertions={refetch}
        setShowSearch={setShowSearch}
      />
      {isFetching && <LoadingBar width={300} />}
      {!!data?.length && (
        <AssertionSearchResult
          foundAssertions={data}
          addEntityToWorkbench={addEntityToWorkbench}
          workbenchEntities={workbenchEntities}
          removeEntitiesFromWorkbench={removeEntitiesFromWorkbench}
          addEntitiesToWorkbench={addEntitiesToWorkbench}
        />
      )}
      {isFetched && data?.length === 0 && <span className="text-secondary px-8">No results</span>}
    </PanelContainer>
  );
};

export default connector(AssertionSearch);
