/**
 *
 * ConnectionType
 *
 */

import React, { memo, FunctionComponent } from 'react';
import ArrowBackOutlinedIcon from 'assets/material-icons/arrow_back_FILL0_wght400_GRAD0_opsz24.svg';
import ArrowForwardOutlinedIcon from 'assets/material-icons/arrow_forward_FILL0_wght400_GRAD0_opsz24.svg';
import { Entity } from 'asserts-types';

interface IProps {
  entity: Entity;
}

const ConnectionType: FunctionComponent<IProps> = ({ entity }) => {
  if (!entity.edgeWithActiveElement) {
    return null;
  }
  return (
    <div className="flex items-center mr-4">
      {entity.edgeWithActiveElement.source === entity.id ? (
        <ArrowBackOutlinedIcon className="svg-icon mr-2 text-secondary" />
      ) : (
        <ArrowForwardOutlinedIcon className="svg-icon mr-2 text-secondary" />
      )}

      <p className="text-secondary text-sm">{entity.edgeWithActiveElement.type}</p>
    </div>
  );
};

export default memo(ConnectionType);
