import { useServiceByPod } from './useServiceByPod';
import { useKubeClusterByWorkload } from './useKubeClusterByWorkload';
import { OpenInKubernetesTriggerProps } from '../OpenInKubernetesTrigger';

export function useKubeClusterByPod(entity: OpenInKubernetesTriggerProps['entity'] | undefined, start: string | number, end: string | number, enabled: boolean) {
  const { data: service, isFetching: isFetchingServiceData } = useServiceByPod(entity, start, end, enabled);

  const { data: cluster, isFetching: isFetchingClusterData } = useKubeClusterByWorkload(service, start, end, enabled);

  return { data: cluster, isFetching: isFetchingServiceData || isFetchingClusterData };
}
