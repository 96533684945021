import { apiHttpService } from 'app/api-http-service';
import messages from '../messages';
import useVendorRules, { USE_VENDOR_RULES_QUERY_KEY } from './useVendorRules';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import SnackbarHelper from 'helpers/Snackbar.helper';

export default function useChangePromRule() {
  const { data: rules } = useVendorRules();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ ruleName, enable }: { ruleName: string; enable: boolean }) =>
      apiHttpService
        .post<void>(
          `/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/prom-rules-${
            enable ? 'enable' : 'disable'
          }/${ruleName}`,
          {},
        )
        .then((res) => res.data),
    onSuccess: (_, params) => {
      SnackbarHelper.success(params.enable ? messages.enabled : messages.disabled);
      queryClient.setQueryData(
        [USE_VENDOR_RULES_QUERY_KEY],
        rules?.map((item) =>
          item.name === params.ruleName
            ? { ...item, enabled: params.enable }
            : item,
        ) || [],
      );
    },
  });
}
