/**
 *
 * ExpandedEntityListItem
 *
 */

import React, { memo, FunctionComponent } from 'react';
import { Entity } from 'asserts-types';
import has from 'helpers/has.helper';
import { formatLongNumber } from 'helpers/ValueFormat.helper';
import { setActiveEntityDetails } from 'features/App/App.slice';

import { connect, ConnectedProps } from 'react-redux';
import { DATE_FIELDS, MONITORING_COLUMNS } from '../../constants';
import CheckCircleIcon from 'assets/material-icons/check_circle_FILL0_wght400_GRAD0_opsz24.svg';
import CancelIcon from 'assets/material-icons/cancel_FILL0_wght400_GRAD0_opsz24.svg';
import { assertsColors } from 'app/constants';
import { areEntitiesEqual, kpiRegexp } from 'helpers/Entity.helper';
import EntityListItemComponent from '../EntityListItem/EntityListItem.component';
import moment from 'moment';
import { FORMAT_YEARLESS_lll } from 'app/moment-locales';
import { Tooltip } from 'components/Tooltip/Tooltip.component';

const connector = connect(
  (state: RootState) => ({
    propByKpis: state.entities.propByKpis,
    monitoringStatus: state.entities.monitoringStatus,
  }),
  {},
);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IProps {
  entity: Entity;
  entityListColumns?: string[];
  setActiveEntityDetails: typeof setActiveEntityDetails;
  lastUpdateTime: number | undefined;
}

const ExpandedEntityListItem: FunctionComponent<IProps & PropsFromRedux> = ({
  entity,
  entityListColumns,
  setActiveEntityDetails,
  propByKpis,
  monitoringStatus,
  lastUpdateTime
}) => {
  const displayValue = (entity: Entity, column: string) => {
    if (MONITORING_COLUMNS.includes(column)) {
      const monValue = getMonitoringValue(entity, column);
      return monValue ? (
        <CheckCircleIcon className="text-success svg-icon" />
      ) : monValue === false ? (
        <CancelIcon className="text-destructive svg-icon" />
      ) : (
        '–'
      );
    }

    let kpiValue: number | undefined;
    let kpiValueColor: string | undefined;

    if (propByKpis[column]) {
      const kpisKeys = Object.keys(propByKpis[column]);
      const reg = kpiRegexp(entity.name, entity.scope);
      const kpiKey = kpisKeys.find((key) => reg.test(key)) as string;
      kpiValue = propByKpis[column][kpiKey]?.kpiValue;
      const kpiAssertionSummary = propByKpis[column][kpiKey]?.assertionSummary;

      kpiValueColor = kpiAssertionSummary?.severity
        ? assertsColors[kpiAssertionSummary?.severity]
        : undefined;
    }

    if (kpiValueColor) {
      return (
        <span style={{ color: kpiValueColor, fontWeight: 700 }}>
          {formatLongNumber(kpiValue) || '-'}
        </span>
      );
    }

    const value = has<Entity, string, string>(entity, column)
      ? entity[column]
      : kpiValue
      ? kpiValue
      : entity.properties[column] || entity.properties[column] === 0
      ? entity.properties[column]
      : '–';

    if (
      typeof value === 'number' &&
      DATE_FIELDS.some((f) => column.toLowerCase().includes(f))
    ) {
      return moment(value).format(FORMAT_YEARLESS_lll);
    }

    return formatLongNumber(value) || '–';
  };

  const getMonitoringValue = (entity: Entity, field: string) => {
    const status: any =
      monitoringStatus.find((s) => areEntitiesEqual(s.entityKey, entity)) || {};
    return status[field];
  };

  return (
    <>
      <td
        className="whitespace-nowrap p-4 divider-b"
        style={{ width: 500, maxWidth: 'none' }}
        title={entity.nameWithNamespace || entity.name}
      >
        <EntityListItemComponent
          className="!border-0 rounded-md w-[500px] min-h-[50px] bg-transparent px-3"
          setActiveEntityDetails={setActiveEntityDetails}
          key={entity.id}
          item={entity}
          lastUpdateTime={lastUpdateTime}
        />
      </td>
      {entityListColumns &&
        entityListColumns.map((column, index) => (
          <td
            key={column}
            align="left"
            className="p-4 divider-b"
            style={{ width: entityListColumns.length - 1 === index ? 0 : 1 }}
          >
            <Tooltip content={displayValue(entity, column)} placement="left">
              <div className="!whitespace-nowrap overflow-hidden text-ellipsis">{displayValue(entity, column)}</div>
            </Tooltip>
          </td>
        ))}
    </>
  );
};

export default connector(memo(ExpandedEntityListItem));
