import { defineMessages } from 'react-intl';

export const scope = 'features.TopInsightsList';

export default defineMessages({
  score: {
    id: `${scope}.score`,
    defaultMessage: 'Score %',
  },
  entity: {
    id: `${scope}.entity`,
    defaultMessage: 'Entity',
  },
  type: {
    id: `${scope}.type`,
    defaultMessage: 'Type',
  },
  filter: {
    id: `${scope}.filter`,
    defaultMessage: 'Summary stats',
  },
});
