import { useParams } from 'react-router-dom';
import { RelabelRuleParams, RelabelRuleType } from 'asserts-types';
import { useQuery } from '@tanstack/react-query';
import { fetchRelabelsRulesFile } from 'services/RelabelRules.service';

export default function useRelabelRulesFile() {
  const { type } = useParams();

  return useQuery(['relabelRulesFile', type], () => fetchRelabelsRulesFile(type as RelabelRuleType), {
    staleTime: 0,
    enabled: !!type,
  });
}
