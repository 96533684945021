/**
 *
 * EntitiesResultsInfo
 *
 */

import React, { memo, FunctionComponent } from 'react';
import ArrowBackIcon from 'assets/material-icons/arrow_back_FILL0_wght400_GRAD0_opsz24.svg';
import { useDispatch } from 'react-redux';
import { historyNavigate } from '../../Entities.slice';
import { useAppSelector } from 'app/store';
import { IconButton } from 'components/IconButton/IconButton.component';

interface IProps {
  isFetching: boolean;
  entityListLength: number;
}

const EntitiesResultsInfo: FunctionComponent<IProps> = ({
  isFetching,
  entityListLength,
}) => {
  const searchDefinition = useAppSelector(
    (state) => state.entities.searchDefinition,
  );

  const activeEntity = useAppSelector((state) => state.entities.activeEntity);

  const dispatch = useDispatch();

  if (isFetching) {
    return <div className="font-bold text-normal">Loading...</div>;
  }
  return (
    <div className="flex items-start space-x-3">
      {activeEntity && (
        <IconButton onClick={() => dispatch(historyNavigate())} data-cy="back-connections-btn">
          <ArrowBackIcon />
        </IconButton>
      )}
      <div>
        <div className="font-bold text-normal">{activeEntity ? activeEntity.name : `${entityListLength} results`}</div>

        {searchDefinition?.boundDescription && (
          <div className="text-secondary text-sm">from {searchDefinition?.boundDescription}</div>
        )}
      </div>
    </div>
  );
};

export default memo(EntitiesResultsInfo);
