import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { PluginPage } from '@grafana/runtime';
import {
  Box,
  InlineField,
  InlineFieldRow,
  InlineSwitch,
  LoadingBar,
  LoadingPlaceholder,
  useStyles2,
} from '@grafana/ui';
import useAppDisplayConfig from 'hooks/useAppDisplayConfig';
import useKpiDisplayConfig from 'hooks/useKpiDisplayConfig';
import { useMutateKpiDisplayConfig } from 'hooks/useMutateKpiDisplayConfig';
import React from 'react';
import ConfigurationTopMenu from '../ConfigurationTopMenu/ConfigurationTopMenu';
import { AddNewGraphDisplayOverrideForm } from './components/AddNewGraphDisplayOverrideForm';
import { EntityDisplayConfigOverrideRowItem } from './components/EntityDisplayConfigOverrideRowItem';
import { KpiViewConfigForm } from './KpiViewConfigForm';

export function DisplayConfig() {
  const styles = useStyles2(getStyles);
  const { isLoading, isFetching, data: displayConfig, refetch } = useAppDisplayConfig();
  const { isLoading: kpiConfigIsLoading, isFetching: kpiConfigIsFetching, data: kpiConfig } = useKpiDisplayConfig();
  const { mutate: mutateKpiDisplayConfig } = useMutateKpiDisplayConfig();

  const entityOverrides = Object.keys(displayConfig?.graph.overrides.entities ?? {});

  return (
    <PluginPage renderTitle={() => <h1>Configuration</h1>} pageNav={{ text: 'Display' }}>
      <ConfigurationTopMenu />
      <Box paddingBottom={4} paddingTop={3}>
        <h4>Entity KPI views</h4>
        <p className={styles.subheader}>
          Asserts ships with pre-built dashboards to provide insights into the health of entities. You can additionally
          have asserts use views embedded from other Grafana Cloud plugins. Note that these views are experimental and
          will continue to be updated.
        </p>
        {!kpiConfigIsFetching && kpiConfig && (
          <KpiViewConfigForm
            defaultValues={kpiConfig}
            onSubmit={(values) => {
              mutateKpiDisplayConfig(values);
            }}
          />
        )}
      </Box>

      <Box paddingBottom={8} paddingTop={3}>
        <h4>Add new entity display override</h4>
        <p className={styles.subheader}>Customize the visual presentation of entity types in the entity graph</p>
        <AddNewGraphDisplayOverrideForm />
      </Box>
      <h4>Overrides</h4>
      <p className={styles.subheader}>Entity overrides you already created</p>
      <Box padding={4} borderColor="weak" borderStyle="solid">
        {isFetching && <LoadingBar width={300} />}
        {isLoading ? (
          <div className="flex items-center justify-center p-8">
            <LoadingPlaceholder text="Loading" />
          </div>
        ) : (
          <table className={styles.table}>
            <thead className={styles.tableHeader}>
              <td>Entity Type</td>
              <td>Color</td>
              <td>Icon</td>
              <td className={css({ width: '10%' })}></td>
            </thead>
            {entityOverrides.length === 0 ? (
              <Box padding={2}>No items here.</Box>
            ) : (
              entityOverrides.map((entityType) => {
                const override = displayConfig?.graph.overrides.entities[entityType];
                if (!override) {
                  return null;
                }
                return (
                  <EntityDisplayConfigOverrideRowItem
                    key={entityType}
                    entityType={entityType}
                    color={override.color}
                    icon={override.icon}
                    displayConfig={displayConfig}
                    onUpdate={() => refetch()}
                  />
                );
              })
            )}
          </table>
        )}
      </Box>
    </PluginPage>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    subheader: css({
      color: theme.colors.text.secondary,
      paddingBottom: theme.spacing(3),
      fontSize: theme.typography.h5.fontSize,
      maxWidth: '700px',
    }),
    table: css({
      width: '100%',
      padding: theme.spacing(1),
    }),
    tableHeader: css({
      borderBottom: `1px solid ${theme.colors.border.weak}`,
      padding: theme.spacing(2),
      td: {
        padding: theme.spacing(2),
        color: theme.colors.text.secondary,
        fontSize: theme.typography.bodySmall.fontSize,
      },
    }),
  };
}
