/**
 *
 * HoveringTag
 *
 */

import React, { memo, FunctionComponent, useState } from 'react';
import TagLabel from '../TagLabel/TagLabel';
import ListAltIcon from 'assets/material-icons/list_alt_FILL0_wght400_GRAD0_opsz24.svg';
import DashboardOutlinedIcon from 'assets/material-icons/dashboard_FILL0_wght400_GRAD0_opsz24.svg';
import PropertiesPopover from '../PropertiesPopover/PropertiesPopover.component';
import { useIntl } from 'react-intl';
import { HierarchicalAssertion } from 'asserts-types';
import { twMerge } from 'tailwind-merge';
import AssertionsProblematicConnectionsButtonComponent from '../../features/Assertions/components/AssertionsProblematicConnectionsButton/AssertionsProblematicConnectionsButton.component';
import { Tooltip } from 'components/Tooltip/Tooltip.component';
import ConnectedEntitiesMenuPopover from 'features/Assertions/components/ConnectedEntitiesMenu/ConnectedEntitiesMenuPopover.component';
import { IconButton } from 'components/IconButton/IconButton.component';
import { useDispatch } from 'react-redux';
import { setActiveEntityDetails } from 'features/App/App.slice';
import { Menu, useTheme2 } from '@grafana/ui';
import messages from './messages'
import useAffectedEntityNames from 'hooks/useAffectedEntityNames';
import { Popover, PopoverContent, PopoverTrigger } from 'components/Popover/Popover.component';
import { omit } from 'lodash';


interface IProps {
  setActiveTooltip: (a: boolean) => void;
  activeTooltip: boolean;
  lastUpdateTime?: number;
  start: number;
  end: number;
  item: HierarchicalAssertion;
}

const HoveringTag: FunctionComponent<IProps> = ({ setActiveTooltip, activeTooltip, start, end, item }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { name, type, scope, labels, lastUpdateTime, rootEntityName, rootEntityType } = item;


  const containsAffectedItems =
    labels.asserts_source_entity_type && ['Pod', 'ServiceInstance'].includes(labels.asserts_source_entity_type);

  const { data: affectedEntitiesData, isFetching: isFetchingAffectedEntities } = useAffectedEntityNames({
    labels: omit(labels, 'asserts_threshold_level', '__grafana_origin'),
    entityType: type,
    entityName: name,
    assertionName: labels.alertname,
    startTime: start,
    endTime: end,
    rootEntityName,
    rootEntityType,
    enabled: true,
  });

  const handleSetActiveItem = () => {
    if (type) {
      dispatch(
        setActiveEntityDetails({
          name,
          type,
          scope,
          properties: labels,
        })
      );
    }
  };

  const [connectedMenuOpened, setConnectedMenuOpened] = useState(false);
  const [showAffected, setShowAffected] = useState(false);

  const theme = useTheme2();

  return (
    <>
      <div
        className={twMerge(
          'h-12 text-right tag-section group-hover:hidden',
          activeTooltip || connectedMenuOpened || showAffected ? '!hidden' : ''
        )}
      >
        <TagLabel
          entityType={type}
          properties={labels}
          scope={scope}
          count={affectedEntitiesData?.entityNames.length}
        />
        <p className="block text-secondary mr-3 whitespace-nowrap overflow-hidden text-ellipsis text-xs">{name}</p>
      </div>
      <div
        className={twMerge(
          'hidden whitespace-nowrap group-hover:block h-12',
          activeTooltip || connectedMenuOpened || showAffected ? '!block' : ''
        )}
      >
        <Tooltip
          placement="top-end"
          interactive
          enterDelay={300}
          onOpen={() => {
            setActiveTooltip(true);
          }}
          onClose={() => {
            setActiveTooltip(false);
          }}
          content={
            <PropertiesPopover
              properties={labels}
              type={type}
              scope={scope}
              showAllProperties
              lastUpdateTime={lastUpdateTime}
            />
          }
        >
          <IconButton onClick={(e) => e.stopPropagation()} active={activeTooltip} variant="primary">
            <ListAltIcon />
          </IconButton>
        </Tooltip>
        <AssertionsProblematicConnectionsButtonComponent
          name={name}
          type={type}
          start={start}
          end={end}
          scope={scope}
        />

        <ConnectedEntitiesMenuPopover name={name} type={type} scope={scope} onOpenChange={setConnectedMenuOpened} />

        {containsAffectedItems ? (
          <Popover open={showAffected} onOpenChange={setShowAffected} placement="bottom-start">
            <PopoverContent>
              <Menu className="max-h-[400px] overflow-overlay bg-paper" style={{ boxShadow: theme.shadows.z3 }}>
                <Menu.Group label={`Affected ${labels.asserts_source_entity_type}s`}>
                  {affectedEntitiesData?.entityNames.map((n) => (
                    <Menu.Item
                      key={n}
                      label={n}
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(
                          setActiveEntityDetails({
                            name:
                              labels.asserts_source_entity_type === 'ServiceInstance'
                                ? `${labels.service || labels.job}:${n}`
                                : n,
                            type: labels.asserts_source_entity_type,
                            scope,
                            properties: labels,
                          })
                        );
                      }}
                    />
                  ))}
                </Menu.Group>
              </Menu>
            </PopoverContent>
            <Tooltip content={intl.formatMessage(messages.kpi)} placement="top">
              <PopoverTrigger
                asChild
                onClick={(e) => {
                  e.stopPropagation();
                  if (affectedEntitiesData?.entityNames.length && !isFetchingAffectedEntities) {
                    setShowAffected(true);
                  }
                }}
                disabled={!affectedEntitiesData?.entityNames.length || isFetchingAffectedEntities}
              >
                <IconButton variant="primary" active={showAffected}>
                  <DashboardOutlinedIcon />
                </IconButton>
              </PopoverTrigger>
            </Tooltip>
          </Popover>
        ) : (
          <Tooltip content={intl.formatMessage(messages.kpi)} placement="top">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleSetActiveItem();
              }}
              variant="primary"
            >
              <DashboardOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </>
  );
};

export default memo(HoveringTag);
