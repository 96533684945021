import { defineMessages } from 'react-intl';

export const scope = 'components.NotificationsSloForm';

export default defineMessages({
  for: {
    id: `${scope}.for`,
    defaultMessage: 'For',
  },
  receiver: {
    id: `${scope}.receiver`,
    defaultMessage: 'Receiver',
  },
  otherLabels: {
    id: `${scope}.otherLabels`,
    defaultMessage: 'Other Labels',
  },
});
