/*
 * PreviewSloChart Messages
 *
 * This contains all the text for the PreviewSloChart component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.PreviewSloChart';

export default defineMessages({
  loading: {
    id: `${scope}.loading`,
    defaultMessage: 'Loading Chart...',
  },
  noData: {
    id: `${scope}.noData`,
    defaultMessage: 'No Data to display.',
  },
  Request: {
    id: `${scope}.request`,
    defaultMessage: 'Availability',
  },
  Occurrence: {
    id: `${scope}.occurrence`,
    defaultMessage: 'Latency / Occurrence',
  },
});
