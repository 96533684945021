import { TimeRange, dateTimeParse } from '@grafana/data';
import { usePluginComponent } from '@grafana/runtime';
import { LoadingPlaceholder } from '@grafana/ui';
import { APP_PLUGIN_IDS, EXTERNAL_PLUGIN_VIEWS } from 'app/constants';
import { Entity } from 'asserts-types';
import React, { useMemo } from 'react';
import { isAppEnabled } from 'utils/app-plugin';

interface Props {
  entity: Pick<Entity, 'scope' | 'type' | 'properties' | 'name'> | undefined;
  initialStart: string | number;
  initialEnd: string | number;
  onTimeRangeChange?: (timeRange: TimeRange) => void;
  onTabChange?: (props: { query: string | undefined; tab: 'traces' | 'logs' }) => void;
}

interface AppO11yAppViewProps {
  serviceName: string;
  namespace?: string;
  initialTimeRange: TimeRange;
  onTimeRangeChange?: (timeRange: TimeRange) => void;
  onTabChange?: (props: { query: string | undefined; tab: 'traces' | 'logs' }) => void;
}

const AppO11yEntityView: React.FC<Props> = ({ entity, initialStart, initialEnd, onTimeRangeChange, onTabChange }) => {
  const { component: AppO11yAppView, isLoading: isAppO11yAppViewLoading } = usePluginComponent<AppO11yAppViewProps>(
    EXTERNAL_PLUGIN_VIEWS.O11Y_APP_OVERVIEW_EXTENSION_V1
  );

  const timeRange: TimeRange = useMemo(() => {
    const from = dateTimeParse(initialStart);
    const to = dateTimeParse(initialEnd);
    return {
      from,
      to,
      raw: {
        from: typeof initialStart === 'string' ? initialStart : from,
        to: typeof initialEnd === 'string' ? initialEnd : to,
      },
    };
  }, [initialStart, initialEnd]);

  const isAppO11yEnabled = isAppEnabled(APP_PLUGIN_IDS.APP_O11Y_APP);

  if (!!entity?.properties.otel_service && !!entity?.properties.otel_namespace && AppO11yAppView && isAppO11yEnabled) {
    return (
      <AppO11yAppView
        initialTimeRange={timeRange}
        serviceName={entity.properties.otel_service.toString()}
        namespace={entity.properties.otel_namespace.toString()}
        onTimeRangeChange={onTimeRangeChange}
        onTabChange={onTabChange}
      />
    );
  }

  if (isAppO11yAppViewLoading) {
    return <LoadingPlaceholder text="Loading..." />;
  }

  return <div>No data</div>;
};

export default AppO11yEntityView;
