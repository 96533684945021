/*
 * BubbleViewItemTooltip Messages
 *
 * This contains all the text for the BubbleViewItemTooltip component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.BubbleViewItemTooltip';

export default defineMessages({
  copied: {
    id: `${scope}.copied`,
    defaultMessage: 'Copied',
  },
});
