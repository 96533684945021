import { defineMessages } from 'react-intl';

export const scope = 'app.errorMessages';

export default defineMessages({
  required: {
    id: `${scope}.required`,
    defaultMessage: 'This field is required',
  },
  wrongFormat: {
    id: `${scope}.wrongFormat`,
    defaultMessage: 'Wrong format',
  },
  wrongEmail: {
    id: `${scope}.wrongEmail`,
    defaultMessage: 'Wrong email',
  },
  range: {
    id: `${scope}.range`,
    defaultMessage: 'Enter value between {min} and {max}',
  },
  noConnectionsFound: {
    id: `${scope}.noConnectionsFound`,
    defaultMessage: 'No problematic connections found.',
  },
  loadingSloFailed: {
    id: `${scope}.loadingSloFailed`,
    defaultMessage: 'Failed loading SLO.',
  },
  noDefinitionFound: {
    id: `${scope}.noDefinitionFound`,
    defaultMessage: 'Failed loading search definition.',
  },
  cantFetchConnections: {
    id: `${scope}.cantFetchConnections`,
    defaultMessage:
      'Unable to fetch connections for the specified entity type.',
  },
  licenseWbLimitTitle: {
    id: `${scope}.licenseWbLimitTitle`,
    defaultMessage: "Some entities weren't added",
  },

  licenseWbLimitContent: {
    id: `${scope}.licenseWbLimitTitle`,
    defaultMessage:
      'Maximum allowed workbench entity count of {count} was exceeded. Please upgrade your account to extend the limits.',
  },

  noEntitiesFound: {
    id: `${scope}.noEntitiesFound`,
    defaultMessage: 'No Entities found',
  },
  noEntitiesFoundHint: {
    id: `${scope}.noEntitiesFoundHint`,
    defaultMessage: 'Try selecting different search query',
  },
});
