import { defineMessages } from 'react-intl';

export const scope = 'components.ThresholdHealthForm';

export default defineMessages({
  clearSelected: {
    id: `${scope}.clearSelected`,
    defaultMessage: 'Clear selected',
  },
  selectRules: {
    id: `${scope}.selectRules`,
    defaultMessage: 'Select rules to edit',
  },
  ruleName: {
    id: `${scope}.ruleName`,
    defaultMessage: 'Select rules to edit',
  },
  group: {
    id: `${scope}.group`,
    defaultMessage: 'Group',
  },
  rules: {
    id: `${scope}.rules`,
    defaultMessage: 'Rules',
  },
  requestType: {
    id: `${scope}.requestType`,
    defaultMessage: 'Request Type',
  },
  requestContext: {
    id: `${scope}.requestContext`,
    defaultMessage: 'Request Context',
  },
  value: {
    id: `${scope}.value`,
    defaultMessage: 'Value',
  },
  otherLabels: {
    id: `${scope}.otherLabels`,
    defaultMessage: 'Other Labels',
  },
  errorType: {
    id: `${scope}.errorType`,
    defaultMessage: 'Error Type',
  },
});
