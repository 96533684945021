import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  Placement,
  safePolygon,
} from '@floating-ui/react';
import React, { ReactNode, useCallback, useRef, useState } from 'react';

import { GrafanaTheme2, colorManipulator } from '@grafana/data';

import { Portal, useStyles2 } from '@grafana/ui';
import { css } from '@emotion/css';

export interface TooltipProps {
  theme?: 'info' | 'error' | 'info-alt';
  show?: boolean;
  placement?: Placement;
  content: ReactNode;
  children: React.JSX.Element;
  /**
   * Set to true if you want the tooltip to stay long enough so the user can move mouse over content to select text or click a link
   */
  interactive?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  className?: string;
  disableArrow?: boolean;
  enterDelay?: number;
  leaveDelay?: number;
}

export const Tooltip = React.forwardRef<HTMLElement, TooltipProps>(
  (
    {
      children,
      theme,
      interactive,
      show,
      placement,
      content,
      onClose,
      onOpen,
      className,
      disableArrow,
      enterDelay,
      leaveDelay,
    },
    forwardedRef
  ) => {
    const arrowRef = useRef(null);
    const [controlledVisible, setControlledVisible] = useState(show);
    const isOpen = show ?? controlledVisible;

    // the order of middleware is important!
    // `arrow` should almost always be at the end
    // see https://floating-ui.com/docs/arrow#order
    const middleware = [
      offset(8),
      flip({
        fallbackAxisSideDirection: 'end',
        // see https://floating-ui.com/docs/flip#combining-with-shift
        crossAxis: false,
        boundary: document.body,
      }),
      shift(),
      arrow({
        element: arrowRef,
      }),
    ];

    const { context, refs, floatingStyles } = useFloating({
      open: isOpen,
      placement,
      onOpenChange: (open) => {
        setControlledVisible(open);
        open ? onOpen?.() : onClose?.();
      },
      middleware,
      whileElementsMounted: autoUpdate,
    });

    const hover = useHover(context, {
      delay: {
        open: enterDelay ?? 0,
        close: leaveDelay ?? 0,
      },
      handleClose: interactive ? safePolygon({ blockPointerEvents: true }) : undefined,
      move: false,
    });
    const focus = useFocus(context);
    const dismiss = useDismiss(context);

    const { getReferenceProps, getFloatingProps } = useInteractions([dismiss, hover, focus]);

    const styles = useStyles2(getStyles);
    const style = styles[theme ?? 'info'];

    const handleRef = useCallback(
      (ref: HTMLElement | null) => {
        refs.setReference(ref);

        if (typeof forwardedRef === 'function') {
          forwardedRef(ref);
        } else if (forwardedRef) {
          forwardedRef.current = ref;
        }
      },
      [forwardedRef, refs]
    );

    return (
      <>
        {React.cloneElement(children, {
          ref: handleRef,
          tabIndex: 0, // tooltip trigger should be keyboard focusable
          ...getReferenceProps(),
        })}
        {isOpen && (
          <Portal>
            <div ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()} className={className}>
              {!disableArrow && <FloatingArrow className={style.arrow} ref={arrowRef} context={context} />}
              <div role="tooltip" className={style.container}>
                {content}
              </div>
            </div>
          </Portal>
        )}
      </>
    );
  }
);

Tooltip.displayName = 'Tooltip';


const buildTooltipTheme = (
  theme: GrafanaTheme2,
  tooltipBg: string,
  toggletipBorder: string,
  tooltipText: string,
  tooltipPadding: { topBottom: number; rightLeft: number }
) => {
  return {
    arrow: css({
      fill: tooltipBg,
    }),
    container: css({
      backgroundColor: tooltipBg,
      borderRadius: theme.shape.radius.default,
      border: `1px solid ${toggletipBorder}`,
      boxShadow: theme.shadows.z2,
      color: tooltipText,
      fontSize: theme.typography.bodySmall.fontSize,
      padding: theme.spacing(tooltipPadding.topBottom, tooltipPadding.rightLeft),
      transition: 'opacity 0.3s',
      zIndex: theme.zIndex.tooltip,
      maxWidth: '400px',
      overflowWrap: 'break-word',

      "&[data-popper-interactive='false']": {
        pointerEvents: 'none',
      },

      code: {
        border: 'none',
        display: 'inline',
        background: colorManipulator.darken(tooltipBg, 0.1),
        color: tooltipText,
        whiteSpace: 'normal',
      },

      pre: {
        background: colorManipulator.darken(tooltipBg, 0.1),
        color: tooltipText,
      },

      a: {
        color: tooltipText,
        textDecoration: 'underline',
      },
      'a:hover': {
        textDecoration: 'none',
      },
    }),
    headerClose: css({
      color: theme.colors.text.secondary,
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1.5),
      backgroundColor: 'transparent',
      border: 0,
    }),
    header: css({
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    }),
    body: css({
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    }),
    footer: css({
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    }),
  };
};
  

export const getStyles = (theme: GrafanaTheme2) => {
  const info = buildTooltipTheme(
    theme,
    theme.components.tooltip.background,
    theme.components.tooltip.background,
    theme.components.tooltip.text,
    { topBottom: 0.5, rightLeft: 1 }
  );
  const error = buildTooltipTheme(
    theme,
    theme.colors.error.main,
    theme.colors.error.main,
    theme.colors.error.contrastText,
    { topBottom: 0.5, rightLeft: 1 }
  );

  return {
    info,
    ['info-alt']: info,
    error,
  };
};
