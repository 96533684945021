/**
 *
 * AssertionsTree
 *
 */
import React, { memo, FunctionComponent, useRef, useEffect } from 'react';
import G6, { G6GraphEvent, TreeGraph, IShape } from '@antv/g6';

import tailwindColors from 'tailwindcss/colors';

import { processAssertionsRollupRecursive } from '../../features/Assertions/Assertions.helpers';
import useWindowSize from '../../hooks/useWindowSize';
import { connect, ConnectedProps } from 'react-redux';

import ResizableBox from '../ResizableBox/ResizableBox.component';
import {
  setExpandedItems,
  setHighlightedItemId,
  setStoredCollapsedItems,
} from '../../features/Assertions/Assertions.slice';
import useDidUpdateEffect from '../../hooks/useDidUpdate';
import { assertsColors } from '../../../src/app/constants';
import { ShapeAttrs } from '@antv/g-base';
import { sortBy } from 'lodash';
import { getIconCodeByType } from '../../helpers/Icon.helper';
import { AssertionRollup, AssertionRollupProcessed, AssertionsBoardEntity } from 'asserts-types';
import { useTheme2 } from '@grafana/ui';

const connector = connect(
  (state: RootState) => ({
    envColorsMap: state.app.envColorsMap,
    showEnvSiteTag: state.app.showEnvSiteTag,
    storedCollapsedItems: state.assertions.storedCollapsedItems,
  }),
  {
    setExpandedItems,
    setHighlightedItemId,
    setStoredCollapsedItems,
  }
);
const NODE_WIDTH = 202;
const LEVEL_VERTICAL_GAP = 70;
const ROW_HEIGHT = 15;

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps {
  show: boolean;
  hideResizer?: boolean;
  onLinkClick?: (boardEntity?: AssertionsBoardEntity) => void;
  data: AssertionRollup[] | undefined;
}

const processData = (data: AssertionRollup[]): AssertionRollupProcessed => ({
  id: 'assertions-root',
  children: processAssertionsRollupRecursive(data, 2),
  labels: {},
  collapsed: false,
  assertionCount: data.map((item) => item.assertionCount).reduce((a = 0, b = 0) => a + b, 0),
  warningCount: data.map((item) => item.warningCount).reduce((a = 0, b = 0) => a + b, 0),
  infoCount: data.map((item) => item.infoCount).reduce((a = 0, b = 0) => a + b, 0),
  criticalCount: data.map((item) => item.criticalCount).reduce((a = 0, b = 0) => a + b, 0),
  name: 'Assertions',
  level: 1,
});

const AssertionsTree: FunctionComponent<PropsFromRedux & IProps> = ({
  setExpandedItems,
  setHighlightedItemId,
  show,
  hideResizer,
  envColorsMap,
  showEnvSiteTag,
  onLinkClick,
  storedCollapsedItems,
  setStoredCollapsedItems,
  data,
}) => {
  const treeData = processData(data || []);
  const { width, height } = useWindowSize();
  const ref = useRef<HTMLDivElement>(null);
  const treeRef = useRef<TreeGraph>();
  const theme = useTheme2();

  const minimap = new G6.Minimap({
    size: [150, 100],
  });

  const defaultConfig = {
    width: 1000,
    height: 500,
    maxZoom: 1.5,
    linkCenter: true,
    // minZoom: 0.8,
    modes: {
      default: ['zoom-canvas', 'drag-canvas'],
    },
    // fitView: true,
    animate: true,
    defaultNode: {
      type: 'flow-rect',
      style: { envColorsMap },
    },
    defaultEdge: {
      type: 'flow-line',
      style: {
        stroke: tailwindColors.gray[300],
      },
    },

    layout: {
      type: 'compactBox',
      direction: 'TB',
      rankSep: 100,
      getWidth: () => 100,
      getHeight: () => 10,
      getHGap: () => 80,
      getVGap: () => LEVEL_VERTICAL_GAP,
    },

    plugins: [minimap],
  };

  const registerFn = () => {
    G6.registerEdge('flow-line', {
      draw(cfg, group) {
        //@ts-ignore
        const startPoint = cfg.startPoint;
        //@ts-ignore
        const endPoint = cfg.endPoint;
        //@ts-ignore
        const { style } = cfg;

        //@ts-ignore
        const shape = group.addShape('path', {
          attrs: {
            stroke: style.stroke,
            endArrow: style.endArrow,
            path: [
              //@ts-ignore
              ['M', startPoint.x, startPoint.y],
              //@ts-ignore
              ['L', startPoint.x, startPoint.y + LEVEL_VERTICAL_GAP],
              //@ts-ignore
              ['L', endPoint.x, startPoint.y + LEVEL_VERTICAL_GAP],
              //@ts-ignore
              ['L', endPoint.x, endPoint.y],
            ],
          },
        });
        return shape;
      },
    });

    G6.registerNode(
      'flow-rect',
      {
        shapeType: 'flow-rect',
        draw(cfg, group) {
          const nodeModel = cfg as unknown as AssertionRollupProcessed;
          const { name = '', collapsed } = nodeModel;

          const gropedByScope =
            nodeModel.mergedChildren?.reduce((group, child) => {
              let key = `${child.scope?.env || ''}${child.scope?.site || ''}`;
              if (group[key]) {
                group[key].push(child);
              } else {
                group[key] = [child];
              }
              return group;
            }, {} as Record<string, AssertionRollupProcessed[]>) || {};

          let countRows = Object.values(gropedByScope).reduce(
            (count, child) => count + child.length + (showEnvSiteTag ? 1.5 : 0),
            0
          );
          countRows -= showEnvSiteTag ? 0.5 : 0; // need decrease height if grouped
          const rectConfig = {
            width: NODE_WIDTH,
            height: nodeModel.mergedChildren?.length ? 40 + countRows * ROW_HEIGHT : 100,
            lineWidth: 1,
            fontSize: 12,
            fill: theme.colors.background.primary,
            radius: 2,
            stroke: tailwindColors.gray[300],
            opacity: 1,
          };

          const nodeOrigin = {
            x: 0,
            y: 0,
          };

          const textConfig: ShapeAttrs = {
            textAlign: 'left',
            textBaseline: 'bottom',
          };

          if (!group || !cfg) {
            return {} as IShape;
          }

          const rect = group.addShape('rect', {
            attrs: {
              x: 0,
              y: 0,
              ...rectConfig,
            },
          });

          if (nodeModel.level <= 2) {
            Object.keys(assertsColors).forEach((key, index) => {
              group.addShape('circle', {
                attrs: {
                  r: 3,
                  fill: assertsColors[key as keyof typeof assertsColors],
                  x: 15 + nodeOrigin.x,
                  y: 19 + (index + 1) * 15 + nodeOrigin.y,
                },
              });
              group.addShape('text', {
                attrs: {
                  ...textConfig,
                  x: 25 + nodeOrigin.x,
                  y: 25 + (index + 1) * 15 + nodeOrigin.y,
                  text: key,
                  fontSize: 12,
                  opacity: 0.85,
                  fill: theme.colors.text.primary,
                },
              });
              group.addShape('text', {
                attrs: {
                  x: rectConfig.width - 10,
                  y: 35 + nodeOrigin.y + 15 * index,
                  fontSize: 11,
                  fill: assertsColors[key as keyof typeof assertsColors],
                  text: cfg[`${key}Count`] || 0,
                  textAlign: 'right',
                  textBaseline: 'middle',
                },
              });
            });
          }
          let leftOffset = 0;

          Object.keys(assertsColors).forEach((key) => {
            let count = 0;
            if (key === 'critical') {
              count = nodeModel.criticalCount;
            }
            if (key === 'info') {
              count = nodeModel.infoCount;
            }
            if (key === 'warning') {
              count = nodeModel.warningCount;
            }
            const width = Math.round((count * rectConfig.width) / nodeModel.assertionCount);

            if (nodeModel.assertionCount) {
              group.addShape('rect', {
                attrs: {
                  fill: assertsColors[key as keyof typeof assertsColors],
                  x: nodeOrigin.x + leftOffset,
                  y: rectConfig.height - 5,
                  height: 5,
                  width,
                },
              });
            }
            leftOffset += width;
          });

          // label title
          group.addShape('text', {
            attrs: {
              ...textConfig,
              x: 12 + nodeOrigin.x,
              y: 20 + nodeOrigin.y,
              text: name.length > 20 ? name.slice(0, 20) + '...' : name,
              fontSize: 12,
              fontWeight: 'bold',
              opacity: 0.85,
              fill: theme.colors.text.primary,
            },
            name: 'name-shape',
          });
          const parent = (treeRef.current?.save() || []).children //@ts-ignore
            .find((child: AssertionRollupProcessed) => child.children.find((c) => c.id === cfg.id));
          const iconCode = getIconCodeByType(nodeModel.name) || getIconCodeByType(parent?.name || '');

          if (iconCode) {
            group.addShape('text', {
              name: 'group-icon',
              attrs: {
                x: rectConfig.width - 15,
                y: nodeOrigin.y + 15,
                id: 'group-icon',
                fontSize: 18,
                fill: theme.colors.primary.main,
                text: String.fromCharCode(iconCode),
                fontFamily: 'icomoon',
                textAlign: 'center',
                textBaseline: 'middle',
              },
            });
          }
          let rowNumber = 0;
          if (nodeModel.mergedChildren?.length) {
            sortBy(
              Object.entries(gropedByScope).map(([scopeKey, children]) => ({
                scopeKey,
                children,
              })),
              'scopeKey'
            ).forEach(({ scopeKey, children }, index) => {
              let paddingLeft = 15;
              let child = children[0];
              const circleColor =
                child.scope?.env && cfg.style?.envColorsMap
                  ? cfg.style.envColorsMap[child.scope.env]
                  : theme.colors.text.secondary;
              if (child.scope && showEnvSiteTag && scopeKey) {
                rowNumber += index === 0 ? 1 : 1.5;
                group.addShape('text', {
                  attrs: {
                    x: paddingLeft + nodeOrigin.x - 3,
                    y: 25 + nodeOrigin.y + ROW_HEIGHT * rowNumber,
                    textAlign: 'left',
                    textBaseline: 'middle',
                    text: `${child.scope.env} ${child.scope.site ? `(${child.scope.site})` : ''} `,
                    fontSize: 12,
                    cursor: 'pointer',
                    fill: circleColor,
                  },
                  name: 'merged-item-env-text',
                  pathHashesToLinkedGroups: child.pathHashesToLinkedGroups,
                  timelineHashes: child.timelineHashes,
                });
              }

              children.forEach((child) => {
                let boardEntity: AssertionsBoardEntity | undefined;
                if (child.entityType && child.scope) {
                  boardEntity = {
                    name: child.name,
                    type: child.entityType,
                    scope: child.scope,
                  };
                }

                rowNumber++;
                group.addShape('circle', {
                  attrs: {
                    x: paddingLeft + nodeOrigin.x,
                    y: 25 + nodeOrigin.y + ROW_HEIGHT * rowNumber,
                    textAlign: 'left',
                    textBaseline: 'middle',
                    r: 2,
                    fill: theme.colors.text.secondary,
                  },
                  name: 'merged-item-text',
                  pathHashesToLinkedGroups: child.pathHashesToLinkedGroups,
                  timelineHashes: child.timelineHashes,
                  itemIndex: rowNumber,
                  boardEntity,
                });
                group.addShape('text', {
                  attrs: {
                    x: paddingLeft + 5 + nodeOrigin.x,
                    y: 25 + nodeOrigin.y + ROW_HEIGHT * rowNumber,
                    textAlign: 'left',
                    textBaseline: 'middle',
                    text: child.name.length > 20 ? child.name.slice(0, 20) + '...' : child.name,
                    fontSize: 12,
                    cursor: 'pointer',
                    fill: theme.colors.text.secondary,
                  },
                  name: 'merged-item-text',
                  pathHashesToLinkedGroups: child.pathHashesToLinkedGroups,
                  timelineHashes: child.timelineHashes,
                  itemIndex: rowNumber,
                  boardEntity,
                });

                group.addShape('rect', {
                  attrs: {
                    x: paddingLeft + 5 + nodeOrigin.x,
                    y: nodeOrigin.y + ROW_HEIGHT * rowNumber - 5,
                    opacity: 0,
                    fill: theme.colors.background.primary,
                    width: 0,
                    height: 20,
                    stroke: tailwindColors.gray[300],
                    radius: 2,
                  },
                  name: 'merged-item-text-title-bg',
                  titleBgIndex: rowNumber,
                });

                group.addShape('text', {
                  attrs: {
                    x: paddingLeft + 10 + nodeOrigin.x,
                    y: 5 + nodeOrigin.y + ROW_HEIGHT * rowNumber,
                    textAlign: 'left',
                    textBaseline: 'middle',
                    text: '',
                    fontSize: 12,
                    cursor: 'pointer',
                    opacity: 1,
                    fill: theme.colors.text.secondary,
                  },
                  hoverText: child.name,
                  name: 'merged-item-text-title',
                  titleIndex: rowNumber,
                });

                if (child.assertionCount) {
                  let prevElementX = 0;
                  Object.keys(assertsColors)
                    .reverse()
                    .forEach((key) => {
                      let count = 0;
                      if (key === 'critical') {
                        count = child.criticalCount;
                      }
                      if (key === 'info') {
                        count = child.infoCount;
                      }
                      if (key === 'warning') {
                        count = child.warningCount;
                      }

                      if (count) {
                        const el = group.addShape('text', {
                          name: 'merged-item-text-count',
                          countIndex: rowNumber,
                          attrs: {
                            x: prevElementX ? prevElementX - 5 : rectConfig.width - 10,
                            y: 25 + nodeOrigin.y + ROW_HEIGHT * rowNumber,
                            fontSize: 12,
                            fill: assertsColors[key as keyof typeof assertsColors],
                            text: count,
                            textAlign: 'right',
                            textBaseline: 'middle',
                          },
                        });
                        prevElementX = el.getBBox().x;
                      }
                    });
                }
              });
            });
            group.addShape('text', {
              name: 'merged-item-text-icon',
              modelId: cfg.id,
              attrs: {
                id: 'node-icon',
                fontSize: 16,
                fill: theme.colors.primary.main,
                text: String.fromCharCode(parseInt(`0xef71`, 16)),
                fontFamily: 'icomoon',
                textAlign: 'center',
                textBaseline: 'middle',
                cursor: 'pointer',
                opacity: 0,
              },
            });
          }

          // collapse rect
          if (nodeModel.children && nodeModel.children.length) {
            group.addShape('rect', {
              attrs: {
                x: rectConfig.width / 2 - 8,
                y: rectConfig.height - 8,
                width: 16,
                height: 16,
                radius: 2,
                stroke: tailwindColors.gray[300],
                cursor: 'pointer',
                fill: theme.colors.background.primary,
              },
              name: 'collapse-back',
              modelId: cfg.id,
            });

            // collpase text
            group.addShape('text', {
              attrs: {
                x: rectConfig.width / 2,
                y: rectConfig.height - 1,
                textAlign: 'center',
                textBaseline: 'middle',
                text: collapsed ? '+' : '–',
                fontSize: 16,
                cursor: 'pointer',
                fill: theme.colors.primary.main,
              },
              name: 'collapse-text',
              modelId: cfg.id,
            });
          }

          return rect;
        },
        afterDraw: (cfg, group) => {
          const textItems = group?.findAll((element) => element.get('name') === 'merged-item-text');
          const linkIcon = group?.find((element) => element.get('name') === 'merged-item-text-icon');

          if (textItems) {
            textItems.forEach((item) => {
              const countText = group?.findAll((el) => el.get('countIndex') === item.get('itemIndex'));
              const title = group?.find((el) => el.get('titleIndex') === item.get('itemIndex'));
              const titleBg = group?.find((el) => el.get('titleBgIndex') === item.get('itemIndex'));
              item.on('mouseenter', () => {
                countText?.map((ct) => ct.attr('opacity', 0));
                title?.attr('text', title.get('hoverText'));
                titleBg?.attr('opacity', 1);
                const titleBBox = title?.getBBox();
                titleBg?.attr('width', titleBBox.width + 15);
                item.attr('fill', theme.colors.text.primary);
                linkIcon?.attr('opacity', 1);
                linkIcon?.attr('x', NODE_WIDTH - 15);
                linkIcon?.attr('y', item.attr('y'));
                treeRef.current?.get('canvas').draw();
              });

              item.on('mouseleave', () => {
                item.attr('fill', theme.colors.text.secondary);
                title?.attr('text', '');
                titleBg?.attr('opacity', 0);
                titleBg?.attr('width', 0);
                linkIcon?.attr('opacity', 0);
                countText?.map((ct) => ct.attr('opacity', 1));
                treeRef.current?.get('canvas').draw();
              });
            });
          }
        },
      },
      'rect'
    );
  };

  const initGraph = () => {
    if (!ref.current) {
      return;
    }
    if (treeRef.current) {
      treeRef.current.destroy();
    }
    treeRef.current = new G6.TreeGraph({
      container: ref.current,
      ...defaultConfig,
      width: ref.current.scrollWidth,
      height: ref.current.scrollHeight,
    });

    if (Object.keys(storedCollapsedItems).length) {
      treeData.children.forEach((item) => {
        item.collapsed = storedCollapsedItems[item.name] === undefined ? true : storedCollapsedItems[item.name];
      });
    }

    treeRef.current.data(treeData);
    treeRef.current.render();
    treeRef.current.zoom(1);
    treeRef.current?.fitCenter();
    treeRef.current?.translate(0, -80);

    const handleCollapse = (e: G6GraphEvent) => {
      if (!treeRef.current) {
        return;
      }
      const target = e.target;
      const id = target.get('modelId');
      const item = treeRef.current.findById(id);
      const nodeModel = item.getModel() as unknown as AssertionRollupProcessed;
      nodeModel.collapsed = !nodeModel.collapsed;
      treeRef.current.layout();

      setStoredCollapsedItems(
        Object.assign({}, storedCollapsedItems, {
          [nodeModel.name]: nodeModel.collapsed,
        })
      );

      const group = item.getContainer();
      const collapseText = group.find((e) => e.get('name') === 'collapse-text');
      if (collapseText) {
        collapseText.attr({
          text: nodeModel.collapsed ? '+' : '–',
        });
      }
    };

    const handleLinkClick = (e: G6GraphEvent) => {
      const target = e.target;
      const pathHashesToLinkedGroups: string[][] | undefined = target.get('pathHashesToLinkedGroups');
      const timelineHashes: string[] | undefined = target.get('timelineHashes');
      const boardEntity: AssertionsBoardEntity | undefined = target.get('boardEntity');

      if (pathHashesToLinkedGroups?.length) {
        onLinkClick && onLinkClick(boardEntity);
        let itemsToExpand = pathHashesToLinkedGroups.reduce((a, b) => a.concat(b), []);
        if (timelineHashes) {
          itemsToExpand = itemsToExpand.concat(timelineHashes);
        }
        setHighlightedItemId(
          timelineHashes?.[0] || pathHashesToLinkedGroups?.[0][pathHashesToLinkedGroups?.[0].length - 1]
        );
        setExpandedItems(itemsToExpand);
      }
    };

    treeRef.current.on('collapse-text:click', handleCollapse);
    treeRef.current.on('collapse-back:click', handleCollapse);
    treeRef.current.on('merged-item-text:click', handleLinkClick);
    treeRef.current.on('merged-item-text-icon:click', handleLinkClick);
  };

  useEffect(() => {
    registerFn();
    initGraph();
    //eslint-disable-next-line
  }, []);

  useDidUpdateEffect(() => {
    registerFn();
    initGraph();
  }, [theme, envColorsMap, showEnvSiteTag]);

  useDidUpdateEffect(() => {
    const newData = processData(data || []);

    if (Object.keys(storedCollapsedItems).length) {
      newData.children.forEach((item) => {
        item.collapsed = storedCollapsedItems[item.name] === undefined ? true : storedCollapsedItems[item.name];
      });
    }

    treeRef.current?.changeData(newData);
    treeRef.current?.zoom(1);
    treeRef.current?.fitCenter();
    treeRef.current?.translate(0, -80);
  }, [data]);

  useDidUpdateEffect(() => {
    if (ref.current && show) {
      treeRef.current?.changeSize(ref.current.clientWidth, ref.current.clientHeight);
      treeRef.current?.zoom(1);
      treeRef.current?.fitCenter();
      treeRef.current?.translate(0, -80);
    }
    //eslint-disable-next-line
  }, [width, height, show]);

  return !hideResizer ? (
    <ResizableBox
      style={{ display: show ? 'block' : 'none' }}
      className="h-[30px] bg-paper"
      type="flex"
      handle="top"
      minSize="10%"
      maxSize="75%"
      initialSize="50%"
      onResize={() => ref.current && treeRef.current?.changeSize(ref.current.clientWidth, ref.current.clientHeight)}
    >
      <div
        className="w-full h-full relative bg-canvas [&_.g6-minimap]:absolute [&_.g6-minimap]:bottom-[20px] [&_.g6-minimap]:left-[20px] [&_.g6-minimap]:bg-paper [&_.g6-minimap]:divider"
        ref={ref}
      />
    </ResizableBox>
  ) : (
    <div
      className="w-full h-full relative bg-canvas [&_.g6-minimap]:absolute [&_.g6-minimap]:bottom-[20px] [&_.g6-minimap]:left-[20px] [&_.g6-minimap]:bg-paper [&_.g6-minimap]:divider"
      ref={ref}
    />
  );
};

export default connector(memo(AssertionsTree));
