import { useQuery } from '@tanstack/react-query';
import { stringToDate } from 'helpers/Date.helper';
import { searchEntities } from 'services/Entity.service';
import { OpenInKubernetesTriggerProps } from '../OpenInKubernetesTrigger';
import { EntityPropertyTypes, StringRules } from 'asserts-types';

export function useKubeClusterByNamespace(
  entity: OpenInKubernetesTriggerProps['entity'] | undefined | null,
  start: string | number,
  end: string | number,
  enabled: boolean
) {
  return useQuery(
    ['cluster-by-namespace', entity, start, end],
    () =>
      searchEntities(
        [
          {
            entityType: entity?.type || '',
            connectToEntityTypes: ['KubeCluster'],
            propertyMatchers: [
              {
                id: -1,
                name: 'name',
                op: StringRules.EQUALS,
                value: entity?.name || '',
                type: EntityPropertyTypes.STRING,
              },
            ],
          },
        ],
        stringToDate(start).valueOf(),
        stringToDate(end).valueOf(),
        {
          nameAndValues: {
            env: entity?.scope?.env ? [entity?.scope?.env] : undefined,
            site: entity?.scope?.site ? [entity?.scope?.site] : undefined,
            namespace: entity?.scope?.namespace ? [entity?.scope?.namespace] : undefined,
          },
        }
      ).then((res) => res.data.entities.find((e) => e.type === 'KubeCluster') || null),
    {
      cacheTime: Infinity,
      staleTime: typeof start === 'string' ? 0 : Infinity,
      refetchOnMount: false,
      enabled: entity?.type === 'Namespace' && enabled,
      keepPreviousData: true,
    }
  );
}
