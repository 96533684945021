/**
 *
 * CustomModelRules
 *
 */

import React, { memo, FunctionComponent, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import CustomModelRulesListComponent from './components/CustomModelRulesList/CustomModelRulesList.component';
import YmlFileEditorComponent from '../../../../components/YmlFileEditor/YmlFileEditor.component';
import useSaveCustomModelRule from './hooks/useSaveCustomModelRule';
import useCustomModelRule from './hooks/useCustomModelRule';
import useDeleteCustomModelRule from './hooks/useDeleteCustomModelRule';
import { Button, useTheme2 } from '@grafana/ui';

interface IProps {}

const CustomModelRules: FunctionComponent<IProps> = () => {
  const { formatMessage } = useIntl();

  const [showFileEditor, setShowFileEditor] = useState(false);
  const [activeFileName, setActiveFile] = useState<string>();

  const {
    data: activeFileText,
    isFetching: isFetchingRule,
    remove: clearCustomModelRuleCache,
  } = useCustomModelRule(activeFileName);

  const handleEditorClose = () => {
    setShowFileEditor(false);
    setActiveFile(undefined);
    clearCustomModelRuleCache();
  };

  const { mutateAsync: handleSaveFile, isLoading: savingFile } = useSaveCustomModelRule({
    onSuccess: handleEditorClose,
  });

  const { isFetching: fetchingRule } = useCustomModelRule(activeFileName);

  const { mutateAsync: handleDeleteFileItem, isLoading: deletingRule } = useDeleteCustomModelRule();

  const handleEditClick = (fileName: string) => {
    setShowFileEditor(true);
    setActiveFile(fileName);
  };

  const handleAddClick = () => {
    setShowFileEditor(true);
    setActiveFile(undefined);
  };

  const handleDeleteClick = (fileName: string) => {
    handleDeleteFileItem(fileName);
    if (fileName === activeFileName) {
      setActiveFile(undefined);
      setShowFileEditor(false);
    }
  };

  const theme = useTheme2();

  return (
    <div>
      <div className="flex items-center justify-between">
        <h1 style={{ fontSize: theme.typography.h1.fontSize }} className="m-0">
          Custom Model Rules
        </h1>
        <Button icon="plus" onClick={handleAddClick}>
          {formatMessage(messages.addNew)}
        </Button>
      </div>
      <div className="mt-8">
        <CustomModelRulesListComponent
          onEditClick={handleEditClick}
          onDeleteClick={handleDeleteClick}
          fetchingRule={fetchingRule}
          deletingRule={deletingRule}
        />
      </div>
      {showFileEditor && (
        <YmlFileEditorComponent
          onClose={handleEditorClose}
          text={activeFileText || ''}
          onSubmit={(data) => handleSaveFile(data.textField)}
          saving={savingFile || isFetchingRule}
        />
      )}
    </div>
  );
};

export default memo(CustomModelRules);
