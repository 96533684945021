/*
 * SloIncidents Messages
 *
 * This contains all the text for the SloIncidents component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.SloIncidents';

export default defineMessages({
  empty: {
    id: `${scope}.empty`,
    defaultMessage: 'List of Incidents is empty',
  },
  changeTime: {
    id: `${scope}.changeTime`,
    defaultMessage: 'Change time range and try again',
  },
  noResults: {
    id: `${scope}.noResults`,
    defaultMessage: 'No results for',
  },
});
