/*
 * EntityContextMenu Messages
 *
 * This contains all the text for the EntityContextMenu component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.EntityContextMenu';

export default defineMessages({
  connected: {
    id: `${scope}.connected`,
    defaultMessage: 'Connected',
  },
  showConnectedAssertions: {
    id: `${scope}.showConnectedAssertions`,
    defaultMessage: 'Show assertions',
  },
});
