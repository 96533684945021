import { useQuery } from "@tanstack/react-query";
import { fetchCustomModelRule } from "services/ManageAssertions.service";

export const USE_CUSTOM_MODEL_RULE_QUERY_KEY = 'custom-model-rule';

export default function useCustomModelRule(ruleName?: string) {
  return useQuery([USE_CUSTOM_MODEL_RULE_QUERY_KEY, ruleName], () => fetchCustomModelRule(ruleName || ''), {
    enabled: Boolean(ruleName),
  });
}
