export const DEFAULT_CHART_PADDING = 30;

export const CHART_COLORS_MAP = {
  lightTheme: {
    lines: [
      '#7EB26D',
      '#CCA300',
      '#6ED0E0',
      '#BA43A9',
      '#1F78C1',
      '#EAB839',
      '#705DA0',
    ],
    thresholds: {
      minmax: ['#EFC7A4', '#C5B7A1', '#CAD0D7'],
      single: ['#FFB000', '#FF5151'],
    },
  },
};

export const CHART_GEOMETRY_THEME = {
  geometries: {
    area: { area: { active: { style: { fillOpacity: 1 } } } },
  },
};


export const CHART_CROSSHAIRS_STYLE = {
  shared: true,
  crosshairs: {
    line: { style: { lineWidth: 1, stroke: '#b7323b' } },
  },
  marker: { stroke: 0, r: 3 },
};
