/**
 *
 * ThresholdItemListItem
 *
 */

import React, {
  memo,
  FunctionComponent,
  useState,
  useEffect,
  useRef,
} from 'react';
import EditIcon from 'assets/material-icons/edit_FILL0_wght400_GRAD0_opsz24.svg';
import CheckIcon from 'assets/material-icons/done_FILL0_wght400_GRAD0_opsz24.svg';
import CloseIcon from 'assets/material-icons/close_FILL0_wght400_GRAD0_opsz24.svg';
import DeleteOutlineIcon from 'assets/material-icons/delete_FILL0_wght400_GRAD0_opsz24.svg';
import { NotificationConfig } from 'asserts-types';
import NotificationRequestFormComponent from '../NotificationRequest/components/NotificationRequestForm/NotificationRequestForm.component';
import NotificationResourceForm from '../NotificationResource/components/NotificationResourceForm/NotificationResourceForm.component';
import NotificationHealthForm from '../NotificationHealth/components/NotificationHealthForm/NotificationHealthForm.component';
import { connect, ConnectedProps } from 'react-redux';

import { setItemToPopulate } from '../../ManageAssertions.slice';
import has from '../../../../helpers/has.helper';
import { deleteNotification, deleteSuppress, saveNotification } from 'services/ManageAssertions.service';
import TooltipConfirmationComponent from 'features/Assertions/components/TooltipConfirmation/TooltipConfirmation.component';
import { Switch } from '@grafana/ui';
import { IconButton } from 'components/IconButton/IconButton.component';
import NotificationsSloForm from '../NotificationsSlo/components/NotificationsSloForm/NotificationsSloForm.component';

interface IProps {
  item: NotificationConfig;
  fields: string[];
  onUpdate: (item: NotificationConfig) => void;
  onDelete?: (item: NotificationConfig) => void;
  suppress?: boolean;
}

const connector = connect(
  (state: RootState) => ({
    itemToPopulate: state.manageAssertions.itemToPopulate,
  }),
  { setItemToPopulate },
);

type PropsFromRedux = ConnectedProps<typeof connector>;

const isFieldFromMatchLabels = (
  item: NotificationConfig,
  field: string,
): field is keyof NotificationConfig['matchLabels'] =>
  item.matchLabels.hasOwnProperty(field);

const isFieldFromAlertLabels = (
  item: NotificationConfig,
  field: string,
): field is keyof NotificationConfig['alertLabels'] =>
  Boolean(item.alertLabels?.hasOwnProperty(field));

const NotificationListItem: FunctionComponent<IProps & PropsFromRedux> = ({
  item,
  onUpdate,
  onDelete,
  fields,
  suppress,
  itemToPopulate,
}) => {
  const [editing, setEditing] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [active, setActive] = useState(false);
  const ref = useRef<HTMLTableRowElement>(null);

  useEffect(() => {
    if (itemToPopulate && itemToPopulate.notificationRuleName === item.name) {
      ref.current?.scrollIntoView();
      setEditing(true);
      setActive(true);
      setItemToPopulate(undefined);
      setTimeout(() => setActive(false), 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, item, itemToPopulate]);

  const handleDelete = () => {
    setUpdating(true);
    (suppress
      ? deleteSuppress(item.name)
      : deleteNotification(item.name)
    ).then(() => {
      onDelete?.(item);
      setEditing(false);
      setUpdating(false);
    });
  };

  const handleSilence = () => {
    setUpdating(true);

    const updatedItem = {
      ...item,
      silenced: !item.silenced,
    };
    saveNotification(updatedItem).then(() => {
      onUpdate(updatedItem);
      setUpdating(false);
    });
  };

  if (editing && fields.includes('job')) {
    return (
      <NotificationRequestFormComponent
        active={active}
        onClose={() => setEditing(false)}
        onUpdate={onUpdate}
        tableRow
        preloadedValue={item}
        suppress={suppress}
      />
    );
  } else if (editing && fields.includes('container')) {
    return (
      <NotificationResourceForm
        active={active}
        onClose={() => setEditing(false)}
        onUpdate={onUpdate}
        tableRow
        preloadedValue={item}
        suppress={suppress}
      />
    );
  } else if(editing && window.location.href.includes('/slo')) {
    return (
      <NotificationsSloForm
        preloadedValue={item}
        onClose={() => setEditing(false)}
      />
    );
  } else if (editing) {
    return (
      <NotificationHealthForm
        active={active}
        onClose={() => setEditing(false)}
        onDelete={handleDelete}
        onUpdate={onUpdate}
        tableRow
        preloadedValue={item}
        suppress={suppress}
      />
    );
  }

  const getValue = (field: string) =>
    (isFieldFromMatchLabels(item, field) && item.matchLabels[field]) ||
    (isFieldFromAlertLabels(item, field) && item.alertLabels?.[field]) ||
    ((has(item, field) && item[field]) as string);

  return (
    <tr className="transition-all hover:bg-hover" ref={ref}>
      <td className="p-4 divider-b" title={item.name}>
        {item.name}
      </td>
      {fields.map((field) => (
        <td className="p-4 divider-b" key={field} title={getValue(field)}>
          {getValue(field)}
        </td>
      ))}
      {!suppress && (
        <td className="p-4 divider-b">
          <Switch value={!item.silenced} onClick={handleSilence} disabled={updating} />
        </td>
      )}
      <td className="p-4 divider-b">
        <div className="flex">
          {editing ? (
            <>
              <IconButton variant="primary" onClick={() => {}} disabled={updating}>
                <CheckIcon />
              </IconButton>
              <IconButton variant="primary" onClick={() => setEditing(false)} disabled={updating}>
                <CloseIcon />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton onClick={() => setEditing(true)} variant="primary" disabled={updating}>
                <EditIcon />
              </IconButton>
              {onDelete && (
                <TooltipConfirmationComponent action={handleDelete}>
                  <IconButton variant="destructive" disabled={updating}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </TooltipConfirmationComponent>
              )}
            </>
          )}
        </div>
      </td>
    </tr>
  );
};

export default connector(memo(NotificationListItem));
