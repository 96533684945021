import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { callConfig } from 'app/constants';
import {  MessageDescriptor } from 'react-intl';


export default {
  success(msg: MessageDescriptor | string, values?: Record<string, string | number>) {
    this.toast(msg, 'alertSuccess', values);
  },
  warning(msg: MessageDescriptor | string, values?: Record<string, string | number>) {
    this.toast(msg, 'alertWarning', values);
  },
  error(msg: MessageDescriptor | string, values?: Record<string, string | number>) {
    this.toast(msg, 'alertError', values);
  },
  toast(msg: MessageDescriptor | string, eventType: keyof typeof AppEvents, values?: Record<string, string | number>) {
    const messageToShow = typeof msg === 'string' ? msg : callConfig.call.intl?.formatMessage(msg, values);

    getAppEvents().publish({
      type: AppEvents[eventType].name,
      payload: [messageToShow],
    });
  },
};
