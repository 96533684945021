import { AssertionSummary, AssertionSummaryResProcessed, SummaryMetric, Metric } from 'asserts-types';
import { orderBy } from 'lodash';
import { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { areEntitiesEqual } from 'helpers/Entity.helper';
import { assertsColors } from 'app/constants';

interface IProps {
  currentDate: number | undefined;
  data: AssertionSummaryResProcessed | undefined;
}

export default function useSelectAssertionsSummary({ currentDate = 0, data }: IProps) {
  const showWithAssertions = useSelector((state: RootState) => state.assertions.showWithAssertions, shallowEqual);

  const sort = useSelector((state: RootState) => state.assertions.sort, shallowEqual);

  return useMemo(() => {
    const processSummaries = (metric: SummaryMetric): SummaryMetric => {
      const processedNestedSummaries = metric.nestedSummaries.map(processSummaries);

      let hovered =
        metric.nestedSummaries.length > 0 ? Boolean(processedNestedSummaries.find((s) => s.hovered)) : false;

      if (metric.nestedSummaries.length === 0) {
        for (let i = 0; i < metric.healthStates.length; i++) {
          const healthState = metric.healthStates[i];
          if (healthState.start <= currentDate && healthState.end >= currentDate) {
            hovered = true;
            break;
          }
        }
      }

      return {
        ...metric,
        hovered,
        nestedSummaries: processedNestedSummaries,
      };
    };

    let scoresMaxY = 0;

    data?.assertionScores.forEach((score) => {
      score.metrics.forEach((metric) => {
        metric.values.forEach((a) => {
          if (a.value && a.value > scoresMaxY) {
            scoresMaxY = a.value;
          }
        });
      });
    });

    // adding hovered field
    let processedAssertionsSummary: AssertionSummary[] | undefined = data?.summaries.map((summary) => {
      const processedTimelines = summary.timeLines.map(processSummaries);

      const scoreMetrics: Metric[] =
        data.assertionScores
          .find((s) => areEntitiesEqual(s, summary))
          ?.metrics.map((item) => ({
            name: item.metric.asserts_severity,
            values: item.values,
            color: assertsColors[item.metric.asserts_severity],
          })) || [];

      return {
        ...summary,
        timeLines: processedTimelines,
        hovered: Boolean(processedTimelines.find((t) => t.hovered)),
        scoreMetrics,
        scoresMaxY,
      };
    });

    // filtering out items without assertions if this option is enabled
    processedAssertionsSummary = processedAssertionsSummary?.filter((item) =>
      showWithAssertions ? item.timeLines.length : true
    );

    const orderByScore = (item: AssertionSummary) => {
      return data?.assertionScores.find((s) => areEntitiesEqual(s, item))?.totalScore || 0;
    };

    // sorting
    processedAssertionsSummary = orderBy(
      processedAssertionsSummary,
      sort.field === 'time' ? undefined : [orderByScore || '', 'name'],
      [sort.order, 'asc']
    );

    return processedAssertionsSummary;
  }, [data?.assertionScores, currentDate, showWithAssertions, sort, data?.summaries]);
}
