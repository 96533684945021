/**
 *
 * BubbleViewItem
 *
 */

import React, { FunctionComponent, useCallback, useMemo, useRef, useState, useEffect } from 'react';
import G6, { G6GraphEvent, Graph, LayoutConfig, Item } from '@antv/g6';
import { connect, ConnectedProps } from 'react-redux';

import { getStrokeColor } from 'components/GraphinGraph/components/AssertsNode';
import useDidUpdateEffect from 'hooks/useDidUpdate';
import useResizeObserver from 'use-resize-observer';
import { GraphCustomData, GraphCustomNode, AssertionSeverity, KpiSummary } from 'asserts-types';
import { stringToDate } from 'helpers/Date.helper';
import { setBubbleViewActiveNodeName } from '../../Entities.slice';

import generateContextMenu from 'components/GraphinGraph/graphContextMenu';
import { useIntl } from 'react-intl';
import messages from './messages';
import GraphHelper from 'helpers/Graph.helper';
import 'components/GraphinGraph/components/BubbleNode';
import BubbleViewItemTooltip from '../BubbleViewItemTooltip/BubbleViewItemTooltip.component';
import { DISABLED_STATE_BUBBLE_ITEM_OPACITY } from './constants';
import { formatLongNumber } from 'helpers/ValueFormat.helper';
import { kpiRegexp } from 'helpers/Entity.helper';
import { useGraphDataForBubbleView } from '../../hooks/useGraphDataForBubbleView';
import GraphTooltipComponent from 'components/GraphinGraph/components/GraphTooltip/GraphTooltip.component';
import useKpiConfig from 'hooks/useKpiConfig';
import { fetchKpiSummary } from 'services/Entity.service';
import CountGroupedEntitiesComponent from 'components/CountGroupedEntities/CountGroupedEntities.component';
import { LoadingBar, useTheme2 } from '@grafana/ui';
import { setActiveEntityDetails } from 'features/App/App.slice';
//@ts-ignore
import workerScript from '@antv/layout/dist/layout.min.js';

const MIN_BUBBLE_SIZE = 40;
const MAX_BUBBLE_SIZE = 200;
const LABEL_FONT_SIZE = 14;
const LETTER_WIDTH = 8;
const MAX_SIZE_WITH_TEXT = 45;

interface IProps {
  property: string;
  properties: string[];
  graphDataRaw: GraphCustomData;
}

const connector = connect(
  (state: RootState) => ({
    start: state.app.start,
    end: state.app.end,
    nameSearchQuery: state.entities.nameSearchQuery,
    bubbleViewActiveNodeName: state.entities.bubbleViewActiveNodeName,
  }),
  { setActiveEntityDetails, setBubbleViewActiveNodeName }
);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ellipsify = (str: string, maxWidth: number, fontSize: number) => {
  const ellipsis = '...';
  const ellipsisLength = G6.Util.getTextSize(ellipsis, fontSize)[0];
  let currentWidth = 0;
  let cutoffIndex = 0;
  let needsEllipsis = false;

  str.split('').forEach((letter, i) => {
    // if the line is short enough, we don't need to ellipsis
    if (currentWidth > maxWidth - ellipsisLength && cutoffIndex === 0) {
      cutoffIndex = i;
      return;
    }

    // get the width of single letter according to the fontSize
    currentWidth += G6.Util.getLetterWidth(letter, fontSize);
    if (currentWidth > maxWidth) {
      needsEllipsis = true;
    }
  });

  if (needsEllipsis && cutoffIndex) {
    return str.slice(0, cutoffIndex) + ellipsis;
  } else {
    return str;
  }
};

const BubbleViewItem: FunctionComponent<IProps & PropsFromRedux> = ({
  property,
  properties,
  start,
  end,
  setActiveEntityDetails,
  setBubbleViewActiveNodeName,
  bubbleViewActiveNodeName,
  graphDataRaw,
}) => {
  const intl = useIntl();
  const ref = useRef<HTMLDivElement>(null);
  const graphRef = useRef<Graph>();
  const theme = useTheme2();
  const isMounted = useRef(false);
  const titleRef = useRef<HTMLDivElement>(null);
  const [kpis, setKpis] = useState<Record<string, KpiSummary>>({});
  const [fetchingKpis, setFetchingKpis] = useState(false);
  const { data: kpiSettings } = useKpiConfig();

  const bubbleActiveNode = (graphRef.current?.getNodes() || []).find(
    (n) => n.getModel().fullLabel === bubbleViewActiveNodeName
  );

  useEffect(() => {
    if (bubbleViewActiveNodeName) {
      const graph = graphRef.current;
      graph?.setAutoPaint(false);
      graph?.getNodes().forEach((node) => {
        if (bubbleActiveNode?.getID() !== node.getID()) {
          graph?.updateItem(node, {
            style: {
              opacity: DISABLED_STATE_BUBBLE_ITEM_OPACITY,
            },
          });
        } else {
          graph?.updateItem(node, {
            style: {
              opacity: 1,
              stroke: theme.colors.primary.main,
              lineWidth: 2,
            },
          });
        }
      });
      graph?.paint();
      graph?.setAutoPaint(true);
    } else {
      const graph = graphRef.current;
      graph?.setAutoPaint(false);
      graph?.getNodes().forEach((node) => {
        graph?.updateItem(node, {
          style: { opacity: 1, stroke: undefined, lineWidth: 0 },
        });
      });
      graph?.paint();
      graph?.setAutoPaint(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bubbleViewActiveNodeName]);

  const {
    processedGraphData,
    graphData,
    activeFilter: typeEnvFilter,
    toggleActiveFilter: toggleTypeEnvFilter,
    setActiveFilter: setTypeEnvFilter,
  } = useGraphDataForBubbleView(graphDataRaw);

  const kpiTitle = useMemo(() => {
    let units = '';
    let name = property.replace(/_/g, ' ');
    kpiSettings?.kpiGroups.forEach((group) => {
      const kpi = group.kpis.find((item) => item.name === property);
      if (kpi) {
        name = kpi.displayName || name;
        units = kpi.unit ? `(${kpi.unit})` : '';
      }
    });
    return `${name} ${units}`;
  }, [kpiSettings, property]);

  const kpiUnit = useMemo(() => {
    let unit = '';
    kpiSettings?.kpiGroups.forEach((group) => {
      const kpi = group.kpis.find((item) => item.name === property);
      if (kpi) {
        unit = kpi.unit || '';
      }
    });
    return unit;
  }, [kpiSettings, property]);

  const handleViewportChange = useCallback(() => {
    if (!graphRef.current) {
      return;
    }
    const currentZoom = graphRef.current.getZoom();
    const fontSize = LABEL_FONT_SIZE / currentZoom;

    graphRef.current?.getNodes()?.forEach((item) => {
      const model = item.getModel() as GraphCustomNode;

      if (!model.size || typeof model.size !== 'number') {
        return;
      }
      const circleSize = currentZoom * model.size;

      let label = ellipsify(model.fullLabel || '', circleSize, LABEL_FONT_SIZE);

      let valueLabel = ellipsify(model.valueLabel || '', circleSize * 0.7, LABEL_FONT_SIZE);

      if (circleSize <= MAX_SIZE_WITH_TEXT) {
        label = '';
        valueLabel = '';
        // mark circle with darker color if it's small and no severity
        if (!model.assertion?.severity) {
          graphRef.current?.updateItem(item, {
            style: { iconColor: '#6a6b6d' },
          });
        }
      } else {
        graphRef.current?.updateItem(item, {
          style: { fill: getStrokeColor(model.assertion) },
        });
      }

      graphRef.current?.updateItem(item, {
        labelCfg: {
          style: {
            fontSize,
          },
        },
        label,
        valueLabel,
      });
    });
  }, []);

  const handleBeforeLayout = useCallback(() => {
    setBubbleViewActiveNodeName(undefined);
    if (Object.keys(kpis).length) {
      const canvas = ref.current?.querySelector('canvas');
      if (canvas) {
        canvas.style.visibility = 'hidden';
      }
      setFetchingKpis(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kpis]);

  const handleAfterLayout = useCallback(() => {
    if (Object.keys(kpis).length) {
      setFetchingKpis(false);
      graphRef.current?.fitView();
      const canvas = ref.current?.querySelector('canvas');
      if (canvas) {
        canvas.style.visibility = 'visible';
      }
    }
  }, [kpis]);

  const handleNodeClick = (e: G6GraphEvent) => {
    // need timeout because immediately fire onCloseHandler from context menu
    setTimeout(() => {
      graphRef.current?.emit('contextmenu', e);
    });
  };

  const handleNodeMouseEnter = (e: G6GraphEvent) => {
    setBubbleViewActiveNodeName(e.item.getModel().fullLabel as string);
  };

  const handleNodeMouseLeave = () => {
    setBubbleViewActiveNodeName(undefined);
  };

  const processData = (): GraphCustomNode[] => {
    const kpisKeys = Object.keys(kpis);
    const nodes = processedGraphData.nodes.map((node) => {
      const reg = kpiRegexp(node.label, node.scope);
      const kpiKey = kpisKeys.find((key) => reg.test(key));

      if (kpiKey) {
        return {
          ...node,
          labelCfg: {
            style: {
              fontSize: LABEL_FONT_SIZE,
            },
          },
          properties: {
            ...node.properties,
            [property]: kpis?.[kpiKey].kpiValue || '',
          },
          assertion: {
            severity: kpis?.[kpiKey].assertionSummary?.severity as AssertionSeverity,
          },
        };
      }
      return node;
    });

    const values = nodes
      .filter((node) => node.properties[property])
      .map((node) =>
        typeof node.properties[property] !== 'undefined' ? parseFloat(node.properties[property]?.toString() || '0') : 0
      );

    const dataMin = Math.min(...values);
    const dataMax = Math.max(...values);
    const dataRangeLength = dataMax - dataMin;
    const nodeSizeLength = MAX_BUBBLE_SIZE - MIN_BUBBLE_SIZE;

    return nodes
      .filter((item) => item.properties[property])
      .map((node) => {
        const value =
          typeof node.properties[property] !== 'undefined'
            ? parseFloat(node.properties[property]?.toString() || '0')
            : 0;

        let size = value;

        if (dataMax / dataMin > MAX_BUBBLE_SIZE / MIN_BUBBLE_SIZE) {
          size = dataRangeLength
            ? ((value - dataMin) * nodeSizeLength) / dataRangeLength + MIN_BUBBLE_SIZE || MIN_BUBBLE_SIZE
            : MAX_BUBBLE_SIZE;
        } else if (dataMax > MAX_BUBBLE_SIZE) {
          size = (value * MAX_BUBBLE_SIZE) / dataMax;
        } else if (dataMin < MIN_BUBBLE_SIZE) {
          size = (value * MIN_BUBBLE_SIZE) / dataMin;
        }

        const labelWidth = G6.Util.getTextSize(node.label, LABEL_FONT_SIZE)[0];

        let label = node.label;
        if (labelWidth > size) {
          label = node.label.slice(0, Math.round(size / LETTER_WIDTH));
        }
        if (size <= MAX_SIZE_WITH_TEXT) {
          label = '';
        }

        const valueLabel = `${formatLongNumber(property && node.properties[property])} ${kpiUnit}`;

        return {
          ...node,
          type: 'bubble-node',
          style: {
            fill: getStrokeColor(node.assertion),
            stroke: undefined,
          },
          fullLabel: node.label,
          size,
          label,
          cluster: 1,
          valueLabel,
        };
      });
  };

  const layoutCfg: LayoutConfig = {
    type: 'force',
    // nodeStrength: 1500,
    // collideStrength: 0.5,
    // alphaDecay: 0.01,
    preventOverlap: true,
    clusterNodeStrength: -5,
    clusterFociStrength: 1.2,
    workerEnabled: true,
    nodeSpacing: 5,
    clustering: true,
    workerScriptURL: `${window.location.origin}/${workerScript}`,
  };

  const initGraph = () => {
    if (!ref.current || !titleRef.current) {
      return;
    }
    if (graphRef.current) {
      graphRef.current.destroy();
    }

    const contextMenuOptions = [
      {
        id: 'showKpi',
        label: intl.formatMessage(messages.showKpi),
        onClick: (item: Item) => {
          const model = item.get('model') as GraphCustomNode;

          if (model) {
            setActiveEntityDetails({
              type: model.entityType,
              name: model.fullLabel || model.label,
              scope: model.scope,
              properties: model.properties,
            });
          }
        },
      },
      {
        id: 'addToWorkbench',
        label: intl.formatMessage(messages.addToBench),
        onClick: (item: Item) => {
          const entity = GraphHelper.convertToEntity(item.get('model') as GraphCustomNode);

          if (entity) {
            entity.name = item.getModel().fullLabel as string;
            // addEntityToWorkbench(entity);
          }
        },
      },
    ];

    const contextMenu = generateContextMenu(contextMenuOptions);

    graphRef.current = new G6.Graph({
      container: ref.current,
      width: ref.current.clientWidth,
      height: ref.current.clientHeight - titleRef.current.clientHeight,
      fitCenter: true,
      linkCenter: true,
      layout: layoutCfg,
      animate: true,
      fitView: true,
      plugins: [contextMenu],
      minZoom: 0.001,
    });

    const nodes = processData();

    graphRef.current.on('viewportchange', handleViewportChange);
    graphRef.current.on('beforelayout', handleBeforeLayout);
    graphRef.current.on('afterlayout', handleAfterLayout);
    graphRef.current.on('node:click', handleNodeClick);
    graphRef.current.on('node:mouseenter', handleNodeMouseEnter);
    graphRef.current.on('node:mouseleave', handleNodeMouseLeave);

    graphRef.current.data({ nodes, edges: [] });
    graphRef.current.render();
  };

  const fetchKpis = () => {
    if (!properties.includes(property)) {
      setFetchingKpis(true);
      fetchKpiSummary(
        processedGraphData.nodes.map((item) => ({
          name: item.label,
          type: item.entityType,
          scope: item.scope,
        })),
        stringToDate(start).valueOf(),
        stringToDate(end).valueOf(),
        property
      )
        .then((res) => setKpis(res))
        .finally(() => setFetchingKpis(false));
    } else {
      setTimeout(initGraph, 1000);
    }
  };

  useEffect(() => {
    isMounted.current = true;
    fetchKpis();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    let nodes = processData();
    const graphNodes = graphRef.current?.getNodes();

    // checking if we need to change data
    if (graphNodes) {
      const same = nodes.every((node) =>
        graphNodes.find((item) => item.getModel().id === node.id && item.getModel().size === node.size)
      );
      if (same && graphNodes.length === nodes.length) {
        // updating styles not data
        nodes.forEach((node) => {
          const graphNode = graphRef.current?.findById(node.id);
          graphNode?.update({ style: node.style });
        });
        handleViewportChange();
        return;
      }
    }

    graphRef.current?.changeData({ nodes, edges: [] });
    graphRef.current?.zoom(1);
    graphRef.current?.fitCenter();
    graphRef.current?.fitView();

    setTimeout(() => {
      graphRef.current?.fitCenter();
      graphRef.current?.fitView();
    }, 200);

    setTimeout(() => {
      graphRef.current?.fitCenter();
      graphRef.current?.fitView();
    }, 2000);
    //eslint-disable-next-line
  }, [processedGraphData, kpis, typeEnvFilter]);

  useDidUpdateEffect(() => {
    fetchKpis();
  }, [processedGraphData]);

  useDidUpdateEffect(() => {
    if (Object.keys(kpis).length) {
      setTimeout(initGraph, 1000);
    }
  }, [kpis]);

  useResizeObserver({
    ref,
    onResize: ({ width, height }) => {
      if (width && height && titleRef.current && graphRef.current && isMounted.current) {
        graphRef.current.changeSize(width, height - titleRef.current.clientHeight);

        graphRef.current.layout();
        graphRef.current?.fitCenter();
        graphRef.current?.fitView();

        setTimeout(() => {
          graphRef.current?.fitCenter();
          graphRef.current?.fitView();
        }, 200);
      }
    },
  });

  const blockRef = useRef(null);

  return (
    <div className="relative flex-1 min-w-[500px]" ref={blockRef}>
      <div ref={ref} className="flex-1 h-full relative divider-r">
        <div ref={titleRef}>
          <div className="p-4 font-bold text-lg">{kpiTitle}</div>
          <CountGroupedEntitiesComponent
            graphData={graphData}
            activeFilter={typeEnvFilter}
            onClick={toggleTypeEnvFilter}
            setFilter={setTypeEnvFilter}
            className="static w-auto"
            maxVisibleCount={4}
          />
          {fetchingKpis && <LoadingBar width={300} />}
        </div>
      </div>
      <GraphTooltipComponent
        graph={graphRef.current}
        propertyName={property}
        forceActiveNodeId={bubbleActiveNode?.getID()}
        CustomTooltip={(props) => <BubbleViewItemTooltip {...props} unit={kpiUnit} graph={graphRef.current} />}
      />
    </div>
  );
};

export default connector(BubbleViewItem);
