import { useDispatch } from 'react-redux';
import { mapEnvSiteColors } from '../features/App/App.slice';
import { useQuery } from '@tanstack/react-query';
import { EnvSiteValuesRes, fetchEnvSiteValues } from 'services/App.service';
import { useAppSelector } from 'app/store';

interface Params {
  start: number;
  end: number;
}
export default function useEnvSiteOptions({ start, end }: Params) {
  const dispatch = useDispatch();

  const selectedEnv = useAppSelector((state) => state.app.selectedEnv);

  return useQuery<EnvSiteValuesRes | undefined>(
    ['envSiteOptions', start, end, selectedEnv],
    async () => {
      if (start && end) {
        const data = await fetchEnvSiteValues(start, end, selectedEnv.length ? { env: selectedEnv } : undefined);

        dispatch(mapEnvSiteColors({ envList: data.scopeValues.env }));
        return data;
      }
    },
    { enabled: !!start && !!end, keepPreviousData: true }
  );
}
