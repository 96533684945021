/**
 *
 * SavedSearchitem
 *
 */

import React, { memo, FunctionComponent, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import DeleteOutlineIcon from 'assets/material-icons/delete_FILL0_wght400_GRAD0_opsz24.svg';
import EditOutlinedIcon from 'assets/material-icons/edit_FILL0_wght400_GRAD0_opsz24.svg';

import {
  clearEntities,
  setSearchDefinition,
  setShowAdvancedSearch,
} from 'features/Entities/Entities.slice';
import useDeleteSavedSearch from '../../../EntityAdvancedSearch/hooks/useDeleteSavedSearch';
import { Definition } from 'asserts-types';
import TooltipConfirmationComponent from 'features/Assertions/components/TooltipConfirmation/TooltipConfirmation.component';
import { twMerge } from 'tailwind-merge';
import { IconButton } from 'components/IconButton/IconButton.component';

const connector = connect((state: RootState) => ({}), {
  setShowAdvancedSearch,
  setSearchDefinition,
  clearEntities,
});

interface IProps {
  definition: Definition;
  removeSearchItem: () => void;
  onEdit: () => void;
  active: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

const SavedSearchItem: FunctionComponent<IProps & PropsFromRedux> = ({
  definition,
  removeSearchItem,
  setShowAdvancedSearch,
  setSearchDefinition,
  onEdit,
  active,
  clearEntities,
}) => {
  const [actionActive, setActionActive] = useState(false);

  const handleEditSearchClick = () => {
    setSearchDefinition(definition);
    setShowAdvancedSearch(true);
    onEdit();
  };

  const {
    mutate: handleDeleteSearch,
    isLoading: isDeleting,
  } = useDeleteSavedSearch({ onSuccess: removeSearchItem });

  return (
    <>
      <div
        data-cy="saved-search-item"
        className={`pr-2 rounded-md hover:bg-primary/5 h-[40px] flex items-center justify-between gap-2 cursor-pointer group ${
          active ? 'bg-primary/5 font-bold' : ''
        } ${isDeleting ? 'opacity-30' : ''}`}
      >
        <div className="pl-3 py-2 grow whitespace-nowrap min-w-0 overflow-hidden text-ellipsis relative">
          <div
            className="absolute inset-0"
            onClick={() => {
              clearEntities();
              setSearchDefinition(definition);
            }}
          ></div>
          {definition.boundDescription}
        </div>
        <div className={twMerge('group-hover:flex hidden whitespace-nowrap items-center', actionActive ? '!flex' : '')}>
          <IconButton onClick={handleEditSearchClick} variant="primary">
            <EditOutlinedIcon />
          </IconButton>
          <TooltipConfirmationComponent
            onOpen={() => setActionActive(true)}
            onClose={() => setActionActive(false)}
            action={() => handleDeleteSearch(definition)}
          >
            <IconButton variant="destructive">
              <DeleteOutlineIcon />
            </IconButton>
          </TooltipConfirmationComponent>
        </div>
      </div>
    </>
  );
};

export default connector(memo(SavedSearchItem));
