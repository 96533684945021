import {  useState } from 'react';
import { Scope } from 'asserts-types';

export default function useConnectedEntitiesMenu() {

  const [menuEntityName, setMenuEntityName] = useState<string | undefined>();
  const [menuEntityType, setMenuEntityType] = useState<string | undefined>();
  const [menuEntityScope, setMenuEntityScope] = useState<Scope | undefined>();

  const open = (entityType: string, entityName: string, scope?: Scope) => {
    setMenuEntityName(entityName);
    setMenuEntityType(entityType);
    setMenuEntityScope(scope);
  };

  const close = () => {
    setMenuEntityName(undefined);
    setMenuEntityType(undefined);
    setMenuEntityScope(undefined);
  };

  return {
    close,
    open,
    menuEntityName,
    menuEntityType,
    menuEntityScope,
    show: Boolean(menuEntityName && menuEntityType),
  };
}
