/*
 * EntitiesSortBy Messages
 *
 * This contains all the text for the EntitiesSortBy component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.EntitiesSortBy';

export default defineMessages({
  az: {
    id: `${scope}.az`,
    defaultMessage: 'Name',
  },
  sortBy: {
    id: `${scope}.sortBy`,
    defaultMessage: 'Sort by:',
  },
  types: {
    id: `${scope}.types`,
    defaultMessage: 'Types',
  },
  assertions: {
    id: `${scope}.assertions`,
    defaultMessage: 'Assertions',
  },
});
