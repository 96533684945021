/**
 *
 * CustomRulesList
 *
 */

import React, { memo, FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import { CustomRule, Sort } from 'asserts-types';
import CustomRulesListItem from '../CustomRulesListItem/CustomRulesListItem.component';
import has from 'helpers/has.helper';
import { LoadingPlaceholder, PanelContainer } from '@grafana/ui';
import TableSortButton from 'features/Entities/components/TableSortButton/TableSortButton.component';

interface IProps {
  fields: string[];
  fetchingList: boolean;
  list: CustomRule[];
  onUpdate: (item: CustomRule) => void;
  onDelete: (item: CustomRule) => void;
  onSort: (sort: Sort | null) => void;
  orderBy: Sort | null;
}

const isFieldFromMessages = (
  messagesObj: typeof messages,
  field: string,
): field is keyof typeof messages => messagesObj.hasOwnProperty(field);

const CustomRulesList: FunctionComponent<IProps> = ({
  orderBy,
  fetchingList,
  list,
  onUpdate,
  onDelete,
  fields,
  onSort,
}) => {
  const intl = useIntl();

  return (
    <PanelContainer className="p-6 my-8">
      {fetchingList ? (
        <div className="flex items-center justify-center p-8">
          <LoadingPlaceholder text="Loading" />
        </div>
      ) : (
        <>
          <p className="mb-6 text-xl">{intl.formatMessage(messages.title)}</p>
          <table className="table-fixed w-full">
            <thead>
              <tr>
                {fields.map((field) => (
                  <td key={field} className="p-4 divider-b">
                    <TableSortButton
                      className="headerCell"
                      active={orderBy?.field === field || orderBy?.field === `labels.${field}`}
                      direction={orderBy?.order}
                      onClick={() =>
                        onSort({
                          field:
                            has<(typeof list)[0], string, string>(list[0], field) && (list[0] as any)[field]
                              ? field
                              : `labels.${field}`,
                          order: orderBy?.order === 'asc' ? 'desc' : 'asc',
                        })
                      }
                    >
                      {isFieldFromMessages(messages, field) ? intl.formatMessage(messages[field]) : ''}
                    </TableSortButton>
                  </td>
                ))}
                <td width="10%" className="p-4 divider-b"></td>
              </tr>
            </thead>
            <tbody>
              {list.length ? (
                list.map((item) => (
                  <CustomRulesListItem
                    fields={fields}
                    key={item.alert}
                    item={item}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                  />
                ))
              ) : (
                <div className="p-4">{intl.formatMessage(messages.emptyList)}</div>
              )}
            </tbody>
          </table>
        </>
      )}
    </PanelContainer>
  );
};

export default memo(CustomRulesList);
