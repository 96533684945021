import { Popover, PopoverContent, PopoverTrigger } from 'components/Popover/Popover.component';
import { Tooltip } from 'components/Tooltip/Tooltip.component';
import React, { MouseEvent } from 'react';
import ConnectedEntitiesMenuComponent from './ConnectedEntitiesMenu.component';
import useConnectedEntitiesMenu from './hooks/useConnectedEntitiesMenu';
import ConnectedEntitiesIcon from 'icons/ConnectedEntitiesIcon';
import { Scope } from 'asserts-types';
import { IconButton } from 'components/IconButton/IconButton.component';

interface Props {
  name: string;
  type: string;
  scope?: Scope;
  onOpenChange?: (show: boolean) => void;
}

export default function ConnectedEntitiesMenuPopover({ name, type, scope, onOpenChange }: Props) {
  const { open, menuEntityName, menuEntityType, menuEntityScope, close, show } = useConnectedEntitiesMenu();

  const handleOpenChange = (value: boolean) => {
    onOpenChange?.(value);
    value ? open(type, name, scope) : close();
  }

  return (
    <Popover open={show} onOpenChange={handleOpenChange} placement="right-start">
      <Tooltip content="Entity" placement="top">
        <PopoverTrigger
          asChild
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            open(type, name, scope);
            onOpenChange?.(true);
          }}
        >
          <IconButton variant="primary" active={show}>
            <ConnectedEntitiesIcon />
          </IconButton>
        </PopoverTrigger>
      </Tooltip>

      <PopoverContent>
        {menuEntityName && menuEntityType && (
          <ConnectedEntitiesMenuComponent
            entityName={menuEntityName}
            entityType={menuEntityType}
            scope={menuEntityScope}
          />
        )}
      </PopoverContent>
    </Popover>
  );
}
