import { useEffect, useState } from "react";
import { useIncidentsQueryParams } from "./useIncidentsQueryParams";
import { useDispatch } from "react-redux";
import { setExpandedItems } from "../Incidents.slice";
import { GroupedIncident, IncidentsQueryParams } from "asserts-types";

interface IProps {
  groupedItems: GroupedIncident[] | undefined;
}
export default function useExpandRows({ groupedItems }: IProps) {
  const { getParsedQueryParams } = useIncidentsQueryParams();
  const dispatch = useDispatch();

  const [queryParams, setQueryParams] = useState<
    IncidentsQueryParams | undefined
  >(getParsedQueryParams());

  useEffect(() => {
    if (!groupedItems || !queryParams?.expand) {
      return;
    }
    const itemsToExpand: string[] = [];

    groupedItems.forEach((item) => {
      if (
        queryParams.expand === item.name &&
        queryParams.expandEnv === item.detail.scope.env &&
        queryParams.expandSite === item.detail.scope.site
      ) {
        itemsToExpand.push(item.id);
      }
      item.nestedItems?.forEach((nestedItem) => {
        if (
          queryParams.expand === nestedItem.name &&
          queryParams.expandEnv === nestedItem.detail.scope.env &&
          queryParams.expandSite === nestedItem.detail.scope.site
        ) {
          itemsToExpand.push(nestedItem.id);
          itemsToExpand.push(item.id);
        }
      });
    });
    dispatch(setExpandedItems(itemsToExpand));
    setQueryParams(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupedItems]);
    
}
