import { AppPlugin } from '@grafana/data';
import {
  EntityAssertionsWidget,
  EntityAssertionsWidgetProps,
} from 'externalComponents/EntityAssertionsWidget/EntityAssertionsWidget';
import { ExternalComponentWrapper } from 'externalComponents/ExternalComponentWrapper';
import { configureGrafanaExtensions } from 'app/grafanaExtensions';
import { AssertsAppJsonData } from 'asserts-types';
import React from 'react';
import { App } from './features/App';
import pluginJson from 'plugin.json';
import { getFaroConfig } from 'faro';
import { initializeFaro } from '@grafana/faro-web-sdk';
import { config } from '@grafana/runtime';

const appUrl = new URL(config.appUrl).hostname;
if (!appUrl.includes('localhost')) {
  initializeFaro(getFaroConfig());
}

const plugin = new AppPlugin<AssertsAppJsonData>().setRootPage(App);

if (plugin.exposeComponent) {
  plugin.exposeComponent({
    id: `${pluginJson.id}/entity-assertions-widget/v1`,
    title: 'Entity Assertions Widget',
    description: 'Widget for displaying entity assertions',
    component: (props: EntityAssertionsWidgetProps) => {
      return (
        <ExternalComponentWrapper>
          <EntityAssertionsWidget {...props} />
        </ExternalComponentWrapper>
      );
    },
  });
}

configureGrafanaExtensions(plugin);

export { plugin };
