import { defineMessages } from 'react-intl';

export const scope = 'features.EntitiesTopMenu';

export default defineMessages({
  list: {
    id: `${scope}.list`,
    defaultMessage: 'List',
  },
  graph: {
    id: `${scope}.graph`,
    defaultMessage: 'Graph',
  },
  all: {
    id: `${scope}.all`,
    defaultMessage: 'All',
  },
  advancedSearch: {
    id: `${scope}.advancedSearch`,
    defaultMessage: 'Advanced search',
  },
});
