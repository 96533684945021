import { Graph, Item } from '@antv/g6';
import { EntityResponse, Entity, GraphCustomData, GraphCustomEdge, GraphCustomNode } from 'asserts-types';
export const NODE_POSTFIX_ID = '-node';
export const EDGE_POSTFIX_ID = '-edge';

let nextEdgeId = 0;

export function generateEdge(
  source: GraphCustomNode,
  target: GraphCustomNode,
  type: string,
) {
  const edgeId = ++nextEdgeId + +new Date() + EDGE_POSTFIX_ID;

  const generatedEdge: GraphCustomEdge = {
    id: edgeId,
    type,
    trafficPresent: false,
    source: source.id,
    target: target.id,
    disabled: false,
    label: type,
    callsPerMinute: undefined,
  };

  return generatedEdge;
}

export function applyClustering(data: GraphCustomData): void {
  const sortedNodes = data.nodes.sort((a, b) => {
    const aEdges = data.edges.filter(
      (edge) => edge.source === a.id || edge.target === a.id,
    );
    const bEdges = data.edges.filter(
      (edge) => edge.source === b.id || edge.target === b.id,
    );
    return bEdges.length - aEdges.length;
  });
  sortedNodes.forEach((node) => {
    node.cluster = 'single-nodes';

    const nodeEdges = data.edges.filter(
      (edge) => edge.source === node.id || edge.target === node.id,
    );

    if (nodeEdges.length) {
      node.cluster = node.id;
    }

    //@ts-ignore Not all code paths return a value.
    nodeEdges.forEach((edge) => {
      const connectedNodeId =
        edge.source === node.id ? edge.target : edge.source;

      const connectedNode = data.nodes.find((n) => n.id === connectedNodeId);

      if (connectedNode?.cluster) {
        node.cluster = connectedNode.cluster;
        return true;
      }
      if (connectedNode) {
        connectedNode.cluster = node.id;
      }
    });
  });
}

export function convertToGraphData(res: EntityResponse): GraphCustomData {
  const edges = res.data.edges.map((item) => {
    const edge = {
      id: item.id.toString() + EDGE_POSTFIX_ID,
      source: item.source.toString() + NODE_POSTFIX_ID,
      target: item.destination.toString() + NODE_POSTFIX_ID,
      label: item.type,
      trafficPresent: item.trafficPresent,
      callsPerMinute: item.callsPerMinute,
    };
    return { ...edge, data: { ...edge } };
  });

  const nodes = res.data.entities.map((item) => {
    const {
      id,
      name,
      type,
      assertion,
      connectedAssertion,
      connectedEntityTypes,
      activeConnectedEntityType,
      scope,
      nameWithNamespace,
    } = item;
    const node = {
      id: id.toString() + NODE_POSTFIX_ID,
      label: nameWithNamespace ? nameWithNamespace : name,
      type,
      properties: item.properties,
      assertion,
      connectedAssertion,
      connectedEntityTypes,
      activeConnectedEntityType,
      entityType: type,
      entityName: name,
      scope,
    };
    return {
      ...node,
      data: {
        id: id.toString() + NODE_POSTFIX_ID,
      },
    };
  });

  return { nodes, edges };
}

export function convertToNode(
  entity: Entity | undefined,
): GraphCustomNode | undefined {
  if (!entity) {
    return undefined;
  }
  const {
    id,
    name,
    type,
    properties,
    assertion,
    connectedAssertion,
    parentEntityId,
    activeConnectedEntityType,
    scope,
    nameWithNamespace,
  } = entity;

  return {
    id: id + NODE_POSTFIX_ID,
    label: nameWithNamespace ? nameWithNamespace : name,
    type,
    properties,
    data: { id: id + NODE_POSTFIX_ID, type },
    assertion,
    connectedAssertion,
    parentEntityId,
    activeConnectedEntityType,
    entityType: type,
    entityName: name,
    scope,
  };
}

export function convertToEntity(
  node: GraphCustomNode | undefined,
): Entity | undefined {
  if (!node) {
    return undefined;
  }
  const {
    id,
    label,
    properties,
    assertion,
    connectedAssertion,
    connectedEntityTypes,
    parentEntityId,
    activeConnectedEntityType,
    entityType,
    scope,
  } = node;

  return {
    id: +id.replace(NODE_POSTFIX_ID, ''),
    name: label,
    type: entityType,
    properties,
    assertion,
    connectedAssertion,
    connectedEntityTypes,
    parentEntityId,
    activeConnectedEntityType,
    scope,
  };
}

const calculate = function calculate(
  position: { x: number; y: number },
  {
    menuBox,
    canvasBox,
  }: {
    menuBox: { menuWidth: number; menuHeight: number };
    canvasBox: { canvasWidth: number; canvasHeight: number };
  },
) {
  const { menuWidth, menuHeight } = menuBox;
  const { canvasWidth, canvasHeight } = canvasBox;

  let { x, y } = position;

  if (menuHeight + y > canvasHeight) {
    y -= menuHeight;
  }

  if (menuWidth + x > canvasWidth) {
    x -= menuWidth;
  }

  return {
    x: x,
    y: y,
  };
};

const getPosition = (
  graph: Graph,
  item: Item,
  menuItem: { width: number; height: number },
) => {
  const itemBBox = item.getBBox(),
    maxY = itemBBox.maxY,
    minX = itemBBox.minX;

  const canvasXY = graph.getCanvasByPoint(minX, maxY);
  const canvas = graph.get('canvas');
  const menuBox = {
    menuWidth: menuItem.width,
    menuHeight: menuItem.height,
  };
  const canvasBox = {
    canvasWidth: canvas.get('width'),
    canvasHeight: canvas.get('height'),
  };

  const { x, y } = calculate(canvasXY, {
    menuBox,
    canvasBox,
  });

  return {
    x: x,
    y: y,
  };
};

export function nodesConnected(
  edges: GraphCustomEdge[],
  node1Id: string,
  node2Id: string,
) {
  return !!edges.find(
    (edge) =>
      (edge.source === node1Id && edge.target === node2Id) ||
      (edge.target === node1Id && edge.source === node2Id),
  );
}


// BE sometimes returns duplicate edges (one with label and and with callsPerMinute)
// cpm is prioritized, other edges removed
export function cleanEdges(edges: GraphCustomEdge[]) {
  return edges.filter((edge) => {
    if (
      !edge.callsPerMinute &&
      edges.find(
        (e) =>
          edge.source === e.source &&
          edge.target === e.target &&
          e.callsPerMinute,
      )
    ) {
      return false;
    }

    return true;
  });
}

export default {
  convertToGraphData,
  convertToNode,
  convertToEntity,
  getPosition,
  applyClustering,
  generateEdge,
  nodesConnected,
};
