import { defineMessages } from 'react-intl';

export const scope = 'features.GraphViewSettings';

export default defineMessages({
  view: {
    id: `${scope}.view`,
    defaultMessage: 'Graph view',
  },
  force: {
    id: `${scope}.force`,
    defaultMessage: 'Force',
  },
  dagre: {
    id: `${scope}.dagre`,
    defaultMessage: 'Dagre',
  },
  relationName: {
    id: `${scope}.relationName`,
    defaultMessage: 'Show Relationship Name',
  },
  nodeName: {
    id: `${scope}.nodeName`,
    defaultMessage: 'Show Node Name',
  },
  highlightSelection: {
    id: `${scope}.highlightSelection`,
    defaultMessage: 'Highlight Selection',
  },
  showCallsWithTraffic: {
    id: `${scope}.showCallsWithTraffic`,
    defaultMessage: 'Show CALLS with active traffic',
  },
});
