/**
 *
 * TopInsightsTimeline
 *
 */

import { Moment } from 'moment';
import React, {
  memo,
  ForwardRefRenderFunction,
  forwardRef,
  useMemo,
  useState,
} from 'react';
import ZoomableTimeline from '../../../../components/ZoomableTimeline/ZoomableTimeline.component';
import TopInsightsTimelineItem from '../TopInsightsTimelineItem/TopInsightsTimelineItem.component';
import { AssertionScore } from 'asserts-types';

interface IProps {
  start: Moment;
  end: Moment;
  assertionScores: (AssertionScore & { itemId: string })[];
  changeDateRange: (start: number | string, end: number | string) => void;
  rootScores: AssertionScore;
  timeStepIntervalMs: number;
  scaleType: 'log' | 'linear';
  expanded?: boolean;
  showAll: boolean;
}

const TopInsightsTimeline: ForwardRefRenderFunction<HTMLDivElement, IProps> = (
  {
    start,
    end,
    assertionScores,
    changeDateRange,
    rootScores,
    timeStepIntervalMs,
    scaleType,
    expanded,
    showAll,
  },
  ref,
) => {
  const [clustersVisible, setClustersVisible] = useState(true);

  // maxY scale value for all timeline items (except root)
  const maxY = useMemo(() => {
    let maxValue = 0;

    assertionScores.forEach((score) => {
      score.metrics.forEach((metric) => {
        metric.values.forEach((a) => {
          if (a.value && a.value > maxValue) {
            maxValue = a.value;
          }
        });
      });
    });
    return maxValue;
    //eslint-disable-next-line
  }, [assertionScores]);

  return (
    <div className="h-full relative">
      <ZoomableTimeline
        changeDateRange={changeDateRange}
        start={start}
        end={end}
        ref={ref}
        onSelectStart={() => setClustersVisible(false)}
        onSelectEnd={() => setClustersVisible(true)}
      >
        <>
          {expanded &&
            assertionScores
              .slice(0, showAll ? assertionScores.length || 0 : 3)
              .map((item) => (
                <TopInsightsTimelineItem
                  key={item.itemId}
                  itemId={item.itemId}
                  start={start}
                  end={end}
                  metrics={item.metrics}
                  maxY={maxY}
                  timeStepIntervalMs={timeStepIntervalMs}
                  entityName={item.name}
                  entityType={item.type}
                  assertionClusters={item.assertionClusters}
                  scope={item.scope}
                  scaleType={scaleType}
                  clustersVisible={clustersVisible}
                />
              ))}
        </>
      </ZoomableTimeline>
    </div>
  );
};

const forwardedTopInsightsTimeline = forwardRef(TopInsightsTimeline);

export default memo(forwardedTopInsightsTimeline);
