/**
 *
 * EntityContextMenu
 *
 */

import React, { memo, FunctionComponent, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import { connect, ConnectedProps } from 'react-redux';

import { setActiveEntity } from 'features/Entities/Entities.slice';
import ConnectedEntitiesIcon from 'icons/ConnectedEntitiesIcon';
import { stringToDate } from 'helpers/Date.helper';
import EntityListItemComponent from 'features/Entities/components/EntityListItem/EntityListItem.component';
import { EnityContextMenuItem, Entity } from 'asserts-types';
import useConnectedEntities from 'hooks/useConnectedEntities';
import { LoadingPlaceholder, Menu } from '@grafana/ui';
import { Popover, PopoverContent, PopoverTrigger } from 'components/Popover/Popover.component';
import { twMerge } from 'tailwind-merge';
import { IconButton } from 'components/IconButton/IconButton.component';
import { setActiveEntityDetails } from 'features/App/App.slice';

interface IProps {
  entity: Entity;
  closeMenu: () => void;
}

const connector = connect(
  (state: RootState) => ({
    activeEntity: state.entities.activeEntity,
    start: state.app.start,
    end: state.app.end,
  }),
  {
    setActiveEntity,
    setActiveEntityDetails,
  }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

const EntityContextMenu: FunctionComponent<IProps & PropsFromRedux> = ({
  entity,
  activeEntity,
  setActiveEntity,
  closeMenu,
  start,
  end,
  setActiveEntityDetails,
}) => {
  const intl = useIntl();

  const startParsed = useMemo(() => stringToDate(start).valueOf(), [start]);
  const endParsed = useMemo(() => stringToDate(end).valueOf(), [end]);

  const [isConnectedMenuShown, setIsConnectedMenuShown] = useState(false);

  const [activeConnectedType, setActiveConnectedType] = useState<string | null>(null);

  const {
    data: connectedEntities,
    isFetching,
    dataUpdatedAt,
  } = useConnectedEntities({
    entityId: entity.id,
    type: activeConnectedType || '',
    start: startParsed,
    end: endParsed,
    scope: entity.scope,
    enabled: Boolean(activeConnectedType && isConnectedMenuShown),
  });

  const handleConnectedButtonClick = (type: string) => {
    if (activeConnectedType && activeConnectedType === type) {
      handleCloseConnectedMenu();
    } else {
      setActiveConnectedType(type);
      setIsConnectedMenuShown(true);
    }
  };

  const handleCloseConnectedMenu = () => {
    setIsConnectedMenuShown(false);
    setActiveConnectedType(null);
  };

  const connectedOptions = useMemo<EnityContextMenuItem[]>(() => {
    return entity.connectedEntityTypes
      ? Object.entries(entity.connectedEntityTypes)
          .filter(([key]) => key !== 'Assertion')
          .map(([key, value]) => ({
            key,
            title: (
              <div className="relative flex grow items-center justify-between gap-2">
                <div>{key}</div>
                <div>
                  <span className={`group-hover:hidden text-secondary ${key === activeConnectedType ? '!hidden' : ''}`}>
                    {value}
                  </span>
                  <Popover
                    open={isConnectedMenuShown && activeConnectedType === key}
                    onOpenChange={setIsConnectedMenuShown}
                    placement="right-start"
                  >
                    <PopoverTrigger
                      asChild
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        handleConnectedButtonClick(key);
                      }}
                    >
                      <IconButton
                        variant={key === activeConnectedType ? 'primary' : 'secondary'}
                        active={key === activeConnectedType}
                        className={`absolute right-[-10px] top-1/2 -translate-y-1/2 hidden group-hover:flex ${
                          key === activeConnectedType ? '!flex' : ''
                        }`}
                      >
                        <ConnectedEntitiesIcon />
                      </IconButton>
                    </PopoverTrigger>
                    <PopoverContent>
                      <Menu>
                        <div className="w-[400px] max-h-[400px] overflow-overlay">
                          <Menu.Group label={`${key} connections`}>
                            {connectedEntities &&
                              connectedEntities
                                ?.filter((item) => item.id !== entity.id)
                                .map((item) => (
                                  <EntityListItemComponent
                                    key={item.id}
                                    item={item}
                                    onClick={(e) => e.stopPropagation()}
                                    hideArrow
                                    setActiveEntityDetails={setActiveEntityDetails}
                                    lastUpdateTime={dataUpdatedAt}
                                  />
                                ))}
                            {isFetching && <LoadingPlaceholder text="Loading..." className="m-0 py-8 text-center" />}
                          </Menu.Group>
                        </div>
                      </Menu>
                    </PopoverContent>
                  </Popover>
                </div>
              </div>
            ),
            onClick: () => {
              setActiveEntity({
                entity: { ...entity, activeConnectedEntityType: key },
              });
              closeMenu();
            },
            value,
          }))
      : [];
    //eslint-disable-next-line
  }, [activeEntity, entity, activeConnectedType, isFetching]);

  return (
    <div>
      <Menu>
        <Menu.Group label={intl.formatMessage(messages.connected)}>
          {connectedOptions.map((item) => {
            return [
              <div
                className={twMerge(
                  'hover:bg-hover px-4 py-2 min-w-[200px] cursor-pointer group',
                  isConnectedMenuShown && item.key === activeConnectedType ? 'bg-hover' : ''
                )}
                key={item.key}
                onClick={(e) => {
                  e.stopPropagation();
                  item.onClick && item.onClick(entity);
                }}
              >
                {item.setTitle ? item.setTitle(entity) : item.title}
              </div>,
            ];
          })}
        </Menu.Group>
      </Menu>
    </div>
  );
};

export default connector(memo(EntityContextMenu));
