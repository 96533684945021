/**
 *
 * AddToWorkbenchButton
 *
 */

import React, { memo, FunctionComponent, ButtonHTMLAttributes } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';

import AddRoundedIcon from 'assets/material-icons/add_FILL0_wght400_GRAD0_opsz24.svg';
import DoneIcon from 'assets/material-icons/done_FILL0_wght400_GRAD0_opsz24.svg';
import { AssertionsBoardEntity } from 'asserts-types';
import { areEntitiesEqual } from 'helpers/Entity.helper';
import { useDispatch } from 'react-redux';
import { addEntitiesToWorkbench, removeEntitiesFromWorkbench } from '../../Assertions.slice';
import WbOpenIcon from 'icons/WbOpenIcon';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'app/store';
import { ROUTES, TRACKING_FEATURES } from 'global-constants';
import { prefixRoute } from 'utils/utils.routing';
import { Button } from '@grafana/ui';
import { Tooltip } from 'components/Tooltip/Tooltip.component';
import { IconButton } from 'components/IconButton/IconButton.component';
import TrackingHelper from 'helpers/Tracking.helper';

interface IProps {
  tooltip?: string;
  active?: boolean;
  entityKeys: AssertionsBoardEntity[];
  buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
  onOpen?: () => void;
  onClose?: () => void;
}

const AddToWorkbenchButton: FunctionComponent<IProps> = ({
  tooltip,
  entityKeys,
  buttonProps,
  onOpen,
  onClose,
  active,
}) => {
  const workbenchEntities = useAppSelector((state) => state.assertions.workbenchEntities);

  const areAddedToWorkbench = entityKeys.length
    ? entityKeys.every((item) => workbenchEntities.find((wbItem) => areEntitiesEqual(item, wbItem)))
    : false;

  const dispatch = useDispatch();

  const { formatMessage } = useIntl();

  const navigate = useNavigate();

  return (
    <Tooltip
      interactive
      onOpen={onOpen}
      onClose={onClose}
      placement="top"
      content={
        areAddedToWorkbench ? (
          <div className="py-2">
            <div className="text-sm flex items-center space-x-1">
              <DoneIcon className="text-primary svg-icon" />
              <span>{formatMessage(messages.added)}</span>
            </div>
            <div className="mt-1 text-secondary pl-6">
              <span>
                {entityKeys.length > 1 ? (
                  <>
                    <span className="font-bold text-black dark:text-secondary">{entityKeys.length} </span>
                    {formatMessage(messages.itemsAdded, {
                      count: entityKeys.length,
                    })}
                  </>
                ) : (
                  entityKeys[0].name
                )}
              </span>
            </div>
            <div className="mt-4 flex items-center space-x-2 ml-6">
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  TrackingHelper.trackNavigateToWorkbench(TRACKING_FEATURES.ADD_TO_WORKBENCH_BTN);
                  navigate(prefixRoute(ROUTES.RCA_WORKBENCH), { state: { timestamp: Date.now() } });
                }}
              >
                <WbOpenIcon />
                <span className="ml-1">{formatMessage(messages.go)}</span>
              </Button>
              <Button
                variant="destructive"
                fill="text"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(removeEntitiesFromWorkbench(entityKeys));
                }}
              >
                {formatMessage(messages.delete)}
              </Button>
            </div>
          </div>
        ) : (
          tooltip || formatMessage(messages.add)
        )
      }
    >
      <IconButton
        data-cy="add-to-wb-btn"
        variant="primary"
        active={active}
        onClick={(e) => {
          e.stopPropagation();
          entityKeys.forEach((entity) => {
            TrackingHelper.trackAddToWorkbench(TRACKING_FEATURES.ADD_TO_WORKBENCH_BTN, entity.type);
          });
          dispatch(addEntitiesToWorkbench(entityKeys));
        }}
        {...buttonProps}
      >
        {areAddedToWorkbench ? <DoneIcon className="text-success" /> : <AddRoundedIcon />}
      </IconButton>
    </Tooltip>
  );
};

export default memo(AddToWorkbenchButton);
