/*
 * AssertionsDirHoveringButtons Messages
 *
 * This contains all the text for the AssertionsDirHoveringButtons component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.AssertionsDirHoveringButtons';

export default defineMessages({
  deleteEntityFromBoard: {
    id: `${scope}.deleteEntityFromBoard`,
    defaultMessage: 'Delete entity from board',
  },
  entity: {
    id: `${scope}.entity`,
    defaultMessage: 'Connected Entities',
  },
  kpi: {
    id: `${scope}.kpi`,
    defaultMessage: 'KPI',
  },
});
