/**
 *
 * AssertionsDirHoveringButtons
 *
 */

import React, { memo, FunctionComponent, MouseEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import CloseIcon from 'assets/material-icons/close_FILL0_wght400_GRAD0_opsz24.svg';
import { removeEntitiesFromWorkbench } from '../../Assertions.slice';
import DashboardOutlinedIcon from 'assets/material-icons/dashboard_FILL0_wght400_GRAD0_opsz24.svg';
import { Scope } from 'asserts-types';
import AssertionsProblematicConnectionsButtonComponent from '../AssertionsProblematicConnectionsButton/AssertionsProblematicConnectionsButton.component';
import { Tooltip } from 'components/Tooltip/Tooltip.component';
import ConnectedEntitiesMenuPopover from '../ConnectedEntitiesMenu/ConnectedEntitiesMenuPopover.component';
import { IconButton } from 'components/IconButton/IconButton.component';

interface IProps {
  itemId: string;
  entityType?: string;
  entityName: string;
  removeEntitiesFromWorkbench: typeof removeEntitiesFromWorkbench;
  setActiveItem: () => void;
  scope: Scope | undefined;
  start: number;
  end: number;
  onOpenChange?: (show: boolean) => void;
}

const AssertionsDirHoveringButtons: FunctionComponent<IProps> = ({
  removeEntitiesFromWorkbench,
  entityType,
  entityName,
  setActiveItem,
  scope,
  start,
  end,
  onOpenChange,
}) => {
  const intl = useIntl();
  const [connectedMenuOpened, setConnectedMenuOpened] = useState(false);

  return (
    <div className={`hidden items-center h-[20px] group-hover:flex ${connectedMenuOpened ? '!flex' : ''}`}>
      {entityType && (
        <Tooltip content={intl.formatMessage(messages.deleteEntityFromBoard)} placement="top">
          <IconButton
            variant="destructive"
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              removeEntitiesFromWorkbench([{ name: entityName, type: entityType, scope }]);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      )}
      {entityType && (
        <AssertionsProblematicConnectionsButtonComponent
          name={entityName}
          type={entityType}
          start={start}
          end={end}
          scope={scope}
        />
      )}
      {entityType && (
        <ConnectedEntitiesMenuPopover
          name={entityName}
          type={entityType}
          scope={scope}
          onOpenChange={(v) => {
            setConnectedMenuOpened(v);
            onOpenChange?.(v);
          }}
        />
      )}
      {entityType && (
        <Tooltip content={intl.formatMessage(messages.kpi)} placement="top">
          <IconButton
            variant="primary"
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              setActiveItem();
            }}
          >
            <DashboardOutlinedIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default memo(AssertionsDirHoveringButtons);
