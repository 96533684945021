import { DataFrame, DataLink, DataSourceApi, TimeRange, dateTimeParse } from '@grafana/data';
import {
  EmbeddedScene,
  PanelBuilders,
  SceneControlsSpacer,
  SceneDataTransformer,
  SceneFlexLayout,
  SceneQueryRunner,
  SceneReactObject,
  SceneRefreshPicker,
  SceneTimePicker,
  SceneTimeRange,
  SplitLayout,
} from '@grafana/scenes';
import { Button } from '@grafana/ui';
import { TempoQuery } from 'asserts-types';
import { CopyLinkButton } from 'components/CopyLinkButton/CopyLinkButton';
import { OpenInExploreButton } from 'components/OpenInExploreButton/OpenInExploreButton';
import { QueryEditorScene } from 'components/QueryEditorScene/QueryEditorScene';
import React, { useEffect, useMemo, useRef } from 'react';
import { map } from 'rxjs';
import { DataSourcePicker } from '@grafana/runtime';

interface Props {
  query: string;
  initialStart: string | number;
  initialEnd: string | number;
  dataSource: DataSourceApi;
  onTimeRangeChange?: (timeRange: TimeRange) => void;
}

export default function EmbeddedTracesExplore({
  query,
  initialStart,
  initialEnd,
  dataSource,
  onTimeRangeChange,
}: Props) {
  const prevQueryTypeRef = useRef<string>('traceql');
  const prevQueryTableTypeRef = useRef<TempoQuery['tableType']>('traces');

  const [scene, cleanup] = useMemo(() => {
    const from = dateTimeParse(initialStart);
    const to = dateTimeParse(initialEnd);

    const timeRange: TimeRange = {
      from,
      to,
      raw: {
        from: typeof initialStart === 'string' ? initialStart : from,
        to: typeof initialEnd === 'string' ? initialEnd : to,
      },
    };

    const $timeRange = new SceneTimeRange({
      value: timeRange,
      from: typeof initialStart === 'string' ? initialStart : from.toString(),
      to: typeof initialEnd === 'string' ? initialEnd : to.toString(),
    });

    const queryData: TempoQuery = { refId: 'A', query, tableType: prevQueryTableTypeRef.current };

    const queryRunner = new SceneQueryRunner({
      datasource: {
        uid: dataSource?.uid,
      },
      queries: [queryData],
    });

    const spanIdDataLink: DataLink = {
      title: 'Span: ${__value.raw}',
      url: '',
      onClick: (data) => {
        const spanId: string | undefined = data.origin?.field?.values?.[data.origin?.rowIndex];
        const traceId: string | undefined = data.origin.field.state.scopedVars?.__dataContext?.value?.data?.[0]?.first?.[0];

        if (spanId && traceId) {
          openTrace(traceId, spanId);
        }
      }
    };

    const transformer = new SceneDataTransformer({
      $data: queryRunner,
      transformations: [
        () => (source) =>
          source.pipe(
            map((frames) =>
              (frames ?? [])
                // Hide streaming progress as it prevents the traces table from rendering
                .filter((frame) => frame.refId !== 'streaming-progress')
                // add links to nested span fields
                .map((frame) => ({
                  ...frame,
                  fields: frame.fields.filter((field) => {
                    if (field.name === 'nested') {
                      field.values.forEach((frames: DataFrame[]) => {
                        frames.forEach((frame) => {
                          const spanIdField = frame.fields?.find((f) => f.name === 'spanID');
                          if (spanIdField) {
                            spanIdField.config.links = [spanIdDataLink];
                          }
                        });
                      });
                    }
                    return true;
                  }),
                }))
            )
          ),
      ],
    });

    const queryEditorPanel = new QueryEditorScene({ dataSource, queryRunner, query: queryData });

    const nodeGraphPanel = PanelBuilders.nodegraph().setTitle('Node Graph').build();

    const tracePanelQueryRunner = new SceneQueryRunner({
      datasource: {
        uid: dataSource?.uid,
      },
      queries: [],
    });

    const tracePanel = PanelBuilders.traces()
      .setTitle('Trace')
      .setData(tracePanelQueryRunner)
      .setHeaderActions(
        <Button
          icon="times"
          size="sm"
          variant="secondary"
          onClick={() => splitLayout.setState({ secondary: undefined })}
        >
          Close
        </Button>
      )
      .build();


    const openTrace = (traceID: string, spanID?: string) => {
      if (splitLayout.getRef().resolve().state.secondary === undefined) {
        splitLayout.setState({ secondary: tracePanel });
      }

      tracePanelQueryRunner.setState({
        queries: [{ queryType: 'traceql', query: traceID, refId: 'trace', limit: 20 }],
      });
      tracePanelQueryRunner.runQueries();
      if (spanID) {
        // setTimeout here to allow tracePanel to render for the first time
        setTimeout(() => {
          tracePanel
            .getRef()
            .resolve()
            //@ts-ignore
            .onOptionsChange({ focusedSpanId: spanID });
        }, 0);
      }
    };

    const tablePanel = PanelBuilders.table()
      .setTitle('Table - Traces')
      .setOverrides((builder) => {
        return builder
          .matchFieldsWithName('traceID')
          .overrideLinks([
            {
              title: 'Trace: ${__value.raw}',
              url: '',
              onClick: (data) => {
                const traceID: string | undefined = data.origin?.field?.values?.[data.origin?.rowIndex];
                traceID && openTrace(traceID);
              },
            },
          ])
          .matchFieldsWithName('spanID')
          .overrideLinks([
            {
              title: 'Span: ${__value.raw}',
              url: '',
              onClick: (data) => {
                const traceID: string | undefined =
                  data?.origin?.field?.state?.scopedVars?.__dataContext?.value?.frame?.first?.[data.origin?.rowIndex];
                const spanID: string | undefined =
                  data?.origin?.field?.values?.[data.origin?.rowIndex];

                traceID && openTrace(traceID, spanID);
              },
            },
          ]);
      })
      .build();

    const splitLayout = new SplitLayout({
      direction: 'row',
      primary: tablePanel.clone(),
    });

    const sceneLayout = new SceneFlexLayout({
      direction: 'column',
      children: [queryEditorPanel, splitLayout],
    });

    const unsubscribableTimeRange = $timeRange.subscribeToState((state) => {
      onTimeRangeChange?.(state.value);
    });

    const unsubscribableQueryEditor = queryEditorPanel.subscribeToState((state) => {
      if (state.query.queryType === 'traceqlSearch' && !state.query.filters) {
        return;
      }
      if (state.query.queryType === 'serviceMap') {
        sceneLayout.setState({ children: [queryEditorPanel, nodeGraphPanel] });
      } else {
        sceneLayout.setState({ children: [queryEditorPanel, splitLayout] });
      }

      const queryTypeChanged =
        state.query.queryType &&
        state.query.queryType !== 'clear' &&
        prevQueryTypeRef.current !== state.query.queryType;

      const queryTableTypeChanged = state.query.tableType && prevQueryTableTypeRef.current !== state.query.tableType;

      // re-running query automatically only in case tab is changed and query table type is changed in the query editor
      if (queryTypeChanged || queryTableTypeChanged) {
        splitLayout.setState({ secondary: undefined });
        prevQueryTypeRef.current = state.query.queryType || '';
        prevQueryTableTypeRef.current = state.query.tableType;

        queryRunner.cancelQuery();
        queryRunner.setState({ queries: [state.query] });
        queryRunner.runQueries();
      }
    });

    const scene = new EmbeddedScene({
      $data: transformer,
      $timeRange,
      controls: [
        new SceneReactObject({ component: () => <DataSourcePicker disabled current={dataSource.uid} /> }),
        new OpenInExploreButton({
          datasourceUid: dataSource.uid,
          query: queryData,
        }),
        new CopyLinkButton({
          datasourceUid: dataSource.uid,
          query: queryData,
        }),
        new SceneControlsSpacer(),

        new SceneTimePicker({
          isOnCanvas: true,
        }),
        new SceneRefreshPicker({
          isOnCanvas: true,
        }),
      ],
      body: sceneLayout,
    });

    const cleanup = () => {
      unsubscribableTimeRange.unsubscribe();
      unsubscribableQueryEditor.unsubscribe();
    };

    return [scene, cleanup];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, query]);

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, [cleanup]);

  return <scene.Component model={scene} />;
}
