/**
 *
 * AddTopInsightsSearchQuery
 *
 */

import React, { memo, FunctionComponent, useState } from 'react';
import { useIntl } from 'react-intl';
import useDebounceValue from 'hooks/useDebounceValue';
import messages from './messages';
import { useDispatch } from 'react-redux';
import { addSearch } from '../../TopInsights.slice';
import useDefinitionAutocomplete from 'hooks/useDefinitionAutocomplete';
import { MAX_AUTOCOMPLETE_RESULTS_COUNT } from 'app/constants';
import { PanelContainer, Select } from '@grafana/ui';
import { SelectableValue } from '@grafana/data';
import { Definition } from 'asserts-types';

interface IProps {
  closeForm: () => void;
}

const AddTopInsightsSearchQuery: FunctionComponent<IProps> = ({
  closeForm,
}) => {
  const { formatMessage } = useIntl();
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounceValue(query, 300);

  const dispatch = useDispatch();

  const { data, isFetching: isFetchingOptions } = useDefinitionAutocomplete({
    query: debouncedQuery,
    max: MAX_AUTOCOMPLETE_RESULTS_COUNT,
    enabled: Boolean(debouncedQuery),
  });

  const options: SelectableValue<Definition>[] = data?.map((d) => ({ value: d, label: d.boundDescription })) || [];


  return (
    <PanelContainer className="mt-8 py-4 px-6">
      <Select
        options={options}
        className="grow"
        autoFocus
        isLoading={isFetchingOptions}
        filterOption={(o) => true}
        onChange={(v) => {
          v.value?.boundDescription && dispatch(addSearch(v.value?.boundDescription));
          closeForm();
        }}
        onInputChange={(v) => setQuery(v)}
        isClearable
        onBlur={closeForm}
        placeholder={formatMessage(messages.write)}
      />
    </PanelContainer>
  );
};

export default memo(AddTopInsightsSearchQuery);
