/**
 *
 * BubbleViewItemTooltip
 *
 */

import React, {
  memo,
  FunctionComponent,
  MouseEvent,
  useState,
  useRef,
} from 'react';
import { formatLongNumber } from '../../../../helpers/ValueFormat.helper';

import { EntityProperties, Scope } from 'asserts-types';
import formatProperties from '../../../../helpers/FormatProperties.helper';
import messages from './messages';
import EnvSiteTag from '../../../../components/EnvSiteTag/EnvSiteTag.component';
import { Graph } from '@antv/g6';
import { DISABLED_STATE_BUBBLE_ITEM_OPACITY } from '../BubbleViewItem/constants';
import { useIntl } from 'react-intl';
import { Tooltip } from 'components/Tooltip/Tooltip.component';

interface IProps {
  tagColor: string;
  type: string;
  properties: EntityProperties;
  label?: string;
  scope: Scope;
  unit: string;
  graph?: Graph;
  itemId?: string;
}

const BubbleViewItemTooltip: FunctionComponent<IProps> = ({
  properties,
  label,
  scope,
  unit,
  graph,
  itemId,
}) => {
  const intl = useIntl();
  const [copyShow, setCopyShow] = useState<'label' | number | null>();
  const objectArray = formatProperties(properties);

  const copyText = (e: MouseEvent<HTMLSpanElement>, text: string, type: 'label' | number) => {
    e.stopPropagation();
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text);
      setCopyShow(type);
      setTimeout(() => setCopyShow(null), 1000);
    }
  };

  const mouseMoveCounter = useRef(0);

  // we use mousemove here because mouseenter doesn't work (conflict with node:mouseleave event and blinking)
  // this is needed for keeping opacity active item state while hovering tooltip
  const handleMouseMove = () => {
    if (!itemId || mouseMoveCounter.current > 3) {
      return;
    }

    graph?.setAutoPaint(false);
    graph?.getNodes().forEach((node) => {
      if (itemId !== node.getID()) {
        graph?.updateItem(node, {
          style: { opacity: DISABLED_STATE_BUBBLE_ITEM_OPACITY },
        });
      } else {
        graph?.updateItem(node, {
          style: { opacity: 1 },
        });
      }
    });
    graph?.paint();
    graph?.setAutoPaint(true);
    mouseMoveCounter.current++;
  };

  const handleMouseLeave = () => {
    if (!itemId) {
      return;
    }

    graph?.setAutoPaint(false);
    graph?.getNodes().forEach((node) => {
      graph?.updateItem(node, {
        style: { opacity: 1 },
      });
    });
    graph?.paint();
    graph?.setAutoPaint(true);
  };

  return (
    <div onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
      <div className="m-0 pt-4 flex flex-col items-center">
        <div>
          {!!label && (
            <Tooltip content={intl.formatMessage(messages.copied)} placement="top" show={copyShow === 'label'}>
              <div
                className="pb-[5px] text-base hover:cursor-pointer hover:underline"
                onClick={(e) => copyText(e, label, 'label')}
              >
                {label}
              </div>
            </Tooltip>
          )}
          <div className="flex justify-start mb-2.5">{scope.env && <EnvSiteTag scope={scope} />}</div>
        </div>
        {objectArray.map(([key, value], index) => (
          <Tooltip key={key} content={intl.formatMessage(messages.copied)} placement="top" show={copyShow === index}>
            <div
              className="text-lg text-center hover:cursor-pointer hover:underline"
              onClick={(e) => copyText(e, `${value} ${unit}`, index)}
            >
              {formatLongNumber(value)} {unit}
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};

export default memo(BubbleViewItemTooltip);
