import { ChartTooltipType, EntityAssertionDetails } from 'asserts-types';
import { Chart, Event } from '@antv/g2';
import { useEffect, RefObject, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveChartPoint } from '../Assertions.slice';
import useDidUpdateEffect from '../../../hooks/useDidUpdate';
import { CHART_CROSSHAIRS_STYLE } from '../../../components/LineRangeChart/constants';
import { useAppSelector } from 'app/store';

interface IProps {
  chartRef: RefObject<Chart> | undefined | null;
  chartData: EntityAssertionDetails | undefined;
  key?: string;
}

export default function useSyncChartTooltips({ chartRef, chartData, key }: IProps) {
  const dispatch = useDispatch();

  // this ref is created to understand which chart is hovered currently
  const drivenTooltipShown = useRef(false);

  const activeChartPoint = useSelector(
    (state: RootState) => state.assertions.activeChartPoint,
  );

  const chartTooltip = useSelector(
    (state: RootState) => state.assertions.chartTooltip,
  );

  const selectedTime = useAppSelector((state) => state.assertions.selectedTime);

  useEffect(() => {
    const chart = chartRef?.current;

    if (chart && chartData) {
      const handleMouseMove = (e: Event) => {
        if (drivenTooltipShown.current && !chart.isTooltipLocked()) {
          dispatch(setActiveChartPoint({ x: e.x, y: e.y }));
        }
      };

      const handleTooltipHide = () => {
        if (drivenTooltipShown.current) {
          dispatch(setActiveChartPoint(undefined));
        }
        drivenTooltipShown.current = false;
      };

      const handleTooltipShow = () => {
        drivenTooltipShown.current = true;
      };

      const handleMouseEnter = () => {
        chart.on('mousemove', handleMouseMove);
        chart.on('tooltip:hide', handleTooltipHide);
        chart.on('tooltip:show', handleTooltipShow);
      };

      const handleMouseLeave = () => {
        chart.off('mousemove', handleMouseMove);
        chart.off('tooltip:hide', handleTooltipHide);
        chart.off('tooltip:show', handleTooltipShow);
        drivenTooltipShown.current = false;
        dispatch(setActiveChartPoint(undefined));
      };

      chart.on('mouseenter', handleMouseEnter);
      chart.on('mouseleave', handleMouseLeave);

      return () => {
        chart.off('mouseenter', handleMouseEnter);
        chart.off('mouseleave', handleMouseLeave);
        chart.off('tooltip:hide', handleTooltipHide);
        chart.off('tooltip:show', handleTooltipShow);
        chart.off('mousemove', handleMouseMove);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, key]);

  // code for locking all tooltips when time is locked
  useDidUpdateEffect(() => {
    const chart = chartRef?.current;

    if (!chart || !chartData) {
      return;
    }

    if (selectedTime) {
      chart.lockTooltip();
      // set all tooltips to selectedTime position
      const selectedPoint = chart.getXY({
        time: selectedTime.valueOf(),
        value: 0,
      });

      // this hack is for case when tooltip isn't initialized yet but sync already fires it
      if (!chart.getEvents()['tooltip:show']) {
        setTimeout(() => {
          chart.showTooltip({ x: selectedPoint.x, y: 10 });
        });
      } else {
        chart.showTooltip({ x: selectedPoint.x, y: 10 });
      }
    } else {
      chart.unlockTooltip();
      chart.hideTooltip();
    }
  }, [selectedTime, chartData, chartRef?.current?.id]);

  // code that reacts on activeChartPoint and change all binded tooltip positions
  useDidUpdateEffect(() => {
    const chart = chartRef?.current;
    
    if (
      chartTooltip === ChartTooltipType.DEFAULT &&
      drivenTooltipShown.current
    ) {
      chart?.tooltip({
        ...CHART_CROSSHAIRS_STYLE,
        showCrosshairs: true,
      });
    }

    if (!chart || selectedTime || drivenTooltipShown.current) {
      return;
    }

    if (chartTooltip === ChartTooltipType.DEFAULT) {
      chart.hideTooltip();
      return;
    }

    if (chartTooltip === ChartTooltipType.SHARED_LINE) {
      chart?.tooltip({
        ...CHART_CROSSHAIRS_STYLE,
        showCrosshairs: false,
      });
      chart.hideTooltip();
      return;
    }

    if (chartTooltip === ChartTooltipType.SHARED_TOOLTIP) {
      chart?.tooltip({
        ...CHART_CROSSHAIRS_STYLE,
        showCrosshairs: false,
      });
    }

    if (activeChartPoint) {
      chart.showTooltip(activeChartPoint);
    } else {
      chart.hideTooltip();
    }
  }, [activeChartPoint]);
}
