/*
 *
 * TopInsights constants
 *
 */

import { SAAFE } from "asserts-types";

export const SLICE_NAME = 'topInsights';

export const ROOT_ENTITY_HEIGHT = 150;
export const SCRORE_H_WIDTH = 40;
export const TOPINSIGHT_LINE_HEIGHT = 69;

export const TI_ITEM_SHORT_LIST_LENGTH = 3;

export const SAAFE_PROPERTIES: SAAFE[] = ['saturation', 'amend', 'anomaly', 'failure', 'error'];

export const MAX_SEARCHES_COUNT = 3;
