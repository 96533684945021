/**
 *
 * TopInsightsSeverityPercentageStats
 *
 */

import { AssertionRollup, AssertionScore } from 'asserts-types';
import React, { memo, FunctionComponent } from 'react';
import { twMerge } from 'tailwind-merge';
import { Badge } from '@grafana/ui';

interface IProps {
  rootScores: AssertionScore | undefined;
  assertionsRollup: AssertionRollup[] | undefined;
  className?: string;
}

const TopInsightsSeverityPercentageStats: FunctionComponent<IProps> = ({
  rootScores,
  assertionsRollup,
  className,
}) => {
  if (!rootScores?.totalScore || !assertionsRollup) {
    return null;
  }

  const getPercentage = (severity: 'info' | 'warning' | 'critical') =>
    Math.round(
      ((rootScores?.severityWiseTotalScores[severity] || 0) * 100) /
        rootScores.totalScore,
    );

  const getCount = (field: 'warningCount' | 'infoCount' | 'criticalCount') =>
    assertionsRollup
      .map((item) => item[field])
      .reduce((a = 0, b = 0) => a + b, 0);

  return (
    <div className={twMerge('flex items-center gap-4 px-6 cursor-pointer ' + (className || ''))}>
      {Boolean(getPercentage('critical')) && (
        <Badge
          text={`Critical ${getCount('criticalCount')}`}
          color="red"
          tooltip="Critical Assertions"
          icon="exclamation-circle"
        />
      )}
      {Boolean(getPercentage('warning')) && (
        <Badge
          text={`Warning ${getCount('warningCount')}`}
          color="orange"
          tooltip="Warning Assertions"
          icon="exclamation-triangle"
        />
      )}
      {Boolean(getPercentage('info')) && (
        <Badge
          text={`Info ${getCount('infoCount')}`}
          color="blue"
          tooltip="Info Assertions"
          icon="info-circle"
        />
      )}
    </div>
  );
};

export default memo(TopInsightsSeverityPercentageStats);
