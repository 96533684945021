/**
 *
 * IncidentsTopMenu
 *
 */

import React, { memo, FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import { setIncidentSearch } from '../../Incidents.slice';
import { connect, ConnectedProps } from 'react-redux';

import IncidentsFilterSettings from '../IncidentsFilterSettings/IncidentsFilterSettings.component';
import { Dropdown, Icon, IconButton, Input, Menu, ToolbarButton } from '@grafana/ui';

const connector = connect(
  (state: RootState) => ({
    incidentSearch: state.incidents.incidentSearch,
    filterByType: state.incidents.filterByType,
  }),
  {
    setIncidentSearch,
  }
);

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps {}

const IncidentsTopMenu: FunctionComponent<PropsFromRedux & IProps> = ({
  setIncidentSearch,
  incidentSearch,
  filterByType,
}) => {
  const intl = useIntl();

  return (
    <div className="flex items-center gap-8 divider-b pb-6">
      <div className="grow">
        <Input
          value={incidentSearch}
          placeholder={intl.formatMessage(messages.search)}
          onChange={(e) => setIncidentSearch(e.currentTarget.value)}
          prefix={<Icon name="search" />}
          suffix={<IconButton name="times" aria-label="Clear" onClick={() => setIncidentSearch('')} />}
        />
      </div>
      <div>
        <Dropdown
          overlay={
            <Menu>
              <div onClick={(e) => e.stopPropagation()} className="p-4">
                <IncidentsFilterSettings />
              </div>
            </Menu>
          }
          placement="bottom-start"
        >
          <ToolbarButton variant="canvas" icon="filter" isHighlighted={filterByType.length !== 0} />
        </Dropdown>
      </div>
    </div>
  );
};

export default connector(memo(IncidentsTopMenu));
