/**
 *
 * TopInsightsIncidents
 *
 */

import React, { memo, FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import EnvSiteTagComponent from '../../../../components/EnvSiteTag/EnvSiteTag.component';
import ArrowCircleRightOutlinedIcon from 'assets/material-icons/arrow_circle_right_FILL0_wght400_GRAD0_opsz24.svg';
import { Link } from 'react-router-dom';
import useTopIncidents from 'hooks/useTopIncidents';
import { Badge, PanelContainer, Spinner } from '@grafana/ui';
import { prefixRoute } from 'utils/utils.routing';
import { ROUTES } from 'global-constants';

interface IProps {
  expanded?: boolean;
  start: number | string;
  end: number | string;
}

const TopInsightsIncidents: FunctionComponent<IProps> = ({
  expanded,
  start,
  end,
}) => {
  const intl = useIntl();

  const { data: incidents, isFetching } = useTopIncidents({
    enabled: true,
    start,
    end,
  });

  return (
    <PanelContainer className="relative" data-tour-target="top-insights-notifications">
      <div className="flex items-center justify-between p-5">
        <p className="flex items-center gap-2 text-base font-bold">
          {intl.formatMessage(messages.header)}

          {Boolean(incidents?.length) && (
            <div className="bg-primary/10 text-primary font-bold rounded-full px-2 py-0.5 inline-flex text-sm">
              {incidents?.length}
            </div>
          )}
        </p>
        {isFetching && <Spinner size={20} className="leading-none" />}
      </div>
      {!Boolean(incidents?.length) && !isFetching && (
        <div className="absolute inset-0 flex items-center justify-center text-secondary text-sm">
          {intl.formatMessage(messages.noData)}
        </div>
      )}
      {expanded && (
        <div className="grid grid-cols-2 gap-px bg-gray-200 dark:bg-neutral-800 divider-t divider-b">
          {incidents?.map((incident) => (
            <div
              className="px-5 py-3 flex items-start justify-between bg-paper gap-2 h-[80px]"
              key={`${incident.name}-${incident.scope?.env}-${incident.scope?.site}-${incident.incidents}`}
              data-cy="ti-incidents-item"
            >
              <div className="min-w-0 overflow-hidden whitespace-nowrap text-ellipsis">
                <Link
                  to={`${prefixRoute(ROUTES.INCIDENTS)}?expand=${incident.name}${
                    incident.scope.env ? '&expandEnv=' + incident.scope.env : ''
                  }${incident.scope.env ? '&expandSite=' + incident.scope.site : ''}`}
                  className="text-primary text-sm hover:underline"
                  title={incident.name}
                >
                  {incident.name}
                </Link>
                {incident.scope && <EnvSiteTagComponent scope={incident.scope} />}
              </div>
              <div className="flex flex-col items-end gap-1">
                <div className="flex items-baseline gap-1">
                  <div className="font-bold text-lg">{incident.incidents}</div>
                  <div className="text-secondary text-xs">
                    {intl.formatMessage(messages.notifications, {
                      count: incident.incidents,
                    })}
                  </div>
                </div>
                <div className="flex items-center gap-1">
                  <div className="font-bold text-xs">
                    {incident.status ? <Badge text="Healthy" color="green" /> : <Badge text="Critical" color="red" />}
                  </div>
                </div>
              </div>
            </div>
          ))}
          {(incidents?.length || 0) % 2 !== 0 && (
            <div className="px-5 py-3 flex items-en justify-end bg-paper">
              <Link
                className="flex items-center gap-1 text-primary no-underline hover:underline"
                to={prefixRoute(ROUTES.INCIDENTS)}
              >
                Open Notifications
                <ArrowCircleRightOutlinedIcon className="svg-icon" />
              </Link>
            </div>
          )}
        </div>
      )}
    </PanelContainer>
  );
};

export default memo(TopInsightsIncidents);
