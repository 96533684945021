import React, { FunctionComponent } from 'react';
import { getStrokeColor } from 'components/GraphinGraph/components/AssertsNode';
import { assertsColors } from 'app/constants';
import PropertiesPopoverComponent from 'components/PropertiesPopover/PropertiesPopover.component';
import GraphHelper from 'helpers/Graph.helper';
import { getIconCodeByType } from 'helpers/Icon.helper';
import { AssertionSeverity, Entity } from 'asserts-types';
import { useTheme2 } from '@grafana/ui';
import { Tooltip } from 'components/Tooltip/Tooltip.component';
import useAppDisplayConfig from 'hooks/useAppDisplayConfig';

interface IProps {
  entity?: Entity;
  size: number;
  className?: string;
}

const AssertsCircle: FunctionComponent<IProps> = ({ entity, size, className }) => {
  const theme = useTheme2();
  const { data: displayConfig } = useAppDisplayConfig();

  const color = getStrokeColor(
    entity?.type === 'Assertion'
      ? {
          severity: entity.properties.asserts_severity as AssertionSeverity,
        }
      : entity?.assertion || undefined
  );
  const connectedColor = getStrokeColor(
    entity?.type === 'Assertion'
      ? {
          severity: entity.properties.asserts_severity as AssertionSeverity,
        }
      : entity?.connectedAssertion || undefined
  );
  const infoColor = assertsColors.info;
  const isAmend = infoColor !== color && entity?.assertion?.amend;
  const isConnectedAmend = infoColor !== connectedColor && entity?.connectedAssertion?.amend;
  const radius = (entity?.type === 'Assertion' ? size / Math.sqrt(2) : size) / 2;
  const innerRadius = (entity?.type === 'Assertion' ? (size - 8) / Math.sqrt(2) : size - 8) / 2;

  const iconOverride = entity && displayConfig?.graph.overrides.entities?.[entity.type]?.icon;
  const iconCode = entity && getIconCodeByType(iconOverride ?? entity.type, entity.properties);

  if (!entity) {
    return null;
  }

  return (
    <Tooltip
      placement="top-start"
      interactive
      content={
        <PropertiesPopoverComponent
          label={entity.name}
          type={entity.type}
          scope={entity.scope}
          entityAssertion={entity.assertion}
          connectedAssertion={entity.connectedAssertion}
          disablePropertiesList
          disableHeader
          connectedEntityTypes={entity.connectedEntityTypes}
          activeNode={GraphHelper.convertToNode(entity)}
          connectionsDefaultExpanded
          className="w-auto p-2"
        />
      }
      className={
        !Boolean(entity?.assertion?.assertions?.length || entity?.connectedAssertion?.assertions?.length)
          ? 'hidden'
          : ''
      }
    >
      <div
        style={{
          width: radius * 2,
          height: radius * 2,
          borderRadius: entity?.type === 'Assertion' ? 0 : 100,
          transform: entity?.type === 'Assertion' ? 'rotate(45deg)' : '0',
          marginLeft: entity?.type === 'Assertion' ? (size - size / Math.sqrt(2)) / 2 : 0,
          background: `radial-gradient(circle at center,${theme.colors.background.primary} ${
            radius - 2
          }px ,transparent ${radius - 1}px ), 
            linear-gradient(90deg , ${color} 0%, ${color} 
            ${isAmend ? `48%, transparent 48%, transparent 52%, ${infoColor} 52%, ${infoColor} 100%` : '100%'})`,
        }}
        className={`flex items-center justify-center relative ${className ? className : ''}`}
      >
        <div
          style={{
            width: innerRadius * 2,
            height: innerRadius * 2,
            borderRadius: entity?.type === 'Assertion' ? 0 : 100,
            fontSize: `${size * 0.4}px`,
            color: theme.colors.primary.main,
            background: `radial-gradient(circle at center,${theme.colors.background.primary} ${
              innerRadius - 2
            }px ,transparent ${innerRadius - 1}px), 
        linear-gradient(90deg , ${connectedColor} 0%, ${connectedColor} 
        ${isConnectedAmend ? `45%, transparent 45%, transparent 55%, ${infoColor} 55%, ${infoColor} 100%` : '100%'})`,
          }}
          className="flex items-center justify-center relative"
        >
          {iconCode && (
            <i
              style={{
                transform: entity?.type === 'Assertion' ? 'rotate(-45deg)' : '0',
                fontSize: innerRadius + 2,
              }}
              className="flex items-center justify-center w-[24px] h-[24px] icon-"
            >
              {String.fromCharCode(iconCode)}
            </i>
          )}
        </div>
      </div>
    </Tooltip>
  );
};

export default AssertsCircle;
