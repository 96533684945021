/**
 *
 * NotificationsChart
 *
 */

import React, { memo, FunctionComponent, useMemo } from 'react';
import bandsAssertionMetricMap from '../../config/bands_assertsion_metric_map.json';
import notifictationsAssertionMetricMap from '../../config/notifications_assertsion_metric_map.json';
import useDebounceValue from 'hooks/useDebounceValue';
import useDidUpdateEffect from 'hooks/useDidUpdate';
import {
  EmbeddedScene,
  QueryRunnerState,
  SceneControlsSpacer,
  SceneFlexItem,
  SceneFlexLayout,
  SceneQueryRunner,
  SceneRefreshPicker,
  SceneTimePicker,
  VizPanel,
} from '@grafana/scenes';
import { FieldColorModeId, LegendDisplayMode, TooltipDisplayMode } from '@grafana/schema';
import { FieldConfigProperty, FieldMatcherID, FieldType } from '@grafana/data';
import { CHART_COLORS_MAP } from 'components/LineRangeChart/constants';
import { assertsColors } from 'app/constants';
import { useMetricsDataSource } from 'hooks/useMetricsDatasource';

interface IProps {
  show: boolean;
  labels: Record<string, string>;
  continuesFor: string | undefined;
  assertion: string | undefined;
}

const NotificationsChart: FunctionComponent<IProps> = ({ show, labels, continuesFor, assertion }) => {
  const metricName: string | undefined = assertion
    ? notifictationsAssertionMetricMap[assertion as keyof typeof bandsAssertionMetricMap]
    : undefined;

  const { data: datasource } = useMetricsDataSource();

  const labelsQuery = Object.entries(labels)
    .map(([key, value]) => `${key}="${value}"`)
    .join(', ');

  const labelsFromMetrics = Object.entries(labels)
    .filter(([key]) => key !== 'alertname')
    .map(([key, value]) => `${key}="${value}"`)
    .join(', ');

  const debouncedContinuesfor = useDebounceValue(continuesFor, 300);

  const queriesForMetrics =
    metricName && labelsFromMetrics
      ? [
          {
            refId: 'avg',
            expr: `avg(${metricName}{${labelsFromMetrics}})`,
          },
          {
            refId: 'max',
            expr: `max(${metricName}{${labelsFromMetrics}})`,
          },
        ]
      : [];

  const metricForBands = assertion ? bandsAssertionMetricMap[assertion as keyof typeof bandsAssertionMetricMap] : null;
  const queriesForBands = metricForBands
    ? [
        {
          refId: 'upper_band',
          expr: `max(${metricForBands}:anomaly_upper_threshold{${labelsFromMetrics}})`,
          legendFormat: 'anomaly_upper_threshold',
        },
        {
          refId: 'lower_band',
          expr: `min(${metricForBands}:anomaly_lower_threshold{${labelsFromMetrics}})`,
          legendFormat: 'anomaly_lower_threshold',
        },
      ]
    : [];

  let queries: QueryRunnerState['queries'] = [
    ...queriesForBands,
    ...queriesForMetrics,
    ...(labelsQuery
      ? [
          {
            refId: 'notifications_query',
            expr: `group by()(
                asserts:alerts{${labelsQuery}} 
             )`,
          },
        ]
      : []),
  ];

  if (continuesFor) {
    const byLabels = labels.asserts_resource_type
      ? 'asserts_env, asserts_site, namespace, container, asserts_resource_type, asserts_source'
      : 'asserts_env, asserts_site, namespace, asserts_request_type, asserts_request_context';
    queries = [
      ...queriesForBands,
      ...queriesForMetrics,
      ...(labelsQuery
        ? [
            {
              refId: 'notifications_query',
              expr: `group by()(
                  min by(${byLabels})(
                      asserts:alerts{${labelsQuery}}
                  ) 
                  == 
                  min by(${byLabels})(
                      asserts:alerts{${labelsQuery}} offset ${continuesFor}m
                  )
              )`,
            },
          ]
        : []),
    ];
  }

  if (!show) {
    queries = [];
  }

  const { scene, $data } = useMemo(() => {
    const $data = new SceneQueryRunner({
      datasource: {
        type: 'prometheus',
        uid: datasource.uid,
      },
      queries,
    });

    const panel = new VizPanel({
      pluginId: 'timeseries',
      title: 'Notification preview',
      options: {
        legend: { displayMode: LegendDisplayMode.Table },
        tooltip: { mode: TooltipDisplayMode.Multi },
        axes: {},
      },
      fieldConfig: {
        defaults: {},
        overrides: [
          {
            matcher: {
              id: FieldMatcherID.byFrameRefID,
              options: 'max',
            },

            properties: [
              {
                id: FieldConfigProperty.Color,
                value: {
                  fixedColor: '#FF8000',
                  mode: FieldColorModeId.Fixed,
                },
              },
            ],
          },
          {
            matcher: {
              id: FieldMatcherID.byFrameRefID,
              options: 'avg',
            },

            properties: [
              {
                id: FieldConfigProperty.Color,
                value: {
                  fixedColor: '#008000',
                  mode: FieldColorModeId.Fixed,
                },
              },
            ],
          },
          {
            matcher: {
              id: FieldMatcherID.byFrameRefID,
              options: 'lower_band',
            },

            properties: [
              {
                id: FieldConfigProperty.Color,
                value: {
                  fixedColor: CHART_COLORS_MAP.lightTheme.thresholds.minmax[0],
                  mode: FieldColorModeId.Fixed,
                },
              },
              {
                id: 'custom.lineWidth',
                value: 0,
              },
            ],
          },
          {
            matcher: {
              id: FieldMatcherID.byFrameRefID,
              options: 'upper_band',
            },

            properties: [
              {
                id: 'custom.lineWidth',
                value: 0,
              },
              {
                id: 'custom.fillBelowTo',
                value: 'anomaly_lower_threshold',
              },
              {
                id: FieldConfigProperty.Color,
                value: {
                  fixedColor: CHART_COLORS_MAP.lightTheme.thresholds.minmax[0],
                  mode: FieldColorModeId.Fixed,
                },
              },
            ],
          },
          {
            matcher: {
              id: FieldMatcherID.byFrameRefID,
              options: 'notifications_query',
            },
            properties: [
              {
                id: 'custom.lineWidth',
                value: 25,
              },
              {
                id: FieldConfigProperty.Color,
                value: {
                  fixedColor: assertsColors.critical,
                  mode: FieldColorModeId.Shades,
                },
              },
              {
                id: FieldConfigProperty.DisplayName,
                value: 'Notification',
              },
            ],
          },
          {
            matcher: {
              id: FieldMatcherID.byType,
              options: FieldType.number,
            },
            properties: [
              {
                id: 'custom.axisPlacement',
                value: 'hidden',
              },
            ],
          },
        ],
      },
    });

    const scene = new EmbeddedScene({
      $data,
      controls: [
        new SceneControlsSpacer(),
        new SceneTimePicker({
          isOnCanvas: true,
        }),
        new SceneRefreshPicker({
          isOnCanvas: true,
        }),
      ],
      body: new SceneFlexLayout({
        children: [
          new SceneFlexItem({
            body: panel,
          }),
        ],
      }),
    });

    return { scene, $data };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDidUpdateEffect(() => {
    if (labelsQuery) {
      $data.setState({
        queries,
      });

      $data.runQueries();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelsQuery, debouncedContinuesfor]);

  if (!show) {
    return null;
  }

  return (
    <>
      <div className="h-[380px] relative mb-4 flex items-center justify-center">
        <scene.Component model={scene} />
      </div>
    </>
  );
};

export default memo(NotificationsChart);
