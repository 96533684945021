import { useDispatch } from 'react-redux';

import { MAX_SEARCHES_COUNT } from '../constants';
import {
  toggleExpandSearch as toggleExpandSearchAction,
  addSearch as addSearchAction,
  togglePinnedSearch as togglePinnedSearchAction,
  removeSearch as removeSearchAction,
} from '../TopInsights.slice';
import messages from '../messages';
import { stringToDate } from '../../../helpers/Date.helper';
import { orderBy } from 'lodash';
import { useAppSelector } from 'app/store';
import SnackbarHelper from 'helpers/Snackbar.helper';

// interface Params {}

export default function useTopInsightsSearches() {
  const dispatch = useDispatch();

  const start = useAppSelector((state) => state.app.start);
  const end = useAppSelector((state) => state.app.end);

  const pinnedSearches = useAppSelector(
    (state) => state.topInsights.pinnedSearches,
  );

  const topInsightsSearches = useAppSelector((state) =>
    orderBy(
      state.topInsights.topInsightsSearches,
      [
        (o) => pinnedSearches.includes(o),
        (o) => (pinnedSearches.length ? o.toLocaleLowerCase() : undefined),
      ],
      ['desc', 'asc'],
    ),
  );

  const selectedEnv = useAppSelector((state) => state.app.selectedEnv);

  const selectedSite = useAppSelector((state) => state.app.selectedSite);

  const expandedSearches = useAppSelector((state) => state.topInsights.expandedSearches);

  const isAdded = (query: string) =>
    topInsightsSearches
      .map((q) => q.toLowerCase())
      .includes(query.toLowerCase());

  const isPinned = (query: string) =>
    pinnedSearches.map((q) => q.toLowerCase()).includes(query.toLowerCase());

  const isExpanded = (query: string) =>
    expandedSearches.map((q) => q.toLowerCase()).includes(query.toLowerCase());

  // const sortedSearches = topInsightsSearches.slice().sort((a, b) => {
  //   const va = isPinned(a) ? 1 : 0;
  //   const vb = isPinned(b) ? 1 : 0;
  //   return vb - va;
  // });

  const addSearch = (query: string) => {
    dispatch(addSearchAction(query));
  };

  const removeSearch = (query: string) => {
    dispatch(removeSearchAction(query));
  };

  const togglePinnedSearch = (query: string) => {
    dispatch(togglePinnedSearchAction(query));
  };

  const toggleExpanded = (query: string) => {
    dispatch(toggleExpandSearchAction(query));
  };

  const canDelete = topInsightsSearches.length !== 1;
  const canPin = pinnedSearches.length < MAX_SEARCHES_COUNT - 1;

  const copyLink = (query: string) => {
    let url = new URL(window.location.href);
    url.searchParams.set('start', stringToDate(start).valueOf().toString());
    url.searchParams.set('end', stringToDate(end).valueOf().toString());
    url.searchParams.set('search', query);

    selectedEnv.forEach((env) => url.searchParams.append('env', env));

    selectedSite.forEach((env) => url.searchParams.append('site', env));

    navigator.clipboard.writeText(url.toString());
    SnackbarHelper.success(messages.copied);    
  };

  const manageInitialExpand = () => {
    const firstSearch = topInsightsSearches[0];
    if (!isExpanded(firstSearch)) {
      toggleExpanded(firstSearch);
    }
  };

  return {
    topInsightsSearches,
    pinnedSearches,
    togglePinnedSearch,
    isAdded,
    addSearch,
    isPinned,
    isExpanded,
    toggleExpanded,
    canDelete,
    canPin,
    copyLink,
    manageInitialExpand,
    removeSearch,
  };
}
