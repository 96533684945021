import { NavModelItem } from '@grafana/data';

const buildParentObj: any = (items: NavModelItem[]) => {
  const [curr, ...rest] = items;
  if (rest.length) {
    return {
      parentItem: {
        ...curr,
        ...buildParentObj(rest),
      },
    };
  } else {
    return {
      parentItem: curr,
    };
  }
};

const generateBreadcrumbs = (text: string, parentItems: NavModelItem[]) => {
  const obj = buildParentObj(parentItems);
  return {
    text: text,
    ...obj,
  };
};

export default generateBreadcrumbs;
