import { PluginPage } from '@grafana/runtime';
import React, { useEffect, useMemo, useState } from 'react';

import {
  Alert,
  Button,
  LoadingPlaceholder,
  FieldValidationMessage,
  Column,
  InteractiveTable,
  Checkbox,
  Input,
  IconButton,
  Icon,
  InlineField,
  InlineSwitch,
  TextLink,
  Select,
  Field,
  Switch,
  Stack,
  Box,
  useStyles2,
  Text,
  ConfirmModal,
  Divider,
  MultiSelect,
} from '@grafana/ui';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import errorMessages from 'app/errorMessages';
import { useVendorsList } from './hooks/useVendorsList';
import { DEFAULT_ENV_LABEL, useConnectedDataForm } from './hooks/useConnectedDataForm';
import ConfigurationTopMenu from '../ConfigurationTopMenu/ConfigurationTopMenu';
import { useConnectedDataConfig } from './hooks/useConnectedDataConfig';
import { LabelsFilter } from 'components/LabelsFilter/LabelsFilter';
import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import useBackendStatus from 'hooks/useBackendStatus';
import { AssertsBackendStatus } from 'asserts-types';
import { useLabelKeys } from 'hooks/useLabelKeys';
import { useLabelValues } from 'hooks/useLabelValues';
import { css } from '@emotion/css';
import { usePreOnboardingCheck } from './hooks/usePreOnboardingCheck';
import { useDeactivateAsserts } from './hooks/useDeactivateAsserts';

const allOption = { value: 'all', label: 'all' };

function PluginPageWrapper({ children }: { children: React.ReactNode }) {
  return (
    <PluginPage renderTitle={() => <h1>Configuration</h1>} pageNav={{ text: 'Connect environment' }}>
      <ConfigurationTopMenu />
      {children}
    </PluginPage>
  );
}

export default function ConnectData() {
  const styles = useStyles2(getStyles);
  const [filterVendorsQuery, setFilterVendorsQuery] = useState('');
  const [showSelectedOnly, setShowSelectedOnly] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);

  const { data: backendStatus } = useBackendStatus();

  const [mode, setMode] = useState<'all' | 'filter'>('all');

  const { data: config, isFetching: isFetchingConfig } = useConnectedDataConfig();
  const { data: preOnboardingData, isFetching: isFetchingPreOnboardingInfo } = usePreOnboardingCheck();
  const { mutateAsync: deactivateAsserts } = useDeactivateAsserts();

  const { formData, control, onSubmit, isSubmitting, setValue, isSuccess, isError, watch } =
    useConnectedDataForm(config);

  const envLabel = watch('envLabel');
  const siteLabel = watch('siteLabel');
  const { data: vendors, isFetching: isFetchingVendors } = useVendorsList();

  useEffect(() => {
    if (config?.filters.length) {
      setMode('filter');
    }
  }, [config]);

  const frameworksTableData = useMemo(
    () =>
      vendors
        ?.slice()
        ?.sort()
        ?.filter((v) => v.includes(filterVendorsQuery))
        ?.filter((v) => (showSelectedOnly ? formData.vendors.includes(v) : true))
        ?.map((vendorName) => ({
          name: vendorName,
        })) || [],
    //eslint-disable-next-line
    [vendors, filterVendorsQuery, showSelectedOnly]
  );

  const frameworksColumns = useMemo<Array<Column<(typeof frameworksTableData)[number]>>>(
    () => [
      {
        id: 'cbx',
        //@ts-ignore
        header: () => (
          <Checkbox
            checked={formData.vendors.length === vendors?.length}
            indeterminate={!!formData.vendors.length && formData.vendors.length !== vendors?.length}
            onClick={() => {
              if (formData.vendors.length) {
                setValue('vendors', []);
              } else {
                setValue('vendors', vendors || []);
              }
            }}
          />
        ),
        cell: (props) => {
          const { name } = props.row.original;
          const checked = formData.vendors.includes(name);
          return (
            <Checkbox
              checked={checked}
              onClick={() =>
                setValue('vendors', checked ? formData.vendors.filter((v) => v !== name) : [...formData.vendors, name])
              }
            />
          );
        },
        disableGrow: true,
      },
      {
        id: 'name',
        //@ts-ignore
        header: () => (
          <Text color="secondary">
            {formData.vendors.length}/{vendors?.length || 0} selected
          </Text>
        ),
      },
    ],
    //eslint-disable-next-line
    [formData.vendors, vendors]
  );

  const intl = useIntl();

  const { data: keys, isFetching: isKeysFetching } = useLabelKeys({ enabled: true });

  const keysOptions: SelectableValue<string>[] = useMemo(
    () => keys?.map((k) => ({ label: k.text, value: k.text })) || [],
    [keys]
  );

  const { data: envValues } = useLabelValues({
    enabled: true,
    key: envLabel,
  });

  const { data: siteValues } = useLabelValues({
    enabled: true,
    key: siteLabel,
  });

  const envOptions = useMemo(() => {
    const options = envValues?.map((v) => ({ label: v.text, value: v.text })) || [];
    options.unshift(allOption);
    return options;
  }, [envValues]);

  const siteOptions = useMemo(() => {
    const options = siteValues?.map((v) => ({ label: v.text, value: v.text })) || [];
    options.unshift(allOption);
    return options;
  }, [siteValues]);

  const handleSelectEnv = (values: string[]) => {
    if (values.includes('all') && values[values.length - 1] === 'all') {
      setValue('envLabelValues', []);
    } else {
      setValue(
        'envLabelValues',
        values.filter((v) => v !== 'all')
      );
    }
  };

  const handleSelectSite = (values: string[]) => {
    if (values.includes('all') && values[values.length - 1] === 'all') {
      setValue('siteLabelValues', []);
    } else {
      setValue(
        'siteLabelValues',
        values.filter((v) => v !== 'all')
      );
    }
  };


  const renderBlockers = () =>
    preOnboardingData.k8sCheck?.stepResults.length ? (
      <Alert title="Missing items" severity="error">
        <ul>
          {preOnboardingData.k8sCheck?.stepResults
            .filter((r) => r.blockers?.length)
            .map((blocker) => (
              <li key={blocker.name}>
                <Text element="p">{blocker.name}</Text>
                {blocker.blockers?.map((blockerMessage, index) => (
                  <Box paddingLeft={1} key={index}>
                    <Text element="p" key={blockerMessage} color="secondary">
                      {blockerMessage}
                    </Text>
                  </Box>
                ))}
              </li>
            ))}
        </ul>
      </Alert>
    ) : null;

  const renderDeactivateButton = () => (
    <>
      <ConfirmModal
        isOpen={showDeactivateModal}
        title="Deactivate Grafana Cloud Asserts"
        body="Are you sure you want to deactivate Grafana Cloud Asserts? You can reduce the number of metrics generated by Asserts by adding filters."
        confirmText="Deactivate"
        onConfirm={() => deactivateAsserts().then(() => setShowDeactivateModal(false))}
        onDismiss={() => setShowDeactivateModal(false)}
      />
      <Button variant="destructive" onClick={() => setShowDeactivateModal(true)}>
        Deactivate
      </Button>
    </>
  );

  if (
    backendStatus?.status === AssertsBackendStatus.PENDING_DISABLE ||
    backendStatus?.status === AssertsBackendStatus.PROCESSING_PENDING_DISABLE
  ) {
    return (
      <PluginPageWrapper>
        <Box paddingTop={2}>
          <Alert title="We're deactivating Grafana Cloud Asserts..." severity="warning"></Alert>
        </Box>
      </PluginPageWrapper>
    );
  }

  if (isFetchingPreOnboardingInfo) {
    return (
      <PluginPageWrapper>
        <Box paddingY={2}>
          <LoadingPlaceholder text="We’re checking your system’s compatibility with Grafana Cloud Asserts..." />
        </Box>
      </PluginPageWrapper>
    );
  }

  if (preOnboardingData?.cardinalityCheckPassed === false && backendStatus?.enabled === false) {
    return (
      <PluginPageWrapper>
        <Box paddingTop={2}>
          <Alert title="Too many active series" severity="warning">
            <Box display="flex" gap={1} direction="column" marginTop={1}>
              <div>
                To reliably manage load and ensure a great experience, Grafana Labs Support is currently assisting with
                onboarding for customers with more than 5 million active series.
              </div>
              <div>
                Please{' '}
                <TextLink href="https://grafana.com/contact" color="link">
                  submit a ticket
                </TextLink>
                , and we will contact you.
              </div>
            </Box>
          </Alert>
        </Box>
      </PluginPageWrapper>
    );
  }

  if (preOnboardingData.k8sCheck?.dataPresent === false && backendStatus?.enabled === false) {
    return (
      <PluginPageWrapper>
        <Box paddingTop={4} maxWidth="750px">
          <h3>Missing prerequisites</h3>
          <Divider />
          <Stack gap={2} direction="column">
            <Text element="p" tabular>
              To discover and map entities, Asserts requires infrastructure and RED metrics in a Prometheus format.
              Currently, Asserts only supports automatically obtaining infrastructure metrics from Kubernetes.
            </Text>
            <Text element="p">
              We are continuing to broaden our support for other metric sources and custom metrics. If you’re interested
              in integrating your custom telemetry with Asserts, please{' '}
              <TextLink href="https://grafana.com/contact" color="link">
                submit a ticket
              </TextLink>{' '}
              and we will be in contact.
            </Text>
            <Text element="p" color="secondary">
              For more information about prerequisites and instrumenting for Asserts, refer to{' '}
              <TextLink href="https://grafana.com/docs/grafana-cloud/monitor-applications/asserts/get-started/prerequisites-troubleshooting/" color="link">
                LINK
              </TextLink>
              .
            </Text>
            {renderBlockers()}
          </Stack>
        </Box>
      </PluginPageWrapper>
    );
  }

  // the case where k8s env was detected but there are blockers
  if (
    preOnboardingData.k8sCheck?.dataPresent === true &&
    preOnboardingData.k8sCheck.stepResults.filter((r) => r.blockers?.length).length &&
    backendStatus?.enabled === false
  ) {
    return (
      <PluginPageWrapper>
        <Box paddingTop={4} maxWidth="750px">
          <h3>Missing prerequisites</h3>
          <Divider />
          <Stack gap={2} direction="column">
            <Text element="p" tabular>
              Currently, Asserts only supports automatically obtaining infrastructure metrics from Kubernetes. We
              detected your Kubernetes metrics, but there are some blockers preventing the use of Asserts.
            </Text>
            {renderBlockers()}
            <div>
              If you need help with configuration, please{' '}
              <TextLink href="https://grafana.com/contact" color="link">
                Contact support
              </TextLink>
              .
            </div>
          </Stack>
        </Box>
      </PluginPageWrapper>
    );
  }

  // TODO: remove this when we have a proper setup for all customers
  if (backendStatus?.status === AssertsBackendStatus.LEGACY) {
    return (
      <PluginPage renderTitle={() => <h1>Configuration</h1>} pageNav={{ text: 'Connect environment' }}>
        <ConfigurationTopMenu />
        <Box paddingTop={2}>
          <Alert title="Your environment uses a custom Asserts setup" severity="info">
            <div>
              To update or change your configuration, please{' '}
              <TextLink href="https://grafana.com/contact" color="link">
                contact support
              </TextLink>
              .
            </div>
          </Alert>
        </Box>
      </PluginPage>
    );
  }

  return (
    <PluginPageWrapper>
      {isFetchingConfig && (
        <Box paddingY={2}>
          <LoadingPlaceholder text="Loading..." />
        </Box>
      )}
      <Box display="flex" direction="column" paddingY={2}>
        {!isFetchingConfig && (
          <Alert title="Additional metrics" severity="info">
            <Stack direction="column">
              <div>
                Asserts generates additional metrics that count towards your current usage and bill. Your Grafana Cloud
                Metrics storage is estimated to increase by 5%.
              </div>
              <div>
                At any time, you can disable Asserts or edit your data configuration to exclude clusters or namespaces.
              </div>
            </Stack>
          </Alert>
        )}
        {!isFetchingConfig && backendStatus?.status === AssertsBackendStatus.PENDING && (
          <Alert title="We're getting set up..." severity="info">
            <div>Asserts needs some time to configure your environment. Please, check back in a few minutes.</div>
          </Alert>
        )}
        {!isFetchingConfig &&
          preOnboardingData.istioCheck?.dataPresent === false &&
          preOnboardingData.otelTracesCheck?.dataPresent === false && (
            <Alert title="Missing RED metrics" severity="warning">
              <Stack direction="column">
                <div>
                  Asserts has discovered infrastructure metrics but not RED metrics. For more information about
                  prerequisites and instrumenting for Asserts, refer to{' '}
                  <TextLink href="https://grafana.com/docs/grafana-cloud/monitor-applications/asserts/get-started/prerequisites-troubleshooting/" color="link">
                    LINK
                  </TextLink>
                  .
                </div>
                <div>
                  For assistance mapping custom or non-standard RED metrics, please{' '}
                  <TextLink href="https://grafana.com/contact" color="link">
                    submit a ticket
                  </TextLink>
                  , and we will contact you.
                </div>
              </Stack>
            </Alert>
          )}
      </Box>
      {!isFetchingConfig && (
        <ol className={styles.list}>
          <li>
            <h3 className={styles.heading}>Specify environment and labels</h3>
            <Stack gap={1.5} direction="column">
              <Stack gap={4} alignItems="start">
                <Controller
                  name="envLabel"
                  control={control}
                  rules={{
                    required: intl.formatMessage(errorMessages.required),
                  }}
                  render={({ field, fieldState }) => (
                    <Field
                      label="Environment label"
                      description="If you have a consistent label for your environment, choose it here. Otherwise, we recommend setting this to `cluster`."
                      invalid={!!fieldState.error}
                      error={fieldState.error?.message}
                      className={styles.envField}
                    >
                      <Select
                        width="auto"
                        placeholder={'Select label'}
                        options={keysOptions}
                        value={field.value}
                        onChange={(v) => {
                          field.onChange(v?.value);
                          setValue('envLabelValues', []);                          
                          if (v?.value !== DEFAULT_ENV_LABEL) {
                            setValue('siteLabel', DEFAULT_ENV_LABEL);
                          }
                        }}
                        isLoading={isKeysFetching}
                        openMenuOnFocus={true}
                      />
                    </Field>
                  )}
                />
                {envLabel && !!envValues?.length && (
                  <Controller
                    control={control}
                    name="envLabelValues"
                    render={({ field }) => (
                      <Field label="Environments" description="Asserts will monitor the items you select here.">
                        <MultiSelect
                          options={envOptions}
                          value={field.value.length ? field.value.map((v) => ({ label: v, value: v })) : [allOption]}
                          onChange={(values) => handleSelectEnv(values?.map((v) => v.value || '') || [])}
                          closeMenuOnSelect={false}
                        />
                      </Field>
                    )}
                  />
                )}
              </Stack>
              <Stack gap={4} alignItems="start">
                <Controller
                  name="siteLabel"
                  control={control}
                  render={({ field }) => (
                    <Field
                      label="Site label"
                      description="Leave this field blank if you selected “cluster” above. Otherwise, we recommend setting this to `cluster`."
                    >
                      <Select
                        width="auto"
                        placeholder={'Select label'}
                        options={keysOptions}
                        value={field.value}
                        onChange={(v) => {
                          field.onChange(v?.value || '');
                          setValue('siteLabelValues', []);
                        }}
                        isLoading={isKeysFetching}
                        openMenuOnFocus={true}
                        isClearable
                      />
                    </Field>
                  )}
                />
                {siteLabel && !!siteValues?.length && (
                  <Controller
                    control={control}
                    name="siteLabelValues"
                    render={({ field }) => (
                      <Field label="Sites" description="Asserts will monitor the items you select here.">
                        <MultiSelect
                          options={siteOptions}
                          value={field.value.length ? field.value.map((v) => ({ label: v, value: v })) : [allOption]}
                          onChange={(values) => handleSelectSite(values?.map((v) => v.value || '') || [])}
                          closeMenuOnSelect={false}
                        />
                      </Field>
                    )}
                  />
                )}
              </Stack>
              <Field
                label="Add filters (optional)"
                description="Filters determine which metrics you want Asserts to process"
              >
                <Stack direction="column" gap={1}>
                  <Switch value={mode === 'filter'} onChange={() => setMode(mode === 'all' ? 'filter' : 'all')} />
                  {mode === 'filter' && <LabelsFilter control={control} />}
                </Stack>
              </Field>
            </Stack>
          </li>
          <li>
            <Stack gap={2}>
              <h3 className={styles.heading}>Select frameworks</h3>
              {isFetchingVendors && <LoadingPlaceholder text="Loading entities..." />}
            </Stack>
            <Box marginBottom={2} maxWidth="750px">
              <Text>
                Asserts detected the following frameworks. Choose which frameworks you’d like Asserts to monitor. If
                frameworks you expect are not available, visit <TextLink href="/connections">Connections</TextLink> to
                review the data that you are storing in Grafana Cloud.
              </Text>
            </Box>

            <Controller
              name="vendors"
              control={control}
              rules={{
                validate: (v) => {
                  if (!v.length) {
                    return intl.formatMessage(errorMessages.required);
                  }
                },
              }}
              render={({ fieldState }) => (
                <>
                  <Box maxWidth="750px" marginBottom={2}>
                    <Stack gap={2}>
                      <Input
                        value={filterVendorsQuery}
                        placeholder="Filter by framework name"
                        onChange={(e) => setFilterVendorsQuery(e.currentTarget.value)}
                        prefix={<Icon name="search" />}
                        suffix={
                          <IconButton name="times" aria-label="Clear" onClick={() => setFilterVendorsQuery('')} />
                        }
                      />
                      <InlineField label="Show selected only">
                        <InlineSwitch value={showSelectedOnly} onClick={() => setShowSelectedOnly(!showSelectedOnly)} />
                      </InlineField>
                    </Stack>
                    {!!fieldState.error && (
                      <FieldValidationMessage>Please select at least one framework</FieldValidationMessage>
                    )}
                    <InteractiveTable
                      columns={frameworksColumns}
                      data={frameworksTableData}
                      getRowId={(r: { name: any }) => r.name}
                      pageSize={10}
                    />
                  </Box>
                </>
              )}
            />
          </li>
          {/* <li>
            <h3>Enable integration alerts</h3>
            <Box marginBottom={2} maxWidth="750px">
              <Text>
                Your selection is supported by Grafana integrations. In order to allow Asserts to surface problematic
                entities via Assertions, alerts for those integrations must be enabled.
              </Text>
            </Box>
          </li> */}
        </ol>
      )}
      <Box marginTop={5}>
        {isSubmitting && (
          <Alert title={'Testing... this could take up to a couple of minutes'} severity={'info'}></Alert>
        )}
        {isError && (
          <Alert title={'Asserts detected some issues during testing'} severity={'error'}>
            <div>
              We didn&apos;t manage to process your metrics, please{' '}
              <TextLink href="https://grafana.com/contact" color="link">
                Contact support
              </TextLink>{' '}
              for custom onboarding.
            </div>
          </Alert>
        )}
        {isSuccess && (
          <Alert title={'Test is successful!'} severity={'success'}>
            <div>It takes us time to get fully set up, so check back shortly.</div>
          </Alert>
        )}
      </Box>
      {!isFetchingConfig && (
        <Stack gap={1}>
          {backendStatus?.status &&
            ![AssertsBackendStatus.NOT_INITIALIZED, AssertsBackendStatus.INCOMPLETE].includes(backendStatus?.status) &&
            renderDeactivateButton()}
          <Button onClick={onSubmit} disabled={isSubmitting}>
            {isSubmitting ? 'Saving configuration...' : 'Test & save configuration'}
          </Button>
        </Stack>
      )}
    </PluginPageWrapper>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    list: css`
      > li {
        margin-left: ${theme.spacing(4)};
        ::marker {
          font-size: ${theme.typography.h3.fontSize};
        }
      }
    `,
    heading: css`
      margin-bottom: ${theme.spacing(2)};
    `,
    tagsList: css`
      justify-content: flex-start;
    `,
    envField: css`
      width: 400px;
    `,
  };
}
