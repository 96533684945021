import { Definition } from 'asserts-types';
import { useQuery } from '@tanstack/react-query';
import { orderBy } from 'lodash';
import { fetchSearchAutocomplete } from 'services/Search.service';

interface UseParams {
  query: string;
  max?: number;
  enabled: boolean;
  onSuccess?: (data: Definition[]) => void;
}

export const USE_DEFINITION_AUTOCOMPLETE_KEY = 'definitions-autocomplete';

export default function useDefinitionAutocomplete({
  query,
  max,
  enabled,
  onSuccess,
}: UseParams) {
  return useQuery(
    [USE_DEFINITION_AUTOCOMPLETE_KEY, query.toLowerCase(), max],
    () =>
      fetchSearchAutocomplete(query, max).then(
        (res) => res.items,
      ),
    {
      enabled,
      keepPreviousData: true,
      select: (data) => orderBy(data, ['filterCriteria'], 'asc'),
      onSuccess,
    },
  );
}
