import { useDispatch } from 'react-redux';
import { setMonitoringStatus as setMonitoringStatusAction } from '../Entities.slice';
import { Entity } from 'asserts-types';
import { useQuery } from '@tanstack/react-query';
import { fetchMonitoringStatus } from 'services/Entity.service';

interface IProps {
  rows: Entity[];
  endMs: number;
}

export default function ({ endMs, rows }: IProps) {
  const dispatch = useDispatch();

  const rowsForRequest = rows.map((item) => ({
    name: item.name,
    type: item.type,
    scope: item.scope,
  }));

  return useQuery(
    ['monitoringStatus', rowsForRequest, endMs],
    async () => {
      const data = await fetchMonitoringStatus(rowsForRequest, endMs);
      dispatch(setMonitoringStatusAction(data.entityStatus));
    },
    {}
  );
}
