import { css } from '@emotion/css';
import { Box, Button, Field, Select, Stack } from '@grafana/ui';
import { getIconCodeByType, getIconNames } from 'helpers/Icon.helper';
import useAppDisplayConfig from 'hooks/useAppDisplayConfig';
import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import useGraphDisplayOverrideForm from '../hooks/useGraphDisplayOverrideForm';
import { ColorPickerButton } from './ColorPickerButton';
import useEntityTypes from 'hooks/useEntityTypes';

export function AddNewGraphDisplayOverrideForm() {
  const { data: displayConfig } = useAppDisplayConfig();
  const { data: entityTypes } = useEntityTypes();
  const { onSubmit, control, formState } = useGraphDisplayOverrideForm();
  const iconOptions = useMemo(() => {
    return getIconNames().map((icon) => ({
      label: (
        <Stack direction="row" gap={1} alignItems="center">
          <i className="icon-">{String.fromCharCode(getIconCodeByType(icon) ?? 0)}</i>
          {icon}
        </Stack>
      ),
      value: icon,
    }));
  }, []);

  return (
    <Box marginY={2}>
      <form onSubmit={onSubmit} className={css({ maxWidth: '700px' })}>
        <Field
          label="Entity Type"
          invalid={Boolean(formState.errors.entityType)}
          error={formState.errors.entityType?.message}
        >
          <Controller
            control={control}
            name="entityType"
            rules={{
              validate: (entityType) => {
                if (displayConfig?.graph?.overrides?.entities[entityType]) {
                  return 'Override for this entity type already exists';
                }
                return;
              },
            }}
            render={({ field }) => (
              <Select
                {...field}
                onChange={(v) => field.onChange(v?.value || '')}
                options={entityTypes?.entities.map(({ entityType }) => ({ label: entityType, value: entityType }))}
                allowCustomValue
              />
            )}
          />
        </Field>
        <Field label="Color" invalid={Boolean(formState.errors.color)} error={formState.errors.color?.message}>
          <Controller control={control} name="color" render={({ field }) => <ColorPickerButton {...field} />} />
        </Field>
        <Controller
          control={control}
          name="icon"
          render={({ field, fieldState }) => {
            return (
              <Field label="Icon" invalid={Boolean(fieldState.error)} error={fieldState.error?.message}>
                <Select
                  {...field}
                  onChange={(v) => field.onChange(v?.value || '')}
                  //@ts-ignore the select component type definition wants a string, but it will render an Element
                  options={iconOptions}
                />
              </Field>
            );
          }}
        />
        <Button type="submit" disabled={formState.isSubmitting}>
          {formState.isSubmitting ? 'Submitting...' : 'Submit'}
        </Button>
      </form>
    </Box>
  );
}
