export const whitelistProperties = [
  'Discovered',
  'Updated',
  'Container',
  'Namespace',
  'Pod Count',
  'Server',
  'Version',
  'Database Type',
  'Instance Type',
  'Zone',
  'Pod Ip',
  'Node Ip',
  'Partitions',
].map((p) => p.toLowerCase());
