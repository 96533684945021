import { ListTabs, KpiDisplayConfig, KpiDisplayView } from 'asserts-types';

export function ensureActiveTabExists(activeTab: ListTabs, kpiViewConfig?: KpiDisplayConfig) {
  let activeTabExists = false;
  if (!kpiViewConfig) {
    return activeTab;
  }
  switch (activeTab) {
    case 'info': {
      if (kpiViewConfig.propertiesView) {
        activeTabExists = true;
        break;
      }
    }
    case 'kpi': {
      if (kpiViewConfig.defaultDashboard) {
        activeTabExists = true;
        break;
      }
    }
    case 'additionalKpi': {
      if (kpiViewConfig.additionalDashboard) {
        activeTabExists = true;
        break;
      }
    }
    case 'frameworkDash': {
      if (kpiViewConfig.frameworkDashboard) {
        activeTabExists = true;
      }
      break;
    }
    case 'runtimeDash': {
      if (kpiViewConfig.runtimeDashboard) {
        activeTabExists = true;
      }
    }
    case 'logs': {
      if (kpiViewConfig.logsView) {
        activeTabExists = true;
        break;
      }
    }
    case 'metrics': {
      if (kpiViewConfig.metricsView) {
        activeTabExists = true;
        break;
      }
    }
    case 'traces': {
      if (kpiViewConfig.tracesView) {
        activeTabExists = true;
        break;
      }
    }
    case 'app-o11y':
    case 'appO11y': {
      if (kpiViewConfig.appO11yAppView) {
        activeTabExists = true;
      }
      break;
    }
    case 'k8s': {
      if (kpiViewConfig.k8sAppView) {
        activeTabExists = true;
      }
      break;
    }
    case 'frontend-o11y': {
      if (kpiViewConfig.frontendO11yAppView) {
        activeTabExists = true;
      }
      break;
    }
    case 'csp': {
      if (kpiViewConfig.awsAppView) {
        activeTabExists = true;
      }
      break;
    }
  }
  if (activeTabExists) {
    return activeTab;
  } else {
    return findFallbackTab(kpiViewConfig);
  }
}

function findFallbackTab(kpiViewConfig: KpiDisplayConfig) {
  const fallbackConfig = Object.entries(kpiViewConfig).find(([_, value]) => value);
  const viewName = fallbackConfig?.[0] as KpiDisplayView | undefined;

  switch (viewName) {
    case 'defaultDashboard':
    case 'additionalDashboard':
    case 'frameworkDashboard':
    case 'runtimeDashboard':
      return 'kpi';
    case 'k8sAppView':
      return 'k8s';
    case 'appO11yAppView':
      return 'app-o11y';
    case 'frontendO11yAppView':
      return 'frontend-o11y';
    case 'awsAppView':
      return 'csp';
    case 'logsView':
      return 'logs';
    case 'tracesView':
      return 'traces';
    case 'metricsView':
      return 'metrics';
    case 'propertiesView':
      return 'info';
    default:
      return 'kpi';
  }
}
