/**
 *
 * IncidentGroupItem
 *
 */

import React, { memo, FunctionComponent } from 'react';
import { GroupedIncident, AssertionEntityTypes } from 'asserts-types';
import { toggleExpandItem } from '../../Incidents.slice';
import TagLabel from 'components/TagLabel/TagLabel';
import EnvSiteTagComponent from 'components/EnvSiteTag/EnvSiteTag.component';

interface IProps {
  groupedIncident: GroupedIncident;
  expanded: boolean;
  toggleExpandItem: typeof toggleExpandItem;
}

const IncidentGroupItem: FunctionComponent<IProps> = ({ groupedIncident, expanded, toggleExpandItem }) => {
  return (
    <div
      className={`opacity-100 py-4 pr-4 flex flex-col divider-b cursor-pointer relative justify-center bg-default`}
      onClick={() => toggleExpandItem(groupedIncident.id)}
    >
      <div className="flex justify-between items-center w-full">
        <div className="mr-2 ml-0 flex grow w-0 items-center">
          <i className={`icon-rounded-${expanded ? 'down' : 'right'} text-gray-300 text-2xl mr-1`} />
          <div className="flex flex-col w-0 grow">
            <p title={groupedIncident.name}>{groupedIncident.name}</p>
            <EnvSiteTagComponent scope={groupedIncident.detail.scope} />
          </div>
        </div>
        <div>
          <TagLabel entityType={AssertionEntityTypes.ASSERTION} scope={groupedIncident.detail.scope} />
        </div>
      </div>
    </div>
  );
};

const IncidentGroupItemContainer = memo(IncidentGroupItem);
export default IncidentGroupItemContainer;
