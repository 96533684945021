import { useAppSelector } from 'app/store';
import { stringToDate } from '../../../helpers/Date.helper';
import { useQuery } from '@tanstack/react-query';
import { findEntityAssertions } from 'services/Assertion.service';

export default function useAssertionsSearch() {
  const start = useAppSelector((state) => state.app.start);
  const end = useAppSelector((state) => state.app.end);

  const searchCriteria = useAppSelector((state) => state.assertionSearch.search.searchCriteria);
  const entityType = useAppSelector((state) => state.assertionSearch.search.entityType);

  return useQuery(
    ['assertions-search', entityType, searchCriteria, start, end],
    () => findEntityAssertions(entityType, searchCriteria, stringToDate(start).valueOf(), stringToDate(end).valueOf()),
    { enabled: false }
  );
}
