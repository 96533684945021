import { useLocation } from 'react-router-dom';
import useDidUpdateEffect from './useDidUpdate';

// TODO: this hook is introduced because it's impossible to detect 'POP' event since it's always 'POP'.
// seems like it's a bug of react-router-dom-v5-compat

export default function useBackButtonPressed(action: () => void) {
  const location = useLocation();

  useDidUpdateEffect(() => {
    // TODO: replace this with
    // const navigationType = useNavigationType();
    // if (navigationType === 'POP') {
    //   action();
    // }
    // when the POP bug is fixed
    if (location.state?.timestamp && Date.now() - location.state.timestamp > 1000) {
      action();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);
}
