import { AppQueryParams } from 'asserts-types';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'app/store';
import { prefixRoute } from 'utils/utils.routing';
import { ROUTES } from 'global-constants';
import { fillAppSliceWithQueryParams } from 'features/App/App.slice';

export function useTopInsightsQueryParams() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const start = useAppSelector((state) => state.app.start);
  const end = useAppSelector((state) => state.app.end);
  const activeEntityDetails = useAppSelector((state) => state.app.activeEntityDetails);
  const env = useAppSelector((state) => state.app.selectedEnv);
  const site = useAppSelector((state) => state.app.selectedSite);

  // reads query string parameters and fill assertions store with values and fill start and end (from entities.slice)
  const readQueryParams = (disableFillingState?: boolean) => {
    const queryParams = qs.parse(window.location.search.slice(1)) as unknown as AppQueryParams;

    // fill common options in app slice
    !disableFillingState && dispatch(fillAppSliceWithQueryParams(queryParams));

    return queryParams;
  };

  const setQueryStringUrl = (noHistoryChange?: boolean) => {
    const queryParams = qs.stringify({
      start,
      end,
      env,
      site,
      ed: activeEntityDetails,
    });

    const route = prefixRoute(ROUTES.TOP_INSIGHTS);

    const url = new URL(window.location.href);
    const replace = url.searchParams.size === 0;

    // push only in case url changed
    if (`${window.location.origin}${route}?${queryParams}` !== window.location.href && !noHistoryChange) {
      navigate(`${route}?${queryParams}`, { state: { timestamp: Date.now() }, replace });
    }
    return queryParams;
  };


  return {
    readQueryParams,
    setQueryStringUrl,
  };
}
