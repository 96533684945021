import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedKpi } from '../../../Entities.slice';
import { GraphCustomData } from 'asserts-types';

const COLUMN_DEFAULTS: Record<string, string[]> = {
  Service: ['RequestRate', 'ErrorRate', 'LatencyAverage'],
  Pod: ['CpuCores', 'MemoryUsage'],
  Node: ['CpuCores', 'MemoryUsage'],
};

export default function useDefaultColumns(graphData: GraphCustomData, bubbleViewPanels: string[]) {
  const dispatch = useDispatch();

  const entityType = graphData.nodes.length ? graphData.nodes[0].entityType : undefined;

  useEffect(() => {
    if (
      bubbleViewPanels.length === 0 &&
      entityType &&
      graphData.nodes.every((item) => item.entityType === entityType) &&
      COLUMN_DEFAULTS[entityType]
    ) {
      dispatch(setSelectedKpi(COLUMN_DEFAULTS[entityType]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphData]);
}
