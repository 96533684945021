import { useQuery } from '@tanstack/react-query';
import { apiHttpService } from 'app/api-http-service';
import { NotificationConfig, Sort } from 'asserts-types';
import { get, orderBy } from 'lodash';

export const USE_NOTIFICATIONS_SLO_LIST_KEY = 'notifications-slo-list';

interface IProps {
  sort: Sort | null;
  filter: string;
}

export default function useNotificationsSloList({ sort, filter }: IProps) {
  return useQuery(
    ['notifications-slo-list'],
    () =>
      apiHttpService
        .get<{ alertConfigs: NotificationConfig[] }>(
          '/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/alerts/slo',
        )
        .then((res) => res.data.alertConfigs),
    {
      select: (data) => {
        let processedData = data.filter(
          (item) =>
            item.name.toLowerCase().includes(filter.toLowerCase()) ||
            item.matchLabels?.additional_labels
              ?.toLowerCase()
              .includes(filter.toLowerCase()) ||
            item.matchLabels?.asserts_slo_name
              ?.toLowerCase()
              .includes(filter.toLowerCase()) ||
            item.for?.toLowerCase().includes(filter.toLowerCase()),
        );

        processedData = processedData.map((item) => ({
          ...item,
          silenced: Boolean(item.silenced),
        }));

        return orderBy(
          processedData,
          [
            sort?.field
              ? (item) => get(item, sort?.field)?.toString().toLowerCase()
              : '',
          ],
          sort?.order,
        );
      },
    },
  );
}
