/*
 * ThresholdList Messages
 *
 * This contains all the text for the ThresholdList component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.ThresholdList';

export default defineMessages({
  assertion: {
    id: `${scope}.assertion`,
    defaultMessage: 'Assertion',
  },
  threshold: {
    id: `${scope}.threshold`,
    defaultMessage: 'Threshold',
  },
  job: {
    id: `${scope}.job`,
    defaultMessage: 'Job',
  },
  asserts_request_type: {
    id: `${scope}.requestType`,
    defaultMessage: 'Request Type',
  },
  asserts_request_context: {
    id: `${scope}.requestContext`,
    defaultMessage: 'Request Context',
  },
  value: {
    id: `${scope}.value`,
    defaultMessage: 'Value #A',
  },
  emptyList: {
    id: `${scope}.emptyList`,
    defaultMessage: 'No items here.',
  },
  asserts_source: {
    id: `${scope}.asserts_source`,
    defaultMessage: 'Source',
  },
  asserts_resource_type: {
    id: `${scope}.asserts_resource_type`,
    defaultMessage: 'Resource Type',
  },
  asserts_severity: {
    id: `${scope}.asserts_severity`,
    defaultMessage: 'Severity',
  },
  container: {
    id: `${scope}.container`,
    defaultMessage: 'Container',
  },
  topic: {
    id: `${scope}.topic`,
    defaultMessage: 'Topic',
  },
});
