/**
 *
 * TooltipConfirmation
 *
 */

import React, {
  memo,
  FunctionComponent,
  ReactElement,
  useState,
  cloneElement,
  MouseEvent,
} from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import messages from './messages';
import { Button, ClickOutsideWrapper } from '@grafana/ui';
import { Tooltip } from 'components/Tooltip/Tooltip.component';
import { IconButton } from 'components/IconButton/IconButton.component';

interface IProps {
  action: () => void;
  actionTextMessage?: MessageDescriptor;
  onOpen?: () => void;
  onClose?: () => void;
}

const TooltipConfirmation: FunctionComponent<IProps & { children: ReactElement<typeof IconButton> }> = ({
  children,
  action,
  actionTextMessage,
  onOpen,
  onClose,
}) => {
  const [open, setOpen] = useState(false);
  const { formatMessage } = useIntl();

  const handleTooltipClose = () => {
    setOpen(false);
    onClose?.();
  };

  const onClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setOpen(true);
    onOpen?.();
  };

  return (
    <ClickOutsideWrapper onClick={handleTooltipClose}>
      <Tooltip
        content={
          <div className="flex items-center gap-2 py-1">
            <p className="text-center mx-2">{formatMessage(messages.question)}</p>
            <Button onClick={action} size="sm" variant="destructive">
              {formatMessage(actionTextMessage ? actionTextMessage : messages.delete)}
            </Button>
            <Button onClick={handleTooltipClose} size="sm" fill="text">
              {formatMessage(messages.cancel)}
            </Button>
          </div>
        }
        show={open}
        interactive
        placement="bottom-end"
      >
        {cloneElement(children, { onClick })}
      </Tooltip>
    </ClickOutsideWrapper>
  );
};

export default memo(TooltipConfirmation);
