import React, { FC, memo, ReactNode, useEffect, useRef, useState, MouseEvent } from 'react';

import { Chart } from '@antv/g2';
import { orderBy } from 'lodash';
import { Event } from '@antv/g2/lib/dependents';
import { TooltipItem } from '@antv/g2/lib/interface';
import { Popover, PopoverContent } from 'components/Popover/Popover.component';
import { Tooltip } from 'components/Tooltip/Tooltip.component';

interface Props {
  chart: Chart | null;
  renderTooltipButtons?: (tooltipTime: number) => ReactNode;
  tooltipMetricActionsMap?: Record<string, { action: (e: MouseEvent, tooltipTime: number) => void; label: string }>;
  disablePortal?: boolean;
  className?: string;
}

interface TooltipEventData {
  items: TooltipItem[];
  title: string;
  x: number;
  y: number;
}

const tooltipOffset = { mainAxis: 20, crossAxis: 20 };

const ChartTooltip: FC<Props> = ({
  chart,
  disablePortal,
  renderTooltipButtons,
  tooltipMetricActionsMap,
  className,
}) => {
  const [tooltipPosition, setTooltipPosition] = useState<{ x: number; y: number } | undefined>();
  const chartHoveredRef = useRef(false);

  const [tooltip, setTooltip] = useState<TooltipEventData | null>(null);
  const [hoveredColor, setHoveredColor] = useState<string | null>(null);

  const currentTime = tooltip?.items?.[0]?.data.time
    ? tooltip.items[0].data.time
    : undefined;

  const orderedData = orderBy(
    tooltip?.items || [],
    [
      (item) =>
        // excluding thresholds from ordering
        item.data.singleThresholdValue || item.data.values
          ? -Infinity
          : parseFloat(item.value.toString()),
    ],
    'desc',
  );

  useEffect(() => {
    if (!chart) {
      return;
    }

    const handleChartMousemove = (e: Event & { data?: { color: string } }) => {
      if (chart.getController('tooltip').isTooltipLocked()) {
        return;
      }

      chartHoveredRef.current = true;
      if (e.data?.color) {
        setHoveredColor(e.data.color);
      } else {
        setHoveredColor(null);
      }

      setTooltipPosition({
        x: chart.ele.getBoundingClientRect().x + e.x,
        y: chart.ele.getBoundingClientRect().y + e.y,
      });
    };

    const handleTooltipShow = (e: { data: TooltipEventData }) => {
      if (
        chart &&
        (!chartHoveredRef.current ||
          chart.getController('tooltip').isTooltipLocked())
      ) {
        setTooltipPosition({
          x: chart.ele.getBoundingClientRect().x + e.data.x,
          y: chart.ele.getBoundingClientRect().y + e.data.y,
        });
      }
      setTooltip(e.data);
    };

    const handleTooltipHide = () => {
      setTooltip(null);
      chartHoveredRef.current = false;
    };

    const handleMouseLeave = () => {
      !chart.getController('tooltip').isTooltipLocked() && chart.hideTooltip();
    }

    chart?.on('tooltip:show', handleTooltipShow);
    chart?.on('tooltip:hide', handleTooltipHide);
    chart?.on('mousemove', handleChartMousemove);
    chart?.on('mouseleave', handleMouseLeave);
    return () => {
      chart?.off('mousemove', handleChartMousemove);
      chart?.off('tooltip:show', handleTooltipShow);
      chart?.off('tooltip:hide', handleTooltipHide);
      chart?.off('mouseleave', handleMouseLeave);
    };
  }, [chart]);
  
  if (!tooltip || !chart) {
    return null;
  }

  return (
    <Popover
      open
      placement="right-start"
      offset={tooltipOffset}
      clientPoint={tooltipPosition}
      disablePortal={disablePortal}
    >
      <PopoverContent style={{ zIndex: 1000 }} className={className}>
        <div className="rounded px-4 py-2 shadow-lg bg-gray-100 dark:bg-paper dark:shadow-black dark:shadow-lg">
          <div className="flex mb-3 font-bold text-xs items-center justify-between">
            <span>{tooltip?.title}</span>
            <div>{currentTime && renderTooltipButtons?.(currentTime)}</div>
          </div>
          <ul className="p-0 m-0 list-none">
            {orderedData?.map((d) => {
              return (
                <li className="flex items-center justify-between my-1" key={d.name}>
                  <div className="flex items-center">
                    <span
                      className="w-[8px] h-[8px] rounded-full inline-block mr-2"
                      style={{ background: d.color }}
                    ></span>
                    {tooltipMetricActionsMap?.[d.name] ? (
                      <Tooltip content="View KPI" placement="top">
                        <span
                          className={`max-w-[700px] text-xs overflow-hidden text-primary text-ellipsis block whitespace-nowrap underline cursor-pointer ${
                            hoveredColor === d.color ? 'font-bold' : 'font-normal'
                          }`}
                          onClick={(e) => tooltipMetricActionsMap?.[d.name]?.action?.(e, currentTime)}
                        >
                          {tooltipMetricActionsMap?.[d.name]?.label}:
                        </span>
                      </Tooltip>
                    ) : (
                      <span
                        className={`max-w-[700px] text-xs overflow-hidden text-ellipsis block whitespace-nowrap ${
                          hoveredColor === d.color ? 'font-bold' : 'font-normal'
                        }`}
                      >
                        {d.name}:
                      </span>
                    )}
                  </div>
                  <span className="inline-block ml-[30px] font-bold text-xs">{d.value}</span>
                </li>
              );
            })}
          </ul>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default memo(ChartTooltip);
