import { defineMessages } from 'react-intl';

export const scope = 'features.TopInsightsTimelineClusters';

export default defineMessages({
  open: {
    id: `${scope}.open`,
    defaultMessage: 'Open in Workbench',
  },
  assertions: {
    id: `${scope}.assertions`,
    defaultMessage: 'Assertions from this range:',
  },
});
