import { AxiosResponse } from 'axios';
import {
  AssertionScore,
  AssertionScoresRes,
  AssertionSummaryRes,
  AssertionSummaryResProcessed,
  AssertionsResponseProcessed,
  AssertionsResponseRaw,
  HierarchicalAssertion,
  Metric,
  SAAFE,
  EntityResponse,
  Scope,
  ChartPoint,
  Definition,
  EntityAssertionDetails,
  AssertionEntityTypes,
  AssertionSearchResult,
  SearchCriteria,
  AssertionsBoardEntity,
  TraceUrlParams,
} from 'asserts-types';

import { areEntitiesEqual, getDashedScopeValues } from '../helpers/Entity.helper';
import { assertsColors } from '../app/constants';
import expandSinglePoints from '../features/Assertions/helpers/expandSinglePoints';
import { addFieldsRecursive } from '../features/Assertions/Assertions.helpers';
import { apiHttpService } from 'app/api-http-service';

export const fetchEntityAssertionsGraph = (
  entityKeys: { type: string; name: string }[],
  startTime: number,
  endTime: number
): Promise<EntityResponse> =>
  apiHttpService
    .post('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/assertions/graph', {
      startTime,
      endTime,
      entityKeys,
    })
    .then((response) => response.data);

export const fetchAssertionEntityMetric = (
  assertionName: string,
  entityName: string,
  entityType: string,
  startTime: number,
  endTime: number,
  labels: Record<string, string>
): Promise<EntityAssertionDetails> =>
  apiHttpService
    .post('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/assertions/entity-metric', {
      assertionName,
      entityName,
      entityType,
      startTime,
      endTime,
      labels,
    })
    .then((response) => response.data);

export const fetchMetricForThreshold = (
  startTime: number,
  endTime: number,
  labels: Record<string, string>
): Promise<EntityAssertionDetails> =>
  apiHttpService
    .post('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/assertions/entity-metric', {
      startTime,
      endTime,
      labels,
      referenceForThreshold: true,
    })
    .then((response) => response.data);

export const fetchConnectedEntityAssertionDetails = (
  entityId: number,
  assertionName: string,
  start: number,
  end: number
): Promise<EntityAssertionDetails> =>
  apiHttpService
    .get(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/entity/${entityId}/assertions/${assertionName}/connected?start=${start}&end=${end}`)
    .then((response) => response.data);

export const fetchEntityAssertionDetails = (
  entityId: number,
  assertionName: string,
  start: number,
  end: number
): Promise<EntityAssertionDetails> =>
  apiHttpService
    .get(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/entity/${entityId}/assertions/${assertionName}?start=${start}&end=${end}`)
    .then((response) => response.data);

export const fetchAssertionLogsUrl = (
  start: number,
  end: number,
  properties: Record<string, string | number>
): Promise<{ url: string }> =>
  apiHttpService
    .post(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/logging/external`, {
      start,
      end,
      properties,
    })
    .then((response) => response.data);

export const fetchAssertionTracesUrl = (
  start: number,
  end: number,
  properties: Record<string, string | number>,
  params: TraceUrlParams
): Promise<{ url: string }> =>
  apiHttpService
    .post(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/integration/trace`, {
      start,
      end,
      properties,
      ...(params.threshold[0]?.values && { thresholdBands: params.threshold }),
      ...(params.threshold[0]?.value && {
        singleThreshold: params.threshold[0]?.value,
      }),
      values: params.values,
    })
    .then((response) => response.data);

export const fetchEntityAssertions = (
  entityKeys: { type: string; name: string; scope: Scope | undefined }[],
  startTime: number,
  endTime: number,
  alertCategories: SAAFE[],
  hideAssertionsOlderThanNHours: number | undefined
) =>
  apiHttpService
    .post<AssertionsResponseRaw>(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/assertions?withFilters=true`, {
      startTime,
      endTime,
      entityKeys,
      includeConnectedAssertions: true,
      groupAssertions: true,
      hideAssertionsOlderThanNHours,
      alertCategories,
    })
    .then((response) => processEntityAssertionsResponse(response.data));

const processEntityAssertionsResponse = (data: AssertionsResponseRaw): AssertionsResponseProcessed => {
  // converting percentage from string to integer
  const processedAssertionScores: AssertionScore[] = data.assertionScores.map((item) => ({
    ...item,
    percentage: item.percentage ? +item.percentage : 0,
  }));

  let processedAssertions: HierarchicalAssertion[] = data.assertions.map((assertion, index) => {
    // preparing score metrics for root item
    let scoreMetrics: Metric[] =
      data.assertionScores
        .find((s) => areEntitiesEqual(s, assertion.allAssertions[0]))
        ?.metrics.map((item) => ({
          name: item.metric.asserts_severity,
          values: item.values,
          color: assertsColors[item.metric.asserts_severity],
        })) || [];

    // preparing score metrics for root item
    scoreMetrics = scoreMetrics.map((metric) => ({
      ...metric,
      values: expandSinglePoints(metric.values, data.timeStepIntervalMs),
    }));

    const percentage =
      processedAssertionScores.find((s) => areEntitiesEqual(s, assertion.allAssertions[0]))?.percentage || 0;

    const totalScore =
      processedAssertionScores.find((s) => areEntitiesEqual(s, assertion.allAssertions[0]))?.totalScore || 0;

    // adding properties from graph data
    const properties = data.graphData.find((e) => areEntitiesEqual(e, assertion.allAssertions[0]))?.properties;

    const sameAssertionName = data.assertions.find((e, i) => areEntitiesEqual(e, assertion) && index !== i);

    const resultItem = {
      ...assertion.allAssertions[0],
      properties,
      scoreMetrics,
      percentage,
      totalScore,
      inboundClientErrorsBreached: assertion.inboundClientErrorsBreached,
      // name with namespace to distinguish same name items with different namespaces
      nameWithNamespace: sameAssertionName
        ? `${assertion.scope?.namespace ? `${assertion.scope.namespace}:` : ''}${assertion.name}`
        : undefined,
    };

    const rootInfoHealthStates =
      data.assertions
        .find((e) => e.type === assertion.type && e.name === assertion.name)
        ?.allAssertions[0].healthStates.filter((h) => h.severity === 'info') || [];

    const lastUpdateTime = Date.now();
    // adds fields 'level' | 'rootEntityName' | 'rootEntityType' | 'rootInfoHealthStates'
    const assertionWithFields = addFieldsRecursive(
      [resultItem],
      -1,
      assertion.allAssertions[0].name,
      assertion.allAssertions[0].type,
      rootInfoHealthStates,
      lastUpdateTime,
      properties
    );

    return assertionWithFields[0];
  });

  let maxValue = 0;

  processedAssertionScores.forEach((score) => {
    score.metrics.forEach((metric) => {
      metric.values.forEach((a) => {
        if (a.value && a.value > maxValue) {
          maxValue = a.value;
        }
      });
    });
  });

  const alertCategoriesCountMap = data.assertionRollupDto.reduce<Record<SAAFE, number>>(
    (acc, item) => {
      acc[item.name as SAAFE] = item.assertionCount;
      return acc;
    },
    { saturation: 0, anomaly: 0, amend: 0, error: 0, failure: 0 } as Record<SAAFE, number>
  );

  return {
    ...data,
    assertions: processedAssertions,
    assertionScores: processedAssertionScores,
    maxAssertionScoreValue: maxValue,
    alertCategoriesCountMap,
  };
};

export const fetchAssertionsByDefinition = (
  definition: Definition,
  start: number,
  end: number,
  alertCategories: SAAFE[],
  hideAssertionsOlderThanNHours?: number
) =>
  apiHttpService
    .post<AssertionsResponseRaw>(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/search/assertions?withFilters=true`, {
      timeCriteria: {
        start,
        end,
      },
      definitionId: definition.definitionId,
      bindings: definition.bindings,
      groupAssertions: true,
      filterCriteria: definition.filterCriteria,
      hideAssertionsOlderThanNHours,
      alertCategories,
    })
    .then((response) => processEntityAssertionsResponse(response.data));

export const fetchAssertionScores = (
  startMs: number,
  endMs: number,
  alertCategories: SAAFE[],
  hideAssertionsOlderThanNHours?: number
): Promise<AssertionScoresRes> =>
  apiHttpService
    .post(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/assertion_scores`, {
      startMs,
      endMs,
      hideAssertionsOlderThanNHours,
      alertCategories,
    })
    .then((response) => response.data);

export const findEntityAssertions = (
  entityType: AssertionEntityTypes,
  searchCriteria: SearchCriteria[],
  start: number,
  end: number
): Promise<AssertionSearchResult[]> =>
  apiHttpService
    .post(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/assertions/search`, {
      entityType,
      searchCriteria,
      timeCriteria: {
        start,
        end,
      },
    })
    .then((response) => response.data);

export const fetchTopEntities = (startMs: number, endMs: number): Promise<AssertionsBoardEntity[]> =>
  apiHttpService
    .post(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/assertion_scores/top_entities`, {
      startMs,
      endMs,
    })
    .then((response) => response.data);

export const searchScores = (
  definition: Definition,
  start: number,
  end: number,
  alertCategories: SAAFE[],
  hideAssertionsOlderThanNHours?: number
): Promise<AssertionScoresRes> =>
  apiHttpService
    .post(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/assertion_scores/search`, {
      definitionId: definition.definitionId,
      bindings: definition.bindings,
      filterCriteria: definition.filterCriteria,
      timeCriteria: {
        start,
        end,
      },
      hideAssertionsOlderThanNHours,
      alertCategories,
    })
    .then((response) => response.data);

export const searchScoresByQuery = (
  query: string,
  start: number,
  end: number,
  alertCategories: SAAFE[],
  hideAssertionsOlderThanNHours?: number
): Promise<AssertionScoresRes> =>
  apiHttpService
    .post(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/assertion_scores/search`, {
      query,
      timeCriteria: {
        start,
        end,
      },
      hideAssertionsOlderThanNHours,
      alertCategories,
    })
    .then((response) => response.data);

const processSummaryResponse = (res: AxiosResponse<AssertionSummaryRes>): AssertionSummaryResProcessed => {
  const processedAssertionScores: AssertionScore[] = res.data.assertionScores.map(
    (item) => ({
      ...item,
      percentage: item.percentage ? +item.percentage : 0,
    }),
  );
  const summaries = res.data.summaries.map((item) => {
    const rootId = `${getDashedScopeValues(item.scope)}-${item.name}-${item.type}`;

    return {
      ...item,
      id: rootId,
      timeLines: item.timeLines.map((timeline) => ({
        ...timeline,
        id: `${rootId}-${timeline.assertionName}-${timeline.alertName}`,
        nestedSummaries: timeline.nestedSummaries.map((summary) => ({
          ...summary,
          id: `${rootId}-${summary.assertionName}-${summary.alertName}-nested`,
        })),
      })),
    };
  });

  return { ...res.data, summaries, assertionScores: processedAssertionScores };
};

export const fetchAssertionsSummaryByDefinition = (
  definition: Definition,
  start: number,
  end: number,
  alertCategories: SAAFE[],
  withRCA: boolean,
  hideAssertionsOlderThanNHours?: number
) =>
  apiHttpService
    .post<AssertionSummaryRes>(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/search/assertions/summary?withRCA=${withRCA}`, {
      timeCriteria: {
        start,
        end,
      },
      definitionId: definition.definitionId,
      bindings: definition.bindings,
      filterCriteria: definition.filterCriteria,
      groupAssertions: true,
      alertCategories,
      hideAssertionsOlderThanNHours,
    })
    .then(processSummaryResponse);

export const fetchAssertionsSummary = (
  entityKeys: { type: string; name: string; scope: Scope | undefined }[],
  start: number,
  end: number,
  alertCategories: SAAFE[],
  withRCA: boolean,
  hideAssertionsOlderThanNHours: number | undefined
) =>
  apiHttpService
    .post<AssertionSummaryRes>(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/assertions/summary?withRCA=${withRCA}`, {
      startTime: start,
      endTime: end,
      entityKeys,
      groupAssertions: true,
      alertCategories,
      hideAssertionsOlderThanNHours,
    })
    .then(processSummaryResponse);

export default {
  fetchAssertionScores,
  fetchEntityAssertionsGraph,
  fetchConnectedEntityAssertionDetails,
  fetchEntityAssertionDetails,
  fetchEntityAssertions,
  fetchAssertionEntityMetric,
  findEntityAssertions,
  fetchTopEntities,
  fetchAssertionsByDefinition,
  fetchAssertionLogsUrl,
  fetchAssertionTracesUrl,
  searchScores,
  searchScoresByQuery,
  fetchAssertionsSummaryByDefinition,
  fetchAssertionsSummary,
  fetchMetricForThreshold,
};
