import React from 'react';
import useLogConfigs from 'features/Configuration/components/LogsConfg/hooks/useLogConfigs';
import { Card, LinkButton, LoadingPlaceholder, Tag } from '@grafana/ui';
import LokiIcon from 'assets/icons/loki_icon.svg';
import { PluginPage } from '@grafana/runtime';
import ConfigurationTopMenu from '../ConfigurationTopMenu/ConfigurationTopMenu';
import { prefixRoute } from 'utils/utils.routing';
import { ROUTES } from 'global-constants';
import { useDataSources } from 'hooks/useDataSources';

const addLogsPath = prefixRoute([ROUTES.CONFIGURATION, ROUTES.CONFIGURATION_LOGS_ADD].join('/'));
const getLogsEditUrl = (name: string) =>
  prefixRoute([ROUTES.CONFIGURATION, ROUTES.CONFIGURATION_LOGS_EDIT.replace(':name', name)].join('/'));

export default function LogsConfg() {
  const { data: logConfigs, isFetching } = useLogConfigs();
  const { data: logsDatasources } = useDataSources({ type: 'loki' });

  return (
    <PluginPage
      renderTitle={() => <h1>Configuration</h1>}
      pageNav={{ text: 'Logs' }}
      actions={
        <LinkButton icon="plus" href={addLogsPath}>
          Add logs config
        </LinkButton>
      }
    >
      <ConfigurationTopMenu />
      <div className="mt-8">
        {isFetching && <LoadingPlaceholder text="Loading..." className="pt-20 text-center" />}
        {!isFetching && (
          <ul className="list-none">
            {logConfigs?.environments?.map((item) => (
              <li key={item.name}>
                <Card href={getLogsEditUrl(item.name)}>
                  <Card.Heading>{item.name}</Card.Heading>
                  <Card.Figure>
                    <LokiIcon />
                  </Card.Figure>
                  <Card.Meta>
                    {!!item.envsForLog?.length && <span>Environments: {item.envsForLog?.join(', ')}</span>}
                    {!!item.sitesForLog?.length && <span>Sites: {item.sitesForLog?.join(', ')}</span>}
                    {item.defaultConfig && <Tag name="Default" colorIndex={1} />}
                    {item.logConfig.dataSource && (
                      <span>{logsDatasources?.find((ds) => ds.uid === item.logConfig.dataSource)?.name}</span>
                    )}
                  </Card.Meta>
                </Card>
              </li>
            ))}
          </ul>
        )}
      </div>
    </PluginPage>
  );
}
