/*
 * RelabelConfig Messages
 *
 * This contains all the text for the RelabelConfig component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.RelabelConfig';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Relabel Config',
  },
  saved: {
    id: `${scope}.saved`,
    defaultMessage: 'Config was saved successfully',
  },
  prologue: {
    id: `${scope}.prologue`,
    defaultMessage: 'Prologue',
  },
  epilogue: {
    id: `${scope}.epilogue`,
    defaultMessage: 'Epilogue',
  },
  generated: {
    id: `${scope}.generated`,
    defaultMessage: 'Generated',
  },
  fileBtn: {
    id: `${scope}.fileBtn`,
    defaultMessage: 'View/edit YML File',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Relabel Config',
  },
  help: {
    id: `${scope}.help`,
    defaultMessage: 'How to configure Relabeling',
  },
  addTitle: {
    id: `${scope}.addTitle`,
    defaultMessage: 'Add {type} relabel config',
  },
});
