/*
 * EmptyAssertions Messages
 *
 * This contains all the text for the EmptyAssertions component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.EmptyAssertions';

export default defineMessages({
  emptyHint: {
    id: `${scope}.emptyHint`,
    defaultMessage: 'Your assertion workbench is empty',
  },
  viewTopServices: {
    id: `${scope}.viewTopServices`,
    defaultMessage: 'Add top services by assertions',
  },
  emptyHint2: {
    id: `${scope}.emptyHint2`,
    defaultMessage:
      'Use search to add Entities to Workbench for inspecting{br}or {top} to get started',
  },
  noAssertions: {
    id: `${scope}.noAssertions`,
    defaultMessage: 'No Assertions found',
  },
  noAssertionsHint: {
    id: `${scope}.noAssertionsHint`,
    defaultMessage:
      'Try additional parameters such as date, name, or other search criteria.',
  },
});
