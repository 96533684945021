import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { useReturnToPrevious } from '@grafana/runtime';
import { Button, Divider, useStyles2 } from '@grafana/ui';
import { Entity, EntityPropertyTypes, Scope, StringRules } from 'asserts-types';
import AssertionsListInfoComponent from 'components/AssertionsListInfo/AssertionsListInfo.component';
import GraphNodeRcaButtonsComponent from 'components/GraphinGraph/components/GraphNodeRcaButtons/GraphNodeRcaButtons.component';
import {
  setActiveEntityDetails,
  setQueryParamsWereRead,
  setSelectedEnv,
  setSelectedSite,
  setTimeRange,
} from 'features/App/App.slice';
import { clearEntities, setSearchObject, setShowAdvancedSearch } from 'features/Entities/Entities.slice';
import { ROUTES, TRACKING_FEATURES } from 'global-constants';
import EntitiesOpenIcon from 'icons/EntitiesOpenIcon';
import React, { useMemo } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { prefixRoute } from 'utils/utils.routing';
import { CONNECTED_ENTITY_TYPES } from './constants';
import TrackingHelper from 'helpers/Tracking.helper';

const connector = connect(undefined, {
  setShowAdvancedSearch,
  setSearchObject,
  clearEntities,
  setQueryParamsWereRead,
  setTimeRange,
  setActiveEntityDetails,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IProps {
  start: number;
  end: number;
  entity: Entity;
  scope?: Scope;
  source?: string;
}

function EntityAssertionsPopover({
  entity,
  setShowAdvancedSearch,
  setSearchObject,
  setTimeRange,
  clearEntities,
  start,
  end,
  scope,
  setQueryParamsWereRead,
  source,
  setActiveEntityDetails,
}: IProps & PropsFromRedux) {
  const styles = useStyles2(getStyles);
  const navigate = useNavigate();
  const setReturnToPrevious = useReturnToPrevious();

  const connectedEntitiesCount = useMemo(() => {
    return entity.connectedEntityTypes
      ? Object.entries(entity.connectedEntityTypes)
          .filter(([entityType]) => entityType !== 'Assertion' && CONNECTED_ENTITY_TYPES.includes(entityType))
          .reduce((acc, [_, entityCount]) => acc + entityCount, 0)
      : [];
  }, [entity]);

  return (
    <div>
      <h4>Asserts troubleshooting</h4>
      <Divider />
      <p className={styles.subtitle}>Entity name</p>
      <span>{entity.name}</span>
      <Divider />
      <p className={styles.subtitle}>Entity type</p>
      {entity.type}
      <Divider />
      <p className={styles.subtitle}>Environment (site)</p>
      {entity.scope?.env} ({entity.scope?.site})
      <Divider />
      <p className={styles.subtitle}>Assertions</p>
      <div>
        {!entity.assertion?.assertions?.length ? (
          <span>No assertions firing</span>
        ) : (
          <AssertionsListInfoComponent assertions={entity.assertion?.assertions} />
        )}
      </div>
      <Divider />
      <div className={styles.buttonGroup}>
        {Boolean(entity.assertion?.assertions?.length || entity.connectedAssertion?.assertions?.length) && (
          <GraphNodeRcaButtonsComponent
            entityInfo={entity}
            trackingFeature={TRACKING_FEATURES.ENTITY_ASSERTIONS_WIDGET}
            source={source}
          />
        )}
        <Button
          fill="outline"
          variant="secondary"
          className={styles.connectedEntitiesButton}
          icon={<EntitiesOpenIcon />}
          fullWidth
          onClick={() => {
            if (source) {
              TrackingHelper.trackNavigateToEntityGraph(TRACKING_FEATURES.ENTITY_ASSERTIONS_WIDGET, source ?? 'unset');
            }
            setActiveEntityDetails();
            setReturnToPrevious(source ?? 'previous');
            setTimeRange({ start, end });
            setShowAdvancedSearch(true);
            clearEntities();
            setQueryParamsWereRead(true); // we need to switch the mode so asserts app will use redux store as a source of data not the query params
            const propertyMatchers = [
              {
                id: 0,
                name: 'name',
                op: StringRules.EQUALS,
                type: EntityPropertyTypes.STRING,
                uom: '',
                value: entity.name,
              },
            ];

            if (scope?.env) {
              setSelectedEnv([scope.env]);
            } else {
              setSelectedEnv([]);
            }
            if (scope?.site) {
              setSelectedSite([scope.site]);
            } else {
              setSelectedSite([]);
            }
            if (scope?.namespace) {
              propertyMatchers.push({
                id: 3,
                name: 'namespace',
                op: StringRules.EQUALS,
                type: EntityPropertyTypes.STRING,
                uom: '',
                value: scope.namespace,
              });
            }

            setSearchObject({
              filterCriteria: [
                {
                  entityType: entity.type,
                  propertyMatchers,
                  connectToEntityTypes: entity.connectedEntityTypes
                    ? Object.keys(entity.connectedEntityTypes).filter((connectedType) =>
                        CONNECTED_ENTITY_TYPES.includes(connectedType)
                      )
                    : [],
                },
              ],
            });
            navigate(prefixRoute(ROUTES.ENTITIES_GRAPH));
          }}
        >
          <span className={styles.buttonContent}>View connected entities ({connectedEntitiesCount})</span>
        </Button>
      </div>
    </div>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    subtitle: css({
      color: theme.colors.text.secondary,
      marginBottom: theme.spacing(1),
    }),
    connectedEntitiesButton: css({
      justifyContent: 'center',
    }),
    buttonContent: css({
      marginLeft: '.25rem',
    }),
    buttonGroup: css({
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    }),
  };
}

export default connector(EntityAssertionsPopover);
