import { usePluginContext } from '@grafana/data';
import { useMutation } from '@tanstack/react-query';
import { apiHttpService } from 'app/api-http-service';
import { AssertsAppJsonData, TraceConfigForm, TraceIntegration } from 'asserts-types';
import { ROUTES } from 'global-constants';
import SnackbarHelper from 'helpers/Snackbar.helper';
import { pick } from 'lodash';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { prefixRoute } from 'utils/utils.routing';
import useTraceConfigs from './useTraceConfigs';
import useDidUpdateEffect from 'hooks/useDidUpdate';
import { useDataSource } from 'hooks/useDataSource';

const DEFAULT_FORM_VALUES = { envs: [], sites: [], labelToTag: [] };
const traceConfigsPath = prefixRoute([ROUTES.CONFIGURATION, ROUTES.CONFIGURATION_TRACES].join('/'));

const convertToForm = (preloadedItem: TraceIntegration | undefined): TraceConfigForm | undefined =>
  preloadedItem
    ? {
        ...DEFAULT_FORM_VALUES,
        name: preloadedItem.name,
        envs: preloadedItem.envsForTrace || [],
        sites: preloadedItem.sitesForTrace || [],
        ...pick(preloadedItem.traceConfig, ['dataSource', 'serviceLabel', 'operationLabel']),
        labelToTag: Object.entries(preloadedItem.traceConfig.labelToTagMapping || {}).map(([label, tag]) => ({
          label,
          tag,
        })),
      }
    : undefined;

export default function useAddTraceConfigForm(preloadedItem: TraceIntegration | undefined) {
  const navigate = useNavigate();

  const { refetch: refetchTraceConfigs } = useTraceConfigs();

  const { control, handleSubmit, reset, watch } = useForm<TraceConfigForm>({
    defaultValues: preloadedItem ? convertToForm(preloadedItem) : DEFAULT_FORM_VALUES,
  });

  const selectedDataSourceUid = watch('dataSource');

  const { data: selectedDataSource } = useDataSource(selectedDataSourceUid);

  useDidUpdateEffect(() => {
    if (preloadedItem) {
      reset(convertToForm(preloadedItem));
    }
    //eslint-disable-next-line
  }, [preloadedItem]);

  const { meta } = usePluginContext();
  const metaJsonData = meta.jsonData as AssertsAppJsonData;

  const { append, remove, fields } = useFieldArray({ control, name: 'labelToTag' });

  const mutation = useMutation({
    mutationFn: (formData: TraceConfigForm) => {
      let dataToSend: TraceIntegration = {
        name: formData.name,
        envsForTrace: formData.envs,
        sitesForTrace: formData.sites,
        defaultConfig: preloadedItem?.defaultConfig,
        traceConfig: {
          tool: 'Tempo',
          // @ts-ignore
          orgId: selectedDataSource?.orgId || metaJsonData.orgId,
          addOutboundContext: true,
          assertsCollector: false,
          url: metaJsonData.instanceUrl,
          ...pick(formData, ['dataSource', 'serviceLabel', 'operationLabel']),
          labelToTagMapping: (formData.labelToTag || [])
            .filter((v) => v.tag && v.label)
            .reduce((mapping, value) => {
              mapping[value.label] = value.tag;
              return mapping;
            }, {} as Record<string, string>),
        },
      };

      return apiHttpService
        .post(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/trace`, dataToSend)
        .then((res) => res.data);
    },

    onSuccess: () => {
      SnackbarHelper.success('Trace configuration was saved successfuly.');
      refetchTraceConfigs();
      navigate(traceConfigsPath);
    },
  });

  const submit = (data: TraceConfigForm) => {
    mutation.mutateAsync(data);
  };

  return {
    control,
    appendLabelToTagItem: append,
    removeLabelToTagItem: remove,
    labelToTagItems: fields,
    onSubmit: handleSubmit(submit),
    isLoading: mutation.isLoading,
  };
}
