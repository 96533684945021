/*
 * AddRuleFile Messages
 *
 * This contains all the text for the AddRuleFile component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.AddRuleFile';

export default defineMessages({
  newRuleFile: {
    id: `${scope}.newRuleFile`,
    defaultMessage: 'New rule file',
  },
  customFiles: {
    id: `${scope}.customFiles`,
    defaultMessage: 'Custom Assertion Files',
  },
  viewEdit: {
    id: `${scope}.viewEdit`,
    defaultMessage: 'View / Edit',
  },
  deleteFile: {
    id: `${scope}.deleteFile`,
    defaultMessage: 'Delete',
  },
  fileSaved: {
    id: `${scope}.fileSaved`,
    defaultMessage: 'File was saved successfully',
  },
  fileSaveError: {
    id: `${scope}.fileSaveError`,
    defaultMessage: 'Error while saving a file ',
  },
  opening: {
    id: `${scope}.oepning`,
    defaultMessage: 'Opening...',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Add New Rule File',
  },
  emptyList: {
    id: `${scope}.emptyList`,
    defaultMessage: 'No items here.',
  },
  fileName: {
    id: `${scope}.fileName`,
    defaultMessage: 'File name',
  },
});
