/**
 *
 * ThresholdList
 *
 */

import React, { memo, FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import ThresholdHealthListItemComponent from '../ThresholdHealthListItem/ThresholdHealthListItem.component';
import has from 'helpers/has.helper';
import { Sort, AssertionGroupRule } from 'asserts-types';
import { LoadingPlaceholder, PanelContainer } from '@grafana/ui';
import TableSortButton from 'features/Entities/components/TableSortButton/TableSortButton.component';

interface IProps {
  fields: string[];
  list: AssertionGroupRule[];
  onUpdate: (item: AssertionGroupRule) => void;
  onDelete?: (item: AssertionGroupRule) => void;
  title?: string;
  fetchingList: boolean;
  onSort?: (sort: Sort | null) => void;
  orderBy?: Sort | null;
}

const isFieldFromMessages = (
  messagesObj: typeof messages,
  field: string,
): field is keyof typeof messages => messagesObj.hasOwnProperty(field);

const ThresholdHealthList: FunctionComponent<IProps> = ({
  list,
  onUpdate,
  fields,
  title,
  onDelete,
  fetchingList,
  onSort,
  orderBy,
}) => {
  const intl = useIntl();

  return (
    <PanelContainer className={title ? 'p-6 my-8' : '!shadow-none'}>
      {title && (
        <div className="flex justify-between items-center pb-1">
          <p className="text-xl">{title}</p>
        </div>
      )}
      {fetchingList ? (
        <div className="flex items-center justify-center p-8">
          <LoadingPlaceholder text="Loading" />
        </div>
      ) : (
        <table className="w-full">
          <thead>
            <tr>
              {fields.map((field) => (
                <td key={field} className="p-4 divider-b">
                  <TableSortButton
                    className="headerCell"
                    active={orderBy?.field === field}
                    direction={orderBy?.order}
                    onClick={() =>
                      list.length &&
                      onSort &&
                      onSort({
                        field: has(list[0], field) ? field : `labels.${field}`,
                        order: orderBy?.order === 'asc' ? 'desc' : 'asc',
                      })
                    }
                  >
                    {isFieldFromMessages(messages, field) ? intl.formatMessage(messages[field]) : ''}
                  </TableSortButton>
                </td>
              ))}
              <td width="10%" className="p-4 divider-b"></td>
            </tr>
          </thead>
          <tbody>
            {list.length ? (
              list.map((item) => (
                <ThresholdHealthListItemComponent
                  fields={fields}
                  key={`${item.alert}-${item.expr}-${item.for}-${item.groupName}`}
                  item={item}
                  onUpdate={onUpdate}
                  onDelete={onDelete}
                  isEditing={item.editing}
                />
              ))
            ) : (
              <div className="p-4">{intl.formatMessage(messages.emptyList)}</div>
            )}
          </tbody>
        </table>
      )}
    </PanelContainer>
  );
};

export default memo(ThresholdHealthList);
