/**
 *
 * ThresholdHealthListItem
 *
 */

import React, { memo, FunctionComponent, useState, useRef } from 'react';
import { AssertionGroupRule } from 'asserts-types';
import EditIcon from 'assets/material-icons/edit_FILL0_wght400_GRAD0_opsz24.svg';
import CheckIcon from 'assets/material-icons/done_FILL0_wght400_GRAD0_opsz24.svg';
import CloseIcon from 'assets/material-icons/close_FILL0_wght400_GRAD0_opsz24.svg';
import DeleteOutlineIcon from 'assets/material-icons/delete_FILL0_wght400_GRAD0_opsz24.svg';
import has from 'helpers/has.helper';
import { deleteRuleItem, saveRuleItem } from 'services/ManageAssertions.service';
import TooltipConfirmationComponent from 'features/Assertions/components/TooltipConfirmation/TooltipConfirmation.component';
import { BracesPlugin, QueryField, SlatePrism } from '@grafana/ui';
import { IconButton } from 'components/IconButton/IconButton.component';

interface IProps {
  item: AssertionGroupRule;
  fields: string[];
  isEditing?: boolean;
  onUpdate: (item: AssertionGroupRule) => void;
  onDelete?: (item: AssertionGroupRule) => void;
}

const ThresholdHealthListItem: FunctionComponent<IProps> = ({
  item,
  onUpdate,
  onDelete,
  fields,
  isEditing,
}) => {
  const [editing, setEditing] = useState(isEditing);
  const [exprField, setExprField] = useState(item.expr);
  const [updating, setUpdating] = useState(false);
  const ref = useRef<HTMLTableRowElement>(null);

  const handleUpdate = () => {
    setUpdating(true);

    const updatedItem: AssertionGroupRule = {
      ...item,
      expr: exprField,
      groupName: item.groupName.includes('custom-')
        ? item.groupName
        : 'custom-' + item.groupName,
    };

    saveRuleItem(updatedItem)
      .then(() => {
        setEditing(false);
        onUpdate(updatedItem);
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  const handleDelete = () => {
    setUpdating(true);
    deleteRuleItem(item)
      .then(() => {
        onDelete && onDelete(item);
      })
      .finally(() => {
        setEditing(false);
        setUpdating(false);
      });
  };

  return (
    <tr ref={ref} className="transition-all hover:bg-hover">
      {fields.map((field) => (
        <td className="p-4 divider-b" key={field}>
          {editing && field === 'expr' ? (
            <QueryField
              portalOrigin="."
              additionalPlugins={[
                BracesPlugin(),
                SlatePrism({
                  onlyIn: (node: any) => node.type === 'code_block',
                  getSyntax: () => 'promql',
                }),
              ]}
              query={exprField}
              onChange={(v) => setExprField(v)}
              disabled={updating}
            />
          ) : (
            <>
              {has(item, field) && item[field]}
              {has(item.labels, field) && item.labels[field]}
            </>
          )}
        </td>
      ))}
      <td className="p-4 divider-b">
        <div className="flex">
          {editing ? (
            <>
              <IconButton variant="primary" disabled={updating} onClick={handleUpdate}>
                <CheckIcon />
              </IconButton>
              <IconButton variant="primary" disabled={updating} onClick={() => setEditing(false)}>
                <CloseIcon />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton onClick={() => setEditing(true)} variant="primary" disabled={updating} data-cy="edit-btn">
                <EditIcon />
              </IconButton>
              {onDelete && (
                <TooltipConfirmationComponent action={handleDelete}>
                  <IconButton variant="destructive" disabled={updating}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </TooltipConfirmationComponent>
              )}
            </>
          )}
        </div>
      </td>
    </tr>
  );
};

export default memo(ThresholdHealthListItem);
