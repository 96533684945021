import { FieldColorModeId, FieldConfigProperty, FieldMatcherID } from '@grafana/data';
import {
  EmbeddedScene,
  QueryRunnerState,
  SceneFlexItem,
  SceneFlexLayout,
  SceneQueryRunner,
  SceneTimeRange,
  VizPanel,
} from '@grafana/scenes';
import { LegendDisplayMode, TooltipDisplayMode } from '@grafana/ui';
import { assertsColors } from 'app/constants';
import { CHART_COLORS_MAP } from 'components/LineRangeChart/constants';
import { setTimeRange } from 'features/App/App.slice';
import useAppTimeRange from 'hooks/useAppTimeRange';
import useDebounceValue from 'hooks/useDebounceValue';
import useDidUpdateEffect from 'hooks/useDidUpdate';
import { useMetricsDataSource } from 'hooks/useMetricsDatasource';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

interface Props {
  metricName: string | undefined;
  labelsQuery: string;
  value: number | undefined;
  thresholdLevel: string | undefined;
  thresholdQuery?: string;
}

export default function useThresholdScene({ metricName, labelsQuery, value, thresholdQuery, thresholdLevel }: Props) {
  const { data: datasource } = useMetricsDataSource();
  const dispatch = useDispatch();

  const { timeRange } = useAppTimeRange();

  const debouncedValue = useDebounceValue(value, 300);

  const assertionQuery = `${metricName}{${labelsQuery}}`;

  let thresholdValueQuery = ''; // thresholdQuery; TODO: enable it when we get back to this

  if (thresholdLevel === 'automatic') {
    thresholdValueQuery = `max by (asserts_threshold_level) (last_over_time(${metricName}:threshold{asserts_threshold_level="automatic", ${labelsQuery}}[10m]))`;
  }

  if (value) {
    thresholdValueQuery = `vector(${value})`;
  }

  let thresholdLabelHint = thresholdLevel ? `(${thresholdLevel})` : '';

  if (value) {
    thresholdLabelHint = '(Entered value)';
  }

  const metricNameForBand = metricName === 'asserts:latency:p99' ? 'asserts:latency:average' : metricName;

  const queries: QueryRunnerState['queries'] = [
    ...(metricName && labelsQuery
      ? [
          {
            refId: 'avg',
            expr: `avg(${assertionQuery})`,
          },
          {
            refId: 'max',
            expr: `max(${assertionQuery})`,
          },
        ]
      : []),

    ...(value //|| thresholdQuery TODO: enable it when we get back to this
      ? [
          {
            refId: 'threshold_value',
            expr: thresholdValueQuery,
            legendFormat:
              labelsQuery.includes('asserts_resource_type') && !value ? undefined : `Threshold ${thresholdLabelHint}`,
          },
        ]
      : []),
    {
      refId: 'upper_band',
      expr: `max(${metricNameForBand}:anomaly_upper_threshold{${labelsQuery}})`,
      legendFormat: 'anomaly_upper_threshold',
    },
    {
      refId: 'lower_band',
      expr: `min(${metricNameForBand}:anomaly_lower_threshold{${labelsQuery}})`,
      legendFormat: 'anomaly_lower_threshold',
    },
  ];

  const { scene, $data, $timeRange } = useMemo(() => {
    const $timeRange = new SceneTimeRange({ value: timeRange });

    const $data = new SceneQueryRunner({
      datasource: {
        type: 'prometheus',
        uid: datasource.uid,
      },
      $timeRange,
      queries,
    });

    const panel = new VizPanel({
      pluginId: 'timeseries',
      title: 'Threshold preview',
      options: {
        legend: { displayMode: LegendDisplayMode.Table },
        tooltip: { mode: TooltipDisplayMode.Multi },
      },
      fieldConfig: {
        defaults: {},
        overrides: [
          {
            matcher: {
              id: FieldMatcherID.byFrameRefID,
              options: 'threshold_value',
            },
            properties: [
              {
                id: FieldConfigProperty.Color,
                value: {
                  fixedColor: assertsColors.critical,
                  mode: FieldColorModeId.Fixed,
                },
              },
              {
                id: 'custom.lineStyle',
                value: {
                  dash: [8, 6],
                  fill: 'dash',
                },
              },
            ],
          },
          {
            matcher: {
              id: FieldMatcherID.byRegexp,
              options: '.*severity="warning".*',
            },
            properties: [
              {
                id: FieldConfigProperty.Color,
                value: {
                  fixedColor: assertsColors.warning,
                  mode: FieldColorModeId.Fixed,
                },
              },
            ],
          },
          {
            matcher: {
              id: FieldMatcherID.byRegexp,
              options: '.*severity="critical".*',
            },
            properties: [
              {
                id: FieldConfigProperty.Color,
                value: {
                  fixedColor: assertsColors.critical,
                  mode: FieldColorModeId.Fixed,
                },
              },
            ],
          },
          {
            matcher: {
              id: FieldMatcherID.byFrameRefID,
              options: 'lower_band',
            },

            properties: [
              {
                id: FieldConfigProperty.Color,
                value: {
                  fixedColor: CHART_COLORS_MAP.lightTheme.thresholds.minmax[0],
                  mode: FieldColorModeId.Fixed,
                },
              },
              {
                id: 'custom.lineWidth',
                value: 0,
              },
            ],
          },
          {
            matcher: {
              id: FieldMatcherID.byFrameRefID,
              options: 'upper_band',
            },

            properties: [
              {
                id: 'custom.lineWidth',
                value: 0,
              },
              {
                id: 'custom.fillBelowTo',
                value: 'anomaly_lower_threshold',
              },
              {
                id: FieldConfigProperty.Color,
                value: {
                  fixedColor: CHART_COLORS_MAP.lightTheme.thresholds.minmax[0],
                  mode: FieldColorModeId.Fixed,
                },
              },
            ],
          },
          {
            matcher: {
              id: FieldMatcherID.byFrameRefID,
              options: 'max',
            },

            properties: [
              {
                id: FieldConfigProperty.Color,
                value: {
                  fixedColor: '#FF8000',
                  mode: FieldColorModeId.Fixed,
                },
              },
            ],
          },
          {
            matcher: {
              id: FieldMatcherID.byFrameRefID,
              options: 'avg',
            },

            properties: [
              {
                id: FieldConfigProperty.Color,
                value: {
                  fixedColor: '#008000',
                  mode: FieldColorModeId.Fixed,
                },
              },
            ],
          },
          // {
          //   matcher: {
          //     id: FieldMatcherID.byFrameRefID,
          //     options: 'D',
          //   },
          //   properties: [
          //     {
          //       id: 'custom.lineStyle',
          //       value: {
          //         dash: [8, 6],
          //         fill: 'dash',
          //       },
          //     },
          //     {
          //       id: FieldConfigProperty.DisplayName,
          //       value: 'Automatic Threshold',
          //     },
          //   ],
          // },
        ],
      },
    });

    panel.onTimeRangeChange = (tr) => {
      dispatch(setTimeRange({ start: tr.from, end: tr.to }));
    };

    const scene = new EmbeddedScene({
      $data,
      body: new SceneFlexLayout({
        children: [
          new SceneFlexItem({
            body: panel,
          }),
        ],
      }),
    });

    return { scene, $data, $timeRange };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDidUpdateEffect(() => {
    if (metricName) {
      $data.setState({
        queries,
      });

      $data.runQueries();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assertionQuery, debouncedValue, thresholdLevel]);

  useDidUpdateEffect(() => {
    $timeRange.setState({ value: timeRange });
  }, [
    typeof timeRange.raw.from === 'string' ? timeRange.raw.from : timeRange.raw.from.valueOf(),
    typeof timeRange.raw.to === 'string' ? timeRange.raw.to : timeRange.raw.to.valueOf(),
  ]);

  return { scene };
}
