import { SelectableValue } from '@grafana/data';
import { Select } from '@grafana/ui';
import { MAX_AUTOCOMPLETE_RESULTS_COUNT } from 'app/constants';
import { useAppSelector } from 'app/store';
import { setSearch } from 'features/Assertions/Assertions.slice';
import { addToTopSearches } from 'features/Entities/Entities.slice';
import { TRACKING_FEATURES } from 'global-constants';
import TrackingHelper from 'helpers/Tracking.helper';
import useDebounceValue from 'hooks/useDebounceValue';
import useDefinitionAutocomplete from 'hooks/useDefinitionAutocomplete';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';


export default function AssertionsSearchQuerySelect() {
  const [query, setQuery] = useState('');

  const dispatch = useDispatch();

  const search = useAppSelector(state => state.assertions.search);

  const debouncedQuery = useDebounceValue(query, 300);

  const { data, isFetching } = useDefinitionAutocomplete({
    query: debouncedQuery,
    max: MAX_AUTOCOMPLETE_RESULTS_COUNT,
    enabled: Boolean(debouncedQuery),
  });

  const options: SelectableValue<string>[] =
    data?.map((d) => ({ value: d.boundDescription, label: d.boundDescription })) || [];
  const value = search ? { value: search, label: search } : null;

  useEffect(() => {
    if (search) {
      TrackingHelper.trackSearchExpression(search, TRACKING_FEATURES.RCA_WORKBENCH);
    }
  }, [search]);

  return (
    <Select
      value={value}
      options={options}
      isLoading={isFetching}
      filterOption={(o) => true}
      onChange={(v) => {
        dispatch(setSearch(v?.value));
        if (v.value) {
          dispatch(addToTopSearches(v.value));
        }
      }}
      onInputChange={(v) => setQuery(v)}
      placeholder="Enter search query (e.g. Show all Services)"
      noOptionsMessage={!query ? 'Start typing query (e.g. Show all...)' : undefined}
    />
  );
}
