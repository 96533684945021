/**
 *
 * CodeBlock
 *
 */

import React, { memo, FunctionComponent, useState, ReactNode } from 'react';
import ContentCopyRoundedIcon from 'assets/material-icons/content_copy_FILL0_wght400_GRAD0_opsz24.svg';
import CheckRoundedIcon from 'assets/material-icons/done_FILL0_wght400_GRAD0_opsz24.svg';
import { twMerge } from 'tailwind-merge';
import { IconButton } from 'components/IconButton/IconButton.component';

interface IProps {
  className?: string;
  children: ReactNode;
}

const CodeBlock: FunctionComponent<IProps> = ({ children, className }) => {
  const [copied, setCopied] = useState(false);

  const copy = () => {
    if (navigator.clipboard && children) {
      navigator.clipboard.writeText(children?.toString());
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };
  return (
    <div
      className={twMerge(
        'divider bg-canvas rounded-md text-secondary p-3 pr-10 relative font-mono text-sm break-all',
        className
      )}
    >
      {children}

      <div className="absolute top-1 right-1">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            copy();
          }}
        >
          {copied ? <CheckRoundedIcon className="text-success" /> : <ContentCopyRoundedIcon />}
        </IconButton>
      </div>
    </div>
  );
};

export default memo(CodeBlock);
