/**
 *
 * NotificationRequestForm
 *
 */

import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import { connect, ConnectedProps } from 'react-redux';

import { useForm, Controller } from 'react-hook-form';
import errorMessages from 'app/errorMessages';
import { AssertionGroupRule, NotificationConfig } from 'asserts-types';
import CheckIcon from 'assets/material-icons/done_FILL0_wght400_GRAD0_opsz24.svg';
import CloseIcon from 'assets/material-icons/close_FILL0_wght400_GRAD0_opsz24.svg';
import HealthRulesItem from '../HealthRulesItem/HealthRulesItem.component';
import { setItemToPopulate } from '../../../../ManageAssertions.slice';
import { stringToDate } from 'helpers/Date.helper';
import tailwindColors from 'tailwindcss/colors';
import useEnvSiteOptions from 'hooks/useEnvSiteOptions';
import { fetchDotColorsForGroup, saveNotification, saveSuppress } from 'services/ManageAssertions.service';
import { Button, Field, Input, Menu, MultiSelect, PanelContainer, Select, Switch, useTheme2 } from '@grafana/ui';
import useGroupRuleData from 'hooks/useGroupRuleData';
import NotificationsHealthChartComponent from 'features/ManageAssertions/components/NotificationsHealthChart/NotificationsHealthChart.component';
import { SelectableValue } from '@grafana/data';
import { Popover, PopoverContent, PopoverTrigger } from 'components/Popover/Popover.component';
import { IconButton } from 'components/IconButton/IconButton.component';
import { useMetricsDataSource } from 'hooks/useMetricsDatasource';

interface IProps {
  onAdd?: (item: NotificationConfig) => void;
  onUpdate?: (item: NotificationConfig) => void;
  onDelete?: (item: NotificationConfig) => void;
  onClose?: () => void;
  tableRow?: boolean;
  preloadedValue?: NotificationConfig;
  suppress?: boolean;
  active?: boolean;
}

const connector = connect(
  (state: RootState) => ({
    start: state.app.start,
    end: state.app.end,
    itemToPopulate: state.manageAssertions.itemToPopulate,
  }),
  { setItemToPopulate }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export interface IForm {
  ruleNameField: string;
  groupField: string | null;
  rulesField: string | null;
  otherLabelsField: string;
  envField: SelectableValue<string>[] | null;
  forField: string | null;
  receiverField: string | null;
  silenced?: boolean;
}

const NotificationRequestForm: FunctionComponent<IProps & PropsFromRedux> = ({
  onAdd,
  tableRow,
  preloadedValue,
  onClose,
  onDelete,
  onUpdate,
  start,
  end,
  suppress,
  active,
  itemToPopulate,
  setItemToPopulate,
}) => {
  const intl = useIntl();
  const startMs = useMemo(() => stringToDate('now-24h').valueOf(), []);
  const endMs = useMemo(() => stringToDate('now').valueOf(), []);

  const { data: groupRuleData, isFetching: isFetchingRules } = useGroupRuleData({ allFailureRuleGroups: true });

  const { data: envSiteOptions, isFetching: isFetchingEnvOptions } = useEnvSiteOptions({
    start: startMs,
    end: endMs,
  });

  const { handleSubmit, setValue, watch, control, reset } = useForm<IForm>({
    defaultValues: {
      ruleNameField: '',
      otherLabelsField: '',
      rulesField: '',
      groupField: preloadedValue?.matchLabels?.alertgroup || null,
      envField: preloadedValue?.matchLabels?.asserts_env?.split('|').map((o) => ({ label: o, value: o })) || [],
      forField: '',
      receiverField: '',
    },
    mode: 'onChange',
  });

  const [saving, setSaving] = useState(false);
  const [ruleOptions, setRuleOptions] = useState<AssertionGroupRule[]>([]);
  const [rulesDropdownOpened, setRulesDropdownOpened] = useState(false);

  const watchGroupField = watch('groupField');
  const watchRulesField = watch('rulesField');
  const watchRuleNameField = watch('ruleNameField');
  const watchOtherLabelsField = watch('otherLabelsField');
  const watchForField = watch('forField');
  const watchEnvField = watch('envField');

  const { data: metricsDatasource } = useMetricsDataSource();

  useEffect(() => {
    if (itemToPopulate && !itemToPopulate.notificationRuleName) {
      itemToPopulate.alertgroup && setValue('groupField', itemToPopulate.alertgroup);

      itemToPopulate.alertname && setValue('rulesField', itemToPopulate.alertname);

      setItemToPopulate(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemToPopulate]);

  useEffect(() => {
    if (!preloadedValue || !groupRuleData?.length) {
      return;
    }
    if (preloadedValue.name) {
      setValue('ruleNameField', preloadedValue.name);
    }
    if (preloadedValue.for) {
      setValue('forField', parseInt(preloadedValue.for, 10).toString());
    }
    if (preloadedValue.matchLabels.alertname) {
      setValue('rulesField', preloadedValue.matchLabels.alertname);
    }
    if (preloadedValue.alertLabels?.asserts_receiver) {
      setValue('receiverField', preloadedValue.alertLabels.asserts_receiver);
    }

    if (preloadedValue.matchLabels.additional_labels) {
      setValue('otherLabelsField', preloadedValue.matchLabels.additional_labels);
    }

    setValue('silenced', Boolean(preloadedValue.silenced));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupRuleData]);

  useEffect(() => {
    if (watchGroupField && metricsDatasource.uid) {
      const optionsToSet =
        groupRuleData
          ?.find((group) => group.name === watchGroupField)
          ?.rules.map((rule, _, rules) => ({
            ...rule,
            // we need count to apply special behaviour for items with same alertname but different labels
            count: rules.filter((r) => r.alert === rule.alert).length,
          })) || [];

      fetchDotColorsForGroup(
        metricsDatasource.uid,
        optionsToSet.map((o) => o.alert)
      ).then((res) => {
        setRuleOptions(
          optionsToSet.map((item) => ({
            ...item,
            dot: res.find((r) => r.alertname === item.alert)?.asserts_severity,
          }))
        );
      });
      setRuleOptions(optionsToSet);
    }
    if (!watchGroupField) {
      setRuleOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchGroupField]);

  useEffect(() => {
    if (!watchRuleNameField && watchRulesField) {
      setValue('ruleNameField', watchRulesField.replace(/\|$/, ''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchRulesField]);

  const filteredRuleOptions = useMemo(() => {
    // latest word after '|'
    const autocompleteFilterStr = watchRulesField?.match(/(?:.(?!\|))+$/gim)?.[0]?.replace('|', '');

    if (autocompleteFilterStr) {
      return ruleOptions.filter((rule) => rule.alert.toLowerCase().includes(autocompleteFilterStr.toLowerCase()));
    }

    return ruleOptions;
  }, [ruleOptions, watchRulesField]);

  const onSubmit = handleSubmit((data) => {
    setSaving(true);

    if (!data.rulesField) {
      return;
    }
    const alertname = data.rulesField;
    const alertgroup = data.groupField || undefined;
    const additional_labels = data.otherLabelsField || undefined;
    const asserts_receiver = data.receiverField || undefined;
    const asserts_env = data.envField?.map((o) => o.value)?.join('|') || undefined;

    const matchLabels = {
      alertname,
      additional_labels,
      alertgroup,
      asserts_env,
    };

    const alertLabels = {
      asserts_receiver,
    };

    const itemToSave = {
      name: data.ruleNameField.toString(),
      matchLabels,
      alertLabels,
      for: data.forField ? `${data.forField}m` : undefined,
    };

    (suppress ? saveSuppress(itemToSave) : saveNotification(itemToSave))
      .then(() => {
        if (preloadedValue) {
          onClose && onClose();
          onUpdate && onUpdate(itemToSave);
        } else {
          onAdd && onAdd(itemToSave);
          reset();
        }
      })
      .finally(() => setSaving(false));
  });

  const renderRuleNameControl = () => (
    <Controller
      name="ruleNameField"
      control={control}
      rules={{ required: intl.formatMessage(errorMessages.required) }}
      render={({ field, fieldState }) => (
        <Field
          className="min-w-[150px]"
          invalid={!!fieldState.error}
          error={fieldState.error?.message}
          label={intl.formatMessage(messages.ruleName)}
        >
          <Input {...field} placeholder="Enter rule name" disabled={!!preloadedValue || saving} />
        </Field>
      )}
    />
  );

  const renderGroupControl = () => (
    <Controller
      name="groupField"
      control={control}
      render={({ field }) => (
        <Field label={intl.formatMessage(messages.group)} className="min-w-[150px]">
          <Select
            {...field}
            onChange={(v) => field.onChange(v?.value || '')}
            options={groupRuleData?.map((o) => ({ label: o.name, value: o.name })) || []}
            disabled={saving}
            isLoading={isFetchingRules}
            isClearable
          />
        </Field>
      )}
    />
  );

  const isRuleOptionSelected = (option: AssertionGroupRule) => {
    const currentSeverityLabel = option.labels.asserts_severity;

    if (option.count && option.count < 2) {
      return !!watchRulesField?.includes(option.alert);
    }

    return watchOtherLabelsField.includes(currentSeverityLabel) && !!watchRulesField?.includes(option.alert);
  };

  const handleRulesChange = (option: AssertionGroupRule) => {
    let newSelectedRules = ruleOptions.filter((o) => isRuleOptionSelected(o));
    const isRemoving = isRuleOptionSelected(option);

    if (isRuleOptionSelected(option)) {
      newSelectedRules = newSelectedRules.filter(
        (r) => !(r.alert === option.alert && r.labels.asserts_severity === option.labels.asserts_severity)
      );
    } else {
      newSelectedRules = newSelectedRules.concat(option);
    }

    const alertValues: string[] = [];
    const assertsSeverityLabels: string[] = [];

    let otherLabelsValues = watchOtherLabelsField
      .split(
        // Splitting on comma outside quotes
        /,(?=(?:[^"]*"[^"]*")*[^"]*$)/
      )
      .filter((item) => item)
      .filter((item) => !item.includes('asserts_severity'));

    newSelectedRules.forEach((rule) => {
      const currentSeverityLabel = rule.labels.asserts_severity;

      if (!assertsSeverityLabels.includes(currentSeverityLabel)) {
        assertsSeverityLabels.push(currentSeverityLabel);
      }
    });

    if (option.count && option.count === assertsSeverityLabels.length && isRemoving) {
      newSelectedRules = newSelectedRules.filter((r) => r.alert !== option.alert);
    }

    newSelectedRules.forEach((rule) => {
      if (!alertValues.includes(rule.alert)) {
        alertValues.push(rule.alert);
      }
    });

    const rulesFieldValue = alertValues.join('|');
    const delimeter = rulesFieldValue.length ? '|' : '';

    setValue('rulesField', rulesFieldValue + delimeter);
    setValue(
      'otherLabelsField',
      otherLabelsValues.concat(`asserts_severity=~"${assertsSeverityLabels.join('|')}"`).join(',')
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  const handleRulesBlur = () => {
    setRulesDropdownOpened(false);
    setValue('rulesField', watchRulesField?.replace(/\|$/, '') || '');
  };
  const handleRulesFocus = () => {
    if (watchRulesField && watchRulesField.charAt(watchRulesField.length - 1) !== '|') {
      setValue('rulesField', watchRulesField + '|');
    }
  };

  const theme = useTheme2();

  const renderRuleControl = () => (
    <Controller
      name="rulesField"
      control={control}
      rules={{ required: intl.formatMessage(errorMessages.required) }}
      render={({ field, fieldState }) => (
        <>
          <Popover
            open={rulesDropdownOpened && !!filteredRuleOptions.length}
            placement="bottom-start"
            onOpenChange={setRulesDropdownOpened}
          >
            <PopoverTrigger asChild onClick={() => setRulesDropdownOpened(true)}>
              <div>
                <Field
                  invalid={!!fieldState.error}
                  error={fieldState.error?.message}
                  label={intl.formatMessage(messages.rules)}
                  className="w-full"
                >
                  <Input
                    {...field}
                    value={field.value || ''}
                    placeholder="Enter rules values"
                    onBlur={handleRulesBlur}
                    onFocus={handleRulesFocus}
                  />
                </Field>
              </div>
            </PopoverTrigger>
            <PopoverContent>
              <Menu>
                <div className="max-h-[300px] overflow-y-auto">
                  {filteredRuleOptions.map((option) => (
                    <div
                      className="py-2 px-3"
                      key={`${option.alert}-${option.expr}`}
                      style={{ background: isRuleOptionSelected(option) ? theme.colors.action.selected : undefined }}
                    >
                      <HealthRulesItem
                        option={option}
                        start={start}
                        end={end}
                        onChange={() => handleRulesChange(option)}
                        selected={isRuleOptionSelected(option)}
                      />
                    </div>
                  ))}
                </div>
              </Menu>
            </PopoverContent>
          </Popover>
        </>
      )}
    />
  );

  const renderEvnControl = () => (
    <Controller
      name="envField"
      control={control}
      render={({ field }) => (
        <Field label={intl.formatMessage(messages.env)}>
          <MultiSelect
            {...field}
            onChange={(v) => field.onChange(v || [])}
            value={field.value || undefined}
            options={envSiteOptions?.scopeValues.env?.map((o) => ({ label: o, value: o })) || []}
            disabled={saving}
            isClearable
            isLoading={isFetchingEnvOptions}
          />
        </Field>
      )}
    />
  );

  const renderReceiverField = () => (
    <Controller
      name="receiverField"
      control={control}
      render={({ field }) => (
        <Field label={intl.formatMessage(messages.receiver)} className="min-w-[150px]">
          <Input {...field} value={field.value || ''} placeholder="Enter value" disabled={saving} />
        </Field>
      )}
    />
  );

  const renderForControl = () => (
    <Controller
      name="forField"
      control={control}
      render={({ field }) => (
        <Field label={intl.formatMessage(messages.for)} className="min-w-[150px]">
          <Input
            {...field}
            value={field.value || ''}
            placeholder="Value"
            disabled={saving}
            type="number"
            suffix="min"
          />
        </Field>
      )}
    />
  );

  const renderOtherLabelsControl = () => (
    <Controller
      name="otherLabelsField"
      control={control}
      render={({ field }) => (
        <Field label={intl.formatMessage(messages.otherLabels)} className="min-w-[150px]">
          <Input {...field} value={field.value || ''} placeholder="Enter value" disabled={saving} />
        </Field>
      )}
    />
  );

  const renderSilenceSwitch = () => (
    <Controller
      name="silenced"
      control={control}
      render={({ field }) => <Switch value={!field.value} onClick={() => field.onChange(!field.value)} />}
    />
  );

  const labelsFromForm = {
    ...(watchGroupField ? { alertgroup: watchGroupField } : {}),
    ...(watchRulesField ? { alertname: watchRulesField } : {}),
    ...(watchEnvField?.length ? { asserts_env: watchEnvField.map((o) => o.value).join('|') } : {}),
  };

  if (tableRow) {
    return (
      <>
        <tr style={active ? { backgroundColor: tailwindColors.yellow[50] } : undefined}>
          <td className="p-4">{renderRuleNameControl()}</td>
          <td className="p-4">{renderGroupControl()}</td>
          <td className="p-4">{renderRuleControl()}</td>
          <td className="p-4">{renderEvnControl()}</td>
          {!suppress && <td className="p-4">{renderReceiverField()}</td>}
          {!suppress && <td className="p-4">{renderForControl()}</td>}
          <td className="p-4">{renderOtherLabelsControl()}</td>
          {!suppress && <td className="p-4">{renderSilenceSwitch()}</td>}
          <td className="p-4">
            <div className="flex items-center">
              <IconButton variant="primary" onClick={() => onSubmit()} disabled={saving}>
                <CheckIcon />
              </IconButton>
              <IconButton variant="primary" onClick={onClose} disabled={saving}>
                <CloseIcon />
              </IconButton>
            </div>
            {/* <IconButton
            color="primary"
            className="text-destructive"
            onClick={() =>
              preloadedValue && onDelete && onDelete(preloadedValue)
            }
            disabled={saving}
          >
            <DeleteOutlineIcon />
          </IconButton> */}
          </td>
        </tr>
        {!suppress && (
          <tr>
            <td colSpan={9}>
              <NotificationsHealthChartComponent
                labels={labelsFromForm}
                continuesFor={watchForField}
                show={Boolean(watchGroupField && watchRulesField)}
              />
            </td>
          </tr>
        )}
      </>
    );
  }

  return (
    <PanelContainer className="mt-8 p-6">
      <p className="mb-6 text-xl">{intl.formatMessage(suppress ? messages.addTitleSuppress : messages.addTitle)}</p>
      <form onSubmit={onSubmit}>
        <div
          className={`grid gap-2.5 ${
            suppress
              ? 'grid-cols-[repeat(1,1fr)_repeat(1,2fr)_repeat(3,1fr)]'
              : 'grid-cols-[repeat(1,1fr)_repeat(1,2fr)_repeat(5,1fr)]'
          }`}
        >
          {renderGroupControl()}
          {renderRuleControl()}
          {renderEvnControl()}
          {!suppress && renderReceiverField()}
          {!suppress && renderForControl()}
          {renderOtherLabelsControl()}
          {renderRuleNameControl()}
        </div>
        {!suppress && (
          <NotificationsHealthChartComponent
            labels={labelsFromForm}
            continuesFor={watchForField}
            show={Boolean(watchGroupField && watchRulesField)}
          />
        )}
        <Button color="primary" type="submit" disabled={saving}>
          {saving ? 'Adding...' : intl.formatMessage(messages.addNew)}
        </Button>
      </form>
    </PanelContainer>
  );
};

export default connector(NotificationRequestForm);
