/**
 *
 * ThresholdRequestForm
 *
 */

import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import assertionsOptions from '../../../../config/assertions_request.json';
import useDidUpdateEffect from 'hooks/useDidUpdate';
import { connect, ConnectedProps } from 'react-redux';

import { useForm, Controller } from 'react-hook-form';
import errorMessages from 'app/errorMessages';
import { ThresholdItem } from 'asserts-types';
import thresholdAssertionMap from '../../../../config/threshold_assertion_map.json';
import has from 'helpers/has.helper';
import { setItemToPopulate } from '../../../../ManageAssertions.slice';
import ThresholdChartComponent from '../../../ThresholdChart/ThresholdChart.component';
import ThresholdValueTooltipComponent from '../../../ThresholdValueTooltip/ThresholdValueTooltip.component';
import useJobOptions from 'hooks/useJobOptions';
import useRequestTypeOptions from 'hooks/useRequestTypeOptions';
import useRequestContextOptions from 'hooks/useRequestContextOptions';
import { saveThreshold } from 'services/ManageAssertions.service';
import { Button, Field, Input, PanelContainer, Select } from '@grafana/ui';
import { SelectableValue } from '@grafana/data';
import { getThreholdValueQueryForRequest } from 'helpers/Query.helper';
import useGrafanaQuery from 'hooks/useGrafanaQuery';
import { Tooltip } from 'components/Tooltip/Tooltip.component';

interface IProps {
  onAdd: (item: ThresholdItem) => void;
  globalList: ThresholdItem[];
  customList: ThresholdItem[];
  fetchingLists: boolean;
}

const connector = connect(
  (state: RootState) => ({
    itemToPopulate: state.manageAssertions.itemToPopulate,
    start: state.app.start,
    end: state.app.end,
  }),
  { setItemToPopulate }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IForm {
  assertionField: SelectableValue<string> | null;
  jobField: string | null;
  requestTypeField: string | null;
  requestContextField: string | null;
  valueField: string;
}

const getLabelsFromForm = (form: IForm) => {
  let labels: { job?: string; asserts_request_type?: string; asserts_request_context?: string } = {};

  if (form.jobField) {
    labels.job = form.jobField;
  }
  if (form.requestTypeField) {
    labels.asserts_request_type = form.requestTypeField;
  }
  if (form.requestContextField) {
    labels.asserts_request_context = form.requestContextField;
  }
  return labels;
};


const ThresholdRequestForm: FunctionComponent<IProps & PropsFromRedux> = ({
  onAdd,
  globalList,
  customList,
  itemToPopulate,
  fetchingLists,
  setItemToPopulate,
  start,
  end,
}) => {
  const intl = useIntl();

  const { handleSubmit, setValue, watch, control, reset, getValues } = useForm<IForm>({
    defaultValues: {
      assertionField: null,
      requestContextField: null,
      requestTypeField: null,
      valueField: '',
      jobField: null,
    },
    mode: 'onChange',
  });

  const [saving, setSaving] = useState(false);

  const watchJobField = watch('jobField');

  const watchRequestType = watch('requestTypeField');
  const watchAssertionField = watch('assertionField');

  const { data: jobOptions, isFetching: isFetchingJobOptions } = useJobOptions(start, end);

  const { data: requestTypeOptions, isFetching: isFetchingRequestTypeOptions } = useRequestTypeOptions({
    job: watchJobField,
    start,
    end,
  });

  const { data: requestContextOptions, isFetching: isFetchingRequestContextOptions } = useRequestContextOptions({
    job: watchJobField,
    requestType: watchRequestType,
    start,
    end,
  });

  const currentFormData = watch();

  const labels = getLabelsFromForm(currentFormData);

  const thresholdQuery = labels.job
    ? getThreholdValueQueryForRequest(labels as Record<string, string> & { job: string })
    : undefined;

  const { data: currentThresholdData } = useGrafanaQuery({
    query: thresholdQuery || '',
    enabled: !!labels.job,
  });

  const thresholdLevel =
    typeof currentThresholdData?.[0]?.metric.asserts_threshold_level === 'string'
      ? currentThresholdData?.[0]?.metric.asserts_threshold_level
      : undefined;

  const defaultValue = useMemo(
    () => globalList.find((item) => item.record === watchAssertionField?.value)?.expr,
    [globalList, watchAssertionField?.value]
  );

  const resetIfNotInOptions = (field: keyof IForm, options: string[]) => {
    const value = getValues(field);
    if (typeof value === 'string' && !options.find((option) => option === value)) {
      setValue(field, '');
    }
  };

  useDidUpdateEffect(() => {
    requestTypeOptions?.length && resetIfNotInOptions('requestTypeField', requestTypeOptions);
    requestContextOptions?.length && resetIfNotInOptions('requestContextField', requestContextOptions);
  }, [requestContextOptions, requestTypeOptions]);

  useEffect(() => {
    if (
      !fetchingLists &&
      itemToPopulate &&
      !customList.some(
        (item) =>
          itemToPopulate.alertname === item.assertion &&
          itemToPopulate.asserts_request_context === item.labels?.asserts_request_context &&
          itemToPopulate.asserts_request_type === item.labels?.asserts_request_type &&
          itemToPopulate.job === item.labels?.job
      ) &&
      assertionsOptions.find(
        (item) =>
          has(thresholdAssertionMap, item.value) && thresholdAssertionMap[item.value] === itemToPopulate.alertname
      )
    ) {
      const assertionFieldValue = Object.entries(thresholdAssertionMap).find(
        ([key, value]) => value === itemToPopulate.alertname
      )?.[0];

      assertionFieldValue &&
        itemToPopulate.alertname &&
        setValue('assertionField', {
          label: itemToPopulate.alertname,
          value: assertionFieldValue,
        });
      itemToPopulate.job && setValue('jobField', itemToPopulate.job);

      itemToPopulate.asserts_request_type && setValue('requestTypeField', itemToPopulate.asserts_request_type);

      itemToPopulate.asserts_request_context && setValue('requestContextField', itemToPopulate.asserts_request_context);

      setItemToPopulate(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customList, globalList, itemToPopulate, fetchingLists]);

  const onSubmit = handleSubmit((data) => {
    if (data.assertionField?.value) {
      setSaving(true);

      const labels = getLabelsFromForm(data);

      saveThreshold(data.assertionField.value.toString(), data.valueField, labels).then(() => {
        onAdd({
          expr: data.valueField,
          record: data.assertionField?.value?.toString() || '',
          labels,
        });
        reset();
        setSaving(false);
      });
    }
  });


  return (
    <PanelContainer className="mt-8 p-6">
      <p className="mb-6 text-xl">{intl.formatMessage(messages.addTitle)}</p>
      <form onSubmit={onSubmit}>
        <div className="grid gap-2.5 grid-cols-5">
          <Controller
            name="jobField"
            control={control}
            rules={{ required: intl.formatMessage(errorMessages.required) }}
            render={({ field, fieldState }) => (
              <Field
                invalid={!!fieldState.error}
                error={fieldState.error?.message}
                label={intl.formatMessage(messages.job)}
              >
                <Select
                  {...field}
                  onChange={(v) => field.onChange(v?.value || '')}
                  options={jobOptions?.map((o) => ({ label: o, value: o })) || []}
                  virtualized
                  disabled={saving}
                  isLoading={isFetchingJobOptions}
                  allowCustomValue
                  isClearable
                />
              </Field>
            )}
          />
          <Controller
            name="assertionField"
            control={control}
            rules={{ required: intl.formatMessage(errorMessages.required) }}
            render={({ field, fieldState }) => (
              <Field
                invalid={!!fieldState.error}
                error={fieldState.error?.message}
                label={intl.formatMessage(messages.assertion)}
              >
                <Select
                  {...field}
                  isClearable
                  options={assertionsOptions}
                  disabled={!assertionsOptions.length || saving}
                />
              </Field>
            )}
          />
          <Controller
            name="requestTypeField"
            control={control}
            render={({ field }) => (
              <Field label={intl.formatMessage(messages.requestType)}>
                <Select
                  {...field}
                  onChange={(v) => field.onChange(v?.value || '')}
                  options={requestTypeOptions?.map((o) => ({ label: o, value: o })) || []}
                  virtualized
                  disabled={saving || watchAssertionField?.value?.toString() === 'asserts:error_log:threshold'}
                  isLoading={isFetchingRequestTypeOptions}
                  isClearable
                />
              </Field>
            )}
          />
          <Controller
            name="requestContextField"
            control={control}
            render={({ field }) => (
              <Field label={intl.formatMessage(messages.requestContext)}>
                <Select
                  {...field}
                  onChange={(v) => field.onChange(v?.value || '')}
                  options={requestContextOptions?.map((o) => ({ label: o, value: o })) || []}
                  virtualized
                  disabled={saving || watchAssertionField?.value?.toString() === 'asserts:error_log:threshold'}
                  isLoading={isFetchingRequestContextOptions}
                  isClearable
                />
              </Field>
            )}
          />

          <Controller
            name="valueField"
            control={control}
            rules={{ required: intl.formatMessage(errorMessages.required) }}
            render={({ field, fieldState }) => (
              <Tooltip
                content={
                  <ThresholdValueTooltipComponent
                    labels={getLabelsFromForm(currentFormData)}
                    assertion={watchAssertionField?.value?.toString()}
                    start={start}
                    end={end}
                    onApply={(value) => setValue('valueField', value)}
                  />
                }
                show={Boolean(watchJobField && watchAssertionField)}
                placement="top"
              >
                <Field
                  invalid={!!fieldState.error}
                  error={fieldState.error?.message}
                  label={intl.formatMessage(messages.value) + (defaultValue ? ` (${defaultValue})` : '')}
                >
                  <Input
                    {...field}
                    placeholder="Enter value"
                    suffix={
                      watchAssertionField?.label === 'LatencyP99ErrorBuildup' ||
                      watchAssertionField?.label === 'LatencyAverageBreach'
                        ? intl.formatMessage(messages.secs)
                        : undefined
                    }
                    type="number"
                  />
                </Field>
              </Tooltip>
            )}
          />
        </div>
        <ThresholdChartComponent
          show={Boolean(watchJobField && watchAssertionField)}
          labels={labels}
          assertion={watchAssertionField?.value?.toString()}
          value={currentFormData.valueField ? +currentFormData.valueField : undefined}
          thresholdQuery={thresholdQuery}
          thresholdLevel={thresholdLevel}
        />
        <Button type="submit" disabled={saving}>
          {intl.formatMessage(messages.addNew)}
        </Button>
      </form>
    </PanelContainer>
  );
};

export default connector(ThresholdRequestForm);
