/**
 *
 * SloTargetRow
 *
 */

import React, { FunctionComponent, memo, useMemo, useState, MouseEvent, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import { Slo, SloIncident, SloQueryParams, SloTarget, SloType, ZoomedPos } from 'asserts-types';
import SloChart from '../SloChart/SloChart.component';
import CheckCircleIcon from 'assets/material-icons/check_circle_FILL0_wght400_GRAD0_opsz24.svg';
import ErrorIcon from 'assets/material-icons/error_FILL0_wght400_GRAD0_opsz24.svg';
import KeyboardArrowDownRoundedIcon from 'assets/material-icons/keyboard_arrow_down_FILL0_wght400_GRAD0_opsz24.svg';
import KeyboardArrowRightRoundedIcon from 'assets/material-icons/chevron_right_FILL0_wght400_GRAD0_opsz24.svg';
import { useNavigate } from 'react-router-dom';
import qs from 'qs';
import { toggleExpandItem } from '../../Slo.slice';
import { formatLongNumber, roundTo } from 'helpers/ValueFormat.helper';
import { fetchSloIncidents } from 'services/Slo.service';
import moment from 'moment';
import { setItemToPopulate } from '../../../ManageAssertions/ManageAssertions.slice';

import { dateToParams, stringToDate } from 'helpers/Date.helper';
import { SLO_ROUND } from '../../constants';
import { Badge, Button, InlineField, QueryField, SlatePrism } from '@grafana/ui';
import { prefixRoute } from 'utils/utils.routing';
import { ROUTES } from 'global-constants';
import { Tooltip } from 'components/Tooltip/Tooltip.component';
import { UserCan, isUserActionAllowed } from 'utils/permissions';

interface IProps {
  sloTarget: SloTarget;
  chartNames: string[];
  slo: Slo;
  toggleExpandItem: typeof toggleExpandItem;
  expanded: boolean;
  setItemToPopulate: typeof setItemToPopulate;
  endTime: number | string;
}

const SloTargetRow: FunctionComponent<IProps> = ({
  sloTarget,
  chartNames,
  slo,
  toggleExpandItem,
  expanded,
  setItemToPopulate,
  endTime,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const rowRef = useRef<HTMLTableRowElement>(null);
  const [sloIncidents, setSloIncidents] = useState<SloIncident>();
  const isRed = useMemo(() => sloTarget.actualSli < sloTarget.targetSli, [sloTarget]);
  const [zoomedXPos, setZoomedXPos] = useState<ZoomedPos>({
    startX: 0,
    endX: 0,
  });

  useEffect(() => {
    const queryParams = qs.parse(window.location.search.slice(1)) as unknown as SloQueryParams;
    if (
      queryParams.sloName === slo.name &&
      queryParams.sloTargetName === sloTarget.name &&
      queryParams.sloEnv === slo.scope?.env &&
      queryParams.sloSite === slo.scope?.site
    ) {
      !expanded && toggleExpandItem(sloTarget.hash);
      queueMicrotask(() => {
        rowRef.current?.scrollIntoView(true);
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (expanded) {
      const chartEndTime = stringToDate(endTime).valueOf();
      fetchSloIncidents(
        slo.name,
        sloTarget.name,
        moment(chartEndTime).add(-sloTarget.complianceWindowDto.numDays, 'd').valueOf(),
        moment(chartEndTime).valueOf(),
        slo.scope
      ).then((res) => {
        setSloIncidents(res);
      });
    }
    // eslint-disable-next-line
  }, [expanded, endTime]);

  const isRedBudgetAvailability = useMemo(() => sloTarget.oneHourBurnRate > sloTarget.fastBurnThreshold, [sloTarget]);

  const toggleCharts = () => {
    toggleExpandItem(sloTarget.hash);
  };

  const openIncidents = (e: MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    navigate(
      `${prefixRoute(ROUTES.INCIDENTS)}?start=now-${
        sloTarget.complianceWindowDto.numDays
      }d&end=now&search=${encodeURIComponent(slo.name)}`,
      { state: { timestamp: Date.now() } }
    );
  };

  const openManageThreshold = (e: MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const match = (slo.badEventQuery || slo.measurementQuery)?.match(/job=".*?"/)?.[0];
    if (match) {
      setItemToPopulate({
        alertname: match.replace('job="', '').replace('"', ''),
      });
    }

    navigate(prefixRoute([ROUTES.RULES, ROUTES.RULES_THRESHOLD, ROUTES.REQUEST].join('/')), {
      state: { timestamp: Date.now() },
    });
  };

  const [copied, setCopied] = useState(false);

  const handleCopyUrl = (e: MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    if (navigator.clipboard) {
      navigator.clipboard.writeText(
        `${window.location.origin}${prefixRoute(ROUTES.SLO)}?sloName=${slo.name}&sloTargetName=${
          slo.sloTargetDtos[0].name
        }${slo.scope?.env ? '&sloEnv=' + slo.scope?.env : ''}${
          slo.scope?.site ? '&sloSite=' + slo.scope?.site : ''
        }&endTime=${dateToParams(endTime)}`
      );
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  return (
    <>
      <tr className="cursor-pointer hover:bg-hover group" onClick={toggleCharts} ref={rowRef}>
        <td className={`p-4 relative pl-0 overflow-visible min-w-[220px] ${expanded ? 'divider-b' : 'border-0'}`}>
          <div className="flex items-center gap-2 pl-2">
            {expanded ? (
              <KeyboardArrowDownRoundedIcon
                className="svg-icon"
                data-intercom-target="slo-expand-btn"
                data-cy="slo-expand-btn"
              />
            ) : (
              <KeyboardArrowRightRoundedIcon
                className="svg-icon"
                data-intercom-target="slo-expand-btn"
                data-cy="slo-expand-btn"
              />
            )}
            <p className="whitespace-nowrap">Objective</p>
          </div>
        </td>
        <td className={`p-4 ${expanded ? 'divider-b' : 'border-0'}`}>
          <p className="text-secondary">
            {intl.formatMessage(messages.day, {
              count: sloTarget.complianceWindowDto.numDays,
            })}
          </p>
        </td>
        <td className={`p-4 ${expanded ? 'divider-b' : 'border-0'}`}>
          <Badge text={`${roundTo(sloTarget.targetSli, SLO_ROUND) || 0}%`} color={isRed ? 'red' : 'green'} />
        </td>
        <td className={`p-4 ${expanded ? 'divider-b' : 'border-0'}`}>
          <Badge text={`${roundTo(sloTarget.actualSli, SLO_ROUND) || 0}%`} color={isRed ? 'red' : 'green'} />
        </td>
        <td className={`p-4 ${expanded ? 'divider-b' : 'border-0'}`} data-intercom-target="incidents-in-window">
          <div className="flex items-center gap-2">
            <p
              className={`font-bold text-xl ${
                isRed ? 'text-destructive' : sloTarget.incidentCount === 0 ? 'text-success' : 'text-yellow-500'
              }`}
            >
              {sloTarget.incidentCount || 0}
            </p>
            {/* <Button
              data-cy="incidents-in-window"
              className={`inline-block min-w-0 invisible group-hover:!visible ${
                sloTarget.incidentCount ? '' : '!invisible'
              }`}
              variant="secondary"
              size="sm"
              onClick={(e) => openIncidents(e)}
            >
              View
            </Button> */}
          </div>
        </td>
        <td className={`p-4 ${expanded ? 'divider-b' : 'border-0'}`}>
          <div className="flex">
            <Tooltip
              content={
                <>
                  <div className="text-destructive">
                    {intl.formatMessage(messages.usedBudget, {
                      errorBudget: formatLongNumber(Math.round(sloTarget.errorBudget - sloTarget.errorBudgetBalance)),
                      type: intl.formatMessage(slo.type === SloType.Occurrence ? messages.min : messages.requests),
                    })}
                  </div>
                  <div className="text-success">
                    {intl.formatMessage(messages.availableBudget, {
                      errorBudget: formatLongNumber(Math.round(sloTarget.errorBudget)),
                      type: intl.formatMessage(slo.type === SloType.Occurrence ? messages.min : messages.requests),
                    })}
                  </div>
                </>
              }
            >
              <p className={`${isRed ? 'text-destructive' : 'text-success'} font-bold text-xl`}>
                {sloTarget.budgetRemainingPersent}x
              </p>
            </Tooltip>
          </div>
        </td>
        <td className={`p-4 ${expanded ? 'divider-b' : 'border-0'}`}>
          <Tooltip content={sloTarget.incidentTriggerDescription}>
            <p
              className={`${
                isRedBudgetAvailability ? 'text-destructive' : 'text-success'
              } font-bold text-xl inline-block`}
            >
              {roundTo(sloTarget.recentBurnRate, SLO_ROUND) || 0}x
            </p>
          </Tooltip>
        </td>
        <td className={`p-4 ${expanded ? 'divider-b' : 'border-0'}`}>
          <Tooltip content={sloTarget.incidentTriggerDescription}>
            <p className="mr-8 inline-block" onClick={(e) => openIncidents(e)}>
              {sloTarget.fastMin === 0 && sloTarget.slowMin === 0 ? (
                <CheckCircleIcon className="text-success svg-icon" />
              ) : (
                <ErrorIcon className="text-destructive svg-icon" />
              )}
            </p>
          </Tooltip>
        </td>
        <td className={`p-0 pr-8 w-[1%] ${expanded ? 'divider-b' : 'border-0'}`}>
          <div className="flex items-center gap-2 invisible group-hover:!visible">
            {isUserActionAllowed(UserCan.ReadRules) && (
              <Button
                className={`${slo.type === SloType.Occurrence ? '' : '!invisible'}`}
                onClick={openManageThreshold}
                icon="chart-line"
                variant="secondary"
                size="sm"
              >
                {intl.formatMessage(messages.thresholds)}
              </Button>
            )}
            <Button variant="secondary" className="whitespace-nowrap" icon="link" onClick={handleCopyUrl} size="sm">
              {intl.formatMessage(copied ? messages.copied : messages.copy)}
            </Button>
          </div>
        </td>
      </tr>
      {expanded && (
        <>
          <tr>
            <td colSpan={15} className="p-8 border-0">
              {slo.type === SloType.Request && (
                <>
                  <InlineField label={intl.formatMessage(messages.badEventQuery)} disabled>
                    <QueryField
                      additionalPlugins={[
                        SlatePrism({
                          onlyIn: (node: any) => node.type === 'code_block',
                          getSyntax: () => 'promql',
                        }),
                      ]}
                      portalOrigin="."
                      query={slo.badEventQuery}
                    />
                  </InlineField>
                  <InlineField label={intl.formatMessage(messages.totalEventQuery)} disabled>
                    <QueryField
                      additionalPlugins={[
                        SlatePrism({
                          onlyIn: (node: any) => node.type === 'code_block',
                          getSyntax: () => 'promql',
                        }),
                      ]}
                      portalOrigin="."
                      query={slo.totalEventQuery}
                    />
                  </InlineField>
                </>
              )}
              {slo.type === SloType.Occurrence && (
                <>
                  <p>
                    <strong>{intl.formatMessage(messages.measurementQuery)}:</strong>{' '}
                    <code>{slo.measurementQuery}</code>
                  </p>
                  <p>
                    <strong>{intl.formatMessage(messages.thresholdValue)}:</strong> {sloTarget.threshold}
                  </p>
                </>
              )}
            </td>
          </tr>
          <tr>
            <td colSpan={15} className="px-8 pb-8 border-b-0">
              <div className="grid grid-cols-1 gap-6">
                {chartNames.map((chartName, index) => (
                  <SloChart
                    key={`chart-${index}`}
                    chartName={chartName}
                    sloName={slo.name}
                    sloTargetName={sloTarget.name}
                    days={sloTarget.complianceWindowDto.numDays}
                    scope={slo.scope}
                    zoomedXPos={zoomedXPos}
                    setZoomedXPos={setZoomedXPos}
                    slo={slo}
                    hash={sloTarget.hash}
                    sloIncidents={sloIncidents}
                    endTime={endTime}
                  />
                ))}
              </div>
            </td>
          </tr>
        </>
      )}
    </>
  );
};

export default memo(SloTargetRow);
