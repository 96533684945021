import { useSelector } from 'react-redux';

import { stringToDate } from '../helpers/Date.helper';
import { useQuery } from '@tanstack/react-query';
import { IncidentSummary } from 'asserts-types';
import { fetchTopIncidents } from 'services/Slo.service';

interface Params {
  enabled: boolean | undefined;
  start: number | string;
  end: number | string;
}
export default function useTopIncidents({ enabled, start, end }: Params) {
  const selectedEnv = useSelector((state: RootState) => state.app.selectedEnv);
  const selectedSite = useSelector((state: RootState) => state.app.selectedSite);

  return useQuery<IncidentSummary[]>(
    ['topIncidents', start, end, selectedEnv, selectedSite],
    async () => {
      const data = await fetchTopIncidents(stringToDate(start).valueOf(), stringToDate(end).valueOf());
      return data.incidentSummaries;
    },
    { enabled, staleTime: typeof start === 'string' ? 0 : Infinity }
  );
}
