/*
 * EntityAdvancedSearch Messages
 *
 * This contains all the text for the EntityAdvancedSearch container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'features.EntityAdvancedSearchBottomPanel';

export default defineMessages({
  search: {
    id: `${scope}.search`,
    defaultMessage: 'Search',
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Save',
  },
  saving: {
    id: `${scope}.saving`,
    defaultMessage: 'Saving...',
  },
  saveChanges: {
    id: `${scope}.saveChanges`,
    defaultMessage: 'Save changes',
  },
  delete: {
    id: `${scope}.delete`,
    defaultMessage: 'Delete',
  },
  deleting: {
    id: `${scope}.deleting`,
    defaultMessage: 'Deleting...',
  },
  created: {
    id: `${scope}.created`,
    defaultMessage:
      'New search was successfuly created. You can find this search in the search autocomplete',
  },
  updated: {
    id: `${scope}.updated`,
    defaultMessage: 'The search was successfuly updated.',
  },
  noName: {
    id: `${scope}.noName`,
    defaultMessage: 'Please specify a Search name eq "Show all my Services"',
  },
});
