/**
 *
 * GraphViewSettings
 *
 */

import React, { memo, FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import ShowEnvSiteTagSettingComponent from 'components/ShowEnvSiteTagSetting/ShowEnvSiteTagSetting.component';
import { Box, Switch } from '@grafana/ui';
import FormControlLabel from 'components/FormControlLabel/FormControlLabel.component';

interface IProps {
  showRelationships: boolean;
  setShowRelationships: (showRelationships: boolean) => void;
  showNodeNames: boolean;
  setShowNodeNames: (showRelationships: boolean) => void;
  showFullNodeNameText: boolean;
  setShowFullNodeNameText: (showFullNodeNameText: boolean) => void;
  highlightSelected: boolean;
  setHighlightSelected: (value: boolean) => void;
  showCallRates: boolean;
  setShowCallRates: (value: boolean) => void;
}

const GraphViewSettings: FunctionComponent<IProps> = ({
  showRelationships,
  setShowRelationships,
  showNodeNames,
  setShowNodeNames,
  showFullNodeNameText,
  setShowFullNodeNameText,
  highlightSelected,
  setHighlightSelected,
  showCallRates,
  setShowCallRates,
}) => {
  const intl = useIntl();

  return (
    <Box display="flex" direction="column" marginTop={2} minWidth={25} gap={1}>
      <ShowEnvSiteTagSettingComponent />
      <FormControlLabel
        control={
          <Switch color="primary" value={showRelationships} onClick={() => setShowRelationships(!showRelationships)} />
        }
        label={intl.formatMessage(messages.relationName)}
      />
      <FormControlLabel
        control={<Switch color="primary" value={showNodeNames} onClick={() => setShowNodeNames(!showNodeNames)} />}
        label={intl.formatMessage(messages.nodeName)}
      />
      <FormControlLabel
        control={
          <Switch
            color="primary"
            value={showFullNodeNameText}
            onClick={() => setShowFullNodeNameText(!showFullNodeNameText)}
          />
        }
        label={'Show full node name text'}
      />
      <FormControlLabel
        control={
          <Switch color="primary" value={highlightSelected} onClick={() => setHighlightSelected(!highlightSelected)} />
        }
        label={intl.formatMessage(messages.highlightSelection)}
      />
      <FormControlLabel
        control={<Switch color="primary" value={showCallRates} onClick={() => setShowCallRates(!showCallRates)} />}
        label={'Show call rates'}
      />
    </Box>
  );
};

export default memo(GraphViewSettings);
