
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import has from 'helpers/has.helper';
import { Entity } from 'asserts-types';
import { kpiRegexp } from 'helpers/Entity.helper';

export default function (rows: Entity[], columns: string[]) {
  const propByKpis = useSelector(
    (state: RootState) => state.entities.propByKpis,
  );

  return useMemo(() => {
    const columnsWidthsMap: Record<string, number> = {};

    columns.forEach((column) => {
      const columnValues = rows.map((row) => {
        let kpiValue: number | undefined;

        if (propByKpis[column]) {
          const kpisKeys = Object.keys(propByKpis[column]);
          const reg = kpiRegexp(row.name, row.scope);
          const kpiKey = kpisKeys.find((key) => reg.test(key)) as string;
          kpiValue = propByKpis[column][kpiKey]?.kpiValue;
        }
        return (has<Entity, string, string>(row, column)
          ? row[column]
          : kpiValue
          ? kpiValue
          : row.properties[column] || row.properties[column] === 0
          ? row.properties[column] || 0
          : '–'
        )?.toString().length;
      });
      const maxWordLength = Math.max(...columnValues);
      columnsWidthsMap[column] = maxWordLength * 8 + 30;
    });
    return columnsWidthsMap;
  }, [columns, propByKpis, rows]);
}
