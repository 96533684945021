import { dateTime } from '@grafana/data';
import { Metric, Scope } from 'asserts-types';
import { setActiveEntityDetails } from 'features/App/App.slice';
import React, { MouseEvent, useMemo } from 'react';
import { useDispatch } from 'react-redux';

interface Props {
  metrics: Metric[] | undefined;
  scope: Scope | undefined;
  labels: Record<string, string>;
}

export function useTooltipMetricActionsMap({ metrics, labels, scope }: Props) {
  const dispatch = useDispatch();

  return useMemo(() => {
    const result: Record<string, { action: (e: MouseEvent, tooltipTime: number) => void; label: string }> = {};

    metrics?.forEach((m) => {
      let entityName: string | undefined;
      let entityType: string = labels.asserts_source_entity_type || labels.asserts_entity_type;

      if(['Pod', 'ServiceInstance'].includes(entityType) === false){
        return;
      }

      if (m.metric?.instance) {
        entityName = m.metric?.instance;
      }

      if (m.metric?.pod) {
        entityName = m.metric?.pod;
      }

      if (entityType === 'ServiceInstance' && (labels.service || labels.job) && m.metric?.instance) {
        entityName = `${labels.service || labels.job}:${m.metric.instance}`;
      }

      if (entityName && entityType) {
        result[m.name] = {
          action: (e, tooltipTime) => {
            e.stopPropagation();

            const start = dateTime(tooltipTime).subtract(360, 'minutes').valueOf();
            const end = dateTime(tooltipTime).add(360, 'minutes').valueOf();

            dispatch(
              setActiveEntityDetails({
                name: entityName || '',
                type: entityType || '',
                scope,
                additionalLabels: labels,
                start,
                end,
              })
            );
          },
          label: m.name,
        };
      }
    });

    if (Object.entries(result).length === 1) {
      const [key, value] = Object.entries(result)[0];
      const metric = metrics?.find(m => m.name === key);

      if (metric && key && value) {
        let type = '';

        if (metric.metric?.instance) {
          type = 'instance';
        }
        if (metric.metric?.pod) {
          type = 'pod';
        }
        if (type) {
          value.label = `${key} {${type}="${metric.metric?.[type]}"}`;
        }
      }
    }


    return result;
  }, [dispatch, labels, scope, metrics]);
}
