import { defineMessages } from 'react-intl';

export const scope = 'features.EntityListColumnsSettings';

export default defineMessages({
  properties: {
    id: `${scope}.properties`,
    defaultMessage: 'Properties',
  },
  monitoring: {
    id: `${scope}.monitoring`,
    defaultMessage: 'Monitoring',
  },
  columns: {
    id: `${scope}.columns`,
    defaultMessage: 'Columns',
  },
  all: {
    id: `${scope}.all`,
    defaultMessage: 'All',
  },
  none: {
    id: `${scope}.none`,
    defaultMessage: 'None',
  },
  collapse: {
    id: `${scope}.collapse`,
    defaultMessage: 'collapse all',
  },
  expand: {
    id: `${scope}.expand`,
    defaultMessage: 'expand all',
  },
  searchColumn: {
    id: `${scope}.searchColumn`,
    defaultMessage: 'Search column',
  },
});
