import { useMutation } from '@tanstack/react-query';
import { apiHttpService } from 'app/api-http-service';
import SnackbarHelper from 'helpers/Snackbar.helper';
import { useNavigate } from 'react-router-dom';
import { prefixRoute } from 'utils/utils.routing';
import { ROUTES } from 'global-constants';
import useTraceConfigs from './useTraceConfigs';

const traceConfigsPath = prefixRoute([ROUTES.CONFIGURATION, ROUTES.CONFIGURATION_TRACES].join('/'));

export default function useDeleteTraceConfig() {
  const { refetch: refetchTraceConfigs } = useTraceConfigs();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (name: string) =>
      apiHttpService
        .delete(
          `/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/trace/${encodeURIComponent(name)}`
        )
        .then((res) => res.data),

    onSuccess: () => {
      SnackbarHelper.success('Trace configuration was deleted successfuly.');
      refetchTraceConfigs();
      navigate(traceConfigsPath);
    },
  });
}
