import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Alert, Box, Button, useStyles2 } from '@grafana/ui';
import { KpiDisplayConfig } from 'asserts-types';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { KpiViewConfigFormItem } from './KpiViewConfigFormItem';

interface IProps {
  defaultValues: KpiDisplayConfig;
  onSubmit: (values: KpiDisplayConfig) => void;
}

export type ViewField = {
  name: keyof KpiDisplayConfig;
  label: string;
};

const appViews: ViewField[] = [
  { name: 'k8sAppView', label: 'Kubernetes Monitoring app view' },
  { name: 'appO11yAppView', label: 'Application Observability app view' },
  { name: 'frontendO11yAppView', label: 'Frontend Observability app view' },
  { name: 'awsAppView', label: 'Cloud provider app view' },
];

const dashboards: ViewField[] = [
  { name: 'defaultDashboard', label: 'Default dashboard' },
  { name: 'additionalDashboard', label: 'Additional dashboards' },
  { name: 'frameworkDashboard', label: 'Framework dashboard' },
  { name: 'runtimeDashboard', label: 'Runtime dashboard' },
];

const telemetryViews: ViewField[] = [
  { name: 'logsView', label: 'Logs view' },
  { name: 'tracesView', label: 'Traces view' },
  { name: 'propertiesView', label: 'Properties view' },
];

export function KpiViewConfigForm({ defaultValues, onSubmit }: IProps) {
  const styles = useStyles2(getStyles);
  const formMethods = useForm({
    defaultValues,
  });

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={formMethods.handleSubmit((values) => {
          const hasViewSelected = Object.values(values).some((value) => value);
          if (!hasViewSelected) {
            formMethods.setError('propertiesView', { type: 'manual', message: 'At least one view is required' });
            return;
          }
          onSubmit(values);
        })}
      >
        <Box paddingBottom={4}>
          <h5>App views</h5>
          {appViews.map((field) => (
            <KpiViewConfigFormItem key={field.name} {...field} />
          ))}
        </Box>

        <Box paddingBottom={4}>
          <h5>Dashboards</h5>
          {dashboards.map((field) => (
            <KpiViewConfigFormItem key={field.name} {...field} />
          ))}
        </Box>

        <Box paddingBottom={4}>
          <h5>Telemetry views</h5>
          {telemetryViews.map((field) => (
            <KpiViewConfigFormItem key={field.name} {...field} />
          ))}
        </Box>

        {formMethods.formState.errors.propertiesView && (
          <Alert severity="error" title="Invalid selection">
            {formMethods.formState.errors.propertiesView.message}
          </Alert>
        )}
        <Button type="submit" className={styles.submitButton}>
          Submit
        </Button>
      </form>
    </FormProvider>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    submitButton: css({
      marginTop: theme.spacing(2),
    }),
  };
}
