import { useQuery } from '@tanstack/react-query';
import { apiHttpService } from 'app/api-http-service';
import { GrafanaDashboard } from 'asserts-types';

interface Props {
  query: string;
  type: GrafanaDashboard['type'];
}

export default function useGrafanaSearch({ query, type }: Props) {
  return useQuery(['grafana-dashboards-search', query, type], () =>
    apiHttpService.get<GrafanaDashboard[]>(`/api/search?query=${query}&limit=1000&type=${type}`).then((res) => res.data)
  );
}
