import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  clearEntities,
  deleteTopSearch,
  setSearchDefinition,
} from 'features/Entities/Entities.slice';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteAdvancedSearch } from 'services/Entity.service';
import { Definition } from 'asserts-types';
import { USE_DEFINITION_AUTOCOMPLETE_KEY } from 'hooks/useDefinitionAutocomplete';
import { prefixRoute } from 'utils/utils.routing';
import { ROUTES } from 'global-constants';

interface IProps {
  onSuccess?: () => void;
}

export default function useDeleteSavedSearch(props?: IProps) {
  const { onSuccess } = props || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const { removeRecentSearch } = useRecentSearches();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (definition: Omit<Definition, 'id'>) => {
      return deleteAdvancedSearch(definition.definitionId);
    },
    onSuccess: (_, definition) => {
      onSuccess?.();
      dispatch(deleteTopSearch(definition));
      dispatch(setSearchDefinition(null));
      dispatch(clearEntities());
      navigate(prefixRoute(ROUTES.ENTITIES_GRAPH), { state: { timestamp: Date.now() } });
      queryClient.removeQueries([USE_DEFINITION_AUTOCOMPLETE_KEY]);
      // removeRecentSearch(definition.boundDescription);
    },
  });
}
