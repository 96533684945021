import { useQuery } from "@tanstack/react-query";
import { apiHttpService } from "app/api-http-service";

interface Params {
  startTime: number;
  endTime: number;
  assertionName: string;
  entityName: string;
  entityType: string;
  rootEntityName?: string;
  rootEntityType?: string;
  enabled: boolean;
  labels: Record<string, string>;
}

export default function useAffectedEntityNames({
  startTime,
  endTime,
  assertionName,
  entityName,
  entityType,
  enabled,
  labels,
  rootEntityName,
  rootEntityType
}: Params) {

  const processedLabels = { ...labels };

  if (
    (labels.asserts_pod_dropped === 'true' ||
    labels.asserts_instance_dropped === 'true') &&
    rootEntityName &&
    rootEntityType
  ) {
    processedLabels[rootEntityType] = rootEntityName;
  }

  return useQuery(
    ['affected-entity-names', startTime, endTime, assertionName, entityName, entityType, processedLabels],
    () =>
      apiHttpService
        .post<{ entityNames: string[] }>(
          '/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/assertion/affected-entity-names',
          {
            assertionName,
            entityName,
            entityType,
            startTime,
            endTime,
            labels,
          }
        )
        .then((response) => response.data),
    { enabled }
  );
}
