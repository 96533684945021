import { useNodeGroupByNode } from './useNodeGroupByNode';
import { useKubeClusterByNodeGroup } from './useKubeClusterByNodeGroup';
import { OpenInKubernetesTriggerProps } from '../OpenInKubernetesTrigger';

export function useKubeClusterByNode(entity: OpenInKubernetesTriggerProps['entity'] | undefined, start: string | number, end: string | number, enabled: boolean) {
  const { data: nodeGroup, isFetching: isFetchingNodeGroupData } = useNodeGroupByNode(entity, start, end, enabled);

  const { data: cluster, isFetching: isFetchingClusterData } = useKubeClusterByNodeGroup(nodeGroup, start, end, enabled);

  return { data: cluster, isFetching: isFetchingNodeGroupData || isFetchingClusterData };
}
