/**
 *
 * TopInsightsRootTimeline
 *
 */

import React, { memo, FunctionComponent, useMemo, useState } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import messages from './messages';
import { AssertionScore } from 'asserts-types';
import { ROOT_ENTITY_HEIGHT, assertsColors } from 'app/constants';
import ZoomableTimelineComponent from 'components/ZoomableTimeline/ZoomableTimeline.component';
import { TIMELINE_STEPS_COUNT } from 'features/Assertions/constants';
import TimelineTicksComponent from 'components/TimelineTicks/TimelineTicks.component';
import LineRangeChart from 'components/LineRangeChart/LineRangeChart';
import TopInsightsTimelineClustersComponent from 'components/TopInsightsTimelineClusters/TopInsightsTimelineClusters.component';
import { Tooltip } from 'components/Tooltip/Tooltip.component';

interface IProps {
  start: number;
  end: number;
  rootScores: AssertionScore;
  timeStepIntervalMs: number;
  scaleType?: 'log' | 'linear';
  changeDateRange: (start: number | string, end: number | string) => void;
  disableInteractions?: boolean;
}

const TopInsightsRootTimeline: FunctionComponent<IProps> = ({
  start,
  end,
  rootScores,
  timeStepIntervalMs,
  scaleType,
  changeDateRange,
  disableInteractions,
}) => {
  const [clustersVisible, setClustersVisible] = useState(true);

  const { formatMessage } = useIntl();

  const rootChartScoreData = useMemo(
    () =>
      rootScores.metrics.map((item) => ({
        name: item.metric.asserts_severity,
        values: item.values,
        color: assertsColors[item.metric.asserts_severity],
      })),
    [rootScores]
  );

  return (
    <div className="relative">
      <ZoomableTimelineComponent
        disableHeader
        start={moment(start)}
        end={moment(end)}
        changeDateRange={changeDateRange}
        onSelectStart={() => setClustersVisible(false)}
        onSelectEnd={() => setClustersVisible(true)}
        disableInteractions={disableInteractions}
      >
        <div
          className="bg-paper relative"
          style={{ height: ROOT_ENTITY_HEIGHT }}
          data-intercom-target="root-entity-timeline"
        >
          <TimelineTicksComponent count={TIMELINE_STEPS_COUNT} />

          <LineRangeChart
            data={rootChartScoreData}
            minX={start.valueOf()}
            maxX={end.valueOf()}
            type="area"
            padding={[30, 0, 30, 0]}
            minY={0}
            hideY
            disableGrid
            countTicks={TIMELINE_STEPS_COUNT}
            disableTooltip
            nullAsZero={false}
            timeStepInterval={timeStepIntervalMs}
            fillOpacity={0.6}
            scaleType={scaleType}
          />
          {clustersVisible && (
            <TopInsightsTimelineClustersComponent
              clusters={rootScores.assertionClusters}
              start={start.valueOf()}
              end={end.valueOf()}
              entityName={''}
              entityType={''}
              scope={{}}
            />
          )}
        </div>
      </ZoomableTimelineComponent>
      <div className="absolute -right-4 inset-y-0 w-0 flex items-center justify-center whitespace-nowrap">
        <Tooltip
          content={
            <>
              {formatMessage(messages.assertsScore)}
              <p className="text-secondary mb-0 mt-2 font-normal">{formatMessage(messages.scoreHint)}</p>
            </>
          }
        >
          <div className="text-secondary text-xs rotate-90 cursor-pointer">
            {formatMessage(messages.assertsScoreLabel)}
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default memo(TopInsightsRootTimeline);
