import { apiHttpService } from 'app/api-http-service';
import { RelabelRule, RelabelRuleType } from 'asserts-types';

export const saveRelabelRules = (type: RelabelRuleType, rules: RelabelRule[]): Promise<RelabelRule[]> => {
  const replaceEmptyWithNull = (obj: any) => {
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        const value = obj[key];

        // If the value is an empty string or empty array, replace it with null
        if (!value || (Array.isArray(value) && value.length === 0)) {
          obj[key] = null;
        }
      }
    }
    return obj;
  };
  const processedRules = rules.map((rule) => replaceEmptyWithNull(rule));
  return apiHttpService
    .put(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v2/config/relabel-rules/${type}`, {
      name: type,
      rules: processedRules,
    })
    .then((response) => response.data);
};

export const fetchRules = (type: RelabelRuleType): Promise<RelabelRule[]> => {
  return apiHttpService.get(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v2/config/relabel-rules/${type}`).then((response) => response.data.rules);
};

export const fetchRelabelsRulesFile = (type: RelabelRuleType): Promise<string> =>
  apiHttpService
    .get(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v2/config/relabel-rules/${type}`, {
      headers: {
        'Content-Type': 'text/plain',
        Accept: 'application/x-yaml',
      },
      data: null,
      responseType: 'text',
    })
    .then((response) => response.data);

export const saveRelabelRulesFile = (text: string, type: RelabelRuleType): Promise<void> => {
  return apiHttpService
    .put(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v2/config/relabel-rules/${type}`, text, {
      headers: {
        'Content-Type': 'application/x-yaml',
      },
      responseType: 'text',
    })
    .then((response) => response.data);
};

export default {
  saveRelabelRules,
  fetchRules,
  fetchRelabelsRulesFile,
  saveRelabelRulesFile,
};
