/**
 *
 * PropertiesPopover
 *
 */

import React, { memo, FunctionComponent, MouseEvent, useState, useMemo } from 'react';
import formatValue from 'helpers/ValueFormat.helper';
import { Entity, EntityAssertion, EntityProperties, GraphCustomNode, Scope } from 'asserts-types';
import formatProperties from 'helpers/FormatProperties.helper';
import messages from './messages';
import { connect, ConnectedProps } from 'react-redux';
import { setActiveEntityDetails } from 'features/App/App.slice';
import { whitelistProperties } from './whitelistProperties';
import ErrorRoundedIcon from 'assets/material-icons/error_FILL0_wght400_GRAD0_opsz24.svg';
import KeyboardArrowDownRoundedIcon from 'assets/material-icons/keyboard_arrow_down_FILL0_wght400_GRAD0_opsz24.svg';
import KeyboardArrowUpRoundedIcon from 'assets/material-icons/keyboard_arrow_up_FILL0_wght400_GRAD0_opsz24.svg';
import DashboardOutlinedIcon from 'assets/material-icons/dashboard_FILL0_wght400_GRAD0_opsz24.svg';

import { ENTITY_OUT_OF_DATE_TIME } from 'features/Entities/constants';
import { useIntl } from 'react-intl';
import TagLabel from '../TagLabel/TagLabel';
import GraphHelper, { convertToEntity } from 'helpers/Graph.helper';
import { twMerge } from 'tailwind-merge';

import { assertsColors } from 'app/constants';
import AssertionsListInfoComponent from 'components/AssertionsListInfo/AssertionsListInfo.component';
import AddToWorkbenchButtonComponent from 'features/Assertions/components/AddToWorkbenchButton/AddToWorkbenchButton.component';
import EnvSiteTagComponent from 'components/EnvSiteTag/EnvSiteTag.component';
import GraphNodeRcaButtonsComponent from 'components/GraphinGraph/components/GraphNodeRcaButtons/GraphNodeRcaButtons.component';
import { Button } from '@grafana/ui';
import { Tooltip } from 'components/Tooltip/Tooltip.component';
import { IconButton } from 'components/IconButton/IconButton.component';
import { useAppSelector } from 'app/store';

interface IProps {
  type: string;
  properties?: EntityProperties;
  label?: string;
  scope: Scope | undefined;
  entityAssertion?: EntityAssertion;
  connectedAssertion?: EntityAssertion;
  showAllProperties?: boolean;
  lastUpdateTime?: number;
  connectedEntityTypes?: Record<string, number>;
  disableAssertionsList?: boolean;
  disablePropertiesList?: boolean;
  disableHeader?: boolean;
  activeNode?: GraphCustomNode;
  className?: string;
  connectionsDefaultExpanded?: boolean;
  onConnectedItemClick?: (entity: Entity | undefined) => void;
  onKpiOpen?: () => void;
}

const connector = connect((state: RootState) => ({}), {
  setActiveEntityDetails,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

const PropertiesPopover: FunctionComponent<IProps & PropsFromRedux> = ({
  type,
  properties,
  label,
  scope,
  entityAssertion,
  connectedAssertion,
  showAllProperties,
  lastUpdateTime,
  setActiveEntityDetails,
  disableAssertionsList,
  activeNode,
  connectedEntityTypes,
  disablePropertiesList,
  disableHeader,
  className,
  connectionsDefaultExpanded,
  onConnectedItemClick,
  onKpiOpen,
}) => {
  const intl = useIntl();
  const [showCopied, setShowCopied] = useState<'label' | 'tag' | 'property' | number | null>(null);
  const [connectionsCollapsed, setConnectionsCollapsed] = useState(connectionsDefaultExpanded ? false : true);
  const start = useAppSelector((state) => state.app.start);
  const end = useAppSelector((state) => state.app.end);

  const objectArray = useMemo(() => {
    return formatProperties(properties || {})
      .filter(
        ([key, value]) =>
          value !== undefined &&
          !key.toLowerCase().startsWith('d ') &&
          (whitelistProperties.includes(key.toLowerCase()) || showAllProperties)
      )
      .concat(scope?.namespace ? [['namespace', scope.namespace]] : []);
  }, [properties, showAllProperties, scope]);

  const copyText = (e: MouseEvent<HTMLSpanElement>, text: string, type: 'label' | 'tag' | 'property' | number) => {
    e.stopPropagation();
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text);
      setShowCopied(type);
      setTimeout(() => setShowCopied(null), 1000);
    }
  };

  const outOfDate = useMemo(() => {
    if (properties && properties['Updated'] && lastUpdateTime) {
      return lastUpdateTime - +properties['Updated'] > ENTITY_OUT_OF_DATE_TIME;
    }
    return false;
  }, [properties, lastUpdateTime]);

  const handleOpenConnections = (entityType: string) => {
    if (!activeNode) {
      return;
    }
    const entity = GraphHelper.convertToEntity(activeNode);
    if (entity) {
      entity.activeConnectedEntityType = entityType;
    }

    onConnectedItemClick?.(entity);
  };

  const connectedTypesList = connectedEntityTypes
    ? Object.entries(connectedEntityTypes).filter(([key]) => key !== 'Assertion')
    : [];

  const connectionsCount = connectedTypesList.map(([type, count]) => count).reduce((a = 0, b = 0) => a + b, 0);

  const entity = convertToEntity(activeNode);

  return (
    // <Menu>
    <div
      className={twMerge('w-[300px] flex flex-col max-h-[500px] overflow-overlay', className ? className : '')}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {!disableHeader && (
        <div className="sticky top-0 z-[99] bg-panel pt-2">
          <div className="flex justify-between items-start gap-1">
            <div className="min-w-0 flex items-center gap-1">
              {!!label && (
                <Tooltip content={intl.formatMessage(messages.copied)} placement="top" show={showCopied === 'label'}>
                  <label
                    onClick={(e) => copyText(e, label, 'label')}
                    className="hover:underline cursor-pointer text-primary break-all text-base"
                  >
                    {label}{' '}
                  </label>
                </Tooltip>
              )}
              {outOfDate && (
                <Tooltip content={intl.formatMessage(messages.warningText)} placement="top">
                  <div>
                    <ErrorRoundedIcon className="mb-[-2px] svg-icon text-destructive" />
                  </div>
                </Tooltip>
              )}
            </div>
            <div className="flex items-center">
              {!!label && <AddToWorkbenchButtonComponent entityKeys={[{ name: label, type, scope }]} />}
              {label && (
                <Tooltip content={intl.formatMessage(messages.showKpi)} placement="top">
                  <IconButton
                    variant="primary"
                    onClick={() => {
                      setActiveEntityDetails({
                        type: type,
                        name: label,
                        scope,
                      });
                      onKpiOpen?.();
                    }}
                  >
                    <DashboardOutlinedIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between mt-1.5">
            {scope?.env ? <EnvSiteTagComponent scope={scope} /> : <div></div>}
            <Tooltip content={intl.formatMessage(messages.copied)} placement="top" show={showCopied === 'tag'}>
              <div>
                <TagLabel
                  onClick={(e) => copyText(e, type, 'tag')}
                  entityType={type}
                  properties={properties}
                  scope={scope}
                  className="cursor-pointer py-1"
                />
              </div>
            </Tooltip>
          </div>
          <div className="divider-t my-3"></div>
        </div>
      )}
      {!disablePropertiesList && (
        <div className="mb-2">
          <ul className="leaders">
            {objectArray.map(([key, value], index) => (
              <li key={key}>
                <div>
                  <span>{key}</span>
                  <Tooltip content={intl.formatMessage(messages.copied)} placement="top" show={showCopied === index}>
                    <span onClick={(e) => copyText(e, formatValue(value, key), index)} title={formatValue(value, key)}>
                      {formatValue(value, key)}
                    </span>
                  </Tooltip>
                </div>
              </li>
            ))}
          </ul>
          {label && (
            <div className="flex items-center justify-end mt-2">
              {/* 
              TODO: Commented out this code since from this place asserts doesn't know which logs datasource to send
              {!!properties && (
                <OpenInKubernetesTrigger entity={{ name: label, type, properties, scope }} start={start} end={end}>
                  <LinkButton fill="text" size="sm" className="mr-auto">
                    Open in Kubernetes
                  </LinkButton>
                </OpenInKubernetesTrigger>
              )} */}
              <Button
                fill="text"
                size="sm"
                onClick={() =>
                  setActiveEntityDetails({
                    type: type,
                    name: label,
                    scope,
                    tab: 'info',
                  })
                }
              >
                {intl.formatMessage(messages.showMore)}
              </Button>
            </div>
          )}
        </div>
      )}

      {!!entityAssertion?.assertions?.length && !disableAssertionsList && (
        <div>
          <p className={twMerge(`text-xs text-secondary pt-2 pb-4 ${disableHeader ? 'pt-0' : 'divider-t'}`)}>
            {intl.formatMessage(messages.assertions)}
          </p>
          <div className="pb-4">
            <AssertionsListInfoComponent assertions={entityAssertion.assertions} />
          </div>
        </div>
      )}
      {connectedEntityTypes && !!connectedTypesList.length && !disableAssertionsList && (
        <div>
          {!!entityAssertion?.assertions?.length && <div className="divider-t h-[1px]"></div>}
          <div
            className="flex items-center justify-between cursor-pointer"
            onClick={() => {
              setConnectionsCollapsed(!connectionsCollapsed);
            }}
          >
            <p className="text-xs text-secondary pt-3 pb-2 flex items-start gap-1">
              {intl.formatMessage(messages.connected)} ({connectionsCount}){' '}
              {connectedAssertion?.severity && (
                <span
                  className="block w-[5px] h-[5px] rounded-full"
                  style={{
                    background: assertsColors[connectedAssertion.severity],
                  }}
                ></span>
              )}
            </p>
            <IconButton>
              {connectionsCollapsed ? <KeyboardArrowDownRoundedIcon /> : <KeyboardArrowUpRoundedIcon />}
            </IconButton>
          </div>
          {!connectionsCollapsed && (
            <div className="space-y-3">
              {connectedTypesList.map(([key, value]) => (
                <div className="space-y-2" key={key}>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <div className="bg-primary/10 text-primary font-bold rounded-full px-2 py-0.5 inline-flex text-xs">
                        {value}
                      </div>
                      <div className="uppercase text-primary text-xs">
                        {key}
                        {value === 1 ? '' : 's'}
                      </div>
                    </div>
                    {onConnectedItemClick && (
                      <Button size="sm" fill="outline" onClick={() => handleOpenConnections(key)}>
                        {intl.formatMessage(messages.open)}
                      </Button>
                    )}
                  </div>
                  <div>
                    <AssertionsListInfoComponent
                      assertions={connectedAssertion?.assertions?.filter((a) => a.entityType === key)}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      {Boolean(entityAssertion?.assertions?.length || connectedAssertion?.assertions?.length) && !!entity && (
        <GraphNodeRcaButtonsComponent entityInfo={entity} />
      )}
    </div>
    // </Menu>
  );
};

export default connector(memo(PropertiesPopover));
