import { useDispatch } from 'react-redux';
import { setKpiSettings } from '../features/Entities/Entities.slice';
import { useQuery } from '@tanstack/react-query';
import { fetchKpiConfig } from 'services/Entity.service';

export default function useKpiConfig() {
  const dispatch = useDispatch();
  return useQuery(
    ['kpiConfig'],
    async () => {
      const data = await fetchKpiConfig();
      // this needs to be added to store because a lot of selectors that were written before
      dispatch(setKpiSettings(data));
      return data;
    },
    {},
  );
}
