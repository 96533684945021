/**
 *
 * GroupedAssertionSummary
 *
 */

import React, { memo, FunctionComponent, useMemo } from 'react';
import { CATEGORY_COLOR } from 'app/constants';
import { getIconCodeByType } from 'helpers/Icon.helper';

interface IProps {
  assertionSummaries: {
    summary: string;
    category: string;
  }[];
}

const GroupedAssertionSummary: FunctionComponent<IProps> = ({
  assertionSummaries,
}) => {
  const grouped = useMemo(() => {
    return assertionSummaries.reduce((acc, summary) => {
      if (acc[summary.category]) {
        acc[summary.category].push(summary.summary);
      } else {
        acc[summary.category] = [summary.summary];
      }
      return acc;
    }, {} as Record<string, string[]>);
  }, [assertionSummaries]);
  return (
    <div>
      {Object.keys(grouped).map((category) => {
        const iconCode = getIconCodeByType(category);
        return (
          <div className="flex py-[8px] px-0" key={category}>
            {iconCode && <i className="text-primary text-[20px] icon-">{String.fromCharCode(iconCode)}</i>}
            <div className="py-0 px-[8px] ml-[8px]" style={{ borderLeft: `3px solid ${CATEGORY_COLOR[category]}` }}>
              {grouped[category].map((summary) => (
                <p
                  className="text-sm relative pl-[15px] before:content-['_'] before:w-[4px] before:h-[4px] before:rounded-full before:top-[8px] before:left-0 before:absolute before:bg-gray-400"
                  key={summary}
                >
                  {summary}
                </p>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default memo(GroupedAssertionSummary);
