import { useAppSelector } from 'app/store';
import { isEqual, omit } from 'lodash';

export default function useAdvancedSearchModified() {
  const searchDefinition = useAppSelector(
    (state) => state.entities.searchDefinition,
  );
  const searchObject = useAppSelector(
    (state) => state.entities.searchObject,
  );
  const searchName = useAppSelector(
    (state) => state.entities.searchName,
  );

  const filtersProcessed = searchObject.filterCriteria.map((filter) => {
    return {
      ...filter,
      connectToEntityTypes: filter.connectToEntityTypes || [],
      propertyMatchers: filter.propertyMatchers.map((matcher) =>
        omit(matcher, 'id'),
      ),
    };
  });

  const definitionFilterProcessed = (
    searchDefinition?.filterCriteria || []
  ).map((filter) => {
    return {
      ...filter,
      connectToEntityTypes: filter.connectToEntityTypes || [],
      propertyMatchers: filter.propertyMatchers.map((matcher) =>
        omit(matcher, 'id'),
      ),
    };
  });

  const isModified = Boolean(
    searchDefinition?.filterCriteria?.length &&
      (searchName !== searchDefinition.boundDescription ||
        !isEqual(definitionFilterProcessed, filtersProcessed)),
  );

  return { isModified };
}
