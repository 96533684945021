import React, { useMemo } from 'react';
import { stringToDate } from '../helpers/Date.helper';
import { useAppSelector } from 'app/store';
import useEnvSiteOptions from './useEnvSiteOptions';

const start = stringToDate('now-24h').valueOf();
const end = stringToDate('now').valueOf();

export default function useEnvSiteOptionsForSelect() {
  const envColorsMap = useAppSelector((state) => state.app.envColorsMap);
  const { data: envSiteOptions } = useEnvSiteOptions({ start, end });

  const envOptions = useMemo(
    () =>
      envSiteOptions?.scopeValues.env?.map((option) => ({
        label: (
          <div className="flex items-center mr-2">
            <span
              className="rounded-full w-[7px] h-[7px] block mr-[5px]"
              style={{
                background: envColorsMap?.[option] || '#000000',
              }}
            ></span>
            {option}
          </div>
        ),
        value: option,
      })) || [],
    [envColorsMap, envSiteOptions]
  );

  const siteOptions = envSiteOptions?.scopeValues.site || [];

  return { envOptions, siteOptions };
}
