import React, { ReactElement } from 'react';

interface Props {
  show: boolean;
  children: ReactElement<any, any>;
}

export default function DotBadge({ show, children }: Props) {
  return (
    <span className="relative block">
      {children}
      {show && (
        <div className="w-[8px] h-[8px] absolute right-0 top-0 -translate-y-1/2 translate-x-1/2 bg-red-600 rounded-full"></div>
      )}
    </span>
  );
}
