import { Button, EmptyState } from '@grafana/ui';
import { ROUTES } from 'global-constants';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { isAdmin } from 'utils/permissions';
import { prefixRoute } from 'utils/utils.routing';

const connectDataPath = prefixRoute([ROUTES.CONFIGURATION, ROUTES.CONNECT_DATA].join('/'));

export function AppNotEnabledPlaceholder({ isPending }: { isPending: boolean }) {
  const navigate = useNavigate();
  return (
    <div className="pt-32">
      <EmptyState
        variant={isPending ? 'completed' : 'call-to-action'}
        message={isPending ? "We're getting set up. Check back in a few minutes." : "You haven't enabled Asserts yet."}
        button={
          <Button
            onClick={() => navigate(connectDataPath)}
            size="lg"
            disabled={!isAdmin}
            tooltip={!isAdmin ? 'Only Admin users can enable Asserts' : undefined}
          >
            {isPending ? 'Go to configuration' : 'Get started'}
          </Button>
        }
      />
    </div>
  );
}
