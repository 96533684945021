import { FORMAT_YEARLESS_lll } from './../app/moment-locales';
import moment from 'moment';

export default function formatValue(value: any, key: string): any {
  if (
    typeof value === 'number' &&
    (key.toLowerCase().replace(/\s/g, '') === 'discovered' ||
      key.toLowerCase().replace(/\s/g, '') === 'updated')
  ) {
    return moment(value).format(FORMAT_YEARLESS_lll);
  }
  return typeof value === 'number'
    ? Intl.NumberFormat('en-US').format(value)
    : value;
}

export function formatLongNumber(value: any): any {
  if (typeof value === 'number') {
    if (value >= 1e9 || -1e9 >= value) {
      return (
        Intl.NumberFormat('en-US').format(Math.round(value / 1e6) / 1e3) + 'G'
      );
    } else if (value >= 1e6 || -1e6 >= value) {
      return (
        Intl.NumberFormat('en-US').format(Math.round(value / 1e3) / 1e3) + 'M'
      );
    }
    if (value >= 9999 || -9999 >= value) {
      return Intl.NumberFormat('en-US').format(Math.round(value) / 1e3) + 'K';
    }
  }
  return value;
}

export function roundTo(value: number, decimals: number) {
  const decimalsValue = Math.pow(10, decimals);
  return Math.round(value * decimalsValue) / decimalsValue;
}
