/**
 *
 * AssertionsSummaryRootAreaChart
 *
 */

import React, { memo, FunctionComponent, useMemo } from 'react';
import LineRangeChart from 'components/LineRangeChart/LineRangeChart';
import { Metric } from 'asserts-types';
import { AXIS_PADDING_RIGHT } from 'app/constants';

interface IProps {
  scoreMetrics: Metric[];
  scoresMaxY?: number;
  start: number;
  end: number;
  timeStepInterval: number | undefined;
}

const AssertionsSummaryRootAreaChart: FunctionComponent<IProps> = ({
  scoreMetrics,
  scoresMaxY,
  start,
  end,
  timeStepInterval,
}) => {
  // memoizing data to prevent chart from blinking
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = useMemo(() => scoreMetrics, [start, end]);

  return (
    <LineRangeChart
      data={data}
      minX={start}
      maxX={end}
      hideX
      hideY
      disableGrid
      padding={[0, AXIS_PADDING_RIGHT, 0, 0]}
      type="area"
      minY={0}
      maxY={scoresMaxY}
      nullAsZero={false}
      timeStepInterval={timeStepInterval}
      fillOpacity={0.6}
      disableTooltip
    />
  );
};

export default memo(AssertionsSummaryRootAreaChart);
