import { useQuery } from '@tanstack/react-query';
import { useAppSelector } from 'app/store';
import { stringToDate } from 'helpers/Date.helper';
import { fetchAdvancedSearchForm } from 'services/Entity.service';

export default function useEntityTypes() {
  const start = useAppSelector((state) => state.app.start);
  const end = useAppSelector((state) => state.app.end);

  return useQuery(['entity-types', start, end], () =>
    fetchAdvancedSearchForm(stringToDate(start).valueOf(), stringToDate(end).valueOf())
  );
}
