import React, { FunctionComponent, memo, useEffect, useMemo, useState } from 'react';
import NotificationTopMenu from '../NotificationTopMenu/NotificationTopMenu.component';
import NotificationList from '../NotificationList/NotificationList.component';
import { NotificationConfig, Sort } from 'asserts-types';
import notificationResourceFields from '../../config/notification_resource_fields.json';
import suppressResourceFields from '../../config/suppress_resource_fields.json';
import NotificationResourceForm from './components/NotificationResourceForm/NotificationResourceForm.component';
import { useIntl } from 'react-intl';
import { fetchNotificationResourceList, fetchSuppressResourceList } from 'services/ManageAssertions.service';
import { orderBy } from 'lodash';

interface IProps {
  suppress?: boolean;
}

const NotificationResource: FunctionComponent<IProps> = ({ suppress }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [customList, setCustomList] = useState<NotificationConfig[]>([]);
  const [customSort, setCustomSort] = useState<Sort | null>(null);

  const [fetchingList, setFetchingList] = useState(true);
  const intl = useIntl();

  useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchList = () => {
    setFetchingList(true);
    (suppress ? fetchSuppressResourceList() : fetchNotificationResourceList()).then((res) => {
      setCustomList(res.alertConfigs);
      setFetchingList(false);
    });
  };

  const handleAddCustomItem = (item: NotificationConfig) => {
    setCustomList(customList.concat([item]));
  };

  const handleUpdateCustomItem = (itemToUpdate: NotificationConfig) => {
    setCustomList(customList.map((item) => (item.name === itemToUpdate.name ? itemToUpdate : item)));
  };

  const handleDeleteCustomItem = (itemToDelete: NotificationConfig) => {
    setCustomList(customList.filter((item) => !(item.name === itemToDelete.name)));
  };

  const customListProcessed = useMemo(
    () =>
      orderBy(
        customList.filter(
          (item) =>
            item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.matchLabels?.additional_labels?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.matchLabels?.alertname?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.matchLabels?.asserts_resource_type?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.matchLabels?.asserts_severity?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.matchLabels?.asserts_source?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.matchLabels?.container?.toLowerCase().includes(searchQuery.toLowerCase())
        ),
        customSort?.field,
        customSort?.order
      ),
    [customList, customSort?.field, customSort?.order, searchQuery]
  );

  const fields = suppress ? suppressResourceFields : notificationResourceFields;

  return (
    <div className="h-full">
      <NotificationTopMenu
        searchQuery={searchQuery}
        onSearchChange={setSearchQuery}
        onYmlFileChange={fetchList}
        suppress={suppress}
      />
      <div>
        <NotificationResourceForm onAdd={handleAddCustomItem} suppress={suppress} />
        <NotificationList
          fields={fields}
          fetchingList={fetchingList}
          list={customListProcessed}
          onUpdate={handleUpdateCustomItem}
          onDelete={handleDeleteCustomItem}
          onSort={setCustomSort}
          orderBy={customSort}
          suppress={suppress}
        />
      </div>
    </div>
  );
};

export default memo(NotificationResource);
