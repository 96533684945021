/**
 *
 * ZoomableTimeline
 * (Historically was a zoomable timeline but now it's timeline interactions without ability to zoom since zoom wasn't used by anyone)
 *
 */

import { Moment } from 'moment';
import React, {
  memo,
  ForwardRefRenderFunction,
  forwardRef,
  useRef,
  useMemo,
  ReactElement,
} from 'react';
import useSyncScroll from 'react-use-sync-scroll';
import RangePickerLine from '../RangePickerLine/RangePickerLine.component';
import useForwardedRef from 'hooks/useForwardedRef';
import { getTimeFromCoordinates } from 'helpers/Time.helper';
import useLinesPosition from 'features/Assertions/components/AssertionsSummaryView/hooks/useLinesPosition';
import useSelectedTime from 'features/Assertions/components/AssertionsSummaryView/hooks/useSelectedTime';
import useRangeSelection from 'features/Assertions/components/AssertionsSummaryView/hooks/useRangeSelection';
import { useAppSelector } from 'app/store';
import TableHeaderComponent from 'components/TableHeader/TableHeader.component';
import TimelineSerifsComponent from 'components/TimelineSerifs/TimelineSerifs.component';
import CoordinateLinesComponent from 'features/Assertions/components/CoordinateLines/CoordinateLines.component';
import { ChartTooltipType } from 'asserts-types';

interface IProps {
  start: Moment;
  end: Moment;
  children: ReactElement<any, any>;
  changeDateRange: (newStart: number | string, newEnd: number | string) => void;
  offset?: number[];
  enableCoordinateLine?: boolean;
  onSelectStart?: () => void;
  onSelectEnd?: () => void;
  disableHeader?: boolean;
  disableInteractions?: boolean;
}

const ZoomableTimeline: ForwardRefRenderFunction<HTMLDivElement, IProps> = (
  {
    children,
    start,
    end,
    changeDateRange,
    offset,
    enableCoordinateLine,
    onSelectStart,
    onSelectEnd,
    disableHeader,
    disableInteractions,
  },
  ref
) => {
  const innerRef = useForwardedRef(ref);
  const headerTapeRef = useRef(null);

  const headerBodyRefsRef = useRef([innerRef, headerTapeRef]);

  useSyncScroll(headerBodyRefsRef, { vertical: false, horizontal: true });

  // const changeTapeZoom = useCallback(
  //   (value) => {
  //     setTapeZoom(tapeZoom + value > 1 ? tapeZoom + value : 1);
  //   },
  //   [tapeZoom],
  // );

  // const handleZoom = useCallback(
  //   (e: React.WheelEvent<HTMLDivElement>) => {
  //     if (!e.nativeEvent || !e.ctrlKey) {
  //       return;
  //     }

  //     const tapeEl = innerRef.current;

  //     const mouseX =
  //       e.clientX - tapeEl.getBoundingClientRect().x + tapeEl.scrollLeft;

  //     let nextZoomValue;

  //     if (e.deltaY >= 0) {
  //       nextZoomValue = -ZOOM_STEP;
  //     } else {
  //       nextZoomValue = ZOOM_STEP;
  //     }

  //     changeTapeZoom(nextZoomValue);

  //     const nextMouseX = (mouseX * (tapeZoom + nextZoomValue)) / tapeZoom;

  //     tapeEl.scrollLeft += nextMouseX - mouseX;

  //     setTimeout(() => {
  //       setMouseX(99999);
  //     });
  //   },
  //   [tapeZoom, changeTapeZoom, innerRef],
  // );

  // const tapeWidth = useMemo(() => 100 * tapeZoom, [tapeZoom]);

  const { mouseX: coordinateLineMouseX, hovered } = useLinesPosition({
    containerRef: innerRef,
  });

  const currentDate = useMemo(
    () =>
      getTimeFromCoordinates({
        mouseX: coordinateLineMouseX,
        tapeEl: innerRef.current,
        start: start.valueOf(),
        end: end.valueOf(),
        rightOffset: offset?.[1] || 0,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [coordinateLineMouseX, start, end]
  );

  const { selectCurrentTime, selectedTime, selectedMouseX, resetSelectedTime } = useSelectedTime({
    currentDate,
    mouseX: coordinateLineMouseX,
  });

  const { selectingRange, selectedStartX, selectedEndX } = useRangeSelection({
    start: start.valueOf(),
    end: end.valueOf(),
    containerRef: innerRef,
    onChange: disableInteractions ? () => {} : changeDateRange,
    onSelectStart,
    onSelectEnd,
    rightOffset: offset?.[1],
  });

  const chartTooltip = useAppSelector((state) => state.assertions.chartTooltip);

  return (
    <div className="grow h-full">
      <div className="overflow-overlay bg-gray-200" ref={headerTapeRef}>
        {!disableHeader && (
          <TableHeaderComponent>
            <div
              className="absolute flex items-center"
              style={{
                top: offset?.[0] || 0,
                right: offset?.[1] || 0,
                bottom: offset?.[2] || 0,
                left: offset?.[3] || 0,
              }}
            >
              <TimelineSerifsComponent start={start.valueOf()} end={end.valueOf()} />
            </div>
          </TableHeaderComponent>
        )}
      </div>
      <div
        className={`overflow-overlay-y overflow-x-hidden relative ${disableHeader ? 'h-full' : 'h-[calc(100%-35px)]'}`}
        ref={innerRef}
        onClick={enableCoordinateLine ? selectCurrentTime : undefined}
      >
        {!disableInteractions && enableCoordinateLine && !selectingRange && (
          <CoordinateLinesComponent
            top={innerRef.current?.getBoundingClientRect().top || 0}
            left={coordinateLineMouseX}
            show={hovered && [ChartTooltipType.SHARED_LINE, ChartTooltipType.SHARED_TOOLTIP].includes(chartTooltip)}
            selectedMouseX={selectedMouseX}
            resetSelectedTime={resetSelectedTime}
            currentDate={selectedTime || currentDate}
          />
        )}
        {!disableInteractions && selectingRange && innerRef.current && (
          <RangePickerLine
            start={start.valueOf()}
            end={end.valueOf()}
            mouseX={selectedEndX}
            tapeEl={innerRef.current}
            selectedStartX={selectedStartX}
            rightOffset={offset?.[1] || 0}
          />
        )}
        <div>{children}</div>
      </div>
    </div>
  );
};

const forwardedZoomableTimeline = forwardRef(ZoomableTimeline);

export default memo(forwardedZoomableTimeline);
