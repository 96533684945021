import SnackbarHelper from 'helpers/Snackbar.helper';
import errorMessages from 'app/errorMessages';
import { useAppSelector } from 'app/store';
import useDefinitionAutocomplete from 'hooks/useDefinitionAutocomplete';

export default function useWorkbenchDefinition() {
  const search = useAppSelector((state) => state.assertions.search);

  const {
    data: definitions,
    isFetching: isFetchingDefinition,
    isStale: isDefinitionStale,
  } = useDefinitionAutocomplete({
    query: search || '',
    max: 1,
    enabled: Boolean(search),
    onSuccess: (data) => {
      if (!data.length) {
        SnackbarHelper.error(errorMessages.noDefinitionFound);
      }
    },
  });

  const definition = Boolean(search) && !isDefinitionStale ? definitions?.[0] : undefined;

  return {
    definition,
    isFetching: isFetchingDefinition,
  };
}
