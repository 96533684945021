import { apiHttpService } from 'app/api-http-service';
import { CustomDashboardResponse, DashboardConfig, GrafanaDashboardConfig } from 'asserts-types';

export const fetchCustomDashboards = () =>
  apiHttpService
    .get<CustomDashboardResponse>(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/dashboard`)
    .then((response) => response.data);

export const fetchGrafanaDashboard = (): Promise<GrafanaDashboardConfig[]> =>
  apiHttpService
    .get(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/grafana/api/search`)
    .then((response) => response.data);

export const saveCustomDashboard = (entityType: string, dash: DashboardConfig): Promise<DashboardConfig> => {
  return apiHttpService
    .post(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/dashboard/${entityType}`, dash)
    .then((response) => response.data);
};

export const deleteCustomDashboard = (entityType: string, dash: DashboardConfig): Promise<void> =>
  apiHttpService
    .post(
      `/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/dashboard/delete/${entityType}`,
      dash
    )
    .then((response) => response.data);

export default {
  fetchCustomDashboards,
  saveCustomDashboard,
  deleteCustomDashboard,
  fetchGrafanaDashboard,
};
