/**
 *
 * RelabelConfigListItem
 *
 */

import React, { memo, FunctionComponent, useState } from 'react';
import EditIcon from 'assets/material-icons/edit_FILL0_wght400_GRAD0_opsz24.svg';
import DeleteOutlineIcon from 'assets/material-icons/delete_FILL0_wght400_GRAD0_opsz24.svg';
import { RelabelRule } from 'asserts-types';
import RelabelConfigFormComponent from '../RelabelConfigForm/RelabelConfigForm.component';
import useSaveRelabelRules from '../../hooks/useSaveRelabelRules';
import TooltipConfirmationComponent from 'features/Assertions/components/TooltipConfirmation/TooltipConfirmation.component';
import { IconButton } from 'components/IconButton/IconButton.component';
import { useParams } from 'react-router-dom';

interface IProps {
  item: RelabelRule;
  fields: (keyof RelabelRule)[];
}

const RelabelConfigListItem: FunctionComponent<IProps> = ({ item, fields }) => {
  const [editing, setEditing] = useState(false);
  const { type } = useParams();

  const { onDelete, isLoading: isSaving } = useSaveRelabelRules();

  if (editing && type !== 'generated') {
    return <RelabelConfigFormComponent tableRow onClose={() => setEditing(false)} preloadedValue={item} />;
  }

  return (
    <tr className="hover:bg-hover transition-all">
      {fields.map((field) => (
        <td className="p-4 divider-b break-words" key={field}>
          {field === 'join_labels' || field === 'ranked_choice'
            ? item[field]?.join(' ') || ''
            : item[field]?.toString()}
        </td>
      ))}
      <td className="p-4 divider-b">
        <div className="flex">
          {!editing && type !== 'generated' && (
            <>
              <IconButton onClick={() => setEditing(true)} variant="primary">
                <EditIcon />
              </IconButton>
              <TooltipConfirmationComponent action={() => onDelete(item)}>
                <IconButton variant="destructive" disabled={isSaving}>
                  <DeleteOutlineIcon />
                </IconButton>
              </TooltipConfirmationComponent>
            </>
          )}
        </div>
      </td>
    </tr>
  );
};

export default memo(RelabelConfigListItem);
