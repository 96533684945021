import { defineMessages } from 'react-intl';

export const scope = 'features.EntityDetailsMenu';

export default defineMessages({
  kpi: {
    id: `${scope}.kpi`,
    defaultMessage: 'KPI',
  },
  customKpi: {
    id: `${scope}.customKpi`,
    defaultMessage: 'Custom KPI',
  },
  properties: {
    id: `${scope}.properties`,
    defaultMessage: 'Properties',
  },
  copy: {
    id: `${scope}.copy`,
    defaultMessage: 'Copy active dashboard URL',
  },
  addToWorkbench: {
    id: `${scope}.addToWorkbench`,
    defaultMessage: 'Add to Workbench',
  },
  openInWorkbench: {
    id: `${scope}.openInWorkbench`,
    defaultMessage: 'Open in Workbench',
  },
  copyHint: {
    id: `${scope}.copyHint`,
    defaultMessage: 'Click to copy text',
  },
  textCopied: {
    id: `${scope}.textCopied`,
    defaultMessage: 'Text copied',
  },
  entity: {
    id: `${scope}.entity`,
    defaultMessage: 'Connected Entities',
  },
  serviceKpi: {
    id: `${scope}.serviceKpi`,
    defaultMessage: 'Service KPI',
  },
});
