import { useCallback, useEffect, useState, RefObject, useMemo } from 'react';

interface IProps {
  containerRef: RefObject<HTMLDivElement>;
  offsetLeft?: number;
}
export default function useLinesPosition({
  containerRef,
  //TODO: add other offsets (if needed)
  offsetLeft = 0,
}: IProps) {
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const [hovered, setHovered] = useState(false);

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!containerRef.current) {
        return;
      }
      const rect = containerRef.current.getBoundingClientRect();

      const left = e.clientX;
      const top = e.clientY;

      setHovered(
        e.clientX > rect.left + offsetLeft &&
          e.clientX < rect.right &&
          e.clientY > rect.top &&
          e.clientY < rect.bottom,
      );

      if (left < offsetLeft + rect.left) {
        setMouseX(offsetLeft + rect.left);
      } else {
        setMouseX(left);
      }

      setMouseY(top);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [containerRef, offsetLeft],
  );

  useEffect(() => {
    const container = containerRef.current;
    container?.addEventListener('mousemove', handleMouseMove);
    return () => {
      container?.removeEventListener('mousemove', handleMouseMove);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleMouseMove]);

  const handleMouseLeave = useCallback((e: MouseEvent) => {
    setHovered(false);
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    container?.addEventListener('mouseleave', handleMouseLeave);
    return () => {
      container?.removeEventListener('mouseleave', handleMouseLeave);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(
    () => ({
      mouseX,
      mouseY,
      hovered,
    }),
    [mouseX, mouseY, hovered],
  );
}
