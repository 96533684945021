import { defineMessages } from 'react-intl';

export const scope = 'components.NotificationList';

export default defineMessages({
  additional_labels: {
    id: `${scope}.additional_labels`,
    defaultMessage: 'Other Labels',
  },
  alertname: {
    id: `${scope}.alertname`,
    defaultMessage: 'Assertion',
  },
  job: {
    id: `${scope}.job`,
    defaultMessage: 'Job',
  },
  asserts_request_type: {
    id: `${scope}.requestType`,
    defaultMessage: 'Request Type',
  },
  asserts_request_context: {
    id: `${scope}.requestContext`,
    defaultMessage: 'Request Context',
  },
  asserts_error_type: {
    id: `${scope}.asserts_error_type`,
    defaultMessage: 'Error Type',
  },
  asserts_receiver: {
    id: `${scope}.asserts_receiver`,
    defaultMessage: 'Receiver',
  },
  value: {
    id: `${scope}.value`,
    defaultMessage: 'Value #A',
  },
  for: {
    id: `${scope}.for`,
    defaultMessage: 'For',
  },
  emptyList: {
    id: `${scope}.emptyList`,
    defaultMessage: 'No items here.',
  },
  asserts_source: {
    id: `${scope}.asserts_source`,
    defaultMessage: 'Source',
  },
  asserts_resource_type: {
    id: `${scope}.asserts_resource_type`,
    defaultMessage: 'Resource Type',
  },
  asserts_severity: {
    id: `${scope}.asserts_severity`,
    defaultMessage: 'Severity',
  },
  container: {
    id: `${scope}.container`,
    defaultMessage: 'Container',
  },
  topic: {
    id: `${scope}.topic`,
    defaultMessage: 'Topic',
  },
  ruleName: {
    id: `${scope}.ruleName`,
    defaultMessage: 'Rule Name',
  },
  alertgroup: {
    id: `${scope}.alertgroup`,
    defaultMessage: 'Group',
  },
  asserts_env: {
    id: `${scope}.asserts_env`,
    defaultMessage: 'Env',
  },
  enabled: {
    id: `${scope}.enabled`,
    defaultMessage: 'Enabled',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Custom items',
  },
});
