/**
 *
 * EntityFilterSettings
 *
 */

import React, { memo, FunctionComponent, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';

import {
  setNameSearchQuery,
  setTypeFilter,
  toggleTypeFilter,
} from '../../Entities.slice';
import { connect, ConnectedProps } from 'react-redux';

import { GraphCustomData } from 'asserts-types';
import { Checkbox, Input } from '@grafana/ui';
import FormControlLabel from 'components/FormControlLabel/FormControlLabel.component';

const connector = connect(
  (state: RootState) => ({
    typeFilter: state.entities.typeFilter,
    nameSearchQuery: state.entities.nameSearchQuery,
  }),
  {
    setTypeFilter,
    toggleTypeFilter,
    setNameSearchQuery,
  },
);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IProps {
  graphData: GraphCustomData;
}

const EntityFilterSettings: FunctionComponent<IProps & PropsFromRedux> = ({
  setTypeFilter,
  typeFilter,
  toggleTypeFilter,
  nameSearchQuery,
  setNameSearchQuery,
  graphData,
}) => {
  const intl = useIntl();

  const typeFilterOptions = useMemo(() => {
    let optionsMap: Record<string, number> = {};

    graphData.nodes.forEach((item) => {
      if (!optionsMap[item.entityType]) {
        optionsMap[item.entityType] = graphData.nodes.filter(
          (entity) => entity.entityType === item.entityType,
        ).length;
      }
    });
    return optionsMap;
  }, [graphData.nodes]);

  const handleAllClick = useCallback(() => {
    if (
      typeFilter.length &&
      typeFilter.length === Object.keys(typeFilterOptions).length
    ) {
      setTypeFilter([]);
    } else {
      setTypeFilter(Object.keys(typeFilterOptions));
    }
  }, [setTypeFilter, typeFilterOptions, typeFilter.length]);

  return (
    <div>
      <Input
        onChange={(e) => setNameSearchQuery(e.currentTarget.value)}
        value={nameSearchQuery}
        className="w-full"
        placeholder="Filter by Name"
        type="search"
        data-cy="entities-filter-input"
        autoFocus
      />
      <div className="mt-4 flex flex-col gap-1">
        <FormControlLabel
          control={
            <Checkbox
              onClick={handleAllClick}
              indeterminate={!!typeFilter.length && typeFilter.length !== Object.keys(typeFilterOptions).length}
              name="all"
              color="primary"
              checked={typeFilter.length !== Object.keys(typeFilterOptions).length}
            />
          }
          label={intl.formatMessage(messages.all)}
        />
        {Object.keys(typeFilterOptions).map((optionName) => (
          <FormControlLabel
            key={optionName}
            control={
              <Checkbox
                onClick={() => toggleTypeFilter(optionName)}
                checked={!typeFilter.includes(optionName)}
                name={optionName}
                color="primary"
              />
            }
            classes={{ label: 'grow' }}
            label={
              <div className="flex items-center justify-between">
                <p>{optionName}</p>
                <p className="text-secondary">{typeFilterOptions[optionName]}</p>
              </div>
            }
          />
        ))}
      </div>
    </div>
  );
};

export default connector(memo(EntityFilterSettings));
