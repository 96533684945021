import { useMutation } from '@tanstack/react-query';
import { apiHttpService } from 'app/api-http-service';
import useBackendStatus from 'hooks/useBackendStatus';
import pluginJson from 'plugin.json';

export const useDeactivateAsserts = () => {
  const { refetch: refetchStatus } = useBackendStatus();

  return useMutation({
    mutationFn: async () => {
      await Promise.allSettled([
        apiHttpService.post(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/stack/disable`, {}),
        apiHttpService.delete(`/api/folders/${pluginJson.id}`),
        ,
      ]);
      await refetchStatus();
    },
  });
};
