import { useQuery } from '@tanstack/react-query';
import { installDashboards, setupAsserts } from 'services/App.service';
import useBackendStatus from './useBackendStatus';

export default function useAppPluginInit() {
  useQuery(['setup-asserts'], () => setupAsserts(), { staleTime: Infinity });
  const { data: assertsBackendStatus } = useBackendStatus();

  useQuery(['install-dashboards'], () => installDashboards(), {
    staleTime: Infinity,
    enabled: !!assertsBackendStatus?.enabled,
  });
}
