import React, { FunctionComponent, memo, useState } from 'react';
import { Button, LinkButton } from '@grafana/ui';
import { EmbeddedDashboard, config, useReturnToPrevious } from '@grafana/runtime';
import { stringToDate } from 'helpers/Date.helper';
import { Entity, EntityDash } from 'asserts-types';
import { OpenInKubernetesTrigger } from 'components/OpenInKubernetesTrigger/OpenInKubernetesTrigger';
import { OpenInAppO11yButton } from 'components/OpenInAppO11yButton/OpenInAppO11yButton';
import { useMetricsDataSource } from 'hooks/useMetricsDatasource';

interface IProps {
  dashboard: EntityDash;
  drawerStart: string | number;
  drawerEnd: string | number;
  logsDatasourceUID?: string;
  onDashboardStateChange: (state: string) => void;
  entity: Entity | undefined;
}

const EntityDetailsDashboard: FunctionComponent<IProps> = ({
  dashboard,
  drawerStart,
  drawerEnd,
  logsDatasourceUID,
  onDashboardStateChange,
  entity,
}) => {
  const setReturnToPrevious = useReturnToPrevious();

  const [copied, setCopied] = useState(false);
  const handleCopyLink = (url: string) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(url);
      setCopied(true);
      setTimeout(() => setCopied(false), 1000);
    }
  };

  const dashboardUrl = new URL(window.location.origin + dashboard.dashUri);
  dashboardUrl.searchParams.set('from', drawerStart.toString());
  dashboardUrl.searchParams.set('to', drawerEnd.toString());

  const { data: metricsDatasouce } = useMetricsDataSource();

  dashboardUrl.searchParams.set('var-ds', metricsDatasouce.uid);

  const uid = dashboardUrl.pathname.split('/')[2] || null;

  const fixedTimeUrl = new URL(dashboardUrl.toString());
  fixedTimeUrl.searchParams.set('from', stringToDate(drawerStart).valueOf().toString());
  fixedTimeUrl.searchParams.set('to', stringToDate(drawerEnd).valueOf().toString());

  return (
    <div key={dashboard.dashUri} className="flex flex-col h-full">
      <div className="flex items-center gap-4">
        <LinkButton href={dashboardUrl.toString()} icon="apps" variant="secondary">
          Open in Dashboards
        </LinkButton>
        {entity && logsDatasourceUID && (
          <>
            <OpenInKubernetesTrigger
              start={drawerStart}
              end={drawerEnd}
              entity={entity}
              metricsDataSourceUID={metricsDatasouce.uid}
              logsDataSourceUID={logsDatasourceUID}
            >
              <LinkButton variant="secondary" onClick={() => setReturnToPrevious('Asserts')}>
                Open in Kubernetes
              </LinkButton>
            </OpenInKubernetesTrigger>
            <OpenInAppO11yButton
              entity={{ name: entity.name, type: entity.type, properties: entity.properties, scope: entity.scope }}
              from={drawerStart.toString()}
              to={drawerEnd.toString()}
            />
          </>
        )}
        <Button
          icon={copied ? 'check' : 'link'}
          variant="secondary"
          onClick={() => handleCopyLink(fixedTimeUrl.toString())}
        >
          {copied ? 'Copied!' : 'Copy link'}
        </Button>
      </div>
      {uid && <EmbeddedDashboard uid={uid} initialState={dashboardUrl.search} onStateChange={onDashboardStateChange} />}
    </div>
  );
};

export default memo(EntityDetailsDashboard);
