/**
 *
 * CountGroupedEntities
 *
 */

import React, { memo, FunctionComponent, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import { twMerge } from 'tailwind-merge';
import tailwindColors from 'tailwindcss/colors';
import KeyboardArrowDownRoundedIcon from 'assets/material-icons/keyboard_arrow_down_FILL0_wght400_GRAD0_opsz24.svg';
import KeyboardArrowUpRoundedIcon from 'assets/material-icons/keyboard_arrow_up_FILL0_wght400_GRAD0_opsz24.svg';
import { GraphCustomData, GroupedCountEntity, TypeScopeFilter } from 'asserts-types';
import { assertsColors } from 'app/constants';
import CountGroupedEntitiesItemComponent from 'components/CountGroupedEntitiesItem/CountGroupedEntitiesItem.component';
import { IconButton } from 'components/IconButton/IconButton.component';
import { Popover, PopoverContent, PopoverTrigger } from 'components/Popover/Popover.component';
import { Menu } from '@grafana/ui';

interface IProps {
  graphData: GraphCustomData;
  activeFilter: TypeScopeFilter[] | null;
  onClick?: (filter: GroupedCountEntity) => void;
  setFilter?: (filter: GroupedCountEntity[]) => void;
  className?: string;
  maxVisibleCount?: number;
}


const CountGroupedEntities: FunctionComponent<IProps> = ({
  graphData,
  onClick,
  activeFilter,
  className,
  setFilter,
  maxVisibleCount,
}) => {
  const groupedEntities = useMemo(() => {
    return graphData.nodes.reduce((grouped, node) => {
      const existingGroup = grouped.find((item) => node.entityType === item.type && isEqual(node.scope, item.scope));
      const currentColor = node.assertion?.severity
        ? assertsColors[node.assertion?.severity]
        : tailwindColors.green[600];

      if (existingGroup) {
        existingGroup.count++;
        if (node.assertion?.severity) {
          existingGroup[node.assertion.severity] = currentColor;
        }
      } else {
        grouped.push({
          type: node.entityType,
          scope: node.scope,
          count: 1,
          color: currentColor,
        });
      }
      return grouped.sort((a, b) => a.type.localeCompare(b.type));
    }, [] as GroupedCountEntity[]);
  }, [graphData]);

  const [expanded, setExpanded] = useState(true);

  const visibleItems = maxVisibleCount ? groupedEntities.slice(0, maxVisibleCount) : groupedEntities;
  const invisibleItems = maxVisibleCount ? groupedEntities.slice(maxVisibleCount) : [];

  return (
    <div
      className={twMerge(
        'absolute p-2 flex flex-wrap z-[2] bg-canvas backdrop-blur-sm bg-white/70 dark:bg-black/50 rounded-br drop-shadow shadow',
        className ? className : '',
        !expanded ? 'h-[40px]' : ''
      )}
    >
      <IconButton
        className="absolute -bottom-4 right-2 bg-paper hover:bg-primary hover:text-white shadow-md"
        onClick={() => setExpanded(!expanded)}
      >
        {expanded ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
      </IconButton>

      {!expanded && (
        <div className="absolute inset-0 flex items-center justify-center">
          <span className="text-sm text-secondary">Graph filter</span>
        </div>
      )}
      {visibleItems.map((groupEntity, index) => {
        let opacity = 0.5;

        if (
          (activeFilter &&
            !activeFilter.find((f) => f.type === groupEntity.type && isEqual(f.scope, groupEntity.scope))) ||
          !activeFilter
        ) {
          opacity = 1;
        }
        return (
          <CountGroupedEntitiesItemComponent
            key={index}
            onClick={onClick}
            opacity={opacity}
            groupedEntities={groupedEntities}
            item={groupEntity}
            expanded={expanded}
            activeFilter={activeFilter}
            setFilter={setFilter}
          />
        );
      })}
      {invisibleItems.length > 0 && expanded && (
        <Popover placement="right-start">
          <PopoverTrigger asChild>
            <div className="flex items-center cursor-pointer text-secondary h-[53px]">{invisibleItems.length} more</div>
          </PopoverTrigger>
          <PopoverContent>
            <Menu>
              <div className="flex items-start flex-wrap gap-2 max-w-[350px] p-2 max-h-[400px] overflow-overlay">
                {invisibleItems.map((groupEntity, index) => {
                  let opacity = 0.5;

                  if (
                    (activeFilter &&
                      !activeFilter.find((f) => f.type === groupEntity.type && isEqual(f.scope, groupEntity.scope))) ||
                    !activeFilter
                  ) {
                    opacity = 1;
                  }
                  return (
                    <CountGroupedEntitiesItemComponent
                      key={index}
                      onClick={onClick}
                      opacity={opacity}
                      groupedEntities={groupedEntities}
                      item={groupEntity}
                      expanded={expanded}
                      activeFilter={activeFilter}
                      setFilter={setFilter}
                    />
                  );
                })}
              </div>
            </Menu>
          </PopoverContent>
        </Popover>
        // <Tooltip
        //   placement="right-end"
        //   className="max-w-[600px]"
        //   interactive
        //   content={
        //     <div className="flex flex-wrap">
        //       {invisibleItems.map((groupEntity, index) => {
        //         let opacity = 0.5;

        //         if (
        //           (activeFilter &&
        //             !activeFilter.find((f) => f.type === groupEntity.type && isEqual(f.scope, groupEntity.scope))) ||
        //           !activeFilter
        //         ) {
        //           opacity = 1;
        //         }
        //         return (
        //           <CountGroupedEntitiesItemComponent
        //             key={index}
        //             onClick={onClick}
        //             opacity={opacity}
        //             groupedEntities={groupedEntities}
        //             item={groupEntity}
        //             expanded={expanded}
        //             activeFilter={activeFilter}
        //             setFilter={setFilter}
        //           />
        //         );
        //       })}
        //     </div>
        //   }
        // >
        //   <div className="flex items-center cursor-pointer text-secondary h-[53px]">{invisibleItems.length} more</div>
        // </Tooltip>
      )}
    </div>
  );
};

export default memo(CountGroupedEntities);
