/*
 * NotificationTopMenu Messages
 *
 * This contains all the text for the NotificationTopMenu component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.NotificationTopMenu';

export default defineMessages({
  suppress: {
    id: `${scope}.resuppressquest`,
    defaultMessage: 'Suppress',
  },
  request: {
    id: `${scope}.request`,
    defaultMessage: 'Request',
  },
  resource: {
    id: `${scope}.resource`,
    defaultMessage: 'Resource',
  },
  health: {
    id: `${scope}.health`,
    defaultMessage: 'Health',
  },
  fileSaved: {
    id: `${scope}.fileSaved`,
    defaultMessage: 'File was saved successfully',
  },
  fileSaveError: {
    id: `${scope}.fileSaveError`,
    defaultMessage: 'Error while saving a file ',
  },
  opening: {
    id: `${scope}.oepning`,
    defaultMessage: 'Opening...',
  },
  viewFile: {
    id: `${scope}.viewFile`,
    defaultMessage: 'View/Edit YML file',
  },
  notifications: {
    id: `${scope}.notifications`,
    defaultMessage: 'Notifications',
  },
  search: {
    id: `${scope}.search`,
    defaultMessage: 'Search',
  },
  help: {
    id: `${scope}.help`,
    defaultMessage: 'Notifications help',
  },
  helpSuppress: {
    id: `${scope}.helpSuppress`,
    defaultMessage: 'Suppressions help',
  },
  slo: {
    id: `${scope}.slo`,
    defaultMessage: 'SLO',
  },
});
