import React, { FunctionComponent, memo, useMemo, useState } from 'react';
import { Cluster, Scope } from 'asserts-types';
import moment from 'moment';
import messages from './messages';
import { useIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { twMerge } from 'tailwind-merge';
import { useNavigate } from 'react-router-dom';
import { addEntityToWorkbench, clearWorkbench } from 'features/Assertions/Assertions.slice';
import { FORMAT_YEARLESS_lll } from 'app/moment-locales';
import GroupedAssertionSummaryComponent from 'components/GroupedAssertionSummary/GroupedAssertionSummary.component';
import { prefixRoute } from 'utils/utils.routing';
import { ROUTES, TRACKING_FEATURES } from 'global-constants';
import { Button } from '@grafana/ui';
import { setTimeRange } from 'features/App/App.slice';
import { Tooltip } from 'components/Tooltip/Tooltip.component';
import TrackingHelper from 'helpers/Tracking.helper';

interface IProps {
  start: number;
  end: number;
  clusters: Cluster[];
  className?: string;
  entityName: string;
  entityType: string;
  assertionNames?: string[];
  scope: Scope | undefined;
  disableAddButton?: boolean;
}

const connector = connect((state: RootState) => ({}), {
  addEntityToWorkbench,
  setTimeRange,
  clearWorkbench,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

const TopInsightsTimelineClusters: FunctionComponent<
  IProps & PropsFromRedux
> = ({
  start,
  end,
  className,
  clusters,
  entityName,
  assertionNames,
  entityType,
  addEntityToWorkbench,
  setTimeRange,
  scope,
  disableAddButton,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [openedTooltipIndex, setOpenedTooltipIndex] = useState<number | null>(
    null,
  );

  const rowAssertionSummary = useMemo(() => {
    return `${
      assertionNames && assertionNames.length > 1
        ? `${assertionNames[0]}::${
            assertionNames[assertionNames.length - 1]
          } on ${entityType} ${entityName}`
        : assertionNames && assertionNames.length === 1
        ? `${assertionNames[0]} on ${entityType} ${entityName}`
        : ``
    }`;
  }, [entityName, assertionNames, entityType]);

  const openInWb = (item: Cluster) => {
    const start = moment(item.start);
    const end = moment(item.end);
    end.add(15, 'm');
    start.subtract(15, 'm');

    setTimeRange({ start: start.valueOf(), end: end.valueOf() });

    clearWorkbench();
    addEntityToWorkbench({
      name: entityName,
      type: entityType,
      scope,
    });
    TrackingHelper.trackNavigateToWorkbench(TRACKING_FEATURES.TOP_INSIGHTS);
    navigate(prefixRoute(ROUTES.RCA_WORKBENCH), { state: { timestamp: Date.now() } });
  };

  return (
    <div
      className={twMerge(
        'rounded w-full relative opacity-100 h-full overflow-hidden',
        className ? className : '',
      )}
    >
      {clusters.map((item, index) => {
        let left = ((item.start - start) * 100) / (end - start);
        left = left < 0 ? 0 : left;

        const width =
          ((item.end - (left === 0 ? start : item.start)) * 100) /
          (end - start);

        return (
          <Tooltip
            interactive
            enterDelay={100}
            className="max-w-[500px] [&_div[role='tooltip']]:overflow-auto [&_div[role='tooltip']]:max-h-[200px] [&_div[role='tooltip']]:pt-0"
            onOpen={() => {
              setOpenedTooltipIndex(index);
            }}
            onClose={() => {
              setOpenedTooltipIndex(null);
            }}
            content={
              <div className="relative min-h-[30px]">
                <div className="flex justify-between gap-4 items-center pb-1 sticky top-0 w-full z-[999] pt-2.5 bg-panel">
                  <strong>
                    {`${moment(item.start).format(FORMAT_YEARLESS_lll)} – ${moment(item.end).format(
                      FORMAT_YEARLESS_lll
                    )}`}
                  </strong>
                  {entityName && !disableAddButton && (
                    <Button className="px-2" onClick={() => openInWb(item)} variant="secondary" size="sm" fill="solid">
                      {intl.formatMessage(messages.open)}
                    </Button>
                  )}
                </div>
                {!!rowAssertionSummary && (
                  <div className="pt-1 w-full max-w-full break-words">{rowAssertionSummary}</div>
                )}
                <div className="text-sm font-bold">{intl.formatMessage(messages.assertions)}</div>
                <GroupedAssertionSummaryComponent assertionSummaries={item.assertionSummaries} />
              </div>
            }
            key={`${index}-${item.start}`}
            placement="top"
          >
            <div
              style={{
                left: `${left}%`,
                width: `${left + width > 100 ? 100 - left : width}%`,
              }}
              className="top-0 bottom-0 absolute z-[999]"
            >
              <div
                className={`hidden absolute top-0 bottom-0 left-[-8px] right-[-8px] rounded-lg border border-primary bg-primary/30 border-solid  pointer-events-none ${
                  openedTooltipIndex === index ? '!block' : ''
                }`}
              />
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default memo(connector(TopInsightsTimelineClusters));
