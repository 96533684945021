import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { ColorPicker, useStyles2, useTheme2 } from '@grafana/ui';
import React from 'react';
import { ColorSwatch } from './ColorSwatch';

interface ColorPickerButtonProps {
  value: string;
  onChange: (value: string) => void;
}

export function ColorPickerButton({ value, onChange }: ColorPickerButtonProps) {
  const theme = useTheme2();
  const styles = useStyles2(getStyles);
  return (
    <ColorPicker
      color={value}
      onChange={(value) => onChange(theme.visualization.getColorByName(value))}
      enableNamedColors
    >
      {({ ref, showColorPicker, hideColorPicker }) => (
        <div className={styles.swatchButton} onClick={showColorPicker}>
          <ColorSwatch
            ref={ref}
            color={value ? theme.visualization.getColorByName(value) : theme.components.input.borderColor}
            onMouseLeave={hideColorPicker}
          />
        </div>
      )}
    </ColorPicker>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    swatchButton: css({
      cursor: 'pointer',
      color: theme.colors.text.primary,
      background: theme.components.input.background,
      padding: '3px',
      maxWidth: '40px',
      height: theme.v1.spacing.formInputHeight,
      border: `1px solid ${theme.components.input.borderColor}`,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'flex-end',
      '&:hover': {
        border: `1px solid ${theme.components.input.borderHover}`,
      },
    }),
  };
}
