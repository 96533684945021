/*
 * AssertionsSummaryView Messages
 *
 * This contains all the text for the AssertionsSummaryView component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.AssertionsSummaryView';

export default defineMessages({
  hint: {
    id: `${scope}.hint`,
    defaultMessage: 'Click on timeline area to lock time',
  },
});
