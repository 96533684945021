/**
 *
 * TopInsightsBoard
 *
 */

import React, { memo, FunctionComponent, useState } from 'react';
import { useIntl } from 'react-intl';
import { assertsColors } from 'app/constants';
import { kpiRegexp } from 'helpers/Entity.helper';
import { formatLongNumber } from 'helpers/ValueFormat.helper';
import useKpiValues from '../../../Entities/hooks/useKpiValues';
import { TI_ITEM_SHORT_LIST_LENGTH } from '../../constants';
import TopInsightsListItem from '../TopInsightsListItem/TopInsightsListItem.component';
import messages from './messages';
import useTopInsightsSearch from 'hooks/useTopInsightsSearch';
import { Button } from '@grafana/ui';

interface IProps {
  data: ReturnType<typeof useTopInsightsSearch>['data'];
  start: number | string;
  end: number | string;
  lastUpdateTime: number;
}

const TopInsightsBoard: FunctionComponent<IProps> = ({
  data,
  start,
  end,
  lastUpdateTime,
}) => {
  const { formatMessage } = useIntl();
  const [showAll, setShowAll] = useState(false);

  const kpis = [
    'Cpu',
    'Memory',
    'LatencyP99',
    'ErrorRate',
    'RequestRate',
    'LatencyAverage',
  ];

  const rows =
    data?.assertionScoresForTopNEntities.map((e) => ({
      type: e.type,
      name: e.name,
      scope: e.scope,
    })) || [];

  const { data: propByKpis } = useKpiValues({ kpis, rows, start, end });

  return (
    <div className="pb-14 relative divider-t">
      <table className="w-full">
        <thead>
          <td className="headerCell p-4 divider-b">{formatMessage(messages.score)}</td>
          <td className="headerCell p-4 divider-b">{formatMessage(messages.name)}</td>
          {kpis.map((kpi) => (
            <td className="headerCell p-4 divider-b" key={kpi}>
              {kpi}
            </td>
          ))}
        </thead>
        <tbody>
          {data?.assertionScoresForTopNEntities
            .slice(0, showAll ? data?.assertionScoresForTopNEntities.length || 0 : TI_ITEM_SHORT_LIST_LENGTH)
            .map((score) => (
              <tr key={score.itemId} className="hover:bg-hover">
                <td className="w-0 divider-b"></td>
                <td className="p-4 w-[400px] overflow-visible divider-b">
                  <TopInsightsListItem
                    key={score.itemId}
                    itemId={score.itemId}
                    score={score}
                    lastUpdateTime={lastUpdateTime}
                    className="-ml-[85px] h-[40px]"
                  />
                </td>
                {kpis.map((column) => {
                  let kpiValue;
                  let kpiValueColor;
                  if (propByKpis[column]) {
                    const kpisKeys = Object.keys(propByKpis[column]);
                    const reg = kpiRegexp(score.name, score.scope);
                    const kpiKey = kpisKeys.find((key) => reg.test(key)) as string;

                    kpiValue = propByKpis[column][kpiKey]?.kpiValue;
                    const kpiAssertionSummary = propByKpis[column][kpiKey]?.assertionSummary;

                    kpiValueColor = kpiAssertionSummary?.severity
                      ? assertsColors[kpiAssertionSummary?.severity]
                      : undefined;
                  }
                  return (
                    <td key={column} className="p-4 divider-b">
                      <span style={{ color: kpiValueColor, fontWeight: 700 }}>{formatLongNumber(kpiValue) || '-'}</span>
                    </td>
                  );
                })}
              </tr>
            ))}
        </tbody>
      </table>
      {(data?.assertionScoresForTopNEntities.length || 0) > TI_ITEM_SHORT_LIST_LENGTH && (
        <div
          className={` ${
            showAll ? '!h-0' : 'bg-gradient-to-t from-white dark:from-black -mx-8'
          } h-[150px] inset-x-0 bottom-0 absolute`}
        >
          <Button
            className="absolute bottom-3 left-1/2 -translate-x-1/2"
            fill="text"
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? 'Show less' : 'Show more'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default memo(TopInsightsBoard);
