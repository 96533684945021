import moment from 'moment';

import 'moment/locale/en-ca';
import 'moment/locale/en-gb';
import 'moment/locale/en-in';
import 'moment/locale/en-au';
import 'moment/locale/en-ie';

const locale = window.navigator.language.toLowerCase();

const supportedLocales = [
  'en',
  'en-us',
  'en-ca',
  'en-gb',
  'en-in',
  'en-au',
  'en-ie',
];

if (supportedLocales.includes(locale)) {
  moment.locale(locale);
}

// default en to en-gb (by default, moment.js comes with English (United States) locale strings)
// so moment.locale('en') will result to moment.locale('en-us')
if (locale === 'en') {
  moment.locale('en-gb')
}


const formatlll = moment.localeData().longDateFormat('lll');
const formatll = moment.localeData().longDateFormat('ll');
const formatLTS = moment.localeData().longDateFormat('LTS');

export const FORMAT_YEARLESS_lll = formatlll.replace('YYYY', '').replace('  ', ' ');

export const FORMAT_YEARLESS_ll_LTS =
  formatll.replace('YYYY', '').replace('  ', ' ') + formatLTS;
