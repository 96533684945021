/**
 *
 * IncidentTimelineChart
 *
 */

import React, { memo, FunctionComponent, useState } from 'react';
import { assertsColors } from 'app/constants';
import moment from 'moment';
import { Incident } from 'asserts-types';
import messages from './messages';
import { useIntl } from 'react-intl';
import { FORMAT_YEARLESS_lll } from 'app/moment-locales';
import { Button } from '@grafana/ui';
import { Tooltip } from 'components/Tooltip/Tooltip.component';

interface IProps {
  start: number;
  end: number;
  incidents: Incident[];
  className?: string;
  expanded?: boolean;
  openInWorkbench: (startTime: number, endTime: number) => void;
}

const IncidentTimelineChart: FunctionComponent<IProps> = ({
  start,
  end,
  className,
  expanded,
  incidents,
  openInWorkbench
}) => {
  const intl = useIntl();

  const [openedTooltipIndex, setOpenedTooltipIndex] = useState<number | null>(
    null,
  );

  return (
    <div
      className={`relative rounded w-full ${
        expanded ? 'min-h-full opacity-10' : 'min-h-[15px] opacity-100'
      } ${className ? className : ''}`}
    >
      {incidents.map((item, index) => {
        let left = ((item.startTime - start) * 100) / (end - start);
        left = left < 0 ? 0 : left;
        const width =
          ((item.endTime - (left === 0 ? start : item.startTime)) * 100) /
          (end - start);

        return (
          <div
            style={{
              background: assertsColors[item.severity],
              left: `${left}%`,
              width: `${left + width > 100 ? 100 - left : width}%`,
              zIndex: 1,
              height: 'auto',
              top: 0,
              bottom: 0,
            }}
            className="rounded top-0 bottom-0 absolute"
            key={`${index}-${item.startTime}`}
          ></div>
        );
      })}
      {incidents.map((item, index) => {
        let left = ((item.startTime - start) * 100) / (end - start);
        left = left < 0 ? 0 : left;
        const width =
          ((item.endTime - (left === 0 ? start : item.startTime)) * 100) /
          (end - start);

        return (
          <Tooltip
            onOpen={() => {
              setOpenedTooltipIndex(index);
            }}
            onClose={() => {
              setOpenedTooltipIndex(null);
            }}
            interactive
            enterDelay={100}
            content={
              <div className="relative min-h-[30px] flex items-start content-start">
                <div className="w-full">
                  <div className="flex justify-between items-center gap-4">
                    <strong>
                      {`${moment(item.startTime).format(FORMAT_YEARLESS_lll)} – ${moment(item.endTime).format(
                        FORMAT_YEARLESS_lll
                      )}`}
                    </strong>

                    <Button
                      className="p-0 px-2"
                      size="sm"
                      variant="secondary"
                      onMouseDown={(e) => {
                        e.stopPropagation();
                        openInWorkbench(item.startTime, item.endTime);
                      }}
                    >
                      {intl.formatMessage(messages.open)}
                    </Button>
                  </div>
                  <div className="break-words w-full max-w-full whitespace-pre pt-1">{item.summary}</div>
                </div>
              </div>
            }
            key={`${index}-${item.startTime}`}
            placement="top"
          >
            <div
              style={{
                left: `${left}%`,
                width: `${left + width > 100 ? 100 - left : width}%`,
              }}
              className="rounded top-0 bottom-0 absolute h-auto z-[999] group/cluster"
            >
              <div className={`hover-outline pointer-events-none ${openedTooltipIndex === index ? '!block' : ''}`} />
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default memo(IncidentTimelineChart);
