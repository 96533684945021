/**
 *
 * GraphNodeRelations
 *
 */

import React, { memo, FunctionComponent, useEffect } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import RelationIcon from 'icons/RelationIcon';
import AssertsCircle from 'features/Entities/components/AssertsCircle/AssertsCircle.component';
import SwapHorizOutlinedIcon from 'assets/material-icons/swap_horiz_FILL0_wght400_GRAD0_opsz24.svg';
import CloseRoundedIcon from 'assets/material-icons/close_FILL0_wght400_GRAD0_opsz24.svg';
import GraphHelper from 'helpers/Graph.helper';
import useEscapeKey from 'hooks/useEscapeKey';
import { GraphCustomNode } from 'asserts-types';
import { Button, Menu, PanelContainer } from '@grafana/ui';
import { Popover, PopoverContent, PopoverTrigger } from 'components/Popover/Popover.component';
import { IconButton } from 'components/IconButton/IconButton.component';

interface IProps {
  onOpen: () => void;
  onClose: () => void;
  onSave: () => void;
  sourceNode?: GraphCustomNode;
  destinationNode?: GraphCustomNode;
  onSwapNodes?: () => void;
  onChangeRelationType?: (v: string) => void;
  relationType: string;
  isSaving?: boolean;
  isEditRelationsMode: boolean;
}

// const relationTypeOptions = ['CALLS', 'GROUPS', 'HOSTS'];

const GraphNodeRelations: FunctionComponent<IProps> = ({
  onOpen,
  onClose,
  sourceNode,
  destinationNode,
  onSwapNodes,
  // onChangeRelationType,
  relationType,
  onSave,
  isSaving,
  isEditRelationsMode,
}) => {
  const intl = useIntl();

  const sourceEntity = GraphHelper.convertToEntity(sourceNode);
  const destinationEntity = GraphHelper.convertToEntity(destinationNode);

  useEscapeKey(onClose);

  useEffect(() => {
    return () => onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PanelContainer className="absolute left-[28px] bottom-[28px]">
        <Popover placement="top-start" open={isEditRelationsMode}>
          <PopoverTrigger asChild>
            <Button
              className="h-[39px]"
              fill="text"
              onClick={(e) => {
                if (isEditRelationsMode) {
                  onClose();
                } else {
                  onOpen();
                }
              }}
            >
              <RelationIcon /> <span className="ml-1">{intl.formatMessage(messages.relations)}</span>
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <Menu>
              <div className="p-4 relative w-[332px]">
                <strong>Add relation between nodes (Services)</strong>
                <p className="text-secondary block text-xs">{intl.formatMessage(messages.relationModeHint)}</p>

                <div className="flex justify-between items-center mt-4 px-10">
                  <div className="flex flex-col items-center w-[70px]">
                    <AssertsCircle size={40} className="mb-[10px]" entity={sourceEntity} />
                    <div
                      className="whitespace-nowrap text-ellipsis overflow-hidden w-full text-center"
                      title={sourceEntity?.name || intl.formatMessage(messages.from)}
                    >
                      {sourceEntity?.name || intl.formatMessage(messages.from)}
                    </div>
                  </div>
                  <IconButton variant="primary" disabled={!(sourceNode && destinationNode)} onClick={onSwapNodes}>
                    <SwapHorizOutlinedIcon />
                  </IconButton>
                  <div className="flex flex-col items-center w-[70px]">
                    <AssertsCircle size={40} className="mb-[10px]" entity={destinationEntity} />
                    <div
                      className="whitespace-nowrap text-ellipsis overflow-hidden w-full text-center"
                      title={sourceEntity?.name || intl.formatMessage(messages.from)}
                    >
                      {destinationEntity?.name || intl.formatMessage(messages.to)}
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-end mt-4">
                  {/* <SelectInput
            className={classes.selectInput}
            options={relationTypeOptions}
            label={intl.formatMessage(messages.type)}
            onChange={(e) =>
              onChangeRelationType &&
              onChangeRelationType(e.target.value as string)
            }
            value={relationType}
          /> */}
                  <Button color="primary" disabled={!(sourceNode && destinationNode) || isSaving} onClick={onSave}>
                    {intl.formatMessage(isSaving ? messages.saving : messages.save)}
                  </Button>
                </div>
                <IconButton className="absolute top-[7px] right-[7px]" onClick={onClose}>
                  <CloseRoundedIcon />
                </IconButton>
              </div>
            </Menu>
          </PopoverContent>
        </Popover>
      </PanelContainer>
    </>
  );
};

export default memo(GraphNodeRelations);
