/**
 *
 * IncidentsSortBy
 *
 */

import React, { memo, FunctionComponent, useMemo } from 'react';
import { useIntl } from 'react-intl';
import SortIcon from 'assets/material-icons/sort_FILL0_wght400_GRAD0_opsz24.svg';

import messages from './messages';
import { useDispatch } from 'react-redux';
import { setSortBy } from '../../Incidents.slice';
import { useAppSelector } from 'app/store';
import { Dropdown, Icon, Menu } from '@grafana/ui';

interface IProps {}

const IncidentsSortBy: FunctionComponent<IProps> = () => {
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const sortBy = useAppSelector((state) => state.incidents.sortBy);


  const menuItems = useMemo(
    () => [
      {
        label: formatMessage(messages.time),
        orderBy: 'endTime',
        action: () => {
          dispatch(setSortBy('endTime'));
        },
      },
      {
        label: formatMessage(messages.type),
        orderBy: 'type',
        action: () => {
          dispatch(setSortBy('type'));
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <Dropdown
        overlay={
          <Menu>
            {menuItems.map((item) => (
              <Menu.Item label={item.label} active={sortBy === item.orderBy} key={item.label} onClick={item.action} />
            ))}
          </Menu>
        }
      >
        <div className="text-xs flex items-center cursor-pointer space-x-2 text-secondary hover:underline">
          <Icon name={'sort-amount-down'} />
          <div>
            <span>{formatMessage(messages.sortBy)} </span>
            <span className="capitalize">{menuItems.find((o) => o.orderBy === sortBy)?.label}</span>
          </div>
        </div>
      </Dropdown>
    </>
  );
};

export default memo(IncidentsSortBy);
