/**
 *
 * RelabelConfigForm
 *
 */

import React, { memo, FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import { Controller } from 'react-hook-form';
import useSaveRelabelRules from '../../hooks/useSaveRelabelRules';
import { RelabelRule } from 'asserts-types';
import CheckIcon from 'assets/material-icons/done_FILL0_wght400_GRAD0_opsz24.svg';
import CloseIcon from 'assets/material-icons/close_FILL0_wght400_GRAD0_opsz24.svg';
import { Button, Field, Grid, Input, MultiSelect, Stack, Switch } from '@grafana/ui';
import { IconButton } from 'components/IconButton/IconButton.component';


interface IProps {
  tableRow?: boolean;
  preloadedValue?: RelabelRule;
  onClose?: () => void;
}

const RelabelConfigForm: FunctionComponent<IProps> = ({ tableRow, preloadedValue, onClose }) => {
  const intl = useIntl();

  const {
    control,
    onSubmit,
    isLoading: isSaving,
  } = useSaveRelabelRules({
    defaultValues: preloadedValue,
    onSuccess: () => onClose && onClose(),
  });

  const renderSelectorControl = () => (
    <Controller
      name="selector"
      control={control}
      render={({ field }) => (
        <Field label="Selector">
          <Input {...field} value={field.value || ''} placeholder="Enter value" disabled={isSaving} />
        </Field>
      )}
    />
  );

  const renderReplacementControl = () => (
    <Controller
      name="replacement"
      control={control}
      render={({ field }) => (
        <Field label={intl.formatMessage(messages.replacement)}>
          <Input {...field} value={field.value || ''} placeholder="Enter value" disabled={isSaving} />
        </Field>
      )}
    />
  );

  const renderJoinLabels = () => (
    <Controller
      name="join_labels"
      control={control}
      render={({ field }) => (
        <Field label="Join labels">
          <MultiSelect
            allowCustomValue
            options={[]}
            {...field}
            value={field.value || []}
            onChange={(v) => v && field.onChange(v.map((i) => i.value))}
            placeholder="Enter values"
            noOptionsMessage="Type label name and hit Enter"
            disabled={isSaving}
          />
        </Field>
      )}
    />
  );

  const renderRankedChoice = () => (
    <Controller
      name="ranked_choice"
      control={control}
      render={({ field }) => (
        <Field label="Ranked choice">
          <MultiSelect
            allowCustomValue
            options={[]}
            {...field}
            value={field.value || []}
            onChange={(v) => v && field.onChange(v.map((i) => i.value))}
            placeholder="Enter values"
            noOptionsMessage="Type label name and hit Enter"
            disabled={isSaving}
          />
        </Field>
      )}
    />
  );

  const renderDropField = () => (
    <Controller
      name="drop"
      control={control}
      render={({ field }) => {
        return (
          <Field label="Drop target label">
            <Switch value={field.value} onChange={(e) => field.onChange(e.currentTarget.checked)} disabled={isSaving} />
          </Field>
        );
      }}
    />
  );

  const renderTargetLabelControl = () => (
    <Controller
      name="target_label"
      control={control}
      render={({ field }) => (
        <Field label={intl.formatMessage(messages.targetLabel)} className="grow">
          <Input {...field} value={field.value || ''} placeholder="Enter value" disabled={isSaving} />
        </Field>
      )}
    />
  );

  if (tableRow) {
    return (
      <tr>
        <td className="p-4">{renderSelectorControl()}</td>
        <td className="p-4">{renderReplacementControl()}</td>
        <td className="p-4">{renderJoinLabels()}</td>
        <td className="p-4">{renderRankedChoice()}</td>
        <td className="p-4">{renderTargetLabelControl()}</td>
        <td className="p-4">{renderDropField()}</td>
        <td className="p-4">
          <div className="flex">
            <IconButton variant="primary" onClick={() => onSubmit()} disabled={isSaving}>
              <CheckIcon />
            </IconButton>
            <IconButton variant="primary" onClick={onClose} disabled={isSaving}>
              <CloseIcon />
            </IconButton>
          </div>
        </td>
      </tr>
    );
  }

  return (
    <form onSubmit={onSubmit}>
      <Grid columns={5} gap={1.5}>
        {renderSelectorControl()}
        {renderReplacementControl()}
        {renderJoinLabels()}
        {renderRankedChoice()}
        <Stack gap={2}>
          {renderTargetLabelControl()}
          {renderDropField()}
        </Stack>
      </Grid>
      <Button type="submit" color="primary" disabled={isSaving}>
        {intl.formatMessage(isSaving ? messages.saving : messages.addNew)}
      </Button>
    </form>
  );
};

export default memo(RelabelConfigForm);
