/*
 * YmlFileEditor Messages
 *
 * This contains all the text for the YmlFileEditor component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.YmlFileEditor';

export default defineMessages({
  close: {
    id: `${scope}.close`,
    defaultMessage: 'Close',
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Edit',
  },
  fileName: {
    id: `${scope}.fileName`,
    defaultMessage: 'File name',
  },
  viewFile: {
    id: `${scope}.viewFile`,
    defaultMessage: 'View/Edit YML file',
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Save',
  },
});
