/**
 *
 * BubbleViewSettings
 *
 */

import React, { memo, FunctionComponent, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import { toggleSelectedKpi, setSelectedKpi, toggleBubbleViewPanel, setBubbleViewPanels } from '../../Entities.slice';
import { connect, ConnectedProps } from 'react-redux';
import { unionBy } from 'lodash';
import ChevronLeftRoundedIcon from 'assets/material-icons/chevron_left_FILL0_wght400_GRAD0_opsz24.svg';
import ChevronRightRoundedIcon from 'assets/material-icons/chevron_right_FILL0_wght400_GRAD0_opsz24.svg';
import { KpiGroup } from 'asserts-types';
import useKpiConfig from 'hooks/useKpiConfig';
import {
  Checkbox,
  Icon,
  Input,
  PanelContainer,
  IconButton as IconButtonGrafana,
  Button,
  LoadingPlaceholder,
  ControlledCollapse,
} from '@grafana/ui';
import { twMerge } from 'tailwind-merge';
import FormControlLabel from 'components/FormControlLabel/FormControlLabel.component';
import { IconButton } from 'components/IconButton/IconButton.component';

const connector = connect(
  (state: RootState) => ({
    selectedKpis: state.entities.selectedKpi,
  }),
  {
    toggleSelectedKpi,
    setSelectedKpi,
    setBubbleViewPanels,
    toggleBubbleViewPanel,
  }
);

interface IProps {
  properties: string[];
  bubbleViewPanels: string[];
  listKpis: KpiGroup['kpis'];
  filteredKpis: string[];
}

type PropsFromRedux = ConnectedProps<typeof connector>;

const BubbleViewSettings: FunctionComponent<IProps & PropsFromRedux> = ({
  bubbleViewPanels,
  listKpis,
  toggleSelectedKpi,
  filteredKpis,
  setSelectedKpi,
  properties,
  toggleBubbleViewPanel,
  selectedKpis,
  setBubbleViewPanels,
}) => {
  const { formatMessage } = useIntl();
  const { data: kpiSettings, isLoading } = useKpiConfig();

  const [collapsedPanel, setCollapsedPanel] = useState(false);
  const [collapsedTabs, setCollapsedTabs] = useState<string[]>([]);
  const [search, setSearch] = useState('');

  const getName = useCallback(
    (optionName: string) => {
      const name = optionName.replace(/_/g, ' ');
      return listKpis.find((k) => k.name === optionName)?.displayName || name;
    },
    [listKpis]
  );

  const handleAccordionChange = (expanded: boolean, tab: string) => {
    setCollapsedTabs(expanded ? collapsedTabs.filter((t) => t !== tab) : collapsedTabs.concat(tab));
  };

  return (
    <PanelContainer
      className={twMerge(
        `h-full border-l-0 border-y-0 pr-0 shrink-0 divider-r w-[268px] sticky left-0 z-[99]  ${
          collapsedPanel ? 'w-[64px]' : ''
        }`
      )}
    >
      <IconButton
        className="absolute top-3 -right-4 bg-paper hover:bg-primary hover:text-white shadow z-[100]"
        onClick={() => setCollapsedPanel(!collapsedPanel)}
      >
        {collapsedPanel ? <ChevronRightRoundedIcon /> : <ChevronLeftRoundedIcon />}
      </IconButton>
      {collapsedPanel && (
        <div className="absolute inset-0 flex items-center justify-center">
          <span className="-rotate-90 font-normal text-sm text-secondary whitespace-nowrap">
            {formatMessage(messages.columns)}
          </span>
        </div>
      )}
      <div className={`overflow-auto h-full ${collapsedPanel ? 'hidden' : 'visible'} pr-4`}>
        <PanelContainer className="sticky top-0 z-[99] pt-6 pb-3 border-0">
          <div className="flex items-baseline justify-between mb-4">
            <div className="font-bold">{formatMessage(messages.columns)}</div>
          </div>
          <Input
            className="w-full mb-4"
            value={search}
            placeholder={formatMessage(messages.searchColumn)}
            onChange={(e) => {
              setSearch(e.currentTarget.value);
              e.currentTarget.value && setCollapsedTabs([]);
            }}
            prefix={<Icon name="search" />}
            suffix={<IconButtonGrafana name="times" aria-label="Clear" onClick={() => setSearch('')} />}
          />
        </PanelContainer>
        {isLoading && <LoadingPlaceholder text="Loading..." className="pt-4 text-center" />}
        {kpiSettings?.kpiGroups
          .filter((g) => g.kpis.some((kp) => filteredKpis.includes(kp.name)))
          .map((group) => (
            <ControlledCollapse
              isOpen={!collapsedTabs.includes(group.name)}
              onToggle={() => handleAccordionChange(!collapsedTabs.includes(group.name), group.name)}
              label={
                <div className="group relative grow">
                  <p className="text-left">{group.name}</p>
                  <div className="hidden items-center group-hover:flex absolute right-2 top-0">
                    <Button
                      size="sm"
                      icon="eye"
                      fill="text"
                      onClick={(e) => {
                        e.stopPropagation();
                        const allGroupItems = filteredKpis.filter((optionName) =>
                          group.kpis.find((item) => item.name === optionName)
                        );

                        setSelectedKpi(unionBy(selectedKpis, allGroupItems));
                      }}
                    >
                      {formatMessage(messages.all)}
                    </Button>
                    <Button
                      size="sm"
                      icon="eye-slash"
                      fill="text"
                      onClick={(e) => {
                        e.stopPropagation();
                        const allGroupItems = filteredKpis.filter((optionName) =>
                          group.kpis.find((item) => item.name === optionName)
                        );

                        setSelectedKpi(selectedKpis.filter((k) => !allGroupItems.includes(k)));
                      }}
                    >
                      {formatMessage(messages.none)}
                    </Button>
                  </div>
                </div>
              }
              key={group.name}
            >
              <div className="flex flex-col">
                {filteredKpis
                  .filter((k) => k.toLowerCase().includes(search.toLowerCase()))
                  .map((optionName) =>
                    group.kpis.find((item) => item.name === optionName) ? (
                      <FormControlLabel
                        className="py-1"
                        key={`entity-type-cbx-${optionName}`}
                        control={
                          <Checkbox
                            onClick={() => {
                              toggleSelectedKpi(optionName);
                            }}
                            checked={bubbleViewPanels.includes(optionName)}
                            name={optionName}
                            color="primary"
                          />
                        }
                        classes={{ label: 'capitalize' }}
                        label={
                          <div>
                            <p className="whitespace-nowrap text-sm">{getName(optionName)}</p>
                          </div>
                        }
                      />
                    ) : null
                  )}
              </div>
            </ControlledCollapse>
          ))}
        <ControlledCollapse
          isOpen={!collapsedTabs.includes('properties')}
          onToggle={() => handleAccordionChange(!collapsedTabs.includes('properties'), 'properties')}
          label={
            <div className="group relative grow">
              <p className="text-left">{formatMessage(messages.properties)}</p>
              <div className="hidden items-center group-hover:flex absolute right-2 top-0">
                <Button
                  size="sm"
                  icon="eye"
                  fill="text"
                  onClick={(e) => {
                    e.stopPropagation();
                    setBubbleViewPanels(properties);
                  }}
                >
                  {formatMessage(messages.all)}
                </Button>
                <Button
                  size="sm"
                  icon="eye-slash"
                  fill="text"
                  onClick={(e) => {
                    e.stopPropagation();
                    setBubbleViewPanels([]);
                  }}
                >
                  {formatMessage(messages.none)}
                </Button>
              </div>
            </div>
          }
        >
          <div className="flex flex-col">
            {properties
              .filter((k) => k.toLowerCase().includes(search.toLowerCase()))
              .map((column) => (
                <FormControlLabel
                  className="py-1"
                  key={`column-cbx-${column}`}
                  control={
                    <Checkbox
                      onClick={() => toggleBubbleViewPanel(column)}
                      checked={bubbleViewPanels.includes(column)}
                      name={column}
                      color="primary"
                    />
                  }
                  classes={{
                    label: 'capitalize',
                  }}
                  label={
                    <div>
                      <p className="whitespace-nowrap text-sm">{column}</p>
                    </div>
                  }
                />
              ))}
          </div>
        </ControlledCollapse>
      </div>
    </PanelContainer>
  );
};

export default connector(memo(BubbleViewSettings));
