/**
 *
 * CoordinateLines
 *
 */

import React, { memo, FunctionComponent, useEffect } from 'react';
import useEscapeKey from 'hooks/useEscapeKey';
import CloseRoundedIcon from 'assets/material-icons/close_FILL0_wght400_GRAD0_opsz24.svg';
import moment from 'moment';
import { useAppSelector } from 'app/store';
import { useTheme2 } from '@grafana/ui';
import { Tooltip } from 'components/Tooltip/Tooltip.component';
import { IconButton } from 'components/IconButton/IconButton.component';



interface IProps {
  left: number;
  top: number;
  show: boolean;
  selectedMouseX: number | undefined;
  resetSelectedTime: () => void;
  currentDate: number | undefined;
}

const CoordinateLines: FunctionComponent<IProps> = ({
  top,
  left,
  show,
  selectedMouseX,
  resetSelectedTime,
  currentDate,
}) => {
  const theme = useTheme2();

  useEscapeKey(resetSelectedTime);

  useEffect(() => {
    return () => {
      resetSelectedTime();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedTime = useAppSelector(state => state.assertions.selectedTime);

  return show || selectedTime ? (
    <>
      <Tooltip
        disableArrow
        content={
          <div className="flex items-center h-full">
            <div className="mr-[5px]">{moment(currentDate)?.format('L LT') || ''}</div>
            {selectedTime && (
              <IconButton
                onMouseDown={(e) => {
                  e.stopPropagation();
                  resetSelectedTime();
                }}
              >
                <CloseRoundedIcon />
              </IconButton>
            )}
          </div>
        }
        show
        className={`[&_div[role='tooltip']]:-mb-[100%] [&_div[role='tooltip']]:min-h-[38px] [&_div[role='tooltip']]:min-w-[107px] [&_div[role='tooltip']]:flex [&_div[role='tooltip']]:items-center ${
          selectedTime ? '' : 'pointer-events-none'
        }`}
      >
        <div
          className="bottom-0 pointer-events-none fixed z-[10] w-[1px] border-0 border-r border-dashed border-r-gray-700"
          style={{
            left: selectedMouseX || left,
            top,
            border: selectedTime ? `1px solid ${theme.colors.primary.main}` : undefined,
          }}
        />
      </Tooltip>
    </>
  ) : null;
};

export default memo(CoordinateLines);
