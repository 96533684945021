import { useMutation } from '@tanstack/react-query';
import { DashboardConfig } from 'asserts-types';
import SnackbarHelper from 'helpers/Snackbar.helper';
import { useForm } from 'react-hook-form';
import { saveCustomDashboard } from 'services/CustomDashboard.service';
import { useCustomDashboards } from './useCustomDashboards';
import TrackingHelper from 'helpers/Tracking.helper';

const defaultValues = {
  entityType: '',
  dashboardUri: '',
  query: '',
  overrideEntityUri: false,
};

export default function useLinkCustomDashboardForm() {
  const { refetch } = useCustomDashboards();

  const form = useForm<DashboardConfig & { entityType: string }>({
    defaultValues,
  });

  const mutation = useMutation({
    mutationFn: (data: DashboardConfig & { entityType: string }) => saveCustomDashboard(data.entityType, data),
    onSuccess: (res, data) => {
      TrackingHelper.trackDashboardLinked(data.entityType);
      SnackbarHelper.success('Dashboard was linked successfuly');
      form.reset();
      refetch();
    },
  });

  const submit = (data: DashboardConfig & { entityType: string }) => {
    mutation.mutateAsync(data);
  };

  return { ...form, ...mutation, onSubmit: form.handleSubmit(submit) };
}
