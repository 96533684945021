import React, { FunctionComponent, memo, useCallback } from 'react';

interface IProps {}

const ellipse = (
  parentNode: HTMLElement,
  childNode: HTMLElement,
  txtNode: HTMLElement,
) => {
  const childWidth = childNode.offsetWidth;
  const containerWidth = parentNode.offsetWidth;
  const txtWidth = txtNode.offsetWidth;
  const targetWidth = childWidth > txtWidth ? childWidth : txtWidth;

  if (targetWidth > containerWidth) {
    const str = txtNode.textContent as string;
    const txtChars = str?.length;
    const avgLetterSize = txtWidth / txtChars;
    const canFit = (containerWidth - (targetWidth - txtWidth)) / avgLetterSize;
    const delEachSide = (txtChars - canFit + 5) / 2;
    const endLeft = Math.floor(txtChars * 0.6 - delEachSide);
    const startRight = Math.ceil(txtChars * 0.6 + delEachSide);

    txtNode.setAttribute('data-original', txtNode?.textContent as string);
    txtNode.textContent =
      str.slice(0, endLeft) + '...' + str.slice(startRight);
  }
};

const EllipsisText: FunctionComponent<IProps> = ({ children }) => {
  const prepEllipse = (node: HTMLDivElement) => {
    const parent = node.parentNode as HTMLElement;
    const child = node.childNodes[0] as HTMLElement;
    const txtToEllipse = child;

    if (child !== null && txtToEllipse !== null) {
      // (Re)-set text back to data-original-text if it exists.
      if (txtToEllipse.hasAttribute('data-original')) {
        txtToEllipse.textContent = txtToEllipse.getAttribute('data-original');
      }

      ellipse(
        // Use the smaller width.
        node.offsetWidth > parent.offsetWidth ? parent : node,
        child,
        txtToEllipse,
      );
    }
  };

  const measuredParent = useCallback((node) => {
    if (node !== null) {
      new ResizeObserver(() => {
        prepEllipse(node);
      }).observe(node.parentElement);
      prepEllipse(node);
    }
  }, []);

  return (
    <div
      ref={measuredParent}
      style={{
        wordBreak: 'keep-all',
        overflowWrap: 'normal',
      }}
    >
      {children}
    </div>
  );
};

export default memo(EllipsisText);
