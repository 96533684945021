/**
 *
 * AssertionsSummaryItemMetricChart
 *
 */

import React, { memo, FunctionComponent, useRef } from 'react';
import { useIntl } from 'react-intl';
import { EntityProperties, Scope } from 'asserts-types';
import LineRangeChart from 'components/LineRangeChart/LineRangeChart';
import { Chart } from '@antv/g2';
import useSyncChartTooltips from '../../hooks/useSyncChartTooltips';
import { AXIS_PADDING_RIGHT } from 'app/constants';
import useAssertionEntityMetrics from 'hooks/useAssertionEntityMetrics';
import { Button, LoadingPlaceholder } from '@grafana/ui';
import { setActiveEntityDetails } from 'features/App/App.slice';
import { useDispatch } from 'react-redux';
import { dateTime } from '@grafana/data';
import { useTooltipMetricActionsMap } from 'features/Assertions/hooks/useTooltipMetricActionsMap';

interface IProps {
  start: number;
  end: number;
  entityName: string;
  entityType: string;
  scope: Scope;
  alertName: string;
  labels: Record<string, string>;
  entityProperties: EntityProperties;
}

const AssertionsSummaryItemMetricChart: FunctionComponent<IProps> = ({
  alertName,
  labels,
  start,
  end,
  entityName,
  entityType,
  scope,
  entityProperties,
}) => {
  const chartRef = useRef<Chart>(null);
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();

  const {
    data: chartData,
    isLoading: isLoadingChart,
  } = useAssertionEntityMetrics({
    enabled: true,
    assertionName: alertName,
    startTime: start,
    endTime: end,
    entityName,
    entityType,
    rootEntityName: entityName,
    rootEntityType: entityType,
    labels: labels,
  });
  const renderTooltipButtons = (currentTime: number) => {
    const startForLogs = dateTime(currentTime).subtract(2, 'minutes').valueOf();
    const endForLogs = dateTime(currentTime).add(2, 'minutes').valueOf();
    const startForTraces = dateTime(currentTime).subtract(5, 'minutes').valueOf();
    const endForTraces = dateTime(currentTime).add(5, 'minutes').valueOf();
    return (
      <div className="flex items-center gap-2 ml-3">
        <Button
          size="sm"
          variant="secondary"
          onClick={(e) => {
            e.stopPropagation();
            dispatch(
              setActiveEntityDetails({
                name: entityName,
                type: entityType,
                scope: scope,
                additionalLabels: labels,
                tab: 'logs',
                start: startForLogs,
                end: endForLogs,
              })
            );
          }}
        >
          Logs
        </Button>
        <Button
          size="sm"
          variant="secondary"
          onClick={(e) => {
            e.stopPropagation();
            dispatch(
              setActiveEntityDetails({
                name: entityName,
                type: entityType,
                scope: scope,
                additionalLabels: labels,
                tab: 'traces',
                start: startForTraces,
                end: endForTraces,
                threshold: chartData?.thresholds[0]?.values || [],
                values: chartData?.metrics[0]?.values || [],
              })
            );
          }}
        >
          Traces
        </Button>
      </div>
    );
  };

  const tooltipMetricActionsMap = useTooltipMetricActionsMap({ metrics: chartData?.metrics, labels, scope });

  useSyncChartTooltips({ chartData, chartRef });

  return (
    <>
      {isLoadingChart && (
        <LoadingPlaceholder text="Loading chart" className="absolute inset-0 flex items-center justify-center gap-1" />
      )}
      {chartData && (
        <LineRangeChart
          ref={chartRef}
          data={chartData.metrics}
          hideX
          padding={[10, AXIS_PADDING_RIGHT, 10, 0]}
          positionY="right"
          minX={chartData.timeWindow.start}
          maxX={chartData.timeWindow.end}
          minY={0}
          timeStepInterval={chartData.timeStepIntervalMs}
          thresholds={chartData.thresholds}
          type="line"
          showLegend
          nullAsZero
          areaUnderLine
          disableTooltipPortal
          renderTooltipButtons={renderTooltipButtons}
          tooltipMetricActionsMap={tooltipMetricActionsMap}
        />
      )}
    </>
  );
};

export default memo(AssertionsSummaryItemMetricChart);
