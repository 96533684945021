import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Box, Stack, useStyles2 } from '@grafana/ui';
import { DisplayConfig, EntityAppearanceOverride } from 'asserts-types';
import CloseIcon from 'assets/material-icons/close_FILL0_wght400_GRAD0_opsz24.svg';
import DeleteOutlineIcon from 'assets/material-icons/delete_FILL0_wght400_GRAD0_opsz24.svg';
import CheckIcon from 'assets/material-icons/done_FILL0_wght400_GRAD0_opsz24.svg';
import EditIcon from 'assets/material-icons/edit_FILL0_wght400_GRAD0_opsz24.svg';
import { IconButton } from 'components/IconButton/IconButton.component';
import TooltipConfirmationComponent from 'features/Assertions/components/TooltipConfirmation/TooltipConfirmation.component';
import { getIconCodeByType } from 'helpers/Icon.helper';
import SnackbarHelper from 'helpers/Snackbar.helper';
import React, { useState } from 'react';
import { deleteGraphDisplayEntityConfig, updateGraphDisplayConfig } from 'services/App.service';
import { ColorPickerButton } from './ColorPickerButton';
import { IconSelectionDropdown } from './IconSelectionDropdown';

interface Props {
  displayConfig: DisplayConfig;
  onUpdate: () => void;
}

export function EntityDisplayConfigOverrideRowItem({
  color,
  icon,
  entityType,
  displayConfig,
  onUpdate,
}: Props & EntityAppearanceOverride) {
  const styles = useStyles2(getStyles(color));
  const [editing, setEditing] = useState(false);
  const [selectedColor, setColorValue] = useState(color);
  const [selectedIcon, setIconValue] = useState(icon);
  const [updating, setUpating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleSave = () => {
    if (!displayConfig?.graph?.entities) {
      SnackbarHelper.error('Display config not loaded');
      return;
    }
    setUpating(true);

    updateGraphDisplayConfig({
      entities: {
        ...displayConfig.graph.overrides.entities,
        [entityType]: {
          color: selectedColor,
          icon: selectedIcon,
          shape: 'circle',
        },
      },
      edges: {},
    }).then(() => {
      SnackbarHelper.success('Display config updated');
      onUpdate();
      setUpating(false);
      setEditing(false);
    });
  };

  const deleteOverride = () => {
    setIsDeleting(true);
    deleteGraphDisplayEntityConfig(entityType).then(() => {
      onUpdate();
      SnackbarHelper.success(`Display config for ${entityType} deleted`);
      setIsDeleting(false);
    });
  };

  return (
    <>
      <tr className={styles.container}>
        <td className={styles.cell}>{entityType}</td>
        <td className={styles.cell}>
          {editing ? (
            <ColorPickerButton value={selectedColor} onChange={(value) => setColorValue(value)} />
          ) : (
            <Box paddingX={1.5} paddingY={1}>
              <div className={styles.colorCircle} />
            </Box>
          )}
        </td>
        <td className={styles.cell}>
          {editing ? (
            <IconSelectionDropdown
              value={selectedIcon}
              onChange={({ value }) => {
                if (value) {
                  setIconValue(value);
                }
              }}
            />
          ) : (
            <Stack alignItems="center">
              <div className="icon-">{String.fromCharCode(getIconCodeByType(icon) ?? 0)}</div>
              {icon}
            </Stack>
          )}
        </td>
        <td>
          {editing ? (
            <Stack gap={1}>
              <>
                <IconButton variant="primary" disabled={updating || isDeleting || !displayConfig} onClick={handleSave}>
                  <CheckIcon />
                </IconButton>
                <IconButton
                  variant="primary"
                  onClick={() => {
                    setEditing(false);
                  }}
                  disabled={updating || isDeleting}
                >
                  <CloseIcon />
                </IconButton>
              </>
            </Stack>
          ) : (
            <Stack direction="row" gap={1}>
              <IconButton
                variant="primary"
                onClick={(e) => {
                  e.preventDefault();
                  setEditing(true);
                }}
                disabled={updating || isDeleting}
              >
                <EditIcon />
              </IconButton>
              <TooltipConfirmationComponent
                action={() => {
                  deleteOverride();
                }}
              >
                <IconButton disabled={updating || isDeleting} variant="destructive">
                  <DeleteOutlineIcon />
                </IconButton>
              </TooltipConfirmationComponent>
            </Stack>
          )}
        </td>
      </tr>
    </>
  );
}

function getStyles(color: string) {
  return function (theme: GrafanaTheme2) {
    return {
      container: css({
        ':hover': {
          backgroundColor: theme.components.table.rowHoverBackground,
        },
        borderBottom: `1px solid ${theme.colors.border.weak}`,
      }),
      cell: css({
        padding: theme.spacing(2),
        width: '400px',
      }),
      colorCircle: css({
        backgroundColor: theme.visualization.getColorByName(color),
        borderRadius: '50%',
        height: 16,
        width: 16,
      }),
      colorEditButton: css({
        background: theme.colors.background.canvas,
        padding: theme.spacing(1),
      }),
      swatchButton: css({
        cursor: 'pointer',
        color: theme.colors.text.primary,
        background: theme.components.input.background,
        padding: '3px',
        maxWidth: '40px',
        height: theme.v1.spacing.formInputHeight,
        border: `1px solid ${theme.components.input.borderColor}`,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'flex-end',
        '&:hover': {
          border: `1px solid ${theme.components.input.borderHover}`,
        },
      }),
    };
  };
}
