/*
 * SavedSearches Messages
 *
 * This contains all the text for the SavedSearches container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'features.SavedSearches';

export default defineMessages({
  search: {
    id: `${scope}.search`,
    defaultMessage: 'Search',
  },
});
