/**
 *
 * AssertionsTopMenu
 *
 */

import React, { memo, FunctionComponent, useEffect } from 'react';
import AssertionsTopMenuButtons from '../AssertionsTopMenuButtons/AssertionsTopMenuButtons.component';
import { setViewType } from '../../Assertions.slice';
import { useIntl } from 'react-intl';
import messages from './messages';
import { AssertionsViewType, HierarchicalAssertion } from 'asserts-types';
import { connect, ConnectedProps } from 'react-redux';
import { Tab, TabsBar } from '@grafana/ui';
import AssertionsSearchQuerySelect from '../AssertionsSearchQuerySelect/AssertionsSearchQuerySelect';
import TrackingHelper from 'helpers/Tracking.helper';

const connector = connect(
  (state: RootState) => ({
    viewType: state.assertions.viewType,
  }),
  {
    setViewType,
  }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IProps {
  assertions: HierarchicalAssertion[] | undefined;
}

const AssertionsTopMenu: FunctionComponent<IProps & PropsFromRedux> = ({
  viewType,
  setViewType,
  assertions,
}) => {
  const intl = useIntl();

  const onTabChange = (value: AssertionsViewType) => {
    setViewType(value);
  };

  useEffect(() => {
    TrackingHelper.trackViewType('workbench_' + viewType.toLowerCase());
  }, [viewType]);

  return (
    <div>
      <div className="flex items-center justify-between pb-3 gap-4">
        <AssertionsSearchQuerySelect />
        <AssertionsTopMenuButtons assertions={assertions} />
      </div>
      <div className="flex justify-between relative">
        <TabsBar className="w-full">
          <Tab
            label={intl.formatMessage(messages.summary)}
            active={viewType === AssertionsViewType.SUMMARY}
            onChangeTab={() => onTabChange(AssertionsViewType.SUMMARY)}
            value="graph"
          />
          <Tab
            label={intl.formatMessage(messages.timeline)}
            active={viewType === AssertionsViewType.BY_ENTITY}
            onChangeTab={() => onTabChange(AssertionsViewType.BY_ENTITY)}
            value="graph"
          />
          <Tab
            label={intl.formatMessage(messages.graph)}
            active={viewType === AssertionsViewType.GRAPH}
            onChangeTab={() => onTabChange(AssertionsViewType.GRAPH)}
            value="graph"
          />
          <Tab
            label={intl.formatMessage(messages.mindMap)}
            active={viewType === AssertionsViewType.MIND_MAP}
            onChangeTab={() => onTabChange(AssertionsViewType.MIND_MAP)}
            value="graph"
          />
        </TabsBar>
      </div>
    </div>
  );
};

export default connector(memo(AssertionsTopMenu));
