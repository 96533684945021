import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ASSERTION_NAME, ASSERTION_SEVERITY, SLICE_NAME } from './constants';
import { AssertionEntityTypes, AssertionSearchContainerState, SearchRule, StringRules } from 'asserts-types';

const initialState: AssertionSearchContainerState = {
  search: {
    entityType: AssertionEntityTypes.SERVICE,
    searchCriteria: [
      {
        labelMatchers: [
          {
            name: ASSERTION_NAME,
            op: StringRules.CONTAINS,
            value: '',
          },
        ],
      },
    ],
  },
};

export const slice = createSlice({
  name: SLICE_NAME,

  initialState,

  reducers: {
    addRule: (state) => {
      state.search.searchCriteria.push({
        labelMatchers: [
          {
            name: ASSERTION_NAME,
            op: StringRules.CONTAINS,
            value: '',
          },
        ],
      });
    },

    setSeverity: (state, action: PayloadAction<{ listSeverity: string[]; index: number }>) => {
      const { listSeverity, index } = action.payload;

      const severityRules = listSeverity.map(
        (severity) =>
          ({
            name: ASSERTION_SEVERITY,
            op: StringRules.EQUALS,
            value: severity,
          } as SearchRule)
      );

      const searchRule = state.search.searchCriteria[index].labelMatchers[0];

      state.search.searchCriteria[index].labelMatchers = [searchRule, ...severityRules];
    },

    changeRule: (
      state,
      action: PayloadAction<{
        criteriaIndex: number;
        ruleIndex: number;
        patchObj: { op?: string; value?: string };
      }>
    ) => {
      const { criteriaIndex, ruleIndex, patchObj } = action.payload;

      const searchRule = state.search.searchCriteria[criteriaIndex].labelMatchers[ruleIndex];

      Object.assign(searchRule, patchObj);
    },

    deleteRule: (state, action: PayloadAction<number>) => {
      const index = action.payload;

      state.search.searchCriteria.splice(index, 1);
    },

    setEntityType: (state, action: PayloadAction<AssertionEntityTypes>) => {
      state.search.entityType = action.payload;
    },
  },
});

export const { addRule, setSeverity, changeRule, deleteRule, setEntityType } = slice.actions;

export default slice.reducer;
