/**
 *
 * EntitySearchProperty
 *
 */

import React, { memo, FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import CloseIcon from 'assets/material-icons/close_FILL0_wght400_GRAD0_opsz24.svg';
import {
  EntityAdvancedSearchFormEntityItem,
  EntityFilterPropertyMatcher,
} from 'asserts-types';
import {
  addSearchProperty,
  changeRule,
  deleteRule,
  deleteSearchProperty,
} from 'features/Entities/Entities.slice';
import EntitySearchRuleItem from '../EntitySearchRuleItem/EntitySearchRuleItem.component';
import messages from './messages';
import { IconButton } from 'components/IconButton/IconButton.component';

interface IProps {
  formItem: EntityAdvancedSearchFormEntityItem;
  propertyName: string;
  deleteSearchProperty: typeof deleteSearchProperty;
  addSearchProperty: typeof addSearchProperty;
  deleteRule: typeof deleteRule;
  changeRule: typeof changeRule;
  rules: EntityFilterPropertyMatcher[];
}

const EntitySearchProperty: FunctionComponent<IProps> = ({
  propertyName,
  formItem,
  deleteSearchProperty,
  rules,
  addSearchProperty,
  deleteRule,
  changeRule,
}) => {
  const intl = useIntl();

  return (
    <div className="py-0 pl-0 pr-4">
      <div className="flex items-center gap-2">
        <IconButton
          variant="destructive"
          onClick={() =>
            deleteSearchProperty({
              formItem,
              propertyName,
            })
          }
        >
          <CloseIcon />
        </IconButton>
        <div className="grow flex items-center justify-between">
          <p className="font-bold">{propertyName.replace(/^asserts_/, '')}</p>
          <button
            className="border-0 p-0 bg-transparent cursor-pointer text-primary hover:underline"
            onClick={() => addSearchProperty({ formItem, propertyName })}
          >
            <p className="text-primary text-xs">{intl.formatMessage(messages.addRule)}</p>
          </button>
        </div>
      </div>
      {rules?.map((rule) => (
        <EntitySearchRuleItem
          key={`${rule.id}-rule-item`}
          changeRule={changeRule}
          deleteRule={deleteRule}
          ruleItem={rule}
          propertyName={propertyName}
          formItem={formItem}
        />
      ))}
    </div>
  );
};

export default memo(EntitySearchProperty);
