import React, { FunctionComponent, memo, useRef } from 'react';
import EntityListItem from '../EntityListItem/EntityListItem.component';
import { ActiveEntityDetailsPayload, Entity } from 'asserts-types';
import EntitiesSortByComponent from '../EntitiesSortBy/EntitiesSortBy.component';
import useVirtualList from 'hooks/useVirtualList';
import { PanelContainer } from '@grafana/ui';

interface IProps {
  label?: string;
  list: Entity[] | undefined;
  activeEntity: Entity | undefined;
  setActiveEntityDetails: (value?: ActiveEntityDetailsPayload) => void;
  handleItemMouseState: (item: Entity, isMouseOutState?: boolean) => void;
  lastUpdateTime: number | undefined;
}

const EntityList: FunctionComponent<IProps> = ({
  label,
  list,
  activeEntity,
  setActiveEntityDetails,
  handleItemMouseState,
  lastUpdateTime,
}) => {
  const parentRef = useRef(null);

  const { parentProps, rowVirtualizer } = useVirtualList({
    parentRef,
    size: list?.length || 0,
    customParentClass: 'h-full overflow-overlay',
  });

  if (!list) {
    return null;
  }

  return (
    <>
      {label && (
        <div className="-mb-4 mt-8">
          <div>{label}</div>
          <div className="flex items-start space-x-8 justify-between">
            <div
              className="text-secondary text-sm whitespace-nowrap overflow-hidden text-ellipsis shrink"
              title={activeEntity?.name}
            >
              of {activeEntity?.name}
            </div>
            <div className="whitespace-nowrap">
              <EntitiesSortByComponent />
            </div>
          </div>
        </div>
      )}
      <PanelContainer className={`grow h-0 ${activeEntity ? 'mt-8' : 'mt-0'}`} data-tour-target="entities-list">
        <div ref={parentRef} {...parentProps}>
          {rowVirtualizer.virtualItems.map((virtualRow) => {
            return (
              <div key={virtualRow.index} ref={virtualRow.measureRef}>
                <EntityListItem
                  setActiveEntityDetails={setActiveEntityDetails}
                  onMouseEnter={() => handleItemMouseState(list[virtualRow.index])}
                  onMouseOut={() => handleItemMouseState(list[virtualRow.index], true)}
                  item={list[virtualRow.index]}
                  lastUpdateTime={lastUpdateTime}
                />
              </div>
            );
          })}
        </div>
      </PanelContainer>
    </>
  );
};

export default memo(EntityList);
