import { useMutation, useQueryClient } from '@tanstack/react-query';
import messages from '../messages';
import { USE_CUSTOM_MODEL_RULES_LIST_QUERY_KEY } from './useCustomModelRulesList';
import { deleteCustomModelRule } from 'services/ManageAssertions.service';
import SnackbarHelper from 'helpers/Snackbar.helper';

export default function useDeleteCustomModelRule() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: string) => deleteCustomModelRule(data),
    onSuccess: (_, ruleName) => {
      SnackbarHelper.success(messages.fileRemoved);

      queryClient.setQueryData<string[]>(
        [USE_CUSTOM_MODEL_RULES_LIST_QUERY_KEY],
        (rules) => rules?.filter((r) => r !== ruleName) || []
      );
    },
  });
}
