import { EntityProperties, Entity, Scope, AssertionEntityTypes } from 'asserts-types';
import { isEqual } from 'lodash';

export const getEntityTypeLabel = (
  entityType: string,
  scope: Scope | undefined,
  properties?: EntityProperties
): string => {
  if (entityType === AssertionEntityTypes.SERVICE && properties) {
    const serviceType = properties['service_type'];
    const provider = properties['provider'];
    const propType = properties['asserts_subtype'];

    if (serviceType === 'Lambda') {
      return 'Function';
    }
    if (serviceType === 'EBSVolume' && provider === 'aws') {
      return 'EBSVolume';
    }
    if (serviceType === 'EFSFileSystem' && provider === 'aws') {
      return 'EFSFileSystem';
    }
    if (serviceType === 'S3 Bucket' && provider === 'aws') {
      return 'S3 Bucket';
    }
    if (serviceType === 'RDS Instance' && provider === 'aws') {
      return 'RDSInstance';
    }
    if (serviceType === 'DynamoDB Table' && provider === 'aws') {
      return 'DynamoDB';
    }
    if (serviceType === 'EC2 Instance' && provider === 'aws') {
      return 'EC2 Instance';
    }
    if (scope?.namespace === 'AWS/ECS') {
      return 'ECSService';
    }
    if (serviceType === 'Api Gateway' && provider === 'aws') {
      return 'API Gateway';
    }
    if (serviceType === 'Kinesis Firehose' && provider === 'aws') {
      return 'KinesisFirehose';
    }
    if (serviceType === 'Kinesis Analytics' && provider === 'aws') {
      return 'KinesisAnalytics';
    }
    if (serviceType === 'Kinesis Stream' && provider === 'aws') {
      return 'KinesisStream';
    }
    if (serviceType === 'SNSTopic' && provider === 'aws') {
      return 'SNSTopic';
    }
    if (serviceType === 'NatGateway' && provider === 'aws') {
      return 'NATGateway';
    }
    if (serviceType === 'LoadBalancer' && provider === 'aws' && propType === 'app') {
      return 'ApplicationLB';
    }
    if (serviceType === 'LoadBalancer' && provider === 'aws' && propType === 'net') {
      return 'NetworkLB';
    }
    if (serviceType === 'LoadBalancer' && provider === 'aws') {
      return 'ClassicLB';
    }
  }

  if (entityType === AssertionEntityTypes.POD && properties && scope?.namespace === 'AWS/ECS') {
    return 'ECSTask';
  }

  if (entityType === AssertionEntityTypes.TOPIC && properties && properties['service_type'] === 'SQSQueue') {
    return 'SQSQueue';
  }

  if (
    properties &&
    properties.asserts_source_entity_type &&
    ['Pod', 'ServiceInstance'].includes(properties.asserts_source_entity_type?.toString())
  ) {
    return properties.asserts_source_entity_type?.toString() + 's'; // plural form
  }

  return entityType;
};

export const kpiRegexp = (name: string, scope: Scope | undefined) => {
  return new RegExp(
    `(.*?name=${name}[,|\\{|\\}])${scope?.site ? `(.*?site=${scope?.site}[,|\\{|\\}])` : ''}${
      scope?.env ? `(.*?env=${scope?.env}[,|\\{|\\}])` : ''
    }`
  );
};

export const areEntitiesEqual = (
  a: Partial<Pick<Entity, 'name' | 'type' | 'scope'>>,
  b: Partial<Pick<Entity, 'name' | 'type' | 'scope'>>
) => {
  return a.name === b.name && a.type === b.type && isEqual(a.scope, b.scope);
};

export const getDashedScopeValues = (scope: Scope) => {
  return Object.keys(scope)
    .sort()
    .map((key) => scope[key as keyof Scope])
    .join('-');
};

export const SYSTEM_ENTITY_PROPERTY_PREFIX = '_';
export const SYSTEM_ENTITY_PROPERTY_WHITELIST = ['_created', '_updated'];

export function deleteSystemEntityProperties(properties: EntityProperties): EntityProperties {
  return Object.keys(properties).reduce((acc, key) => {
    if (!key.startsWith(SYSTEM_ENTITY_PROPERTY_PREFIX) || SYSTEM_ENTITY_PROPERTY_WHITELIST.includes(key)) {
      acc[key] = properties[key];
    }
    return acc;
  }, {} as EntityProperties);
}
