/**
 *
 * TopInsightsItem
 *
 */

import React, { memo, FunctionComponent, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import TopInsightsTimelineComponent from '../TopInsightsTimeline/TopInsightsTimeline.component';
import moment from 'moment';
import TopInsightsListComponent from '../TopInsightsList/TopInsightsList.component';
import PinIcon from 'icons/PinIcon';
import useTopInsightsSearches from '../../hooks/useTopInsightsSearches';
import { useNavigate } from 'react-router-dom';
import DeleteOutlineIcon from 'assets/material-icons/delete_FILL0_wght400_GRAD0_opsz24.svg';
import { connect, ConnectedProps, useDispatch } from 'react-redux';

import { setSelectedViewTypeMap } from '../../TopInsights.slice';
import KeyboardArrowRightRoundedIcon from 'assets/material-icons/chevron_right_FILL0_wght400_GRAD0_opsz24.svg';
import KeyboardArrowDownRoundedIcon from 'assets/material-icons/keyboard_arrow_down_FILL0_wght400_GRAD0_opsz24.svg';
import SaafeButtonsComponent from 'components/SaafeButtons/SaafeButtons.component';
import EntitiesOpenIcon from 'icons/EntitiesOpenIcon';
import { TI_ITEM_SHORT_LIST_LENGTH } from '../../constants';
import WbOpenIcon from 'icons/WbOpenIcon';
import axios from 'axios';
import { setSearch as setWorkbenchSearch } from '../../../Assertions/Assertions.slice';
import { clearEntities, setSearch as setEntitiesSearch } from '../../../Entities/Entities.slice';
import { SAAFE, TopInsightsSort, TopInsightsViewType } from 'asserts-types';
import useTopInsightsSearch from 'hooks/useTopInsightsSearch';
import TopInsightsSeverityPercentageStatsComponent from 'components/TopInsightsSeverityPercentageStats/TopInsightsSeverityPercentageStats.component';
import TopInsightsRootTimelineComponent from 'components/TopInsightsRootTimeline/TopInsightsRootTimeline.component';
import TopInsightsBoardComponent from '../TopInsightsBoard/TopInsightsBoard.component';
import { prefixRoute } from 'utils/utils.routing';
import { ROUTES, TRACKING_FEATURES } from 'global-constants';
import { Button, PanelContainer, Spinner, Tab, TabsBar } from '@grafana/ui';
import { Tooltip } from 'components/Tooltip/Tooltip.component';
import { IconButton } from 'components/IconButton/IconButton.component';
import TrackingHelper from 'helpers/Tracking.helper';

interface IProps {
  searchQuery: string;
  start: number | string;
  end: number | string;
  scaleType: 'log' | 'linear';
  handleDateRangeChange: (start: string | number, end: string | number) => void;
  viewType: TopInsightsViewType;
}

const connector = connect((state: RootState) => ({}), {
  setSelectedViewTypeMap,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

const TopInsightsItem: FunctionComponent<IProps & PropsFromRedux> = ({
  start,
  end,
  searchQuery,
  scaleType,
  handleDateRangeChange,
  setSelectedViewTypeMap,
  viewType = TopInsightsViewType.SUMMARY,
}) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [sortField, setSortField] = useState<TopInsightsSort>({
    field: 'percentage',
    order: 'desc',
  });
  const [typeFilter] = useState<string[]>([]);

  const { canPin, togglePinnedSearch, isPinned, isExpanded, toggleExpanded, canDelete, removeSearch } =
    useTopInsightsSearches();

  const [alertCategories, setAlertCategories] = useState<SAAFE[]>([
    'saturation',
    'amend',
    'anomaly',
    'failure',
    'error',
  ]);

  const { data, isFetching, dataUpdatedAt, error } = useTopInsightsSearch({
    start,
    end,
    query: searchQuery,
    alertCategories,
    typeFilter,
    sortField,
  });

  const notFoundError = error && axios.isAxiosError(error) && error.response?.status === 404;

  const [showAll, setShowAll] = useState(false);

  const expanded = isExpanded(searchQuery);

  return (
    <PanelContainer
      className={`relative !overflow-visible mt-8 px-8 mb-0 ${notFoundError ? 'border-2 border-red-500' : ''}`}
      data-cy="ti-item"
      data-tour-target="ti-item"
    >
      <div
        className={`flex items-center h-[70px] sticky top-0 z-[1000] overflow-visible bg-paper rounded-md -mx-8 px-2 ${
          expanded && 'divider-b'
        }`}
      >
        <div className="flex items-center justify-between grow" onClick={() => toggleExpanded(searchQuery)}>
          <div className="cursor-pointer flex items-center">
            {expanded ? (
              <KeyboardArrowDownRoundedIcon className="svg-icon" />
            ) : (
              <KeyboardArrowRightRoundedIcon className="svg-icon" />
            )}
            <div>
              <p className={`text-xs  ml-1 mr-2.5 ${notFoundError ? 'text-destructive font-bold' : 'text-secondary'}`}>
                {formatMessage(notFoundError ? messages.notFound : messages.query)}
              </p>
              <p className="text-base font-bold ml-1 mr-2.5">
                {searchQuery}{' '}
                <span className="text-xs text-secondary font-normal">
                  {Boolean(data?.graphData.length) && `(${data?.graphData.length} entities)`}
                </span>
              </p>
            </div>
            <div>
              <Tooltip content={formatMessage(messages.addToWb)} placement="top">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(setWorkbenchSearch(searchQuery));
                    TrackingHelper.trackNavigateToWorkbench(TRACKING_FEATURES.TOP_INSIGHTS)
                    navigate(prefixRoute(ROUTES.RCA_WORKBENCH), { state: { timestamp: Date.now() } });
                  }}
                >
                  <WbOpenIcon />
                </IconButton>
              </Tooltip>
              <Tooltip content={formatMessage(messages.entities)} placement="top">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(clearEntities());
                    dispatch(setEntitiesSearch(searchQuery));
                    navigate(prefixRoute(ROUTES.ENTITIES_GRAPH), { state: { timestamp: Date.now() } });
                  }}
                >
                  <EntitiesOpenIcon />
                </IconButton>
              </Tooltip>
              {canPin && !isPinned(searchQuery) && (
                <Tooltip content={formatMessage(messages.pin)} placement="top">
                  <IconButton
                    data-cy="pin-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      togglePinnedSearch(searchQuery);
                    }}
                  >
                    <PinIcon />
                  </IconButton>
                </Tooltip>
              )}
              {isPinned(searchQuery) && (
                <Tooltip content={formatMessage(messages.unpin)} placement="top">
                  <IconButton
                    data-cy="unpin-btn"
                    variant="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      togglePinnedSearch(searchQuery);
                    }}
                  >
                    <PinIcon />
                  </IconButton>
                </Tooltip>
              )}
              {canDelete && !isPinned(searchQuery) && (
                <Tooltip content={formatMessage(messages.remove)} placement="top">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      removeSearch(searchQuery);
                    }}
                    variant="destructive"
                    data-cy="delete-watched"
                  >
                    <DeleteOutlineIcon className="svg-icon" />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>

          {isFetching ? (
            <Spinner size={20} className="mr-4 leading-none" />
          ) : (
            <TopInsightsSeverityPercentageStatsComponent
              rootScores={data?.assertionScoresForRootEntity}
              assertionsRollup={data?.assertionRollupDto}
            />
          )}
        </div>
      </div>
      {expanded && data && (
        <>
          <div className="flex">
            <div className="flex items-center self-end mb-4 w-[450px] justify-between pr-4 shrink-0">
              <div className="font-bold">
                {formatMessage(messages.topN, {
                  count: data.assertionScoresForTopNEntities.length,
                })}
                <span className="text-secondary text-xs block font-normal">
                  {formatMessage(messages.from, { query: searchQuery })}
                </span>
              </div>
              {Boolean(data.assertionScoresForTopNEntities.length) && (
                <TabsBar>
                  <Tab
                    label={formatMessage(messages.assertions)}
                    value={TopInsightsViewType.SUMMARY}
                    active={viewType === TopInsightsViewType.SUMMARY}
                    onChangeTab={() =>
                      setSelectedViewTypeMap({
                        topInsightsSearch: searchQuery,
                        type: TopInsightsViewType.SUMMARY,
                      })
                    }
                  />
                  <Tab
                    label={formatMessage(messages.kpi)}
                    value={TopInsightsViewType.KPI}
                    active={viewType === TopInsightsViewType.KPI}
                    onChangeTab={() =>
                      setSelectedViewTypeMap({
                        topInsightsSearch: searchQuery,
                        type: TopInsightsViewType.KPI,
                      })
                    }
                  />
                </TabsBar>
              )}
            </div>
            <div className="grow divider-l">
              <TopInsightsRootTimelineComponent
                start={data.timeWindow.start}
                end={data.timeWindow.end}
                rootScores={data.assertionScoresForRootEntity}
                timeStepIntervalMs={data.timeStepIntervalMs}
                scaleType={scaleType}
                changeDateRange={handleDateRangeChange}
              />
              <div className="py-4">
                <SaafeButtonsComponent
                  selectedSaafe={alertCategories}
                  countMap={data.alertCategoriesCountMap}
                  setAlertCategories={setAlertCategories}
                />
              </div>
            </div>
          </div>
          {viewType === TopInsightsViewType.KPI && Boolean(data.assertionScoresForTopNEntities.length) && (
            <TopInsightsBoardComponent start={start} end={end} data={data} lastUpdateTime={dataUpdatedAt} />
          )}
          {viewType === TopInsightsViewType.SUMMARY && Boolean(data.assertionScoresForTopNEntities.length) && (
            <div className="pb-16">
              <div className="flex items-start">
                <TopInsightsListComponent
                  assertionScores={data.assertionScoresForTopNEntities}
                  setSortField={setSortField}
                  sortField={sortField}
                  expanded
                  lastUpdateTime={dataUpdatedAt}
                  showAll={showAll}
                />
                <div className="relative w-full">
                  <TopInsightsTimelineComponent
                    start={moment(data.timeWindow.start)}
                    end={moment(data.timeWindow.end)}
                    assertionScores={data.assertionScoresForTopNEntities}
                    rootScores={data.assertionScoresForRootEntity}
                    changeDateRange={handleDateRangeChange}
                    timeStepIntervalMs={data.timeStepIntervalMs}
                    scaleType={scaleType}
                    expanded
                    showAll={showAll}
                  />
                </div>
              </div>
              {(data?.assertionScoresForTopNEntities.length || 0) > TI_ITEM_SHORT_LIST_LENGTH && (
                <div
                  className={` ${
                    showAll ? 'bg-transparent' : 'bg-gradient-to-t from-white dark:from-black rounded-md'
                  } h-[150px] inset-x-0 bottom-0 absolute`}
                >
                  <Button
                    fill="text"
                    className="absolute bottom-3 left-1/2 -translate-x-1/2"
                    onClick={() => setShowAll(!showAll)}
                  >
                    {showAll ? 'Show less' : 'Show more'}
                  </Button>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </PanelContainer>
  );
};

export default connector(memo(TopInsightsItem));
