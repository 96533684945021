import React, { FunctionComponent, MouseEvent, useMemo } from 'react';
import { getEntityTypeLabel } from '../../helpers/Entity.helper';
import { hex2rgba } from '../../helpers/Color.helper';
import { twMerge } from 'tailwind-merge';
import { EntityProperties, Scope } from 'asserts-types';
import useAppDisplayConfig from 'hooks/useAppDisplayConfig';
import { useTheme2 } from '@grafana/ui';

interface IProps {
  entityType: string;
  scope: Scope | undefined;
  properties?: EntityProperties;
  className?: string;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  count?: number;
}

const TagLabel: FunctionComponent<IProps> = ({
  children,
  className,
  entityType,
  properties,
  scope,
  onClick,
  count,
}) => {
  const name = useMemo(() => getEntityTypeLabel(entityType, scope, properties), [entityType, properties, scope]);
  const { data: displayConfig } = useAppDisplayConfig();
  const theme = useTheme2();

  const tagColor =
    displayConfig?.graph?.overrides?.entities?.[entityType]?.color ||
    displayConfig?.graph?.entities?.[name]?.color ||
    displayConfig?.graph?.defaultEntity?.color ||
    theme.colors.primary.main;

  return (
    <div
      style={{
        background: tagColor && hex2rgba(tagColor, 0.15),
        color: entityType === 'Assertion' ? '#9e9e9e' : tagColor,
      }}
      onClick={onClick}
      className={twMerge(
        `${entityType === 'Assertion' ? `border border-dashed border-gray-500 text-primary` : ''} inline-block py-1 ${
          count ? 'pr-[12px]' : 'pr-[24px]'
        } pl-[12px] rounded-full text-sm relative whitespace-nowrap bg-primary/20 ${className}`
      )}
    >
      {!count && (
        <div
          style={{ background: entityType === 'Assertion' ? '#9e9e9e' : tagColor }}
          className="w-[6px] h-[6px] absolute top-1/2 right-[12px] rounded-full -translate-y-1/2"
        ></div>
      )}
      {children ? children : name} {count ? `(${count})` : ''}
    </div>
  );
};

export default TagLabel;
