import { defineMessages } from 'react-intl';

export const scope = 'features.TopInsightsViewSettings';

export default defineMessages({
  viewType: {
    id: `${scope}.viewType`,
    defaultMessage: 'View type',
  },
  sort: {
    id: `${scope}.sort`,
    defaultMessage: 'Sort',
  },
  assertionScores: {
    id: `${scope}.assertionScores`,
    defaultMessage: 'Assertion scores',
  },
  assertionBars: {
    id: `${scope}.assertionBars`,
    defaultMessage: 'Assertion bars',
  },
  byType: {
    id: `${scope}.byType`,
    defaultMessage: 'by Type',
  },
  byScore: {
    id: `${scope}.byScore`,
    defaultMessage: 'by Score',
  },
  hideAssertionsOld: {
    id: `${scope}.hideAssertionsOld`,
    defaultMessage: 'Hide assertions older than',
  },
  hours: {
    id: `${scope}.hours`,
    defaultMessage: 'hours',
  },
  category: {
    id: `${scope}.category`,
    defaultMessage: 'Category',
  },
  lgScale: {
    id: `${scope}.lgScale`,
    defaultMessage: 'Logarithmic scale',
  },
});
