/*
 * AssertionsProblematicConnectionsButton Messages
 *
 * This contains all the text for the AssertionsProblematicConnectionsButton component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.AssertionsProblematicConnectionsButton';

export default defineMessages({
  connections: {
    id: `${scope}.connections`,
    defaultMessage: 'Add {name} problematic connections',
  },
  loading: {
    id: `${scope}.loading`,
    defaultMessage: 'Loading problematic connections...',
  },
  connectionsLoaded: {
    id: `${scope}.connectionsLoaded`,
    defaultMessage: 'Problematic connections added.',
  },
  noConnections: {
    id: `${scope}.noConnections`,
    defaultMessage: 'No problematic connections found.',
  },
  added: {
    id: `${scope}.added`,
    defaultMessage: 'Problematic connections added:',
  },
  notFound: {
    id: `${scope}.notFound`,
    defaultMessage: 'No problematic connections found',
  },
});
