/*
 * AssertionSearchRules Messages
 *
 * This contains all the text for the AssertionSearchRules container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'features.AssertionSearchRules';

export default defineMessages({
  assertionName: {
    id: `${scope}.assertionName`,
    defaultMessage: 'Assertion name',
  },
  addRule: {
    id: `${scope}.addRule`,
    defaultMessage: 'Add rule',
  },
  rootEntity: {
    id: `${scope}.rootEntity`,
    defaultMessage: 'Root entity type',
  },
  service: {
    id: `${scope}.service`,
    defaultMessage: 'Service',
  },
  namespace: {
    id: `${scope}.namespace`,
    defaultMessage: 'Namespace',
  },
  node: {
    id: `${scope}.node`,
    defaultMessage: 'Node',
  },
  search: {
    id: `${scope}.search`,
    defaultMessage: 'Search',
  },
  close: {
    id: `${scope}.close`,
    defaultMessage: 'Close',
  },
});
