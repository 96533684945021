/**
 *
 * ConnectedEntitiesMenu
 *
 */

import React, { FunctionComponent, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import { Scope } from 'asserts-types';
import { connect, ConnectedProps } from 'react-redux';

import { stringToDate } from 'helpers/Date.helper';
import ConnectedEntitiesMenuItem from '../ConnectedEntitiesMenuItem/ConnectedEntitiesMenuItem.component';
import TagLabel from 'components/TagLabel/TagLabel';
import ArrowBackIcon from 'assets/material-icons/arrow_back_FILL0_wght400_GRAD0_opsz24.svg';
import useDidUpdateEffect from 'hooks/useDidUpdate';
import useEntity from 'hooks/useEntity';
import { LoadingPlaceholder, Menu } from '@grafana/ui';
import { IconButton } from 'components/IconButton/IconButton.component';

interface IProps {
  entityType: string;
  entityName: string;
  scope?: Scope;
}

const connector = connect(
  (state: RootState) => ({
    start: state.app.start,
    end: state.app.end,
  }),
  {}
);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedEntitiesMenu: FunctionComponent<IProps & PropsFromRedux> = ({
  entityType,
  entityName,
  start,
  end,
  scope,
}) => {
  const intl = useIntl();

  const [menuItemsNavigation, setMenuItemsNavigation] = useState<{ name: string; type: string }[]>([
    { type: entityType, name: entityName },
  ]);

  useDidUpdateEffect(() => {
    setMenuItemsNavigation([{ type: entityType, name: entityName }]);
  }, [entityName, entityType]);

  const memoStart = useMemo(() => stringToDate(start).valueOf(), [start]);
  const memoEnd = useMemo(() => stringToDate(end).valueOf(), [end]);

  const handleNavigate = (name: string, type: string) => {
    setMenuItemsNavigation(menuItemsNavigation.concat([{ type, name }]));
  };

  const handleNavigateBack = () => {
    setMenuItemsNavigation(menuItemsNavigation.slice(0, -1));
  };

  const currentName = menuItemsNavigation[menuItemsNavigation.length - 1].name;
  const currentType = menuItemsNavigation[menuItemsNavigation.length - 1].type;

  const { data: rootEntity, isFetching } = useEntity({
    entityName: currentName,
    entityType: currentType,
    start: memoStart,
    end: memoEnd,
    scope,
  });

  const renderHeader = () => (
    <div className="flex justify-between items-center sticky top-0 bg-paper z-[10] py-4">
      <div className="flex items-center">
        {menuItemsNavigation.length > 1 && (
          <IconButton onClick={handleNavigateBack} className="mr-2">
            <ArrowBackIcon />
          </IconButton>
        )}
        <p className="whitespace-nowrap text-ellipsis overflow-hidden mr-4 font-bold">{currentName}</p>
      </div>
      <TagLabel entityType={currentType} scope={scope} />
    </div>
  );

  const connectedItems = rootEntity?.connectedEntityTypes
    ? Object.keys(rootEntity.connectedEntityTypes).filter((key) => key !== 'Assertion' && key !== 'KubeService')
    : [];

  return (
    <Menu>
      <div className="p-4 pt-0 z-[999] w-[500px] max-h-[400px] overflow-overlay" onClick={(e) => e.stopPropagation()}>
        {renderHeader()}
        {isFetching ? (
          <LoadingPlaceholder text="Loading..." className="py-8 text-center m-0" />
        ) : (
          <Menu.Group label={intl.formatMessage(connectedItems.length ? messages.connected : messages.noItems)}>
            {rootEntity &&
              connectedItems.map((key) => (
                <ConnectedEntitiesMenuItem
                  key={key}
                  type={key}
                  count={rootEntity.connectedEntityTypes?.[key] || 0}
                  start={memoStart}
                  end={memoEnd}
                  scope={rootEntity.scope}
                  entityId={rootEntity.id}
                  onNavigate={handleNavigate}
                />
              ))}
          </Menu.Group>
        )}
      </div>
    </Menu>
  );
};

export default connector(ConnectedEntitiesMenu);
