/**
 *
 * AssertionSearchRuleItem
 *
 */

import React, { memo, FunctionComponent, useState } from 'react';
import { useIntl } from 'react-intl';
import CloseIcon from 'assets/material-icons/close_FILL0_wght400_GRAD0_opsz24.svg';
import messages from './messages';
import { SearchRule, StringRules } from 'asserts-types';
import MoreHorizIcon from 'assets/material-icons/more_horiz_FILL0_wght400_GRAD0_opsz24.svg';
import { connect, ConnectedProps } from 'react-redux';
import { setSeverity, changeRule, deleteRule } from '../../AssertionSearch.slice';
import { assertsColors } from 'app/constants';
import { Button, Dropdown, Field, Input, Menu, Select } from '@grafana/ui';
import { isEqual } from 'lodash';
import { IconButton } from 'components/IconButton/IconButton.component';

interface IProps {
  labelMatchers: SearchRule[];
  criteriaIndex: number;
}
const connector = connect(null, {
  setSeverity,
  changeRule,
  deleteRule
});
type PropsFromRedux = ConnectedProps<typeof connector>;

const AssertionSearchRuleItem: FunctionComponent<IProps & PropsFromRedux> = ({
  labelMatchers,
  criteriaIndex,
  setSeverity,
  deleteRule,
  changeRule,
}) => {
  const intl = useIntl();

  const ruleOptions = [
    {
      value: StringRules.CONTAINS,
      label: intl.formatMessage(messages.contains),
    },
    {
      value: StringRules.STARTS_WITH,
      label: intl.formatMessage(messages.startsWith),
    },
    {
      value: StringRules.EQUALS,
      label: intl.formatMessage(messages.equals),
    },
  ];

  const severityOptions = [
    {
      message: intl.formatMessage(messages.criticalAndAlerts),
      types: ['critical', 'warning'],
    },
    {
      message: intl.formatMessage(messages.critical),
      types: ['critical'],
    },
    {
      message: intl.formatMessage(messages.alerts),
      types: ['warning'],
    },
  ];

  const setSeverityHandler = (listSeverity: string[]) => {
    setSeverity({ index: criteriaIndex, listSeverity });
  };

  return (
    <div className="flex items-center mb-4 -ml-3 gap-1">
      <IconButton onClick={() => deleteRule(criteriaIndex)} data-cy="wb-search-delete-rule">
        <CloseIcon />
      </IconButton>
      <div className="flex items-center grow gap-4">
        <Field label={intl.formatMessage(messages.rule)} className="w-0 grow basis-0">
          <Select
            onChange={(v) =>
              changeRule({
                criteriaIndex: criteriaIndex,
                ruleIndex: 0,
                patchObj: {
                  op: v.value,
                },
              })
            }
            value={labelMatchers[0].op}
            options={ruleOptions}
          />
        </Field>
        <Field label={intl.formatMessage(messages.value)} className="w-0 grow basis-0">
          <Input
            data-cy="wb-search-value"
            disabled={labelMatchers[0].op === StringRules.IS_NULL || labelMatchers[0].op === StringRules.IS_NOT_NULL}
            onChange={(e) =>
              changeRule({
                criteriaIndex: criteriaIndex,
                ruleIndex: 0,
                patchObj: {
                  value: e.currentTarget.value as string,
                },
              })
            }
            placeholder="Enter value"
            value={labelMatchers[0].value}
            label={intl.formatMessage(messages.value)}
          />
        </Field>
        <Field label="Type">
          <Dropdown
            overlay={
              <Menu>
                {severityOptions.map((option, index) => (
                  <Menu.Item
                    className="flex justify-between"
                    key={index + 'menu-item'}
                    onClick={() => setSeverityHandler(option.types)}
                    label={option.message}
                    active={isEqual(
                      option.types,
                      labelMatchers.slice(1).map((l) => l.value)
                    )}
                  />
                ))}
              </Menu>
            }
          >
            <Button variant="secondary" className="w-[33px] px-0 flex items-center justify-center">
              {labelMatchers.length < 2 && <MoreHorizIcon color="primary" />}
              {labelMatchers.length >= 2 && (
                <div className="flex justify-center min-w-[20px] space-x-1">
                  {labelMatchers.slice(1).map((rule, index) => (
                    <span
                      key={index}
                      className="w-[8px] h-[8px] rounded"
                      style={{
                        background: assertsColors[rule.value as 'critical' | 'warning' | 'info'],
                      }}
                    ></span>
                  ))}
                </div>
              )}
            </Button>
          </Dropdown>
        </Field>
      </div>
    </div>
  );
};

export default connector(memo(AssertionSearchRuleItem));
