/**
 *
 * AssertionsList
 *
 */
import React, { memo, useRef, FC } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import {
  setExpandedSaafeFilter,
  setExpandedItems,
  setAlertCategories,
} from 'features/Assertions/Assertions.slice';
import AssertionsListByEntityItem from '../AssertionsListByEntityItem/AssertionsListByEntityItem.component';
import { AssertionsResponseProcessed } from 'asserts-types';
import ExpandLessIcon from 'assets/material-icons/keyboard_arrow_up_FILL0_wght400_GRAD0_opsz24.svg';
import ExpandMoreIcon from 'assets/material-icons/keyboard_arrow_down_FILL0_wght400_GRAD0_opsz24.svg';
import AssertionsSortByComponent from '../AssertionsSortBy/AssertionsSortBy.component';
import SelectedAssertionsActionsComponent from '../SelectedAssertionsActions/SelectedAssertionsActions.component';
import { useDispatch } from 'react-redux';
import useSummaryLeftPanel from '../../hooks/useSummaryLeftPanel';
import AssertionsSummaryResizeHandleComponent from '../AssertionsSummaryResizeHandle/AssertionsSummaryResizeHandle.component';
import { AXIS_PADDING_RIGHT } from 'app/constants';
import AssertionsTimelineInteractionsComponent from '../AssertionsTimelineInteractions/AssertionsTimelineInteractions.component';
import { useAppSelector } from 'app/store';
import TableHeaderComponent from 'components/TableHeader/TableHeader.component';
import TopInsightsSeverityPercentageStatsComponent from 'components/TopInsightsSeverityPercentageStats/TopInsightsSeverityPercentageStats.component';
import TopInsightsRootTimelineComponent from 'components/TopInsightsRootTimeline/TopInsightsRootTimeline.component';
import SaafeButtonsComponent from 'components/SaafeButtons/SaafeButtons.component';
import TimelineSerifsComponent from 'components/TimelineSerifs/TimelineSerifs.component';

interface IProps {
  selectedItems: string[];
  show: boolean;
  setExpandedSaafeFilter: typeof setExpandedSaafeFilter;
  expandedSaafeFilter: boolean;
  start: number;
  end: number;
  changeDateRange: (newStart: number | string, newEnd: number | string) => void;
  assertionsData: AssertionsResponseProcessed | undefined;
  isFetching: boolean;
}

const AssertionsList: FC<IProps> = ({
  selectedItems,
  show,
  setExpandedSaafeFilter,
  expandedSaafeFilter,
  start,
  end,
  changeDateRange,
  assertionsData,
  isFetching,
}) => {
  const intl = useIntl();

  const workbenchEntities = useAppSelector(
    (state) => state.assertions.workbenchEntities,
  );

  const labelsFilter = useAppSelector((state) => state.assertions.labelsFilter);

  const expandedItems = useAppSelector(
    (state) => state.assertions.expandedItems,
  );
  const dispatch = useDispatch();

  const ref = useRef<HTMLDivElement | null>(null);

  const alertCategories = useAppSelector(
    (state) => state.assertions.alertCategories,
  );

  const { width: leftPanelWidth } = useSummaryLeftPanel();

  const timelineRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <AssertionsTimelineInteractionsComponent
        timelineRef={timelineRef}
        start={assertionsData?.timeWindow.start || start}
        end={assertionsData?.timeWindow.end || end}
        changeDateRange={changeDateRange}
        show={show}
      >
        <>
          <div className="w-full" ref={ref} data-intercom-target="wb-list">
            <div
              className="grid sticky top-0 z-[10]"
              style={{
                gridTemplateColumns: `${leftPanelWidth}px auto ${AXIS_PADDING_RIGHT}px`,
              }}
            >
              <TableHeaderComponent className="pl-8 pr-4 sticky top-0">
                <p
                  className="flex items-center cursor-pointer space-x-2 text-xs text-secondary"
                  onClick={() => setExpandedSaafeFilter(!expandedSaafeFilter)}
                >
                  {expandedSaafeFilter ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  <div>{intl.formatMessage(expandedSaafeFilter ? messages.collapse : messages.expand)}</div>
                </p>
              </TableHeaderComponent>
              <div className="bg-panel flex items-center justify-center" ref={timelineRef}>
                <p className="text-secondary text-xs">{intl.formatMessage(messages.hint)}</p>
              </div>
              <div className="bg-panel" />
            </div>

            {expandedSaafeFilter && (
              <div className="flex items-center">
                <div className="px-4 h-[210px] py-4" style={{ width: leftPanelWidth }}>
                  <div className="flex flex-col gap-4 justify-end items-end h-full">
                    <div className="font-bold">
                      {isFetching
                        ? 'Loading...'
                        : intl.formatMessage(messages.summary, {
                            count: workbenchEntities.length,
                          })}
                    </div>
                    <TopInsightsSeverityPercentageStatsComponent
                      rootScores={assertionsData?.aggregateAssertionScores}
                      assertionsRollup={assertionsData?.assertionRollupDto}
                      className="flex-col gap-2 items-end pr-0"
                    />
                  </div>
                </div>
                <div
                  style={{ paddingRight: AXIS_PADDING_RIGHT }}
                  className="bg-paper grow flex flex-col justify-end h-full"
                >
                  {assertionsData?.timeWindow && (
                    <>
                      <TopInsightsRootTimelineComponent
                        start={assertionsData.timeWindow.start}
                        end={assertionsData.timeWindow.end}
                        rootScores={assertionsData.aggregateAssertionScores}
                        timeStepIntervalMs={assertionsData.timeStepIntervalMs}
                        changeDateRange={changeDateRange}
                        disableInteractions
                      />

                      <div className="py-4">
                        <SaafeButtonsComponent
                          selectedSaafe={alertCategories}
                          countMap={assertionsData?.alertCategoriesCountMap}
                          setAlertCategories={(o) => dispatch(setAlertCategories(o))}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
            <div
              className="grid sticky top-0 z-[10]"
              style={{
                gridTemplateColumns: `${leftPanelWidth}px auto ${AXIS_PADDING_RIGHT}px`,
              }}
            >
              <TableHeaderComponent className="py-0 pl-2 pr-4 sticky top-0 z-[10] flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <SelectedAssertionsActionsComponent assertions={assertionsData?.assertions} />
                  <AssertionsSortByComponent />
                </div>
                {!!expandedItems.length && (
                  <div
                    className="text-secondary text-xs cursor-pointer hover:underline"
                    onClick={() => dispatch(setExpandedItems([]))}
                  >
                    {intl.formatMessage(messages.collapseAll)}
                  </div>
                )}
              </TableHeaderComponent>
              <div>
                <TableHeaderComponent>
                  <TimelineSerifsComponent
                    start={assertionsData?.timeWindow.start || start}
                    end={assertionsData?.timeWindow.end || end}
                  />
                </TableHeaderComponent>
              </div>
              <div className="bg-panel" />
            </div>

            {assertionsData?.assertions.map((item) => (
              <AssertionsListByEntityItem
                key={item.hash}
                selected={selectedItems.includes(item.hash)}
                entityGroupItem={item}
                start={assertionsData?.timeWindow.start || start}
                end={assertionsData?.timeWindow.end || end}
                labelsFilter={labelsFilter}
                maxScoreValue={assertionsData.maxAssertionScoreValue}
                timeWindowStart={assertionsData.timeWindow.start}
                timeWindowEnd={assertionsData.timeWindow.end}
                timeStepInterval={assertionsData.timeStepIntervalMs}
              />
            ))}
            <div className="h-[100px]"></div>
            {show && <AssertionsSummaryResizeHandleComponent />}
          </div>
        </>
      </AssertionsTimelineInteractionsComponent>
    </>
  );
};

export default memo(AssertionsList);
