/*
 * TopInsights Messages
 *
 * This contains all the text for the TopInsights container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'features.TopInsightsRootTimeline';

export default defineMessages({
  scoreHint: {
    id: `${scope}.scoreHint`,
    defaultMessage:
      'Asserts score is a composite measure of the # of assertions, their duration and severity reported by an entity.',
  },
  assertsScore: {
    id: `${scope}.assertsScore`,
    defaultMessage: `Asserts score`,
  },
  assertsScoreLabel: {
    id: `${scope}.assertsScoreLabel`,
    defaultMessage: `Asserts Score`,
  },
});
