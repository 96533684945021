/**
 *
 * ChartLegend
 *
 */

import { LegendConfig, LegendItem } from 'asserts-types';
import React, { memo, FunctionComponent } from 'react';
// import messages from './messages';
import ChartLegendItem from '../ChartLegendItem/ChartLegendItem.component';

interface IProps {
  legendConfig: LegendConfig;
  activeLegendItem?: LegendItem;
  onItemClick?: ({ name, field }: LegendItem) => void;
  onItemMouseOver?: ({ name, field }: LegendItem) => void;
  onItemMouseOut?: ({ name, field }: LegendItem) => void;
}

const ChartLegend: FunctionComponent<IProps> = ({
  legendConfig,
  activeLegendItem,
  onItemClick,
  onItemMouseOver,
  onItemMouseOut,
}) => {
  return (
    <div className="absolute inset-x-0 bottom-0 h-[25px] pl-[10px] overflow-x-scroll flex">
      {Object.keys(legendConfig).map((name) => {
        let opacity = 1;
        if (activeLegendItem && activeLegendItem.name !== name) {
          opacity = 0.5;
        }

        return (
          <div
            className="flex items-center text-xs cursor-pointer mr-[10px] whitespace-nowrap"
            key={name}
            onClick={(e) => {
              e.stopPropagation();
              onItemClick?.({
                name,
                field: legendConfig[name].field,
              });
            }}
            onMouseOver={() =>
              onItemMouseOver &&
              onItemMouseOver({
                name,
                field: legendConfig[name].field,
              })
            }
            onMouseOut={() =>
              onItemMouseOut &&
              onItemMouseOut({
                name,
                field: legendConfig[name].field,
              })
            }
            style={{
              opacity,
            }}
          >
            <ChartLegendItem
              color={legendConfig[name].color}
              name={name}
              shape={legendConfig[name].shape}
            />
          </div>
        );
      })}
    </div>
  );
};

export default memo(ChartLegend);
