import { useQuery } from '@tanstack/react-query';
import { Scope } from 'asserts-types';
import { fetchSloChart } from 'services/Slo.service';

interface IProps {
  sloName: string;
  chartName: string;
  sloTargetName: string;
  start: number;
  end: number;
  scope: Scope | undefined;
  enabled: boolean;
  measurementQuery?: string;
  threshold?: number;
}

export default function useSloChart({
  sloName,
  chartName,
  sloTargetName,
  start,
  end,
  scope,
  enabled,
  measurementQuery,
  threshold,
}: IProps) {
  return useQuery(
    ['slo-chart-data', sloName, chartName, sloTargetName, start, end, scope, measurementQuery, threshold],
    () => fetchSloChart(sloName, chartName, sloTargetName, start, end, scope, measurementQuery, threshold),
    { enabled }
  );
}
