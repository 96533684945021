/*
 * TopInsightsBoard Messages
 *
 * This contains all the text for the TopInsightsBoard component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.TopInsightsBoard';

export default defineMessages({
  score: {
    id: `${scope}.score`,
    defaultMessage: 'Score',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Name',
  },
});
