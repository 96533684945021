import { isEqual } from 'lodash';
import { useMemo, useState } from 'react';
import useDebounceValue from '../../../hooks/useDebounceValue';
import { GraphCustomData, TypeScopeFilter } from 'asserts-types';
import { useAppSelector } from 'app/store';

export function useGraphDataForBubbleView(graphData: GraphCustomData) {
  const typeFilter = useAppSelector((state) => state.entities.typeFilter);

  const nameSearchQuery = useAppSelector(
    (state) => state.entities.nameSearchQuery,
  );

  const [activeFilter, setActiveFilter] = useState<TypeScopeFilter[]>([]);

  const debouncedNameSearchQuery = useDebounceValue(nameSearchQuery, 300);

  const toggleActiveFilter = (filter: TypeScopeFilter) => {
    if (activeFilter.find((f) => isEqual(f, filter))) {
      setActiveFilter(activeFilter.filter((f) => !isEqual(f, filter)));
    } else {
      setActiveFilter(activeFilter.concat(filter));
    }
  };

  const processedGraphData = useMemo(
    () => ({
      nodes: graphData.nodes.filter(
        (n) =>
          !typeFilter.includes(n.entityType) &&
          n.label
            ?.toLowerCase()
            .includes(debouncedNameSearchQuery?.toLowerCase()) &&
          (activeFilter
            ? !Boolean(
                activeFilter.find(
                  (f) => f.type === n.type && isEqual(f.scope, n.scope),
                ),
              )
            : true),
      ),
      edges: [],
    }),
    [activeFilter, graphData.nodes, debouncedNameSearchQuery, typeFilter],
  );

  return {
    processedGraphData,
    graphData,
    activeFilter,
    setActiveFilter,
    toggleActiveFilter,
  };
}
