// Asserts supports layered thresholds. For Request thresholds, the following are the layers

// There is a system-default threshold
// The user could over-ride this with a threshold at the Service aka job level
// The user could over-ride further at the job, asserts_request_type combination level
// The user could over-ride further at the job, asserts_request_type, asserts_request_context combination level
// If the user has not defined a threshold at any of the above levels, the system automatically computes a threshold at the job, asserts_request_type, asserts_request_context combination level
// When the user comes to the threshold screen and chooses a job, if we apply the above rules, then exactly one of the above thresholds will be in effect.

export function getThreholdValueQueryForRequest(labels: Record<string, string> & { job: string }) {
  return `max by(asserts_threshold_level)(asserts:latency:average:threshold{asserts_threshold_level="job_type_context", job="${labels.job}", asserts_request_type="${labels.asserts_request_type || ''}", asserts_request_context="${labels.asserts_request_context || ''}"})
    or ignoring(asserts_threshold_level)
    max by(asserts_threshold_level)(asserts:latency:average:threshold{asserts_threshold_level="job_type", job="${labels.job}", asserts_request_type="${labels.asserts_request_type || ''}"})
    or ignoring(asserts_threshold_level)
    max by(asserts_threshold_level)(asserts:latency:average:threshold{asserts_threshold_level="job", job="${labels.job}"})
    or ignoring(asserts_threshold_level)
    max by(asserts_threshold_level)(last_over_time(asserts:latency:average:threshold{asserts_threshold_level="automatic", job="${labels.job}", asserts_request_type="${labels.asserts_request_type || ''}", asserts_request_context="${labels.asserts_request_context || ''}"}[10m]))
    or ignoring(asserts_threshold_level)
    max by(asserts_threshold_level)(label_replace(asserts:latency:average:threshold{asserts_threshold_level="", job="", asserts_request_type="", asserts_request_context=""}, "asserts_threshold_level", "system-default", "", ""))`;
}


export function getThreholdValueQueryForResource(labels: Record<string, string> & { asserts_resource_type: string }) {
  return `max by(asserts_threshold_level, asserts_severity)(asserts:resource:threshold{asserts_resource_type="${labels.asserts_resource_type}", asserts_threshold_level="source_topic_job", job="${labels.job||''}", asserts_source="${labels.asserts_source || ''}", topic="${labels.topic}"})
  or ignoring(asserts_threshold_level)
  max by(asserts_threshold_level, asserts_severity)(asserts:resource:threshold{asserts_resource_type="${labels.asserts_resource_type}", asserts_threshold_level="source_container_job", job="${labels.job||''}", asserts_source="${labels.asserts_source || ''}", container="${labels.container}"})
  or ignoring(asserts_threshold_level)
  max by(asserts_threshold_level, asserts_severity)(asserts:resource:threshold{asserts_resource_type="${labels.asserts_resource_type}", asserts_threshold_level="source_container", asserts_source="${labels.asserts_source || ''}", container="${labels.container}"})
  or ignoring(asserts_threshold_level)
  max by(asserts_threshold_level, asserts_severity)(asserts:resource:threshold{asserts_resource_type="${labels.asserts_resource_type}", asserts_threshold_level="source", asserts_source="${labels.asserts_source || ''}"})
  or ignoring(asserts_threshold_level)
  max by(asserts_threshold_level, asserts_severity)(asserts:resource:threshold{asserts_resource_type="${labels.asserts_resource_type}", asserts_threshold_level="automatic", asserts_source="${labels.asserts_source || ''}", job="${labels.job||''}"})
  or ignoring(asserts_threshold_level)
  max by(asserts_threshold_level, asserts_severity)(label_replace(asserts:resource:threshold{asserts_resource_type="${labels.asserts_resource_type}", asserts_threshold_level="", job="", asserts_source="", container="", job="", topic=""}, "asserts_threshold_level", "system-default", "", ""))`;
}
