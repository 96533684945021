/**
 *
 * CustomDashboardCard
 *
 */

import React, { memo, FunctionComponent, useState } from 'react';
import EditIcon from 'assets/material-icons/edit_FILL0_wght400_GRAD0_opsz24.svg';
import CheckIcon from 'assets/material-icons/done_FILL0_wght400_GRAD0_opsz24.svg';
import CloseIcon from 'assets/material-icons/close_FILL0_wght400_GRAD0_opsz24.svg';
import DeleteOutlineIcon from 'assets/material-icons/delete_FILL0_wght400_GRAD0_opsz24.svg';
import messages from './messages';
import { useIntl } from 'react-intl';
import { DashboardConfig } from 'asserts-types';
import TooltipConfirmationComponent from 'features/Assertions/components/TooltipConfirmation/TooltipConfirmation.component';
import useGrafanaSearch from 'hooks/useGrafanaSearch';
import { useDeleteCustomDashboard } from 'features/CustomDashboards/hooks/useDeleteCustomDashboard';
import { BracesPlugin, QueryField, SlatePrism, Switch } from '@grafana/ui';
import { saveCustomDashboard } from 'services/CustomDashboard.service';
import { useCustomDashboards } from 'features/CustomDashboards/hooks/useCustomDashboards';
import FormControlLabel from 'components/FormControlLabel/FormControlLabel.component';
import { IconButton } from 'components/IconButton/IconButton.component';

interface IProps {
  customDash: DashboardConfig & { entityType: string };
}

const CustomDashboardCard: FunctionComponent<IProps> = ({ customDash }) => {
  const intl = useIntl();
  const [updating, setUpdating] = useState(false);
  const [editing, setEditing] = useState(false);
  const [queryField, setQueryField] = useState(customDash.query);
  const [primaryField, setPrimaryField] = useState(customDash.overrideEntityUri);

  const { refetch } = useCustomDashboards();

  const handleSave = () => {
    setUpdating(true);
    const itemToSave = {
      ...customDash,
      query: queryField,
      overrideEntityUri: primaryField,
    };
    saveCustomDashboard(customDash.entityType, itemToSave)
      .then(() => {
        refetch();
      })
      .finally(() => {
        setEditing(false);
        setUpdating(false);
      });
  };

  const { data: dashboards, isFetching: isFetchingDashboards } = useGrafanaSearch({ query: '', type: 'dash-db' });

  const { mutate: deleteCustomDashboard, isLoading: isDeleting } = useDeleteCustomDashboard();

  return (
    <tr className="hover:bg-hover">
      <td className="p-4 divider-b">
        {isFetchingDashboards
          ? 'Loading...'
          : dashboards?.find((o) => o.url.toString().endsWith(customDash.dashboardUri))?.title || 'Not found'}
      </td>
      <td className="p-4 divider-b">
        {editing ? (
          <FormControlLabel
            control={<Switch value={primaryField} onChange={() => setPrimaryField(!primaryField)} />}
            label={intl.formatMessage(messages.makeAsPrimary)}
          />
        ) : (
          String(customDash.overrideEntityUri)
        )}
      </td>
      <td className="p-4 divider-b">
        {!editing ? (
          customDash.query
        ) : (
          <QueryField
            additionalPlugins={[
              BracesPlugin(),
              SlatePrism({
                onlyIn: (node: any) => node.type === 'code_block',
                getSyntax: () => 'promql',
              }),
            ]}
            portalOrigin="."
            placeholder="Enter query"
            onChange={(v) => setQueryField(v)}
            query={queryField}
            disabled={updating || isDeleting}
          />
        )}
      </td>
      <td className="p-4 divider-b">{customDash.entityType}</td>
      <td className="p-4 divider-b">
        <div className="flex items-center">
          {editing ? (
            <>
              <IconButton variant="primary" disabled={updating || isDeleting} onClick={handleSave}>
                <CheckIcon />
              </IconButton>
              <IconButton
                variant="primary"
                onClick={() => {
                  setQueryField(customDash.query);
                  setEditing(false);
                }}
                disabled={updating || isDeleting}
              >
                <CloseIcon />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                variant="primary"
                onClick={(e) => {
                  e.preventDefault();
                  setEditing(true);
                }}
                disabled={updating || isDeleting}
              >
                <EditIcon />
              </IconButton>
              <TooltipConfirmationComponent action={() => deleteCustomDashboard(customDash)}>
                <IconButton disabled={updating || isDeleting} variant="destructive">
                  <DeleteOutlineIcon />
                </IconButton>
              </TooltipConfirmationComponent>
            </>
          )}
        </div>
      </td>
    </tr>
  );
};

export default memo(CustomDashboardCard);
