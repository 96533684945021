import useDebounceValue from '../../../hooks/useDebounceValue';
import { SloType } from 'asserts-types';

interface IProps {
  allEventsQuery: string | undefined;
  badEventsQuery: string | undefined;
  measurementQuery: string | undefined;
  isGauge: boolean | undefined;
  kind: SloType;
}

export default function useQueryForPreview({
  kind,
  isGauge,
  measurementQuery,
  badEventsQuery,
  allEventsQuery,
}: IProps) {
  const debouncedMeasurementQuery = useDebounceValue(measurementQuery, 300);
  const debouncedAllEventsQuery = useDebounceValue(allEventsQuery, 300);
  const debouncedBadEventsQuery = useDebounceValue(badEventsQuery, 300);

  let queryForPreview = '';

  if (kind === SloType.Occurrence && debouncedMeasurementQuery) {
    queryForPreview = `label_replace(max(${debouncedMeasurementQuery}), "stat", "max" , "", "") or label_replace(avg(${debouncedMeasurementQuery}), "stat", "avg", "", "")`;
  }

  if (kind === SloType.Request && debouncedAllEventsQuery && debouncedBadEventsQuery) {
    queryForPreview = `100 * ( 1 - (sum by(asserts_env, asserts_site, namespace, job) (rate(${debouncedBadEventsQuery}[5m])) or sum by(asserts_env, asserts_site, namespace, job) (rate(${debouncedAllEventsQuery}[5m])*0))/ sum by(asserts_env, asserts_site, namespace, job) (rate(${debouncedAllEventsQuery}[5m]) > 0))`;
  }

  if (kind === SloType.Request && isGauge && debouncedBadEventsQuery && debouncedAllEventsQuery) {
    queryForPreview = `100 * ( 1 - (sum by(asserts_env, asserts_site, namespace, job) (sum_over_time(${debouncedBadEventsQuery}[5m])) or sum by(asserts_env, asserts_site, namespace, job) (sum_over_time(${debouncedAllEventsQuery}[5m])*0)) / sum by(asserts_env, asserts_site, namespace, job) (sum_over_time(${debouncedAllEventsQuery}[5m]) > 0))`;
  }

  return queryForPreview;
}
