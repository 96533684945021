/**
 *
 * ChartLegendItem
 *
 */

import React, { memo, FunctionComponent } from 'react';
import { LegendConfigItem } from 'asserts-types';
import { Tooltip } from 'components/Tooltip/Tooltip.component';

const MAX_LEGEND_TITLE_LENGTH = 40;


interface IProps {
  name: string;
  shape: LegendConfigItem['shape'];
  color: string;
}

const ChartLegendItem: FunctionComponent<IProps> = ({ name, shape, color }) => {
  const isTruncated = name.length > MAX_LEGEND_TITLE_LENGTH;
  const processedName = isTruncated ? name.slice(0, 10) + '...' : name;

  const shapeMap = {
    line: {
      className: 'w-[14px] h-[3px] rounded-[1px] border-0 mr-[9px]',
      style: { background: color },
    },
    area: {
      className: 'w-[14px] h-[14px] rounded-[1px] border-0 mr-[9px]',
      style: { background: color },
    },
    dash: {
      className: 'w-[14px] h-[1px] mr-[9px]',
      style: { border: `1px dashed ${color}` },
    },
    triangle: {
      className:
        'mr-[7px] bg-transparent border-l-[5px] border-r-[5px] border-transparent border-b-[5px] border-b-transparent border-solid',
      style: { borderBottomColor: color },
    },
  };

  return (
    <>
      <div className={shapeMap[shape].className} style={shapeMap[shape].style} />
      {isTruncated ? (
        <Tooltip content={name}>
          <div className="h-[14px]">{processedName}</div>
        </Tooltip>
      ) : (
        <div className="h-[14px]">{processedName}</div>
      )}
    </>
  );
};

export default memo(ChartLegendItem);
