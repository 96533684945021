/*
 * SloTopMenu Messages
 *
 * This contains all the text for the SloTopMenu component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.SloTopMenu';

export default defineMessages({
  showSlo: {
    id: `${scope}.showSlo`,
    defaultMessage: 'Show SLOs',
  },
  showIncident: {
    id: `${scope}.showIncident`,
    defaultMessage: 'Show Incidents',
  },
  search: {
    id: `${scope}.search`,
    defaultMessage: 'Search by name',
  },
  endTime: {
    id: `${scope}.endTime`,
    defaultMessage: 'Compliance Window End Time',
  },
  currentTime: {
    id: `${scope}.currentTime`,
    defaultMessage: 'Current Time',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'SLO List',
  },
});
