import { stringToDate } from '../../../helpers/Date.helper';
import { useDispatch } from 'react-redux';
import { clearWorkbench, setWorkbenchEntities } from '../Assertions.slice';
import { useAppSelector } from 'app/store';
import { useQuery } from '@tanstack/react-query';
import { fetchTopEntities } from 'services/Assertion.service';

export default function useTopServices() {
  const dispatch = useDispatch();
  const start = useAppSelector((state) => state.app.start);
  const end = useAppSelector((state) => state.app.end);

  return useQuery(
    ['assertions-top-services', start, end],
    () =>
      fetchTopEntities(
        stringToDate(start).valueOf(),
        stringToDate(end).valueOf(),
      ),
    {
      enabled: false,
      staleTime: 0,
      onSuccess: (data) => {
        dispatch(clearWorkbench());
        dispatch(setWorkbenchEntities(data));
      },
    },
  );
}
