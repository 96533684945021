import { useMutation } from '@tanstack/react-query';
import { KpiDisplayConfig } from 'asserts-types';
import SnackbarHelper from 'helpers/Snackbar.helper';
import TrackingHelper from 'helpers/Tracking.helper';
import { updateKpiDisplayConfig } from 'services/App.service';

export function useMutateKpiDisplayConfig() {
  return useMutation({
    mutationFn: (displayConfig: KpiDisplayConfig) => {
      return updateKpiDisplayConfig(displayConfig);
    },
    onSuccess: (_, displayConfig) => {
      TrackingHelper.trackKpiDisplayConfigUpdated(displayConfig);
      SnackbarHelper.success('Display config updated');
    },
    onError: (res: any) => {
      SnackbarHelper.error(`Display config update failed: ${res?.message}`);
    },
  });
}
