/**
 *
 * AssertionsSummaryGraph
 *
 */

import React, { memo, FunctionComponent, useEffect, useMemo, useState, useCallback } from 'react';
import GraphinGraphComponent from 'components/GraphinGraph/GraphinGraph.component';
import { AssertionSummary } from 'asserts-types';
import { useSelector } from 'react-redux';
import messages from './messages';
import { useIntl } from 'react-intl';
import Draggable from 'react-draggable';
import { Graph, LayoutConfig } from '@antv/g6';
import { cleanEdges } from 'helpers/Graph.helper';
import moment from 'moment';
import useAssertionsGraph from 'hooks/useAssertionsGraph';
import { useAppSelector } from 'app/store';
import { DEFAULT_FORCE_LAYOUT_OPTIONS } from 'global-constants';
import { Button, PanelContainer, useStyles2 } from '@grafana/ui';
import { debounce } from 'lodash';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import ConnectedEntitiesIcon from 'icons/ConnectedEntitiesIcon';

interface IProps {
  start: number | string;
  end: number | string;
  assertionsSummary: AssertionSummary[];
  currentDate: number;
}

const AFTER_LAYOUT_DONE_CONFIG: LayoutConfig = {
  nodeStrength: 1000,
};

const AssertionsSummaryGraph: FunctionComponent<IProps> = ({ start, end, assertionsSummary, currentDate }) => {
  const { data: graphData } = useAssertionsGraph({ start, end });
  const selectedTime = useAppSelector((state) => state.assertions.selectedTime);

  const showWithAssertions = useSelector((state: RootState) => state.assertions.showWithAssertions);

  const intl = useIntl();
  const [graphRef, setGraphRef] = useState<Graph>();
  const onRef = useCallback((c: Graph) => setGraphRef(c), []);
  const [expanded, setExpanded] = useState(false);

  const currentTimeNodes =
    graphData?.nodes
      .filter((node) =>
        showWithAssertions ? node.assertion?.assertions?.length || node.connectedAssertion?.assertions?.length : true
      )
      .map((node) => ({
        ...node,
        summaryMetrics:
          assertionsSummary
            .find((summaryItem) => summaryItem.hovered && node.label === summaryItem.name)
            ?.timeLines.filter((t) => t.hovered)
            .map((timeline) => ({
              ...timeline,
              nestedSummaries: timeline.nestedSummaries.filter((s) => s.hovered),
              healthStates: timeline.healthStates.filter(
                (hs) =>
                  hs.start <= (selectedTime || currentDate).valueOf() &&
                  hs.end >= (selectedTime || currentDate).valueOf()
              ),
            })) || [],
      })) || [];

  let currentTimeEdges = graphData?.edges || [];

  currentTimeEdges = cleanEdges(currentTimeEdges);

  // making sure all edges has source and target nodes
  currentTimeEdges = currentTimeEdges.filter((edge) => {
    const sourceNode = currentTimeNodes.find((item) => edge.source === item.id);
    const targetNode = currentTimeNodes.find((item) => edge.target === item.id);
    return sourceNode && targetNode;
  });

  const finalGraphData = { edges: currentTimeEdges, nodes: currentTimeNodes };

  const debouncedFitView = useMemo(() => debounce(() => graphRef?.fitView(), 1000), [graphRef]);

  useEffect(() => {
    debouncedFitView();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assertionsSummary]);

  const layout = { ...DEFAULT_FORCE_LAYOUT_OPTIONS, nodeSize: 150 };

  const handleToggleWindow = () => {
    setExpanded(!expanded);
  };

  const draggablePositon = expanded ? undefined : { x: 0, y: 0 };
  const styles = useStyles2(getStyles);

  if (!expanded) {
    return (
      <Button
        className={styles.openButton}
        variant="secondary"
        onClick={handleToggleWindow}
        icon={<ConnectedEntitiesIcon />}
      >
        <span className={styles.btnContent}>Open graph preview</span>
      </Button>
    );
  }

  return (
    <Draggable handle="#header-handle" bounds="parent" disabled={!expanded} position={draggablePositon}>
      <PanelContainer className={styles.container}>
        <div className={styles.header} id="header-handle">
          <div>Graph state as of {selectedTime ? moment(selectedTime).format('L LT') : moment(currentDate).format('L LT')}</div>
          <div>
            <Button onClick={handleToggleWindow} size="sm" icon="times" variant="secondary">
              Close
            </Button>
          </div>
        </div>

        <div className="relative grow">
          {!Boolean(finalGraphData?.nodes.length) && expanded && (
            <div className={styles.empty}>{intl.formatMessage(messages.empty)}</div>
          )}
          {Boolean(finalGraphData?.nodes.length) && expanded ? (
            <GraphinGraphComponent
              data={finalGraphData}
              disableContextMenu
              disableTooltip
              disableToolbar
              fitView
              layout={layout}
              options={{ fitViewPadding: 20, maxZoom: 1 }}
              onRef={onRef}
              afterLayoutConfig={AFTER_LAYOUT_DONE_CONFIG}
            />
          ) : null}
        </div>
      </PanelContainer>
    </Draggable>
  );
};

export default memo(AssertionsSummaryGraph);


const getStyles = (theme: GrafanaTheme2) => ({
  openButton: css`
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1000;
    background-color: ${theme.colors.background.secondary};
    &:hover {
      background-color: ${theme.colors.background.secondary};
    }
  `,
  container: css`
    width: 500px;
    height: 400px;
    display: flex;
    flex-direction: column;
    overflow: hidden !important;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1000;
    box-shadow: ${theme.shadows.z3};
  `,
  header: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${theme.colors.background.secondary};
    height: 44px;
    padding-left: 15px;
    padding-right: 10px;
    cursor: move;
    border-bottom: 1px solid ${theme.colors.border.weak};
  `,
  empty: css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${theme.colors.background.canvas};
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.text.secondary};
  `,
  content: css`
    position: relative;
    flex-grow: 1;
  `,
  btnContent: css`
    margin-left: ${theme.spacing(1)};
  `,
});
