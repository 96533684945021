import { useEffect } from 'react';
import { useAssertionsQueryParams } from './useAssertionsQueryParams';
import { useAppSelector } from 'app/store';
import useBackButtonPressed from 'hooks/useBackButtonPressed';
import { orderBy } from 'lodash';
import { setQueryParamsWereRead } from 'features/App/App.slice';
import { useDispatch } from 'react-redux';

export default function useAssertionsHistoryChanges() {
  const dispatch = useDispatch();

  const workbenchEntities = useAppSelector((state) =>
    JSON.stringify(orderBy(state.assertions.workbenchEntities, 'name'))
  );
  const viewType = useAppSelector((state) => state.assertions.viewType);
  const search = useAppSelector((state) => state.assertions.search);
  const start = useAppSelector((state) => state.app.start);
  const end = useAppSelector((state) => state.app.end);

  const env = useAppSelector((state) => state.app.selectedEnv.slice().sort().join(','));
  const site = useAppSelector((state) => state.app.selectedSite.slice().sort().join(','));
  const activeEntityDetails = useAppSelector((state) => state.app.activeEntityDetails);
  const queryParamsWereRead = useAppSelector((state) => state.app.queryParamsWereRead);

  const { readQueryParams, setQueryStringUrl } = useAssertionsQueryParams();

  useEffect(() => {
    // initial read of query params
    const url = new URL(window.location.href);
    if (!queryParamsWereRead && url.searchParams.size > 0) {
      readQueryParams();
    }
    // if no query params in url, set them and switch mode to using store as a source of truth
    // this is needed for the case when user navigates to the page with no query params and we want reflect the app state in the url after navigation
    if (!queryParamsWereRead && url.searchParams.size === 0) {
      dispatch(setQueryParamsWereRead(true));
      setQueryStringUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useBackButtonPressed(readQueryParams);

  useEffect(() => {
    // updating url query params
    if (queryParamsWereRead) {
      setQueryStringUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workbenchEntities, search, start, end, env, site, viewType, activeEntityDetails]);
}
