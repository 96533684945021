import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  EntityContainerState,
  Entity,
  Order,
  KpiConfig,
  KpiSummary,
  TypeScopeFilter,
  MonitoringStatus,
  EntitiesTab,
  EntitiesQueryParams,
  EntityAdvancedSearchFormEntityItem,
  EntityFilterPropertyMatcher,
  EntityFilterCriteria,
  NumberRules,
  StringRules,
  EntityPropertyTypes,
  EntitySearchObject,
  Definition,
  TopDefinition,
} from 'asserts-types';
import GraphHelper from '../../helpers/Graph.helper';

import { isEqual } from 'lodash';
import { DEFAULT_TOP_DEFINITION } from './constants';

let nextMatcherId = 0;

const initialState: EntityContainerState = {
  searchObject: {
    // filterCriteria: parsedSearchDefinition?.filterCriteria || [],
    filterCriteria: [],
  },
  selectedOnly: false,
  searchName: '',
  entityListColumns: [],
  typeFilter: [],
  graphTypeScopeFilter: [],
  activeEntity: undefined,
  activeNode: undefined,
  graphPadding: [0, 0, 0, 0],
  history: [],
  searchDefinition: null,
  nameSearchQuery: '',
  showRelationships: false,
  showNodeNames: true,
  showFullNodeNameText: true,
  showCallRates: true,
  highlightSelected: true,
  order: 'desc',
  orderBy: 'assertionCount',
  activeView: 'graph',
  showAdvancedSearch: false,
  topSearches: DEFAULT_TOP_DEFINITION,
  bubbleViewPanels: [],
  kpiSettings: { kpiGroups: [], kpisByEntityType: [] },
  propByKpis: {},
  monitoringStatus: [],
  selectedKpi: [],
  search: 'Show all Services',
};

export const slice = createSlice({
  name: 'entities',

  initialState,

  reducers: {
    resetHistory: (state) => {
      state.history = [];
    },

    setBubbleViewActiveNodeName: (state, action: PayloadAction<string | undefined>) => {
      state.bubbleViewActiveNodeName = action.payload;
    },

    setKpiSettings: (state, action: PayloadAction<KpiConfig>) => {
      state.kpiSettings = action.payload;
    },

    setGraphTypeScopeFilter: (state, action: PayloadAction<TypeScopeFilter[]>) => {
      state.graphTypeScopeFilter = action.payload;
    },

    toggleGraphTypeScopeFilter: (state, action: PayloadAction<TypeScopeFilter>) => {
      if (state.graphTypeScopeFilter?.find((f) => isEqual(f, action.payload))) {
        state.graphTypeScopeFilter = state.graphTypeScopeFilter.filter((f) => !isEqual(f, action.payload));
      } else {
        state.graphTypeScopeFilter?.push(action.payload);
      }
    },

    reset: () => initialState,

    setOrder: (state, action: PayloadAction<Order>) => {
      state.order = action.payload;
    },

    setActiveView: (state, action: PayloadAction<EntitiesTab>) => {
      state.activeView = action.payload;
    },

    setShowAdvancedSearch: (state, action: PayloadAction<boolean>) => {
      state.showAdvancedSearch = action.payload;
    },

    setOrderBy: (state, action: PayloadAction<string>) => {
      state.orderBy = action.payload;
    },

    historyNavigate: (state, action: PayloadAction<number | undefined>) => {
      const historyIndex = action.payload;
      let newHistory: Entity[] = [];

      if (typeof historyIndex === 'number') {
        newHistory = state.history.slice(historyIndex + 1);
      } else {
        newHistory = state.history.slice(0, -1);
      }

      state.history = newHistory;

      state.activeEntity = newHistory[newHistory.length - 1];

      state.nameSearchQuery = '';
    },

    setActiveEntity: (state, action: PayloadAction<{ entity: Entity | undefined }>) => {
      const { entity } = action.payload;
      state.activeEntity = entity;
      state.nameSearchQuery = '';

      if (entity) {
        state.history.push(entity);
      }
    },

    setActiveNode: (state, action: PayloadAction<{ entity: Entity | undefined }>) => {
      const { entity } = action.payload;

      if (entity) {
        state.activeNode = GraphHelper.convertToNode(entity);
      } else {
        state.activeNode = undefined;
      }
    },

    setGraphPadding: (
      state,
      action: PayloadAction<{
        top?: number;
        right?: number;
        bottom?: number;
        left?: number;
      }>
    ) => {
      const { top, right, bottom, left } = action.payload;
      state.graphPadding = [
        top !== undefined ? top : state.graphPadding[0],
        right !== undefined ? right : state.graphPadding[1],
        bottom !== undefined ? bottom : state.graphPadding[2],
        left !== undefined ? left : state.graphPadding[3],
      ];
    },

    toggleEntityColumn: (state, action: PayloadAction<string>) => {
      const entityColumnName = action.payload;
      if (state.entityListColumns.indexOf(entityColumnName) === -1) {
        state.entityListColumns.push(entityColumnName);
      } else {
        state.entityListColumns = state.entityListColumns.filter((item) => item !== entityColumnName);
      }
    },

    toggleBubbleViewPanel: (state, action: PayloadAction<string>) => {
      const panelName = action.payload;
      if (!state.bubbleViewPanels.includes(panelName)) {
        state.bubbleViewPanels.push(panelName);
      } else {
        state.bubbleViewPanels = state.bubbleViewPanels.filter((item) => item !== panelName);
      }
    },

    toggleSelectedKpi: (state, action: PayloadAction<string>) => {
      const kpi = action.payload;
      if (!state.selectedKpi.includes(kpi)) {
        state.selectedKpi.push(kpi);
      } else {
        state.selectedKpi = state.selectedKpi.filter((item) => item !== kpi);
      }
    },

    setSelectedKpi: (state, action: PayloadAction<string[]>) => {
      state.selectedKpi = action.payload;
    },

    setBubbleViewPanels: (state, action: PayloadAction<string[]>) => {
      state.bubbleViewPanels = action.payload;
    },

    toggleTypeFilter: (state, action: PayloadAction<string>) => {
      const filterName = action.payload;

      if (state.typeFilter.indexOf(filterName) === -1) {
        state.typeFilter.push(filterName);
      } else {
        state.typeFilter = state.typeFilter.filter((item) => item !== filterName);
      }
    },

    setTypeFilter: (state, action: PayloadAction<string[]>) => {
      state.typeFilter = action.payload;
    },

    setEntityListColumns: (state, action: PayloadAction<string[]>) => {
      state.entityListColumns = action.payload;
    },

    setNameSearchQuery: (state, action: PayloadAction<string>) => {
      state.nameSearchQuery = action.payload;
    },

    setSearchDefinition: (state, action: PayloadAction<Omit<Definition, 'id'> | null>) => {
      state.searchDefinition = action.payload;

      state.search = undefined;

      if (action.payload?.filterCriteria) {
        state.searchObject = { filterCriteria: action.payload?.filterCriteria };
      }
    },

    setShowRelationships: (state, action: PayloadAction<boolean>) => {
      state.showRelationships = action.payload;
    },

    setShowNodeNames: (state, action: PayloadAction<boolean>) => {
      state.showNodeNames = action.payload;
    },

    setShowFullNodeNameText: (state, action: PayloadAction<boolean>) => {
      state.showFullNodeNameText = action.payload;
    },

    setShowCallRates: (state, action: PayloadAction<boolean>) => {
      state.showCallRates = action.payload;
    },

    setHighlightSelected: (state, action: PayloadAction<boolean>) => {
      state.highlightSelected = action.payload;
    },

    addToTopSearches: (state, action: PayloadAction<string>) => {
      const definition = action.payload;
      const search = state.topSearches?.find((s) => s.boundDescription === definition);
      if (search && search.rank) {
        search.rank++;
      } else {
        const topDefinition: TopDefinition = Object.assign({ rank: 1 }, { boundDescription: definition });
        state.topSearches.unshift(topDefinition);
      }
    },

    deleteTopSearch: (state, action: PayloadAction<TopDefinition>) => {
      const definition = action.payload;
      state.topSearches = state.topSearches?.filter((s) => s.boundDescription !== definition.boundDescription);
    },

    setKpisByPropName: (
      state,
      action: PayloadAction<{
        propName: string;
        kpis: Record<string, KpiSummary>;
      }>
    ) => {
      const { propName, kpis } = action.payload;
      state.propByKpis[propName] = kpis;
    },
    setMonitoringStatus: (state, action: PayloadAction<MonitoringStatus[]>) => {
      state.monitoringStatus = action.payload;
    },
    fillSliceWithQueryParams: (state, action: PayloadAction<EntitiesQueryParams>) => {
      const queryParams = action.payload;

      if (queryParams.bvp) {
        state.bubbleViewPanels = queryParams.bvp;
      }
      if (queryParams.view) {
        state.activeView = queryParams.view;
      }
      if (queryParams.ec) {
        state.entityListColumns = queryParams.ec;
      }
      if (queryParams.kc) {
        state.selectedKpi = queryParams.kc;
      }

      if (queryParams.search) {
        state.search = queryParams.search;
      }

      if (queryParams.definitionId && queryParams.boundDescription) {
        state.search = undefined;

        const { definitionId, bindings, boundDescription, filterCriteria } = queryParams;

        state.searchDefinition = {
          bindings: bindings || {},
          definitionId,
          boundDescription,
          filterCriteria: filterCriteria?.map((item) =>
            !item.propertyMatchers ? { ...item, propertyMatchers: [] } : (item as EntityFilterCriteria)
          ),
        };
      }

      if (queryParams.filterCriteria) {
        state.search = undefined;
        state.searchObject = {
          filterCriteria: queryParams.filterCriteria.map((item) =>
            !item.propertyMatchers ? { ...item, propertyMatchers: [] } : (item as EntityFilterCriteria)
          ),
        };
      }

      if (!queryParams.filterCriteria && !queryParams.definitionId && !queryParams.boundDescription) {
        state.searchDefinition = undefined;
        state.searchObject = { filterCriteria: [] };
      }
    },
    setSearch: (state, action: PayloadAction<string | undefined>) => {
      state.search = action.payload;
    },
    clearEntities: (state) => {
      state.activeEntity = undefined;
      state.searchDefinition = undefined;
      state.bubbleViewPanels = [];
      state.selectedKpi = [];
      state.entityListColumns = [];
      state.search = undefined;
      state.nameSearchQuery = '';
      state.searchName = '';
      state.searchObject = { filterCriteria: [] };
      state.selectedOnly = false;
      state.history = [];
    },
    setSearchObject: (state, action: PayloadAction<EntitySearchObject>) => {
      state.searchObject = {
        filterCriteria: action.payload.filterCriteria.map((propertySearchItem) => ({
          ...propertySearchItem,
          havingAssertion:
            // this check should be here because of reading this from query params in Entities.container
            typeof propertySearchItem.havingAssertion === 'string'
              ? propertySearchItem.havingAssertion === 'true'
              : Boolean(propertySearchItem.havingAssertion),
          propertyMatchers: propertySearchItem.propertyMatchers
            ? propertySearchItem.propertyMatchers.map((matcher) => {
                let { value } = matcher;
                if (matcher.type === EntityPropertyTypes.DOUBLE && !isNaN(parseFloat(value as string))) {
                  value = parseFloat(value as string);
                }
                return {
                  ...matcher,
                  id: matcher.id || ++nextMatcherId,
                  value,
                };
              })
            : [],
        })),
      };

      if (!action.payload.filterCriteria.length) {
        state.selectedOnly = false;
        state.searchName = '';
      }
    },

    addSearchProperty: (
      state,
      action: PayloadAction<{
        formItem: EntityAdvancedSearchFormEntityItem;
        propertyName: string;
      }>
    ) => {
      // we need to clear definition search if user touched advanced search
      if (state.searchDefinition && !state.searchDefinition.filterCriteria) {
        state.searchDefinition = undefined;
      }
      const { propertyName, formItem } = action.payload;

      const propertyType = formItem.properties.find((item) => item.name === propertyName)?.type;

      const propertyUom = formItem.properties.find((item) => item.name === propertyName)?.uom;

      const rule: EntityFilterPropertyMatcher = {
        id: +new Date(),
        op: propertyType === EntityPropertyTypes.DOUBLE ? NumberRules.EQUALS : StringRules.CONTAINS,
        value: '',
        name: propertyName,
        type: propertyType || EntityPropertyTypes.STRING,
        uom: propertyUom,
      };

      const itemToChange = state.searchObject.filterCriteria.find((a) => a.entityType === formItem.entityType);

      if (itemToChange) {
        itemToChange.propertyMatchers.push(rule);
      } else {
        state.searchObject.filterCriteria.push({
          entityType: formItem.entityType,
          propertyMatchers: [rule],
        });
      }
    },

    deleteSearchProperty: (
      state,
      action: PayloadAction<{
        formItem: EntityAdvancedSearchFormEntityItem;
        propertyName: string;
      }>
    ) => {
      const { propertyName, formItem } = action.payload;

      const itemToChange = state.searchObject.filterCriteria.find((a) => a.entityType === formItem.entityType);

      if (itemToChange) {
        itemToChange.propertyMatchers = itemToChange.propertyMatchers.filter((item) => item.name !== propertyName);
        if (
          !itemToChange.propertyMatchers.length &&
          !itemToChange.havingAssertion &&
          !itemToChange.connectToEntityTypes?.length
        ) {
          state.searchObject.filterCriteria = state.searchObject.filterCriteria.filter(
            (item) => item.entityType !== formItem.entityType
          );
        }
      }
    },

    deleteRule: (
      state,
      action: PayloadAction<{
        id: number;
        formItem: EntityAdvancedSearchFormEntityItem;
      }>
    ) => {
      const { formItem, id } = action.payload;
      const itemToChange = state.searchObject.filterCriteria.find((a) => a.entityType === formItem.entityType);

      if (itemToChange) {
        itemToChange.propertyMatchers = itemToChange.propertyMatchers.filter((item) => item.id !== id);

        if (
          !itemToChange.propertyMatchers.length &&
          !itemToChange.havingAssertion &&
          !itemToChange.connectToEntityTypes?.length
        ) {
          state.searchObject.filterCriteria = state.searchObject.filterCriteria.filter(
            (item) => item.entityType !== formItem.entityType
          );
        }
      }
      state.searchObject.filterCriteria = state.searchObject.filterCriteria.filter(
        (item) => item.havingAssertion || item.connectToEntityTypes?.length || item.propertyMatchers.length
      );
    },

    changeSearchProperty: (
      state,
      action: PayloadAction<{
        formItem: EntityAdvancedSearchFormEntityItem;
        patchObj: Partial<EntityFilterCriteria>;
      }>
    ) => {
      // we need to clear definition search if user touched advanced search
      if (state.searchDefinition && !state.searchDefinition.filterCriteria) {
        state.searchDefinition = undefined;
      }
      const { formItem, patchObj } = action.payload;

      const itemToChange = state.searchObject.filterCriteria.find((a) => a.entityType === formItem.entityType);

      state.searchObject.filterCriteria = state.searchObject.filterCriteria.map((item) =>
        item.entityType === formItem.entityType ? { ...item, ...patchObj } : item
      );

      if (!itemToChange) {
        state.searchObject.filterCriteria.push({
          entityType: formItem.entityType,
          propertyMatchers: [],
          ...patchObj,
        });
      }
      state.searchObject.filterCriteria = state.searchObject.filterCriteria.filter(
        (item) => item.havingAssertion || item.connectToEntityTypes?.length || item.propertyMatchers.length
      );
    },

    changeSearchPropertyConnectedEntities: (
      state,
      action: PayloadAction<{
        formItem: EntityAdvancedSearchFormEntityItem;
        connectedName: string;
      }>
    ) => {
      const { formItem, connectedName } = action.payload;

      const itemToChange = state.searchObject.filterCriteria.find((a) => a.entityType === formItem.entityType);

      if (itemToChange && itemToChange.connectToEntityTypes) {
        if (itemToChange.connectToEntityTypes.indexOf(connectedName) === -1) {
          itemToChange.connectToEntityTypes.push(connectedName);
        } else {
          itemToChange.connectToEntityTypes = itemToChange.connectToEntityTypes.filter(
            (item) => item !== connectedName
          );
        }

        state.searchObject.filterCriteria = state.searchObject.filterCriteria.filter(
          (item) => item.havingAssertion || item.connectToEntityTypes?.length || item.propertyMatchers.length
        );
        return state;
      }

      if (itemToChange) {
        itemToChange.connectToEntityTypes = [connectedName];
      } else {
        state.searchObject.filterCriteria.push({
          entityType: formItem.entityType,
          propertyMatchers: [],
          connectToEntityTypes: [connectedName],
          havingAssertion: false,
        });
      }
      state.searchObject.filterCriteria = state.searchObject.filterCriteria.filter(
        (item) => item.havingAssertion || item.connectToEntityTypes?.length || item.propertyMatchers.length
      );
    },

    changeRule: (
      state,
      action: PayloadAction<{
        id: number;
        formItem: EntityAdvancedSearchFormEntityItem;
        patchObj: Partial<EntityFilterPropertyMatcher>;
      }>
    ) => {
      const { formItem, id, patchObj } = action.payload;

      const propToChange = state.searchObject.filterCriteria.find((a) => a.entityType === formItem.entityType);

      if (propToChange) {
        propToChange.propertyMatchers = propToChange.propertyMatchers.map((item) =>
          item.id === id ? { ...item, ...patchObj } : item
        );
      }
    },

    setSelectedOnly: (state, action: PayloadAction<boolean>) => {
      state.selectedOnly = action.payload;
    },
    setSearchName: (state, action: PayloadAction<string | undefined>) => {
      state.searchName = action.payload || initialState.searchName;
    },
  },
});

export const {
  setActiveEntity,
  setActiveNode,
  setTypeFilter,
  historyNavigate,
  toggleEntityColumn,
  setEntityListColumns,
  toggleTypeFilter,
  setNameSearchQuery,
  setSearchDefinition,
  reset,
  setShowRelationships,
  setShowNodeNames,
  setShowFullNodeNameText,
  setShowCallRates,
  setHighlightSelected,
  setOrder,
  setOrderBy,
  setActiveView,
  setShowAdvancedSearch,
  addToTopSearches,
  deleteTopSearch,
  setGraphPadding,
  toggleBubbleViewPanel,
  toggleSelectedKpi,
  setBubbleViewPanels,
  setKpiSettings,
  setKpisByPropName,
  setGraphTypeScopeFilter,
  resetHistory,
  setMonitoringStatus,
  setSelectedKpi,
  toggleGraphTypeScopeFilter,
  setBubbleViewActiveNodeName,
  fillSliceWithQueryParams,
  setSearch,
  clearEntities,
  setSearchObject,
  addSearchProperty,
  deleteSearchProperty,
  deleteRule,
  changeRule,
  changeSearchProperty,
  changeSearchPropertyConnectedEntities,
  setSelectedOnly,
  setSearchName,
} = slice.actions;

export default slice.reducer;
