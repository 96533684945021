import { Entity, GraphCustomData, GraphCustomNode } from 'asserts-types';
import GraphHelper from '../../../../../helpers/Graph.helper';
import { useAppSelector } from 'app/store';

interface IProps {
  activeEntity: Entity | undefined;
  graphData: GraphCustomData;
}
export default function useGraphDataHighlight({ activeEntity, graphData }: IProps) {
  const highlightSelected = useAppSelector((state) => state.entities.highlightSelected);

  let nodes: GraphCustomNode[] = [];

  if (highlightSelected && activeEntity) {
    const activeNodeId = GraphHelper.convertToNode(activeEntity)?.id;

    nodes = graphData.nodes.map((node) => {
      if (
        activeNodeId === node.id ||
        (graphData.edges.find(
          (e) =>
            (e.source === activeNodeId && e.target === node.id) || (e.target === activeNodeId && e.source === node.id)
        ) &&
          activeEntity.activeConnectedEntityType === node.entityType)
      ) {
        return { ...node, disabled: false };
      }
      return { ...node, disabled: true };
    });

    const edges = graphData.edges.map((edge) => {
      const sourceNode = nodes.find((item) => edge.source === item.id);
      const targetNode = nodes.find((item) => edge.target === item.id);

      return {
        ...edge,
        disabled: sourceNode?.disabled || targetNode?.disabled,
      };
    });

    return { nodes, edges };
  }

  return {
    nodes: graphData.nodes.map((n) => ({ ...n, disabled: false })),
    edges: graphData.edges.map((e) => ({ ...e, disabled: false })),
  };
}
