/*
 * AssertionsDirHoveringButtons Messages
 *
 * This contains all the text for the AssertionsDirHoveringButtons component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.AssertionsGraph';

export default defineMessages({
  showConnectedAssertions: {
    id: `${scope}.showConnectedAssertions`,
    defaultMessage: 'Show assertions',
  },
  reset: {
    id: `${scope}.reset`,
    defaultMessage: 'Reset Graph',
  },
});
