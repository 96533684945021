import {
  EntitiesQueryParams,
  IncidentsQueryParams,
} from 'asserts-types';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { stringToDate } from '../../../helpers/Date.helper';
import { useNavigate } from 'react-router-dom';
import { fillSliceWithQueryParams } from '../Incidents.slice';
import { useAppSelector } from 'app/store';
import { setTimeRange } from 'features/App/App.slice';
import { prefixRoute } from 'utils/utils.routing';
import { ROUTES } from 'global-constants';

export function useIncidentsQueryParams() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const start = useAppSelector((state) => state.app.start);
  const end = useAppSelector((state) => state.app.end);
  const incidentSearch = useAppSelector((state) => state.incidents.incidentSearch);

  // reads query string parameters and fill entities store with values and fill start and end
  const readQueryParams = (disableFillingState?: boolean) => {
    const queryParams = (qs.parse(
      window.location.search.slice(1),
    ) as unknown) as IncidentsQueryParams;

    // filling assertions store with values
    if (!disableFillingState) {
      dispatch(fillSliceWithQueryParams(queryParams));
    }

    // fill start and end (from entities.slice)
    if (queryParams?.start && queryParams.end && !disableFillingState) {
      const numberStart = Number(queryParams.start);
      const numberEnd = Number(queryParams.end);

      const parsedStart = numberStart
        ? moment(numberStart)
        : stringToDate(queryParams.start);

      const parsedEnd = numberEnd
        ? moment(numberEnd)
        : stringToDate(queryParams.end);

      if (parsedStart.isValid() && parsedEnd.isValid()) {
        dispatch(
          setTimeRange({
            start: numberStart ? parsedStart.valueOf() : queryParams.start,
            end: numberEnd ? parsedEnd.valueOf() : queryParams.end,
          }),
        );
      }
    }
    return queryParams;
  };

  const setQueryStringUrl = (
    overrideParams?: EntitiesQueryParams,
    noHistoryChange?: boolean,
  ) => {
    const params: IncidentsQueryParams = {
      start: start.toString(),
      end: end.toString(),
      search: incidentSearch,
    };

    const queryParams = qs.stringify(params);

    const route = prefixRoute(ROUTES.INCIDENTS);
    const url = new URL(window.location.href);
    const replace = url.searchParams.size === 0;

    // push only in case url changed
    if (`${window.location.origin}${route}?${queryParams}` !== window.location.href && !noHistoryChange) {
      navigate(`${route}?${queryParams}`, { state: { timestamp: Date.now() }, replace });
    }
    return queryParams;
  };

  const getQueryStringUrlFromAppState = () => setQueryStringUrl({}, true);

  const getParsedQueryParams = () => readQueryParams(true);

  return {
    readQueryParams,
    setQueryStringUrl,
    getQueryStringUrlFromAppState,
    getParsedQueryParams,
  };
}
