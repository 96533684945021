import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Box, LinkButton, Stack, Text, useStyles2 } from '@grafana/ui';
import React from 'react';

export function AlertingHomepageCallout() {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.container}>
      <Box paddingBottom={1} flex={0}>
        <Stack direction="row" justifyContent="center">
          <img src="/public/plugins/grafana-asserts-app/img/logo.svg" className={styles.logo} />
          <Text element="h4">Asserts</Text>
        </Stack>
      </Box>
      <Box flex={1}>Leverage a curated library of alert rules out of the box</Box>
      <Box flex={0}>
        <LinkButton href="/a/grafana-asserts-app" fill="text">
          Go to Asserts
        </LinkButton>
      </Box>
    </div>
  );
}

const getStyles = (theme: GrafanaTheme2) => ({
  container: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  }),
  logo: css({
    width: theme.spacing(3),
    height: theme.spacing(3),
  }),
});
