import React, { useMemo } from 'react';
import { LabelFilter } from 'asserts-types';
import { Control, Controller, useWatch } from 'react-hook-form';
import { Button, MultiSelect, Select, useStyles2 } from '@grafana/ui';
import { useLabelKeys } from 'hooks/useLabelKeys';
import { useLabelValues } from 'hooks/useLabelValues';
import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import { css } from '@emotion/css';

type Props<T> = {
  control: T extends {
    filters: LabelFilter[];
  }
    ? Control<T>
    : never;
  index: number;
  onRemove: () => void;
};

const operators = [
  { label: '=', value: '=', description: 'Equals' },
  { label: '!=', value: '!=', description: 'Not equals' },
  { label: '=~', value: '=~', description: 'Matches regex' },
  { label: '!~', value: '!~', description: 'Does not match regex' },
]; 

export function LabelsFilterItem<
  T extends {
    filters: LabelFilter[];
  }
>({ control, index, onRemove }: Props<T>) {
  const styles = useStyles2(getStyles);

  const filters = useWatch({ control, name: 'filters' });

  const { data: keys, isFetching: isKeysFetching } = useLabelKeys({ enabled: !!filters.length });

  const keysOptions: SelectableValue<string>[] = useMemo(
    () =>
      keys
        ?.filter((k) => !filters.find((f, i) => f.name === k.text && i !== index))
        .map((k) => ({ label: k.text, value: k.text })) || [],
    [keys, filters, index]
  );

  const { data: values, isFetching: isValuesFetching } = useLabelValues({
    enabled: true,
    key: filters[index].name,
  });

  const valuesOptions: SelectableValue<string>[] = useMemo(
    () => values?.map((v) => ({ label: v.text, value: v.text })) || [],
    [values]
  );
  return (
    <div className={styles.wrapper}>
      <Controller
        name={`filters.${index}.name`}
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            width="auto"
            placeholder={'Select label'}
            options={keysOptions}
            autoFocus={field.value === ''}
            onChange={(v) => field.onChange(v?.value)}
            isLoading={isKeysFetching}
            openMenuOnFocus={true}
          />
        )}
      />
      <Controller
        name={`filters.${index}.operator`}
        control={control}
        render={({ field }) => (
          <Select {...field} onChange={(v) => field.onChange(v?.value)} width="auto" options={operators} />
        )}
      />
      <Controller
        name={`filters.${index}.values`}
        control={control}
        render={({ field }) => (
          <MultiSelect
            {...field}
            width="auto"
            placeholder={'Select value'}
            options={valuesOptions}
            onChange={(values) => field.onChange(values?.map(({ value }) => value))}
            isLoading={isValuesFetching}
            openMenuOnFocus={true}
            allowCustomValue
          />
        )}
      />
      <Button
        icon="times"
        variant="secondary"
        onClick={() => onRemove()}
        className={styles.removeButton}
        title="Remove filter"
      ></Button>
    </div>
  );
}

const getStyles = (theme: GrafanaTheme2) => ({
  field: css({
    marginBottom: 0,
  }),
  wrapper: css({
    display: 'flex',
    '> *': {
      '&:not(:first-child)': {
        // Negative margin hides the double-border on adjacent selects
        marginLeft: -1,
      },

      '&:first-child': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },

      '&:last-child': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },

      '&:not(:first-child):not(:last-child)': {
        borderRadius: 0,
      },

      // Fix focus state zIndex issues
      position: 'relative',
      zIndex: 0,

      // Adjacent borders are overlapping, so raise children up when hovering etc
      // so all that child's borders are visible.
      '&:hover': {
        zIndex: 1,
      },

      '&:focus-within': {
        zIndex: 2,
      },
    },
  }),
  widthWhenOpen: css({
    minWidth: theme.spacing(16),
  }),
  removeButton: css({
    paddingLeft: theme.spacing(3 / 2),
    paddingRight: theme.spacing(3 / 2),
    borderLeft: 'none',
    // To not have button background and last select border intersect
    position: 'relative',
    left: '1px',
  }),
});
