import { defineMessages } from 'react-intl';

export const scope = 'components.ThresholdResourceForm';

export default defineMessages({
  assertion: {
    id: `${scope}.assertion`,
    defaultMessage: 'Assertion',
  },
  exporter: {
    id: `${scope}.exporter`,
    defaultMessage: 'Source',
  },
  container: {
    id: `${scope}.container`,
    defaultMessage: 'Container',
  },
  topic: {
    id: `${scope}.topic`,
    defaultMessage: 'Topic',
  },
  severity: {
    id: `${scope}.severity`,
    defaultMessage: 'Severity',
  },
  resourceType: {
    id: `${scope}.resourceType`,
    defaultMessage: 'Resource Type',
  },
  requestContext: {
    id: `${scope}.requestContext`,
    defaultMessage: 'Request Context',
  },
  value: {
    id: `${scope}.value`,
    defaultMessage: 'Value',
  },
  addNew: {
    id: `${scope}.addNew`,
    defaultMessage: 'Add new',
  },
  job: {
    id: `${scope}.job`,
    defaultMessage: 'Job',
  },
  addTitle: {
    id: `${scope}.addTitle`,
    defaultMessage: 'Add Resource Threshold',
  },
});
