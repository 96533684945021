import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

import { ConnectedProps, connect } from 'react-redux';
import { useIntl } from 'react-intl';
import messages from './messages';
import TopInsightsTopMenu from './components/TopInsightsTopMenu/TopInsightsTopMenu.component';
import { MAX_SEARCHES_COUNT } from './constants';
import { useNavigate } from 'react-router-dom';
import EntityDetailsContainer from '../EntityDetails/EntityDetails.container';
import { setWorkbenchEntities } from '../Assertions/Assertions.slice';
import TopInsightsItem from './components/TopInsightsItem/TopInsightsItem.component';
import useTopInsightsSearches from './hooks/useTopInsightsSearches';
import qs from 'qs';
import TopInsightsSlo from './components/TopInsightsSlo/TopInsightsSlo.component';
import TopInsightsIncidents from './components/TopInsightsIncidents/TopInsightsIncidents.component';
import AddTopInsightsSearchQueryComponent from './components/AddTopInsightsSearchQuery/AddTopInsightsSearchQuery.component';
import AddRounded from 'assets/material-icons/add_FILL0_wght400_GRAD0_opsz24.svg';
import { TopInsightQueryParams } from 'asserts-types';
import ExpandLineButtonComponent from 'components/ExpandLineButton/ExpandLineButton.component';
import { PluginPage } from '@grafana/runtime';
import EnvSiteSelectorsComponent from 'components/EnvSiteSelectors/EnvSiteSelectors.component';
import { setTimeRange } from 'features/App/App.slice';
import useTopInsightsHistoryChanges from './hooks/useTopInsightsHistoryChanges';
import { Tooltip } from 'components/Tooltip/Tooltip.component';
import { IconButton } from 'components/IconButton/IconButton.component';
import GlobalDateRangePicker from 'features/App/components/GlobalDateRangePicker/GlobalDateRangePicker';
import { Dropdown, Menu, ToolbarButton } from '@grafana/ui';
import TopInsightsViewSettingsComponent from './components/TopInsightsViewSettings/TopInsightsViewSettings.component';

const connector = connect(
  (state: RootState) => ({
    start: state.app.start,
    end: state.app.end,
    scaleType: state.topInsights.scaleType,
    activeEntityDetails: state.app.activeEntityDetails,
    selectedViewTypeMap: state.topInsights.selectedViewTypeMap,
    hideOldAssertions: state.assertions.hideOldAssertions,
  }),
  {
    setTimeRange,
    setWorkbenchEntities,
  }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

const TI_EXPANDED_STATS_KEY = 'topStatsExpanded';

const TopInsights: FunctionComponent<PropsFromRedux> = ({
  start,
  end,
  setTimeRange,
  scaleType,
  activeEntityDetails,
  selectedViewTypeMap,
  hideOldAssertions,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  // used for performance mark to exclude first render
  const isRendered = useRef(false);

  useTopInsightsHistoryChanges();

  const expandedBottomInfoFromStorage = JSON.parse(localStorage.getItem(TI_EXPANDED_STATS_KEY) || 'null');

  const [expandedBottomInfo, setExpandedBottomInfo] = useState(
    expandedBottomInfoFromStorage === null ? true : expandedBottomInfoFromStorage
  );
  const [addFormVisible, setAddFormVisible] = useState(false);

  const { topInsightsSearches } = useTopInsightsSearches();

  useEffect(() => {
    isRendered.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateRangeChange = (start: string | number, end: string | number) => {
    setTimeRange({ start, end });

    const queryParams: TopInsightQueryParams = {
      start: typeof start === 'string' ? start : start.valueOf().toString(),
      end: typeof end === 'string' ? end : end.valueOf().toString(),
    };

    navigate(`?${qs.stringify(queryParams)}`, { state: { timestamp: Date.now() } });
  };

  const handleToggleStatsClick = () => {
    localStorage.setItem(TI_EXPANDED_STATS_KEY, (!expandedBottomInfo).toString());
    setExpandedBottomInfo(!expandedBottomInfo);
  };

  return (
    <PluginPage
      actions={
        <div className="flex items-start gap-4">
          <Dropdown
            overlay={
              <Menu>
                <div onClick={(e) => e.stopPropagation()} className="p-4">
                  <TopInsightsViewSettingsComponent />
                </div>
              </Menu>
            }
          >
            <ToolbarButton variant="canvas" icon="cog" isHighlighted={hideOldAssertions} />
          </Dropdown>
          <EnvSiteSelectorsComponent start={start} end={end} />
          <GlobalDateRangePicker showRefreshButton />
        </div>
      }
      subTitle=""
    >
      <div className="h-full flex flex-col">
        {/* <TopInsightsTopMenu /> */}
        <div className="relative overflow-y-auto pb-8 grow h-0">
          <div className="grid grid-cols-2 my-8 gap-8">
            <TopInsightsSlo expanded={expandedBottomInfo} endTime={end} />
            <TopInsightsIncidents expanded={expandedBottomInfo} start={start} end={end} />
          </div>
          <ExpandLineButtonComponent onClick={handleToggleStatsClick} direction={expandedBottomInfo ? 'up' : 'down'} />
          <div className="flex items-center gap-4 -mb-4 mt-8">
            <p className="text-base font-bold">{intl.formatMessage(messages.myQueries)}</p>
            <Tooltip
              content={intl.formatMessage(messages.add, {
                count: MAX_SEARCHES_COUNT,
              })}
              placement="top"
            >
              <IconButton
                data-tour-target="add-top-insights-query-btn"
                data-cy="add-top-insights-query-btn"
                onClick={() =>
                  topInsightsSearches.length === MAX_SEARCHES_COUNT ? undefined : setAddFormVisible(true)
                }
                disabled={topInsightsSearches.length === MAX_SEARCHES_COUNT}
              >
                <AddRounded fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
          {addFormVisible && <AddTopInsightsSearchQueryComponent closeForm={() => setAddFormVisible(false)} />}
          {topInsightsSearches.map((searchQuery, index) => (
            <TopInsightsItem
              key={searchQuery}
              searchQuery={searchQuery}
              start={start}
              end={end}
              scaleType={scaleType}
              handleDateRangeChange={handleDateRangeChange}
              viewType={selectedViewTypeMap[searchQuery]}
            />
          ))}
        </div>
      </div>
      {activeEntityDetails && <EntityDetailsContainer />}
    </PluginPage>
  );
};

export default connector(TopInsights);
