/*
 * AddCustomDashboard Messages
 *
 * This contains all the text for the AddCustomDashboard component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.AddCustomDashboard';

export default defineMessages({
  entityType: {
    id: `${scope}.entityType`,
    defaultMessage: 'Entity Type',
  },
  datasourceName: {
    id: `${scope}.datasourceName`,
    defaultMessage: 'Datasource Name',
  },
  dashboardURI: {
    id: `${scope}.dashboardURI`,
    defaultMessage: 'Dashboard Name',
  },
  dashboardURIUnique: {
    id: `${scope}.dashboardURIUnique`,
    defaultMessage: 'Dashboard Name should be unique',
  },
  prometheusQuery: {
    id: `${scope}.prometheusQuery`,
    defaultMessage: 'Query to link Dashboard to Entity Type',
  },
  addNew: {
    id: `${scope}.addNew`,
    defaultMessage: 'Add New',
  },
  metrics: {
    id: `${scope}.metrics`,
    defaultMessage: 'Metrics',
  },
  makeAsPrimary: {
    id: `${scope}.makeAsPrimary`,
    defaultMessage: 'Make as Primary Dashboard',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Add Custom Dashboard',
  },
});
