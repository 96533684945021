/**
 *
 * ZoomableLineRangeChart
 *
 */

import React, { memo, FunctionComponent, useEffect, useRef, useCallback, useState } from 'react';
import LineRangeChart, { LineRangeChartProps } from '../LineRangeChart/LineRangeChart';
import { assertsColors } from '../../app/constants';
import { useIntl } from 'react-intl';
import messages from './messages';
import { DEFAULT_CHART_PADDING } from '../LineRangeChart/constants';
import { orderBy } from 'lodash';
import { INCIDENTS_MINIMUM_WIDTH } from 'global-constants';
import { SloIncident } from 'asserts-types';
import { Tooltip } from 'components/Tooltip/Tooltip.component';

const defaultLegendHeight = 25;

interface IProps {
  setZoomedXPos?: (pos: { startX: number; endX: number }) => void;
  zoomToLastValueUpperThreshold?: boolean;
  zoomedXPos: {
    startX: number;
    endX: number;
  };
  left?: number;
  setLeft: (n: number) => void;
  width: number;
  setWidth: (n: number) => void;
  zoomableRangeTooltip?: string | React.ReactElement;
  sloIncidents?: SloIncident;
  scrollPosition: number;
  openWorkbench: (e: React.MouseEvent<HTMLSpanElement>, forceStart?: number, forceEnd?: number) => void;
}

const ZoomableLineRangeChart: FunctionComponent<LineRangeChartProps & IProps> = ({
  data,
  thresholds,
  padding,
  minX,
  maxX,
  showLegend,
  type,
  disableGrid,
  timeStepInterval,
  nullAsZero,
  fillOpacity,
  crossingRanges,
  setZoomedXPos,
  zoomToLastValueUpperThreshold,
  left,
  setLeft,
  width,
  setWidth,
  zoomableRangeTooltip,
  sloIncidents,
  scrollPosition,
  areaUnderLine,
  openWorkbench,
}) => {
  const rangeRef = useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = useState(true);
  const [dragging, setDragging] = useState(false);
  const { formatMessage } = useIntl();

  const margin = padding || DEFAULT_CHART_PADDING;

  useEffect(() => {
    if (maxX && minX && rangeRef?.current && setZoomedXPos && left !== undefined) {
      const range = maxX - minX;
      const rangeWidth = rangeRef?.current.offsetWidth;
      const startX = Math.round(((left > 0 ? left : 0) / rangeWidth) * range + minX);
      const endX = Math.round(((left + width > rangeWidth ? rangeWidth : left + width) / rangeWidth) * range + minX);
      setZoomedXPos({ startX, endX });
    }
    // eslint-disable-next-line
  }, [width, left, maxX]);

  // effect for setting initial position of range
  useEffect(() => {
    if (rangeRef?.current?.offsetWidth && left === undefined) {
      let newLeft = rangeRef?.current?.offsetWidth - width;
      if (maxX && minX && zoomToLastValueUpperThreshold) {
        const incidentsInRange = orderBy(
          (sloIncidents?.incidents || []).filter((incident) => incident.startTime >= minX && incident.endTime <= maxX),
          'startTime'
        );

        // moving range to last incident +- 30min
        if (incidentsInRange.length) {
          const lastIncidentInRange = incidentsInRange[incidentsInRange.length - 1];

          let startRangeTime = lastIncidentInRange.startTime - 30 * 60000; // maxTime - 30 minutes
          let endRangeTime = lastIncidentInRange.endTime + 30 * 60000; // maxTime + 30 minutes

          startRangeTime = startRangeTime < minX ? minX : startRangeTime;
          endRangeTime = endRangeTime > maxX ? maxX : endRangeTime;

          const rangeWidth = ((endRangeTime - startRangeTime) * rangeRef?.current.offsetWidth) / (maxX - minX);

          newLeft = ((startRangeTime - minX) * rangeRef?.current.offsetWidth) / (maxX - minX);

          setWidth(rangeWidth);
        } else {
          // if no incidents moving range to max value withing default width
          let maxTime = Math.max(
            ...data.map((d) =>
              Math.max(
                ...d.values
                  .filter((v) => {
                    return thresholds?.some(
                      (t) => (t.values.find((t) => t.time === v.time)?.value as number) < (v.value as number)
                    );
                  })
                  .map((v) => v.time)
              )
            )
          );
          maxTime = isFinite(maxTime) ? maxTime : maxX;

          newLeft = ((maxTime - minX) * rangeRef?.current.offsetWidth) / (maxX - minX) - width / 2;
        }
      }

      setLeft(newLeft);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    // this is done to hide tooltip while scrolling
    setShowTooltip(false);
    setTimeout(() => {
      setShowTooltip(true);
    }, 600);
  }, [scrollPosition]);

  const mouseDown = (e: React.MouseEvent<HTMLDivElement>, stopPrevent?: boolean) => {
    if (!stopPrevent) {
      e.preventDefault();
      e.stopPropagation();
    }
    const element = e.target as HTMLDivElement;
    const parent = element.parentElement as HTMLDivElement;
    const originalWidth = parent.getBoundingClientRect().width;
    sliderMove(element, originalWidth, e.pageX);
    setDragging(true);
  };

  const sliderMove = useCallback(
    (element: HTMLDivElement, originalWidth: number, originalMouseX: number) => {
      const originalLeft = left || 0;
      const minWidth = 10;

      function resize(e: MouseEvent) {
        if (element.classList.contains('left')) {
          const width = originalWidth - (e.pageX - originalMouseX);
          if (width > minWidth) {
            setWidth(width);
            setLeft(originalLeft + (e.pageX - originalMouseX));
          }
        } else if (element.classList.contains('right')) {
          const width = originalWidth + (e.pageX - originalMouseX);
          if (width > minWidth) {
            setWidth(width);
          }
        } else {
          const width = originalWidth - (e.pageX - originalMouseX);
          if (width > minWidth) {
            setLeft(originalLeft + (e.pageX - originalMouseX));
          }
        }
      }
      function handleMouseUp() {
        window.removeEventListener('mousemove', resize);
        window.removeEventListener('mouseup', handleMouseUp);
        setDragging(false);
      }
      window.addEventListener('mousemove', resize);
      window.addEventListener('mouseup', handleMouseUp);
    },
    [left, setLeft, setWidth]
  );

  const newSlideArea = (e: React.MouseEvent<HTMLDivElement>) => {
    if (rangeRef.current) {
      setDragging(true);
      const rightBorder = rangeRef.current.querySelector('.right');
      const rangeContainerParams = rangeRef.current.getBoundingClientRect();
      const chartHeight = rangeContainerParams.height - defaultLegendHeight;
      const chartWidth = rangeContainerParams.width;
      const xPosOnChart = e.pageX - rangeContainerParams.x;
      const yPosOnChart = e.pageY - rangeContainerParams.y;
      if (
        rightBorder &&
        xPosOnChart >= 0 &&
        xPosOnChart <= chartWidth &&
        yPosOnChart >= 0 &&
        yPosOnChart <= chartHeight
      ) {
        setLeft(e.pageX - rangeContainerParams.x);
        const defaultWidth = 1;
        setWidth(defaultWidth);
        sliderMove(rightBorder as HTMLDivElement, defaultWidth, e.pageX);
      }
    }
  };

  let incidentsLegendShape: 'line' | 'triangle' | null = null;

  if (sloIncidents?.incidents.length) {
    incidentsLegendShape = 'line';
  }

  const TooltipComponent = showTooltip ? Tooltip : React.Fragment;

  return (
    <div>
      <div
        className="absolute inset-0 overflow-hidden"
        style={{
          margin: Array.isArray(margin) ? margin.map((m) => `${m}px`).join(' ') : margin,
        }}
        ref={rangeRef}
        onMouseDown={newSlideArea}
      >
        <TooltipComponent
          show={dragging ? true : undefined}
          content={zoomableRangeTooltip || ''}
          interactive
          placement="top"
          className="max-w-[400px]"
        >
          <div
            style={{
              width,
              left: left || 0,
              marginBottom: showLegend ? `${defaultLegendHeight}px` : 0,
            }}
            className="absolute inset-y-0 cursor-ew-resize z-[2] bg-primary/30"
            onMouseDown={mouseDown}
          >
            <div
              className="absolute inset-y-0 bg-primary w-[3px] cursor-col-resize left-0 z-[9999] left"
              onMouseDown={mouseDown}
            />
            <div
              className="absolute inset-y-0 bg-primary w-[3px] cursor-col-resize right-0 z-[99999] right"
              onMouseDown={mouseDown}
            />
          </div>
        </TooltipComponent>
      </div>
      <div
        className="absolute inset-0 pt-[224px] overflow-hidden"
        style={{
          margin: Array.isArray(margin) ? margin.map((m) => `${m}px`).join(' ') : margin,
        }}
      >
        {minX &&
          maxX &&
          sloIncidents?.incidents.map((incident) => {
            let left = ((incident.startTime - minX) * 100) / (maxX - minX);
            left = left < 0 ? 0 : left;

            const width = ((incident.endTime - (left === 0 ? minX : incident.startTime)) * 100) / (maxX - minX);

            if (incidentsLegendShape !== 'triangle' && width < INCIDENTS_MINIMUM_WIDTH) {
              incidentsLegendShape = 'triangle';
            }

            return (
              <Tooltip
                content={formatMessage(messages.incidentTooltip)}
                key={incident.severity + incident.startTime + incident.endTime}
              >
                <div
                  onClick={(e) =>
                    openWorkbench(
                      e,
                      incident.startTime - 5 * 60 * 1000, // startTime - 5 min
                      incident.endTime
                    )
                  }
                  className={`cursor-pointer absolute z-[999] h-[3px] ${
                    width < INCIDENTS_MINIMUM_WIDTH
                      ? 'border-l-[5px] border-r-[5px] border-transparent border-b-[5px] ml-[-5px] border-solid'
                      : ''
                  }`}
                  style={{
                    left: `${left}%`,
                    width: `${left + width > 100 ? 100 - left : width}%`,
                    background: width > INCIDENTS_MINIMUM_WIDTH ? assertsColors[incident.severity] : 'transparent',
                    borderBottomColor:
                      width < INCIDENTS_MINIMUM_WIDTH ? assertsColors[incident.severity] : 'transparent',
                  }}
                />
              </Tooltip>
            );
          })}
      </div>
      <div onMouseDown={newSlideArea}>
        <LineRangeChart
          className={dragging ? 'pointer-events-none' : undefined}
          data={data}
          thresholds={thresholds}
          positionY="left"
          minX={minX}
          maxX={maxX}
          timeStepInterval={timeStepInterval}
          disableGrid={disableGrid}
          padding={padding}
          type={type}
          showLegend={showLegend}
          nullAsZero={nullAsZero}
          fillOpacity={fillOpacity}
          crossingRanges={crossingRanges}
          areaUnderLine={areaUnderLine}
          customLegendConfig={
            incidentsLegendShape
              ? {
                  Violations: {
                    color: assertsColors.critical,
                    shape: incidentsLegendShape,
                  },
                }
              : undefined
          }
          showCrosshairs
        />
      </div>
    </div>
  );
};

export default memo(ZoomableLineRangeChart);
