/**
 *
 * ShowEnvSiteTagSetting
 *
 */

import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import messages from './messages';
import { setShowEnvSiteTag } from '../../features/App/App.slice';
import { Switch } from '@grafana/ui';
import FormControlLabel from 'components/FormControlLabel/FormControlLabel.component';

interface IProps {}

const connector = connect(
  (state: RootState) => ({
    showEnvSiteTag: state.app.showEnvSiteTag,
  }),
  {
    setShowEnvSiteTag,
  },
);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ShowEnvSiteTagSetting: FunctionComponent<IProps & PropsFromRedux> = ({
  setShowEnvSiteTag,
  showEnvSiteTag,
}) => {
  const intl = useIntl();

  return (
    <FormControlLabel
      control={<Switch onClick={() => setShowEnvSiteTag(!showEnvSiteTag)} color="primary" value={showEnvSiteTag} />}
      label={intl.formatMessage(messages.showEnvSiteTag)}
    />
  );
};

export default connector(ShowEnvSiteTagSetting);
