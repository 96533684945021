
import {
  Definition,
  DefinitionSearchTypes,
  EntityKpiDetails,
  AdditionalEntityAssertionDetails,
  EntityKpiSummary,
  EntityDashboardResponse,
  Entity,
  EntityResponse,
  KpiConfig,
  KpiSummary,
  MonitoringStatus,
  Scope,
  EntityAdvancedSearchForm,
  EntityFilterCriteria,
  EntityProperties,
  EntitySearchObject,
  ProcessedEntityResponse,
  ScopeCriteria,
} from 'asserts-types';

import {
  NODE_POSTFIX_ID,
  applyClustering,
  cleanEdges,
  convertToGraphData,
} from '../helpers/Graph.helper';
import { areEntitiesEqual, SYSTEM_ENTITY_PROPERTY_PREFIX } from '../helpers/Entity.helper';
import { apiHttpService } from 'app/api-http-service';
export const fetchEntities =  (
  definition: Omit<Definition, 'id'>,
  start: number,
  end: number,
) =>
  apiHttpService
    .post<EntityResponse>(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/search`, {
      definitionId: definition.definitionId,
      bindings: definition.bindings,
      timeCriteria: {
        start,
        end,
      },
    })
    .then((response) => processEntityResponse(response.data));

const processEntityResponse = (
  res: EntityResponse,
): ProcessedEntityResponse => {
  const graphData = convertToGraphData(res);
  applyClustering(graphData);

  graphData.edges = cleanEdges(graphData.edges);

  const entities = res.data.entities.map((entity, index) => {
    const sameEntityName = res.data.entities.find(
      (e, i) => areEntitiesEqual(e, entity) && index !== i,
    );

    if (sameEntityName) {
      entity.nameWithNamespace = `${
        entity.scope?.namespace ? `${entity.scope?.namespace}:` : ''
      }${entity.name}`;
    }

    return entity;
  });

  return {
    ...res,
    data: { ...res.data, entities },
    graphData,
  };
};

export const searchEntities = (
  filterCriteria: EntityFilterCriteria[],
  start: number,
  end: number,
  scopeCriteria?: ScopeCriteria,
) =>
  apiHttpService
    .post<EntityResponse>(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/search`, {
      filterCriteria,
      timeCriteria: {
        start,
        end,
      },
      scopeCriteria,
    })
    .then((response) => processEntityResponse(response.data));

export const fetchEntityConnectionsById = (
  entityId: number,
  start: number,
  end: number,
): Promise<EntityResponse> =>
  apiHttpService
    .post(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/search`, {
      definitionId: DefinitionSearchTypes.EntityConnections,
      bindings: {
        id: entityId,
      },
      timeCriteria: {
        start,
        end,
      },
    })
    .then((response) => response.data);

export const fetchConnectedEntities = (
  entityId: number,
  entityType: string,
  start: number,
  end: number,
  scopeCriteria?: ScopeCriteria,
) =>
  apiHttpService
    .post<EntityResponse>(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/search`, {
      definitionId: DefinitionSearchTypes.ConnectedEntities,
      bindings: {
        id: entityId,
        entityType,
      },
      timeCriteria: {
        start,
        end,
      },
      scopeCriteria,
    })
    .then((response) => processConnectedEntities(response.data, entityId));

const processConnectedEntities = (
  res: EntityResponse,
  entityId: number,
): ProcessedEntityResponse => {
  const graphData = convertToGraphData(res);

  const entities = res.data.entities
    .filter((entity) => entity.id !== entityId)
    .map((entity, index) => {
      res.data.edges.forEach((edge) => {
        if (edge.destination === entity.id || edge.source === entity.id) {
          entity.edgeWithActiveElement = edge;
        }
      });

      const sameEntityName = res.data.entities.find(
        (e, i) => areEntitiesEqual(e, entity) && index !== i,
      );

      if (sameEntityName) {
        entity.nameWithNamespace = `${
          entity.scope?.namespace ? `${entity.scope?.namespace}:` : ''
        }${entity.name}`;
      }

      entity.parentEntityId = entityId;
      return entity;
    });

  graphData.nodes = graphData.nodes.map((n) => ({
    ...n,
    expandedFrom: entityId + NODE_POSTFIX_ID,
    parentEntityId: entityId,
  }));

  graphData.edges = graphData.edges.map((e) => ({
    ...e,
    expandedFrom: entityId + NODE_POSTFIX_ID,
  }));

  return {
    ...res,
    data: { ...res.data, entities },
    graphData,
  };
};

export const fetchEntityKpiDetails = (
  entityName: string,
  entityType: string,
  kpiGroupNames: string[],
  start: number,
  end: number,
): Promise<EntityKpiDetails> =>
  apiHttpService
    .post(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/entity/${entityType}/${entityName}/kpi/detail`, {
      kpiGroupNames,
      startMs: start,
      endMs: end,
    })
    .then((response) => response.data);

export const fetchEntityGrafanaDashboard = async (
  entityName: string,
  entityType: string,
  start: number,
  end: number,
  properties?: EntityProperties
): Promise<EntityDashboardResponse> => {
  let propertiesQuery;

  if (properties) {
    propertiesQuery = Object.keys(properties)
      .map((key) => `${key}=${encodeURIComponent(properties[key] || '')}`)
      .join('&');
  }


  return apiHttpService
    .get(
      `/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/entity/kpi/dash?start=${start}&end=${end}${
        propertiesQuery ? `&${propertiesQuery}` : ''
      }&entity_type=${entityType}&entity_name=${entityName}`
    )
    .then((response) => response.data);
};

export const fetchEntityKpiSummary = (entityId: number, start: number, end: number): Promise<EntityKpiSummary> =>
  apiHttpService
    .get(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/entity/${entityId}/kpi/summary?start=${start}&end=${end}`)
    .then((response) => response.data);

export const fetchAssertionDetails = (
  assertionName: string,
  labels?: Record<string, string>,
): Promise<AdditionalEntityAssertionDetails> =>
  apiHttpService
    .post(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/assertion/${assertionName}/info`, {
      assertionName,
      labels,
    })
    .then((response) => response.data);

export const fetchAdvancedSearchForm = (start: number, end: number) =>
  apiHttpService
    .get<EntityAdvancedSearchForm>(
      `/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/entity_type?start=${start}&end=${end}`
    )
    .then((response) => {

      // filter out system properties
      response.data.entities.forEach((entity) => {
        entity.properties = entity.properties.filter(
          (property) => !property.name.startsWith(SYSTEM_ENTITY_PROPERTY_PREFIX)
        );
      });

      return response.data;
    });

export const saveEntitySearch = (
  searchName: string,
  searchObject: EntitySearchObject,
): Promise<{
  describedQuery: string;
  filterCriteria: EntityFilterCriteria[];
  id: number;
  indexed: boolean;
}> =>
  apiHttpService
    .post('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/search/definition', {
      describedQuery: searchName,
      ...searchObject,
    })
    .then((response) => response.data);

export const updateEntitySearch = (
  definitionId: number,
  searchName: string,
  searchObject: EntitySearchObject,
): Promise<void> =>
  apiHttpService
    .put(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/search/definition/${definitionId}`, {
      id: definitionId,
      describedQuery: searchName,
      ...searchObject,
    })
    .then((response) => response.data);

export const fetchEntity = (
  entityType: string,
  entityName: string,
  start: number,
  end: number,
  scope: Scope,
): Promise<Entity> =>
  apiHttpService
    .get(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/entity/${entityType}/${entityName}`, {
      params: { ...scope, start, end },
    })
    .then((response) => response.data);

export const deleteAdvancedSearch = (definitionId: number): Promise<void> =>
  apiHttpService
    .delete(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/search/definition/${definitionId}`)
    .then((response) => response.data);

export const fetchKpiConfig = (): Promise<KpiConfig> =>
  apiHttpService
    .get('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/kpi/list')
    .then((response) => response.data);

export const fetchKpiSummaryByDefinition = (
  definition: Definition,
  start: number,
  end: number,
  kpiName: string,
): Promise<Record<string, number>> =>
  apiHttpService
    .post(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/kpi/summary/${kpiName}`, {
      definitionId: definition.definitionId,
      bindings: definition.bindings,
      timeCriteria: {
        start,
        end,
      },
    })
    .then((response) => response.data);

export const fetchKpiSummary = (
  entityKeys: { type: string; name: string; scope: Scope | undefined }[],
  startMs: number,
  endMs: number,
  kpiName: string,
): Promise<Record<string, KpiSummary>> =>
  apiHttpService
    .post(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/kpi/summary/for-entities/${kpiName}`, {
      startMs,
      endMs,
      entityKeys,
    })
    .then((response) => response.data);

export const fetchMonitoringStatus = (
  entityKeys: { type: string; name: string; scope: Scope | undefined }[],
  instantMs: number,
): Promise<{ entityStatus: MonitoringStatus[] }> =>
  apiHttpService
    .post(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/monitoring-status/for-entities`, {
      instantMs,
      entityKeys,
    })
    .then((response) => response.data);

export default {
  fetchEntityConnectionsById,
  fetchEntities,
  fetchEntityKpiDetails,
  fetchAssertionDetails,
  fetchAdvancedSearchForm,
  searchEntities,
  saveEntitySearch,
  updateEntitySearch,
  fetchEntityKpiSummary,
  fetchEntityGrafanaDashboard,
  fetchConnectedEntities,
  fetchEntity,
  deleteAdvancedSearch,
  fetchKpiConfig,
  fetchKpiSummaryByDefinition,
  fetchKpiSummary,
  fetchMonitoringStatus,
};
