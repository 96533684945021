import { DataSourceApi, TimeRange, dateTimeParse } from '@grafana/data';
import { DataSourcePicker } from '@grafana/runtime';
import {
  EmbeddedScene,
  SceneControlsSpacer,
  SceneFlexLayout,
  SceneQueryRunner,
  SceneReactObject,
  SceneRefreshPicker,
  SceneTimePicker,
  SceneTimeRange,
  VizPanel,
} from '@grafana/scenes';
import { LokiQuery } from 'asserts-types';
import { CopyLinkButton } from 'components/CopyLinkButton/CopyLinkButton';
import { OpenInExploreButton } from 'components/OpenInExploreButton/OpenInExploreButton';
import { QueryEditorScene } from 'components/QueryEditorScene/QueryEditorScene';
import React, { useEffect, useMemo } from 'react';

interface Props {
  query: string;
  initialStart: string | number;
  initialEnd: string | number;
  dataSource: DataSourceApi;
  onTimeRangeChange?: (timeRange: TimeRange) => void;
}

export default function EmbeddedLogsExplore({ query, initialStart, initialEnd, dataSource, onTimeRangeChange }: Props) {
  const [scene, cleanup] = useMemo(() => {
    const from = dateTimeParse(initialStart);
    const to = dateTimeParse(initialEnd);

    const timeRange: TimeRange = {
      from,
      to,
      raw: {
        from: typeof initialStart === 'string' ? initialStart : from,
        to: typeof initialEnd === 'string' ? initialEnd : to,
      },
    };

    const $timeRange = new SceneTimeRange({
      value: timeRange,
      from: typeof initialStart === 'string' ? initialStart : from.toString(),
      to: typeof initialEnd === 'string' ? initialEnd : to.toString(),
    });

    const queryData: LokiQuery = { refId: 'A', expr: query };

    const queryRunner = new SceneQueryRunner({
      datasource: {
        uid: dataSource?.uid,
      },
      queries: [queryData],
    });

    const unsubscribableTimeRange = $timeRange.subscribeToState((state) => {
      onTimeRangeChange?.(state.value);
    });

    const scene = new EmbeddedScene({
      $data: queryRunner,
      $timeRange,
      controls: [
        new SceneReactObject({ component: () => <DataSourcePicker disabled current={dataSource.uid} /> }),
        new OpenInExploreButton({
          datasourceUid: dataSource.uid,
          query: queryData,
        }),
        new CopyLinkButton({
          datasourceUid: dataSource.uid,
          query: queryData,
        }),
        new SceneControlsSpacer(),

        new SceneTimePicker({
          isOnCanvas: true,
        }),
        new SceneRefreshPicker({
          isOnCanvas: true,
        }),
      ],
      body: new SceneFlexLayout({
        direction: 'column',
        children: [
          new QueryEditorScene({ dataSource, queryRunner, query: queryData }),
          new VizPanel({ title: 'Table - Logs', pluginId: 'logs' }),
        ],
      }),
    });

    const cleanup = () => {
      unsubscribableTimeRange.unsubscribe();
    };

    return [scene, cleanup];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, query]);

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, [cleanup]);

  return <scene.Component model={scene} />;
}
