import { config, getDataSourceSrv } from '@grafana/runtime';
import { useQuery } from '@tanstack/react-query';
import { fetchMetricsDatasource } from 'services/App.service';
import { METRICS_DATA_SOURCE_UID } from 'global-constants';
import { DataQuery, DataSourceApi, DataSourceJsonData } from '@grafana/data';

type DS =
  | DataSourceApi<DataQuery, DataSourceJsonData, {}>
  | Pick<DataSourceApi<DataQuery, DataSourceJsonData, {}>, 'uid'>;

export function useMetricsDataSource() {
  const query = useQuery<DS>(['metrics-datasource'], () => {
    const fallback = getDataSourceSrv().get(METRICS_DATA_SOURCE_UID);
    return fetchMetricsDatasource()
      .then((response) => {
        if (!response.promDatasourceName) {
          return fallback;
        } else {
          const uid = config.datasources[response.promDatasourceName]?.uid;
          if (!uid) {
            return fallback;
          }
          return getDataSourceSrv()
            .get(uid)
            .then((ds) => (ds ? ds : fallback));
        }
      })
      .catch((e) => fallback);
  });

  const data: DS = query.data || { uid: METRICS_DATA_SOURCE_UID };

  return {
    ...query,
    isDataSourceApi,
    data,
  };
}

function isDataSourceApi(ds: DS): ds is DataSourceApi<DataQuery, DataSourceJsonData, {}> {
  const cast = ds as DataSourceApi<DataQuery, DataSourceJsonData, {}>;
  return cast.getTagKeys !== undefined;
}
