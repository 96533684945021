import { ChartTooltipType } from 'asserts-types';
import { defineMessages } from 'react-intl';

export const scope = 'features.AssertionsViewSettings';

export default defineMessages({
  view: {
    id: `${scope}.view`,
    defaultMessage: 'View',
  },
  groupedByEntity: {
    id: `${scope}.groupedByEntity`,
    defaultMessage: 'Grouped by entity',
  },
  showHiddenLines: {
    id: `${scope}.showHiddenLines`,
    defaultMessage: 'Show hidden lines',
  },
  showWithAssertions: {
    id: `${scope}.showWithAssertions`,
    defaultMessage: 'Show Entity with Assertions',
  },
  graph: {
    id: `${scope}.graph`,
    defaultMessage: 'Graph view',
  },
  hideAssertionsOld: {
    id: `${scope}.hideAssertionsOld`,
    defaultMessage: 'Hide assertions older than',
  },
  hours: {
    id: `${scope}.hours`,
    defaultMessage: 'hours',
  },
  assertionLevel: {
    id: `${scope}.assertionLevel`,
    defaultMessage: 'Category',
  },
  gradient: {
    id: `${scope}.gradient`,
    defaultMessage: 'Gradient',
  },
  heatmap: {
    id: `${scope}.heatmap`,
    defaultMessage: 'Heatmap',
  },
  timelineViewType: {
    id: `${scope}.timelineViewType`,
    defaultMessage: 'Root timeline view type',
  },
  showRCA: {
    id: `${scope}.showRCA`,
    defaultMessage: 'Show Root Causes',
  },
  [ChartTooltipType.DEFAULT]: {
    id: `${scope}.default`,
    defaultMessage: 'Default',
  },
  [ChartTooltipType.SHARED_LINE]: {
    id: `${scope}.shared_line`,
    defaultMessage: 'Shared Line',
  },
  [ChartTooltipType.SHARED_TOOLTIP]: {
    id: `${scope}.shared_tooltip`,
    defaultMessage: 'Shared Tooltip',
  },
  chartTooltip: {
    id: `${scope}.chartTooltip`,
    defaultMessage: 'Chart tooltip',
  },
  chartTooltipHint: {
    id: `${scope}.chartTooltipHint`,
    defaultMessage:
      'Controls tooltip and hover highlight behavior across different rows.',
  },
});
