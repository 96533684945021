/**
 *
 * ThresholdList
 *
 */

import React, { memo, FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import { ThresholdItem, Sort } from 'asserts-types';
import ThresholdListItem from '../ThresholdListItem/ThresholdListItem.component';
import { LoadingPlaceholder, PanelContainer } from '@grafana/ui';
import TableSortButton from 'features/Entities/components/TableSortButton/TableSortButton.component';

interface IProps {
  fields: string[];
  fetchingList: boolean;
  list: ThresholdItem[];
  title: string;
  onUpdate: (record: string, expr: string, labels: Record<string, string>) => void;
  onDelete?: (record: string, expr: string, labels?: Record<string, string>) => void;
  onSort: (sort: Sort | null) => void;
  orderBy: Sort | null;
}

const isFieldFromMessages = (messagesObj: typeof messages, field: string): field is keyof typeof messages =>
  messagesObj.hasOwnProperty(field);

const ThresholdList: FunctionComponent<IProps> = ({
  orderBy,
  fetchingList,
  list,
  onUpdate,
  onDelete,
  fields,
  title,
  onSort,
}) => {
  const intl = useIntl();

  return (
    <PanelContainer className="p-6 my-8">
      <div className="flex justify-between items-center pb-1">
        <p className="text-xl">{title}</p>
      </div>
      {fetchingList ? (
        <div className="flex justify-center items-center p-8">
          <LoadingPlaceholder text="Loading" />
        </div>
      ) : (
        <table className="w-full">
          <thead>
            <tr>
              <td className="p-4 divider-b">
                <TableSortButton
                  className="headerCell"
                  active={orderBy?.field === 'assertion'}
                  direction={orderBy?.order}
                  onClick={() =>
                    onSort({
                      field: 'assertion',
                      order: orderBy?.order === 'asc' ? 'desc' : 'asc',
                    })
                  }
                >
                  {intl.formatMessage(messages.assertion)}
                </TableSortButton>
              </td>
              <td className="p-4 divider-b">
                <TableSortButton
                  className="headerCell"
                  active={orderBy?.field === 'record'}
                  direction={orderBy?.order}
                  onClick={() =>
                    onSort({
                      field: 'record',
                      order: orderBy?.order === 'asc' ? 'desc' : 'asc',
                    })
                  }
                >
                  {intl.formatMessage(messages.threshold)}
                </TableSortButton>
              </td>

              {fields.map((field) => (
                <td key={field} className="p-4 divider-b">
                  <TableSortButton
                    className="headerCell"
                    active={orderBy?.field === `labels.${field}`}
                    direction={orderBy?.order}
                    onClick={() =>
                      onSort({
                        field: `labels.${field}`,
                        order: orderBy?.order === 'asc' ? 'desc' : 'asc',
                      })
                    }
                  >
                    {isFieldFromMessages(messages, field) ? intl.formatMessage(messages[field]) : ''}
                  </TableSortButton>
                </td>
              ))}
              <td className="p-4 divider-b">
                <TableSortButton
                  className="headerCell"
                  active={orderBy?.field === 'expr'}
                  direction={orderBy?.order}
                  onClick={() =>
                    onSort({
                      field: 'expr',
                      order: orderBy?.order === 'asc' ? 'desc' : 'asc',
                    })
                  }
                >
                  {intl.formatMessage(messages.value)}
                </TableSortButton>
              </td>
              <td width="10%" className="p-4 divider-b"></td>
            </tr>
          </thead>
          <tbody>
            {list.length ? (
              list.map((item) => (
                <ThresholdListItem
                  fields={fields}
                  key={`${item.record}-${item.expr}-${fields.map((field) => item.labels?.[field]).join('-')}`}
                  item={item}
                  onUpdate={onUpdate}
                  onDelete={onDelete}
                />
              ))
            ) : (
              <div className="p-4">{intl.formatMessage(messages.emptyList)}</div>
            )}
          </tbody>
        </table>
      )}
    </PanelContainer>
  );
};

export default memo(ThresholdList);
