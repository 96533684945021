/**
 *
 * PromRuleSwitch
 *
 */

import React, { memo, FunctionComponent } from 'react';
import useChangePromRule from '../../hooks/useChangePromRule';
import { Switch } from '@grafana/ui';

interface IProps {
  ruleName: string;
  enabled: boolean;
}

const PromRuleSwitch: FunctionComponent<IProps> = ({ ruleName, enabled }) => {
  const {
    mutate: changePromRule,
    isLoading: isChangingRule,
  } = useChangePromRule();

  return (
    <tr className="hover:bg-hover transition-all">
      <td className="p-4 divider-b">{ruleName}</td>
      <td className="p-4 divider-b">
        <Switch
          className="mr-1"
          value={enabled}
          disabled={isChangingRule}
          onChange={() =>
            changePromRule({
              ruleName,
              enable: !enabled,
            })
          }
        />
      </td>
    </tr>
  );
};

export default memo(PromRuleSwitch);
