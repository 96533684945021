/**
 *
 * EntityAdvancedSearchItem
 *
 */

import React, { memo, FunctionComponent, useState, useMemo } from 'react';
import AddIcon from 'assets/material-icons/add_FILL0_wght400_GRAD0_opsz24.svg';
import MoreHorizIcon from 'assets/material-icons/more_horiz_FILL0_wght400_GRAD0_opsz24.svg';
import messages from './messages';

import { EntityAdvancedSearchFormEntityItem, EntityFilterCriteria, EntityFilterPropertyMatcher } from 'asserts-types';
import EntitySearchProperty from '../EntitySearchProperty/EntitySearchProperty.component';
import {
  addSearchProperty,
  changeRule,
  changeSearchProperty,
  changeSearchPropertyConnectedEntities,
  deleteRule,
  deleteSearchProperty,
} from 'features/Entities/Entities.slice';
import { useIntl } from 'react-intl';
import { Checkbox, FilterPill, Menu } from '@grafana/ui';
import FormControlLabel from 'components/FormControlLabel/FormControlLabel.component';
import { Popover, PopoverContent, PopoverTrigger } from 'components/Popover/Popover.component';
import { IconButton } from 'components/IconButton/IconButton.component';

interface IProps {
  formItem: EntityAdvancedSearchFormEntityItem;
  addSearchProperty: typeof addSearchProperty;
  deleteSearchProperty: typeof deleteSearchProperty;
  deleteRule: typeof deleteRule;
  changeRule: typeof changeRule;
  filterCriteria: EntityFilterCriteria | undefined;
  changeSearchProperty: typeof changeSearchProperty;
  changeSearchPropertyConnectedEntities: typeof changeSearchPropertyConnectedEntities;
}

const EntityAdvancedSearchItem: FunctionComponent<IProps> = ({
  formItem,
  addSearchProperty,
  deleteSearchProperty,
  deleteRule,
  changeRule,
  filterCriteria,
  changeSearchProperty,
  changeSearchPropertyConnectedEntities,
}) => {
  const intl = useIntl();

  const [isMenuShown, setIsMenuShown] = useState(false);
  //prettier-ignore
  const [isMoreMenuShown, setIsMoreMenuShown] = useState(false);

  const isAssertion = formItem.entityType.toLowerCase() === 'assertion';

  const properties = useMemo(() => {
    let result: Record<string, EntityFilterPropertyMatcher[]> = {};

    filterCriteria?.propertyMatchers.forEach((propertyMatcher) => {
      if (result[propertyMatcher.name]) {
        result[propertyMatcher.name].push(propertyMatcher);
      } else {
        result[propertyMatcher.name] = [propertyMatcher];
      }
    });

    return result;
  }, [filterCriteria]);

  const hasCriteria = Boolean(
    filterCriteria?.havingAssertion || Object.keys(properties).length || filterCriteria?.connectToEntityTypes?.length
  );

  // const handlClickAway = useCallback(() => {
  //   setMoreMenuAnchorEl(null);
  //   setMenuAnchorEl(null);
  // }, []);

  const isItemActive = Boolean(isMoreMenuShown || isMenuShown);

  return (
    <div
      className={`pr-2 pl-4 hover:bg-primary/5 rounded-lg py-0.5 group ${
        hasCriteria || isItemActive ? 'bg-primary/5' : ''
      }`}
    >
      <div className="flex items-center justify-between min-h-[38px]">
        <p className="block uppercase">{formItem.entityType}</p>
        <div className={isItemActive ? '!block' : 'hidden group-hover:block'} data-cy="rule-btns">
          <Popover open={isMoreMenuShown} onOpenChange={setIsMoreMenuShown}>
            <PopoverContent>
              <Menu>
                {!isAssertion && (
                  <FormControlLabel
                    className="py-4 px-4"
                    control={
                      <Checkbox
                        color="primary"
                        checked={Boolean(filterCriteria?.havingAssertion)}
                        onClick={() =>
                          changeSearchProperty({
                            formItem,
                            patchObj: {
                              havingAssertion: !Boolean(filterCriteria?.havingAssertion),
                            },
                          })
                        }
                      />
                    }
                    label={intl.formatMessage(messages.showEntitiesOption)}
                  />
                )}
                <Menu.Divider />
                <Menu.Group label={intl.formatMessage(messages.showConnectedLabel)}>
                  <div className="pb-4 px-4 flex flex-col gap-1">
                    {formItem.connectedEntityTypes.map((entityTypeName) => (
                      <FormControlLabel
                        key={`${entityTypeName}-connected-option`}
                        control={
                          <Checkbox
                            color="primary"
                            checked={
                              filterCriteria?.connectToEntityTypes
                                ? filterCriteria.connectToEntityTypes.includes(entityTypeName)
                                : false
                            }
                            onClick={() =>
                              changeSearchPropertyConnectedEntities({
                                formItem,
                                connectedName: entityTypeName,
                              })
                            }
                          />
                        }
                        label={entityTypeName}
                      />
                    ))}
                  </div>
                </Menu.Group>
              </Menu>
            </PopoverContent>
            <PopoverTrigger onClick={() => setIsMoreMenuShown(true)} asChild>
              <IconButton variant="primary" data-cy="rule-options-btn" active={isMoreMenuShown}>
                <MoreHorizIcon />
              </IconButton>
            </PopoverTrigger>
          </Popover>

          <Popover open={isMenuShown} onOpenChange={setIsMenuShown}>
            <PopoverContent>
              <Menu>
                <div className="max-h-[700px] overflow-overlay">
                  {formItem.properties.map((prop) => (
                    <Menu.Item
                      key={`EntityAdvancedSearchItemMenuItem-${prop.name}`}
                      onClick={() => {
                        addSearchProperty({ propertyName: prop.name, formItem });
                        setIsMenuShown(false);
                      }}
                      label={prop.name.replace(/^asserts_/, '')}
                    />
                  ))}
                </div>
              </Menu>
            </PopoverContent>
            <PopoverTrigger asChild onClick={() => setIsMenuShown(true)}>
              <IconButton variant="primary" data-cy="add-rule-btn" active={isMenuShown}>
                <AddIcon />
              </IconButton>
            </PopoverTrigger>
          </Popover>
        </div>
      </div>
      {filterCriteria && (
        <div className="max-w-[408px] pt-0 pb-2 px-0 flex flex-col gap-2">
          {filterCriteria?.havingAssertion && (
            <div>
              <FilterPill
                label={intl.formatMessage(messages.showEntitiesOption)}
                icon="times"
                selected
                onClick={() =>
                  changeSearchProperty({
                    formItem,
                    patchObj: {
                      havingAssertion: !Boolean(filterCriteria?.havingAssertion),
                    },
                  })
                }
              />
            </div>
          )}
          {!!filterCriteria?.connectToEntityTypes?.length && (
            <div>
              <p className="block text-secondary text-xs">{intl.formatMessage(messages.showConnectedLabel)}: </p>
              <div className="flex flex-wrap items-center gap-1 mt-2">
                {filterCriteria?.connectToEntityTypes.map((type) => (
                  <FilterPill
                    key={type}
                    label={type}
                    icon="times"
                    selected
                    onClick={() =>
                      changeSearchPropertyConnectedEntities({
                        formItem,
                        connectedName: type,
                      })
                    }
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      )}
      <div>
        {Object.keys(properties).map((propertyName) => (
          <EntitySearchProperty
            changeRule={changeRule}
            deleteRule={deleteRule}
            rules={properties[propertyName]}
            deleteSearchProperty={deleteSearchProperty}
            formItem={formItem}
            propertyName={propertyName}
            key={`${propertyName}-property-search-item`}
            addSearchProperty={addSearchProperty}
          />
        ))}
      </div>
    </div>
  );
};

export default memo(EntityAdvancedSearchItem);
