/*
 * IncidentsSortBy Messages
 *
 * This contains all the text for the IncidentsSortBy component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.IncidentsSortBy';

export default defineMessages({
  time: {
    id: `${scope}.time`,
    defaultMessage: 'End Time',
  },
  type: {
    id: `${scope}.type`,
    defaultMessage: 'Type',
  },
  sortBy: {
    id: `${scope}.sortBy`,
    defaultMessage: 'Sort By:',
  },
});
