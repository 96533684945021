/**
 *
 * TopInsightsListItem
 *
 */

import React, { memo, FunctionComponent, useRef, useMemo } from 'react';
import HoveredButtons from '../HoveredButtons/HoveredButtons.component';
import { connect, ConnectedProps } from 'react-redux';
import EnvSiteTag from 'components/EnvSiteTag/EnvSiteTag.component';
import { ENTITY_OUT_OF_DATE_TIME } from '../../../Entities/constants';
import PropertiesPopover from 'components/PropertiesPopover/PropertiesPopover.component';
import messages from './messages';
import { useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';
import { AssertionScore } from 'asserts-types';
import { setTimeRange, setActiveEntityDetails } from 'features/App/App.slice';
import { Tooltip } from 'components/Tooltip/Tooltip.component';

const connector = connect(
  (state: RootState) => ({
    start: state.app.start,
    end: state.app.end,
  }),
  {
    setTimeRange,
    setActiveEntityDetails,
  }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IProps {
  score: AssertionScore;
  itemId: string;
  lastUpdateTime: number;
  className?: string;
}

const TopInsightsListItem: FunctionComponent<IProps & PropsFromRedux> = ({
  score,
  itemId,
  start,
  end,
  setTimeRange,
  setActiveEntityDetails,
  lastUpdateTime,
  className,
}) => {
  const intl = useIntl();

  const rootRef = useRef<HTMLDivElement | null>(null);

  

  const outOfDate = useMemo(() => {
    if (score.properties && score.properties['Updated'] && lastUpdateTime) {
      return (
        lastUpdateTime - +score.properties['Updated'] > ENTITY_OUT_OF_DATE_TIME
      );
    }
    return false;
  }, [score.properties, lastUpdateTime]);

  const openInboundError = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (score.type) {
      const properties = Object.assign({}, score.properties, {
        panel: 'inbound_client_error',
      });
      setActiveEntityDetails({
        type: score.type,
        name: score.name,
        scope: score.scope,
        properties,
      });
    }
  };

  return (
    <div
      className={twMerge(
        `flex items-center justify-between cursor-pointer select-none relative group/item h-full ${
          outOfDate
            ? 'before:content-["_"] before:absolute before:left-0 before:h-full before:w-1 before:bg-gray-100'
            : ''
        } ${className ? className : ''}`
      )}
      ref={rootRef}
      data-cy="ti-list-item"
    >
      <div className="flex items-center grow w-0 pl-4">
        <Tooltip
          content={
            <>
              {intl.formatMessage(messages.assertsScore)}
              <p className="text-secondary mb-0 mt-2 font-normal">{intl.formatMessage(messages.scoreHint)}</p>
            </>
          }
        >
          <div className="mr-6 min-w-[50px]">
            <div
              className={`rounded-full inline-block px-2 py-1 text-xs cursor-pointer font-bold ${
                score.percentage > 10 ? 'text-red-500 bg-red-500/25' : 'text-secondary bg-gray-400/25'
              }`}
            >
              {score.percentage}%
            </div>
          </div>
        </Tooltip>
        <div className="overflow-hidden">
          <Tooltip
            placement="top-end"
            interactive
            content={
              <PropertiesPopover
                className="px-2 pb-2"
                type={score.type}
                label={score.name}
                properties={score.properties || {}}
                scope={score.scope}
                lastUpdateTime={lastUpdateTime}
              />
            }
          >
            <p className="overflow-hidden whitespace-nowrap text-ellipsis mr-4">
              {score.nameWithNamespace || score.name}
            </p>
          </Tooltip>
          {score.scope.env && <EnvSiteTag scope={score.scope} />}
          {score.inboundClientErrorBreached && (
            <a className="text-xs block text-primary hover:underline" href="#" onClick={openInboundError}>
              {intl.formatMessage(messages.inboundClientErrors)}
            </a>
          )}
        </div>
      </div>
      <HoveredButtons
        score={score}
        start={start}
        end={end}
        setTimeRange={setTimeRange}
        setActiveEntityDetails={setActiveEntityDetails}
      />      
    </div>
  );
};

export default connector(memo(TopInsightsListItem));
