/**
 *
 * TopSearch
 *
 */

import React, { memo, FunctionComponent, MouseEvent, useMemo } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import HistoryIcon from 'assets/material-icons/history_FILL0_wght400_GRAD0_opsz24.svg';
import CloseIcon from 'assets/material-icons/close_FILL0_wght400_GRAD0_opsz24.svg';
import { connect, ConnectedProps, useDispatch } from 'react-redux';

import {
  deleteTopSearch,
  addToTopSearches,
  setSearch as setEntitiesSearch,
  clearEntities,
} from 'features/Entities/Entities.slice';
import { setSearch as setWorkbenchSearch } from 'features/Assertions/Assertions.slice';
import { orderBy } from 'lodash';
import { TopDefinition } from 'asserts-types';
import { Button, PanelContainer } from '@grafana/ui';
import { IconButton } from 'components/IconButton/IconButton.component';

interface IProps {}
const connector = connect(
  (state: RootState) => ({
    topSearches: state.entities.topSearches,
  }),
  {
    deleteTopSearch,
    addToTopSearches,
  },
);

type PropsFromRedux = ConnectedProps<typeof connector>;

const TopSearch: FunctionComponent<IProps & PropsFromRedux> = ({
  topSearches,
  addToTopSearches,
  deleteTopSearch,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const orderedTopSearches = useMemo(
    () => orderBy(topSearches, 'rank', 'desc').slice(0, 8),
    [topSearches],
  );

  const handleQueryClick = (search: TopDefinition) => {
    dispatch(clearEntities());
    addToTopSearches(search.boundDescription);
    window.location.href.includes('/assertions')
      ? dispatch(setWorkbenchSearch(search.boundDescription))
      : dispatch(setEntitiesSearch(search.boundDescription));
  };

  return (
    <PanelContainer className="block shadow w-[550px] pt-0 px-2 pb-2 max-h-full overflow-overlay !overflow-x-hidden min-h-[200px]">
      <div className="p-4 sticky top-0 z-[10] bg-paper">
        <p className="text-secondary text-xl">{intl.formatMessage(messages.title)}</p>
      </div>
      {orderedTopSearches.map((search, index) => (
        <Button
          className="w-full rounded h-12 py-0 px-4 group [&>span]:w-full"
          variant="secondary"
          fill="text"
          key={`${search.boundDescription}-${index}`}
          onClick={() => handleQueryClick(search)}
        >
          <div className="flex justify-between items-center w-full">
            <div className="flex items-center">
              <div className="pr-4 items-center flex">
                <HistoryIcon className="text-secondary svg-icon" />
              </div>
              <p>{search.boundDescription}</p>
            </div>
            <IconButton
              className="opacity-0 group-hover:opacity-100 transition-opacity"
              variant="primary"
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                deleteTopSearch(search);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </Button>
      ))}
      {!orderedTopSearches.length && (
        <div className="text-center p-[16px]">{intl.formatMessage(messages.noSearches)}</div>
      )}
    </PanelContainer>
  );
};

export default connector(memo(TopSearch));
