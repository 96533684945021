import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { RelabelRule, RelabelRuleType } from 'asserts-types';
import { useQuery } from '@tanstack/react-query';
import { fetchRules } from 'services/RelabelRules.service';

export default function () {
  const { type } = useParams();
  // const sort = useSelector(
  //   (state: RootState) => state.settings.relabelRulesSort,
  // );

  const dispatch = useDispatch();

  // const setSort = (payload: Sort) => {
  //   dispatch(setRelabelRulesSort(payload));
  // };

  const query = useQuery<RelabelRule[]>(
    ['relabelRulesList', type],
    () => {
      return fetchRules(type as RelabelRuleType);
    },
    {
      select: (data) =>
        data.map((item, index) => ({
          ...item,
          index,
        })),
    }
  );
  // return { ...query, sort, setSort };
  return query;
}
