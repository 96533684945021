import { ChartPoint } from 'asserts-types';

// finds single dots and converts it to timerange with 2 points
export default function expandSinglePoints(
  points: ChartPoint[],
  interval: number,
) {
  if (!interval) {
    return points;
  }

  const singlePoints = points.filter(
    (point, i) =>
      point.time - points[i - 1]?.time !== interval &&
      points[i + 1]?.time - point.time !== interval,
  );

  return [
    ...points,
    ...singlePoints.map((point) => ({ ...point, time: point.time + interval })),
  ];
}
