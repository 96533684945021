/*
 * AssertionSearchRuleItem Messages
 *
 * This contains all the text for the AssertionSearchRuleItem container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'features.AssertionSearchRuleItem';

export default defineMessages({
  equals: {
    id: `${scope}.equals`,
    defaultMessage: 'Equals',
  },
  startsWith: {
    id: `${scope}.startsWith`,
    defaultMessage: 'Starts with',
  },
  contains: {
    id: `${scope}.contains`,
    defaultMessage: 'Contains',
  },
  isNull: {
    id: `${scope}.isNull`,
    defaultMessage: 'Is null',
  },
  isNotNull: {
    id: `${scope}.isNotNull`,
    defaultMessage: 'Is not null',
  },
  rule: {
    id: `${scope}.rule`,
    defaultMessage: 'Rule',
  },
  value: {
    id: `${scope}.value`,
    defaultMessage: 'Value',
  },
  criticalAndAlerts: {
    id: `${scope}.criticalAndAlerts`,
    defaultMessage: 'Critical and Warning',
  },
  critical: {
    id: `${scope}.critical`,
    defaultMessage: 'Critical only',
  },
  alerts: {
    id: `${scope}.alerts`,
    defaultMessage: 'Warning only',
  },

});
