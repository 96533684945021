/**
 *
 * AssertionsFilter
 *
 */

import React, { memo, FunctionComponent, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import useAssertionsFilterValues from './hooks/useAssertionsFilterValues';
import useVirtualList from 'hooks/useVirtualList';
import { HierarchicalAssertion } from 'asserts-types';
import { useDispatch } from 'react-redux';
import { clearLabelFilter, toggleLabelFilter } from '../../Assertions.slice';
import { useAppSelector } from 'app/store';
import { Icon, Input, IconButton, Checkbox } from '@grafana/ui';
import FormControlLabel from 'components/FormControlLabel/FormControlLabel.component';

interface IProps {
  assertions: HierarchicalAssertion[] | undefined;
}

const AssertionsFilter: FunctionComponent<IProps> = ({ assertions }) => {
  const { formatMessage } = useIntl();
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();

  const labelsFilter = useAppSelector((state) => state.assertions.labelsFilter);

  const filterValues = useAssertionsFilterValues(assertions, search);
  const parentRef = useRef(null);

  const labels = Object.keys(filterValues);

  const { parentProps, rowVirtualizer } = useVirtualList({
    parentRef,
    size: labels.length || 0,
    customParentClass:
      'max-h-[500px] w-[400px] mt-4 gap-4 overflow-overlay space-y-3',
  });

  return (
    <>
      <div>
        <div className="flex items-baseline justify-between">
          <p className="font-bold text-lg mb-3">{formatMessage(messages.filter)}</p>
          <span
            className="text-primary cursor-pointer text-sm hover:underline"
            onClick={() => dispatch(clearLabelFilter())}
          >
            {formatMessage(messages.clear)}
          </span>
        </div>
        <Input
          className="w-full"
          value={search}
          placeholder={formatMessage(messages.search)}
          onChange={(e) => setSearch(e.currentTarget.value)}
          prefix={<Icon name="search" />}
          suffix={<IconButton name="times" aria-label="Clear" onClick={() => setSearch('')} />}
        />
      </div>
      {!labels.length && search && (
        <div className="pt-4">
          <span className="text-secondary">{formatMessage(messages.noResults)}</span>{' '}
          <span className="font-bold">&quot;{search}&quot;</span>
        </div>
      )}
      {!!labels.length && (
        <div ref={parentRef} {...parentProps}>
          {rowVirtualizer.virtualItems.map((virtualRow) => {
            return (
              <div key={virtualRow.index} ref={virtualRow.measureRef}>
                <p className="font-bold">{labels[virtualRow.index]}</p>
                <div className='space-y-1.5 py-1'>
                  {filterValues[labels[virtualRow.index]].map((labelValue) => (
                    <FormControlLabel
                      key={labelValue}
                      classes={{
                        label: '!text-sm block max-w-[400px] overflow-hidden whitespace-nowrap text-ellipsis',
                      }}
                      className="flex items-center"
                      control={
                        <Checkbox
                          color="primary"
                          title={labelValue}
                          onClick={() =>
                            dispatch(
                              toggleLabelFilter({
                                label: labels[virtualRow.index],
                                value: labelValue,
                              })
                            )
                          }
                          checked={Boolean(labelsFilter[labels[virtualRow.index]]?.includes(labelValue))}
                        />
                      }
                      label={labelValue}
                    />
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default memo(AssertionsFilter);
