import {
  Comparator,
  GrafanaSlo,
  GrafanaSloType,
  GroupedIncident,
  Incident,
  IncidentGroup,
  IncidentResponse,
  Scope,
  SloChartResponse,
  SloConfig,
  SloConfigModel,
  SloIncident,
  SloResponse,
  SloType,
  TopIncidentsResponse,
} from 'asserts-types';
import Decimal from 'decimal.js';
import { getHash } from '../features/Assertions/Assertions.helpers';
import { getDashedScopeValues } from '../helpers/Entity.helper';
import { cloneDeep, isNumber } from 'lodash';
import { apiHttpService } from 'app/api-http-service';
import { APP_PLUGIN_IDS } from 'app/constants';

export const fetchTopSlo = (endTime: number): Promise<SloResponse> =>
  apiHttpService
    .post(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/slo/top`, {
      endTime,
      sloSource: 'GrafanaSLO',
    })
    .then((response) => response.data);

export const fetchTopIncidents = (start: number, end: number): Promise<TopIncidentsResponse> =>
  apiHttpService
    .post(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/incidents/top`, {
      start,
      end,
      sloSource: 'GrafanaSLO',
    })
    .then((response) => response.data);

export const fetchSlos = (endTime: number): Promise<SloResponse> =>
  apiHttpService
    .post(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/slo`, { endTime, sloSource: 'GrafanaSLO' })
    .then((response) => response.data);

export const fetchSloChart = (
  sloName: string,
  chartName: string,
  targetName: string,
  start: number,
  end: number,
  scope?: Scope,
  measurementQuery?: string,
  threshold?: number
): Promise<SloChartResponse> =>
  apiHttpService
    .post(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/slo/chart`, {
      sloName,
      chartName,
      targetName,
      start,
      end,
      scopeCriteria: scope?.env
        ? {
            nameAndValues: {
              env: [scope?.env],
              site: scope?.site ? [scope?.site] : undefined,
            },
          }
        : undefined,
      measurementQuery,
      threshold: isNumber(threshold) ? threshold : undefined,
      sloSource: 'GrafanaSLO',
    })
    .then((response) => response.data);

export const fetchIncidents = (start: number, end: number, search?: string) =>
  apiHttpService
    .post<IncidentResponse>(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/incidents`, {
      start,
      end,
      search,
      sloSource: 'GrafanaSLO',
    })
    .then((res) => {
      res.data.incidentGroups.forEach((incidentGroup) => {
        incidentGroup.id = getHash(
          `${incidentGroup.name}-${incidentGroup.detail.name}-${incidentGroup.detail.type}-${getDashedScopeValues(
            incidentGroup.detail.scope
          )}-${Object.values(incidentGroup.detail.labels || {}).join('-')}`
        ).toString();
      });

      let arrayIncidents: IncidentGroup[] = [];

      const gropedIncidents = res.data.incidentGroups.reduce((grouped, incidentGroup) => {
        let groupKey = '';
        if (incidentGroup.type === 'assertion_alert') {
          groupKey =
            (incidentGroup.detail.labels?.asserts_notification_rule_name || '') +
            getDashedScopeValues(incidentGroup.detail.scope);
        }

        if (groupKey) {
          if (grouped[groupKey]) {
            grouped[groupKey].nestedItems?.push(incidentGroup);
            grouped[groupKey].incidents = grouped[groupKey].incidents.concat(incidentGroup.incidents);
          } else {
            grouped[groupKey] = {
              nestedItems: [incidentGroup],
              id: getHash(
                `${groupKey}-${incidentGroup.type}-group-${getDashedScopeValues(
                  incidentGroup.detail.scope
                )}-${Object.values(incidentGroup.detail.labels || {}).join('-')}`
              ),
              name: incidentGroup.detail.labels?.asserts_notification_rule_name || 'Notifications group',
              incidents: incidentGroup.incidents,
              detail: incidentGroup.detail,
              type: incidentGroup.type,
            };
          }
        } else {
          arrayIncidents.push(incidentGroup);
        }

        return grouped;
      }, {} as Record<string, GroupedIncident>);

      let groupedResult = Object.values(gropedIncidents);
      groupedResult = groupedResult.map((incident) => {
        return {
          ...incident,
          //  merge incidents with overlapping time in one
          incidents: cloneDeep(incident.incidents)
            .sort(function (a, b) {
              return a.startTime - b.startTime || a.endTime - b.endTime;
            })
            .reduce(function (acc, incident) {
              let last = acc[acc.length - 1] || ([] as Incident[]);
              if (
                last.startTime <= incident.startTime &&
                incident.startTime <= last.endTime &&
                last.severity === incident.severity
              ) {
                if (last.endTime < incident.endTime) {
                  last.endTime = incident.endTime;
                }
                last.summary = (last.summary || '') + (incident.summary ? ' \n' + incident.summary : '');
                return acc;
              }
              return acc.concat(incident);
            }, [] as Incident[]),
        };
      });

      let resultArray = groupedResult.concat(arrayIncidents);
      return { incidentGroups: resultArray, chartName: res.data.chartName };
    });

export const fetchSloIncidents = (
  sloName: string,
  targetName: string,
  start: number,
  end: number,
  scope?: Scope
): Promise<SloIncident> =>
  apiHttpService
    .post(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/slo/incidents`, {
      sloName,
      targetName,
      start,
      end,
      scopeCriteria: scope?.env
        ? {
            nameAndValues: {
              env: scope?.env ? [scope?.env] : undefined,
              site: scope?.site ? [scope?.site] : undefined,
              namespace: scope?.namespace ? [scope?.namespace] : undefined,
            },
          }
        : undefined,
      sloSource: 'GrafanaSLO',
    })
    .then((response) => response.data);

export const fetchSloConfig = (sloName: string) =>
  apiHttpService
    .get<SloConfig>(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/slo/${sloName}`)
    .then((response) => response.data);

const processSloConfig = (configs: SloConfigModel[]) => {
  return configs.map((config) => ({
    ...config,
    objectives: config.objectives.map((item) => ({
      ...item,
      ratio: new Decimal(item.ratio).mul(100).toNumber(),
    })),
  }));
};

export const fetchSloConfigs = () =>
  apiHttpService
    .get<SloConfigModel[]>(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/slo`)
    .then((response) => processSloConfig(response.data));

export function convertAssertsSloToGrafanaSlo(config: SloConfigModel, metricsDsUid: string): GrafanaSlo {
  const slo = {
    name: config.name,
    uuid: config.uuid,
    labels: [{ key: 'grafana_slo_provenance', value: 'asserts' }],
    objectives: [{ window: config.objectives[0].window.days + 'd', value: config.objectives[0].ratio }],
    destinationDatasource: { uid: metricsDsUid },
    searchExpression: config.entitySearch,
    readOnly: config.readOnly,
    alerting: {
      fastBurn: {
        annotations: [
          {
            key: 'name',
            value: `${config.indicator.kind}SloFastBurn`,
          },
          {
            key: 'description',
            value: 'Error budget is burning too fast.',
          },
        ],
      },
    },
  };
  if (config.indicator.kind === SloType.Request) {
    const grafanaSloItem: GrafanaSlo = {
      ...slo,
      alerting: {
        ...slo.alerting,
        slowBurn: {
          annotations: [
            {
              key: 'name',
              value: `${config.indicator.kind}SloSlowBurn`,
            },
            {
              key: 'description',
              value: 'Error budget is burning slow.',
            },
          ],
        },
      },
      query: {
        failureRatio: {
          groupByLabels: ['asserts_env', 'asserts_site', 'namespace'],
          totalMetric: {
            prometheusMetric: config.indicator.totalEventCount || '',
            type: config.indicator.gauge ? 'gauge' : 'counter',
          },
          failureMetric: {
            prometheusMetric: config.indicator.badEventCount || '',
            type: config.indicator.gauge ? 'gauge' : 'counter',
          },
        },
        type: GrafanaSloType.FailureRatio,
      },
    };
    return grafanaSloItem;
  } else {
    const grafanaSloItem: GrafanaSlo = {
      ...slo,
      query: {
        failureThreshold: {
          groupByLabels: ['asserts_env', 'asserts_site', 'namespace'],
          failureThresholdExpression: config.indicator.measurement || '',
          threshold: {
            operator: config.indicator.thresholdComparator,
            value: config.objectives[0].value,
          },
        },
        type: GrafanaSloType.FailureThreshold,
      },
    };
    return grafanaSloItem;
  }
}

export function convertGrafanaSloToAssertsSlo(config: GrafanaSlo): SloConfigModel {
  const slo = {
    name: config.name,
    uuid: config.uuid,
    entitySearch: config.searchExpression,
    active: true,
    objectives: config.objectives.map((item) => ({
      name: 'Objective',
      ratio: new Decimal(item.value).mul(100).toNumber(),
      value: config.query.type === GrafanaSloType.FailureThreshold ? config.query.failureThreshold.threshold.value : 0,
      window: {
        days: parseInt(item.window.replace('d', ''), 10),
      },
    })),
    readOnly: config.readOnly,
  };
  if (config.query.type === GrafanaSloType.FailureRatio) {
    const grafanaSloItem: SloConfigModel = {
      ...slo,
      kind: 'SLO',
      indicator: {
        kind: SloType.Request,
        badEventCount: config.query.failureRatio.failureMetric.prometheusMetric,
        totalEventCount: config.query.failureRatio.totalMetric.prometheusMetric,
        gauge: config.query.failureRatio.failureMetric.type === 'gauge',
        thresholdComparator: Comparator.EqualTo,
      },
    };
    return grafanaSloItem;
  } else {
    const grafanaSloItem: SloConfigModel = {
      ...slo,
      kind: 'SLO',
      indicator: {
        kind: SloType.Occurrence,
        measurement: config.query.failureThreshold.failureThresholdExpression,
        thresholdComparator: config.query.failureThreshold.threshold.operator,
      },
    };
    return grafanaSloItem;
  }
}

// TODO: the entire slo form can be refactored after SLO place is defined, for now it just converts a new format to old one to support form interactions
export const createGrafanaSloConfig = (config: SloConfigModel, metricsDsUid: string): Promise<SloConfigModel> => {
  return apiHttpService
    .post(
      `/api/plugins/${APP_PLUGIN_IDS.SLO_APP}/resources/v1/slo`,
      convertAssertsSloToGrafanaSlo(config, metricsDsUid),
      {
        headers: {
          'Grafana-Asserts-Request': true,
        },
      }
    )
    .then((response) => response.data);
};

// TODO: the entire slo form can be refactored after SLO place is defined, for now it just converts a new format to old one to support form interactions
export const updateGrafanaSloConfig = (
  id: string,
  config: SloConfigModel,
  metricsDsUid: string
): Promise<SloConfigModel> => {
  return apiHttpService
    .put(
      `/api/plugins/${APP_PLUGIN_IDS.SLO_APP}/resources/v1/slo/${id}`,
      {
        ...convertAssertsSloToGrafanaSlo(config, metricsDsUid),
        uuid: id,
      },
      {
        headers: {
          'Grafana-Asserts-Request': true,
        },
      }
    )
    .then((response) => response.data);
};

export const deleteGrafanaSlo = (uuid: string) => {
  return apiHttpService
    .delete(`/api/plugins/${APP_PLUGIN_IDS.SLO_APP}/resources/v1/slo/${uuid}`)
    .then((response) => response.data);
};

// TODO: the entire slo form can be refactored after SLO place is defined, for now it just converts a new format to old one to support form interactions
export const fetchGrafanaSlo = (id: string) => {
  return apiHttpService
    .get<GrafanaSlo>(`/api/plugins/${APP_PLUGIN_IDS.SLO_APP}/resources/v1/slo/${id}`)
    .then((response) => convertGrafanaSloToAssertsSlo(response.data));
};

export const fetchGrafanaSloList = () => {
  return apiHttpService
    .get<{ slos: GrafanaSlo[] }>(`/api/plugins/${APP_PLUGIN_IDS.SLO_APP}/resources/v1/slo`)
    .then((res) => res.data.slos);
};

export const createSloConfig = (config: SloConfigModel): Promise<SloConfigModel> =>
  apiHttpService
    .post(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/slo`, config)
    .then((response) => response.data);

export const updateSloConfig = (config: SloConfigModel): Promise<SloConfigModel> =>
  apiHttpService
    .post(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/slo`, config)
    .then((response) => response.data);

export const deleteSloConfig = (name: string): Promise<SloConfigModel> =>
  apiHttpService
    .delete(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/slo/${name}`)
    .then((response) => response.data);

export default {
  fetchSlos,
  fetchSloChart,
  fetchSloConfig,
  fetchSloConfigs,
  createSloConfig,
  updateSloConfig,
  deleteSloConfig,
  createGrafanaSloConfig,
  updateGrafanaSloConfig,
  deleteGrafanaSlo,
  fetchTopSlo,
  fetchTopIncidents,
};
