import { useQuery } from '@tanstack/react-query';
import { fetchAssertionDetails } from 'services/Entity.service';

interface IProps {
  assertionName?: string;
  labels?: Record<string, string>;
  onSuccess?: () => void;
}

export default function useAssertionDetails({ assertionName, labels, onSuccess }: IProps) {
  return useQuery(
    ['assertion-details', assertionName, labels],
    () => fetchAssertionDetails(assertionName || '', labels),
    { enabled: !!assertionName, onSuccess }
  );
}
