import { useQuery } from '@tanstack/react-query';
import { fetchGrafanaSlo } from 'services/Slo.service';

const useGrafanaSlo = (id: string | undefined) => {
  return useQuery(
    ['slo', id],
    () => {
      if (!id) {
        return Promise.resolve(null);
      }
      return fetchGrafanaSlo(id);
    },
    { enabled: !!id }
  );
};

export default useGrafanaSlo;
