/**
 *
 * AssertionsListItemActions
 *
 */

import React, { memo, FunctionComponent, MouseEvent, useMemo, useState } from 'react';
import AutoGraphRoundedIcon from 'assets/material-icons/monitoring_FILL0_wght400_GRAD0_opsz24.svg';
import RulesIcon from 'icons/RulesIcon';
import NullZeroIcon from 'icons/NullZeroIcon';
import { setNullAsZero } from '../../Assertions.slice';
import { connect, ConnectedProps } from 'react-redux';
import { useIntl } from 'react-intl';
import messages from './messages';
import NotificationsNoneIcon from 'assets/material-icons/notifications_FILL0_wght400_GRAD0_opsz24.svg';
import MoreHorizIcon from 'assets/material-icons/more_horiz_FILL0_wght400_GRAD0_opsz24.svg';
import LegendItemIcon from 'icons/LegendItemIcon';
import CompressIcon from 'icons/CompressIcon';
import { Scope, SubMenuItem } from 'asserts-types';
import { thresholdTypeMap } from '../../constants';
import has from 'helpers/has.helper';
import { useNavigate } from 'react-router-dom';
import RulesInfoSectionComponent from '../RulesInfoSection/RulesInfoSection.component';
import useAssertionEntityMetrics from 'hooks/useAssertionEntityMetrics';
import { prefixRoute } from 'utils/utils.routing';
import { ROUTES } from 'global-constants';
import { setItemToPopulate } from 'features/ManageAssertions/ManageAssertions.slice';
import { setActiveEntityDetails } from 'features/App/App.slice';
import { Tooltip } from 'components/Tooltip/Tooltip.component';
import { Dropdown, Menu, PanelContainer } from '@grafana/ui';
import { IconButton } from 'components/IconButton/IconButton.component';
import { UserCan, isUserActionAllowed } from 'utils/permissions';

interface IProps {
  itemId: string;
  level: number;
  showNullsBtn?: boolean;
  labels: Record<string, string>;
  start: number;
  end: number;
  name: string;
  type: string;
  rootEntityName?: string;
  rootEntityType?: string;
  scope: Scope | undefined;
}

const connector = connect(
  (state: RootState) => ({
    nullAsZeroByHash: state.assertions.nullAsZeroByHash,
  }),
  {
    setNullAsZero,
    setItemToPopulate,
    setActiveEntityDetails,
  }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

const AssertionsListItemActions: FunctionComponent<IProps & PropsFromRedux> = ({
  itemId,
  level,
  nullAsZeroByHash,
  setNullAsZero,
  showNullsBtn,
  labels,
  start,
  end,
  name,
  type,
  rootEntityName,
  setItemToPopulate,
  rootEntityType,
  setActiveEntityDetails,
  scope,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [activeRulesTooltip, setActiveRulesTooltip] = useState(false);
  const [activeDotsTooltip, setActiveDotsTooltip] = useState(false);

  const { data: chartData, isLoading } = useAssertionEntityMetrics({
    labels,
    entityType: type,
    entityName: name,
    assertionName: labels.alertname,
    startTime: start,
    endTime: end,
    rootEntityName,
    rootEntityType,
    enabled: Boolean(labels.alertname && name && type && labels),
  });

  const openMetrics = (e: any) => {
    e.stopPropagation();

    if (chartData?.metrics[0]?.query) {
      setActiveEntityDetails({
        name,
        type,
        scope,
        tab: 'metrics',
        additionalLabels: labels,
        alertName: labels.alertname,
      });
    }

    // const left = encodeURIComponent(
    //   JSON.stringify({
    //     queries: [{ expr: chartData.metrics[0].query }],
    //     range: {
    //       from: start,
    //       to: end,
    //     },
    //   })
    // );

    // navigate(`/explore?left=${left}`);
  };

  const handleThresholdNavigate = (e: MouseEvent) => {
    e.stopPropagation();
    const urlToNavigate = prefixRoute(
      [
        ROUTES.RULES,
        ROUTES.RULES_THRESHOLD,
        has(thresholdTypeMap, labels.alertname) ? thresholdTypeMap[labels.alertname] : 'health',
      ].join('/')
    );
    setItemToPopulate(labels);
    navigate(urlToNavigate, { state: { timestamp: Date.now() } });
  };

  const handleNotificationNavigate = (e: MouseEvent) => {
    e.stopPropagation();
    let urlToNavigate = prefixRoute(
      [
        ROUTES.RULES,
        ROUTES.RULES_NOTIFICATIONS,
        has(thresholdTypeMap, labels.alertname) ? thresholdTypeMap[labels.alertname] : 'health',
      ].join('/')
    );
    setItemToPopulate({
      ...labels,
      notificationRuleName: chartData?.notificationRuleName || '',
    });
    navigate(urlToNavigate, { state: { timestamp: Date.now() } });
  };

  const handleSuppressNavigate = (e: MouseEvent) => {
    e.stopPropagation();
    let urlToNavigate = prefixRoute(
      [
        ROUTES.RULES,
        ROUTES.RULES_SUPPRESS,
        has(thresholdTypeMap, labels.alertname) ? thresholdTypeMap[labels.alertname] : 'health',
      ].join('/')
    );
    setItemToPopulate(labels);
    navigate(urlToNavigate, { state: { timestamp: Date.now() } });
  };

  const settingsSubMenu: SubMenuItem[] = useMemo(
    () => [
      {
        icon: <LegendItemIcon className="svg-icon" />,
        text: intl.formatMessage(messages.threshold),
        onClick: handleThresholdNavigate,
      },
      {
        icon: <NotificationsNoneIcon className="svg-icon" />,
        text: chartData?.notificationRuleName
          ? intl.formatMessage(messages.updateNotification)
          : intl.formatMessage(messages.createNotification),
        onClick: handleNotificationNavigate,
      },
      {
        icon: <CompressIcon className="svg-icon" />,
        text: intl.formatMessage(messages.suppress),
        onClick: handleSuppressNavigate,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intl, chartData?.notificationRuleName, itemId]
  );

  return (
    <>
      <PanelContainer
        className="mt-4 bg-panel rounded-lg py-1 px-1.5 self-start flex items-center gap-1"
        style={{ marginLeft: 16 * (level + 1) }}
        onClick={(e) => e.stopPropagation()}
      >
        <Tooltip content={intl.formatMessage(messages.showChart)}>
          <IconButton
            className="rounded-md"
            disabled={!chartData?.metrics[0]?.query}
            variant="primary"
            onClick={openMetrics}
          >
            <AutoGraphRoundedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          interactive
          className="min-w-[300px]"
          enterDelay={300}
          onOpen={() => setActiveRulesTooltip(true)}
          onClose={() => setActiveRulesTooltip(false)}
          content={<RulesInfoSectionComponent assertionName={labels.alertname} labels={labels} />}
          placement="bottom-start"
        >
          <IconButton active={activeRulesTooltip} className="rounded-md" variant="primary">
            <RulesIcon />
          </IconButton>
        </Tooltip>
        {showNullsBtn && (
          <Tooltip content={intl.formatMessage(nullAsZeroByHash[itemId] ? messages.nullAsNull : messages.nullAsZero)}>
            <IconButton
              variant="primary"
              onClick={(e) => {
                e.stopPropagation();
                setNullAsZero(itemId);
              }}
              className="rounded-md"
              active={nullAsZeroByHash[itemId]}
            >
              <NullZeroIcon />
            </IconButton>
          </Tooltip>
        )}
        {isUserActionAllowed(UserCan.WriteRules) && (
          <Dropdown
            onVisibleChange={setActiveDotsTooltip}
            overlay={
              <Menu>
                {settingsSubMenu.map((it) => (
                  <Menu.Item
                    onClick={it.onClick}
                    key={it.text}
                    //@ts-ignore
                    label={
                      <span className="flex items-center gap-2">
                        {it.icon}
                        {it.text}
                      </span>
                    }
                  />
                ))}
              </Menu>
            }
          >
            <IconButton
              active={activeDotsTooltip}
              disabled={isLoading}
              className="rounded-md"
              variant="primary"
              onClick={(e) => e.stopPropagation()}
            >
              <MoreHorizIcon />
            </IconButton>
          </Dropdown>
        )}
      </PanelContainer>
    </>
  );
};

export default connector(memo(AssertionsListItemActions));
