import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { setGraphPadding, setShowAdvancedSearch } from 'features/Entities/Entities.slice';
import EntityDetailsMenuComponent from './components/EntityDetailsMenu/EntityDetailsMenu.component';
import EntityPropertiesComponent from './components/EntityProperties/EntityProperties.component';
import { LokiQuery, ServerError, TempoQuery } from 'asserts-types';
import { stringToDate } from 'helpers/Date.helper';
import useEntity from 'hooks/useEntity';
import useEntityGrafanaDashboards from 'hooks/useEntityGrafanaDashboards';
import useLogsUrl from 'hooks/useLogsUrl';
import useTracesUrl from 'hooks/useTracesUrl';
import { Alert, Drawer, LoadingPlaceholder } from '@grafana/ui';
import useAssertionEntityMetrics from 'hooks/useAssertionEntityMetrics';
import EmbeddedMetricsExplore from 'components/EmbeddedMetricsExplore/EmbeddedMetricsExplore';
import { useDataSource } from 'hooks/useDataSource';
import EmbeddedTracesExplore from 'components/EmbeddedTracesExplore/EmbeddedTracesExplore';
import EmbeddedLogsExplore from 'components/EmbeddedLogsExplore/EmbeddedLogsExplore';
import { TimeRange } from '@grafana/data';
import { AxiosError } from 'axios';
import TrackingHelper from 'helpers/Tracking.helper';
import K8sEntityView from 'extensions/k8s-app/K8sEntityView';
import AppO11yEntityView from 'extensions/appO11y-app/AppO11yEntityView';
import FrontendO11yEntityView from 'extensions/frontendO11y-app/FrontendO11yEntityView';
import CspEntityDashboardView from 'extensions/csp/CspEntityDashboardView';
import EntityDetailsDashboard from 'features/EntityDetails/components/EntityDetailsDashboard/EntityDetailsDashboard.component';
import { setActiveEntityDetails, setDrawerEnd, setDrawerStart, setDrawerTab } from 'features/App/App.slice';
import useKubernetes from 'components/OpenInKubernetesTrigger/hooks/useKubernetes';
import { useMetricsDataSource } from 'hooks/useMetricsDatasource';
import { pick } from 'lodash';
import useKpiDisplayConfig from 'hooks/useKpiDisplayConfig';

interface IProps {}

const connector = connect(
  (state: RootState) => ({
    activeEntityDetails: state.app.activeEntityDetails,
    drawerStart: state.app.activeEntityDetails?.start || state.app.start,
    drawerEnd: state.app.activeEntityDetails?.end || state.app.end,
    drawerTab: state.app.activeEntityDetails?.tab || 'kpi',
  }),
  {
    setGraphPadding,
    setShowAdvancedSearch,
    setActiveEntityDetails,
    setDrawerEnd,
    setDrawerStart,
    setDrawerTab,
  }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

const EntityDetailsContainer: FunctionComponent<PropsFromRedux & IProps> = ({
  activeEntityDetails,
  setShowAdvancedSearch,
  setActiveEntityDetails,
  drawerStart,
  drawerEnd,
  drawerTab,
  setDrawerEnd,
  setDrawerStart,
  setDrawerTab,
}) => {
  // used for requests that fetches queries or urls (TODO: ask BE to not ask for the start and end time since FE doesn't use the time from returned data anymore)
  //eslint-disable-next-line
  const initialStartMs = useMemo(() => stringToDate(drawerStart).valueOf(), []);
  //eslint-disable-next-line
  const initialEndMs = useMemo(() => stringToDate(drawerEnd).valueOf(), []);

  // these values are for reacting on time change and update an entity circle
  const startMsForEntity = useMemo(() => stringToDate(drawerStart).valueOf(), [drawerStart]);
  const endMsForEntity = useMemo(() => stringToDate(drawerEnd).valueOf(), [drawerEnd]);

  useEffect(() => {
    setShowAdvancedSearch(false);
    //eslint-disable-next-line
  }, []);

  let { data: entity, isFetching: isEntityFetching } = useEntity({
    entityName: activeEntityDetails?.name,
    entityType: activeEntityDetails?.type,
    start: startMsForEntity,
    end: endMsForEntity,
    scope: activeEntityDetails?.scope,
  });

  // in case Pod/ServiceInstance was dropped entity request will return empty response
  // so we need to create a fake entity object based on activeEntityDetails to be able to fetch logs/traces/metrics and dashboards
  if (
    !entity &&
    !isEntityFetching &&
    activeEntityDetails?.properties?.asserts_source_entity_type &&
    ['Pod', 'ServiceInstance'].includes(activeEntityDetails?.properties.asserts_source_entity_type?.toString())
  ) {
    entity = {
      ...pick(activeEntityDetails, ['name', 'type', 'scope']),
      id: 1,
      connectedAssertion: {},
      assertion: {},
      properties: activeEntityDetails.properties || {},
    };
  }

  const {
    data: logsData,
    isFetching: isFetchingLogs,
    error: logsError,
  } = useLogsUrl({
    start: initialStartMs,
    end: initialEndMs,
    entity,
    additionalLabels: activeEntityDetails?.additionalLabels,
  });

  const {
    data: tracesData,
    isFetching: isFetchingTraces,
    error: tracesError,
  } = useTracesUrl({
    start: initialStartMs,
    end: initialEndMs,
    entity,
    additionalLabels: activeEntityDetails?.additionalLabels,
    params: { values: activeEntityDetails?.values || [], threshold: activeEntityDetails?.threshold || [] },
  });

  const [tracesQueryFromData, tracesDataSourceUID] = useMemo(() => {
    if (tracesData?.url) {
      const tracesUrl = new URL(tracesData.url);
      const queryEncoded = tracesUrl.searchParams.get('left');
      const tracesParamsStr = queryEncoded ? decodeURIComponent(queryEncoded) : undefined;
      const left = tracesParamsStr
        ? (JSON.parse(tracesParamsStr) as { queries: TempoQuery[]; datasource: string })
        : undefined;

      return [left?.queries?.[0].query, left?.datasource];
    }
    return [];
  }, [tracesData]);
  const [tracesQuery, setTracesQuery] = useState(tracesQueryFromData);
  useEffect(() => {
    setTracesQuery(tracesQueryFromData);
  }, [tracesQueryFromData]);

  const [logsQueryFromData, logsDataSourceUID] = useMemo(() => {
    if (logsData?.url) {
      const tracesUrl = new URL(logsData.url);
      const queryEncoded = tracesUrl.searchParams.get('left');
      const tracesParamsStr = queryEncoded ? decodeURIComponent(queryEncoded) : undefined;
      const left = tracesParamsStr
        ? (JSON.parse(tracesParamsStr) as { queries: LokiQuery[]; datasource: string })
        : undefined;

      return [left?.queries?.[0].expr, left?.datasource];
    }
    return [];
  }, [logsData]);
  const [logsQuery, setLogsQuery] = useState(logsQueryFromData);
  useEffect(() => {
    setLogsQuery(logsQueryFromData);
  }, [logsQueryFromData]);

  const { data: kpiDisplayConfig, isFetching: isFetchingKpiDisplayConfig } = useKpiDisplayConfig();

  const { isAvailable: isK8sAvailable, isFetching: isFetchingK8sData } = useKubernetes({
    entity,
    start: drawerStart,
    end: drawerEnd,
  });

  const { data: dashboards = {}, isLoading: isDashboardsLoading } = useEntityGrafanaDashboards({
    kpiDisplayConfig,
    entity,
    start: initialStartMs,
    end: initialEndMs,
  });

  const {
    data: chartData,
    isFetching: isFetchingMetrics,
    error: metricsError,
  } = useAssertionEntityMetrics({
    enabled: Boolean(activeEntityDetails?.alertName),
    assertionName: activeEntityDetails?.alertName || '',
    startTime: initialStartMs,
    endTime: initialEndMs,
    entityName: activeEntityDetails?.name || '',
    entityType: activeEntityDetails?.type || '',
    rootEntityName: activeEntityDetails?.name,
    rootEntityType: activeEntityDetails?.type,
    labels: activeEntityDetails?.additionalLabels || {},
  });

  const metricsQuery = chartData?.metrics[0]?.query;

  const { data: metricsDataSource, isDataSourceApi } = useMetricsDataSource();
  const { data: tracesDataSource } = useDataSource(tracesDataSourceUID);
  const { data: logsDataSource } = useDataSource(logsDataSourceUID);

  const handleTimeRangeChange = useCallback((timeRange: TimeRange) => {
    setDrawerStart(
      timeRange.raw.from.toString().includes('now') ? (timeRange.raw.from as string) : timeRange.from.valueOf()
    );
    setDrawerEnd(timeRange.raw.to.toString().includes('now') ? (timeRange.raw.to as string) : timeRange.to.valueOf());
    //eslint-disable-next-line
  }, []);

  const handleDashboardStateChange = useCallback((state: string) => {
    const url = new URL(state, window.location.origin);
    const fromParsed = Date.parse(url.searchParams.get('from') || '') || url.searchParams.get('from');
    const toParsed = Date.parse(url.searchParams.get('to') || '') || url.searchParams.get('to');

    if (fromParsed && toParsed) {
      setDrawerStart(fromParsed);
      setDrawerEnd(toParsed);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isDashboardsLoading) {
      TrackingHelper.trackKpiDashboard(dashboards[drawerTab]?.dashName || drawerTab, entity?.type);
    }
    // eslint-disable-next-line
  }, [dashboards[drawerTab]?.dashName, drawerTab]);

  if (!activeEntityDetails) {
    return null;
  }

  const onAppO11yTabChange = ({ query, tab }: { query: string | undefined; tab: 'traces' | 'logs' }) => {
    if (tab === 'traces') {
      setDrawerTab(tab);
      if (query) {
        setTracesQuery(query);
      }
    } else if (tab === 'logs') {
      setDrawerTab(tab);
      if (query) {
        setLogsQuery(query);
      }
    }
  };

  return (
    <Drawer
      size="lg"
      onClose={() => setActiveEntityDetails()}
      title={
        <EntityDetailsMenuComponent
          activeTab={drawerTab}
          setActiveTab={setDrawerTab}
          entity={entity}
          dashboards={dashboards}
          isFetchingEntity={isEntityFetching}
          isFetchingK8sData={isFetchingK8sData}
          isK8sAvailable={isK8sAvailable}
        />
      }
    >
      {isDashboardsLoading ? (
        <div className="w-full h-full flex items-center justify-center">
          <LoadingPlaceholder text="Loading..." />
        </div>
      ) : (
        <>
          {Object.entries(dashboards).map(([dashType, dashboard]) => {
            return drawerTab !== dashType ? null : (
              <EntityDetailsDashboard
                entity={entity}
                dashboard={dashboard}
                drawerStart={drawerStart}
                drawerEnd={drawerEnd}
                logsDatasourceUID={logsDataSourceUID}
                onDashboardStateChange={handleDashboardStateChange}
              />
            );
          })}
          {drawerTab === 'logs' && (
            <div className="w-full h-full flex flex-col">
              {logsQuery && logsDataSource && (
                <EmbeddedLogsExplore
                  query={logsQuery}
                  dataSource={logsDataSource}
                  initialStart={drawerStart}
                  initialEnd={drawerEnd}
                  onTimeRangeChange={handleTimeRangeChange}
                />
              )}
              {isFetchingLogs && <LoadingPlaceholder text="Loading..." />}
              {!isFetchingLogs && (!logsQuery || !logsDataSource) && (
                <Alert
                  title={(logsError as AxiosError<ServerError> | undefined)?.response?.data?.message || 'No Data'}
                />
              )}
            </div>
          )}
          {drawerTab === 'traces' && (
            <div className="w-full h-full flex flex-col">
              {tracesQuery && tracesDataSource && (
                <EmbeddedTracesExplore
                  query={tracesQuery}
                  dataSource={tracesDataSource}
                  initialStart={drawerStart}
                  initialEnd={drawerEnd}
                  onTimeRangeChange={handleTimeRangeChange}
                />
              )}
              {isFetchingTraces && <LoadingPlaceholder text="Loading..." />}
              {!isFetchingTraces && (!tracesQuery || !tracesDataSource) && (
                <Alert
                  title={(tracesError as AxiosError<ServerError> | undefined)?.response?.data?.message || 'No Data'}
                />
              )}
            </div>
          )}
          {!!activeEntityDetails.alertName && drawerTab === 'metrics' && isDataSourceApi(metricsDataSource) && (
            <div className="w-full h-full flex flex-col">
              {metricsQuery && (
                <EmbeddedMetricsExplore
                  query={metricsQuery}
                  dataSource={metricsDataSource}
                  initialStart={drawerStart}
                  initialEnd={drawerEnd}
                  onTimeRangeChange={handleTimeRangeChange}
                />
              )}

              {isFetchingMetrics && <LoadingPlaceholder text="Loading..." />}

              {!isFetchingMetrics && (!metricsQuery || !metricsDataSource) && (
                <Alert
                  title={(metricsError as AxiosError<ServerError> | undefined)?.response?.data?.message || 'No Data'}
                />
              )}
            </div>
          )}
          {drawerTab === 'info' && !!entity && (
            <EntityPropertiesComponent properties={entity.properties} scope={entity.scope} />
          )}

          {drawerTab === 'k8s' && (
            <K8sEntityView
              entity={entity}
              start={drawerStart}
              end={drawerEnd}
              metricsDataSourceUID={metricsDataSource.uid}
              logsDataSourceUID={logsDataSourceUID}
              onTimeRangeChange={handleTimeRangeChange}
            />
          )}
          {drawerTab === 'app-o11y' && (
            <AppO11yEntityView
              entity={entity}
              initialStart={drawerStart}
              initialEnd={drawerEnd}
              onTimeRangeChange={handleTimeRangeChange}
              onTabChange={onAppO11yTabChange}
            />
          )}
          {drawerTab === 'csp' && !!entity && (
            <CspEntityDashboardView
              entity={entity}
              start={drawerStart}
              end={drawerEnd}
              metricsDataSourceUID={metricsDataSource.uid}
              logsDataSourceUID={logsDataSourceUID}
              onTimeRangeChange={handleTimeRangeChange}
            />
          )}
          {drawerTab === 'frontend-o11y' && (
            <FrontendO11yEntityView
              entity={entity}
              initialStart={drawerStart}
              initialEnd={drawerEnd}
              onTimeRangeChange={handleTimeRangeChange}
              logsDataSourceUID={logsDataSourceUID}
              tracesDataSourceUID={tracesDataSourceUID}
            />
          )}
        </>
      )}
    </Drawer>
  );
};

export default connector(EntityDetailsContainer);
