import { css, cx } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Box, Container, Stack, useStyles2, useTheme2 } from '@grafana/ui';
import { AssertionSeverity, EntityAssertion } from 'asserts-types';
import { Tooltip } from 'components/Tooltip/Tooltip.component';
import React, { FunctionComponent, memo, useMemo } from 'react';
import { SEVERITY_COLORS } from '../../app/constants';
import { getIconCodeByType } from '../../helpers/Icon.helper';

interface IProps {
  assertions?: EntityAssertion['assertions'];
}

const AssertionsListInfo: FunctionComponent<IProps> = ({ assertions }) => {
  const styles = useStyles2(getStyles);
  const groupedSelfAssertions = useMemo(() => {
    return (
      (assertions || []).reduce((acc, summary) => {
        if (acc[summary.category]) {
          acc[summary.category].push({ name: summary.assertionName, severity: summary.severity });
        } else {
          acc[summary.category] = [{ name: summary.assertionName, severity: summary.severity }];
        }
        return acc;
      }, {} as Record<string, { name: string; severity: AssertionSeverity }[]>) || {}
    );
  }, [assertions]);

  return (
    <>
      {!!Object.keys(groupedSelfAssertions).length && (
        <Box display="flex" direction="column" gap={1}>
          {Object.keys(groupedSelfAssertions).map((category) => {
            return (
              <Box display="flex" direction="row" key={category}>
                <span className={cx(styles.groupIcon, 'icon-')}>
                  {String.fromCharCode(getIconCodeByType(category) || 0)}
                </span>
                <div className={css({ overflow: 'hidden' })}>
                  {groupedSelfAssertions[category].map(({ name, severity }, index, arr) => {
                    const isLast = index === arr.length - 1;
                    return (
                      <Tooltip content={name} key={severity} placement="left" enterDelay={600}>
                        <Box marginLeft={1} display="flex" paddingBottom={isLast ? 0 : 1}>
                          <div className={cx(styles.colorBar, css({ borderColor: SEVERITY_COLORS[severity] }))} />
                          <p className={styles.label}>{name}</p>
                        </Box>
                      </Tooltip>
                    );
                  })}
                </div>
              </Box>
            );
          })}
        </Box>
      )}
    </>
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    groupIcon: css({
      color: theme.colors.info.shade,
      fontSize: '20px',
    }),
    colorBar: css({
      borderLeft: '3px solid green',
      minHeight: '100%',
      width: '3px',
      marginRight: theme.spacing(1),
      // this negative margin is a bit of a hack to make sure the color bar is continuous across the gaps between rows. There might be a better way
      marginBottom: `-${theme.spacing(1)}`,
      ':last-child': {
        marginBottom: 0,
      },
    }),
    label: css({
      fontSize: theme.typography.bodySmall.fontSize,
      paddingBotton: theme.spacing(1),
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginBottom: 0,
    }),
  };
}

export default memo(AssertionsListInfo);
