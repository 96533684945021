import { defineMessages } from 'react-intl';

export const scope = 'features.EntitiesContainer';

export default defineMessages({
  connections: {
    id: `${scope}.connections`,
    defaultMessage: '{count, plural, =0 {No connections} one {# connection} other {# connections}}',
  },
  settings: {
    id: `${scope}.settings`,
    defaultMessage: 'View settings',
  },
  filter: {
    id: `${scope}.filter`,
    defaultMessage: 'Filter',
  },
  advancedSearch: {
    id: `${scope}.advancedSearch`,
    defaultMessage: 'Advanced search',
  },
  noEntities: {
    id: `${scope}.noEntities`,
    defaultMessage: 'No Entities found',
  },
  noEntitiesForTime: {
    id: `${scope}.noEntities`,
    defaultMessage: 'No Entities found in the time range',
  },
  savedSearches: {
    id: `${scope}.savedSearch`,
    defaultMessage: 'Saved searches',
  },
  navigator: {
    id: `${scope}.navigator`,
    defaultMessage: 'Entities Navigator',
  },
});
