/*
 * Assertions Messages
 *
 * This contains all the text for the Assertions container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'features.AssertionsTopMenu';

export default defineMessages({
  wb: {
    id: `${scope}.wb`,
    defaultMessage: 'RCA Workbench',
  },
  summary: {
    id: `${scope}.summary`,
    defaultMessage: 'Summary',
  },
  timeline: {
    id: `${scope}.timeline`,
    defaultMessage: 'Timeline',
  },
  graph: {
    id: `${scope}.graph`,
    defaultMessage: 'Graph',
  },
  mindMap: {
    id: `${scope}.mindMap`,
    defaultMessage: 'Mind map',
  },
  assertionSearch: {
    id: `${scope}.assertionSearch`,
    defaultMessage: 'Assertion search',
  },
  showSelected: {
    id: `${scope}.showSelected`,
    defaultMessage: 'Show selected only',
  },
  clear: {
    id: `${scope}.clear`,
    defaultMessage: 'Clear',
  },
});
