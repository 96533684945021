import React from 'react';
import { LinkButton } from '@grafana/ui';
import { useReturnToPrevious } from '@grafana/runtime';
import { Entity } from 'asserts-types';

interface IProps {
  entity: Pick<Entity, 'scope' | 'type' | 'properties' | 'name'>;
  from?: string;
  to?: string;
}

export function OpenInAppO11yButton({ entity, from, to }: IProps) {
  const setReturnToPrevious = useReturnToPrevious();
  const href = `/a/grafana-app-observability-app/services/service/${entity.name}?from=${from}&to=${to}`;

  if (entity.type !== 'Service') {
    return null;
  }

  return (
    <LinkButton href={href} onClick={() => setReturnToPrevious('Asserts')} variant="secondary">
      Open in App O11y
    </LinkButton>
  );
}
