/**
 *
 * SloTopMenu
 *
 */

import React, { memo, FunctionComponent, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import messages from './messages';
import { setSearch } from '../../Slo.slice';
import { useIntl } from 'react-intl';
import qs from 'qs';
import { SloQueryParams } from 'asserts-types';
import { Icon, IconButton, Input } from '@grafana/ui';

const connector = connect(
  (state: RootState) => ({
    search: state.slo.search,
  }),
  {
    setSearch,
  }
);

interface IProps {
  changeEndTime: (d: number | string) => void;
  endTime: number | string;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

const SloTopMenu: FunctionComponent<PropsFromRedux & IProps> = ({ search, setSearch, changeEndTime, endTime }) => {
  const intl = useIntl();

  useEffect(() => {
    const queryParams = qs.parse(window.location.search.slice(1)) as unknown as SloQueryParams;
    if (queryParams.search) {
      setSearch(queryParams.search);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Input
        className="w-full"
        value={search}
        placeholder={intl.formatMessage(messages.search)}
        onChange={(e) => setSearch(e.currentTarget.value)}
        prefix={<Icon name="search" />}
        suffix={<IconButton name="times" aria-label="Clear" onClick={() => setSearch('')} />}
      />
    </div>
  );
};

export default connector(memo(SloTopMenu));
