/*
 * GraphNodeRelations Messages
 *
 * This contains all the text for the GraphNodeRelations component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.GraphNodeRelations';

export default defineMessages({
  relations: {
    id: `${scope}.relations`,
    defaultMessage: 'Relations',
  },
  type: {
    id: `${scope}.type`,
    defaultMessage: 'Type',
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Save',
  },
  saving: {
    id: `${scope}.saving`,
    defaultMessage: 'Saving...',
  },
  from: {
    id: `${scope}.from`,
    defaultMessage: 'From',
  },
  to: {
    id: `${scope}.to`,
    defaultMessage: 'To',
  },
  relationModeHint: {
    id: `${scope}.relationModeHint`,
    defaultMessage: 'Press Esc to exit Add relation mode',
  },
  saveRelationError: {
    id: `${scope}.saveRelationError`,
    defaultMessage: 'Cannot establish connection between these nodes',
  },
});
