/**
 *
 * TableHeader
 *
 */

import React, { memo, FunctionComponent, CSSProperties } from 'react';
import { twMerge } from 'tailwind-merge';

interface IProps {
  style?: CSSProperties;
  className?: string;
}

const TableHeader: FunctionComponent<IProps> = ({
  children,
  style,
  className,
}) => {
  return (
    <div
      style={style}
      className={twMerge(
        'flex py-2 items-center justify-between relative h-[35px] bg-panel',
        className ? className : '',
      )}
    >
      {children}
    </div>
  );
};

export default memo<FunctionComponent<IProps>>(TableHeader);
