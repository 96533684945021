/**
 *
 * ThresholdListItem
 *
 */

import React, {
  memo,
  FunctionComponent,
  useState,
  useEffect,
  useRef,
} from 'react';
import { ThresholdItem } from 'asserts-types';
import EditIcon from 'assets/material-icons/edit_FILL0_wght400_GRAD0_opsz24.svg';
import CheckIcon from 'assets/material-icons/done_FILL0_wght400_GRAD0_opsz24.svg';
import CloseIcon from 'assets/material-icons/close_FILL0_wght400_GRAD0_opsz24.svg';
import DeleteOutlineIcon from 'assets/material-icons/delete_FILL0_wght400_GRAD0_opsz24.svg';
import { connect, ConnectedProps } from 'react-redux';
import { pick } from 'lodash';

import thresholdAssertionMap from '../../config/threshold_assertion_map.json';
import has from 'helpers/has.helper';
import { setItemToPopulate } from '../../ManageAssertions.slice';
import assertionsRequest from '../../config/assertions_request.json';
import assertionsResource from '../../config/assertions_resource.json';
import tailwindColors from 'tailwindcss/colors';
import ThresholdChartComponent from '../ThresholdChart/ThresholdChart.component';
import { deleteThreshold, saveThreshold } from 'services/ManageAssertions.service';
import TooltipConfirmationComponent from 'features/Assertions/components/TooltipConfirmation/TooltipConfirmation.component';
import { Input } from '@grafana/ui';
import { IconButton } from 'components/IconButton/IconButton.component';

const connector = connect(
  (state: RootState) => ({
    itemToPopulate: state.manageAssertions.itemToPopulate,
    start: state.app.start,
    end: state.app.end,
  }),
  { setItemToPopulate },
);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IProps {
  item: ThresholdItem;
  fields: string[];
  onUpdate: (
    record: string,
    expr: string,
    labels: Record<string, string>,
  ) => void;
  onDelete?: (
    record: string,
    expr: string,
    labels?: Record<string, string>,
  ) => void;
}

const ThresholdListItem: FunctionComponent<IProps & PropsFromRedux> = ({
  item,
  onUpdate,
  onDelete,
  fields,
  itemToPopulate,
  setItemToPopulate,
  start,
  end,
}) => {
  const [editing, setEditing] = useState(false);
  const [rowValue, setRowValue] = useState(item.expr);
  const [updating, setUpdating] = useState(false);
  const [active, setActive] = useState(false);
  const ref = useRef<HTMLTableRowElement>(null);

  useEffect(() => {
    const options = assertionsRequest.concat(assertionsResource);
    if (
      !itemToPopulate ||
      !options.find(
        (option) =>
          has(thresholdAssertionMap, option.value) &&
          thresholdAssertionMap[option.value] === itemToPopulate.alertname,
      )
    ) {
      return;
    }
    if (
      fields.length &&
      fields.every((field) => itemToPopulate[field] === item.labels?.[field]) &&
      item.assertion === itemToPopulate.alertname
    ) {
      setEditing(true);
      ref.current?.scrollIntoView();
      setActive(true);
      setItemToPopulate(undefined);
      setTimeout(() => setActive(false), 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, item, itemToPopulate]);

  const update = () => {
    setUpdating(true);
    saveThreshold(
      item.record,
      rowValue,
      item.labels,
    ).then(() => {
      setEditing(false);
      onUpdate(item.record, rowValue, item.labels || {});
      setUpdating(false);
    });
  };

  const handleDelete = () => {
    setUpdating(true);
    deleteThreshold(
      item.record,
      item.expr,
      item.labels,
    ).then(() => {
      onDelete && onDelete(item.record, item.expr, item.labels);
      setEditing(false);
      setUpdating(false);
    });
  };

  const labelsForChart = pick(
    item.labels,
    'job',
    'asserts_request_type',
    'asserts_request_context',
    'asserts_source',
    'asserts_resource_type',
    'container',
    'topic',
    'asserts_severity',
  );

  return (
    <>
      <tr
        ref={ref}
        className="transition-all hover:bg-hover"
        style={active ? { backgroundColor: tailwindColors.yellow[50] } : undefined}
      >
        <td className="p-4 divider-b">
          {has<typeof thresholdAssertionMap, string, string>(thresholdAssertionMap, item.record) &&
            thresholdAssertionMap[item.record]}
        </td>
        <td className="p-4 divider-b">{item.record}</td>
        {fields.map((field) => (
          <td className="p-4 divider-b" key={field}>
            {item.labels?.[field] || ''}
          </td>
        ))}
        <td className="p-4 divider-b">
          {editing ? (
            <Input
              value={rowValue}
              onChange={(e) => setRowValue(e.currentTarget.value)}
              autoFocus
              type="text"
              className="max-w-[70px]"
              disabled={updating}
            />
          ) : (
            item.expr
          )}
        </td>
        <td className="p-4 divider-b">
          <div className="flex">
            {editing ? (
              <>
                <IconButton variant="primary" onClick={update} disabled={updating}>
                  <CheckIcon />
                </IconButton>
                <IconButton
                  variant="primary"
                  onClick={() => {
                    setEditing(false);
                    setRowValue(item.expr);
                  }}
                  disabled={updating}
                >
                  <CloseIcon />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton onClick={() => setEditing(true)} variant="primary" disabled={updating}>
                  <EditIcon />
                </IconButton>
                {onDelete && (
                  <TooltipConfirmationComponent action={handleDelete}>
                    <IconButton variant="destructive" disabled={updating}>
                      <DeleteOutlineIcon />
                    </IconButton>
                  </TooltipConfirmationComponent>
                )}
              </>
            )}
          </div>
        </td>
      </tr>
      {editing && item.assertion && item.labels && item.assertion && (
        <tr>
          <td className="p-4 divider-b" colSpan={10}>
            <ThresholdChartComponent
              show
              labels={labelsForChart as Record<string, string>}
              assertion={item.record}
              value={+rowValue}
              thresholdLevel={undefined}
            />
          </td>
        </tr>
      )}
    </>
  );
};

export default connector(memo(ThresholdListItem));
