/*
 * CustomDashboardCard Messages
 *
 * This contains all the text for the CustomDashboardCard component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.CustomDashboardCard';

export default defineMessages({
  entityType: {
    id: `${scope}.entityType`,
    defaultMessage: 'Entity Type',
  },
  datasourceName: {
    id: `${scope}.datasourceName`,
    defaultMessage: 'Datasource Name',
  },
  dashboardURI: {
    id: `${scope}.dashboardURI`,
    defaultMessage: 'Dashboard URI',
  },
  dashboardURIUnique: {
    id: `${scope}.dashboardURIUnique`,
    defaultMessage: 'Dashboard URI should be unique',
  },
  prometheusQuery: {
    id: `${scope}.prometheusQuery`,
    defaultMessage: 'Prometheus Query',
  },
  makeAsPrimary: {
    id: `${scope}.makeAsPrimary`,
    defaultMessage: 'Make as Primary',
  },
});
