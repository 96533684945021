import moment from 'moment';

export const getTimeFromCoordinates = ({
  mouseX,
  tapeEl,
  start,
  end,
  rightOffset = 0,
  leftOffset = 0,
}: {
  mouseX: number;
  tapeEl: HTMLDivElement | null;
  start: number;
  end: number;
  rightOffset?: number;
  leftOffset?: number;
}) => {
  if (!tapeEl) {
    return moment().valueOf();
  }

  const offsetX =
    mouseX - leftOffset - tapeEl.getBoundingClientRect().x + tapeEl.scrollLeft;

  const tapeX =
    (offsetX * 100) / (tapeEl.scrollWidth - rightOffset - leftOffset);

  const rangeLength = end - start;

  return moment(start + (tapeX * rangeLength) / 100).valueOf();
};

export const getRangeInMinutes = (
  startMs: number,
  endMs: number,
  strategy?: 'ceil' | 'floor',
) => {
  return Math[strategy || 'ceil']((endMs - startMs) / (1000 * 60));
};


export const getQueryTimeRange = (startMs: number, endMs: number) => {
  const rangeOffsetInMinutes = getRangeInMinutes(endMs, +new Date(), 'floor');

  return `[${getRangeInMinutes(startMs, endMs)}m]${
    rangeOffsetInMinutes ? ` offset ${rangeOffsetInMinutes}m` : ''
  }`;
};
