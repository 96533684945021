import { DataSourceApi, TimeRange, dateTimeParse } from '@grafana/data';
import {
  EmbeddedScene,
  SceneControlsSpacer,
  SceneFlexLayout,
  SceneQueryRunner,
  SceneRefreshPicker,
  SceneTimePicker,
  SceneTimeRange,
  VizPanel,
} from '@grafana/scenes';
import { PromQuery } from 'asserts-types';
import { CopyLinkButton } from 'components/CopyLinkButton/CopyLinkButton';
import { OpenInExploreButton } from 'components/OpenInExploreButton/OpenInExploreButton';
import { QueryEditorScene } from 'components/QueryEditorScene/QueryEditorScene';
import React, { useEffect, useMemo } from 'react';

interface Props {
  query: string;
  initialStart: string | number;
  initialEnd: string | number;
  dataSource: DataSourceApi;
  onTimeRangeChange?: (timeRange: TimeRange) => void;
}

const EmbeddedMetricsExplore = ({ query, initialStart, initialEnd, dataSource, onTimeRangeChange }: Props) => {
  const [scene, cleanup] = useMemo(() => {
    const from = dateTimeParse(initialStart);
    const to = dateTimeParse(initialEnd);

    const timeRange: TimeRange = {
      from,
      to,
      raw: {
        from: typeof initialStart === 'string' ? initialStart : from,
        to: typeof initialEnd === 'string' ? initialEnd : to,
      },
    };
    const $timeRange = new SceneTimeRange({
      value: timeRange,
      from: typeof initialStart === 'string' ? initialStart : from.toString(),
      to: typeof initialEnd === 'string' ? initialEnd : to.toString(),
    });

    const queryData: PromQuery = { refId: 'A', expr: query };

    const queryRunner = new SceneQueryRunner({
      datasource: {
        uid: dataSource?.uid,
      },
      queries: [queryData],
    });

    const queryEditor = new QueryEditorScene({ dataSource, queryRunner, query: queryData });
    const chart = new VizPanel({ title: 'Graph' });
    const table = new VizPanel({ title: 'Table', pluginId: 'table' });

    const sceneLayout = new SceneFlexLayout({
      direction: 'column',
      children: [queryEditor, chart, table],
    });

    const scene = new EmbeddedScene({
      $data: queryRunner,
      $timeRange,
      controls: [
        new OpenInExploreButton({
          datasourceUid: dataSource.uid,
          query: queryData,
        }),
        new CopyLinkButton({
          datasourceUid: dataSource.uid,
          query: queryData,
        }),
        new SceneControlsSpacer(),

        new SceneTimePicker({
          isOnCanvas: true,
        }),
        new SceneRefreshPicker({
          isOnCanvas: true,
        }),
      ],
      body: sceneLayout,
    });

    const unsubscribableQueryRunner = queryRunner.subscribeToState(() => {
      const dataType = queryRunner.state.queries[0].format;

      if (dataType === 'table') {
        sceneLayout.setState({
          // hiding timeseries in case of dataType is table
          children: [queryEditor, table],
        });
      } else {
        sceneLayout.setState({
          children: [queryEditor, chart, table],
        });
      }
    });

    const unsubscribableTimeRange = $timeRange.subscribeToState((state) => {
      onTimeRangeChange?.(state.value);
    });

    const unsubscribe = () => {
      unsubscribableQueryRunner.unsubscribe();
      unsubscribableTimeRange.unsubscribe();
    };

    return [scene, unsubscribe];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, query]);

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, [cleanup]);

  return <scene.Component model={scene} />;
};

export default EmbeddedMetricsExplore;
