import { unionBy, isEqual } from 'lodash';
import { useMemo } from 'react';
import { convertToNode } from '../../../helpers/Graph.helper';
import useActiveEntityConnections, {
  USE_ACTIVE_ENTITIES_CONNECTIONS_QUERY_KEY,
} from './useActiveEntityConnections';
import { useQueryClient } from '@tanstack/react-query';
import { GraphCustomData } from 'asserts-types';
import { useAppSelector } from 'app/store';

interface IProps {
  graphData: GraphCustomData | undefined;
  connections: GraphCustomData | undefined;
}

export default function useMergeGraphData({ graphData, connections }: IProps) {
  const queryClient = useQueryClient();

  const activeEntitiesHistory = useAppSelector(
    (state) => state.entities.history,
  );

  // useEffect(() => {
  //   if (graphData) {
  //     const nodes = unionBy(mergedGraphData.nodes, connections?.nodes, 'id');
  //     const edges = unionWith(
  //       mergedGraphData.edges,
  //       connections?.edges,
  //       (arrVal, othVal) =>
  //         arrVal.source === othVal.source && arrVal.target === othVal.target,
  //     );

  //     setMergedGraphData({ nodes, edges });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [graphData, connections]);

  const activeEntity = useAppSelector((state) => state.entities.activeEntity);
  const start = useAppSelector((state) => state.app.start);
  const end = useAppSelector((state) => state.app.end);

  const typeFilter = useAppSelector((state) => state.entities.typeFilter);
  const nameSearchQuery = useAppSelector(
    (state) => state.entities.nameSearchQuery,
  );
  const highlightSelected = useAppSelector(
    (state) => state.entities.highlightSelected,
  );
  const graphTypeScopeFilter = useAppSelector(
    (state) => state.entities.graphTypeScopeFilter,
  );
  return useMemo(() => {
    const mergedGraphData = {
      nodes: graphData ? [...graphData?.nodes] : [],
      edges: graphData ? [...graphData?.edges] : [],
    };

    activeEntitiesHistory.forEach((entity) => {
      const currentConnections = queryClient.getQueryData<
        ReturnType<typeof useActiveEntityConnections>['data']
      >([USE_ACTIVE_ENTITIES_CONNECTIONS_QUERY_KEY, entity, start, end]);

      mergedGraphData.nodes = unionBy(
        mergedGraphData.nodes,
        currentConnections?.graphData.nodes,
        'id',
      );

      mergedGraphData.edges = unionBy(
        mergedGraphData.edges,
        currentConnections?.graphData.edges,
        'id',
      );
    });

    let displayedEntitiesListIds: string[] = [];

    const activeNode = convertToNode(activeEntity);

    if (highlightSelected) {
      displayedEntitiesListIds = (activeNode
        ? connections?.nodes.concat(activeNode) || []
        : graphData?.nodes || []
      ).map((e) => e.id);
    }

    const nodes = mergedGraphData.nodes.map((item) => {
      const isSearched =
        typeFilter.indexOf(item.entityType) !== -1 ||
        item.label?.toLowerCase().indexOf(nameSearchQuery?.toLowerCase()) ===
          -1;
      return {
        ...item,
        disabled: highlightSelected
          ? !displayedEntitiesListIds.includes(item.id) || isSearched
          : isSearched,
        hidden: graphTypeScopeFilter
          ? Boolean(
              graphTypeScopeFilter.find(
                (f) => f.type === item.type && isEqual(f.scope, item.scope),
              ),
            )
          : false,
      };
    });

    let edges = mergedGraphData.edges.map((edge) => {
      const sourceNode = nodes.find((item) => edge.source === item.id);
      const targetNode = nodes.find((item) => edge.target === item.id);
      return {
        ...edge,
        disabled: sourceNode?.disabled || targetNode?.disabled,
        hidden: sourceNode?.hidden || targetNode?.hidden,
      };
    });

    return { nodes, edges };
  }, [
    activeEntitiesHistory,
    activeEntity,
    connections?.nodes,
    end,
    graphData,
    graphTypeScopeFilter,
    highlightSelected,
    nameSearchQuery,
    queryClient,
    start,
    typeFilter,
  ]);
}
