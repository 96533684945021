/**
 *
 * CountGroupedEntities
 *
 */

import React, { memo, FunctionComponent } from 'react';
import { isEqual } from 'lodash';
import { GroupedCountEntity, TypeScopeFilter } from 'asserts-types';
import EnvSiteTagComponent from 'components/EnvSiteTag/EnvSiteTag.component';
import { Button } from '@grafana/ui';
import { Tooltip } from 'components/Tooltip/Tooltip.component';

interface IProps {
  groupedEntities: GroupedCountEntity[];
  item: GroupedCountEntity;
  expanded: boolean;
  onClick?: (filter: GroupedCountEntity) => void;
  opacity: number;
  setFilter?: (filter: GroupedCountEntity[]) => void;
  activeFilter: TypeScopeFilter[] | null;
}

const CountGroupedEntitiesItem: FunctionComponent<IProps> = ({
  item,
  expanded,
  onClick,
  opacity,
  setFilter,
  activeFilter,
  groupedEntities,
}) => {
  return (
    <Tooltip
      placement="bottom-start"
      interactive
      content={
        <div className="flex items-center">
          {!(activeFilter?.length === groupedEntities.length - 1 && opacity === 1) && (
            <Button
              size="sm"
              icon="eye"
              fill="text"
              onClick={(e) => {
                e.stopPropagation();
                setFilter?.(groupedEntities.filter((f) => !isEqual(item, f)));
              }}
            >
              Only
            </Button>
          )}
          {activeFilter?.length !== 0 && (
            <Button
              size="sm"
              icon="eye"
              fill="text"
              onClick={(e) => {
                e.stopPropagation();
                setFilter?.([]);
              }}
            >
              All
            </Button>
          )}
        </div>
      }
    >
      <button
        onClick={() => onClick && onClick(item)}
        style={{ opacity }}
        className={`pr-4 pb-2 cursor-pointer bg-transparent border-0 text-left whitespace-nowrap flex flex-col items-start ${
          !expanded ? 'invisible pointer-events-none' : ''
        }`}
      >
        <p className="mt-[5px] text-secondary">
          {item.type}
          <p
            className="font-bold border-2 border-red-600 text-secondary rounded-full inline-block px-2 py-0 text-sm ml-2.5"
            style={{
              border: `2px solid ${item.critical || item.warning || item.color}`,
            }}
          >
            {item.count}
          </p>
        </p>
        <EnvSiteTagComponent scope={item.scope} />
      </button>
    </Tooltip>
  );
};

export default memo(CountGroupedEntitiesItem);
