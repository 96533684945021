/*
 * EntityAdvancedSearch Messages
 *
 * This contains all the text for the EntityAdvancedSearch container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'features.EntityAdvancedSearch';

export default defineMessages({
  search: {
    id: `${scope}.search`,
    defaultMessage: 'Advanced search',
  },
  savedSearches: {
    id: `${scope}.savedSearches`,
    defaultMessage: 'Saved searches',
  },
  noEntities: {
    id: `${scope}.noEntities`,
    defaultMessage: 'No entities found',
  },
  noEntitiesForTime: {
    id: `${scope}.noEntities`,
    defaultMessage: 'No Entities Found in time range',
  },
  selectConnectedEntities: {
    id: `${scope}.selectConnectedEntities`,
    defaultMessage: 'Select one or more connected entities',
  },
});
