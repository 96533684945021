import { ServerError } from 'asserts-types';
import axios, { AxiosError, AxiosInstance } from 'axios';
import SnackbarHelper from 'helpers/Snackbar.helper';
import { callConfig } from './constants';

const SCOPE_URLS = [
  '/api-server/v1/search',
  '/api-server/v1/search/definition',
  '/api-server/v1/entity_type',
  '/api-server/v1/assertions',
  '/api-server/v1/incidents',
  '/api-server/v1/assertion_scores/search',
  '/api-server/v1/assertion_scores',
  '/api-server/v1/incidents',
  '/api-server/v1/assertions/search',
  '/api-server/v1/search/assertions',
  '/api-server/v1/assertion_scores/top_entities',
  '/api-server/v1/slo/top',
  '/api-server/v1/incidents/top',
  '/api-server/v1/assertions/summary',
  'api-server/v1/search/assertions/summary',
];

const ERROR_URLS_BLACK_LIST = [
  '/api-server/v1/integration/trace',
  '/api-server/v1/logging/external',
  '/resources/install-dashboards'
];

export const apiHttpService: AxiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'X-Content-Type-Options': 'nosniff ',
    'X-Frame-Options': 'SAMEORIGIN',
    'Referrer-Policy': 'strict-origin-when-cross-origin',
    'Content-Security-Policy': "default-src 'self'",
  },
});

apiHttpService.interceptors.request.use((req) => {
  const selectedEnv = callConfig.call.selectedEnv;
  const selectedSite = callConfig.call.selectedSite;

  let scopeCriteria;


  if (selectedEnv?.length || selectedSite?.length) {
    scopeCriteria = {
      nameAndValues: {
        env: selectedEnv,
        site: selectedSite,
      },
    };
  }

  const shouldIncludeScope = SCOPE_URLS.some((scopeUrl) => {
    const url = new URL(req.url || '', window.location.origin);
    return url.pathname.endsWith(scopeUrl);
  });

  if (scopeCriteria && shouldIncludeScope) {
    if (req.data && !req.data.scopeCriteria) {
      req.data.scopeCriteria = scopeCriteria;
    }
    if (!req.data) {
      req.data = { scopeCriteria };
    }
  }
  return req;
});

apiHttpService.interceptors.response.use(
  (res) => res,
  (error: AxiosError<ServerError>) => {
    if (
      ((error?.response?.status || 0) >= 500 ||
        (error?.response?.status || 0) === 422 ||
        (error?.response?.status || 0) === 409) &&
      !ERROR_URLS_BLACK_LIST.some((url) => error.config?.url?.endsWith(url))
    ) {
      let data = error.response?.data;

      try {
        if (error.response?.config.responseType === 'text' && typeof error.response?.data === 'string') {
          data = JSON.parse(error.response?.data) as ServerError;
        }
        SnackbarHelper.error(
          data?.message
            ? `${data?.message} ${(data.subErrors || [])
                .map(
                  (item) =>
                    `${(item.field || '') && `${item.field} | `} ${item.message} ${
                      (item.rejectedValue || '') && `| rejected value: ${item.rejectedValue}`
                    }`
                )
                .join('\n')}`
            : 'Unexpected error'
        );
      } catch (err) {
        SnackbarHelper.error('Something went wrong.');
        console.error("Can't parse JSON response");
      }
    }
    return Promise.reject(error);
  }
);
