import { ChartPoint, Threshold, SloType, SloObjective } from 'asserts-types';

interface IProps {
  chartValues: ChartPoint[] | undefined;
  objectives: SloObjective[];
  kind: SloType;
}

export default function useTargetThresholds({ objectives, chartValues, kind }: IProps): Threshold[] {
  if (!chartValues) {
    return [];
  }

  const valuableObjectives = objectives.filter((o) => o.ratio);
  const thresholds: Threshold[] = valuableObjectives.map(({ ratio, value }, index) => ({
    type: 'single',
    name: `Objective ${index + 1}`,
    labels: {},
    values: chartValues.map((v) => ({
      time: v.time,
      value: kind === SloType.Request ? +ratio : +value,
    })),
  }));

  return thresholds;
}
