import { css } from '@emotion/css';

export function getIconStyles() {
  return css({
    userSelect: 'none',
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fill: 'currentColor',
    flexShrink: 0,
    fontSize: '1.5rem',
  });
}
