import {
  AssertionGroup,
  AssertionGroupRule,
  CustomRule,
  NotificationConfig,
  ThresholdRulesRes,
  Metric,
} from 'asserts-types';
import has from '../helpers/has.helper';
import qs from 'qs';
import moment from 'moment';
import Decimal from 'decimal.js';
import axios from 'axios';
import { getQueryTimeRange } from '../helpers/Time.helper';
import { apiHttpService } from 'app/api-http-service';

interface CustomRelationJobProps {
  job: string;
  dst_job: string;
  namespace?: string; // source namespace
  dst_namespace?: string; // destination namespace
  asserts_env?: string; // source env
  asserts_site?: string; // source site
}

interface CustomRelationWorkloadProps {
  workload: string;
  dst_workload: string;
  namespace?: string; // source namespace
  dst_namespace?: string; // destination namespace
  asserts_env?: string; // source env
  asserts_site?: string; // source site
}

interface GrafanaDatasourceResponse {
  result: {
    metric: Record<string, unknown>;
    values?: [number, string][];
    value?: [number, string];
  }[];
}

export const exporterQueryMap = {
  'asserts:resource:usage:limit': 'asserts:resource:usage',
  'asserts:resource:rate:critical': 'asserts:resource:rate5m',
  'asserts:resource:threshold': 'asserts:resource',
  'asserts:resource': 'asserts:resource',
};

export const saveCustomWorkloadRelation = ({
  workload,
  dst_workload,
  namespace, // source namespace
  dst_namespace, // destination namespace
  asserts_env, // source env
  asserts_site, // source site
}: CustomRelationWorkloadProps): Promise<void> =>
  apiHttpService
    .post('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/prom-rules/user-defined-relations', {
      record: 'asserts:relation:routes',
      expr: '1',
      labels: {
        workload,
        dst_workload,
        namespace,
        dst_namespace,
        asserts_env,
        asserts_site,
      },
    })
    .then((response) => response.data);

export const saveCustomJobRelation = ({
  job,
  dst_job,
  namespace, // source namespace
  dst_namespace, // destination namespace
  asserts_env, // source env
  asserts_site, // source site
}: CustomRelationJobProps): Promise<void> =>
  apiHttpService
    .post('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/prom-rules/user-defined-relations', {
      record: 'asserts:relation:routes',
      expr: '1',
      labels: {
        job,
        dst_job,
        namespace,
        dst_namespace,
        asserts_env,
        asserts_site,
      },
    })
    .then((response) => response.data);

export const fetchThresholdRequestList = (): Promise<ThresholdRulesRes> =>
  apiHttpService
    .get('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/threshold-rules/request')
    .then((response) => response.data);

export const fetchThresholdResourceList = (): Promise<ThresholdRulesRes> =>
  apiHttpService
    .get('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/threshold-rules/resource')
    .then((response) => response.data);

export const fetchFailureRules = (): Promise<AssertionGroupRule[]> =>
  apiHttpService
    .get<AssertionGroup[]>(
      '/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/failure-rules?customFailureRules=true'
    )
    .then((response) =>
      response.data.reduce((acc: AssertionGroupRule[], curr) => {
        return acc.concat(
          curr.rules.map((item) => ({
            ...item,
            groupName: curr.name,
          }))
        );
      }, [])
    );

export const saveThreshold = (record: string, expr: string, labels?: Record<string, string>): Promise<void> =>
  apiHttpService
    .post('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/threshold-rule', {
      record,
      expr,
      labels,
    })
    .then((response) => response.data);

export const saveRule = (data: CustomRule): Promise<void> =>
  apiHttpService
    .post(
      '/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/prom-rules/user-defined-alerts',
      data
    )
    .then((response) => response.data);

export const deleteThreshold = (record: string, expr: string, labels?: Record<string, string>): Promise<void> =>
  apiHttpService
    .post('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/threshold-rule/delete', {
      record,
      expr,
      labels,
    })
    .then((response) => response.data);

export const deleteRule = (rule: CustomRule): Promise<void> =>
  apiHttpService
    .post(
      '/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/prom-rules/user-defined-alerts/delete',
      rule
    )
    .then((response) => response.data);

export const fetchJobOptions = (uid: string, start?: number, end?: number): Promise<string[]> => {
  const timeWindowQuery = start && end && getQueryTimeRange(start, end);
  const query =
    start && end
      ? `group by (job) (last_over_time(asserts:mixin_workload_job${timeWindowQuery}))`
      : `group by (job) (asserts:mixin_workload_job)`;

  return apiHttpService
    .post(`/api/datasources/proxy/uid/${uid}/api/v1/query?${qs.stringify({ query })}`)
    .then((response) => response.data.data.result.map((item: any) => item.metric.job || ''));
};

export const fetchJobOptionsForSaturation = (
  uid: string,
  source: string,
  resourceType: string,
  start?: number,
  end?: number
): Promise<string[]> => {
  const timeWindowQuery = start && end && getQueryTimeRange(start, end);

  const query = `group by (job) (last_over_time(asserts:resource{asserts_source="${source}", asserts_resource_type="${resourceType}"}${
    timeWindowQuery || '[2m]'
  }))`;

  return apiHttpService
    .post(`/api/datasources/proxy/uid/${uid}/api/v1/query?${qs.stringify({ query })}`)
    .then((response) => response.data.data.result.map((item: any) => item.metric.job || '').filter((o: any) => o));
};

export const fetchExporterOptions = (
  uid: string,
  assertionKey: string,
  start?: number,
  end?: number
): Promise<string[]> => {
  let queryPart = has<typeof exporterQueryMap, string, string>(exporterQueryMap, assertionKey)
    ? exporterQueryMap[assertionKey]
    : null;

  if (queryPart && assertionKey === 'asserts:resource:usage:limit') {
    queryPart = queryPart + ':limit';
  }

  const timeWindowQuery = start && end && getQueryTimeRange(start, end);

  const query =
    start && end
      ? `group by (asserts_source) (last_over_time(${queryPart}${timeWindowQuery}))`
      : `group by (asserts_source) (${queryPart})`;

  return apiHttpService
    .post(`/api/datasources/proxy/uid/${uid}/api/v1/query?${qs.stringify({ query })}`)
    .then((response) => response.data.data.result.map((item: any) => item.metric.asserts_source || ''));
};

export const fetchRequestTypeOptions = (
  uid: number | string,
  job?: string | null,
  start?: number,
  end?: number
): Promise<string[]> => {
  const timeWindowQuery = start && end && getQueryTimeRange(start, end);

  const query = `group by (asserts_request_type) (last_over_time(asserts:request:type_context${
    job ? `{job="${job}"}` : ''
  }${timeWindowQuery || '[1d]'}))`;

  return apiHttpService
    .post(`/api/datasources/proxy/uid/${uid}/api/v1/query?${qs.stringify({ query })}`)
    .then((response) => response.data.data.result.map((item: any) => item.metric.asserts_request_type || ''));
};

export const fetchResourceTypeOptions = (
  uid: string,
  assertionKey: string,
  exporter: string,
  start?: number,
  end?: number
): Promise<string[]> => {
  let queryPart = has<typeof exporterQueryMap, string, string>(exporterQueryMap, assertionKey)
    ? exporterQueryMap[assertionKey]
    : null;

  if (queryPart && assertionKey === 'asserts:resource:usage:limit') {
    queryPart = queryPart + ':limit';
  }

  const timeWindowQuery = start && end && getQueryTimeRange(start, end);

  const query = `group by (asserts_resource_type) (last_over_time(${queryPart}{asserts_source="${exporter}"}${
    timeWindowQuery || '[2m]'
  }))`;

  return apiHttpService
    .post(`/api/datasources/proxy/uid/${uid}/api/v1/query?${qs.stringify({ query })}`)
    .then((response) => response.data.data.result.map((item: any) => item.metric.asserts_resource_type || ''));
};

export const fetchRequestContextOptions = (
  uid: string,
  job: string | null,
  requestType: string,
  start?: number,
  end?: number
): Promise<string[]> => {
  const timeWindowQuery = start && end && getQueryTimeRange(start, end);

  const query = `group by (asserts_request_context) (last_over_time(asserts:request:type_context{${
    job ? `job="${job}", ` : ''
  }asserts_request_type="${requestType}"}${timeWindowQuery || '[1d]'}))`;

  return apiHttpService
    .post(`/api/datasources/proxy/uid/${uid}/api/v1/query?${qs.stringify({ query })}`)
    .then((response) => response.data.data.result.map((item: any) => item.metric.asserts_request_context || ''));
};

export const fetchContainerOptions = (
  uid: string,
  assertionKey: string,
  exporter: string,
  resourceType: string,
  start?: number,
  end?: number
): Promise<string[]> => {
  const queryPart = has<typeof exporterQueryMap, string, string>(exporterQueryMap, assertionKey)
    ? exporterQueryMap[assertionKey]
    : null;

  const timeWindowQuery = start && end && getQueryTimeRange(start, end);

  const query = `group by (container) (last_over_time(${queryPart}{asserts_source="${exporter}", asserts_resource_type="${resourceType}"}${
    timeWindowQuery || '[2m]'
  }))`;

  return apiHttpService
    .post(`/api/datasources/proxy/uid/${uid}/api/v1/query?${qs.stringify({ query })}`)
    .then((response) => response.data.data.result.map((item: any) => item.metric.container || ''));
};

export const fetchTopicOptions = (
  uid: string,
  assertionKey: string,
  exporter: string,
  resourceType: string,
  start?: number,
  end?: number
): Promise<string[]> => {
  const queryPart = has<typeof exporterQueryMap, string, string>(exporterQueryMap, assertionKey)
    ? exporterQueryMap[assertionKey]
    : null;

  const timeWindowQuery = start && end && getQueryTimeRange(start, end);

  const query = `group by (topic) (last_over_time(${queryPart}{asserts_source="${exporter}", asserts_resource_type="${resourceType}"}${
    timeWindowQuery || '[2m]'
  }))`;

  return apiHttpService
    .post(`/api/datasources/proxy/uid/${uid}/api/v1/query?${qs.stringify({ query })}`)
    .then((response) => response.data.data.result.map((item: any) => item.metric.topic || ''));
};

export const fetchThresholdYml = (): Promise<string> =>
  apiHttpService
    .get('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/threshold-rules', {
      headers: {
        'Content-Type': 'text/plain',
        Accept: 'application/x-yaml',
      },
      data: null,
      responseType: 'text',
    })
    .then((response) => response.data);

export const fetchNotificationYml = (): Promise<string> =>
  apiHttpService
    .get('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/alerts', {
      headers: {
        'Content-Type': 'text/plain',
        Accept: 'application/x-yaml',
      },
      data: null,
      responseType: 'text',
    })
    .then((response) => response.data);

export const fetchSuppressYml = (): Promise<string> =>
  apiHttpService
    .get('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/disabled-alerts', {
      headers: {
        'Content-Type': 'text/plain',
        Accept: 'application/x-yaml',
      },
      data: null,
      responseType: 'text',
    })
    .then((response) => response.data);

export const saveThresholdYml = (text: string): Promise<void> =>
  apiHttpService
    .post('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/threshold-rules/', text, {
      headers: {
        'Content-Type': 'application/x-yaml',
      },
      responseType: 'text',
    })
    .then((response) => response.data);

export const saveNotificationYml = (text: string): Promise<void> =>
  apiHttpService
    .post('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/alerts/', text, {
      headers: {
        'Content-Type': 'application/x-yaml',
      },
      responseType: 'text',
    })
    .then((response) => response.data);

export const saveSuppressYml = (text: string): Promise<void> =>
  apiHttpService
    .post('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/disabled-alerts/', text, {
      headers: {
        'Content-Type': 'application/x-yaml',
      },
      responseType: 'text',
    })
    .then((response) => response.data);

export const fetchNotificationRequestList = (): Promise<{
  alertConfigs: NotificationConfig[];
}> =>
  apiHttpService
    .get('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/alerts/request')
    .then((response) => response.data);

export const fetchRulesList = (): Promise<CustomRule[]> =>
  apiHttpService
    .get('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/prom-rules/user-defined-alerts')
    .then((response) => response.data.groups[0]?.rules || []);

export const fetchNotificationResourceList = (): Promise<{
  alertConfigs: NotificationConfig[];
}> =>
  apiHttpService
    .get('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/alerts/resource')
    .then((response) => response.data);

export const fetchSuppressRequestList = (): Promise<{
  alertConfigs: NotificationConfig[];
}> =>
  apiHttpService
    .get('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/disabled-alerts/request')
    .then((response) => ({
      alertConfigs: response.data.disabledAlertConfigs,
    }));

export const fetchSuppressResourceList = (): Promise<{
  alertConfigs: NotificationConfig[];
}> =>
  apiHttpService
    .get('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/disabled-alerts/resource')
    .then((response) => ({
      alertConfigs: response.data.disabledAlertConfigs,
    }));

export const fetchErrorTypeOptions = (uid: string, job: string | null, requestType: string): Promise<string[]> => {
  const query =
    `group by (asserts_error_type) (asserts:error:total{${
      job ? `job="${job}", ` : ''
    }asserts_request_type=~"${requestType}"} ` +
    `or asserts:error:gauge{${job ? `job="${job}", ` : ''}asserts_request_type=~"${requestType}"} ` +
    `or {asserts_metric_error="total", ${job ? `job="${job}", ` : ''}asserts_request_type=~"${requestType}"})`;

  return apiHttpService
    .post(`/api/datasources/proxy/uid/${uid}/api/v1/query?${qs.stringify({ query })}`)
    .then((response) => response.data.data.result.map((item: any) => item.metric.asserts_error_type || ''));
};

export const fetchRequestMetricType = (uid: string, job: string, requestType?: string | null): Promise<string> => {
  const query = `label_replace(group by(job)(last_over_time({asserts_metric_request="gauge", job="${job}"${
    requestType ? `, asserts_request_type="${requestType}"` : ''
  }}[1d])), "metric_type", "gauge", "", "") or
            label_replace(group by(job)(last_over_time({asserts_metric_request="total", job="${job}"${
    requestType ? `, asserts_request_type="${requestType}"` : ''
  }}[1d])), "metric_type", "counter", "", "")`;

  return apiHttpService
    .post(`/api/datasources/proxy/uid/${uid}/api/v1/query?${qs.stringify({ query })}`)
    .then((response) => {
      const metricTypes = response.data.data.result.map((item: any) => item.metric.metric_type || '');

      // if has more then two metric types and includes counter need use counter
      return metricTypes.length > 1 && metricTypes.includes('counter') ? 'counter' : metricTypes[0] || '';
    });
};

export const deleteNotification = (name: string) =>
  apiHttpService
    .delete(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/alert/${name}`)
    .then((response) => response.data);

export const saveNotification = (item: NotificationConfig): Promise<void> =>
  apiHttpService
    .post('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/alert', item)
    .then((response) => response.data);

export const saveRuleItem = (item: AssertionGroupRule): Promise<void> =>
  apiHttpService
    .post(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/prom-rules/${item.groupName}`, item)
    .then((response) => response.data);

export const deleteRuleItem = (item: AssertionGroupRule): Promise<void> =>
  apiHttpService
    .post(
      `/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/prom-rules/${item.groupName}/delete`,
      item
    )
    .then((response) => response.data);

export const fetchNotificationHealthList = (): Promise<{
  alertConfigs: NotificationConfig[];
}> =>
  apiHttpService
    .get('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/alerts/health')
    .then((response) => response.data);

export const deleteSuppress = (name: string) =>
  apiHttpService
    .delete(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/disabled-alert/${name}`)
    .then((response) => response.data);

export const saveSuppress = (item: NotificationConfig): Promise<void> =>
  apiHttpService
    .post('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/disabled-alert', item)
    .then((response) => response.data);

export const fetchSuppressHealthList = (): Promise<{
  alertConfigs: NotificationConfig[];
}> =>
  apiHttpService
    .get('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/disabled-alerts/health')
    .then((response) => ({
      alertConfigs: response.data.disabledAlertConfigs,
    }));

export const fetchGroupOptions = (allFailureRuleGroups?: boolean): Promise<AssertionGroup[]> => {
  return apiHttpService
    .get<AssertionGroup[]>(
      `/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/failure-rules${
        allFailureRuleGroups ? '?allFailureRuleGroups=true' : ''
      }`
    )
    .then((response) =>
      response.data.map((group) => ({
        ...group,
        rules: group.rules.map((rule) => ({
          ...rule,
          groupName: group.name,
        })),
      }))
    );
};

export const fetchAssertionRuleFilesList = (): Promise<string[]> => {
  return apiHttpService
    .get('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/prom-rules')
    .then((response) => response.data.ruleNames);
};

export const saveRuleFile = (text: string): Promise<void> =>
  apiHttpService
    .put('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/prom-rules/', text, {
      headers: {
        'Content-Type': 'application/x-yaml',
      },
      responseType: 'text',
    })
    .then((response) => response.data);

export const deleteRuleFile = (fileName: string): Promise<void> =>
  apiHttpService
    .delete(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/prom-rules/${fileName}`)
    .then((response) => response.data);

export const fetchRuleFile = (fileName: string): Promise<string> =>
  apiHttpService
    .get(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/prom-rules/${fileName}`, {
      headers: {
        'Content-Type': 'text/plain',
        Accept: 'application/x-yaml',
      },
      data: null,
      responseType: 'text',
    })
    .then((response) => response.data);

export const fetchDotColorsForGroup = (
  uid: string,
  rules: string[]
): Promise<{ alertname: string; asserts_severity: 'warning' | 'critical' }[]> => {
  const query = `group by (alertname, asserts_severity) (asserts:alerts{asserts_alert_category="failure", alertname=~"${rules.join(
    '|'
  )}"})`;

  return apiHttpService
    .post(`/api/datasources/proxy/uid/${uid}/api/v1/query?${qs.stringify({ query })}`)
    .then((response) =>
      response.data.data.result.map((item: any) => ({
        alertname: item.metric.alertname || '',
        asserts_severity: item.metric.asserts_severity || '',
      }))
    );
};

const processGrafanaResponse = (data: GrafanaDatasourceResponse): Metric[] => {
  return data.result.map((metric) => ({
    name: JSON.stringify(metric.metric),
    values:
      metric.values?.map(([time, value]) => ({
        time: moment.unix(time).valueOf(),
        value: new Decimal(value).toDecimalPlaces(3).toNumber(),
      })) || [],
  }));
};

const calculateStepInSeconds = (start: number, end: number) => {
  // If less than one day, fit into 15 seconds, 30 seconds, or 60 seconds
  const hours = (end - start) / (1000 * 60 * 60);
  if (hours < 6) {
    return 15;
  } else if (hours < 12) {
    return 30;
  } else if (hours < 24) {
    return 60;
  } else {
    // if more than one day, less than a month, fit into 1 minute, 2 minute, 5 minute, 10 minute, 20 minute
    const days = hours / 24;
    if (days < 2) {
      return 60;
    } else if (days < 4) {
      return 120;
    } else if (days < 7) {
      return 300;
    } else if (days < 15) {
      return 600;
    } else if (days < 30) {
      return 1200;
    } else {
      // if more than one month, use 1 hour per two months
      const biMonths = days / 61 + 1;
      return biMonths * 60 * 60;
    }
  }
};

export const fetchQueryRange = (uid: string, query: string, startMs: number, endMs: number) => {
  const timeStepIntervalSeconds = calculateStepInSeconds(startMs, endMs);
  const start = moment(startMs).unix().toString();
  const end = moment(endMs).unix().toString();
  const step = timeStepIntervalSeconds + 's';

  return axios
    .post<{ data: GrafanaDatasourceResponse }>(
      `/api/datasources/proxy/uid/${uid}/api/v1/query_range?${qs.stringify({ query, start, end, step })}`
    )
    .then((res) => ({
      metrics: processGrafanaResponse(res.data.data).reverse(),
      timeStepInterval: timeStepIntervalSeconds * 1000,
    }));
};

export const fetchGrafanaQuery = (uid: string, query: string) => {
  return apiHttpService
    .post<{ data: GrafanaDatasourceResponse }>(
      `/api/datasources/proxy/uid/${uid}/api/v1/query?${qs.stringify({ query })}`
    )
    .then((response) => response.data.data.result);
};

export const fetchCustomModelRules = () =>
  apiHttpService
    .get<{ ruleNames: string[] }>('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/model-rules')
    .then((response) => response.data.ruleNames);

export const saveCustomModelRule = (text: string): Promise<void> => {
  return apiHttpService
    .put('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/model-rules/', text, {
      headers: {
        'Content-Type': 'application/x-yaml',
      },
      responseType: 'text',
    })
    .then((response) => response.data);
};

export const deleteCustomModelRule = (rule: string): Promise<void> => {
  return apiHttpService
    .delete(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/model-rules/${rule}`)
    .then((response) => response.data);
};

export const fetchCustomModelRule = (ruleName: string) => {
  return apiHttpService
    .get<string>(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/model-rules/${ruleName}`, {
      headers: {
        'Content-Type': 'text/plain',
        Accept: 'application/x-yaml',
      },
      data: null,
      responseType: 'text',
    })
    .then((response) => response.data);
};

export default {
  fetchThresholdRequestList,
  saveThreshold,
  fetchJobOptions,
  fetchRequestTypeOptions,
  fetchRequestContextOptions,
  deleteThreshold,
  fetchThresholdResourceList,
  fetchExporterOptions,
  fetchResourceTypeOptions,
  fetchContainerOptions,
  fetchTopicOptions,
  saveThresholdYml,
  fetchThresholdYml,
  fetchNotificationRequestList,
  fetchErrorTypeOptions,
  saveNotification,
  deleteNotification,
  fetchNotificationResourceList,
  fetchNotificationYml,
  saveNotificationYml,
  fetchGroupOptions,
  fetchNotificationHealthList,
  deleteSuppress,
  saveSuppress,
  fetchSuppressHealthList,
  fetchSuppressRequestList,
  fetchSuppressResourceList,
  saveSuppressYml,
  fetchSuppressYml,
  fetchAssertionRuleFilesList,
  saveRuleFile,
  deleteRuleFile,
  fetchRuleFile,
  fetchDotColorsForGroup,
  saveRule,
  fetchRulesList,
  deleteRule,
  saveRuleItem,
  fetchFailureRules,
  deleteRuleItem,
  fetchJobOptionsForSaturation,
  fetchRequestMetricType,
  saveCustomWorkloadRelation,
  saveCustomJobRelation,
  fetchQueryRange,
  fetchGrafanaQuery,
  processGrafanaResponse,
  calculateStepInSeconds,
};
