
import { useDispatch, useSelector } from 'react-redux';
import { toggleExpandSummaryItem } from '../Assertions.slice';

export default function useExpandedSummaryItems() {
  const expandedItems = useSelector(
    (state: RootState) => state.assertions.expandedSummaryItems,
  );
  const dispatch = useDispatch();

  const toggleExpand = (item: string) =>
    dispatch(toggleExpandSummaryItem(item));

  const isExpanded = (item: string) => {
    return expandedItems.includes(item);
  };

  return { isExpanded, toggleExpand };
}
