/**
 *
 * AssertionsSummaryResizeHandle
 *
 */

import React, { memo, FunctionComponent, useCallback } from 'react';
import MoreVertIcon from 'assets/material-icons/more_vert_FILL0_wght400_GRAD0_opsz24.svg';
import useSummaryLeftPanel from '../../hooks/useSummaryLeftPanel';
import Draggable, { DraggableEventHandler } from 'react-draggable';
import { LEFT_PANEL_INITIAL_WIDTH } from '../AssertionsSummaryView/constants';

interface IProps {}

const DRAGGABLE_BOUNDS = {
  left: LEFT_PANEL_INITIAL_WIDTH,
  right: LEFT_PANEL_INITIAL_WIDTH + 1000,
};

const AssertionsSummaryResizeHandle: FunctionComponent<IProps> = () => {
  const { width: leftPanelWidth, changeWidth } = useSummaryLeftPanel();

  const handleDrag: DraggableEventHandler = useCallback((e, data) => {
    setTimeout(() => {
      changeWidth(data.x);
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDragStop: DraggableEventHandler = useCallback((e, data) => {
    const captureClick = (e: MouseEvent) => {
      e.stopPropagation(); // Stop the click from being propagated.
      window.removeEventListener('click', captureClick, true); // cleanup
    };

    window.addEventListener(
      'click',
      captureClick,
      true // <-- This registeres this listener for the capture
      //     phase instead of the bubbling phase!
    );
  }, []);

  return (
    <Draggable
      axis="x"
      onDrag={handleDrag}
      onStop={handleDragStop}
      handle="#summary-resize-handle"
      defaultPosition={{ x: leftPanelWidth, y: 0 }}
      positionOffset={{ x: -8, y: 0 }}
      bounds={DRAGGABLE_BOUNDS}
    >
      <div className={`absolute inset-y-0 w-[8px] flex items-center justify-center bg-panel`}>
        <div id="summary-resize-handle" className="text-secondary cursor-ew-resize">
          <MoreVertIcon className='svg-icon' />
        </div>
      </div>
    </Draggable>
  );
};

export default memo(AssertionsSummaryResizeHandle);
