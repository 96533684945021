import { useQuery } from '@tanstack/react-query';
import { TimeRange, dateTimeParse } from '@grafana/data';
import { useMetricsDataSource } from './useMetricsDatasource';

interface Props {
  enabled: boolean;
}

export function useLabelKeys({ enabled }: Props) {
  const { data: ds, isDataSourceApi } = useMetricsDataSource();

  let from = dateTimeParse('now-24h');
  let to = dateTimeParse('now');

  let timeRange: TimeRange = {
    from,
    to,
    raw: {
      from: 'now-24h',
      to: 'now',
    },
  };
  return useQuery(
    ['label-keys'],
    () => {
      if (isDataSourceApi(ds)) {
        return ds.getTagKeys?.({ timeRange, filters: [] }).then((res) => res as { value: string; text: string }[]);
      }
    },
    {
      enabled: isDataSourceApi(ds) && enabled,
    }
  );
}
