
import React from 'react';

export default function AddConnectionsIcon() {
  return (
    <svg className="svg-icon" viewBox="0 0 20 20">
      <g clipPath="url(#clip0_15183_18627)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.38276 3.33879L8.41179 3.57799C5.52569 4.28897 3.38623 6.89589 3.38623 10C3.38623 12.8904 5.24094 15.3502 7.82815 16.2492L8.77275 16.5774L8.11628 18.4666L7.17168 18.1384C3.80506 16.9685 1.38623 13.7681 1.38623 10C1.38623 5.95412 4.17473 2.56198 7.93339 1.63605L8.90437 1.39685L9.38276 3.33879ZM18.6601 9.56683L18.5954 10.5647C18.5831 10.7548 18.5646 10.9433 18.5401 11.1301C18.1113 14.3991 15.8527 17.0874 12.8282 18.1384L11.8836 18.4666L11.2272 16.5774L12.1718 16.2492C14.495 15.4419 16.2285 13.3747 16.5571 10.8699C16.5759 10.7266 16.5901 10.5816 16.5996 10.4354L16.6643 9.43745L18.6601 9.56683Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.6114 6.7258L9.6184 6.8442C9.48905 6.85963 9.36227 6.88279 9.2385 6.91319C7.85035 7.2541 6.82153 8.50818 6.82153 10.0001C6.82153 11.7555 8.24457 13.1785 9.99997 13.1785C11.3948 13.1785 12.5823 12.2794 13.0094 11.0262C13.0651 10.8628 13.1079 10.6932 13.1365 10.5188L13.298 9.5319L15.2718 9.85507L15.1102 10.8419C15.0635 11.1268 14.9936 11.404 14.9025 11.6714C14.2077 13.71 12.2766 15.1785 9.99997 15.1785C7.14 15.1785 4.82153 12.86 4.82153 10.0001C4.82153 7.56592 6.50006 5.52628 8.7615 4.9709C8.96395 4.92118 9.17093 4.88339 9.3816 4.85827L10.3746 4.73987L10.6114 6.7258Z"
          fill="currentColor"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 1.33325V3.83325H19V5.83325H16.5V8.33325H14.5V5.83325H12V3.83325H14.5V1.33325H16.5Z"
        fill="currentColor"
      />
      <defs>
        <clipPath id="clip0_15183_18627">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(1 1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
