import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedKpi, setEntityListColumns } from '../Entities.slice';
import { Entity } from 'asserts-types';
import { useAppSelector } from 'app/store';

const COLUMN_DEFAULTS: Record<string, string[]> = {
  Node: ['instance_type'],
  Pod: ['cpu_requests', 'memory_requests'],
};

const KPI_COLUMN_DEFAULTS: Record<string, string[]> = {
  Node: ['Cpu', 'Memory', 'Disk'],
  Service: [
    'RequestRate',
    'LatencyAverage',
    'ErrorRate',
    'CpuCores',
    'MemoryUsage',
  ],
  ServiceInstance: [
    'RequestRate',
    'LatencyAverage',
    'ErrorRate',
    'CpuCores',
    'MemoryUsage',
  ],
  Pod: ['CpuCores', 'MemoryUsage'],
};

export default function useKpiColumnDefaults(entityList: Entity[]) {
  const dispatch = useDispatch();
  const entityListColumns = useAppSelector(
    (state) => state.entities.entityListColumns,
  );

  useEffect(() => {
    if (entityListColumns.length === 0 && entityList.length) {
      const entityType = entityList[0]?.type;

      if (
        entityList.every((item) => item.type === entityType) &&
        COLUMN_DEFAULTS[entityType]
      ) {
        dispatch(setSelectedKpi(KPI_COLUMN_DEFAULTS[entityType]));
        dispatch(setEntityListColumns(COLUMN_DEFAULTS[entityType]));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
