import { TimeRange, dateTimeParse, getDataSourceRef } from '@grafana/data';
import { config, usePluginComponent } from '@grafana/runtime';
import { DataSourceRef } from '@grafana/schema';
import { LoadingPlaceholder } from '@grafana/ui';
import { APP_PLUGIN_IDS, EXTERNAL_PLUGIN_VIEWS, K8S_SUPPORTED_ENTITY_TYPES } from 'app/constants';
import { Entity } from 'asserts-types';
import useKubernetes from 'components/OpenInKubernetesTrigger/hooks/useKubernetes';
import React, { memo, useMemo } from 'react';
import { isAppEnabled } from 'utils/app-plugin';

interface Props {
  entity: Pick<Entity, 'scope' | 'type' | 'properties' | 'name'> | undefined;
  start: string | number;
  end: string | number;
  metricsDataSourceUID: string | undefined;
  logsDataSourceUID: string | undefined;
  onTimeRangeChange?: (timeRange: TimeRange) => void;
}

interface K8sAppViewProps {
  promDatasourceRef: DataSourceRef;
  lokiDatasourceRef: DataSourceRef;
  entityType: 'Node' | 'Namespace' | 'Workload' | 'KubeCluster' | 'Pod';
  entityName: string;
  clusterName: string;
  namespace: string | undefined;
  workloadType: string | undefined;
  workload: string | undefined;
  initialTimeRange: TimeRange;
  onTimeRangeChange?: (timeRange: TimeRange) => void;
}

const K8sEntityView: React.FC<Props> = ({
  entity,
  start,
  end,
  metricsDataSourceUID,
  logsDataSourceUID,
  onTimeRangeChange,
}) => {
  const timeRange: TimeRange = useMemo(() => {
    const from = dateTimeParse(start);
    const to = dateTimeParse(end);
    return {
      from,
      to,
      raw: {
        from: typeof start === 'string' ? start : from,
        to: typeof end === 'string' ? end : to,
      },
    };
  }, [start, end]);
  const { isAvailable, clusterName, isFetching: isFetchingK8sData } = useKubernetes({ entity, start, end });

  const [, promDatasource] = useMemo(
    () => Object.entries(config.datasources).find(([key, value]) => value.uid === metricsDataSourceUID) || [],
    [metricsDataSourceUID]
  );

  const promDatasourceRef = useMemo(
    () => (promDatasource ? getDataSourceRef(promDatasource) : undefined),
    [promDatasource]
  );

  const [, lokiDatasource] = useMemo(
    () => Object.entries(config.datasources).find(([key, value]) => value.uid === logsDataSourceUID) || [],
    [logsDataSourceUID]
  );

  const lokiDatasourceRef = useMemo(
    () => (lokiDatasource ? getDataSourceRef(lokiDatasource) : undefined),
    [lokiDatasource]
  );

  const { component: K8sAppView, isLoading: isK8sAppViewLoading } = usePluginComponent<K8sAppViewProps>(
    EXTERNAL_PLUGIN_VIEWS.K8S_APP_OBJECT_DETAIL_EXTENSION_V1
  );


  if (entity && isAvailable && K8sAppView && promDatasourceRef && lokiDatasourceRef && clusterName) {
    return (
      <K8sAppView
        promDatasourceRef={promDatasourceRef}
        lokiDatasourceRef={lokiDatasourceRef}
        entityType={entity.type === 'Service' ? 'Workload' : (entity.type as K8sAppViewProps['entityType'])}
        entityName={entity.name}
        clusterName={clusterName}
        namespace={entity.scope?.namespace}
        workloadType={entity.properties?.workload_type?.toString()}
        workload={entity.properties?.workload?.toString()}
        initialTimeRange={timeRange}
        onTimeRangeChange={onTimeRangeChange}
      />
    );
  }

  if (isK8sAppViewLoading || isFetchingK8sData) {
    return <LoadingPlaceholder text="Loading..." />;
  }

  return <div>No data</div>;
};

export default memo(K8sEntityView);
