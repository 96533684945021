/**
 *
 * SloChart
 *
 */

import React, { memo, FunctionComponent, useMemo, MouseEvent } from 'react';
import moment from 'moment';
import ZoomableLineRangeChart from 'components/ZoomableLineRangeChart/ZoomableLineRangeChart.component';
import SloZoomedChart from '../SloZoomedChart/SloZoomedChart.component';
import { ChartName, Slo, SloIncident, ZoomedPos, Scope } from 'asserts-types';
import { useNavigate } from 'react-router-dom';
import { generateCrossingRange } from 'helpers/Chart.helper';
import { useIntl } from 'react-intl';
import messages from './messages';
import { connect, ConnectedProps } from 'react-redux';

import { setItemsZoomedWidth, setItemsZoomedLeft } from '../../Slo.slice';

import { stringToDate } from 'helpers/Date.helper';
import { clearWorkbench, setSearch } from '../../../Assertions/Assertions.slice';
import { setSelectedEnv, setSelectedSite, setTimeRange } from 'features/App/App.slice';
import useSloChart from '../../hooks/useSloChart';
import { FORMAT_YEARLESS_lll } from 'app/moment-locales';
import { Button, LoadingPlaceholder } from '@grafana/ui';
import { prefixRoute } from 'utils/utils.routing';
import { ROUTES, TRACKING_FEATURES } from 'global-constants';
import TrackingHelper from 'helpers/Tracking.helper';

const OPACITY = 0.05;
const DEFAULT_RANGE_WIDTH = 100;
const CHART_PADDING = [12, 0, 35, 32];

const connector = connect(
  (state: RootState) => ({
    itemsZoomedLeft: state.slo.itemsZoomedLeft,
    itemsZoomedWidth: state.slo.itemsZoomedWidth,
    scrollPosition: state.slo.scrollPosition,
  }),
  {
    setItemsZoomedWidth,
    setItemsZoomedLeft,
    clearWorkbench,
    setSelectedEnv,
    setSelectedSite,
    setSearch,
    setTimeRange,
  }
);

interface IProps {
  sloName: string;
  chartName: string;
  sloTargetName: ChartName | string;
  days: number;
  scope?: Scope;
  zoomedXPos: ZoomedPos;
  setZoomedXPos: (pos: { startX: number; endX: number }) => void;
  slo: Slo;
  hash: string;
  sloIncidents?: SloIncident;
  endTime: number | string;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

const SloChart: FunctionComponent<IProps & PropsFromRedux> = ({
  sloName,
  chartName,
  sloTargetName,
  days,
  scope,
  zoomedXPos,
  setZoomedXPos,
  itemsZoomedLeft,
  setItemsZoomedLeft,
  itemsZoomedWidth,
  setItemsZoomedWidth,
  slo,
  hash,
  sloIncidents,
  scrollPosition,
  endTime,
  clearWorkbench,
  setSelectedEnv,
  setSelectedSite,
  setSearch,
  setTimeRange,
}) => {
  const navigate = useNavigate();
  const intl = useIntl();

  const start = useMemo(() => moment(stringToDate(endTime)).add(-days, 'd').valueOf(), [days, endTime]);
  const end = useMemo(() => stringToDate(endTime).valueOf(), [endTime]);

  const { data: chartData, isFetching: isLoadingChart } = useSloChart({
    sloName,
    chartName,
    sloTargetName,
    start,
    end,
    scope,
    enabled: true,
    measurementQuery: slo.measurementQuery,
    threshold: slo.sloTargetDtos[0].threshold,
  });

  const openWorkbench = (e: MouseEvent<HTMLSpanElement>, forceStart?: number, forceEnd?: number) => {
    e.preventDefault();
    clearWorkbench();

    slo.scope?.env && setSelectedEnv([slo.scope?.env]);
    slo.scope?.site && setSelectedSite([slo.scope?.site]);

    setTimeRange({
      start: forceStart || zoomedXPos.startX,
      end: forceEnd || zoomedXPos.endX,
    });
    setSearch(slo.action);
    TrackingHelper.trackNavigateToWorkbench(TRACKING_FEATURES.SLO);
    navigate(prefixRoute(ROUTES.RCA_WORKBENCH), { state: { timestamp: Date.now() } });
  };

  const burndownTooltip = useMemo(() => {
    let leftPoint = 0,
      rightPoint = 0;

    if (chartData) {
      const thresholds = chartData.metrics?.[0];
      leftPoint = thresholds?.values.find((t) => Math.abs(t.time - zoomedXPos.startX) <= chartData.stepMs)?.value || 0;
      rightPoint = thresholds?.values.find((t) => Math.abs(t.time - zoomedXPos.endX) <= chartData.stepMs)?.value || 0;
    }

    const percentUsed = Math.round((leftPoint - rightPoint) * 100) / 100;

    return (
      <div>
        <div>
          {moment(zoomedXPos.startX).format(FORMAT_YEARLESS_lll)} -{' '}
          {moment(zoomedXPos.endX).format(FORMAT_YEARLESS_lll)}
          {!!slo.action && (
            <Button variant="secondary" onClick={(e) => openWorkbench(e)} size="sm" className="ml-2.5">
              {intl.formatMessage(messages.view)}
            </Button>
          )}
        </div>
        <div>{intl.formatMessage(messages.errorBudgetUsed, { val: percentUsed })}</div>
      </div>
    );
    // eslint-disable-next-line
  }, [zoomedXPos]);

  return (
    <>
      <div className="divider rounded py-2 pr-6 pl-2 relative" data-intercom-target="slo-chart">
        {isLoadingChart && (
          <div className="absolute inset-0 flex items-center justify-center">
            <LoadingPlaceholder text="Loading" />
          </div>
        )}
        <p className="font-bold text-center">{chartData?.title}</p>
        <div className="relative h-[300px]">
          {chartData?.metrics && (
            <ZoomableLineRangeChart
              data={chartData.metrics}
              thresholds={chartData.thresholds}
              positionY="left"
              minX={chartData.startTimeMs}
              maxX={chartData.endTimeMs}
              timeStepInterval={chartData.stepMs}
              disableGrid
              padding={CHART_PADDING}
              type={'line'}
              areaUnderLine={ChartName.SLI === chartName}
              showLegend
              fillOpacity={ChartName.ErrorBudgetBurndown === chartName ? 0.3 : OPACITY}
              setZoomedXPos={setZoomedXPos}
              zoomedXPos={zoomedXPos}
              zoomToLastValueUpperThreshold
              crossingRanges={
                ChartName.ErrorBudgetBurndown === chartName
                  ? generateCrossingRange(chartData.thresholds, chartData.metrics)
                  : undefined
              }
              left={itemsZoomedLeft[hash]}
              setLeft={(left) => {
                setItemsZoomedLeft({ itemHash: hash, left });
              }}
              width={itemsZoomedWidth[hash] || DEFAULT_RANGE_WIDTH}
              setWidth={(width) => {
                setItemsZoomedWidth({ itemHash: hash, width });
              }}
              zoomableRangeTooltip={burndownTooltip}
              sloIncidents={sloIncidents}
              scrollPosition={scrollPosition}
              openWorkbench={openWorkbench}
            />
          )}
        </div>
      </div>
      {ChartName.SLI === chartName && (
        <SloZoomedChart
          chartName={chartName}
          sloName={sloName}
          sloTargetName={sloTargetName}
          zoomedXPos={zoomedXPos}
          scope={scope}
          fillOpacity={OPACITY}
          padding={CHART_PADDING}
          sloIncidents={sloIncidents}
          measurementQuery={slo.measurementQuery}
          threshold={slo.sloTargetDtos[0].threshold}
        />
      )}
    </>
  );
};

export default connector(memo(SloChart));
