/**
 *
 * AssertionsGraph
 *
 */

import React, { memo, FunctionComponent, useEffect, useState } from 'react';
import GraphinGraph from '../../../../components/GraphinGraph/GraphinGraph.component';
import { Entity, GraphCustomData } from 'asserts-types';
import { LayoutConfig } from '@antv/g6-core';
import useGraphDataHighlight from './hooks/useGraphDataHighlight';
import { useIntl } from 'react-intl';
import messages from './messages';
import { cleanEdges } from '../../../../helpers/Graph.helper';
import useEntityConnections from 'hooks/useEntityConnections';
import useAssertionsGraph from 'hooks/useAssertionsGraph';
import { unionBy } from 'lodash';
import { Button, LoadingBar } from '@grafana/ui';

interface IProps {
  start: number | string;
  end: number | string;
  layout: LayoutConfig;
  isFetchingWorkbench: boolean;
}

const AssertionsGraph: FunctionComponent<IProps> = ({ start, end, layout, isFetchingWorkbench }) => {
  const [activeEntity, setActiveEntity] = useState<Entity | undefined>();

  const { formatMessage } = useIntl();

  const { isFetching: isFetchingConnections, data: activeConnections } = useEntityConnections({
    start,
    end,
    entityId: activeEntity?.id || 0,
    entityType: activeEntity?.activeConnectedEntityType || '',
    enabled: Boolean(activeEntity),
  });

  const { data: assertionsGraphData, isFetching: isFetchingGraph } = useAssertionsGraph({
    start,
    end,
  });

  // collecting all connections incrementally
  useEffect(() => {
    if (activeConnections) {
      const nodes = unionBy(activeConnections?.nodes || [], graphData.nodes, 'id');
      const edges = unionBy(activeConnections?.edges || [], graphData.edges, 'id');

      setGraphData({ nodes, edges });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeConnections]);

  useEffect(() => {
    if (assertionsGraphData) {
      setGraphData(assertionsGraphData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assertionsGraphData]);

  const [graphData, setGraphData] = useState<GraphCustomData>(
    assertionsGraphData || {
      nodes: [],
      edges: [],
    }
  );

  const isFetching = isFetchingConnections || isFetchingGraph;

  const handleConnectedItemClick = (entity: Entity | undefined) => {
    setActiveEntity(entity);
  };

  const highlightedGraphData = useGraphDataHighlight({
    activeEntity,
    graphData,
  });

  const cleanedEdges = cleanEdges(highlightedGraphData.edges);

  return (
    <>
      {/* hide when the parent LoadingBar is shown */}
      {!isFetchingWorkbench && isFetching && (
        <div className="absolute top-[-1px] inset-x-0">
          <LoadingBar width={300} />
        </div>
      )}

      {!!activeEntity && (
        <Button
          className="absolute top-4 left-4 z-[99]"
          variant="destructive"
          onClick={() => {
            assertionsGraphData && setGraphData(assertionsGraphData);
            setActiveEntity(undefined);
          }}
        >
          {formatMessage(messages.reset)}
        </Button>
      )}
      {!!highlightedGraphData.nodes.length && (
        <GraphinGraph
          data={{ edges: cleanedEdges, nodes: highlightedGraphData.nodes }}
          layout={layout}
          onConnectedItemClick={handleConnectedItemClick}
        />
      )}
    </>
  );
};

export default memo(AssertionsGraph);
