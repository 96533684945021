import { useQuery, useQueryClient } from '@tanstack/react-query';
import { apiHttpService } from 'app/api-http-service';

export function useVendorsList() {
  const queryClient = useQueryClient();
  const isSetupDone = queryClient.getQueryData(['setup-asserts']) !== undefined;

  return useQuery(
    ['vendors-list'],
    () =>
      apiHttpService
        .get<{ items: { name: string; enabled: boolean }[] }>(
          '/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/stack/vendor-integration'
        )
        .then((res) => res.data.items.map((v) => v.name)),
    { keepPreviousData: true, enabled: isSetupDone }
  );
}
