/**
 *
 * GraphContextMenu
 *
 */

import React, { memo, FunctionComponent, useEffect, useCallback, useState, useMemo, useRef } from 'react';
import { G6GraphEvent, Graph, Item } from '@antv/g6';
import { Entity, GraphCustomNode } from 'asserts-types';
import GraphHelper from '../../../../helpers/Graph.helper';

import messages from './messages';
import { useIntl } from 'react-intl';
import { Menu } from '@grafana/ui';
import { Popover, PopoverContent } from 'components/Popover/Popover.component';

export interface CustomContextMenuItem {
  key: string;
  title?: string | React.ReactNode;
  group?: string;
  onClick?: (item: Item) => void;
  setTitle?: (item: Item) => string | React.ReactNode;
  hideOption?: (item: Item) => boolean;
}

export interface IProps {
  options?: CustomContextMenuItem[];
  graph?: Graph;
  setTooltipDisplay: (isVisible: boolean) => void;
  onConnectedItemClick?: (entity: Entity | undefined) => void;
}
const GraphContextMenu: FunctionComponent<IProps> = ({
  options = [],
  graph,
  setTooltipDisplay,
  onConnectedItemClick,
}) => {
  const intl = useIntl();

  const [position, setPosition] = useState<{ x: number; y: number } | undefined>();

  const [activeItem, setActiveItem] = useState<Item | null>(null);

  const connectedOptions = useMemo<CustomContextMenuItem[]>(() => {
    const node = activeItem?.get('model') as GraphCustomNode | null;
    return onConnectedItemClick && node?.connectedEntityTypes
      ? Object.entries(node.connectedEntityTypes)
          .filter(([key]) => key !== 'Assertion')
          .map(([key, value]) => ({
            key,
            title: (
              <div className="flex justify-between w-full min-w-[190px]">
                <div>{key}</div>
                <div className="ml-[20px] text-secondary flex items-center space-x-2">
                  <span>{value}</span>
                </div>
              </div>
            ),
            onClick: (item: Item) => {
              const node = item.get('model') as GraphCustomNode;
              const entity = GraphHelper.convertToEntity(node);
              if (entity) {
                entity.activeConnectedEntityType = key;
              }

              onConnectedItemClick?.(entity);
            },
          }))
      : [];
    //eslint-disable-next-line
  }, [activeItem]);

  const handleContextMenu = useCallback(
    (e: G6GraphEvent) => {
      if (!graph) {
        return;
      }
      e.preventDefault();
      setActiveItem(e.item);

      const coordinates = graph.getClientByPoint(e.item.getBBox().centerX || 0, e.item.getBBox().centerY || 0);

      setPosition(coordinates);

      setTooltipDisplay(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [graph]
  );

  const handleGraphClick = () => {
    setActiveItem(null);
    setTooltipDisplay(true);
  };

  useEffect(() => {
    graph?.on('node:click', handleContextMenu);
    graph?.on('node:contextmenu', handleContextMenu);
    graph?.on('click', handleGraphClick);
    graph?.on('viewportchange', handleGraphClick);
    return () => {
      graph?.off('node:click', handleContextMenu);
      graph?.off('click', handleGraphClick);
      graph?.off('node:contextmenu', handleContextMenu);
      graph?.off('viewportchange', handleGraphClick);
    };
    //eslint-disable-next-line
  }, []);

  return (
    <Popover open={!!activeItem} clientPoint={position} placement="bottom-start" offset={{ mainAxis: 0, crossAxis: 0 }}>
      <PopoverContent>
        <Menu onClick={() => setTooltipDisplay(true)}>
          <Menu.Group
            label={
              onConnectedItemClick
                ? intl.formatMessage(connectedOptions.length ? messages.connected : messages.noConnected)
                : ''
            }
          >
            {options &&
              connectedOptions.concat(options).map((item, index) => {
                if (item.hideOption && activeItem && item.hideOption(activeItem)) {
                  return null;
                }
                return [
                  <Menu.Item
                    key={item.key}
                    className="justify-center"
                    onClick={() => {
                      activeItem && item.onClick && item.onClick(activeItem);
                      setActiveItem(null);
                    }}
                    // @ts-ignore
                    label={item.setTitle && activeItem ? item.setTitle(activeItem) : item.title}
                  />,
                  index === connectedOptions.length - 1 && <Menu.Divider />,
                ];
              })}
          </Menu.Group>
        </Menu>
      </PopoverContent>
    </Popover>
  );
};

export default memo(GraphContextMenu);
