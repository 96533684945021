/*
 * SloChart Messages
 *
 * This contains all the text for the SloChart component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.SloChart';

export default defineMessages({
  errorBudgetUsed: {
    id: `${scope}.errorBudgetUsed`,
    defaultMessage: '{val}% error budget used',
  },
  view: {
    id: `${scope}.view`,
    defaultMessage: 'Open in Workbench',
  },
});
