import React, { useMemo } from 'react';

import { SceneComponentProps, sceneGraph, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import { LinkButton } from '@grafana/ui';
import { DataQuery } from '@grafana/schema';

export interface OpenInExploreButtonState {
  query: DataQuery;
  datasourceUid: string;
}

export type InternalOpenInExploreButtonState = SceneObjectState & OpenInExploreButtonState;

export class OpenInExploreButton extends SceneObjectBase<InternalOpenInExploreButtonState> {
  static Component = OpenInExploreButtonRenderer;

  constructor(state: OpenInExploreButtonState) {
    super(state);
  }
}

function OpenInExploreButtonRenderer({ model }: SceneComponentProps<OpenInExploreButton>) {
  const { query, datasourceUid } = model.useState();

  const { value } = sceneGraph.getTimeRange(model).useState();

  const left = useMemo(
    () => [
      encodeURIComponent(
        JSON.stringify({
          datasource: datasourceUid,
          queries: [query],
          range: {
            from: typeof value.raw.from === 'string' ? value.raw.from : value.from.utc().toString(),
            to: typeof value.raw.to === 'string' ? value.raw.to : value.to.utc().toString(),
          },
        })
      ),
    ],
    [value, datasourceUid, query]
  );

  return (
    <LinkButton icon="compass" data-cy="openInExploreButton" variant="secondary" href={`/explore?left=${left}`}>
      Open in Explore
    </LinkButton>
  );
}
