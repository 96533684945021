/*
 * AssertionSearchResult Messages
 *
 * This contains all the text for the AssertionSearchResult container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'features.AssertionSearchResult';

export default defineMessages({
  entitiesFound: {
    id: `${scope}.entitiesFound`,
    defaultMessage: 'Entities found',
  },
  addAll: {
    id: `${scope}.addAll`,
    defaultMessage: 'Add all',
  },
});
