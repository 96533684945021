/*
 * NotificationsSlo Messages
 *
 * This contains all the text for the NotificationsSlo component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.NotificationsSlo';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Notifications SLO',
  },

  saveSuccess: {
    id: `${scope}.saveSuccess`,
    defaultMessage: 'SLO Notification saved successfuly.',
  },
});
