import { useQuery } from '@tanstack/react-query';
import { TimeRange, dateTimeParse } from '@grafana/data';
import { useMetricsDataSource } from './useMetricsDatasource';

interface Props {
  enabled: boolean;
  key: string | undefined;
}

export function useLabelValues({ enabled, key }: Props) {
  const { data: ds, isDataSourceApi } = useMetricsDataSource();

  let from = dateTimeParse('now-24h');
  let to = dateTimeParse('now');

  let timeRange: TimeRange = {
    from,
    to,
    raw: {
      from: 'now-24h',
      to: 'now',
    },
  };
  return useQuery(
    ['label-values', key],
    () => {
      if (isDataSourceApi(ds)) {
        return ds
          .getTagValues?.({ timeRange, filters: [], key: key || '' })
          .then((res) => res as { value: string; text: string }[]);
      }
    },
    {
      enabled: isDataSourceApi(ds) && enabled && !!key,
    }
  );
}
