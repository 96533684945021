/**
 *
 * AssertionSearchResult
 *
 */

import React, { memo, FunctionComponent, useCallback } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import { AssertionsBoardEntity } from 'asserts-types';
import AddRoundedIcon from 'assets/material-icons/add_FILL0_wght400_GRAD0_opsz24.svg';
import RemoveRoundedIcon from 'assets/material-icons/remove_FILL0_wght400_GRAD0_opsz24.svg';
import {
  addEntityToWorkbench,
  addEntitiesToWorkbench,
  removeEntitiesFromWorkbench,
} from '../../../Assertions/Assertions.slice';
import EnvSiteTagComponent from 'components/EnvSiteTag/EnvSiteTag.component';
import { areEntitiesEqual } from 'helpers/Entity.helper';
import { Button } from '@grafana/ui';
import { IconButton } from 'components/IconButton/IconButton.component';

interface IProps {
  foundAssertions: AssertionsBoardEntity[];
  workbenchEntities: AssertionsBoardEntity[];
  addEntityToWorkbench: typeof addEntityToWorkbench;
  removeEntitiesFromWorkbench: typeof removeEntitiesFromWorkbench;
  addEntitiesToWorkbench: typeof addEntitiesToWorkbench;
}

const AssertionSearchResult: FunctionComponent<IProps> = ({
  foundAssertions,
  addEntityToWorkbench,
  workbenchEntities,
  removeEntitiesFromWorkbench,
  addEntitiesToWorkbench,
}) => {
  const intl = useIntl();

  const addAll = useCallback(() => {
    const entities = foundAssertions.filter(
      (assertion) =>
        !workbenchEntities.some((entity) =>
          areEntitiesEqual(entity, assertion),
        ),
    );
    addEntitiesToWorkbench(entities);
    // eslint-disable-next-line
  }, [foundAssertions, workbenchEntities]);

  return (
    <div className="divider-t px-8 py-8">
      <div className="flex justify-between items-center">
        <p className='text-primary block uppercase'>
          {foundAssertions.length} {intl.formatMessage(messages.entitiesFound)}
        </p>
        <Button fill="text" size="sm" onClick={addAll}>
          {intl.formatMessage(messages.addAll)}
        </Button>
      </div>
      <div className="space-y-2 divide-y dark:divide-neutral-700 mt-2">
        {foundAssertions.map((assertion, index) => {
          const bIndex = workbenchEntities.findIndex((entity) => areEntitiesEqual(entity, assertion));

          return (
            <div className="flex items-center space-x-2 py-1" key={index}>
              {bIndex !== -1 && (
                <IconButton variant="destructive" onClick={() => removeEntitiesFromWorkbench([assertion])}>
                  <RemoveRoundedIcon />
                </IconButton>
              )}
              {bIndex === -1 && (
                <IconButton onClick={() => addEntityToWorkbench(assertion)}>
                  <AddRoundedIcon />
                </IconButton>
              )}
              <div>
                <p>{assertion.name}</p>
                {assertion.scope?.env && <EnvSiteTagComponent scope={assertion.scope} />}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(AssertionSearchResult);
