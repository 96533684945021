import GraphHelper from '../helpers/Graph.helper';
import { stringToDate } from '../helpers/Date.helper';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { GraphCustomData } from 'asserts-types';
import { fetchConnectedEntities } from 'services/Entity.service';

interface IProps {
  start: number | string;
  end: number | string;
  entityId: number;
  entityType: string;
}

export default function useEntityConnections({
  start,
  end,
  entityId,
  entityType,
  enabled,
  onSuccess,
}: IProps & Pick<UseQueryOptions<GraphCustomData>, 'enabled' | 'onSuccess'>) {
  return useQuery(
    ['entity-connections', entityId, entityType, start, end],
    () =>
      fetchConnectedEntities(entityId, entityType, stringToDate(start).valueOf(), stringToDate(end).valueOf()).then(
        (res) => GraphHelper.convertToGraphData(res)
      ),
    {
      enabled,
      staleTime: typeof start === 'string' ? 0 : Infinity,
      onSuccess,
    }
  );
}
