import { useQuery } from '@tanstack/react-query';
import { apiHttpService } from 'app/api-http-service';
import { LogIntegrationsResponse } from 'asserts-types';

export default function useLogConfigs() {
  return useQuery(['log-configs'], () =>
    apiHttpService
      .get<LogIntegrationsResponse>(
        `/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/environment`
      )
      .then((res) => res.data)
  );
}
