/**
 *
 * AssertionsSummaryItem
 *
 */

import React, { memo, FunctionComponent } from 'react';
import { AssertionSummary } from 'asserts-types';
import EnvSiteTagComponent from 'components/EnvSiteTag/EnvSiteTag.component';
import AssertionsSummaryItemMetric from '../AssertionsSummaryItemMetric/AssertionsSummaryItemMetric.component';
import DashboardOutlinedIcon from 'assets/material-icons/dashboard_FILL0_wght400_GRAD0_opsz24.svg';
import { useDispatch } from 'react-redux';
import { setActiveEntityDetails } from 'features/App/App.slice';
import ChevronRightRoundedIcon from 'assets/material-icons/chevron_right_FILL0_wght400_GRAD0_opsz24.svg';
import ExpandMoreRoundedIcon from 'assets/material-icons/keyboard_arrow_down_FILL0_wght400_GRAD0_opsz24.svg';
import useExpandedSummaryItems from '../../hooks/useExpandedSummaryItems';
import useSummaryLeftPanel from '../../hooks/useSummaryLeftPanel';
import AssertionsSummaryRootAreaChartComponent from '../AssertionsSummaryRootAreaChart/AssertionsSummaryRootAreaChart.component';
import TagLabel from 'components/TagLabel/TagLabel';
import { Tooltip } from 'components/Tooltip/Tooltip.component';
import { IconButton } from 'components/IconButton/IconButton.component';

interface IProps {
  item: Omit<AssertionSummary, 'id'> & { id: string };
  start: number;
  end: number;
  timeStepInterval: number | undefined;
}

const AssertionsSummaryItem: FunctionComponent<IProps> = ({
  item,
  start,
  end,
  timeStepInterval,
}) => {
  const dispatch = useDispatch();

  const { isExpanded, toggleExpand } = useExpandedSummaryItems();
  const expanded = isExpanded(item.id);
  const { width: leftPanelWidth } = useSummaryLeftPanel();

  return (
    <div className="group">
      <div
        className="grid"
        style={{
          gridTemplateColumns: `${leftPanelWidth}px auto`,
          height: expanded ? 55 : 65,
        }}
      >
        <div className="flex px-4 items-center justify-between w-full">
          <button
            onClick={() => toggleExpand(item.id)}
            className="bg-transparent flex border-0 items-start cursor-pointer min-w-0 grow text-left"
          >
            {expanded ? <ExpandMoreRoundedIcon className='svg-icon' /> : <ChevronRightRoundedIcon className="svg-icon" />}
            <span className="flex flex-col grow min-w-0">
              <p title={item.name} className="whitespace-nowrap overflow-hidden text-ellipsis font-bold text-md">
                {item.name}
              </p>
              <EnvSiteTagComponent scope={item.scope} />
            </span>
          </button>
          <div className="group-hover:hidden ml-2.5">
            <TagLabel entityType={item.type} scope={item.scope} properties={item.entityProperties} />
          </div>
          <div className="hidden group-hover:block">
            <Tooltip content="KPI" placement="top">
              <IconButton
                variant="primary"
                onClick={() =>
                  dispatch(
                    setActiveEntityDetails({
                      name: item.name,
                      type: item.type,
                      scope: item.scope,
                    })
                  )
                }
              >
                <DashboardOutlinedIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div className="relative">
          {!expanded && (
            <AssertionsSummaryRootAreaChartComponent
              scoreMetrics={item.scoreMetrics || []}
              scoresMaxY={item.scoresMaxY}
              start={start}
              end={end}
              timeStepInterval={timeStepInterval}
            />
          )}
        </div>
      </div>
      {expanded &&
        item.timeLines.map((metric) => (
          <AssertionsSummaryItemMetric
            key={metric.id}
            metric={metric}
            start={start}
            end={end}
            entityName={item.name}
            entityType={item.type}
            scope={item.scope}
            entityProperties={item.entityProperties}
          />
        ))}
      <div style={{ paddingTop: expanded ? 15 : 0 }} className="divider-b" />
    </div>
  );
};

export default memo(AssertionsSummaryItem);
