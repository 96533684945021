/*
 * EntityAdvancedSearch Messages
 *
 * This contains all the text for the EntityAdvancedSearch container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'features.EntityAdvancedSearchItem';

export default defineMessages({
  showEntitiesOption: {
    id: `${scope}.showEntitiesOption`,
    defaultMessage: 'With assertions only',
  },
  showConnectedLabel: {
    id: `${scope}.showConnectedLabel`,
    defaultMessage: 'Connected entities',
  },
});
