import { AppPlugin } from '@grafana/data';
import { AssertsAppJsonData } from 'asserts-types';
import { AlertingHomepageCallout } from 'components/AlertingHomepageCallout';

export function configureGrafanaExtensions(plugin: AppPlugin<AssertsAppJsonData>) {
  plugin.configureExtensionComponent({
    extensionPointId: 'grafana/alerting/home',
    title: 'Asserts',
    description: 'Grafana Asserts App',
    component: AlertingHomepageCallout,
  });
}
