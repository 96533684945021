import { useQuery } from '@tanstack/react-query';
import { Sort } from 'asserts-types';
import { orderBy } from 'lodash';
import { fetchCustomModelRules } from 'services/ManageAssertions.service';

export const USE_CUSTOM_MODEL_RULES_LIST_QUERY_KEY = 'custom-model-rules-list';

export default function useCustomModelRulesList(sort?: Sort) {
  return useQuery([USE_CUSTOM_MODEL_RULES_LIST_QUERY_KEY], fetchCustomModelRules, {
    select: (data) => orderBy(data, undefined, sort?.order),
  });
}
