/*
 * SloTargetRow Messages
 *
 * This contains all the text for the SloTargetRow component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.SloTargetRow';

export default defineMessages({
  min: {
    id: `${scope}.min`,
    defaultMessage: 'min',
  },
  requests: {
    id: `${scope}.requests `,
    defaultMessage: 'requests ',
  },
  day: {
    id: `${scope}.day`,
    defaultMessage: '{count, plural, =0 {0 days} one {# day} other {# days}}',
  },
  compliant: {
    id: `${scope}.compliant`,
    defaultMessage: 'Compliant',
  },
  badEventQuery: {
    id: `${scope}.badEventQuery`,
    defaultMessage: 'Bad Events Query',
  },
  totalEventQuery: {
    id: `${scope}.totalEventQuery`,
    defaultMessage: 'Total Events Query',
  },
  measurementQuery: {
    id: `${scope}.measurementQuery`,
    defaultMessage: 'Measurement Query',
  },
  thresholdValue: {
    id: `${scope}.thresholdValue`,
    defaultMessage: 'Threshold Value',
  },
  usedBudget: {
    id: `${scope}.usedBudget`,
    defaultMessage: 'Used: {errorBudget} {type}',
  },
  availableBudget: {
    id: `${scope}.availableBudget`,
    defaultMessage: 'Available: {errorBudget} {type}',
  },
  viewInWorkbench: {
    id: `${scope}.viewInWorkbench`,
    defaultMessage: 'View in Workbench',
  },
  thresholds: {
    id: `${scope}.thresholds`,
    defaultMessage: 'Thresholds',
  },
  copy: {
    id: `${scope}.copy`,
    defaultMessage: 'Copy URL',
  },
  copied: {
    id: `${scope}.copied`,
    defaultMessage: 'Copied!',
  },
});
