
import React from 'react';

export default function NullZeroIcon() {
  return (
    <svg className="svg-icon" viewBox="0 0 21 17" style={{ transform: 'scale(0.9)' }}>
      <path
        d="M14.54 8L11 4.46L12.41 3.05L14.53 5.17L18.77 0.93L20.18 2.34L14.54 8ZM9 4H0V6H9V4ZM19 10.41L17.59 9L15 11.59L12.41 9L11 10.41L13.59 13L11 15.59L12.41 17L15 14.41L17.59 17L19 15.59L16.41 13L19 10.41ZM9 12H0V14H9V12Z"
        fill="currentColor"
      />
    </svg>
  );
}
