import { useQuery } from '@tanstack/react-query';
import { Entity, EntityDash, KpiDisplayConfig, EntityDashboardResponse } from 'asserts-types';
import { useCallback } from 'react';
import { fetchEntityGrafanaDashboard } from 'services/Entity.service';
interface UseEntityGrafanaDashboardsParams {
  entity?: Entity;
  start?: number;
  end?: number;
  kpiDisplayConfig?: KpiDisplayConfig;
}
export default function useEntityGrafanaDashboards({
  entity,
  start,
  end,
  kpiDisplayConfig,
}: UseEntityGrafanaDashboardsParams) {
  return useQuery(
    ['entity-dashboards', entity, start, end],
    () => {
      if (!entity || !start || !end) {
        return Promise.reject();
      }
      return fetchEntityGrafanaDashboard(entity.name, entity.type, start, end, {
        ...entity.properties,
        ...entity.scope,
      });
    },
    {
      enabled: Boolean(entity && start && end),
      keepPreviousData: true,
      select: useCallback(
        (data: EntityDashboardResponse) => {
          const result: Record<string, EntityDash> = {};
          if (data?.defaultDash.dashUri && kpiDisplayConfig?.defaultDashboard) {
            result.kpi = data.defaultDash;
          }
          if (data?.runtimeDash.dashUri && kpiDisplayConfig?.runtimeDashboard) {
            result.runtimeDash = data.runtimeDash;
          }
          if (data?.frameworkDash.dashUri && kpiDisplayConfig?.frameworkDashboard) {
            result.frameworkDash = data.frameworkDash;
          }
          if (data?.additionalDash.dashUri && kpiDisplayConfig?.additionalDashboard) {
            result.additionalKpi = data.additionalDash;
          }
          if (data?.customDash.dashUri) {
            result.customKpi = data.customDash;
          }

          return result;
        },
        [
          kpiDisplayConfig?.defaultDashboard,
          kpiDisplayConfig?.runtimeDashboard,
          kpiDisplayConfig?.frameworkDashboard,
          kpiDisplayConfig?.additionalDashboard,
        ]
      ),
    }
  );
}
