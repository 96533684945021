import { useQuery, useQueryClient } from '@tanstack/react-query';
import { apiHttpService } from 'app/api-http-service';
import { AssertsBackendStatus } from 'asserts-types';
import { AssertsFeatures, isFeatureToggleEnabled } from 'utils/feature-toggles';

export default function useBackendStatus() {
  const queryClient = useQueryClient();
  const isSetupDone = queryClient.getQueryData(['setup-asserts']) !== undefined;

  return useQuery(
    ['asserts-backend-status'],
    () =>
      apiHttpService
        .get<{ status: AssertsBackendStatus; enabled: false }>(
          '/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/stack/status'
        )
        .then((res) => res.data),
    {
      staleTime: Infinity,
      enabled: isSetupDone,
      // check status every 10 seconds until asserts is enabled
      refetchInterval: (data) => (data?.enabled ? false : 10000),
    }
  );
}
