import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { RelabelRule, RelabelRuleType } from 'asserts-types';
import messages from '../messages';
import useRelabelRulesFile from './useRelabelRulesFile';
import useRelabelRulesList from './useRelabelRulesList';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { saveRelabelRules } from 'services/RelabelRules.service';
import SnackbarHelper from 'helpers/Snackbar.helper';

interface IProps {
  defaultValues?: RelabelRule;
  onSuccess?: () => void;
}

export default function (props?: IProps) {
  const { type } = useParams();

  const { handleSubmit, control, reset } = useForm<RelabelRule>({
    defaultValues: props?.defaultValues || {
      selector: '',
      replacement: '',
      target_label: '',
      drop: false,
      join_labels: [],
      ranked_choice: [],
    },
  });

  const { refetch: refetchYmlFile } = useRelabelRulesFile();

  const queryClient = useQueryClient();
  let { data: rulesList = [] } = useRelabelRulesList();

  const getUpdatedRulesList = (updatedItem: RelabelRule) => {
    const isUpdate = updatedItem.index !== undefined;

    return isUpdate
      ? rulesList.map((r) => (r.index === updatedItem.index ? updatedItem : r))
      : rulesList.concat(updatedItem);
  };

  const mutation = useMutation({
    mutationFn: (updatedData: RelabelRule[]) => {
      return saveRelabelRules(type as RelabelRuleType, updatedData);
    },
    onSuccess: (_, updatedData) => {
      SnackbarHelper.success(messages.saved);

      queryClient.setQueryData<RelabelRule[]>(
        ['relabelRulesList', type],
        updatedData,
      );

      props?.onSuccess && props.onSuccess();
      reset();
      refetchYmlFile();
    },
  });

  const submit = (data: RelabelRule) => {
    mutation.mutateAsync(getUpdatedRulesList(data));
  };

  const onDelete = (item: RelabelRule) => {
    const newList = rulesList.filter((r) => r.index !== item.index);
    mutation.mutateAsync(newList);
  }

  return {
    onSubmit: handleSubmit(submit),
    onDelete,
    control,
    ...mutation,
  };
}
