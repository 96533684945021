/**
 *
 * TopInsightsViewSettings
 *
 */

import React, { memo, FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';

import messages from './messages';
import {
  setHideOldAssertions,
  setOldAssertionHours,
} from '../../../Assertions/Assertions.slice';
import { setScaleType } from '../../TopInsights.slice';
import ShowEnvSiteTagSettingComponent from '../../../../components/ShowEnvSiteTagSetting/ShowEnvSiteTagSetting.component';
import { Input, Switch } from '@grafana/ui';
import FormControlLabel from 'components/FormControlLabel/FormControlLabel.component';

interface IProps {}

const connector = connect(
  (state: RootState) => ({
    hideOldAssertions: state.assertions.hideOldAssertions,
    oldAssertionHours: state.assertions.oldAssertionHours,
    scaleType: state.topInsights.scaleType,
  }),
  {
    setHideOldAssertions,
    setOldAssertionHours,
    setScaleType,
  },
);
type PropsFromRedux = ConnectedProps<typeof connector>;

const TopInsightsViewSettings: FunctionComponent<IProps & PropsFromRedux> = ({
  oldAssertionHours,
  hideOldAssertions,
  setHideOldAssertions,
  setOldAssertionHours,
  scaleType,
  setScaleType,
}) => {
  const intl = useIntl();

  return (
    <div className="flex flex-col">
      <ShowEnvSiteTagSettingComponent />
      <div className="flex items-center gap-3">
        <FormControlLabel
          control={
            <Switch
              color="primary"
              value={hideOldAssertions}
              onChange={() => setHideOldAssertions(!hideOldAssertions)}
            />
          }
          label={intl.formatMessage(messages.hideAssertionsOld)}
        />
        <Input
          type="number"
          value={oldAssertionHours}
          onChange={(e) => setOldAssertionHours(parseInt(e.currentTarget.value, 10))}
          className="w-[80px]"
          suffix="hrs"
        />
      </div>
      <FormControlLabel
        control={
          <Switch
            color="primary"
            value={scaleType === 'log'}
            onChange={() => setScaleType(scaleType === 'log' ? 'linear' : 'log')}
          />
        }
        label={intl.formatMessage(messages.lgScale)}
      />
    </div>
  );
};

export default connector(memo(TopInsightsViewSettings));
