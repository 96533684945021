/**
 *
 * EntitiesSortBy
 *
 */

import React, { memo, FunctionComponent, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { setOrder, setOrderBy } from '../../Entities.slice';
import messages from './messages';
import { useAppSelector } from 'app/store';
import { Icon, InlineField, Select } from '@grafana/ui';

interface IProps {}

const EntitiesSortBy: FunctionComponent<IProps> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const orderBy = useAppSelector((state) => state.entities.orderBy);
  const order = useAppSelector((state) => state.entities.order);

  const options = useMemo(
    () => [
      {
        label: formatMessage(messages.az),
        orderBy: 'name',
        action: () => {
          dispatch(setOrder('asc'));
          dispatch(setOrderBy('name'));
        },
      },
      {
        label: formatMessage(messages.types),
        orderBy: 'type',
        action: () => {
          dispatch(setOrder('asc'));
          dispatch(setOrderBy('type'));
        },
      },
      {
        label: formatMessage(messages.assertions),
        orderBy: 'assertionCount',

        action: () => {
          dispatch(setOrder('desc'));
          dispatch(setOrderBy('assertionCount'));
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <InlineField label="Sort by">
      <Select
        options={options.map((o) => ({ label: o.label, value: o.orderBy }))}
        onChange={(v) => {
          options.find((o) => v.value === o.orderBy)?.action();
        }}
        value={orderBy}
        width="auto"
        prefix={<Icon name={order === 'asc' ? 'sort-amount-up' : 'sort-amount-down'} />}
      />
    </InlineField>
  );
};

export default memo(EntitiesSortBy);
