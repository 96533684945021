import { defineMessages } from 'react-intl';

export const scope = 'features.IncidentsFilterSettings';

export default defineMessages({
  slo_alert: {
    id: `${scope}.slo_alert`,
    defaultMessage: 'SLO',
  },
  assertion_alert: {
    id: `${scope}.assertion_alert`,
    defaultMessage: 'Assertion',
  },
  filter: {
    id: `${scope}.filter`,
    defaultMessage: 'Type Filter',
  },
});
