/*
 * TooltipConfirmation Messages
 *
 * This contains all the text for the TooltipConfirmation component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.TooltipConfirmation';

export default defineMessages({
  question: {
    id: `${scope}.question`,
    defaultMessage: 'Are you sure?',
  },
  delete: {
    id: `${scope}.delete`,
    defaultMessage: 'Delete',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
});
