import { Icon } from '@grafana/ui';
import React, { CSSProperties, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  active?: boolean;
  direction?: 'asc' | 'desc';
  onClick: () => void;
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
}

export default function TableSortButton({ active, direction, onClick, children, style, className }: Props) {
  return (
    <button
      className={twMerge('hover:text-primary hover:underline bg-transparent p-0 border-0', className)}
      onClick={onClick}
      style={style}
    >
      {children} {active && (direction === 'asc' ? <Icon name="arrow-up" /> : <Icon name="arrow-down" />)}
    </button>
  );
}
