import { config } from '@grafana/runtime';
import { OrgRole } from '@grafana/data';

export const isViewer = config.bootData.user.orgRole === OrgRole.Viewer;

export const isAdmin = config.bootData.user.orgRole === OrgRole.Admin;

const ASSERTS_PERMISSION_PREFIX = 'grafana-asserts-app';

type Actions = 'RulesRead' | 'RulesWrite' | 'ConfigRead' | 'ConfigWrite' | 'AssertionsRead';

export enum Action {
  READ = 'read',
  WRITE = 'write',
}

export type UserAction = {
  permission: string;
  fallbackMinimumRoleRequired: OrgRole;
};

export enum Resource {
  ASSERTIONS = 'assertions',
  RULES = 'rules',
  CONFIG = 'config',
}

const roleMapping: Record<OrgRole, number> = {
  [OrgRole.Admin]: 0,
  [OrgRole.Editor]: 1,
  [OrgRole.Viewer]: 2,
  [OrgRole.None]: 3,
};

const userHasMinimumRoleRequired = (minimumRoleRequired: OrgRole): boolean =>
  roleMapping[config.bootData.user.orgRole as OrgRole] <= roleMapping[minimumRoleRequired];

const generatePermissionString = (resource: Resource, action: Action, includePrefix: boolean): string =>
  `${includePrefix ? `${ASSERTS_PERMISSION_PREFIX}.` : ''}${resource}:${action}`;

const constructAction = (
  resource: Resource,
  action: Action,
  fallbackMinimumRoleRequired: OrgRole,
  includePrefix = true // pass false if checking for non-Asserts permissions i.e. reading folders
): UserAction => ({
  permission: generatePermissionString(resource, action, includePrefix),
  fallbackMinimumRoleRequired,
});

export const UserActions: { [action in Actions]: UserAction } = {
  AssertionsRead: constructAction(Resource.ASSERTIONS, Action.READ, OrgRole.Viewer),
  RulesRead: constructAction(Resource.RULES, Action.READ, OrgRole.Editor),
  RulesWrite: constructAction(Resource.RULES, Action.WRITE, OrgRole.Editor),
  ConfigRead: constructAction(Resource.CONFIG, Action.READ, OrgRole.Editor),
  ConfigWrite: constructAction(Resource.CONFIG, Action.WRITE, OrgRole.Editor),
};

export const UserCan = {
  ReadAssertions: [UserActions.AssertionsRead],
  ReadRules: [UserActions.RulesRead],
  WriteRules: [UserActions.RulesWrite],
  ReadConfig: [UserActions.ConfigRead],
  WriteConfig: [UserActions.ConfigWrite],
};

export const isUserActionAllowed = (userActions: UserAction[]): boolean => {
  // If any of the feature flags are disabled, we should fall back to Grafana roles
  const rbacSupported = config.featureToggles.accessControlOnCall;
  if (rbacSupported) {
    return userActions.every((action) => config.bootData.user.permissions?.[action.permission] ?? false);
  } else {
    return userActions.every((action) => userHasMinimumRoleRequired(action.fallbackMinimumRoleRequired));
  }
};
