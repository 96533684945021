/**
 *
 * ConnectedEntitiesMenuItem
 *
 */

import React, { FunctionComponent, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import { Scope } from 'asserts-types';
import AddIcon from 'assets/material-icons/add_FILL0_wght400_GRAD0_opsz24.svg';
import EntityListItemComponent from '../../../Entities/components/EntityListItem/EntityListItem.component';
import { connect, ConnectedProps } from 'react-redux';

import { addEntitiesToWorkbench } from '../../Assertions.slice';
import { setActiveEntityDetails } from 'features/App/App.slice';
import CheckIcon from 'assets/material-icons/done_FILL0_wght400_GRAD0_opsz24.svg';
import useConnectedEntities from 'hooks/useConnectedEntities';
import { LoadingPlaceholder } from '@grafana/ui';
import { Tooltip } from 'components/Tooltip/Tooltip.component';
import { IconButton } from 'components/IconButton/IconButton.component';

interface IProps {
  type: string;
  count: number;
  start: number;
  end: number;
  scope: Scope | undefined;
  entityId: number;
  onNavigate: (n: string, t: string) => void;
}

const connector = connect((state: RootState) => ({}), {
  addEntitiesToWorkbench,
  setActiveEntityDetails,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedEntitiesMenuItem: FunctionComponent<IProps & PropsFromRedux> = ({
  type,
  count,
  start,
  end,
  scope,
  entityId,
  addEntitiesToWorkbench,
  setActiveEntityDetails,
  onNavigate,
}) => {
  const intl = useIntl();

  const [expanded, setExpanded] = useState(false);
  const [added, setAdded] = useState(false);

  const {
    data: connectedEntities,
    status,
    refetch,
    dataUpdatedAt,
  } = useConnectedEntities({
    entityId,
    type,
    start,
    end,
    scope,
    enabled: expanded,
  });

  const handleAddAllToWorkbench = () => {
    if (connectedEntities) {
      addEntitiesToWorkbench(connectedEntities);
      setAdded(true);
    } else {
      refetch().then((res) => {
        if (res.data) {
          addEntitiesToWorkbench(res.data);
          setAdded(true);
        }
      });
    }
  };

  return (
    <>
      <div
        className={`flex justify-between items-center my-2 hover:bg-hover rounded-md py-1 ${
          expanded ? 'bg-primary/5' : ''
        }`}
      >
        <button
          className="flex items-center bg-transparent border-0 cursor-pointer grow"
          onClick={() => setExpanded(!expanded)}
        >
          <i className={`icon-rounded-${expanded ? 'down' : 'right'} text-primary text-lg mr-[10px]`} />
          <p>{type}</p>
        </button>
        <div className="flex items-center">
          <p className="text-secondary">{count}</p>
          <div className="ml-[5px] mr-1">
            <Tooltip content={intl.formatMessage(messages.addToWb)} placement="right">
              <IconButton variant="primary" onClick={handleAddAllToWorkbench} disabled={added || status === 'loading'}>
                {added ? <CheckIcon /> : <AddIcon />}
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
      {expanded &&
        status === 'success' &&
        connectedEntities
          ?.filter((item) => item.id !== entityId)
          .map((item) => (
            <EntityListItemComponent
              key={item.id}
              item={item}
              onClick={() => onNavigate(item.name, item.type)}
              setActiveEntityDetails={setActiveEntityDetails}
              lastUpdateTime={dataUpdatedAt}
            />
          ))}
      {expanded && status === 'loading' && <LoadingPlaceholder text="Loading..." className="m-0 py-2 text-center" />}
    </>
  );
};

export default connector(ConnectedEntitiesMenuItem);
