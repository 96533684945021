import { apiHttpService } from 'app/api-http-service';
import { AuthResponse, DisplayConfig, KpiDisplayConfig, LicenseLimits } from 'asserts-types';
import { config } from '@grafana/runtime';
import { isEmpty } from 'lodash';
import { DEFAULT_KPI_DISPLAY_CONFIG } from 'features/Entities/constants';
import { isAdmin } from 'utils/permissions';

const getColorByName = config.theme2.visualization.getColorByName;

const defaultDisplayConfig: DisplayConfig = {
  graph: {
    overrides: { entities: {}, edges: {} },
    defaultEntity: { color: config.theme2.visualization.getColorByName('purple'), shape: 'circle', icon: 'none' },
    defaultEdge: { color: '#000000' },
    entities: {
      Schema: { color: '#B9ABA2', shape: 'circle', icon: 'none' },
      KubeCluster: { color: '#326CE5', shape: 'circle', icon: 'none' },
      Pod: { color: '#A6CFD5', shape: 'circle', icon: 'none' },
      Pods: { color: '#A6CFD5', shape: 'circle', icon: 'none' },
      Node: { color: '#D2B48C', shape: 'square', icon: 'server' },
      Assertion: { color: '#FFFFFF', shape: 'triangle', icon: 'exclamation' },
      Deployment: { color: '#C2E7D9', shape: 'circle', icon: 'none' },
      Service: { color: '#6B8E23', shape: 'square', icon: 'none' },
      DataSource: { color: '#4479A1', shape: 'circle', icon: 'none' },
      ServiceInstance: { color: '#8FBC8B', shape: 'circle', icon: 'none' },
      ServiceInstances: { color: '#8FBC8B', shape: 'circle', icon: 'none' },
      Namespace: { color: '#F2B880', shape: 'circle', icon: 'none' },
      Topic: { color: '#BC8F8F', shape: 'square', icon: 'none' },
      KafkaBroker: { color: '#32CD32', shape: 'square', icon: 'none' },
      Volume: { color: '#AFBC88', shape: 'circle', icon: 'none' },
      DaemonSet: { color: '#FF6347', shape: 'square', icon: 'none' },
      StatefulSet: { color: '#FFB6C1', shape: 'circle', icon: 'none' },
      ReplicaSet: { color: '#7B68EE', shape: 'square', icon: 'none' },
      KubeService: { color: '#B1BDBE', shape: 'circle', icon: 'none' },
      KubeControlPlane: { color: '#C0C0C0', shape: 'circle', icon: 'none' },
      NodeGroup: { color: '#EDAFB8', shape: 'square', icon: 'none' },
    },
    edges: {
      HOSTS: { color: getColorByName('light-purple') },
      GROUPS: { color: getColorByName('dark-blue') },
      ROUTES: { color: getColorByName('dark-green') },
      KUBE_CONTROL_PLANE: { color: getColorByName('blue') },
      CAUSING: { color: getColorByName('dark-red') },
      KUBE_SYSTEM: { color: getColorByName('dark-orange') },
      CALLS: { color: '#808080' },
      KUBE_API_SERVER: { color: '#708090' },
      CONTROLS: { color: getColorByName('dark-purple') },
    },
  },
};

export interface EnvSiteValuesRes {
  scopeValues: {
    env?: string[];
    site?: string[];
  };
}

export const fetchGraphDisplayConfig = (): Promise<DisplayConfig> =>
  apiHttpService
    .get(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/display/graph`)
    .then((response) => {
      const entityOverrides = response.data?.entities ?? {};
      const edgeOverrides = response.data?.edges ?? {};
      return {
        graph: {
          defaultEdge: defaultDisplayConfig.graph.defaultEdge,
          defaultEntity: defaultDisplayConfig.graph.defaultEntity,
          entities: defaultDisplayConfig.graph.entities,
          edges: defaultDisplayConfig.graph.edges,
          overrides: {
            entities: entityOverrides,
            edges: edgeOverrides,
          },
        },
      };
    })
    .catch(() => defaultDisplayConfig);

export const updateGraphDisplayConfig = (displayConfig: Partial<DisplayConfig['graph']>) => {
  return apiHttpService
    .post('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/display/graph', displayConfig)
    .then((response) => response.data);
};

export const deleteGraphDisplayEntityConfig = (entityType: string) => {
  return apiHttpService
    .delete(
      `/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/display/graph/entity_type/${entityType}`
    )
    .then((response) => response.data);
};

export const fetchKpiDisplayConfig = (): Promise<KpiDisplayConfig> =>
  apiHttpService
    .get(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/display/kpi`)
    .then((response) => {
      if (isEmpty(response.data)) {
        return DEFAULT_KPI_DISPLAY_CONFIG;
      }
      return response.data;
    });

export const updateKpiDisplayConfig = (displayConfig: Partial<KpiDisplayConfig>) =>
  apiHttpService
    .post('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/display/kpi', displayConfig)
    .then((response) => response.data);

export const fetchAppVersion = (): Promise<{ version: string } | undefined> =>
  apiHttpService
    .get('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/version/ui')
    .then((response) => response.data);

export const fetchEnvSiteValues = (
  start: number,
  end: number,
  conditions?: Record<'env', string[]>
): Promise<EnvSiteValuesRes> =>
  conditions
    ? apiHttpService
        .post('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/entity_scope', {
          start,
          end,
          conditions,
        })
        .then((response) => response.data)
    : apiHttpService
        .get(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/entity_scope?start=${start}&end=${end}`)
        .then((response) => response.data);

export const fetchDefaultUser = () =>
  apiHttpService
    .get<AuthResponse>(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/user`)
    .then((response) => response.data);

export const fetchLicenseLimits = () => {
  return apiHttpService
    .get<LicenseLimits>('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/license-limits')
    .then((res) => res.data);
};

export const installDashboards = () => {
  return apiHttpService
    .post('/api/plugins/grafana-asserts-app/resources/install-dashboards', {})
    .then((res) => res.data);
};

export const setupAsserts = () => {
  return isAdmin
    ? apiHttpService.post('/api/plugins/grafana-asserts-app/resources/asserts-setup', {}).then((res) => res.data)
    : Promise.resolve({});
};

export const fetchMetricsDatasource = () => {
  return apiHttpService
    .get('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/datasource/prometheus')
    .then((res) => res.data);
};

export default {
  fetchGraphDisplayConfig,
  fetchEnvSiteValues,
  fetchAppVersion,
  fetchDefaultUser,
  fetchLicenseLimits,
  installDashboards,
  setupAsserts,
};
