import React, { ButtonHTMLAttributes, ReactNode, cloneElement, forwardRef, isValidElement } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'destructive';
  active?: boolean;
  size?: 'sm' | 'md';
  children: ReactNode;
}

export const IconButton = forwardRef<HTMLButtonElement, Props>(
  ({ variant = 'secondary', size = 'md', children, disabled, className, active, ...restProps }, ref) => {
    return (
      <button
        className={twMerge(
          'h-[33px] w-[33px] rounded-full bg-transparent hover:bg-hover transition-colors inline-flex items-center justify-center border-0',
          variant === 'secondary' ? 'text-secondary' : '',
          variant === 'primary' ? 'text-primary hover:bg-primary/10' : '',
          variant === 'destructive' ? 'text-destructive hover:bg-destructive/10' : '',
          active && variant === 'primary' ? '!bg-primary/10' : '',
          active && variant === 'destructive' ? '!bg-destructive/10' : '',
          active && variant === 'secondary' ? '!bg-hover' : '',
          disabled ? 'text-secondary hover:!bg-hover' : '',
          size === 'sm' ? 'h-[21px] w-[21px]' : '',
          className
        )}
        {...restProps}
        ref={ref}
        type="button"
      >
        {isValidElement(children) &&
          cloneElement(children, { ...children.props, className: `svg-icon ${children.props.className}` })}
      </button>
    );
  }
);

IconButton.displayName = 'IconButton';
