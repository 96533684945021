/**
 *
 * SloZoomedChart
 *
 */

import React, { memo, FunctionComponent, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import LineRangeChart from 'components/LineRangeChart/LineRangeChart';
import { fetchSloChart } from 'services/Slo.service';
import useDebounceValue from 'hooks/useDebounceValue';
import {ChartName, Scope, SloChartResponse, SloIncident, ZoomedPos} from 'asserts-types';
import { generateCrossingRange } from 'helpers/Chart.helper';
import { assertsColors } from 'app/constants';
import { DEFAULT_CHART_PADDING } from 'components/LineRangeChart/constants';
import { LoadingPlaceholder } from '@grafana/ui';
import { INCIDENTS_MINIMUM_WIDTH } from 'global-constants';

interface IProps {
  zoomedXPos: ZoomedPos;
  chartName: string;
  sloName: string;
  sloTargetName: string;
  fillOpacity?: number;
  scope?: Scope;
  padding?: number | number[];
  sloIncidents?: SloIncident;
  measurementQuery?: string;
  threshold?: number;
}

const SloZoomedChart: FunctionComponent<IProps> = ({
  chartName,
  sloName,
  sloTargetName,
  zoomedXPos,
  fillOpacity,
  scope,
  padding,
  sloIncidents,
  measurementQuery,
  threshold,
}) => {
  const intl = useIntl();
  const margin = padding || DEFAULT_CHART_PADDING;
  const debouncedZoomedXPos = useDebounceValue(zoomedXPos, 300);
  const [chartData, setChartData] = useState<SloChartResponse>();
  const [isLoadingChart, setIsLoadingChart] = useState<boolean>(false);

  useEffect(() => {
    if (
      debouncedZoomedXPos.startX &&
      debouncedZoomedXPos.endX &&
      debouncedZoomedXPos.startX < debouncedZoomedXPos.endX
    ) {
      setIsLoadingChart(true);
      fetchSloChart(
        sloName,
        chartName,
        sloTargetName,
        debouncedZoomedXPos.startX,
        debouncedZoomedXPos.endX,
        scope,
        measurementQuery,
        threshold
      )
        .then((res) => {
          setChartData(res);
        })
        .finally(() => setIsLoadingChart(false));
    }
    // eslint-disable-next-line
  }, [debouncedZoomedXPos]);

  let incidentsLegendShape: 'line' | 'triangle' | null = null;

  if (sloIncidents?.incidents.length) {
    incidentsLegendShape = 'line';
  }

  return (
    <div className="divider rounded py-2 pr-6 pl-2 relative">
      {isLoadingChart && <LoadingPlaceholder text="Loading" />}
      <p className="font-bold text-center">
        {chartData?.title} {intl.formatMessage(messages.zoomedIn)}
      </p>

      <div className="relative h-[300px]">
        <div
          className="absolute inset-0 pt-[229px] overflow-hidden"
          style={{
            margin: Array.isArray(margin) ? margin.map((m) => `${m}px`).join(' ') : margin,
          }}
        >
          {chartData &&
            sloIncidents?.incidents.map((incident) => {
              const minX = chartData.startTimeMs;
              const maxX = chartData.endTimeMs;
              let left = ((incident.startTime - minX) * 100) / (maxX - minX);

              const width = ((incident.endTime - (left === 0 ? minX : incident.startTime)) * 100) / (maxX - minX);

              if (incidentsLegendShape !== 'triangle' && width < INCIDENTS_MINIMUM_WIDTH) {
                incidentsLegendShape = 'triangle';
              }

              return (
                <div
                  key={incident.severity + incident.startTime + incident.endTime}
                  className={`absolute z-[999] h-[3px] ${
                    width < INCIDENTS_MINIMUM_WIDTH
                      ? 'border-transparent border-l-[5px] border-r-[5px] border-b-[5px] -ml-[5px] border-solid'
                      : ''
                  }`}
                  style={{
                    left: `${left}%`,
                    width: `${left + width > 100 ? 100 - left : width}%`,
                    background: width > INCIDENTS_MINIMUM_WIDTH ? assertsColors[incident.severity] : 'transparent',
                    borderBottomColor:
                      width < INCIDENTS_MINIMUM_WIDTH ? assertsColors[incident.severity] : 'transparent',
                  }}
                />
              );
            })}
        </div>
        {chartData?.metrics && (
          <LineRangeChart
            data={chartData.metrics}
            thresholds={chartData.thresholds}
            positionY="left"
            minX={chartData.startTimeMs}
            maxX={chartData.endTimeMs}
            timeStepInterval={chartData.stepMs}
            disableGrid
            padding={padding}
            type={'line'}
            areaUnderLine={ChartName.SLI === chartName}
            showLegend
            showCrosshairs
            fillOpacity={fillOpacity}
            crossingRanges={
              ChartName.ErrorBudgetBurndown === chartName
                ? generateCrossingRange(chartData.thresholds, chartData.metrics)
                : undefined
            }
            customLegendConfig={
              incidentsLegendShape
                ? {
                    Violations: {
                      color: assertsColors.critical,
                      shape: incidentsLegendShape,
                    },
                  }
                : undefined
            }
          />
        )}
      </div>
    </div>
  );
};

export default memo(SloZoomedChart);
