import { useQuery } from "@tanstack/react-query";
import { fetchAssertionEntityMetric } from "services/Assertion.service";

interface Params {
  startTime: number;
  endTime: number;
  assertionName: string;
  entityName: string;
  entityType: string;
  rootEntityName?: string;
  rootEntityType?: string;
  enabled: boolean;
  labels: Record<string, string>;
}

export default function useAssertionEntityMetrics({
  startTime,
  endTime,
  assertionName,
  entityName,
  entityType,
  enabled,
  labels,
  rootEntityName,
  rootEntityType
}: Params) {

  const processedLabels = { ...labels };

  if (
    (labels.asserts_pod_dropped === 'true' ||
    labels.asserts_instance_dropped === 'true') &&
    rootEntityName &&
    rootEntityType
  ) {
    processedLabels[rootEntityType] = rootEntityName;
  }

  return useQuery(
    [
      'assertion-entity-metric',
      startTime,
      endTime,
      assertionName,
      entityName,
      entityType,
      processedLabels,
    ],
    () =>
      fetchAssertionEntityMetric(
        assertionName,
        entityName,
        entityType,
        startTime,
        endTime,
        processedLabels,
      ),
    { enabled },
  );
}
