import useGrafanaQuery from 'hooks/useGrafanaQuery';
import { useKubeClusterByNamespace } from './useKubeClusterByNamespace';
import { useKubeClusterByWorkload } from './useKubeClusterByWorkload';
import { useKubeClusterByNode } from './useKubeClusterByNode';
import { useKubeClusterByPod } from './useKubeClusterByPod';
import { useServiceByPod } from './useServiceByPod';
import { Entity } from 'asserts-types';
import { OpenInKubernetesTriggerProps } from '../OpenInKubernetesTrigger';
import { APP_PLUGIN_IDS, K8S_SUPPORTED_ENTITY_TYPES } from 'app/constants';
import { useMemo } from 'react';
import { isAppEnabled } from 'utils/app-plugin';
import useKpiDisplayConfig from 'hooks/useKpiDisplayConfig';

interface Props {
  entity: Pick<Entity, 'scope' | 'type' | 'properties' | 'name'> | undefined;
  start: string | number;
  end: string | number;
}

export default function useKubernetes({ entity, start, end }: Props) {
  const { data: kubeInfoData } = useGrafanaQuery({ query: 'count(kube_node_info{cluster!=""})', enabled: true });
  const isDataFlowing = !!kubeInfoData?.length;

  const isK8sAppEnabled = isAppEnabled(APP_PLUGIN_IDS.K8S_APP);
  const { data: kpiDisplayConfig } = useKpiDisplayConfig();

  // TODO: ideally these calls should be removed when Asserts can ship the cluster name and other fields in properties
  const { data: clusterByNamespace, isFetching: isClusterByNamespaceFetching } = useKubeClusterByNamespace(
    entity,
    start,
    end,
    isDataFlowing
  );
  const { data: clusterByWorkload, isFetching: isClusterByWorkloadFetching } = useKubeClusterByWorkload(
    entity,
    start,
    end,
    isDataFlowing
  );
  const { data: clusterByNode, isFetching: isClusterByNodeFetching } = useKubeClusterByNode(
    entity,
    start,
    end,
    isDataFlowing
  );
  const { data: clusterByPod, isFetching: isClusterByPodFetching } = useKubeClusterByPod(
    entity,
    start,
    end,
    isDataFlowing
  );
  const { data: workload, isFetching: isWorkloadFetching } = useServiceByPod(entity, start, end, isDataFlowing);

  const isFetching =
    isClusterByNamespaceFetching ||
    isClusterByWorkloadFetching ||
    isClusterByNodeFetching ||
    isClusterByPodFetching ||
    isWorkloadFetching;

  let clusterName = clusterByNamespace?.name || clusterByWorkload?.name || clusterByNode?.name || clusterByPod?.name;

  if (entity?.type === 'KubeCluster' && entity.name) {
    clusterName = entity.name;
  }

  const navigationUrlPart = getUrlPartByEntity(entity, clusterName, workload?.name);

  const isAvailable = Boolean(
    entity &&
      isDataFlowing &&
      K8S_SUPPORTED_ENTITY_TYPES.includes(entity.type) &&
      navigationUrlPart &&
      isK8sAppEnabled &&
      kpiDisplayConfig?.k8sAppView &&
      clusterName
  );

  return useMemo(
    () => ({ clusterName, workload, isFetching, navigationUrlPart, isAvailable }),
    [clusterName, workload, isFetching, navigationUrlPart, isAvailable]
  );
}

const getUrlPartByEntity = (
  entity: OpenInKubernetesTriggerProps['entity'] | undefined,
  clusterName: string | undefined,
  workloadName: string | undefined
) => {
  if (!entity) {
    return '';
  }
  if (entity.type === 'KubeCluster') {
    return `cluster/${encodeURIComponent(entity.name)}`;
  }

  if (entity.type === 'Node' && clusterName) {
    return `nodes/${clusterName}/${encodeURIComponent(entity.name)}`;
  }

  if (entity.type === 'Namespace' && clusterName) {
    return `namespace/${clusterName}/${encodeURIComponent(entity.name)}`;
  }
  if (entity.type === 'Service' && entity.properties.workload_type && clusterName && entity.scope?.namespace) {
    return `namespace/${clusterName}/${entity.scope.namespace}/${entity.properties.workload_type}/${encodeURIComponent(
      entity.name
    )}`;
  }

  if (
    entity.type === 'Pod' &&
    entity.properties.workload_type &&
    clusterName &&
    entity.scope?.namespace &&
    workloadName
  ) {
    return `namespace/${clusterName}/${entity.scope.namespace}/${
      entity.properties.workload_type
    }/${workloadName}/${encodeURIComponent(entity.name)}`;
  }

  return '';
};
