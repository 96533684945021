import { useMutation } from '@tanstack/react-query';
import { DashboardConfig } from 'asserts-types';
import SnackbarHelper from 'helpers/Snackbar.helper';
import { deleteCustomDashboard } from 'services/CustomDashboard.service';
import { useCustomDashboards } from './useCustomDashboards';

export function useDeleteCustomDashboard() {
  const { refetch } = useCustomDashboards();

  return useMutation({
    mutationFn: (dash: DashboardConfig & { entityType: string }) => deleteCustomDashboard(dash.entityType, dash),
    onSuccess: () => {
      SnackbarHelper.success('Dashboard link was deleted successfuly');
      refetch();
    },
  });
}
