import { stringToDate } from '../helpers/Date.helper';
import { useQuery } from '@tanstack/react-query';
import { fetchQueryRange } from 'services/ManageAssertions.service';
import { useMetricsDataSource } from './useMetricsDatasource';

interface IProps {
  start: number | string;
  end: number | string;
  enabled: boolean;
  query: string;
}

export default function useGrafanaQueryRange({ start, end, query, enabled }: IProps) {
  const { data: metricsDatasource } = useMetricsDataSource();

  return useQuery(
    ['grafana-query-range', start, end, query],
    () => fetchQueryRange(metricsDatasource.uid, query, stringToDate(start).valueOf(), stringToDate(end).valueOf()),
    {
      enabled: Boolean(metricsDatasource.uid) && enabled,
      keepPreviousData: true,
      staleTime: typeof start === 'string' ? 0 : Infinity,
    }
  );
}
