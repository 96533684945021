/**
 *
 * ThresholdRequest
 *
 */

import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import ThresholdTopMenu from '../ThresholdTopMenu/ThresholdTopMenu.component';
import ThresholdList from '../ThresholdList/ThresholdList.component';
import ThresholdRequestForm from './components/ThresholdRequestForm/ThresholdRequestForm.component';
import { connect, ConnectedProps } from 'react-redux';

import { setItemToPopulate } from '../../ManageAssertions.slice';
import { ThresholdItem, Sort } from 'asserts-types';
import requestFields from '../../config/threshold_request.json';
import has from 'helpers/has.helper';
import thresholdAssertionMap from '../../config/threshold_assertion_map.json';
import assertionsOptions from '../../config/assertions_request.json';
import { fetchThresholdRequestList } from 'services/ManageAssertions.service';
import { orderBy } from 'lodash';

interface IProps {}

const connector = connect(
  (state: RootState) => ({
    itemToPopulate: state.manageAssertions.itemToPopulate,
  }),
  {
    setItemToPopulate,
  },
);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ThresholdRequest: FunctionComponent<IProps & PropsFromRedux> = ({
  setItemToPopulate,
  itemToPopulate,
}) => {
  const intl = useIntl();

  const [searchQuery, setSearchQuery] = useState('');
  const [globalList, setGlobalList] = useState<ThresholdItem[]>([]);
  const [customList, setCustomList] = useState<ThresholdItem[]>([]);
  const [globalSort, setGlobalSort] = useState<Sort | null>(null);
  const [customSort, setCustomSort] = useState<Sort | null>(null);

  const [fetchingList, setFetchingList] = useState(true);

  useEffect(() => {
    fetchLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchLists = () => {
    setFetchingList(true);
    fetchThresholdRequestList()
      .then((res) => {
        setGlobalList(res.globalThresholds);
        setCustomList(res.customThresholds);
      })
      .finally(() => {
        setFetchingList(false);
      });
  };

  const handleAddCustomItem = (item: ThresholdItem) => {
    setCustomList(customList.concat([item]));
  };

  const handleUpdateGlobalItem = (
    record: string,
    expr: string,
    labels: Record<string, string>,
  ) => {
    setGlobalList(
      globalList.map((item) =>
        item.record === record &&
        item.labels?.asserts_request_context ===
          labels.asserts_request_context &&
        item.labels?.job === labels.job &&
        item.labels?.asserts_request_type === labels.asserts_request_type
          ? { ...item, record, expr }
          : item,
      ),
    );
  };

  const handleUpdateCustomItem = (
    record: string,
    expr: string,
    labels: Record<string, string>,
  ) => {
    setCustomList(
      customList.map((item) =>
        item.record === record &&
        item.labels?.asserts_request_context ===
          labels.asserts_request_context &&
        item.labels?.job === labels.job &&
        item.labels?.asserts_request_type === labels.asserts_request_type
          ? { ...item, expr }
          : item,
      ),
    );
  };

  const handleDeleteCustomItem = (
    record: string,
    expr: string,
    labels?: Record<string, string>,
  ) => {
    setCustomList(
      customList.filter(
        (item) =>
          !(
            item.expr === expr &&
            item.record === record &&
            item.labels?.asserts_request_context ===
              labels?.asserts_request_context &&
            item.labels?.job === labels?.job &&
            item.labels?.asserts_request_type === labels?.asserts_request_type
          ),
      ),
    );
  };

  const globalListProcessed = useMemo(() => {
    return orderBy(
      globalList
        .map((item) => ({
          ...item,
          assertion: has<typeof thresholdAssertionMap, string, string>(
            thresholdAssertionMap,
            item.record,
          )
            ? thresholdAssertionMap[item.record]
            : '',
        }))
        .filter((item) =>
          Object.values({
            ...item,
            ...(item.labels || {}),
          }).some(
            (item) =>
              typeof item === 'string' &&
              item.toLowerCase().includes(searchQuery.toLowerCase()),
          ),
        ),
      globalSort?.field,
      globalSort?.order,
    );
  }, [globalList, globalSort?.field, globalSort?.order, searchQuery]);

  const customListProcessed = useMemo(
    () =>
      orderBy(
        customList
          .map((item) => ({
            ...item,
            assertion: has<typeof thresholdAssertionMap, string, string>(
              thresholdAssertionMap,
              item.record,
            )
              ? thresholdAssertionMap[item.record]
              : '',
          }))
          .filter((item) =>
            Object.values({
              ...item,
              ...(item.labels || {}),
            }).some(
              (item) =>
                typeof item === 'string' &&
                item.toLowerCase().includes(searchQuery.toLowerCase()),
            ),
          ),
        customSort?.field,
        customSort?.order,
      ),
    [customList, customSort?.field, customSort?.order, searchQuery],
  );

  useEffect(() => {
    if (
      itemToPopulate &&
      customListProcessed.length &&
      !customListProcessed.some(
        (item) =>
          item.assertion === itemToPopulate.alertname &&
          requestFields.every(
            (field) => itemToPopulate[field] === item.labels?.[field],
          ),
      )
    ) {
      itemToPopulate.alertname && setSearchQuery(itemToPopulate.alertname);
    }
    if (
      // leave itemToPopulate to populate form in ThresholdRequestForm.component
      !fetchingList &&
      itemToPopulate &&
      !assertionsOptions.find(
        (item) =>
          has(thresholdAssertionMap, item.value) &&
          thresholdAssertionMap[item.value] === itemToPopulate.alertname,
      )
    ) {
      setItemToPopulate(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customListProcessed]);

  return (
    <div className="h-full overflow-overlay">
      <ThresholdTopMenu searchQuery={searchQuery} onSearchChange={setSearchQuery} onYmlFileChange={fetchLists} />
      <div>
        <ThresholdRequestForm
          onAdd={handleAddCustomItem}
          globalList={globalListProcessed}
          customList={customListProcessed}
          fetchingLists={fetchingList}
        />
        <ThresholdList
          title={intl.formatMessage(messages.customThresholds)}
          fields={requestFields}
          fetchingList={fetchingList}
          list={customListProcessed}
          onUpdate={handleUpdateCustomItem}
          onDelete={handleDeleteCustomItem}
          onSort={setCustomSort}
          orderBy={customSort}
        />
        <ThresholdList
          title={intl.formatMessage(messages.defaultGlobal)}
          onUpdate={handleUpdateGlobalItem}
          fetchingList={fetchingList}
          list={globalListProcessed}
          onSort={setGlobalSort}
          orderBy={globalSort}
          fields={[]}
        />
      </div>
    </div>
  );
};

export default connector(ThresholdRequest);
