/**
 *
 * SearchExpression
 *
 */

import React, { memo, FunctionComponent, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { SloConfigModel } from 'asserts-types';
import useDebounceValue from 'hooks/useDebounceValue';
import CodeBlockComponent from 'components/CodeBlock/CodeBlock.component';
import useDefinitionAutocomplete from 'hooks/useDefinitionAutocomplete';
import { MAX_AUTOCOMPLETE_RESULTS_COUNT } from 'app/constants';
import { SelectableValue } from '@grafana/data';
import { Field, Select } from '@grafana/ui';

interface IProps {
  control: Control<SloConfigModel>;
  editing?: boolean;
}

const SearchExpression: FunctionComponent<IProps> = ({
  control,
  editing,
}) => {
  const [query, setQuery] = useState('');

  const debouncedQuery = useDebounceValue(query, 300);

  const { data, isFetching } = useDefinitionAutocomplete({
    query: debouncedQuery,
    max: MAX_AUTOCOMPLETE_RESULTS_COUNT,
    enabled: Boolean(debouncedQuery),
  });

  const options: SelectableValue<string>[] =
    data?.map((d) => ({ value: d.boundDescription, label: d.boundDescription })) || [];
  
  return (
    <Controller
      name="entitySearch"
      control={control}
      render={({ field }) =>
        editing ? (
          <Field label="Search Expression" className="w-full">
            <Select
              {...field}
              value={field.value ? { value: field.value, label: field.value } : ''}
              onChange={(v) => field.onChange(v?.value)}
              options={options}
              className="grow"
              isLoading={isFetching}
              onInputChange={(v) => setQuery(v)}
              isClearable
              allowCustomValue
              placeholder="Enter search expression (e.g. Show all Services)"
            />
          </Field>
        ) : (
          <Field label="Search Expression" className="w-[800px] m-0">
            <CodeBlockComponent className="text-sm">{field.value}</CodeBlockComponent>
          </Field>
        )
      }
    />
  );
};

export default memo(SearchExpression);
