/**
 *
 * ThresholdChart
 *
 */

import React, { memo, FunctionComponent } from 'react';
import requestOptions from '../../config/assertions_request.json';
import resourceOptions from '../../config/assertions_resource.json';
import useThresholdScene from './hooks/useThresholdScene';

interface IProps {
  show: boolean;
  assertion: string | undefined;
  labels: Record<string, string>;
  value: number | undefined;
  thresholdQuery?: string;
  thresholdLevel: string | undefined;
}

const ThresholdChart: FunctionComponent<IProps> = ({
  show,
  labels,
  assertion,
  value,
  thresholdQuery,
  thresholdLevel,
}) => {
  const assertionOptions = requestOptions.concat(resourceOptions);

  let labelsQuery = Object.entries(labels)
    .map(([key, value]) => `${key}="${value}"`)
    .join(', ');

  const metricName = assertionOptions.find((a) => a.value === assertion)?.metricValue;

  if (assertion === 'asserts:error_log:threshold') {
    const { job } = labels;

    const labelsForLog = {
      job,
      asserts_request_type: 'logger',
      asserts_request_context: 'error',
    };
    labelsQuery = Object.entries(labelsForLog)
      .map(([key, value]) => `${key}="${value}"`)
      .join(', ');
  }

  const { scene } = useThresholdScene({ metricName, labelsQuery, value, thresholdQuery, thresholdLevel });

  if (!show) {
    return null;
  }

  return (
    <>
      <div className="h-[350px] relative mb-4 flex items-center justify-center">
        <scene.Component model={scene} />
      </div>
    </>
  );
};

export default memo(ThresholdChart);
