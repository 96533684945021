import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  setActiveChartPoint,
  setSelectedTime,
} from '../../../Assertions.slice';
import { useAppSelector } from 'app/store';

interface IProps {
  currentDate: number;
  mouseX: number;
}

export default function useSelectedTime({
  currentDate,
  mouseX,
}: IProps) {
  const selectedTime = useAppSelector((state) => state.assertions.selectedTime);
  const [selectedMouseX, setSelectedMouseX] = useState<undefined | number>();
  const dispatch = useDispatch();

  const selectCurrentTime = () => {
    dispatch(setSelectedTime(currentDate));
    setSelectedMouseX(mouseX);
  };

  const resetSelectedTime = () => {
    dispatch(setSelectedTime(undefined));
    setSelectedMouseX(undefined);
    dispatch(setActiveChartPoint(undefined));
  };

  return {
    selectedTime,
    selectedMouseX,
    resetSelectedTime,
    selectCurrentTime,
  };
}
