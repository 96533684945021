/**
 *
 * NotificationRequestForm
 *
 */

import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import assertionsOptions from '../../../../config/notifications_resource.json';
import useDidUpdateEffect from 'hooks/useDidUpdate';
import { connect, ConnectedProps } from 'react-redux';

import { useForm, Controller } from 'react-hook-form';
import errorMessages from 'app/errorMessages';
import { NotificationConfig } from 'asserts-types';
import CheckIcon from 'assets/material-icons/done_FILL0_wght400_GRAD0_opsz24.svg';
import CloseIcon from 'assets/material-icons/close_FILL0_wght400_GRAD0_opsz24.svg';
import { severityOptions } from '../../../ThresholdResource/components/ThresholdResourceForm/ThresholdResourceForm.component';
import { setItemToPopulate } from '../../../../ManageAssertions.slice';
import tailwindColors from 'tailwindcss/colors';
import { saveNotification, saveSuppress } from 'services/ManageAssertions.service';
import { Button, Field, Input, PanelContainer, Select, Switch } from '@grafana/ui';
import useGrafanaQuery from 'hooks/useGrafanaQuery';
import { getQueryTimeRange } from 'helpers/Time.helper';
import { stringToDate } from 'helpers/Date.helper';
import { useAppSelector } from 'app/store';
import NotificationsChartComponent from 'features/ManageAssertions/components/NotificationsChart/NotificationsChart.component';
import { IconButton } from 'components/IconButton/IconButton.component';
import { useMetricsDataSource } from 'hooks/useMetricsDatasource';

interface IProps {
  onAdd?: (item: NotificationConfig) => void;
  onUpdate?: (item: NotificationConfig) => void;
  onClose?: () => void;
  tableRow?: boolean;
  preloadedValue?: NotificationConfig;
  suppress?: boolean;
  active?: boolean;
}

const connector = connect(
  (state: RootState) => ({
    itemToPopulate: state.manageAssertions.itemToPopulate,
  }),
  { setItemToPopulate }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IForm {
  ruleNameField: string;
  assertionField: string | null;
  exporterField: string | null;
  resourceTypeField: string | null;
  containerField: string | null;
  topicField: string | null;
  severityField: string | null;
  otherLabelsField: string;
  forField: string | null;
  receiverField: string | null;
  silenced?: boolean;
}

const NotificationResourceForm: FunctionComponent<IProps & PropsFromRedux> = ({
  onAdd,
  tableRow,
  preloadedValue,
  onClose,
  onUpdate,
  suppress,
  active,
  itemToPopulate,
  setItemToPopulate,
}) => {
  const intl = useIntl();

  const { data: metricsDatasource } = useMetricsDataSource();

  const { handleSubmit, setValue, watch, control, reset, getValues } = useForm<IForm>({
    defaultValues: {
      ruleNameField: '',
      assertionField: '',
      exporterField: '',
      resourceTypeField: '',
      containerField: '',
      topicField: '',
      severityField: '',
      otherLabelsField: '',
      forField: '',
      receiverField: '',
    },
    mode: 'onChange',
  });

  const [saving, setSaving] = useState(false);

  const watchAssertionField = watch('assertionField');
  const watchExporterField = watch('exporterField');
  const watchResourceTypeField = watch('resourceTypeField');
  const watchForField = watch('forField');
  const watchTopicField = watch('topicField');
  const watchContainerField = watch('containerField');

  const start = useAppSelector((state) => state.app.start);
  const end = useAppSelector((state) => state.app.end);

  const startMs = useMemo(() => stringToDate(start).valueOf(), [start]);
  const endMs = useMemo(() => stringToDate(end).valueOf(), [end]);

  const timeWindowQuery = start && end && getQueryTimeRange(startMs, endMs);

  const selectedAssertion = assertionsOptions.find((o) => o.label === watchAssertionField);

  const assertionQueryPart = selectedAssertion?.metricValue;

  const resourceTypeQuery =
    watchAssertionField === 'ResourceRateAnomaly'
      ? `
        group by(asserts_resource_type) (last_over_time(asserts:resource:total${timeWindowQuery || '[2m]'}))
        or
        group by(asserts_resource_type) (last_over_time(asserts:resource:gauge${timeWindowQuery || '[2m]'}))
        `
      : `group by(asserts_resource_type) (last_over_time(${assertionQueryPart}${timeWindowQuery || '[2m]'}))`;

  const { data: resourceTypeData, isFetching: isFetchingResourceTypeOptions } = useGrafanaQuery({
    query: resourceTypeQuery,
    enabled: Boolean(assertionQueryPart),
  });

  const resourceTypeOptions = resourceTypeData?.map((o) =>
    typeof o.metric.asserts_resource_type === 'string' ? o.metric.asserts_resource_type : 'none'
  );

  const containerQuery =
    watchAssertionField === 'ResourceRateAnomaly'
      ? `
          group by(container) (last_over_time(asserts:resource:total{asserts_resource_type="${watchResourceTypeField}"}${
          timeWindowQuery || '[2m]'
        }))
          or
          group by(container) (last_over_time(asserts:resource:gauge{asserts_resource_type="${watchResourceTypeField}"}${
          timeWindowQuery || '[2m]'
        }))
        `
      : `group by(container) (last_over_time(${assertionQueryPart}{asserts_resource_type="${watchResourceTypeField}"}${
          timeWindowQuery || '[2m]'
        }))`;

  const { data: containerData, isFetching: isFetchingContainerOptions } = useGrafanaQuery({
    query: containerQuery,
    enabled: Boolean(assertionQueryPart && watchResourceTypeField),
  });

  const containerOptions = containerData
    ?.map((o) => (typeof o.metric.container === 'string' ? o.metric.container : ''))
    .filter((c) => c);

  const sourceQuery =
    watchAssertionField === 'ResourceRateAnomaly'
      ? `
          group by(asserts_source) (last_over_time(asserts:resource:total{asserts_resource_type="${watchResourceTypeField}"${
          watchContainerField ? `, container="${watchContainerField}"` : ''
        }}${timeWindowQuery || '[2m]'}))
          or
          group by(asserts_source) (last_over_time(asserts:resource:gauge{asserts_resource_type="${watchResourceTypeField}"${
          watchContainerField ? `, container="${watchContainerField}"` : ''
        }}${timeWindowQuery || '[2m]'}))
        `
      : `group by(asserts_source) (last_over_time(${assertionQueryPart}{asserts_resource_type="${watchResourceTypeField}"${
          watchContainerField ? `, container="${watchContainerField}"` : ''
        }}${timeWindowQuery || '[2m]'}))`;

  const { data: exporterData, isFetching: isFetchingExporterOptions } = useGrafanaQuery({
    query: sourceQuery,
    enabled: Boolean(assertionQueryPart && watchResourceTypeField),
  });

  const exporterOptions = exporterData
    ?.map((o) => (typeof o.metric.asserts_source === 'string' ? o.metric.asserts_source : ''))
    .filter((c) => c);

  // const { data: jobData, isFetching: isFetchingJobOptions } = useGrafanaQuery({
  //   query: `group by(job) (last_over_time(${assertionQueryPart}{asserts_resource_type="${watchResourceTypeField}", container="${watchContainerField}", asserts_source="${watchExporterField}"}${
  //     timeWindowQuery || '[2m]'
  //   }))`,
  //   enabled: Boolean(watchResourceTypeField && watchContainerField && watchExporterField),
  // });

  // const jobOptions = jobData?.map((o) => (typeof o.metric.job === 'string' ? o.metric.job : '')).filter((c) => c);

  const { data: topicData, isFetching: isFetchingTopicOptions } = useGrafanaQuery({
    query: `group by(topic) (last_over_time(${assertionQueryPart}{asserts_resource_type="${watchResourceTypeField}", container="${watchContainerField}", asserts_source="${watchExporterField}"}${
      timeWindowQuery || '[2m]'
    }))`,
    enabled: Boolean(watchResourceTypeField && watchContainerField && watchExporterField),
  });

  const topicOptions = topicData?.map((o) => (typeof o.metric.job === 'string' ? o.metric.job : '')).filter((c) => c);

  const resetIfNotInOptions = (field: keyof IForm, options: string[]) => {
    const value = getValues(field);
    if (!options.find((option) => option === value)) {
      setValue(field, null);
    }
  };

  const severityOptionsFiltered = useMemo(
    () =>
      severityOptions.filter(
        (option) =>
          !(
            option === 'warning' &&
            (watchAssertionField === 'ResourceRateBreach' || watchAssertionField === 'ResourceMayExhaust')
          )
      ),
    [watchAssertionField]
  );

  useEffect(() => {
    if (itemToPopulate && !itemToPopulate.notificationRuleName) {
      itemToPopulate.alertname && setValue('assertionField', itemToPopulate.alertname);

      itemToPopulate.asserts_source && setValue('exporterField', itemToPopulate.asserts_source);

      itemToPopulate.asserts_resource_type && setValue('resourceTypeField', itemToPopulate.asserts_resource_type);

      itemToPopulate.container && setValue('containerField', itemToPopulate.container);

      itemToPopulate.topic && setValue('topicField', itemToPopulate.topic);

      itemToPopulate.asserts_severity && setValue('severityField', itemToPopulate.asserts_severity);

      setItemToPopulate(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemToPopulate]);

  useEffect(() => {
    if (!preloadedValue) {
      return;
    }
    if (preloadedValue.for) {
      setValue('forField', parseInt(preloadedValue.for, 10).toString());
    }
    if (preloadedValue.name) {
      setValue('ruleNameField', preloadedValue.name);
    }

    if (preloadedValue.matchLabels.alertname) {
      setValue('assertionField', preloadedValue.matchLabels.alertname);
    }

    if (preloadedValue.matchLabels.asserts_source) {
      setValue('exporterField', preloadedValue.matchLabels.asserts_source);
    }

    if (preloadedValue.matchLabels.asserts_resource_type) {
      setValue('resourceTypeField', preloadedValue.matchLabels.asserts_resource_type);
    }

    if (preloadedValue.matchLabels.container) {
      setValue('containerField', preloadedValue.matchLabels.container);
    }

    if (preloadedValue.matchLabels.topic) {
      setValue('topicField', preloadedValue.matchLabels.topic);
    }

    if (preloadedValue.matchLabels.asserts_severity) {
      setValue('severityField', preloadedValue.matchLabels.asserts_severity);
    }

    if (preloadedValue.alertLabels?.asserts_receiver) {
      setValue('receiverField', preloadedValue.alertLabels.asserts_receiver);
    }

    if (preloadedValue.matchLabels.additional_labels) {
      setValue('otherLabelsField', preloadedValue.matchLabels.additional_labels);
    }

    setValue('silenced', Boolean(preloadedValue.silenced));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDidUpdateEffect(() => {
    if (!watchAssertionField) {
      setValue('exporterField', null);
      setValue('resourceTypeField', null);
      setValue('containerField', null);
      setValue('topicField', null);
    }

    resetIfNotInOptions('severityField', severityOptionsFiltered);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricsDatasource.uid, watchAssertionField]);

  useDidUpdateEffect(() => {
    if (!watchExporterField || !watchAssertionField) {
      setValue('resourceTypeField', null);
      setValue('containerField', null);
      setValue('topicField', null);
    }
  }, [watchExporterField, metricsDatasource.uid]);

  useDidUpdateEffect(() => {
    if (!watchResourceTypeField) {
      setValue('containerField', null);
      setValue('topicField', null);
    }
  }, [watchResourceTypeField, metricsDatasource.uid]);

  const onSubmit = handleSubmit((data) => {
    setSaving(true);
    const alertname = data.assertionField;
    if (!alertname) {
      return;
    }

    const asserts_source = data.exporterField || undefined;
    const asserts_resource_type = data.resourceTypeField || undefined;
    const container = data.containerField || undefined;
    const topic = data.topicField || undefined;
    const asserts_severity = data.severityField || undefined;
    const additional_labels = data.otherLabelsField || undefined;
    const asserts_receiver = data.receiverField || undefined;

    const matchLabels = {
      alertname,
      asserts_source,
      additional_labels,
      asserts_resource_type,
      container,
      topic,
      asserts_severity,
    };

    const alertLabels = {
      asserts_receiver,
    };

    const itemToSave = {
      name: data.ruleNameField.toString(),
      matchLabels,
      for: data.forField ? `${data.forField}m` : undefined,
      alertLabels,
      silenced: data.silenced,
    };

    (suppress ? saveSuppress(itemToSave) : saveNotification(itemToSave))
      .then(() => {
        if (preloadedValue) {
          onClose && onClose();
          onUpdate && onUpdate(itemToSave);
        } else {
          onAdd && onAdd(itemToSave);
          reset();
        }
      })
      .finally(() => setSaving(false));
  });

  const renderRuleNameControl = () => (
    <Controller
      name="ruleNameField"
      control={control}
      rules={{ required: intl.formatMessage(errorMessages.required) }}
      render={({ field, fieldState }) => (
        <Field
          invalid={!!fieldState.error}
          error={fieldState.error?.message}
          label={intl.formatMessage(messages.ruleName)}
        >
          <Input {...field} placeholder="Enter rule name" disabled={!!preloadedValue || saving} />
        </Field>
      )}
    />
  );

  const renderAssertionControl = () => (
    <Controller
      name="assertionField"
      control={control}
      rules={{ required: intl.formatMessage(errorMessages.required) }}
      render={({ field, fieldState }) => (
        <Field
          invalid={!!fieldState.error}
          error={fieldState.error?.message}
          label={intl.formatMessage(messages.assertion)}
        >
          <Select
            {...field}
            onChange={(v) => field.onChange(v?.value || '')}
            options={assertionsOptions?.map((o) => ({ label: o.label, value: o.label })) || []}
            disabled={saving}
            allowCustomValue
            isClearable
          />
        </Field>
      )}
    />
  );

  const renderExporterControl = () => (
    <Controller
      name="exporterField"
      control={control}
      render={({ field }) => (
        <Field label={intl.formatMessage(messages.exporter)}>
          <Select
            {...field}
            onChange={(v) => field.onChange(v?.value || '')}
            options={exporterOptions?.map((o) => ({ label: o, value: o })) || []}
            disabled={saving}
            isLoading={isFetchingExporterOptions}
            allowCustomValue
            isClearable
          />
        </Field>
      )}
    />
  );

  const renderResourceTypeControl = () => (
    <Controller
      name="resourceTypeField"
      control={control}
      render={({ field }) => (
        <Field label={intl.formatMessage(messages.resourceType)}>
          <Select
            {...field}
            onChange={(v) => field.onChange(v?.value || '')}
            options={resourceTypeOptions?.map((o) => ({ label: o, value: o })) || []}
            disabled={saving}
            isLoading={isFetchingResourceTypeOptions}
            allowCustomValue
            isClearable
          />
        </Field>
      )}
    />
  );

  const renderContainerControl = () => (
    <Controller
      name="containerField"
      control={control}
      render={({ field }) => (
        <Field label={intl.formatMessage(messages.container)}>
          <Select
            {...field}
            onChange={(v) => field.onChange(v?.value || '')}
            options={containerOptions?.map((o) => ({ label: o, value: o })) || []}
            disabled={saving}
            isLoading={isFetchingContainerOptions}
            allowCustomValue
            isClearable
          />
        </Field>
      )}
    />
  );

  const renderTopicControl = () => (
    <Controller
      name="topicField"
      control={control}
      render={({ field }) => (
        <Field label={intl.formatMessage(messages.topic)}>
          <Select
            {...field}
            onChange={(v) => field.onChange(v?.value || '')}
            options={topicOptions?.map((o) => ({ label: o, value: o })) || []}
            disabled={saving}
            isLoading={isFetchingTopicOptions}
            allowCustomValue
            isClearable
          />
        </Field>
      )}
    />
  );

  const renderSeverityControl = () => (
    <Controller
      name="severityField"
      control={control}
      render={({ field }) => (
        <Field label={intl.formatMessage(messages.severity)}>
          <Select
            {...field}
            onChange={(v) => field.onChange(v?.value || '')}
            options={severityOptionsFiltered?.map((o) => ({ label: o, value: o })) || []}
            disabled={!severityOptionsFiltered.length || saving}
            isClearable
          />
        </Field>
      )}
    />
  );

  const renderReceiverControl = () => (
    <Controller
      name="receiverField"
      control={control}
      render={({ field }) => (
        <Field label={intl.formatMessage(messages.receiver)}>
          <Input {...field} value={field.value || ''} placeholder="Enter value" disabled={saving} />
        </Field>
      )}
    />
  );

  const renderForControl = () => (
    <Controller
      name="forField"
      control={control}
      render={({ field }) => (
        <Field label={intl.formatMessage(messages.for)}>
          <Input
            {...field}
            value={field.value || ''}
            placeholder="Value"
            disabled={saving}
            type="number"
            suffix="min"
          />
        </Field>
      )}
    />
  );

  const renderOtherLabelsControl = () => (
    <Controller
      name="otherLabelsField"
      control={control}
      render={({ field }) => (
        <Field label={intl.formatMessage(messages.otherLabels)}>
          <Input {...field} value={field.value || ''} placeholder="Enter value" disabled={saving} />
        </Field>
      )}
    />
  );

  const renderSilenceSwitch = () => (
    <Controller
      name="silenced"
      control={control}
      render={({ field }) => <Switch value={!field.value} onClick={() => field.onChange(!field.value)} />}
    />
  );

  const labelsFromForm = {
    ...(watchAssertionField ? { alertname: watchAssertionField } : {}),
    ...(watchContainerField ? { container: watchContainerField } : {}),
    ...(watchExporterField ? { asserts_source: watchExporterField } : {}),
    ...(watchResourceTypeField ? { asserts_resource_type: watchResourceTypeField } : {}),
    ...(watchTopicField ? { topic: watchTopicField } : {}),
  };

  if (tableRow) {
    return (
      <>
        <tr style={active ? { backgroundColor: tailwindColors.yellow[50] } : undefined}>
          <td className="p-4">{renderRuleNameControl()}</td>
          <td className="p-4">{renderAssertionControl()}</td>
          <td className="p-4">{renderExporterControl()}</td>
          <td className="p-4">{renderResourceTypeControl()}</td>
          <td className="p-4">{renderSeverityControl()}</td>
          <td className="p-4">{renderContainerControl()}</td>
          <td className="p-4">{renderTopicControl()}</td>
          {!suppress && <td className="p-4">{renderReceiverControl()}</td>}
          {!suppress && <td className="p-4">{renderForControl()}</td>}
          <td className="p-4">{renderOtherLabelsControl()}</td>
          {!suppress && <td className="p-4">{renderSilenceSwitch()}</td>}
          <td className="p-4">
            <div className="flex items-center">
              <IconButton variant="primary" onClick={() => onSubmit()} disabled={saving}>
                <CheckIcon />
              </IconButton>
              <IconButton variant="primary" onClick={onClose} disabled={saving}>
                <CloseIcon />
              </IconButton>
            </div>
          </td>
        </tr>
        {!suppress && (
          <tr>
            <td colSpan={12}>
              <NotificationsChartComponent
                labels={labelsFromForm}
                show={Boolean(watchResourceTypeField && watchExporterField && watchAssertionField)}
                continuesFor={watchForField || undefined}
                assertion={watchAssertionField || undefined}
              />
            </td>
          </tr>
        )}
      </>
    );
  }

  return (
    <PanelContainer className="mt-8 p-6">
      <p className="mb-6 text-xl">{intl.formatMessage(suppress ? messages.addTitleSuppress : messages.addTitle)}</p>
      <form onSubmit={onSubmit}>
        <div className="flex items-start gap-2.5 [&_>_div]:w-0 [&_>_div]:grow">
          {renderAssertionControl()}
          {renderResourceTypeControl()}
          {renderContainerControl()}
          {renderExporterControl()}
          {!!topicOptions?.length && renderTopicControl()}
          {renderSeverityControl()}
          {!suppress && renderReceiverControl()}
          {!suppress && renderForControl()}
          {renderOtherLabelsControl()}
          {renderRuleNameControl()}
        </div>
        {!suppress && (
          <NotificationsChartComponent
            labels={labelsFromForm}
            show={Boolean(watchResourceTypeField && watchExporterField && watchAssertionField)}
            continuesFor={watchForField || undefined}
            assertion={watchAssertionField || undefined}
          />
        )}
        <Button color="primary" type="submit" disabled={saving}>
          {intl.formatMessage(messages.addNew)}
        </Button>
      </form>
    </PanelContainer>
  );
};

export default connector(NotificationResourceForm);
