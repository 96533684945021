/**
 *
 * ThresholdTopMenu
 *
 */

import React, { memo, FunctionComponent, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import YmlFileEditor, { IYmlForm } from 'components/YmlFileEditor/YmlFileEditor.component';
import Snackbar from 'helpers/Snackbar.helper';

import { fetchThresholdYml, saveThresholdYml } from 'services/ManageAssertions.service';
import { Button, Icon, IconButton, Input, Tab, TabsBar, TimeRangePicker, useTheme2 } from '@grafana/ui';
import { prefixRoute } from 'utils/utils.routing';
import { ROUTES } from 'global-constants';
import useAppTimeRange from 'hooks/useAppTimeRange';

interface IProps {
  searchQuery: string;
  onSearchChange: (value: string) => void;
  onYmlFileChange: () => void;
}


const ThresholdTopMenu: FunctionComponent<IProps> = ({
  onSearchChange,
  searchQuery,
  onYmlFileChange,
}) => {
  const intl = useIntl();
  const { timeRange, onChange, onZoom, onMove } = useAppTimeRange();

  const theme = useTheme2();

  const [showYmlEditor, setShowYmlEditor] = useState(false);
  const [ymlFileText, setYmlFileText] = useState('');
  const [fetchingFile, setFetchingFile] = useState(false);
  const [savingFile, setSavingFile] = useState(false);

  const handleShowYmlFile = () => {
    if (showYmlEditor) {
      setShowYmlEditor(false);
      return;
    }
    setFetchingFile(true);
    fetchThresholdYml()
      .then((text) => {
        setYmlFileText(text);
        setShowYmlEditor(true);
      })
      .finally(() => setFetchingFile(false));
  };

  const handleSubmitFile = (data: IYmlForm) => {
    setSavingFile(true);
    saveThresholdYml(data.textField)
      .then(
        () => {
          setShowYmlEditor(false);
          Snackbar.success(messages.fileSaved);
          onYmlFileChange();
        },
        (err) => {
          Snackbar.error(intl.formatMessage(messages.fileSaveError));
        }
      )
      .finally(() => setSavingFile(false));
  };

  const requestPath = prefixRoute([ROUTES.RULES, ROUTES.RULES_THRESHOLD, ROUTES.REQUEST].join('/'));
  const resourcePath = prefixRoute([ROUTES.RULES, ROUTES.RULES_THRESHOLD, ROUTES.RESOURCE].join('/'));
  const healthPath = prefixRoute([ROUTES.RULES, ROUTES.RULES_THRESHOLD, ROUTES.HEALTH].join('/'));

  return (
    <>
      <div>
        <div className="flex items-center justify-between">
          <h1 style={{ fontSize: theme.typography.h1.fontSize }} className="m-0">
            Threshold
          </h1>
          <Button fill="outline" onClick={handleShowYmlFile} disabled={fetchingFile}>
            {fetchingFile ? intl.formatMessage(messages.opening) : intl.formatMessage(messages.viewFile)}
          </Button>
        </div>
        <TabsBar className="w-full mt-2">
          <Tab
            label={intl.formatMessage(messages.request)}
            href={requestPath}
            active={location.pathname.includes(requestPath)}
          />
          <Tab
            label={intl.formatMessage(messages.resource)}
            href={resourcePath}
            active={location.pathname.includes(resourcePath)}
          />
          <Tab
            label={intl.formatMessage(messages.health)}
            href={healthPath}
            active={location.pathname.includes(healthPath)}
          />
        </TabsBar>
        <div className="flex items-center justify-between gap-8 mt-8">
          <Input
            className="w-full"
            value={searchQuery}
            placeholder={intl.formatMessage(messages.search)}
            onChange={(e) => onSearchChange(e.currentTarget.value)}
            prefix={<Icon name="search" />}
            suffix={<IconButton name="times" aria-label="Clear" onClick={() => onSearchChange('')} />}
          />
          <TimeRangePicker
            value={timeRange}
            onChange={onChange}
            onMoveBackward={() => onMove('back')}
            onChangeTimeZone={() => {}}
            onMoveForward={() => onMove('forward')}
            onZoom={onZoom}
            isOnCanvas
          />
        </div>
      </div>
      {showYmlEditor && (
        <YmlFileEditor
          onClose={() => setShowYmlEditor(false)}
          text={ymlFileText}
          onSubmit={handleSubmitFile}
          saving={savingFile}
        />
      )}
    </>
  );
};

export default memo(ThresholdTopMenu);
