import React, { FunctionComponent, useEffect, useState } from 'react';

import SavedSearchItem from './components/SavedSearchItem/SavedSearchitem.component';
import { sortBy } from 'lodash';
import { useIntl } from 'react-intl';
import messages from './messages';
import { Definition } from 'asserts-types';
import { useAppSelector } from 'app/store';
import useDebounceValue from 'hooks/useDebounceValue';
import { fetchSavedSearch } from 'services/Search.service';
import { MAX_AUTOCOMPLETE_RESULTS_COUNT } from 'app/constants';
import useDidUpdateEffect from 'hooks/useDidUpdate';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Icon, Input, LoadingBar, IconButton as IconButtonGrafana } from '@grafana/ui';


interface IProps {
  onEdit:  () => void
}


const SavedSearches: FunctionComponent<IProps> = ({ onEdit }) => {
  const [savedSearches, setSavedSearches] = useState<Definition[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [search, setSearch] = useState('');

  const { formatMessage } = useIntl();

  const searchDefinition = useAppSelector((state) => state.entities.searchDefinition);

  const debouncedSearch = useDebounceValue(search, 300);

  const fetchList = () => {
    setIsSearching(true);
    fetchSavedSearch(search, MAX_AUTOCOMPLETE_RESULTS_COUNT)
      .then((res) => {
        setSavedSearches(sortBy(res.items, 'boundDescription'));
        setOffset(MAX_AUTOCOMPLETE_RESULTS_COUNT);
        setHasMore(res.pageResults === res.maxResults);
      })
      .finally(() => setIsSearching(false));
  }

  useDidUpdateEffect(() => {
    fetchList();
  }, [debouncedSearch]);

  useEffect(() => {
    fetchList();
    // eslint-disable-next-line
  }, []);

  const loadNext = () => {
    setIsSearching(true);
    fetchSavedSearch(search, MAX_AUTOCOMPLETE_RESULTS_COUNT, offset)
      .then((res) => {
        setSavedSearches(
          sortBy(savedSearches.concat(res.items), 'boundDescription'),
        );
        setOffset(offset + MAX_AUTOCOMPLETE_RESULTS_COUNT);

        setHasMore(res.pageResults === res.maxResults);
      })
      .finally(() => setIsSearching(false));
  };

  return (
    <>
      <div className="grow overflow-overlay relative" id="scrollableDiv">
        {isSearching && (
          <div className="absolute top-0 z-[999] inset-x-0">
            <LoadingBar width={300} />
          </div>
        )}
        <div className="px-8 py-4 sticky top-0 bg-paper z-[99] divider-b">
          <Input
            className="w-full"
            value={search}
            placeholder={formatMessage(messages.search)}
            onChange={(e) => setSearch(e.currentTarget.value)}
            prefix={<Icon name="search" />}
            suffix={<IconButtonGrafana name="times" aria-label="Clear" onClick={() => setSearch('')} />}
          />
        </div>
        {!savedSearches.length && search && !isSearching && (
          <div className="p-8 text-secondary">
            No results for <span className="text-black dark:text-secondary">&quot;{search}&quot;</span>
          </div>
        )}
        {!savedSearches.length && !search && !isSearching && (
          <div className="p-8 text-secondary">No saved searches</div>
        )}
        <div className="py-4 relative">
          <InfiniteScroll
            dataLength={savedSearches.length}
            next={loadNext}
            hasMore={hasMore}
            loader={<span />}
            className="px-6 space-y-3"
            scrollableTarget="scrollableDiv"
          >
            {savedSearches.map((def, index) => (
              <SavedSearchItem
                key={index}
                definition={def}
                active={def.definitionId === searchDefinition?.definitionId}
                onEdit={onEdit}
                removeSearchItem={() => setSavedSearches(savedSearches.filter((_, i) => i !== index))}
              />
            ))}
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
};

export default SavedSearches;
