import { apiHttpService } from 'app/api-http-service';
import { DefinitionResponse } from 'asserts-types';

export const fetchSearchAutocomplete = (query: string, max = 10) =>
  apiHttpService
    .get<DefinitionResponse>(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/search/definition?q=${query}&max=${max}`)
    .then((response) => response.data);

export const fetchSavedSearch = (query: string, max = 10, offset?: number) =>
  apiHttpService
    .get<DefinitionResponse>(
      `/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/search/definition?q=${query}&max=${max}${offset ? `&offset=${offset}` : ''}&onlyCustomSearch=true`
    )
    .then((response) => response.data);

export default {
  fetchSearchAutocomplete,
  fetchSavedSearch,
};
