import { useAppSelector } from 'app/store';
import { stringToDate } from '../../../helpers/Date.helper';
import { useQuery } from '@tanstack/react-query';
import { fetchConnectedEntities } from 'services/Entity.service';


export const USE_ACTIVE_ENTITIES_CONNECTIONS_QUERY_KEY =
  'active-entity-connections';

export default function useActiveEntityConnections() {
  const start = useAppSelector((state) => state.app.start);
  const end = useAppSelector((state) => state.app.end);

  const activeEntity = useAppSelector((state) => state.entities.activeEntity);

  const query = useQuery(
    [USE_ACTIVE_ENTITIES_CONNECTIONS_QUERY_KEY, activeEntity, start, end],

    () => {
      if (activeEntity?.activeConnectedEntityType) {
        return fetchConnectedEntities(
          activeEntity.id,
          activeEntity.activeConnectedEntityType,
          stringToDate(start).valueOf(),
          stringToDate(end).valueOf()
        );
      }
      return Promise.reject();
    },
    {
      enabled: Boolean(activeEntity),
      cacheTime: Infinity,
      staleTime: 0,
    },
  );

  return query;
}
