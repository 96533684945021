/**
 *
 * EntityDetailsLoadingPlaceholder
 *
 */

import React, { memo, FunctionComponent } from 'react';

interface IProps {}

const EntityDetailsLoadingPlaceholder: FunctionComponent<IProps> = () => {
  return (
    <div className="px-4 pt-4 w-full">
      <div className="max-w-lg">
        <div className="animate-pulse flex space-x-4">
          <div className="rounded-full bg-panel h-12 w-12"></div>
          <div className="flex-1 space-y-3 py-1">
            <div className="h-3 bg-panel rounded-full"></div>
            <div className="grid grid-cols-6 gap-4">
              <div className="h-2 bg-panel rounded"></div>
            </div>
          </div>
          <div className="rounded-full bg-panel h-8 w-12"></div>
          <div className="rounded-full bg-panel h-8 w-8"></div>
          <div className="rounded-full bg-panel h-8 w-8"></div>
          <div className="rounded-full bg-panel h-8 w-8"></div>
        </div>
      </div>
    </div>
  );
};

export default memo(EntityDetailsLoadingPlaceholder);
