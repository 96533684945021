import { fillAppSliceWithQueryParams } from 'features/App/App.slice';
import { ROUTES } from 'global-constants';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { prefixRoute } from 'utils/utils.routing';


// this hook is used for routes that has no query params syncing and fires fillAppSliceWithQueryParams action
// to make sure app queryParamsWereFilled is true
const useReadAppQueryParams = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (
      [ROUTES.RCA_WORKBENCH, ROUTES.TOP_INSIGHTS, ROUTES.ENTITIES_GRAPH]
        .map((r) => prefixRoute(r))
        .includes(location.pathname) === false
    ) {
      dispatch(fillAppSliceWithQueryParams());
    }
    // eslint-disable-next-line
  }, []);
};

export default useReadAppQueryParams;
