import { useQuery } from '@tanstack/react-query';
import { Entity } from 'asserts-types';
import { fetchAssertionLogsUrl } from 'services/Assertion.service';

interface UseOpenLogsParams {
  start: number;
  end: number;
  entity: Entity | undefined;
  additionalLabels?: Record<string, string>;
}

export default function useLogsUrl({ start, end, entity, additionalLabels }: UseOpenLogsParams) {
  const labels = {
    ...(entity?.properties || {}),
    ...(entity
      ? {
          asserts_entity_name: entity.name,
          asserts_entity_type: entity.type,
          asserts_env: entity.scope?.env,
          asserts_site: entity.scope?.site,
          namespace: entity.scope?.namespace,
        }
      : {}),
    ...(additionalLabels || {}),
  };
  return useQuery(['logs-link', start, end, entity], () => fetchAssertionLogsUrl(start, end, labels), {
    enabled: Boolean(start && end && entity),
    keepPreviousData: true,
  });
}
