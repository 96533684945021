/*
 *
 * Assertions constants
 *
 */

import { SAAFE } from 'asserts-types';

export const SLICE_NAME = 'assertions';

export const TIMELINE_STEPS_COUNT = 6;

export const LINE_HEIGHT = 75;
export const MIDDLE_LEVEL_LINE_HEIGHT = 40;
export const LEAF_LINE_HEIGHT = 95;
export const EXPANDED_LINE_HEIGHT = 170;
export const FULL_EXPANDED_LINE_HEIGHT = '70vh';
export const SAAFE_VALUES: SAAFE[] = [
  'saturation',
  'amend',
  'anomaly',
  'failure',
  'error',
];

export const EXPANDED_SUMMARY_ROW_HEIGHT = 150;
export const EXPANDED_SUMMARY_ROW_GAP = 20;

export const thresholdTypeMap = {
  RequestRateAnomaly: 'request',
  ErrorRatioAnomaly: 'request',
  ErrorRatioBreach: 'request',
  ErrorBuildup: 'request',
  InboundClientErrorAnomaly: 'request',
  ErrorLogRateBreach: 'request',
  LatencyAverageAnomaly: 'request',
  LatencyAverageBreach: 'request',
  LatencyP99ErrorBuildup: 'request',
  Saturation: 'resource',
  ResourceRateBreach: 'resource',
  ResourceMayExhaust: 'resource',
  ResourceRateAnomaly: 'resource',
  LoggerRateAnomaly: 'request',
};
