/**
 *
 * SelectedAssertionsActions
 *
 */

import React, { memo, FunctionComponent, useState, MouseEvent } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import KeyboardArrowDownRoundedIcon from 'assets/material-icons/keyboard_arrow_down_FILL0_wght400_GRAD0_opsz24.svg';
import { HierarchicalAssertion } from 'asserts-types';
import { useDispatch } from 'react-redux';
import {
  removeEntitiesFromWorkbench,
  resetSelectedItems,
  setSelectedItems,
  setShowOnlySelected,
} from '../../Assertions.slice';
import { selectRecursively } from '../../Assertions.helpers';
import { useAppSelector } from 'app/store';
import { Checkbox, Dropdown, Menu } from '@grafana/ui';
import DotBadge from 'components/DotBadge/DotBadge.component';

interface IProps {
  assertions: HierarchicalAssertion[] | undefined;
}

const SelectedAssertionsActions: FunctionComponent<IProps> = ({
  assertions,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const selectedItems = useAppSelector(
    (state) => state.assertions.selectedItems,
  );
  const showOnlySelected = useAppSelector(
    (state) => state.assertions.showOnlySelected,
  );

  const allSelected = !!assertions?.every((item) =>
    selectedItems.includes(item.hash),
  );

  const someSelected = !!assertions?.some((item) =>
    selectedItems.includes(item.hash),
  );

  const handleCheckboxClick = (e: MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (someSelected) {
      dispatch(resetSelectedItems());
      dispatch(setShowOnlySelected(false));
    } else {
      const idsToSelect: string[] = [];

      assertions?.forEach((item) => {
        const ids = selectRecursively([item]);
        idsToSelect.push(...ids);
      });

      dispatch(setSelectedItems(idsToSelect));
    }
  };

  const handleDeleteOthers = () => {
    const itemsToDelete =
      assertions?.filter((item) => !selectedItems.includes(item.hash)) || [];

    dispatch(
      removeEntitiesFromWorkbench(
        itemsToDelete.map((item) => ({
          name: item.name,
          type: item.type,
          scope: item.scope,
        })),
      ),
    );
  };

  const handleDeleteSelected = () => {
    const itemsToDelete =
      assertions?.filter((item) => selectedItems.includes(item.hash)) || [];

    dispatch(
      removeEntitiesFromWorkbench(
        itemsToDelete.map((item) => ({
          name: item.name,
          type: item.type,
          scope: item.scope,
        })),
      ),
    );
  };
  
  const [menuOpened, setMenuOpened] = useState(false);

  return (
    <Dropdown
      onVisibleChange={setMenuOpened}
      overlay={
        <Menu>
          <Menu.Item
            disabled={!someSelected}
            onClick={handleDeleteOthers}
            label={formatMessage(messages.deleteOthers)}
          />

          <Menu.Item
            disabled={!someSelected}
            onClick={handleDeleteSelected}
            label={formatMessage(messages.deleteSelected)}
          />

          <Menu.Item
            onClick={() => dispatch(setShowOnlySelected(!showOnlySelected))}
            active={showOnlySelected}
            disabled={!selectedItems.length}
            label={formatMessage(messages.showSelected)}
          />
        </Menu>
      }
    >
      <div
        className={`flex items-center gap-1 hover:bg-hover px-1 py-1 rounded cursor-pointer active:bg-canvas ${
          menuOpened ? 'bg-canvas' : ''
        }`}
      >
        <DotBadge show={showOnlySelected}>
          <div onClick={handleCheckboxClick}>
            <Checkbox className="m-0" checked={allSelected} indeterminate={someSelected && !allSelected} />
          </div>
        </DotBadge>
        <button className="p-0 bg-transparent flex items-center cursor-pointer border-0" data-cy="wb-more-btn">
          <KeyboardArrowDownRoundedIcon className="text-secondary w-[1em] h-[1em]" />
        </button>
      </div>
    </Dropdown>
  );
};

export default memo(SelectedAssertionsActions);
