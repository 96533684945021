import { cloneDeep, orderBy as _orderBy } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { getHash } from '../features/Assertions/Assertions.helpers';
import { getDashedScopeValues } from '../helpers/Entity.helper';
import {
  GrafanaSloType,
  Slo,
  SloResponse,
  SloTarget,
} from 'asserts-types';
import { useQuery } from '@tanstack/react-query';
import { fetchGrafanaSloList, fetchSlos } from 'services/Slo.service';
import { mergeGrafanaAndAssertsSLOs } from 'features/Slo/slo.utils';

interface UseParams {
  endTime: number;
}

export const USE_SLOS_QUERY_KEY = 'slos';

export default function useSlos({ endTime }: UseParams) {
  const search = useSelector((state: RootState) => state.slo.search);
  const order = useSelector((state: RootState) => state.slo.order);
  const orderBy = useSelector((state: RootState) => state.slo.orderBy);
  const selectedSite = useSelector((state: RootState) => state.app.selectedSite);
  const selectedEnv = useSelector((state: RootState) => state.app.selectedEnv);

  return useQuery<SloResponse>(
    [USE_SLOS_QUERY_KEY, endTime],
    async () => {
      const grafanaSLOs = fetchGrafanaSloList()
        .then((list) => list.filter((slo) => slo.readOnly.provenance === 'asserts'))
        .then((list) =>
          list.filter((s) => s.query.type === GrafanaSloType.FailureThreshold || s.query.type === GrafanaSloType.FailureRatio)
        );

      const assertsSLOs = fetchSlos(endTime);

      // merging grafana SLO data with enriched asserts Backend SLO items
      const mergedSloData: SloResponse = mergeGrafanaAndAssertsSLOs({
        assertsSLOs: await assertsSLOs,
        grafanaSLOs: await grafanaSLOs,
      });

      mergedSloData.slos.forEach((slo) => {
        let sumActualSli = 0;
        let sumIncidentCount = 0;
        let sumBudgetRemainingPersent = 0;
        let sumRecentBurnRate = 0;
        let sumStatus = 0;
        slo.sloTargetDtos.forEach((target) => {
          target.hash = getHash(`${slo.name}-${slo.type}-${getDashedScopeValues(slo.scope || {})}-${target.name}`);
          target.budgetRemainingPersent =
            Math.round(((target.badCount || 0) / (target.errorBudget || 0)) * 100) / 100 || 0;
          target.fastMin = Math.round(
            moment.duration(moment().diff(moment(target.fastBurnViolatingSince))).asMinutes()
          );
          target.slowMin = Math.round(
            moment.duration(moment().diff(moment(target.slowBurnViolatingSince))).asMinutes()
          );
          target.incidentStatus = target.fastMin === 0 && target.slowMin === 0 ? 1 : 0;
          sumActualSli += target.actualSli;
          sumIncidentCount += target.incidentCount;
          sumBudgetRemainingPersent += target.budgetRemainingPersent;
          sumRecentBurnRate += target.recentBurnRate;
          sumStatus += target.incidentStatus;
        });
        slo.average_actualSli = sumActualSli / slo.sloTargetDtos.length;
        slo.average_incidentCount = sumIncidentCount / slo.sloTargetDtos.length;
        slo.average_budgetRemainingPersent = sumBudgetRemainingPersent / slo.sloTargetDtos.length;
        slo.average_recentBurnRate = sumRecentBurnRate / slo.sloTargetDtos.length;
        slo.average_incidentStatus = sumStatus / slo.sloTargetDtos.length;
      });

      return mergedSloData;
    },
    {
      keepPreviousData: true,
      staleTime: 0,
      cacheTime: Infinity,
      select: (data) => {
        let filteredSlos = data.slos.filter((slo) => new RegExp(search || '', 'i').test(slo.name));
        if (selectedSite.length || selectedEnv.length) {
          filteredSlos = filteredSlos.filter((slo) => {
            const site = selectedSite.length ? selectedSite.includes(slo.scope?.site || '') : true;
            const env = selectedEnv.length ? selectedEnv.includes(slo.scope?.env || '') : true;
            return site && env;
          });
        }
        let clonedSlos = cloneDeep(filteredSlos);
        clonedSlos.forEach((item) => {
          item.sloTargetDtos = _orderBy(
            item.sloTargetDtos,
            [(it) => it[orderBy as keyof SloTarget]],
            [order]
          ) as SloTarget[];
        });
        const processedSlos = _orderBy(
          clonedSlos,
          [
            (item) => {
              const propName = ('average_' + orderBy) as keyof Slo;
              return item[propName];
            },
          ],
          [order]
        );
        return { ...data, slos: processedSlos };
      },
    }
  );
}
