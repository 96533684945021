import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AppRootProps, PluginContextProvider } from '@grafana/data';
import { PLUGIN_BASE_URL, ROUTES } from '../../global-constants';
import '../../styles.css';
import 'assets/icomoon/style.css';
import { IntlProvider } from 'react-intl';
import { LoadingPlaceholder, useTheme2 } from '@grafana/ui';
import { Provider, useDispatch } from 'react-redux';
import store, { persistor } from 'app/store';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'app/queryClient';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import OutsideCallConsumer from 'react-outside-call';
import { callConfig } from 'app/constants';
import 'prismjs/components/prism-promql.min.js';

// Containers
import AssertionsContainer from 'features/Assertions/Assertions.container';
import EntitiesContainer from 'features/Entities/Entities.container';
import HomeContainer from 'features/Home/Home.container';
import { PersistGate } from 'redux-persist/integration/react';
import ManageAssertionsContainer from 'features/ManageAssertions/ManageAssertions.container';
import SloContainer from 'features/Slo/Slo.container';
import CustomDashboardsContainer from 'features/CustomDashboards/CustomDashboards.container';

// import AppSettings from 'features/Configuration/components/AppSettings/AppSettings.component';
import useAppPluginInit from 'hooks/useAppPluginInit';
import IncidentsContainer from 'features/Incidents/Incidents.container';
import { useEntityDetailsOpen } from 'hooks/useEntityDetailsOpen';
import { AssertsAppJsonData, AssertsBackendStatus } from 'asserts-types';
import TrackingHelper, { useTrackLocation } from 'helpers/Tracking.helper';
import ThresholdRequestComponent from 'features/ManageAssertions/components/ThresholdRequest/ThresholdRequest.component';
import ThresholdResourceComponent from 'features/ManageAssertions/components/ThresholdResource/ThresholdResource.component';
import ThresholdHealthComponent from 'features/ManageAssertions/components/ThresholdHealth/ThresholdHealth.component';
import NotificationRequestComponent from 'features/ManageAssertions/components/NotificationRequest/NotificationRequest.component';
import NotificationResourceComponent from 'features/ManageAssertions/components/NotificationResource/NotificationResource.component';
import AddRuleFileComponent from 'features/ManageAssertions/components/AddRuleFile/AddRuleFile.component';
import CustomRulesComponent from 'features/ManageAssertions/components/CustomRules/CustomRules.component';
import RelabelConfigComponent from 'features/ManageAssertions/components/RelabelConfig/RelabelConfig.component';
import DisableRulesComponent from 'features/ManageAssertions/components/DisableRules/DisableRules.component';
import CustomModelRulesComponent from 'features/ManageAssertions/components/CustomModelRules/CustomModelRules.component';
import NotificationHealth from 'features/ManageAssertions/components/NotificationHealth/NotificationHealth.component';
import ConnectDataContainer from 'features/Configuration/components/ConnectData/ConnectData.container';
import LogsConfg from 'features/Configuration/components/LogsConfg/LogsConfg';
import AddLogConfig from 'features/Configuration/components/LogsConfg/components/AddLogConfig';
import TracesConfg from 'features/Configuration/components/TracesConfg/TracesConfg';
import AddTraceConfig from 'features/Configuration/components/TracesConfg/components/AddTraceConfig';
import useBackendStatus from 'hooks/useBackendStatus';
import pluginJson from '../../plugin.json';
import { PluginPage } from '@grafana/runtime';
import { AppNotEnabledPlaceholder } from 'components/AppNotEnabledPlaceholder/AppNotEnabledPlaceholder';
import TopInsightsContainer from 'features/TopInsights/TopInsights.container';
import { UserCan, isAdmin, isUserActionAllowed } from 'utils/permissions';
import useReadAppQueryParams from 'hooks/useReadAppQueryParams';
import SloAddConfigComponent from 'features/ManageSlo/components/SloAddConfig/SloAddConfig.component';
import { DisplayConfig } from 'features/Configuration/components/DisplayConfig/DisplayConfig.component';
import { AssertsFeatures, isFeatureToggleEnabled } from 'utils/feature-toggles';
import { setQueryParamsWereRead } from './App.slice';

function AppRoutes() {
  const dispatch = useDispatch();

  useAppPluginInit();
  useEntityDetailsOpen();
  useReadAppQueryParams();

  const { data: backendStatus } = useBackendStatus();
  const isPending = backendStatus?.status === AssertsBackendStatus.PENDING || backendStatus?.status === AssertsBackendStatus.PROCESSING_PENDING;

  useEffect(() => {
    return () => {
      // if we leave asserts app, we need to reset the flag so after some other app decides to open asserts again using url
      // it will read query params again
      dispatch(setQueryParamsWereRead(false));
    };
  }, [dispatch]);

  if (backendStatus && !backendStatus.enabled) {
    return (
      <Routes>
        {pluginJson.includes.map((item) =>
          item.path === '/a/%PLUGIN_ID%/entities' && !isPending ? (
            <Route key={item.path} path={ROUTES.ENTITIES_GRAPH} element={<HomeContainer />} />
          ) : (
            <Route
              key={item.path}
              path={item.path.replace('/a/%PLUGIN_ID%/', '')}
              element={
                <PluginPage>
                  <AppNotEnabledPlaceholder isPending={isPending} />
                </PluginPage>
              }
            />
          )
        )}
        <Route path={ROUTES.CONFIGURATION}>
          <Route path="" element={<Navigate to={ROUTES.CONNECT_DATA} replace />} />
          <Route
            path={ROUTES.CONNECT_DATA}
            element={
              isAdmin ? (
                <ConnectDataContainer />
              ) : (
                <PluginPage>
                  <AppNotEnabledPlaceholder isPending={isPending} />
                </PluginPage>
              )
            }
          />
        </Route>
      </Routes>
    );
  }

  if (backendStatus?.enabled) {
    return (
      <Routes>
        <Route path={ROUTES.TOP_INSIGHTS} element={<TopInsightsContainer />} />
        <Route path={ROUTES.ENTITIES_GRAPH} element={<EntitiesContainer />} />
        <Route path={ROUTES.INCIDENTS} element={<IncidentsContainer />} />
        <Route path={ROUTES.RCA_WORKBENCH} element={<AssertionsContainer />} />
        <Route path={ROUTES.SLO} element={<SloContainer />} />
        <Route path={ROUTES.SLO_ADD} element={<SloAddConfigComponent />} />
        <Route path={ROUTES.SLO_EDIT} element={<SloAddConfigComponent />} />

        <Route
          path={ROUTES.RULES}
          element={
            isUserActionAllowed(UserCan.ReadRules) ? (
              <ManageAssertionsContainer />
            ) : (
              <Navigate to={PLUGIN_BASE_URL} replace />
            )
          }
        >
          <Route path={ROUTES.RULES_THRESHOLD}>
            <Route path="" element={<Navigate to={ROUTES.REQUEST} replace />} />
            <Route path={ROUTES.REQUEST} element={<ThresholdRequestComponent />} />
            <Route path={ROUTES.RESOURCE} element={<ThresholdResourceComponent />} />
            <Route path={ROUTES.HEALTH} element={<ThresholdHealthComponent />} />
          </Route>

          <Route path={ROUTES.RULES_NOTIFICATIONS}>
            <Route path="" element={<Navigate to={ROUTES.REQUEST} replace />} />
            <Route path={ROUTES.REQUEST} element={<NotificationRequestComponent />} />
            <Route path={ROUTES.RESOURCE} element={<NotificationResourceComponent />} />
            <Route path={ROUTES.HEALTH} element={<NotificationHealth />} />
          </Route>

          <Route path={ROUTES.RULES_SUPPRESS}>
            <Route path="" element={<Navigate to={ROUTES.REQUEST} replace />} />
            <Route path={ROUTES.REQUEST} element={<NotificationRequestComponent suppress />} />
            <Route path={ROUTES.RESOURCE} element={<NotificationResourceComponent suppress />} />
            <Route path={ROUTES.HEALTH} element={<NotificationHealth suppress />} />
          </Route>

          {isFeatureToggleEnabled(AssertsFeatures.RELABEL_RULES_UI) && (
            <Route path={ROUTES.RULES_RELABEL}>
              <Route path="" element={<Navigate to={ROUTES.RULES_RELABEL_PROLOGUE} replace />} />
              <Route path={ROUTES.RULES_RELABEL_TYPE} element={<RelabelConfigComponent />} />
            </Route>
          )}

          <Route path={ROUTES.RULES_ADD}>
            <Route path="" element={<Navigate to={ROUTES.RULES_ADD_FILE} replace />} />
            <Route path={ROUTES.RULES_ADD_FILE} element={<AddRuleFileComponent />} />
            <Route path={ROUTES.RULES_ADD_RULE} element={<CustomRulesComponent />} />
          </Route>

          <Route path={ROUTES.RULES_DISABLE} element={<DisableRulesComponent />} />
          {/* TODO: ask team if we need these config screens
              <Route path={ROUTES.RULES_ALERT_MANAGER} element={AlertManagerConfigComponent} /> */}
          {/* <Route path={ROUTES.RULES_OTEL} element={OtelConfigComponent} /> */}
          <Route path={ROUTES.RULES_CUSTOM_MODEL} element={<CustomModelRulesComponent />} />
          <Route path="" element={<Navigate to={ROUTES.RULES_THRESHOLD} replace />} />
        </Route>

        {/* <Route path={ROUTES.CONFIGURATION} element={<AppSettings/>} /> */}
        <Route
          path={ROUTES.CONFIGURATION}
          element={isUserActionAllowed(UserCan.ReadConfig) ? null : <Navigate to={PLUGIN_BASE_URL} replace />}
        >
          {isAdmin ? (
            <>
              <Route path="" element={<Navigate to={ROUTES.CONNECT_DATA} replace />} />
              <Route path={ROUTES.CONNECT_DATA} element={<ConnectDataContainer />} />
            </>
          ) : (
            <Route path="" element={<Navigate to={ROUTES.CONFIGURATION_LINK_DASHBOARDS} replace />} />
          )}

          <Route path={ROUTES.CONFIGURATION_LINK_DASHBOARDS} element={<CustomDashboardsContainer />} />

          <Route path={ROUTES.CONFIGURATION_LOGS} element={<LogsConfg />} />
          <Route path={ROUTES.CONFIGURATION_LOGS_ADD} element={<AddLogConfig />} />
          <Route path={ROUTES.CONFIGURATION_LOGS_EDIT} element={<AddLogConfig />} />

          <Route path={ROUTES.CONFIGURATION_TRACES} element={<TracesConfg />} />
          <Route path={ROUTES.CONFIGURATION_TRACES_ADD} element={<AddTraceConfig />} />
          <Route path={ROUTES.CONFIGURATION_TRACES_EDIT} element={<AddTraceConfig />} />
          <Route path={ROUTES.CONFIGURATION_DISPLAY} element={<DisplayConfig />} />
        </Route>

        <Route path="/" element={<Navigate to={ROUTES.ENTITIES_GRAPH} replace />} />
      </Routes>
    );
  }

  return <LoadingPlaceholder text="Loading..." className="pt-32 text-center" />;
}

export function App(props: AppRootProps<AssertsAppJsonData>) {
  const theme = useTheme2();
  if (props.meta.jsonData) {
    TrackingHelper.initialize(props.meta.jsonData);
  }

  useTrackLocation();

  useEffect(() => {
    if (theme.isDark) {
      document.body.classList.add('asserts-theme-dark');
    } else {
      document.body.classList.remove('asserts-theme-dark');
    }
    if (!document.body.classList.contains('asserts-app')) {
      document.body.classList.add('asserts-app');
    }
    return () => {
      document.body.classList.remove('asserts-app');
      document.body.classList.remove('asserts-theme-dark');
    };
  }, [theme.isDark]);

  return (
    <>
      <PluginContextProvider meta={props.meta}>
        <QueryClientProvider client={queryClient}>
          <IntlProvider locale="en">
            <Provider store={store}>
              <OutsideCallConsumer config={callConfig}>
                <PersistGate loading={null} persistor={persistor}>
                  <AppRoutes />
                </PersistGate>
              </OutsideCallConsumer>
            </Provider>
          </IntlProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </PluginContextProvider>
    </>
  );
}
