import React from 'react';
import useAddLogConfigForm from '../hooks/useAddLogConfigForm';
import { Controller } from 'react-hook-form';
import {
  Alert,
  Button,
  ConfirmButton,
  Field,
  Input,
  LinkButton,
  LoadingPlaceholder,
  MultiSelect,
  Select,
} from '@grafana/ui';
import errorMessages from 'app/errorMessages';
import { useIntl } from 'react-intl';
import useEnvSiteOptionsForSelect from 'hooks/useEnvSiteOptionsForSelect';
import { PluginPage } from '@grafana/runtime';
import { useDataSources } from 'hooks/useDataSources';
import { prefixRoute } from 'utils/utils.routing';
import { ROUTES } from 'global-constants';
import generateBreadcrumbs from 'helpers/Breadcrumbs.helper';
import { useParams } from 'react-router-dom';
import useLogConfigs from '../hooks/useLogConfigs';
import useDeleteLogConfig from '../hooks/useDeleteLogConfig';

const logConfigsPath = prefixRoute([ROUTES.CONFIGURATION, ROUTES.CONFIGURATION_LOGS].join('/'));

export default function AddLogConfig() {
  const intl = useIntl();
  const { data: logConfigs, isFetching: isFetchingLogConfigs } = useLogConfigs();
  const { name } = useParams();

  const editItem = logConfigs?.environments.find((e) => e.name === name);

  const {
    control,
    appendLabelToFieldItem,
    removeLabelToFieldItem,
    labelToFieldItems,
    onSubmit,
    isLoading: isSavingConfig,
  } = useAddLogConfigForm(editItem);

  const { envOptions, siteOptions } = useEnvSiteOptionsForSelect();
  const { data: logsDatasources } = useDataSources({ type: 'loki' });
  const { mutateAsync: deleteConfig, isLoading: isDeletingConfig } = useDeleteLogConfig();

  const lokiDatasourceOptions = logsDatasources
    ?.filter((ds) => ds.type === 'loki')
    .map((ds) => ({ label: ds.name, value: ds.uid }));

  const isLoadingForm = name && isFetchingLogConfigs;

  return (
    <PluginPage
      renderTitle={(title) => <h1>{title}</h1>}
      pageNav={generateBreadcrumbs(name ? 'Edit log config' : 'Add log config', [
        { text: 'Logs', url: logConfigsPath },
      ])}
    >
      {isLoadingForm ? (
        <LoadingPlaceholder text="Loading" />
      ) : (
        <form className="max-w-[700px]" onSubmit={onSubmit}>
          {editItem?.defaultConfig && (
            <Alert title="This is a default configuration." severity="info">
              The default configuration is applied to all environments and sites.
            </Alert>
          )}
          <Controller
            name="name"
            control={control}
            rules={{
              required: intl.formatMessage(errorMessages.required),
            }}
            render={({ field, fieldState }) => (
              <Field invalid={!!fieldState.error} error={fieldState.error?.message} label="Name">
                <Input {...field} placeholder="Enter config name" disabled={!!editItem} />
              </Field>
            )}
          />
          {!editItem?.defaultConfig && (
            <Controller
              name="envs"
              control={control}
              rules={{
                validate: (v) => {
                  if (!v.length && !editItem?.defaultConfig) {
                    return intl.formatMessage(errorMessages.required);
                  }
                },
              }}
              render={({ field, fieldState }) => (
                <Field invalid={!!fieldState.error} error={fieldState.error?.message} label={'Environments'}>
                  <MultiSelect
                    {...field}
                    onChange={(values) => field.onChange(values.map((v) => v.value))}
                    //@ts-ignore
                    options={envOptions}
                  />
                </Field>
              )}
            />
          )}
          {!editItem?.defaultConfig && (
            <Controller
              name="sites"
              control={control}
              render={({ field, fieldState }) => (
                <Field invalid={!!fieldState.error} error={fieldState.error?.message} label={'Sites'}>
                  <MultiSelect
                    {...field}
                    onChange={(values) => field.onChange(values.map((v) => v.value))}
                    options={siteOptions.map((item) => ({ label: item, value: item }))}
                  />
                </Field>
              )}
            />
          )}
          <Controller
            name="dataSource"
            rules={{
              required: intl.formatMessage(errorMessages.required),
            }}
            control={control}
            render={({ field, fieldState }) => (
              <Field invalid={!!fieldState.error} error={fieldState.error?.message} label={'Data source'}>
                <Select {...field} options={lokiDatasourceOptions} onChange={(v) => field.onChange(v.value)} />
              </Field>
            )}
          />
          <Controller
            name="errorFilter"
            control={control}
            render={({ field }) => (
              <Field label="Error filter">
                <Input {...field} placeholder="Value" />
              </Field>
            )}
          />
          <Field label="Label to field mapping">
            <>
              {labelToFieldItems.map((item, index) => (
                <div className="flex items-start gap-2" key={item.id}>
                  <Controller
                    control={control}
                    name={`labelToField.${index}.label`}
                    render={({ field }) => (
                      <Field>
                        <Input {...field} placeholder="Label" />
                      </Field>
                    )}
                  />
                  <Controller
                    control={control}
                    name={`labelToField.${index}.field`}
                    render={({ field }) => (
                      <Field>
                        <Input {...field} placeholder="Field" />
                      </Field>
                    )}
                  />
                  <Button icon="times" variant="secondary" onClick={() => removeLabelToFieldItem(index)} />
                </div>
              ))}
              <Button
                icon="plus"
                variant="secondary"
                size="sm"
                onClick={() => appendLabelToFieldItem({ label: '', field: '' })}
              >
                Add item
              </Button>
            </>
          </Field>
          <div className="flex max-w-[700px] justify-between mt-8">
            <div className="flex items-center gap-3">
              <Button type="submit" disabled={isSavingConfig}>
                {isSavingConfig ? 'Saving...' : 'Save configuration'}
              </Button>
              <LinkButton variant="secondary" href={logConfigsPath}>
                Back
              </LinkButton>
            </div>
            {editItem && !editItem.defaultConfig && (
              <ConfirmButton
                onConfirm={() => deleteConfig(editItem.name)}
                className="ml-10"
                confirmVariant="destructive"
                confirmText="Confirm"
                closeOnConfirm
                disabled={isDeletingConfig}
              >
                <Button variant="destructive" icon="trash-alt">
                  {isDeletingConfig ? 'Deleting...' : 'Delete'}
                </Button>
              </ConfirmButton>
            )}
          </div>
        </form>
      )}
    </PluginPage>
  );
}
