/*
 * EntityAdvancedSearch Messages
 *
 * This contains all the text for the EntityAdvancedSearch container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'features.EntitySearchRuleItem';

export default defineMessages({
  equals: {
    id: `${scope}.equals`,
    defaultMessage: 'Equals',
  },
  notEquals: {
    id: `${scope}.notEquals`,
    defaultMessage: 'Not equals',
  },
  startsWith: {
    id: `${scope}.startsWith`,
    defaultMessage: 'Starts with',
  },
  contains: {
    id: `${scope}.contains`,
    defaultMessage: 'Contains',
  },
  isNull: {
    id: `${scope}.isNull`,
    defaultMessage: 'Is null',
  },
  isNotNull: {
    id: `${scope}.isNotNull`,
    defaultMessage: 'Is not null',
  },
  rule: {
    id: `${scope}.rule`,
    defaultMessage: 'Rule',
  },
  value: {
    id: `${scope}.value`,
    defaultMessage: 'Value',
  },
});
