import { defineMessages } from 'react-intl';

export const scope = 'features.RulesInfoSection';

export default defineMessages({
  expression: {
    id: `${scope}.expression`,
    defaultMessage: 'Expression',
  },
  for: {
    id: `${scope}.for`,
    defaultMessage: 'For',
  },
  labels: {
    id: `${scope}.labels`,
    defaultMessage: 'Labels',
  },
  details: {
    id: `${scope}.details`,
    defaultMessage: 'Details',
  },
});
