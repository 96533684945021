
import React from 'react';

export default function SloOpenIcon() {
  return (
    <svg className="svg-icon" viewBox="0 0 20 20">
      <g clipPath="url(#clip0_513_158)">
        <path
          d="M11.323 3.46619C10.6376 3.35826 9.93711 3.33565 9.23739 3.40222C7.38119 3.57883 5.63785 4.37286 4.28622 5.65729C2.9346 6.94171 2.05278 8.64233 1.78183 10.4871C1.51088 12.3319 1.86644 14.2143 2.79165 15.8331C2.93707 16.085 3.14589 16.2945 3.39734 16.4407C3.64879 16.5868 3.93412 16.6647 4.22498 16.6664H15.7666C16.0603 16.6676 16.3491 16.5911 16.6037 16.4448C16.8584 16.2985 17.0698 16.0874 17.2166 15.8331C17.9845 14.503 18.3698 12.9867 18.3301 11.4514C18.3207 11.0871 18.2874 10.7251 18.231 10.3675H16.5367C16.6346 10.8584 16.6773 11.3605 16.6626 11.8645C16.6306 12.967 16.3256 14.0442 15.775 14.9998H4.22498C3.50929 13.7582 3.21273 12.319 3.37926 10.8957C3.5458 9.47229 4.16657 8.14046 5.14955 7.09763C6.13252 6.0548 7.42537 5.35648 8.83643 5.10619C9.66498 4.95922 10.5084 4.97133 11.323 5.13616V3.46619Z"
          fill="currentColor"
        />
        <path
          d="M11.8374 8.47481L8.82498 10.4831C8.67002 10.6379 8.54709 10.8217 8.46321 11.024C8.37934 11.2264 8.33617 11.4432 8.33617 11.6623C8.33617 11.8813 8.37934 12.0982 8.46321 12.3005C8.54709 12.5028 8.67002 12.6867 8.82498 12.8414C8.97977 12.9964 9.16358 13.1193 9.36591 13.2032C9.56824 13.2871 9.78512 13.3303 10.0041 13.3303C10.2232 13.3303 10.44 13.2871 10.6424 13.2032C10.8447 13.1193 11.0285 12.9964 11.1833 12.8414L13.2011 9.81476L11.8374 8.47481Z"
          fill="currentColor"
        />
        <path
          d="M14.5837 8.3125L17.0421 5.85417V7.70833H18.7087V3H14.0004V4.66667H15.8754L13.4171 7.125L14.5837 8.3125Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_513_158">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
