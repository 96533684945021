/*
 * TopInsightsItem Messages
 *
 * This contains all the text for the TopInsightsItem component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.TopInsightsItem';

export default defineMessages({
  mindMap: {
    id: `${scope}.mindMap`,
    defaultMessage: 'Mind map',
  },
  topN: {
    id: `${scope}.topN`,
    defaultMessage:
      '{count, plural, =0 {No assertions} one {Top # entity} other {Top # entities}}',
  },
  topDetailed: {
    id: `${scope}.topDetailed`,
    defaultMessage: 'Top N Detailed',
  },
  remove: {
    id: `${scope}.remove`,
    defaultMessage: 'Delete from board',
  },
  pin: {
    id: `${scope}.pin`,
    defaultMessage: 'Pin search',
  },
  unpin: {
    id: `${scope}.unpin`,
    defaultMessage: 'Unpin',
  },
  entities: {
    id: `${scope}.entities`,
    defaultMessage: 'Reveal in Entities',
  },
  workbench: {
    id: `${scope}.workbench`,
    defaultMessage: 'Open in Workbench',
  },
  copyLink: {
    id: `${scope}.copyLink`,
    defaultMessage: 'Copy Link',
  },
  assertions: {
    id: `${scope}.assertions`,
    defaultMessage: 'Assertions',
  },
  kpi: {
    id: `${scope}.kpi`,
    defaultMessage: 'KPIs',
  },
  addToWb: {
    id: `${scope}.addToWb`,
    defaultMessage: 'Open Top Entities in Workbench',
  },
  from: {
    id: `${scope}.from`,
    defaultMessage: 'from {query}',
  },
  notFound: {
    id: `${scope}.notFound`,
    defaultMessage: 'Not Found',
  },
  query: {
    id: `${scope}.query`,
    defaultMessage: 'Search query',
  },
});
