import { CSSProperties } from 'react';
import { Options as VirtualOptions, useVirtual } from 'react-virtual';

export default function useVirtualList<T>(
  options: VirtualOptions<T> & { customParentClass?: string },
) {
  const rowVirtualizer = useVirtual(options);

  const { virtualItems } = rowVirtualizer;
  const virtualPaddingTop = virtualItems.length > 0 ? virtualItems[0].start : 0;
  const virtualPaddingBottom =
    virtualItems.length > 0
      ? rowVirtualizer.totalSize - virtualItems[virtualItems.length - 1].end
      : 0;

  const style: CSSProperties = {
    //@ts-ignore
    '--virtualPaddingTop': virtualPaddingTop + 'px',
    //@ts-ignore
    '--virtualPaddingBottom': virtualPaddingBottom + 'px',
  };

  const className = `virtualTable ${options.customParentClass || ''}`;
  return {
    parentProps: {
      style,
      className,
    },
    rowVirtualizer,
  };
}
