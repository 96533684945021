import { usePluginContext } from '@grafana/data';
import { useMutation } from '@tanstack/react-query';
import { apiHttpService } from 'app/api-http-service';
import { AssertsAppJsonData, LogConfigForm, LogIntegration } from 'asserts-types';
import { ROUTES } from 'global-constants';
import SnackbarHelper from 'helpers/Snackbar.helper';
import { pick } from 'lodash';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { prefixRoute } from 'utils/utils.routing';
import useLogConfigs from './useLogConfigs';
import useDidUpdateEffect from 'hooks/useDidUpdate';
import { useDataSource } from 'hooks/useDataSource';

const DEFAULT_FORM_VALUES = { envs: [], sites: [], errorFilter: '', defaultSearchText: '' };
const logConfigsPath = prefixRoute([ROUTES.CONFIGURATION, ROUTES.CONFIGURATION_LOGS].join('/'));

const convertToForm = (preloadedItem: LogIntegration | undefined): LogConfigForm | undefined =>
  preloadedItem
    ? {
        ...DEFAULT_FORM_VALUES,
        name: preloadedItem.name,
        ...pick(preloadedItem.logConfig, ['dataSource', 'defaultSearchText', 'errorFilter']),
        envs: preloadedItem.envsForLog || [],
        sites: preloadedItem.sitesForLog || [],
        labelToField: Object.entries(preloadedItem.logConfig.labelToLogFieldMapping || {}).map(([label, field]) => ({
          label,
          field,
        })),
      }
    : undefined;

export default function useAddLogConfigForm(preloadedItem: LogIntegration | undefined) {
  const navigate = useNavigate();
  const { refetch: refetchLogConfigs } = useLogConfigs();

  const { control, handleSubmit, reset, watch } = useForm<LogConfigForm>({
    defaultValues: preloadedItem ? convertToForm(preloadedItem) : DEFAULT_FORM_VALUES,
  });

  const selectedDataSourceUid = watch('dataSource');

  const { data: selectedDataSource } = useDataSource(selectedDataSourceUid);

  useDidUpdateEffect(() => {
    if (preloadedItem) {
      reset(convertToForm(preloadedItem));
    }
    //eslint-disable-next-line
  }, [preloadedItem]);

  const { meta } = usePluginContext();
  const metaJsonData = meta.jsonData as AssertsAppJsonData;

  const { append, remove, fields } = useFieldArray({ control, name: 'labelToField' });

  const mutation = useMutation({
    mutationFn: (formData: LogConfigForm) => {
      let dataToSend: LogIntegration = {
        name: formData.name,
        envsForLog: formData.envs,
        sitesForLog: formData.sites,
        defaultConfig: preloadedItem?.defaultConfig,
        logConfig: {
          tool: 'Loki',
          //@ts-ignore
          orgId: selectedDataSource?.orgId || metaJsonData.orgId,
          url: metaJsonData.instanceUrl,
          dateFormat: "yyyy-MM-dd'T'HH:mm: ss.SSS'Z'",
          ...pick(formData, ['dataSource', 'defaultSearchText', 'errorFilter']),
          labelToLogFieldMapping: (formData.labelToField || [])
            .filter((v) => v.field && v.label)
            .reduce((mapping, value) => {
              mapping[value.label] = value.field;
              return mapping;
            }, {} as Record<string, string>),
        },
      };

      return apiHttpService
        .post(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/environment`, dataToSend)
        .then((res) => res.data);
    },

    onSuccess: () => {
      SnackbarHelper.success('Log configuration was saved successfuly.');
      refetchLogConfigs();
      navigate(logConfigsPath);
    },
  });

  const submit = (data: LogConfigForm) => {
    mutation.mutateAsync(data);
  };

  return {
    control,
    appendLabelToFieldItem: append,
    removeLabelToFieldItem: remove,
    labelToFieldItems: fields,
    onSubmit: handleSubmit(submit),
    isLoading: mutation.isLoading,
  };
}
