/*
 * SelectedAssertionsActions Messages
 *
 * This contains all the text for the SelectedAssertionsActions component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.SelectedAssertionsActions';

export default defineMessages({
  deleteOthers: {
    id: `${scope}.deleteOthers`,
    defaultMessage: 'Delete others from board',
  },
  deleteSelected: {
    id: `${scope}.deleteSelected`,
    defaultMessage: 'Delete selected from board',
  },
  showSelected: {
    id: `${scope}.showSelected`,
    defaultMessage: 'Show selected rows only',
  },
});
