import { useDispatch } from 'react-redux';
import { setSummaryLeftPanelWidth } from '../Assertions.slice';
import { useAppSelector } from 'app/store';

export default function useSummaryLeftPanel() {
  const width = useAppSelector(
    (state) => state.assertions.summaryLeftPanelWidth,
  );
  const dispatch = useDispatch();

  const setWidth = (newWidth: number) =>
    dispatch(setSummaryLeftPanelWidth(newWidth));

  const changeWidth = (delta: number) =>
    dispatch(setSummaryLeftPanelWidth(delta));

  return { width, changeWidth, setWidth };
}
