/**
 *
 * ExpandedEntityListSettings
 *
 */

import React, { memo, FunctionComponent } from 'react';
import {
  setHighlightSelected,
  setShowNodeNames,
  setShowRelationships,
  setShowCallRates,
  setShowFullNodeNameText,
} from '../../Entities.slice';
import GraphViewSettings from '../GraphViewSettings/GraphViewSettings.component';
import { connect, ConnectedProps } from 'react-redux';
import ShowEnvSiteTagSettingComponent from '../../../../components/ShowEnvSiteTagSetting/ShowEnvSiteTagSetting.component';

const connector = connect(
  (state: RootState) => ({
    activeView: state.entities.activeView,
    showRelationships: state.entities.showRelationships,
    showNodeNames: state.entities.showNodeNames,
    showFullNodeNameText: state.entities.showFullNodeNameText,
    highlightSelected: state.entities.highlightSelected,
    showCallRates: state.entities.showCallRates,
  }),
  {
    setHighlightSelected,
    setShowNodeNames,
    setShowFullNodeNameText,
    setShowRelationships,
    setShowCallRates,
  }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IProps {}

const ViewSettings: FunctionComponent<IProps & PropsFromRedux> = ({
  activeView,
  showRelationships,
  setShowRelationships,
  showNodeNames,
  setShowNodeNames,
  highlightSelected,
  setHighlightSelected,
  showCallRates,
  setShowCallRates,
  showFullNodeNameText,
  setShowFullNodeNameText,
}) => {
  if (activeView === 'list' || activeView === 'bubble') {
    return (
      <div>
        <ShowEnvSiteTagSettingComponent />
      </div>
    );
  }

  return (
    <GraphViewSettings
      showRelationships={showRelationships}
      setShowRelationships={setShowRelationships}
      showNodeNames={showNodeNames}
      setShowNodeNames={setShowNodeNames}
      highlightSelected={highlightSelected}
      setHighlightSelected={setHighlightSelected}
      showCallRates={showCallRates}
      setShowCallRates={setShowCallRates}
      showFullNodeNameText={showFullNodeNameText}
      setShowFullNodeNameText={setShowFullNodeNameText}
    />
  );
};

export default connector(memo(ViewSettings));
