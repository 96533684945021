/**
 *
 * AssertionsTopMenuButtons
 *
 */

import React, { memo, FunctionComponent } from 'react';

import { setShowSearch, clearWorkbench } from '../../Assertions.slice';
import { connect, ConnectedProps } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import AssertionsViewSettings from '../AssertionsViewSettings/AssertionsViewSettings.component';
import { AssertionsViewType, HierarchicalAssertion } from 'asserts-types';
import AssertionsFilterComponent from '../AssertionsFilter/AssertionsFilter.component';
import ManageSearchRoundedIcon from 'assets/material-icons/manage_search_FILL0_wght400_GRAD0_opsz24.svg';
import { prefixRoute } from 'utils/utils.routing';
import { ROUTES } from 'global-constants';
import { ButtonGroup, Dropdown, Menu, ToolbarButton } from '@grafana/ui';

interface IProps {
  assertions: HierarchicalAssertion[] | undefined;
}

const connector = connect(
  (state: RootState) => ({
    viewType: state.assertions.viewType,
    hideOldAssertions: state.assertions.hideOldAssertions,
    showWithAssertions: state.assertions.showWithAssertions,
    labelsFilter: state.assertions.labelsFilter,
    workbenchEntities: state.assertions.workbenchEntities,
    showSearch: state.assertions.showSearch,
  }),
  {
    clearWorkbench,
    setShowSearch,
  }
);
type PropsFromRedux = ConnectedProps<typeof connector>;

const AssertionsTopMenuButtons: FunctionComponent<IProps & PropsFromRedux> = ({
  clearWorkbench,
  viewType,
  hideOldAssertions,
  showWithAssertions,
  setShowSearch,
  showSearch,
  labelsFilter,
  assertions,
  workbenchEntities,
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center">
      <ButtonGroup>
        <Dropdown
          overlay={
            <Menu>
              <div onClick={(e) => e.stopPropagation()} className="p-4">
                <AssertionsViewSettings />
              </div>
            </Menu>
          }
        >
          <ToolbarButton variant="canvas" icon="cog" isHighlighted={showWithAssertions || hideOldAssertions} />
        </Dropdown>
        {viewType === AssertionsViewType.BY_ENTITY && (
          <Dropdown
            overlay={
              <Menu>
                <div onClick={(e) => e.stopPropagation()} className="p-4">
                  <AssertionsFilterComponent assertions={assertions} />
                </div>
              </Menu>
            }
          >
            <ToolbarButton
              variant="canvas"
              icon="filter"
              disabled={!workbenchEntities.length}
              isHighlighted={!!Object.keys(labelsFilter).length}
            />
          </Dropdown>
        )}

        <ToolbarButton
          variant={showSearch ? 'active' : 'canvas'}
          tooltip="Search Entities with Assertions"
          onClick={() => setShowSearch(!showSearch)}
        >
          <ManageSearchRoundedIcon className="w-[18px] h-[18px] !flex svg-icon" />
        </ToolbarButton>
        <ToolbarButton
          variant="destructive"
          icon="trash-alt"
          tooltip="Clear"
          data-cy="wb-clear"
          onClick={() => {
            clearWorkbench();
            navigate(prefixRoute(ROUTES.RCA_WORKBENCH), { state: { timestamp: Date.now() } });
          }}
        />
      </ButtonGroup>
    </div>
  );
};

export default connector(memo(AssertionsTopMenuButtons));
