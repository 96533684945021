import React, { FunctionComponent, memo, useCallback, useMemo } from 'react';
import ExpandedEntityListItem from '../ExpandedEntityListItem/ExpandedEntityListItem.component';
import { DEFAULT_ENTITY_COLUMNS } from '../../constants';
import { connect, ConnectedProps } from 'react-redux';

import { setOrder, setOrderBy } from '../../Entities.slice';
import { stringToDate } from 'helpers/Date.helper';
import useKpiValues from '../../hooks/useKpiValues';
import useMonitoringStatus from '../../hooks/useMonitoringStatus';
import useVirtualList from 'hooks/useVirtualList';
import useSortedEntityList from '../../hooks/useSortedEntityList';
import useColumnsMeasurer from '../../hooks/useColumnsMeasurer';
import EntitiesResultsInfoComponent from '../EntitiesResultsInfo/EntitiesResultsInfo.component';
import EntitiesSortByComponent from '../EntitiesSortBy/EntitiesSortBy.component';
import useKpiColumnDefaults from '../../hooks/useKpiColumnDefaults';
import EntityListColumnsSettingsComponent from '../EntityListColumnsSettings/EntityListColumnsSettings.component';
import { Entity, KpiGroup } from 'asserts-types';
import useKpiConfig from 'hooks/useKpiConfig';
import { LoadingBar, PanelContainer } from '@grafana/ui';
import TableSortButton from '../TableSortButton/TableSortButton.component';
import { setActiveEntityDetails } from 'features/App/App.slice';

const connector = connect(
  (state: RootState) => ({
    activeEntity: state.entities.activeEntity,
    order: state.entities.order,
    orderBy: state.entities.orderBy,
    start: state.app.start,
    end: state.app.end,
  }),
  {
    setOrder,
    setOrderBy,
    setActiveEntityDetails,
  }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IProps {
  entityList: Entity[];
  activeEntityConnections: Entity[];
  isFetching: boolean;
  entityListColumns: string[];
  kpiListColumns: string[];
  listKpis: KpiGroup['kpis'];
  columnsOptions: Record<string, string[]>;
  filteredKpis: string[];
  lastUpdateTime: number | undefined;
}

const ExpandedEntityList: FunctionComponent<IProps & PropsFromRedux> = ({
  activeEntity,
  setActiveEntityDetails,
  setOrder,
  setOrderBy,
  order,
  orderBy,
  activeEntityConnections,
  entityList,
  start,
  end,
  kpiListColumns,
  listKpis,
  isFetching,
  entityListColumns,
  columnsOptions,
  filteredKpis,
  lastUpdateTime,
}) => {
  const memoEndMs = useMemo(() => stringToDate(end).valueOf(), [end]);

  const handleSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const rows = useMemo(
    () => (activeEntity ? activeEntityConnections : entityList),
    [activeEntity, activeEntityConnections, entityList],
  );

  useKpiConfig();

  useKpiColumnDefaults(entityList);

  const { isLoading: isLoadingKpiValues } = useKpiValues({
    rows: activeEntity ? rows.concat([activeEntity]) : rows,
    start,
    end,
    kpis: kpiListColumns,
  });

  const { isLoading: isLoadingMonitoringStatus } = useMonitoringStatus({
    rows: activeEntity ? rows.concat([activeEntity]) : rows,
    endMs: memoEndMs,
  });

  const getColumnName = useCallback(
    (optionName: string) => {
      const name = optionName.replace(/_/g, ' ');
      return listKpis.find((k) => k.name === optionName)?.displayName || name;
    },
    [listKpis],
  );

  const parentRef = React.useRef(null);
  const sortedRows = useSortedEntityList(rows);

  const { parentProps, rowVirtualizer } = useVirtualList({
    size: sortedRows.length,
    parentRef,
    customParentClass: 'min-w-[750px]',
  });

  const columnsWidthMap = useColumnsMeasurer(rows, entityListColumns);

  return (
    <div className="flex items-start h-full relative">
      {(isLoadingMonitoringStatus || isLoadingKpiValues) && (
        <div className="absolute top-0 inset-x-0">
          <LoadingBar width={300} />
        </div>
      )}

      <EntityListColumnsSettingsComponent
        listKpis={listKpis}
        columnsOptions={columnsOptions}
        filteredKpis={filteredKpis}
      />
      <div className="flex flex-col pl-8 pt-6 w-0 h-full grow">
        <div className="flex items-end justify-between mb-4">
          <EntitiesResultsInfoComponent
            entityListLength={activeEntity ? activeEntityConnections.length : entityList.length}
            isFetching={isFetching}
          />
          {!activeEntity && <EntitiesSortByComponent />}
        </div>
        <PanelContainer className="min-w-0 grow overflow-overlay">
          <div ref={parentRef}>
            <table {...parentProps} className={`border-separate w-full border-spacing-0 [&_td]:py-2 ${parentProps.className}`}>
              <thead className="font-bold">
                <tr className={`[&_td]:sticky [&_td]:top-0 [&_td]:bg-paper [&_td]:z-[99] [&_td]:divider-b`}>
                  {activeEntity && (
                    <ExpandedEntityListItem
                      setActiveEntityDetails={setActiveEntityDetails}
                      entity={activeEntity}
                      entityListColumns={entityListColumns}
                      lastUpdateTime={lastUpdateTime}
                    />
                  )}
                </tr>
                <tr
                  className={`[&_th]:sticky [&_th]:bg-paper [&_th]:z-[99] [&_th]:divider-b ${
                    activeEntity ? '[&_th]:top-[65px]' : '[&_th]:top-0'
                  }`}
                >
                  {DEFAULT_ENTITY_COLUMNS.concat(entityListColumns).map((headCell) => (
                    <th
                      key={headCell}
                      className="whitespace-nowrap p-4 capitalize"
                      style={{ minWidth: columnsWidthMap[headCell] }}
                    >
                      <TableSortButton
                        className="font-bold capitalize whitespace-nowrap text-left"
                        active={orderBy === headCell}
                        direction={orderBy === headCell ? order : 'asc'}
                        onClick={() => handleSort(headCell)}
                        style={{ minWidth: columnsWidthMap[headCell] }}
                      >
                        {getColumnName(headCell)}
                      </TableSortButton>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rowVirtualizer.virtualItems.map((virtualRow) => {
                  return (
                    <tr
                      key={virtualRow.index}
                      ref={virtualRow.measureRef}
                      data-cy="expanded-list-item"
                      className="hover:bg-hover"
                    >
                      <ExpandedEntityListItem
                        setActiveEntityDetails={setActiveEntityDetails}
                        entity={sortedRows[virtualRow.index]}
                        entityListColumns={entityListColumns}
                        lastUpdateTime={lastUpdateTime}
                      />
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </PanelContainer>
      </div>
    </div>
  );
};

export default connector(memo(ExpandedEntityList));
