/**
 *
 * SearchFilterControl
 *
 */

import React, { memo, FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from './messages';
import MenuRoundedIcon from 'assets/material-icons/menu_FILL0_wght400_GRAD0_opsz24.svg';
import TocRoundedIcon from 'assets/material-icons/toc_FILL0_wght400_GRAD0_opsz24.svg';
import { setSelectedOnly } from 'features/Entities/Entities.slice';
import { useAppSelector } from 'app/store';

interface IProps {}

const SearchFilterControl: FunctionComponent<IProps> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const selectedOnly = useAppSelector((state) => state.entities.selectedOnly);

  return (
    <>
      <div
        onClick={() => dispatch(setSelectedOnly(!selectedOnly))}
        className="text-sm flex items-center cursor-pointer space-x-2 text-secondary hover:bg-hover px-2 py-1 rounded-md"
      >
        {selectedOnly ? (
          <TocRoundedIcon className="svg-icon" />
        ) : (
          <MenuRoundedIcon className="svg-icon" />
        )}
        <div>
          <span>
            {formatMessage(selectedOnly ? messages.selectedOnly : messages.all)}
          </span>
        </div>
      </div>
    </>
  );
};

export default memo(SearchFilterControl);
