import { defineMessages } from 'react-intl';

export const scope = 'components.AddRuleTopMenu';

export default defineMessages({
  fileSaved: {
    id: `${scope}.fileSaved`,
    defaultMessage: 'File was saved successfully',
  },
  fileSaveError: {
    id: `${scope}.fileSaveError`,
    defaultMessage: 'Error while saving a file ',
  },
  opening: {
    id: `${scope}.oepning`,
    defaultMessage: 'Opening...',
  },
  viewFile: {
    id: `${scope}.viewFile`,
    defaultMessage: 'View/Edit YML file',
  },
  search: {
    id: `${scope}.search`,
    defaultMessage: 'Search rule',
  },
  newRuleFile: {
    id: `${scope}.newRuleFile`,
    defaultMessage: 'New rule file',
  },
  newRule: {
    id: `${scope}.newRule`,
    defaultMessage: 'New rule',
  },
  add: {
    id: `${scope}.add`,
    defaultMessage: 'Add new rule',
  },
  help: {
    id: `${scope}.help`,
    defaultMessage: 'Additions help',
  },
});
