import React, { FC } from 'react';
import useNotificationsSloForm from '../../hooks/useNotificationsSloForm';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import messages from './messages';
import { NotificationConfig } from 'asserts-types';
import { Input, Switch } from '@grafana/ui';
import { IconButton } from 'components/IconButton/IconButton.component';
import CheckIcon from 'assets/material-icons/done_FILL0_wght400_GRAD0_opsz24.svg';
import CloseIcon from 'assets/material-icons/close_FILL0_wght400_GRAD0_opsz24.svg';

interface IProps {
  onClose: () => void;
  preloadedValue: NotificationConfig;
}

const NotificationsSloForm: FC<IProps> = ({ onClose, preloadedValue }) => {

  const intl = useIntl();

  const { isSaving, onSubmit, control } = useNotificationsSloForm({
    preloadedValue,
    onSuccess: onClose,
  });

  const renderReceiverControl = () => (
    <Controller
      name="receiverField"
      control={control}
      render={({ field }) => (
        <Input {...field} placeholder="Value" label={intl.formatMessage(messages.receiver)} disabled={isSaving} />
      )}
    />
  );

  const renderForControl = () => (
    <Controller
      name="forField"
      control={control}
      render={({ field }) => (
        <Input
          {...field}
          placeholder="Value"
          type="number"
          label={intl.formatMessage(messages.for)}
          disabled={isSaving}
          suffix="min"
        />
      )}
    />
  );

  const renderOtherLabelsControl = () => (
    <Controller
      name="otherLabelsField"
      control={control}
      render={({ field }) => (
        <Input {...field} label={intl.formatMessage(messages.otherLabels)} disabled={isSaving} placeholder="Value" />
      )}
    />
  );

  const renderSilenceSwitch = () => (
    <Controller
      name="silenced"
      control={control}
      render={({ field }) => {
        return <Switch value={!field.value} onClick={() => field.onChange(!field.value)} />;
      }}
    />
  );

  return (
    <tr>
      <td className="p-4">{preloadedValue.name}</td>
      <td className="p-4">{renderReceiverControl()}</td>
      <td className="p-4">{renderForControl()}</td>
      <td className="p-4">{renderOtherLabelsControl()}</td>
      <td className="p-4">{renderSilenceSwitch()}</td>
      <td className="p-4">
        <IconButton variant="primary" onClick={onSubmit} disabled={isSaving}>
          <CheckIcon />
        </IconButton>
        <IconButton variant="primary" onClick={onClose} disabled={isSaving}>
          <CloseIcon />
        </IconButton>
      </td>
    </tr>
  );
};

export default NotificationsSloForm;
