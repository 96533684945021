import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SAAFE_VALUES, SLICE_NAME } from './constants';
import {
  AssertionsBoardEntity,
  AssertionsContainerState,
  AssertionsQueryParams,
  AssertionsViewType,
  ChartTooltipType,
  HierarchicalAssertion,
  isValidAssertionsViewType,
  SAAFE,
  Sort,
  TimelineViewType,
} from 'asserts-types';
import { unionWith } from 'lodash';
import { Point } from '@antv/g2/lib/interface';
import { areEntitiesEqual } from '../../helpers/Entity.helper';
import { selectRecursively } from './Assertions.helpers';
import { LEFT_PANEL_INITIAL_WIDTH } from './components/AssertionsSummaryView/constants';

const initialState: AssertionsContainerState = {
  workbenchEntities: [],
  viewType: AssertionsViewType.BY_ENTITY,
  expandedItems: [],
  selectedItems: [],
  linesHeight: {},
  showSearch: false,
  nullAsZeroByHash: {},
  showSavedSearch: false,
  showWithAssertions: false,
  hideOldAssertions: false,
  oldAssertionHours: 48,
  scrollPosition: 0,
  alertCategories: SAAFE_VALUES,
  storedCollapsedItems: {},
  timelineViewType: TimelineViewType.GRADIENT,
  labelsFilter: {},
  expandedSaafeFilter: true,
  showOnlySelected: false,
  sort: {
    field: 'totalScore',
    order: 'desc',
  },
  expandedSummaryItems: [],
  summaryLeftPanelWidth: LEFT_PANEL_INITIAL_WIDTH,
  withRCA: false,
  selectedTime: undefined,
  chartTooltip: ChartTooltipType.DEFAULT,
};

export const slice = createSlice({
  name: SLICE_NAME,

  initialState,

  reducers: {
    setSearch: (state, action: PayloadAction<string | undefined>) => {
      state.search = action.payload;
    },
    setWithRCA: (state, action: PayloadAction<boolean>) => {
      state.withRCA = action.payload;
    },

    setHighlightedItemId: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.highlightedItemId = action.payload;
    },

    setScrollPosition: (state, action: PayloadAction<number>) => {
      state.scrollPosition = action.payload;
    },

    setShowOnlySelected: (state, action: PayloadAction<boolean>) => {
      state.showOnlySelected = action.payload;
    },

    setSort: (state, action: PayloadAction<Sort<'totalScore' | 'time'>>) => {
      state.sort = action.payload;
    },

    setLineHeight: (
      state,
      action: PayloadAction<{
        itemId: string;
        height?: number;
      }>,
    ) => {
      const { itemId, height } = action.payload;
      if (height) {
        state.linesHeight[itemId] = height;
      } else {
        delete state.linesHeight[itemId];
      }
    },

    setExpandedItems: (state, action: PayloadAction<string[]>) => {
      state.expandedItems = action.payload;
    },

    toggleExpandItem: (state, action: PayloadAction<HierarchicalAssertion>) => {
      const rowItem = action.payload;
      if (state.expandedItems.includes(rowItem.hash)) {
        const ids = selectRecursively([rowItem]);

        state.expandedItems = state.expandedItems.filter(
          (item) => !ids.includes(item),
        );
      } else {
        state.expandedItems.push(rowItem.hash);
      }
    },

    toggleSelectItem: (state, action: PayloadAction<HierarchicalAssertion>) => {
      const row = action.payload;
      const ids = selectRecursively([row]);

      if (
        state.selectedItems.includes(row.hash) &&
        state.selectedItems.filter((id) => !ids.includes(id)).length === 0 &&
        state.showOnlySelected
      ) {
        return;
      }
      if (state.selectedItems.includes(row.hash)) {
        state.selectedItems = state.selectedItems.filter(
          (id) => !ids.includes(id),
        );
      } else {
        state.selectedItems = state.selectedItems.concat(ids);
      }
    },

    toggleAlertCategories: (state, action: PayloadAction<SAAFE[]>) => {
      action.payload.forEach((option) => {
        if (state.alertCategories.includes(option)) {
          state.alertCategories = state.alertCategories.filter(
            (level) => level !== option,
          );
        } else {
          state.alertCategories.push(option);
        }
      });
    },

    setAlertCategories: (state, action: PayloadAction<SAAFE[]>) => {
      state.alertCategories = action.payload;
    },

    setSelectedItems: (state, action: PayloadAction<string[]>) => {
      state.selectedItems = action.payload;
    },

    resetSelectedItems: (state) => {
      if (state.selectedItems.length) {
        state.selectedItems = [];
      }
    },

    setViewType: (state, action: PayloadAction<AssertionsViewType>) => {
      state.viewType = action.payload;
    },

    setWorkbenchEntities: (
      state,
      action: PayloadAction<AssertionsBoardEntity[]>,
    ) => {
      state.workbenchEntities = action.payload;
    },

    addEntityToWorkbench: (
      state,
      action: PayloadAction<AssertionsBoardEntity>,
    ) => {

      state.workbenchEntities = unionWith(
        state.workbenchEntities,
        [action.payload],
        (a, b) => areEntitiesEqual(a, b),
      );

      state.search = undefined;
    },

    addEntitiesToWorkbench: (
      state,
      action: PayloadAction<AssertionsBoardEntity[]>,
    ) => {

      state.workbenchEntities = unionWith(
        state.workbenchEntities,
        action.payload,
        (a, b) => areEntitiesEqual(a, b),
      );

      state.search = undefined;
    },

    setShowSearch: (state, action: PayloadAction<boolean>) => {
      state.showSearch = action.payload;
    },
    setNullAsZero: (state, action: PayloadAction<string>) => {
      state.nullAsZeroByHash[action.payload] = !state.nullAsZeroByHash[
        action.payload
      ];
    },
    setShowSavedSearches: (state, action: PayloadAction<boolean>) => {
      state.showSavedSearch = action.payload;
    },
    setShowWithAssertions: (state, action: PayloadAction<boolean>) => {
      state.showWithAssertions = action.payload;
    },
    setHideOldAssertions: (state, action: PayloadAction<boolean>) => {
      state.hideOldAssertions = action.payload;
    },
    setOldAssertionHours: (state, action: PayloadAction<number>) => {
      state.oldAssertionHours = action.payload;
    },
    setStoredCollapsedItems: (
      state,
      action: PayloadAction<Record<string, boolean>>,
    ) => {
      state.storedCollapsedItems = action.payload;
    },
    setTimelineViewType: (state, action: PayloadAction<TimelineViewType>) => {
      state.timelineViewType = action.payload;
    },
    setExpandedSaafeFilter: (state, action: PayloadAction<boolean>) => {
      state.expandedSaafeFilter = action.payload;
    },
    setActiveChartPoint: (state, action: PayloadAction<Point | undefined>) => {
      state.activeChartPoint = action.payload;
    },
    setExpandedSummaryItems: (state, action: PayloadAction<string[]>) => {
      state.expandedSummaryItems = action.payload;
    },
    toggleExpandSummaryItem: (state, action: PayloadAction<string>) => {
      if (state.expandedSummaryItems.indexOf(action.payload) !== -1) {
        state.expandedSummaryItems = state.expandedSummaryItems.filter(
          (item) => item !== action.payload,
        );
      } else {
        state.expandedSummaryItems.push(action.payload);
      }
    },
    setSummaryLeftPanelWidth: (state, action: PayloadAction<number>) => {
      state.summaryLeftPanelWidth = action.payload;
    },
    changeSummaryLeftPanelWidth: (state, action: PayloadAction<number>) => {
      state.summaryLeftPanelWidth += action.payload;
    },
    setSelectedTime: (state, action: PayloadAction<number | undefined>) => {
      state.selectedTime = action.payload;
    },
    clearLabelFilter: (state) => {
      state.labelsFilter = {};
    },
    toggleLabelFilter: (
      state,
      action: PayloadAction<{ label: string; value: string }>,
    ) => {
      const { label, value } = action.payload;

      // if filter contains label with value
      if (state.labelsFilter[label]?.includes(value)) {
        const filteredValues = state.labelsFilter[label].filter(
          (v) => v !== value,
        );
        if (filteredValues.length) {
          state.labelsFilter[label] = filteredValues;
        } else {
          delete state.labelsFilter[label];
        }

        return;
      }

      // if filter is not empty but has no label in it
      if (state.labelsFilter && !state.labelsFilter[label]) {
        state.labelsFilter[label] = [value];
        return;
      }

      // if filter is not empty and has label but has no value in it
      if (
        state.labelsFilter &&
        state.labelsFilter[label] &&
        !state.labelsFilter[label].includes(value)
      ) {
        state.labelsFilter[label].push(value);
        return;
      }
    },
    setChartTooltip: (state, action: PayloadAction<ChartTooltipType>) => {
      state.chartTooltip = action.payload;
    },

    fillSliceWithQueryParams: (
      state,
      action: PayloadAction<AssertionsQueryParams>,
    ) => {
      const queryParams = action.payload;

      if (queryParams?.workbenchEntities) {
        state.workbenchEntities = Object.values(
          queryParams?.workbenchEntities,
        ).map((item) => ({
          ...item,
          scope: item.scope ? item.scope : {},
        }));
      } else {
        state.workbenchEntities = [];
      }

      if (queryParams?.view && isValidAssertionsViewType(queryParams?.view)) {
        state.viewType = queryParams.view;
      }

      state.search = queryParams?.search;
    },

    removeEntitiesFromWorkbench: (
      state,
      action: PayloadAction<AssertionsBoardEntity[]>,
    ) => {
      const itemsToDelete = action.payload;
      state.selectedItems = [];
      state.search = undefined;
      state.showOnlySelected = false;

      state.workbenchEntities = state.workbenchEntities.filter(
        (item) => !itemsToDelete.find((e) => areEntitiesEqual(e, item)),
      );
    },

    clearWorkbench: (state) => {
      state.workbenchEntities = [];
      state.expandedItems = [];
      state.scrollPosition = 0;
      state.selectedItems = [];
      state.showOnlySelected = false;
      state.alertCategories = SAAFE_VALUES;
      state.search = undefined;
      state.labelsFilter = {};
    },
  },
});

export const {
  setWorkbenchEntities,
  addEntityToWorkbench,
  setViewType,
  toggleExpandItem,
  toggleSelectItem,
  setSelectedItems,
  resetSelectedItems,
  setLineHeight,
  setShowSearch,
  addEntitiesToWorkbench,
  setExpandedItems,
  setHighlightedItemId,
  setNullAsZero,
  setShowSavedSearches,
  setShowWithAssertions,
  setHideOldAssertions,
  setOldAssertionHours,
  setScrollPosition,
  toggleAlertCategories,
  setStoredCollapsedItems,
  setTimelineViewType,
  setExpandedSaafeFilter,
  setActiveChartPoint,
  setShowOnlySelected,
  setAlertCategories,
  setSort,
  setExpandedSummaryItems,
  toggleExpandSummaryItem,
  setSummaryLeftPanelWidth,
  changeSummaryLeftPanelWidth,
  setWithRCA,
  setSelectedTime,
  setSearch,
  toggleLabelFilter,
  clearLabelFilter,
  setChartTooltip,
  fillSliceWithQueryParams,
  removeEntitiesFromWorkbench,
  clearWorkbench,
} = slice.actions;

export default slice.reducer;
