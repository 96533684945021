import { config } from '@grafana/runtime';
import { useMutation } from '@tanstack/react-query';
import { DisplayConfig } from 'asserts-types';
import SnackbarHelper from 'helpers/Snackbar.helper';
import TrackingHelper from 'helpers/Tracking.helper';
import useAppDisplayConfig from 'hooks/useAppDisplayConfig';
import { useForm } from 'react-hook-form';
import { updateGraphDisplayConfig } from 'services/App.service';

const defaultValues = {
  entityType: '',
  color: config.theme2.visualization.getColorByName('purple'),
  icon: '',
};

interface EntityDisplayConfigValues {
  entityType: string;
  color: string;
  icon: string;
}

interface DisplayConfigMutationVars {
  displayConfig: Partial<DisplayConfig['graph']>;
  entityType: string;
}

export default function useGraphDisplayOverrideForm() {
  const { refetch, data: displayConfig } = useAppDisplayConfig();

  const form = useForm<EntityDisplayConfigValues>({ defaultValues });

  const mutation = useMutation<any, unknown, DisplayConfigMutationVars>({
    mutationFn: ({ displayConfig }) => {
      return updateGraphDisplayConfig(displayConfig);
    },
    onSuccess: (res, { entityType }) => {
      TrackingHelper.trackDisplayConfigUpdated(entityType);
      SnackbarHelper.success('Display config added');
      form.reset();
      refetch();
    },
    onError: (res: any) => {
      SnackbarHelper.error(`Display config update failed: ${res?.message}`);
    },
  });

  const submit = (data: EntityDisplayConfigValues) => {
    if (!displayConfig?.graph?.entities) {
      throw new Error('Display config not loaded');
    }

    const overrides = displayConfig.graph.overrides ?? { entities: {}, edges: {} };
    overrides.entities[data.entityType] = { color: data.color, icon: data.icon, shape: 'circle' };

    mutation
      .mutateAsync({
        displayConfig: {
          entities: overrides.entities,
          edges: {},
        },
        entityType: data.entityType,
      })
      .then(() => {
        form.reset();
      });
  };

  return { ...form, ...mutation, onSubmit: form.handleSubmit(submit) };
}
