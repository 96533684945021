import { useMutation } from '@tanstack/react-query';
import { apiHttpService } from 'app/api-http-service';
import { LogIntegrationsResponse } from 'asserts-types';
import SnackbarHelper from 'helpers/Snackbar.helper';
import useLogConfigs from './useLogConfigs';
import { useNavigate } from 'react-router-dom';
import { prefixRoute } from 'utils/utils.routing';
import { ROUTES } from 'global-constants';

const logConfigsPath = prefixRoute([ROUTES.CONFIGURATION, ROUTES.CONFIGURATION_LOGS].join('/'));

export default function useDeleteLogConfig() {
  const { refetch: refetchLogConfigs } = useLogConfigs();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (name: string) =>
      apiHttpService
        .delete<LogIntegrationsResponse>(
          `/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/environment/${encodeURIComponent(
            name
          )}`
        )
        .then((res) => res.data),

    onSuccess: () => {
      SnackbarHelper.success('Log configuration was deleted successfuly.');
      refetchLogConfigs();
      navigate(logConfigsPath);
    },
  });
}
