/**
 *
 * IncidentTimeLineItem
 *
 */

import React, { memo, FunctionComponent, useState, useEffect, useRef, useCallback } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';

import TimelineTicks from 'components/TimelineTicks/TimelineTicks.component';
import {
  EXPANDED_LINE_HEIGHT,
  LEAF_LINE_HEIGHT,
  LINE_HEIGHT,
  TIMELINE_STEPS_COUNT,
} from '../../../Assertions/constants';
import IncidentTimelineChart from '../IncidentTimelineChart/IncidentTimelineChart.component';
import LineRangeChart from 'components/LineRangeChart/LineRangeChart';
import moment from 'moment';
import { IncidentGroup } from 'asserts-types';
import { addEntityToWorkbench, setSearch, clearWorkbench } from '../../../Assertions/Assertions.slice';
import messages from './messages';
import { useIntl } from 'react-intl';
import { Chart } from '@antv/g2';
import { Event } from '@antv/g2/lib/dependents';
import useEscapeKey from 'hooks/useEscapeKey';
import { AXIS_PADDING_RIGHT } from 'app/constants';
import useSloChart from '../../../Slo/hooks/useSloChart';
import CodeBlockComponent from 'components/CodeBlock/CodeBlock.component';
import { setTimeRange } from 'features/App/App.slice';
import useAssertionEntityMetrics from 'hooks/useAssertionEntityMetrics';
import { Button, LoadingBar } from '@grafana/ui';
import { useNavigate } from 'react-router-dom';
import { prefixRoute } from 'utils/utils.routing';
import { ROUTES, TRACKING_FEATURES } from 'global-constants';
import TrackingHelper from 'helpers/Tracking.helper';

const connector = connect((state: RootState) => ({}), {
  setTimeRange,
  addEntityToWorkbench,
  clearWorkbench,
});

interface IProps {
  incidentGroup: IncidentGroup;
  expanded?: boolean;
  start: number;
  end: number;
  isLeaf: boolean;
  chartName: string;
}
type PropsFromRedux = ConnectedProps<typeof connector>;

const IncidentTimeLineItem: FunctionComponent<IProps & PropsFromRedux> = ({
  incidentGroup,
  expanded,
  start,
  end,
  setTimeRange,
  addEntityToWorkbench,
  isLeaf,
  clearWorkbench,
  chartName,
}) => {
  const intl = useIntl();
  const [isTooltipLocked, setIsTooltipLocked] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const chartRef = useRef<Chart>(null);

  const openInWorkbench = useCallback(
    (startTime: number, endTime: number) => {
      clearWorkbench();
      const start = moment(startTime).subtract(5, 'm').valueOf();
      const end = moment(endTime).add(5, 'm').valueOf();

      if (incidentGroup.type === 'assertion_alert') {
        const entity = {
          name: incidentGroup.detail.name as string,
          type: incidentGroup.detail.type as string,
          //TODO: check scope in detail response
          scope: incidentGroup.detail.scope,
        };
        setTimeRange({ start, end });
        addEntityToWorkbench(entity);
      } else {
        dispatch(setSearch(incidentGroup.detail.action));
        setTimeRange({ start, end });
      }
      TrackingHelper.trackNavigateToWorkbench(TRACKING_FEATURES.INCIDENTS);
      navigate(prefixRoute(ROUTES.RCA_WORKBENCH), { state: { timestamp: Date.now() } });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      incidentGroup.detail.action,
      incidentGroup.detail.name,
      incidentGroup.detail.scope,
      incidentGroup.detail.type,
      incidentGroup.type,
    ]
  );

  useEscapeKey(() => {
    chartRef.current?.unlockTooltip();
    chartRef.current?.hideTooltip();
    setIsTooltipLocked(false);
  });

  const renderTooltipButtons = useCallback(
    (time: number) => (
      <div className="flex items-center gap-2 ml-3">
        <Button
          size="sm"
          variant="secondary"
          onClick={(e) => {
            e.stopPropagation();
            openInWorkbench(time, time);
          }}
        >
          {intl.formatMessage(messages.view)}
        </Button>
        {isTooltipLocked && (
          <Button
            size="sm"
            variant="secondary"
            onClick={(e) => {
              e.stopPropagation();
              chartRef.current?.unlockTooltip();
              chartRef.current?.hideTooltip();
              setIsTooltipLocked(false);
            }}
          >
            {intl.formatMessage(messages.close)}
          </Button>
        )}
      </div>
    ),
    [openInWorkbench, intl, isTooltipLocked]
  );

  const { data: sloChartData, isFetching: isLoadingSloChart } = useSloChart({
    start,
    end,
    scope: incidentGroup.detail.scope,
    sloName: incidentGroup.detail.sloName || '',
    chartName,
    sloTargetName: incidentGroup.detail.sloTargetName || '',
    enabled: Boolean(incidentGroup.type === 'slo_alert' && expanded),
  });

  const { data: chartData, isFetching: isLoadingChart } = useAssertionEntityMetrics({
    assertionName: incidentGroup.detail.assertionName || '',
    entityName: incidentGroup.detail.name || '',
    entityType: incidentGroup.detail.type || '',
    startTime: start.valueOf(),
    endTime: end.valueOf(),
    labels: incidentGroup.detail.labels || {},
    enabled: Boolean(
      incidentGroup.detail.assertionName &&
        incidentGroup.detail.name &&
        incidentGroup.detail.type &&
        incidentGroup.detail.labels &&
        expanded
    ),
  });

  useEffect(() => {
    const chart = chartRef.current;

    if (!(chartData || sloChartData) || !chart || !expanded) {
      return;
    }

    const handleChartClick = (e: Event) => {
      if (isTooltipLocked) {
        chart.showTooltip({ x: e.x, y: e.y });
      } else {
        chart.lockTooltip();
        setIsTooltipLocked(true);
      }
    };

    chart.on('click', handleChartClick);

    return () => {
      chart.off('click', handleChartClick);
    };
  }, [chartData, sloChartData, isTooltipLocked, expanded]);

  let height: number | string = isLeaf ? LEAF_LINE_HEIGHT : LINE_HEIGHT;

  if (expanded) {
    height = EXPANDED_LINE_HEIGHT;
  }

  const isLoading = isLoadingSloChart || isLoadingChart;

  return (
    <div ref={ref}>
      <div
        style={{
          height,
          paddingBottom: expanded ? 36 : 0,
          paddingRight: AXIS_PADDING_RIGHT,
        }}
        className={`divider-b flex items-center relative`}
      >
        {isLoading && isLeaf && (
          <div className="absolute top-0 inset-x-0">
            <LoadingBar width={300} />
          </div>
        )}
        <div className="absolute left-0 top-0 bottom-0" style={{ right: AXIS_PADDING_RIGHT }}>
          <TimelineTicks count={TIMELINE_STEPS_COUNT} />
        </div>
        <IncidentTimelineChart
          className="bg-transparent"
          incidents={incidentGroup.incidents}
          start={start}
          end={end}
          expanded={expanded}
          openInWorkbench={openInWorkbench}
        />
        {expanded && (
          <LineRangeChart
            ref={chartRef}
            data={chartData?.metrics || sloChartData?.metrics || []}
            hideX
            padding={[10, AXIS_PADDING_RIGHT, 10, 0]}
            positionY="right"
            minX={chartData ? chartData.timeWindow.start : sloChartData?.startTimeMs}
            maxX={chartData ? chartData.timeWindow.end : sloChartData?.endTimeMs}
            minY={0}
            timeStepInterval={chartData ? chartData.timeStepIntervalMs : sloChartData?.stepMs}
            thresholds={chartData?.thresholds || sloChartData?.thresholds || []}
            type="line"
            areaUnderLine
            showLegend
            nullAsZero
            showCrosshairs
            renderTooltipButtons={renderTooltipButtons}
            disableTooltipPortal
          />
        )}
      </div>
      {expanded && (sloChartData?.totalEventQuery || sloChartData?.measurementQuery || sloChartData?.badEventQuery) && (
        <div className="p-4 bg-paper divider-b space-y-4" onMouseDown={(e) => e.stopPropagation()}>
          {sloChartData?.totalEventQuery && (
            <div className="space-y-1 text-xs">
              <p className="text-sm font-bold">{intl.formatMessage(messages.totalEventQuery)}:</p>
              <CodeBlockComponent>{sloChartData.totalEventQuery}</CodeBlockComponent>
            </div>
          )}
          {sloChartData?.measurementQuery && (
            <div className="space-y-1 text-xs">
              <p className="text-sm font-bold">{intl.formatMessage(messages.measurementQuery)}:</p>
              <CodeBlockComponent>{sloChartData.measurementQuery}</CodeBlockComponent>
            </div>
          )}
          {sloChartData?.badEventQuery && (
            <div className="space-y-1 text-xs">
              <p className="text-sm font-bold">{intl.formatMessage(messages.badEventQuery)}:</p>
              <CodeBlockComponent>{sloChartData.badEventQuery}</CodeBlockComponent>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default connector(memo(IncidentTimeLineItem));
