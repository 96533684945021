/**
 *
 * RangePickerLine
 *
 */

import React, { memo, FunctionComponent, useMemo } from 'react';
import moment from 'moment';
import { getTimeFromCoordinates } from 'helpers/Time.helper';

interface IProps {
  mouseX: number;
  tapeEl: HTMLDivElement;
  start: number;
  end: number;
  selectedStartX: number | null;
  rightOffset?: number;
  leftOffset?: number;
}

const RangePickerLine: FunctionComponent<IProps> = ({
  mouseX,
  tapeEl,
  start,
  end,
  selectedStartX,
  rightOffset = 0,
  leftOffset = 0,
}) => {
  const movingTooltipDate = useMemo(() => {
    return getTimeFromCoordinates({
      mouseX: selectedStartX && mouseX < selectedStartX ? selectedStartX : mouseX,
      tapeEl,
      start,
      end,
      rightOffset,
      leftOffset,
    });
  }, [selectedStartX, mouseX, tapeEl, start, end, rightOffset, leftOffset]);

  const staticTooltipDate = useMemo(() => {
    return getTimeFromCoordinates({
      mouseX: selectedStartX || mouseX,
      tapeEl,
      start,
      end,
      rightOffset,
      leftOffset,
    });
  }, [selectedStartX, mouseX, tapeEl, start, end, rightOffset, leftOffset]);

  const left = (selectedStartX || mouseX) - tapeEl.getBoundingClientRect().x + tapeEl.scrollLeft;

  const width = selectedStartX ? mouseX - tapeEl.getBoundingClientRect().x + tapeEl.scrollLeft - left : 0;

  return (
    <>
      {selectedStartX && (
        <div
          className="fixed z-[999]"
          style={{
            left: selectedStartX || 0,
            top: tapeEl.getBoundingClientRect().top,
          }}
        >
          <div className="bg-black/80 text-white h-[35px] px-2 items-center flex rounded -translate-x-1/2">
            <p className="block whitespace-nowrap text-xs">{moment(staticTooltipDate).format('DD/MM h:mmA')}</p>
          </div>
        </div>
      )}
      <div
        className="fixed z-[999]"
        style={{
          top: tapeEl.getBoundingClientRect().top,
          left: selectedStartX && mouseX < selectedStartX ? selectedStartX : mouseX,
        }}
      >
        <div className="bg-black/80 text-white h-[35px] px-2 items-center flex rounded -translate-x-1/2">
          <p className="block whitespace-nowrap text-xs">{moment(movingTooltipDate).format('DD/MM h:mmA')}</p>
        </div>
      </div>
      <div
        style={{
          height: `${tapeEl?.scrollHeight}px`,
          left,
          width,
        }}
        className={`absolute top-0 bg-primary/50 border-l-2 border-l-primary border-solid z-[2] pointer-events-none ${
          selectedStartX ? 'border-r-primary border-r-2' : 'border-0'
        }`}
      />
    </>
  );
};

export default memo(RangePickerLine);
