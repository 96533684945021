/*
 * HoveredButtons Messages
 *
 * This contains all the text for the HoveredButtons component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.HoveredButtons';

export default defineMessages({
  open: {
    id: `${scope}.open`,
    defaultMessage: 'Open in Workbench',
  },
  addedToWorkbench: {
    id: `${scope}.addedToWorkbench`,
    defaultMessage: 'Added to workbench',
  },
  entity: {
    id: `${scope}.entity`,
    defaultMessage: 'Entity',
  },
  entityDetails: {
    id: `${scope}.entityDetails`,
    defaultMessage: 'Entity details',
  },
  viewSlo: {
    id: `${scope}.openSlo`,
    defaultMessage: 'View on SLO',
  },
  slo: {
    id: `${scope}.slo`,
    defaultMessage: 'SLO',
  },
});
