import { stringToDate } from '../helpers/Date.helper';
import { useQuery } from '@tanstack/react-query';
import { fetchJobOptions } from 'services/ManageAssertions.service';
import { useMetricsDataSource } from './useMetricsDatasource';

export default function useJobOptions(start?: number | string, end?: number | string) {
  const { data: metricsDatasource } = useMetricsDataSource();

  return useQuery(
    ['jobOptions', metricsDatasource.uid, start, end],
    () => {
      return fetchJobOptions(
        metricsDatasource.uid,
        start ? stringToDate(start).valueOf() : undefined,
        end ? stringToDate(end).valueOf() : undefined
      );
    },
    {
      enabled: !!metricsDatasource.uid,
      select: (data) => data.filter((d) => d),
      staleTime: Infinity,
    }
  );
}
