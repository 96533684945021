/**
 *
 * AssertionsTimelineItem
 *
 */

import React, { memo, FunctionComponent, useEffect, useRef, useCallback, useMemo, MouseEvent } from 'react';
import {
  EXPANDED_LINE_HEIGHT,
  FULL_EXPANDED_LINE_HEIGHT,
  LEAF_LINE_HEIGHT,
  LINE_HEIGHT,
  MIDDLE_LEVEL_LINE_HEIGHT,
  TIMELINE_STEPS_COUNT,
} from '../../constants';
import LineRangeChart from 'components/LineRangeChart/LineRangeChart';
import { TimelineViewType, HierarchicalAssertion } from 'asserts-types';
import { setLineHeight, setHighlightedItemId } from '../../Assertions.slice';
import useResizeObserver from 'use-resize-observer';
import TimelineTicks from 'components/TimelineTicks/TimelineTicks.component';
import { connect, ConnectedProps, useDispatch } from 'react-redux';

import { Chart } from '@antv/g2';
import useSyncChartTooltips from '../../hooks/useSyncChartTooltips';
import { AXIS_PADDING_RIGHT } from 'app/constants';
import useAssertionEntityMetrics from 'hooks/useAssertionEntityMetrics';
import AssertionsTimelineChartComponent from 'components/AssertionsTimelineChart/AssertionsTimelineChart.component';
import TopInsightsTimelineClustersComponent from 'components/TopInsightsTimelineClusters/TopInsightsTimelineClusters.component';
import { Button, LoadingPlaceholder } from '@grafana/ui';
import { dateTime } from '@grafana/data';
import { setActiveEntityDetails } from 'features/App/App.slice';
import { useTooltipMetricActionsMap } from 'features/Assertions/hooks/useTooltipMetricActionsMap';

interface IProps {
  item: HierarchicalAssertion;
  start: number;
  end: number;
  infoEventsToBottom?: boolean;
  selected: boolean;
  expanded?: boolean;
  fullExpanded?: boolean;
  maxScoreValue: number;
  timeWindowStart: number | undefined;
  timeWindowEnd: number | undefined;
  timeStepInterval: number | undefined;
}

const connector = connect(
  (state: RootState) => ({
    nullAsZeroByHash: state.assertions.nullAsZeroByHash,
    highlightedItemId: state.assertions.highlightedItemId,
    timelineViewType: state.assertions.timelineViewType,
    showOnlySelected: state.assertions.showOnlySelected,
    labelsFilter: state.assertions.labelsFilter,
  }),
  {
    setLineHeight,
    setHighlightedItemId,
  }
);
type PropsFromRedux = ConnectedProps<typeof connector>;

const AssertionsTimelineItem: FunctionComponent<IProps & PropsFromRedux> = ({
  item,
  start,
  end,
  infoEventsToBottom,
  selected,
  expanded,
  fullExpanded,
  setLineHeight,
  nullAsZeroByHash,
  highlightedItemId,
  setHighlightedItemId,
  timelineViewType,
  showOnlySelected,
  labelsFilter,
  maxScoreValue,
  timeWindowStart,
  timeWindowEnd,
  timeStepInterval,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const chartRef = useRef<Chart>(null);

  const { data: chartData } = useAssertionEntityMetrics({
    startTime: start,
    endTime: end,
    labels: item.labels,
    entityType: item.type,
    entityName: item.name,
    rootEntityName: item.rootEntityName,
    rootEntityType: item.rootEntityType,
    assertionName: item.labels.alertname,
    enabled: Boolean(expanded && item.labels.alertname && item.name && item.type && item.labels),
  });

  useSyncChartTooltips({ chartData, chartRef, key: nullAsZeroByHash[item.hash]?.toString() });

  useResizeObserver({
    ref,
    onResize: ({ height }) => {
      // this made for performance reasons, recalculate height on item expand
      if (expanded) {
        setLineHeight({ itemId: item.hash, height });
      }
    },
  });

  useEffect(() => {
    if (highlightedItemId === item.hash && ref.current) {
      setTimeout(() => {
        ref.current?.scrollIntoView();
        setHighlightedItemId();
      }, 200);
    }
    //eslint-disable-next-line
  }, [highlightedItemId]);

  const dispatch = useDispatch();

  const renderTooltipButtons = useCallback(
    (currentTime: number) => {
      const startForLogs = dateTime(currentTime).subtract(2, 'minutes').valueOf();
      const endForLogs = dateTime(currentTime).add(2, 'minutes').valueOf();
      const startForTraces = dateTime(currentTime).subtract(5, 'minutes').valueOf();
      const endForTraces = dateTime(currentTime).add(5, 'minutes').valueOf();
      return (
        <div className="flex items-center gap-2 ml-3">
          <Button
            size="sm"
            variant="secondary"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                setActiveEntityDetails({
                  name: item.rootEntityName,
                  type: item.rootEntityType,
                  scope: item.scope,
                  additionalLabels: item.labels,
                  tab: 'logs',
                  start: startForLogs,
                  end: endForLogs,
                })
              );
            }}
          >
            Logs
          </Button>
          <Button
            size="sm"
            variant="secondary"
            onClick={(e) => {
              e.stopPropagation();

              dispatch(
                setActiveEntityDetails({
                  name: item.rootEntityName,
                  type: item.rootEntityType,
                  scope: item.scope,
                  additionalLabels: item.labels,
                  tab: 'traces',
                  start: startForTraces,
                  end: endForTraces,
                  threshold: chartData?.thresholds[0]?.values || [],
                  values: chartData?.metrics[0]?.values || [],
                })
              );
            }}
          >
            Traces
          </Button>
        </div>
      );
    },
    [item, dispatch, chartData]
  );

  const isLeaf = !item.nestedTimelines.length && !!item.level;
  let backgroundClass = 'bg-transparent';
  let height: number | string = isLeaf ? LEAF_LINE_HEIGHT : LINE_HEIGHT;

  if (expanded) {
    height = EXPANDED_LINE_HEIGHT;
  }

  if (fullExpanded) {
    height = FULL_EXPANDED_LINE_HEIGHT;
  }

  if (item.level !== 0 && !isLeaf) {
    height = MIDDLE_LEVEL_LINE_HEIGHT;
  }

  if (selected && !showOnlySelected) {
    backgroundClass = 'bg-primary/10';
  }

  const isLineFiltered =
    Boolean(Object.keys(labelsFilter).length) &&
    !Object.entries(labelsFilter).every(([label, values]) =>
      item.labels?.[label] ? values.includes(item.labels?.[label]) : false
    );

  const tooltipMetricActionsMap = useTooltipMetricActionsMap({
    metrics: chartData?.metrics,
    labels: item.labels,
    scope: item.scope,
  });

  return (
    <div
      ref={ref}
      className={`block ${showOnlySelected && !selected ? 'hidden' : ''} ${isLineFiltered ? 'hidden' : ''}`}
      data-intercom-target="wb-timeline"
    >
      <div
        className={`divider-b flex items-center relative ${backgroundClass}`}
        style={{
          paddingBottom: expanded ? 34 : 0,
          paddingRight: AXIS_PADDING_RIGHT,
          height,
        }}
      >
        <div className="absolute inset-y-0 left-0" style={{ right: AXIS_PADDING_RIGHT }}>
          <TimelineTicks count={TIMELINE_STEPS_COUNT} />
        </div>
        {(timelineViewType === TimelineViewType.HEATMAP || !item.scoreMetrics) && (
          <>
            <AssertionsTimelineChartComponent
              clusters={item.clusters}
              infoEventsToBottom={infoEventsToBottom}
              className="bg-transparent"
              healthStates={item.healthStates}
              start={start}
              end={end}
              expanded={expanded}
              labels={item.labels}
              properties={item.properties}
              rootEntityName={item.rootEntityName}
              rootEntityType={item.rootEntityType}
              assertionNames={item.assertionNames}
              entityName={item.name}
              entityType={item.type}
              scope={item.scope}
            />
            {expanded && chartData && (
              <LineRangeChart
                ref={chartRef}
                data={chartData.metrics}
                hideX
                padding={[10, AXIS_PADDING_RIGHT, 10, 0]}
                positionY="right"
                minX={chartData.timeWindow.start}
                maxX={chartData.timeWindow.end}
                minY={0}
                timeStepInterval={chartData.timeStepIntervalMs}
                thresholds={chartData.thresholds}
                type="line"
                showLegend
                nullAsZero={nullAsZeroByHash[item.hash]}
                areaUnderLine
                disableTooltipPortal
                renderTooltipButtons={renderTooltipButtons}
                tooltipMetricActionsMap={tooltipMetricActionsMap}
              />
            )}
            {expanded && !!item.rootInfoHealthStates.length && (
              <AssertionsTimelineChartComponent
                className="bg-transparent absolute bottom-[40px] left-0 w-auto"
                style={{ right: AXIS_PADDING_RIGHT }}
                clusters={[]}
                infoEventsToBottom
                healthStates={item.rootInfoHealthStates}
                start={start}
                end={end}
                expanded={false}
                labels={item.labels}
                properties={item.properties}
                rootEntityName={item.rootEntityName}
                rootEntityType={item.rootEntityType}
                assertionNames={item.assertionNames}
                entityName={item.name}
                entityType={item.type}
                scope={item.scope}
                disableTooltips
              />
            )}
          </>
        )}
        {timeWindowStart &&
          timeWindowEnd &&
          !!item.name &&
          !!item.type &&
          !!item.scoreMetrics?.length &&
          timelineViewType === TimelineViewType.GRADIENT && (
            <>
              <LineRangeChart
                className="overflow-hidden [&>div]:bottom-[-1px]"
                data={item.scoreMetrics}
                minX={timeWindowStart}
                maxX={timeWindowEnd}
                hideX
                hideY
                disableGrid
                padding={[0, AXIS_PADDING_RIGHT, 0, 0]}
                type="area"
                minY={0}
                maxY={maxScoreValue}
                nullAsZero={false}
                timeStepInterval={timeStepInterval}
                fillOpacity={0.6}
                disableTooltip
              />
              <TopInsightsTimelineClustersComponent
                clusters={item.clusters}
                start={start}
                end={end}
                entityName={item.name}
                entityType={item.type}
                scope={item.scope}
                disableAddButton
              />
            </>
          )}
        {expanded && !chartData && (
          <LoadingPlaceholder
            className="absolute inset-0 flex items-center justify-center gap-1"
            text="Loading chart"
          />
        )}
      </div>
    </div>
  );
};

export default connector(memo(AssertionsTimelineItem));
