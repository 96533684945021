/**
 *
 * AssertionsListItem
 *
 */

import React, { memo, FunctionComponent, useState, useRef, useEffect } from 'react';
import AssertionsListItemWrap from '../AssertionsListItemWrap/AssertionsListItemWrap.component';
import { toggleExpandItem, toggleSelectItem } from '../../Assertions.slice';
import { connect, ConnectedProps } from 'react-redux';
import { HierarchicalAssertion } from 'asserts-types';
import ElipsisText from 'components/EllipsisText/ElipsisText.component';
import HoveringTagComponent from 'components/HoveringTag/HoveringTag.component';
import { Tooltip } from 'components/Tooltip/Tooltip.component';

interface IProps {
  toggleSelectItem: typeof toggleSelectItem;
  selected: boolean;
  itemId: string;
  start: number;
  end: number;
  toggleExpandItem: typeof toggleExpandItem;
  expanded?: boolean;
  height?: number;
  highlightedItemId?: string;
  item: HierarchicalAssertion;
  onMount?: (el: HTMLDivElement | null) => void;
}

const connector = connect(
  (state: RootState) => ({
    showOnlySelected: state.assertions.showOnlySelected,
    labelsFilter: state.assertions.labelsFilter,
  }),
  {}
);
type PropsFromRedux = ConnectedProps<typeof connector>;

const AssertionsListItem: FunctionComponent<IProps & PropsFromRedux> = ({
  selected,
  itemId,
  toggleSelectItem,
  toggleExpandItem,
  expanded,
  height,
  highlightedItemId,
  item,
  showOnlySelected,
  start,
  end,
  onMount,
  labelsFilter,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [activeTooltip, setActiveTooltip] = useState(false);

  const { type, labels, level, assertionName } = item;

  useEffect(() => {
    onMount?.(containerRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parseName = (name: string) => {
    const parsedName = name.split('::');
    const customerName = labels['asserts_customer'];
    return parsedName.map((namePart, index) => (
      <div key={index + 'boxPart'} className="flex items-center">
        {index === 0 && (
          <i
            className={`icon-${expanded ? 'down' : 'right'} text-secondary text-[20px] mr-1 mt-[2px] ml-[-23px] inline`}
          />
        )}
        {Array.from(Array(index), (_, i) => (
          <span key={i} className="mr-2.5 flex items-center text-secondary">
            &#8226;
          </span>
        ))}

        <div className="text-ellipsis overflow-hidden whitespace-nowrap flex w-full">
          <ElipsisText>
            <Tooltip content={namePart} placement="top" enterDelay={500}>
              <p>
                {namePart}
                {parsedName.length - 1 === index && customerName ? ` - ${customerName}` : ''}
              </p>
            </Tooltip>
          </ElipsisText>
        </div>
      </div>
    ));
  };

  return (
    <AssertionsListItemWrap
      level={level}
      ref={containerRef}
      toggleSelectItem={toggleSelectItem}
      itemId={itemId}
      selected={selected}
      expanded={expanded}
      onClick={() => toggleExpandItem(item)}
      height={height}
      highlightedItemId={highlightedItemId}
      activeTooltip={activeTooltip}
      labels={labels}
      item={item}
      showOnlySelected={showOnlySelected}
      start={start}
      end={end}
      labelsFilter={labelsFilter}
    >
      <div className="grow min-w-[120px] mr-2" style={{ marginLeft: 16 * (level ? level + 1 : 0) }}>
        {parseName(assertionName || '')}
      </div>
      {type && (
        <div className="shrink min-w-[100px]">
          <HoveringTagComponent
            activeTooltip={activeTooltip}
            setActiveTooltip={setActiveTooltip}
            item={item}
            start={start}
            end={end}
          />
        </div>
      )}
    </AssertionsListItemWrap>
  );
};

export default connector(memo(AssertionsListItem));
