import React, { ReactElement, CSSProperties, useCallback, useEffect, memo } from 'react';
import { Graph } from '@antv/g6';

import ZoomInIcon from 'assets/material-icons/add_FILL0_wght400_GRAD0_opsz24.svg';
import ZoomOutIcon from 'assets/material-icons/remove_FILL0_wght400_GRAD0_opsz24.svg';
import FullscreenIcon from 'assets/material-icons/fullscreen_FILL0_wght400_GRAD0_opsz24.svg';
import FullscreenExitIcon from 'assets/material-icons/fullscreen_exit_FILL0_wght400_GRAD0_opsz24.svg';

import useFullscreen from './useFullscreen';
import useZoom from './useZoom';
// import { Slider } from '@mui/material';
import { MIN_ZOOM, MAX_ZOOM } from '../../constants';
import { PanelContainer } from '@grafana/ui';
import { IconButton } from 'components/IconButton/IconButton.component';
import { Slider } from 'components/Slider/Slider.component';

interface MenuItem {
  id: string;
  name: string;
  icon: ReactElement;
  disabled?: boolean;
  style?: object;
  action: () => void;
}

export interface RenderProps {
  toolbarCfg: MenuItem[];
  graph?: Graph;
  apis?: any; // eslint-disable-line
  graphVars?: {
    width?: number;
    height?: number;
  };
  direction?: string;
}

export type Tdirection = 'horizontal' | 'vertical';
export interface ToolbarProps {
  style?: CSSProperties;
  graphDOM?: HTMLElement;
  graph?: Graph;
  apis?: any; // eslint-disable-line
  className?: string;
  containerRef?: HTMLDivElement;
  onChange: () => void;
  direction?: Tdirection; // 指定步骤条方向。目前支持水平（horizontal）和竖直（vertical）两种方向
  render?(props: RenderProps): MenuItem[];
}

const Toolbar: React.FC<ToolbarProps> = (props) => {
  const { graph, render, apis, direction = 'vertical', onChange, containerRef } = props;

  const [fullscreen, toggleFullscreen] = useFullscreen(containerRef);
  const [zoom, handleZoom, setZoom] = useZoom(1);

  const handleGraphZoom = (isZoom: boolean) => {
    onChange();
    const center = {
      x: (graph?.getWidth() || 0) / 2,
      y: (graph?.getHeight() || 0) / 2,
    };
    const newZoom = handleZoom(isZoom);
    graph?.zoomTo(newZoom, center);
  };

  const handleZoomChange = useCallback(
    (value: number) => {
      onChange();
      const center = {
        x: (graph?.getWidth() || 0) / 2,
        y: (graph?.getHeight() || 0) / 2,
      };
      setZoom(value);
      graph?.zoomTo(value, center);
    },
    [graph, setZoom, onChange]
  );

  const handleWheelZoom = useCallback(() => {
    onChange();
    setZoom(graph?.getZoom() || 0);
  }, [graph, setZoom, onChange]);

  let buttonCfg: MenuItem[] = [
    {
      id: 'zoomOut',
      name: 'zoom out',
      icon: <ZoomOutIcon />,
      disabled: zoom <= MIN_ZOOM,
      action: () => handleGraphZoom(false),
    },
    {
      id: 'zoomIn',
      name: 'zoom in',
      icon: <ZoomInIcon />,
      disabled: zoom >= MAX_ZOOM,
      action: () => handleGraphZoom(true),
    },
    {
      id: 'fullscreen',
      name: 'fullscreen',
      icon: fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />,
      disabled: false,
      action: toggleFullscreen,
    },
  ];

  if (render) {
    buttonCfg = render({
      toolbarCfg: buttonCfg,
      graph,
      apis,
      direction,
    });
  }

  useEffect(() => {
    graph?.on('viewportchange', handleWheelZoom);
    return () => {
      graph?.off('viewportchange', handleWheelZoom);
    };
    //eslint-disable-next-line
  }, [onChange]);

  return (
    <PanelContainer className="w-[320px] pl-4 absolute bottom-[28px] right-[28px] z-[99] flex items-center gap-4">
      <Slider step={0.1} min={MIN_ZOOM} max={MAX_ZOOM} value={zoom} onChange={handleZoomChange} />
      <div className="flex items-center gap-2">
        {buttonCfg.map((item) => {
          return (
            <IconButton key={item.id} variant="primary" onClick={item.action} disabled={item.disabled}>
              {item.icon}
            </IconButton>
          );
        })}
      </div>
    </PanelContainer>
  );
};

export default memo(Toolbar);
