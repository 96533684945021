import { EntityFilterCriteria, StringRules } from 'asserts-types';
import { cloneDeep } from 'lodash';

export const filterFilterCriteria = (filterCriteria: EntityFilterCriteria[]): EntityFilterCriteria[] => {
  return cloneDeep(filterCriteria).filter((criteria) => {
    criteria.propertyMatchers = criteria.propertyMatchers.filter((p) =>
      StringRules.IS_NULL === p.op || StringRules.IS_NOT_NULL === p.op ? true : p.value !== ''
    );
    return criteria.connectToEntityTypes?.length || criteria.propertyMatchers.length || criteria.havingAssertion;
  });
};
