import G6, { IG6GraphEvent, Item } from '@antv/g6';

const generateContextMenu = (
  options: {
    id: string;
    label: string;
    onClick: (i: Item) => void;
  }[],
) =>
  new G6.Menu({
    getContent(evt: IG6GraphEvent) {
      return `<ul class="bg-paper py-4 px-0 rounded-md list-none shadow">
                    ${options
                      .map((o) => {
                        return `<li class="text-black dark:text-white relative py-[6px] px-[16px] cursor-pointer select-none hover:bg-hover text-base" id="${o.id}">${o.label}</li>`;
                      })
                      .join('')}
                </ul>`;
    },
    handleMenuClick: (target: HTMLElement, item: Item) => {
      const clickedOption = options.find((o) => o.id === target.id);
      if (clickedOption) {
        clickedOption.onClick(item);
      } else {
        console.warn('options not found');
      }
    },
    offsetX: 16,
    offsetY: 16,
    itemTypes: ['node'],
  });

export default generateContextMenu;
