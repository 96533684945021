/**
 *
 * ExpandLineButton
 *
 */

import React, { memo, FunctionComponent } from 'react';
import KeyboardArrowDownRoundedIcon from 'assets/material-icons/keyboard_arrow_down_FILL0_wght400_GRAD0_opsz24.svg';
import KeyboardArrowUpRoundedIcon from 'assets/material-icons/keyboard_arrow_up_FILL0_wght400_GRAD0_opsz24.svg';
import { IconButton } from 'components/IconButton/IconButton.component';

interface IProps {
  direction: 'up' | 'down';
  onClick?: () => void;
}

const ExpandLineButton: FunctionComponent<IProps> = ({
  direction,
  onClick,
}) => {
  return (
    <div className="relative border-t-2 border-0 border-t-primary border-solid flex justify-center ">
      <IconButton
        className="absolute top-[-15px] bg-panel hover:bg-primary shadow drop-shadow hover:text-white"
        onClick={onClick}
        data-cy="collapse-btn"
      >
        {direction === 'up' ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
      </IconButton>
    </div>
  );
};

export default memo(ExpandLineButton);
