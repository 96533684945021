/**
 *
 * RelabelConfig
 *
 */

import React, { memo, FunctionComponent, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import RelabelConfigFormComponent from './components/RelabelConfigForm/RelabelConfigForm.component';
import RelabelConfigListComponent from './components/RelabelConfigList/RelabelConfigList.component';
import { useParams } from 'react-router-dom';
import YmlFileEditorComponent from 'components/YmlFileEditor/YmlFileEditor.component';
import useRelabelRulesFile from './hooks/useRelabelRulesFile';
import useSaveRelabelRulesFile from './hooks/useSaveRelabelRulesFile';
import { prefixRoute } from 'utils/utils.routing';
import { Button, PanelContainer, Tab, TabsBar, useTheme2 } from '@grafana/ui';
import { ROUTES } from 'global-constants';

interface IProps {}

const RelabelConfig: FunctionComponent<IProps> = () => {
  const intl = useIntl();

  const { type } = useParams();
  const { data: fileContentYml, isFetching: isFetchingYmlFile } = useRelabelRulesFile();

  const { mutateAsync: saveFile, isLoading: isSavingFile } = useSaveRelabelRulesFile({
    onSuccess: () => setShowFileEditor(null),
  });

  const [showFileEditor, setShowFileEditor] = useState<'yml' | 'jsonnet' | null>(null);

  const theme = useTheme2();

  const prologuePath = prefixRoute([ROUTES.RULES, ROUTES.RULES_RELABEL, ROUTES.RULES_RELABEL_PROLOGUE].join('/'));
  const epiloguePath = prefixRoute([ROUTES.RULES, ROUTES.RULES_RELABEL, ROUTES.RULES_RELABEL_EPILOGUE].join('/'));
  const generatedPath = prefixRoute([ROUTES.RULES, ROUTES.RULES_RELABEL, ROUTES.RULES_RELABEL_GENERATED].join('/'));

  return (
    <div>
      <div className="flex items-center justify-between">
        <h1 style={{ fontSize: theme.typography.h1.fontSize }} className="m-0">
          Relabel Config
        </h1>
        <div className="flex items-center gap-4">
          <Button fill="outline" onClick={() => setShowFileEditor('yml')} disabled={isFetchingYmlFile}>
            {intl.formatMessage(messages.fileBtn)}
          </Button>
        </div>
      </div>
      <TabsBar className="mt-2 mb-8">
        <Tab
          label={intl.formatMessage(messages.prologue)}
          href={prologuePath}
          active={location.pathname.includes(prologuePath)}
        />
        <Tab
          label={intl.formatMessage(messages.epilogue)}
          href={epiloguePath}
          active={location.pathname.includes(epiloguePath)}
        />
        <Tab
          label={intl.formatMessage(messages.generated)}
          href={generatedPath}
          active={location.pathname.includes(generatedPath)}
        />
      </TabsBar>
      {type !== 'generated' && (
        <PanelContainer className="p-6">
          <p className="mb-6 text-xl">
            {intl.formatMessage(messages.addTitle, {
              type,
            })}
          </p>
          <RelabelConfigFormComponent />
        </PanelContainer>
      )}
      <RelabelConfigListComponent />
      {showFileEditor && !isFetchingYmlFile && (
        <YmlFileEditorComponent
          onClose={() => {
            setShowFileEditor(null);
          }}
          text={fileContentYml}
          onSubmit={saveFile}
          saving={isFetchingYmlFile || isSavingFile}
        />
      )}
    </div>
  );
};

export default memo(RelabelConfig);
