
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { areEntitiesEqual, kpiRegexp } from '../../../helpers/Entity.helper';
import { MONITORING_COLUMNS } from '../constants';
import { Entity } from 'asserts-types';
import has from 'helpers/has.helper';

import { orderBy as _orderBy } from 'lodash';

export default function (rows: Entity[]) {
  const monitoringStatus = useSelector(
    (state: RootState) => state.entities.monitoringStatus,
  );
  const propByKpis = useSelector(
    (state: RootState) => state.entities.propByKpis,
  );
  const orderBy = useSelector((state: RootState) => state.entities.orderBy);
  const order = useSelector((state: RootState) => state.entities.order);


  const sortedRows = useMemo(() => {
    // need know what type of property string on number
    let type = '';

    const getMonitoringValue = (entity: Entity, field: string) => {
      const status: any =
        monitoringStatus.find((s) => areEntitiesEqual(s.entityKey, entity)) ||
        {};
      return status[field.split('_')[0]];
    };

    const getValue = (item: Entity) => {
      let value;
      if (propByKpis[orderBy]) {
        const kpisKeys = Object.keys(propByKpis[orderBy]);
        const reg = kpiRegexp(item.name, item.scope);
        const kpiKey = kpisKeys.find((key) => reg.test(key)) as string;
        value = propByKpis[orderBy][kpiKey]?.kpiValue;
      } else if (MONITORING_COLUMNS.includes(orderBy)) {
        value = getMonitoringValue(item, orderBy);
      }
      return has<Entity, string, string>(item, orderBy)
        ? item[orderBy]
        : value
        ? value
        : item.properties[orderBy] || '';
    };

    rows.some((item) => {
      const value = getValue(item);
      if (value) {
        type = typeof value;
        return true;
      }
      return false;
    });

    return _orderBy(
      rows,
      [
        (item) => {
          const value = getValue(item);

          if (type === 'boolean') {
            return value;
          }

          // if empty value, set value at the end of  list according with type and sort order
          const emptyValue =
            order === 'asc'
              ? type === 'string'
                ? '~'
                : Infinity
              : type === 'string'
              ? ' '
              : -Infinity;
          return value || value === 0 ? value : emptyValue;
        },
      ],
      [order],
    );
  }, [monitoringStatus, order, orderBy, propByKpis, rows]);

  return sortedRows;
}
