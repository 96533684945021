import React, { FunctionComponent, memo, useEffect, useMemo, useState } from 'react';

import { ConnectedProps, connect } from 'react-redux';

import EntityAdvancedSearchItem from './components/EntityAdvancedSearchItem/EntityAdvancedSearchItem.component';
import EntityAdvancedSearchBottomPanel from './components/EntityAdvancedSearchBottomPanel/EntityAdvancedSearchBottomPanel.component';
import { useIntl } from 'react-intl';
import messages from './messages';
import CloseRoundedIcon from 'assets/material-icons/close_FILL0_wght400_GRAD0_opsz24.svg';
import EntityAdvancedSearchNameComponent from './components/EntityAdvancedSearchName/EntityAdvancedSearchName.component';
import SearchFilterControlComponent from './components/SearchFilterControl/SearchFilterControl.component';
import {
  addSearchProperty,
  deleteRule,
  deleteSearchProperty,
  changeRule,
  changeSearchProperty,
  changeSearchPropertyConnectedEntities,
  setShowAdvancedSearch,
  clearEntities,
} from 'features/Entities/Entities.slice';
import { EntityAdvancedSearchForm, EntityPropertyTypes } from 'asserts-types';
import { fetchAdvancedSearchForm } from 'services/Entity.service';
import { stringToDate } from 'helpers/Date.helper';
import { LoadingPlaceholder, PanelContainer, Tab, TabsBar } from '@grafana/ui';
import SavedSearches from '../SavedSearches/SavedSearches';
import { IconButton } from 'components/IconButton/IconButton.component';

const connector = connect(
  (state: RootState) => ({
    searchObject: state.entities.searchObject,
    selectedOnly: state.entities.selectedOnly,
    searchDefinition: state.entities.searchDefinition,
    start: state.app.start,
    end: state.app.end,
  }),
  {
    addSearchProperty,
    deleteSearchProperty,
    deleteRule,
    changeRule,
    changeSearchProperty,
    changeSearchPropertyConnectedEntities,
    setShowAdvancedSearch,
    clearEntities,
  }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

const EntityAdvancedSearch: FunctionComponent<PropsFromRedux> = ({
  searchObject,
  addSearchProperty,
  deleteSearchProperty,
  deleteRule,
  changeRule,
  changeSearchProperty,
  changeSearchPropertyConnectedEntities,
  selectedOnly,
  searchDefinition,
  start,
  end,
  setShowAdvancedSearch,
  clearEntities,
}) => {
  const [advancedSearchOptions, setAdvancedSearchOptions] = useState<EntityAdvancedSearchForm | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const { formatMessage } = useIntl();

  const [activeTab, setActiveTab] = useState<'search' | 'saved-searches'>('search');

  useEffect(() => {
    fetchAdvancedSearchForm(stringToDate(start).valueOf(), stringToDate(end).valueOf()).then((res) => {
      setAdvancedSearchOptions({
        // adding default property to each property
        entities: res.entities.map((item) => ({
          ...item,
          properties: [{ name: 'name', type: EntityPropertyTypes.STRING, uom: null }, ...item.properties],
        })),
      });
      setIsLoading(false);
    });
  }, [start, end]);

  const options = useMemo(
    () =>
      selectedOnly
        ? advancedSearchOptions?.entities.filter((item) => {
            const filterCriteria = searchObject.filterCriteria.find((a) => a.entityType === item.entityType);
            return (
              filterCriteria?.havingAssertion ||
              filterCriteria?.connectToEntityTypes?.length ||
              filterCriteria?.propertyMatchers.length
            );
          })
        : advancedSearchOptions?.entities,
    [advancedSearchOptions, searchObject.filterCriteria, selectedOnly]
  );

  return (
    <PanelContainer
      className={`absolute top-0 bg-paper right-0 bottom-0 z-[99] shadow-md w-[408px] border-t-0 ${
        isLoading ? 'flex items-center justify-center h-full' : ''
      }`}
    >
      {isLoading ? (
        <LoadingPlaceholder text="Loading" />
      ) : (
        <>
          <div className="flex flex-col h-full">
            <div className="pl-8 pr-4 pt-3 flex items-center justify-between divider-b">
              <TabsBar className="w-full border-b-0">
                <Tab
                  label={formatMessage(messages.search)}
                  active={activeTab === 'search'}
                  onChangeTab={() => setActiveTab('search')}
                  value="graph"
                />
                <Tab
                  label={formatMessage(messages.savedSearches)}
                  active={activeTab === 'saved-searches'}
                  onChangeTab={() => setActiveTab('saved-searches')}
                  value="graph"
                />
              </TabsBar>
              <IconButton onClick={() => setShowAdvancedSearch(false)}>
                <CloseRoundedIcon />
              </IconButton>
            </div>
            {activeTab === 'search' && (
              <>
                <EntityAdvancedSearchNameComponent />
                <div className="px-8 pb-2 flex items-center justify-between divider-b">
                  <div className="flex items-baseline gap-4">
                    <p className="font-bold">Parameters</p>

                    <button
                      className="text-primary cursor-pointer bg-transparent text-xs p-0 border-0"
                      onClick={() => clearEntities()}
                    >
                      Clear all
                    </button>
                  </div>
                  {!!searchObject.filterCriteria?.length && <SearchFilterControlComponent />}
                </div>

                <div className="grow overflow-auto pt-4 px-4 space-y-1">
                  {options?.map((item) => (
                    <EntityAdvancedSearchItem
                      key={`EntityAdvancedSearchItem-${item.entityType}`}
                      changeSearchPropertyConnectedEntities={changeSearchPropertyConnectedEntities}
                      changeSearchProperty={changeSearchProperty}
                      changeRule={changeRule}
                      deleteRule={deleteRule}
                      deleteSearchProperty={deleteSearchProperty}
                      addSearchProperty={addSearchProperty}
                      filterCriteria={searchObject.filterCriteria.find((a) => a.entityType === item.entityType)}
                      formItem={item}
                    />
                  ))}
                </div>
                <EntityAdvancedSearchBottomPanel searchObject={searchObject} searchDefinition={searchDefinition} />
              </>
            )}
            {activeTab === 'saved-searches' && <SavedSearches onEdit={() => setActiveTab('search')} />}
          </div>
        </>
      )}
    </PanelContainer>
  );
};

export default connector(memo(EntityAdvancedSearch));
