import { defineMessages } from 'react-intl';

export const scope = 'features.EntityListItem';

export default defineMessages({
  addToWorkbench: {
    id: `${scope}.addToWorkbench`,
    defaultMessage: 'Add to workbench',
  },
  addedToWorkbench: {
    id: `${scope}.addedToWorkbench`,
    defaultMessage: 'Added to workbench',
  },
  kpi: {
    id: `${scope}.kpi`,
    defaultMessage: 'KPI',
  },
  copied: {
    id: `${scope}.copied`,
    defaultMessage: 'Copied',
  },
});
