/**
 *
 * ThresholdResource
 *
 */

import React, { FunctionComponent, useState, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import ThresholdTopMenu from '../ThresholdTopMenu/ThresholdTopMenu.component';
import { ThresholdItem, Sort } from 'asserts-types';
import { connect, ConnectedProps } from 'react-redux';

import { setItemToPopulate } from '../../ManageAssertions.slice';
import ThresholdList from '../ThresholdList/ThresholdList.component';
import resourceFields from '../../config/threshold_resource.json';
import ThresholdResourceForm from './components/ThresholdResourceForm/ThresholdResourceForm.component';
import thresholdAssertionMap from '../../config/threshold_assertion_map.json';
import has from 'helpers/has.helper';
import assertionsResource from '../../config/assertions_resource.json';
import { fetchThresholdResourceList } from 'services/ManageAssertions.service';
import { orderBy } from 'lodash';

const sortFields = ['assertion', 'record'].concat(
  resourceFields.map((field) => `labels.${field}`),
);

interface IProps {}

const connector = connect(
  (state: RootState) => ({
    itemToPopulate: state.manageAssertions.itemToPopulate,
  }),
  {
    setItemToPopulate,
  },
);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ThresholdResource: FunctionComponent<IProps & PropsFromRedux> = ({
  itemToPopulate,
  setItemToPopulate,
}) => {
  const intl = useIntl();
  const [searchQuery, setSearchQuery] = useState('');

  const [globalList, setGlobalList] = useState<ThresholdItem[]>([]);
  const [customList, setCustomList] = useState<ThresholdItem[]>([]);
  const [globalSort, setGlobalSort] = useState<Sort | null>(null);
  const [customSort, setCustomSort] = useState<Sort | null>(null);

  const [fetchingList, setFetchingList] = useState(true);

  useEffect(() => {
    if (
      itemToPopulate &&
      !assertionsResource.find(
        (option) =>
          has(thresholdAssertionMap, option.value) &&
          thresholdAssertionMap[option.value] === itemToPopulate.alertname,
      )
    ) {
      itemToPopulate.alertname && setSearchQuery(itemToPopulate.alertname);
      setItemToPopulate(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemToPopulate]);

  useEffect(() => {
    fetchLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchLists = () => {
    setFetchingList(true);
    fetchThresholdResourceList().then((res) => {
      setGlobalList(res.globalThresholds);
      setCustomList(res.customThresholds);
      setFetchingList(false);
    });
  };

  const handleAddCustomItem = (item: ThresholdItem) => {
    setCustomList(customList.concat([item]));
  };

  const handleUpdateGlobalItem = (
    record: string,
    expr: string,
    labels: Record<string, string>,
  ) => {
    setGlobalList(
      globalList.map((item) =>
        item.record === record &&
        item.labels?.asserts_source === labels.asserts_source &&
        item.labels?.asserts_resource_type === labels.asserts_resource_type &&
        item.labels?.asserts_severity === labels.asserts_severity &&
        item.labels?.container === labels.container
          ? { ...item, record, expr }
          : item,
      ),
    );
  };

  const handleUpdateCustomItem = (
    record: string,
    expr: string,
    labels: Record<string, string>,
  ) => {
    setCustomList(
      customList.map((item) =>
        item.record === record &&
        item.labels?.asserts_source === labels.asserts_source &&
        item.labels?.asserts_resource_type === labels.asserts_resource_type &&
        item.labels?.asserts_severity === labels.asserts_severity &&
        item.labels?.container === labels.container
          ? { ...item, expr }
          : item,
      ),
    );
  };

  const handleDeleteCustomItem = (
    record: string,
    expr: string,
    labels?: Record<string, string>,
  ) => {
    setCustomList(
      customList.filter(
        (item) =>
          !(
            item.expr === expr &&
            item.record === record &&
            item.labels?.asserts_source === labels?.asserts_source &&
            item.labels?.asserts_resource_type ===
              labels?.asserts_resource_type &&
            item.labels?.asserts_severity === labels?.asserts_severity &&
            item.labels?.container === labels?.container
          ),
      ),
    );
  };

  const globalListProcessed = useMemo(
    () =>
      orderBy(
        globalList
          .map((item) => ({
            ...item,
            assertion: has<typeof thresholdAssertionMap, string, string>(
              thresholdAssertionMap,
              item.record,
            )
              ? thresholdAssertionMap[item.record]
              : '',
          }))
          .filter((item) =>
            Object.values({
              ...item,
              ...(item.labels || {}),
            }).some(
              (item) =>
                typeof item === 'string' &&
                item.toLowerCase().includes(searchQuery.toLowerCase()),
            ),
          ),
        globalSort?.field
          ? sortFields.slice(sortFields.indexOf(globalSort?.field))
          : sortFields,
        globalSort?.order || 'asc',
      ),
    [globalList, globalSort?.field, globalSort?.order, searchQuery],
  );

  const customListProcessed = useMemo(
    () =>
      orderBy(
        customList
          .map((item) => ({
            ...item,
            assertion: has<typeof thresholdAssertionMap, string, string>(
              thresholdAssertionMap,
              item.record,
            )
              ? thresholdAssertionMap[item.record]
              : '',
          }))
          .filter((item) =>
            Object.values({
              ...item,
              ...(item.labels || {}),
            }).some(
              (item) =>
                typeof item === 'string' &&
                item.toLowerCase().includes(searchQuery.toLowerCase()),
            ),
          ),
        customSort?.field
          ? sortFields.slice(sortFields.indexOf(customSort?.field))
          : sortFields,
        customSort?.order || 'asc',
      ),
    [customList, customSort?.field, customSort?.order, searchQuery],
  );

  return (
    <div className="h-full overflow-overlay">
      <ThresholdTopMenu onSearchChange={setSearchQuery} searchQuery={searchQuery} onYmlFileChange={fetchLists} />
      <div>
        <ThresholdResourceForm
          globalList={globalList}
          customList={customList}
          onAdd={handleAddCustomItem}
          fetchingLists={fetchingList}
        />
        <ThresholdList
          title={intl.formatMessage(messages.customThresholds)}
          fields={resourceFields}
          fetchingList={fetchingList}
          list={customListProcessed}
          onUpdate={handleUpdateCustomItem}
          onDelete={handleDeleteCustomItem}
          onSort={setCustomSort}
          orderBy={customSort}
        />
        <ThresholdList
          title={intl.formatMessage(messages.defaultGlobal)}
          fields={resourceFields}
          fetchingList={fetchingList}
          list={globalListProcessed}
          onUpdate={handleUpdateGlobalItem}
          onSort={setGlobalSort}
          orderBy={globalSort}
        />
      </div>
    </div>
  );
};

export default connector(ThresholdResource);
