/**
 *
 * SloSortBy
 *
 */

import React, { memo, FunctionComponent, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from './messages';
import { setOrder, setOrderBy } from '../../Slo.slice';
import { useAppSelector } from 'app/store';
import { Icon, InlineField, Select } from '@grafana/ui';

interface IProps {}

const SloSortBy: FunctionComponent<IProps> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const orderBy = useAppSelector((state) => state.slo.orderBy);
  const order = useAppSelector((state) => state.slo.order);

  const options = useMemo(
    () => [
      {
        label: formatMessage(messages.actual),
        orderBy: 'actualSli',
        action: () => {
          dispatch(setOrder('asc'));
          dispatch(setOrderBy('actualSli'));
        },
      },
      {
        label: formatMessage(messages.budgetUsed),
        orderBy: 'budgetRemainingPersent',
        action: () => {
          dispatch(setOrder('desc'));
          dispatch(setOrderBy('budgetRemainingPersent'));
        },
      },
      {
        label: formatMessage(messages.incidentStatus),
        orderBy: 'incidentStatus',

        action: () => {
          dispatch(setOrder('asc'));
          dispatch(setOrderBy('incidentStatus'));
        },
      },
      {
        label: formatMessage(messages.numberOfIncidents),
        orderBy: 'incidentCount',

        action: () => {
          dispatch(setOrder('desc'));
          dispatch(setOrderBy('incidentCount'));
        },
      },
      {
        label: formatMessage(messages.recentBudgetUsage),
        orderBy: 'recentBurnRate',

        action: () => {
          dispatch(setOrder('desc'));
          dispatch(setOrderBy('recentBurnRate'));
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <InlineField label="Sort by">
      <Select
        options={options.map((o) => ({ label: o.label, value: o.orderBy }))}
        onChange={(v) => {
          options.find((o) => v.value === o.orderBy)?.action();
        }}
        value={orderBy}
        width="auto"
        prefix={<Icon name={order === 'asc' ? 'sort-amount-up' : 'sort-amount-down'} />}
      />
    </InlineField>
  );
};

export default memo(SloSortBy);
