import React, { FunctionComponent, memo } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import EntityFilterSettings from '../EntityFilterSettings/EntityFilterSettings.component';
import EntityViewSettings from '../ViewSettings/ViewSettings.component';
import { GraphCustomData } from 'asserts-types';
import { ButtonGroup, Dropdown, Menu, ToolbarButton } from '@grafana/ui';
import { clearEntities } from 'features/Entities/Entities.slice';

interface IProps {
  graphData: GraphCustomData;
}

const connector = connect(
  (state: RootState) => ({
    typeFilter: state.entities.typeFilter,
    nameSearchQuery: state.entities.nameSearchQuery,
  }),
  { clearEntities }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

const EntityButtonsGroup: FunctionComponent<PropsFromRedux & IProps> = ({
  typeFilter,
  nameSearchQuery,
  graphData,
  clearEntities,
}) => {
  return (
    <>
      <div data-tour-target="entities-actions">
        <ButtonGroup>
          <Dropdown
            overlay={
              <Menu>
                <div onClick={(e) => e.stopPropagation()} className="p-4">
                  <EntityFilterSettings graphData={graphData} />
                </div>
              </Menu>
            }
            placement="bottom-start"
          >
            <ToolbarButton
              variant="canvas"
              icon="filter"
              isHighlighted={typeFilter.length !== 0 || !!nameSearchQuery}
            />
          </Dropdown>
          <Dropdown
            overlay={
              <Menu>
                <div onClick={(e) => e.stopPropagation()} className="p-4">
                  <EntityViewSettings />
                </div>
              </Menu>
            }
            placement="bottom-start"
          >
            <ToolbarButton variant="canvas" icon="cog" />
          </Dropdown>

          <ToolbarButton variant="destructive" icon="trash-alt" tooltip="Clear" onClick={() => clearEntities()} />
        </ButtonGroup>
      </div>
    </>
  );
};

export default connector(memo(EntityButtonsGroup));
