import { AssertionsViewType } from 'asserts-types';

import { stringToDate } from 'helpers/Date.helper';
import { useDispatch } from 'react-redux';
import {
  setExpandedSummaryItems,
  setWorkbenchEntities,
} from '../Assertions.slice';
import { pick, unionBy } from 'lodash';
import useWorkbenchDefinition from './useWorkbenchDefinition';
import { useEffect } from 'react';
import { useAppSelector } from 'app/store';
import { SAAFE_PROPERTIES } from 'app/constants';
import { useQuery } from '@tanstack/react-query';
import { fetchAssertionsSummary, fetchAssertionsSummaryByDefinition } from 'services/Assertion.service';

const USE_ENTITY_ASSERTIONS_SUMMARY_QUERY_KEY = 'entity-assertions-summary';

export default function useEntityAssertionsSummary() {
  const dispatch = useDispatch();
  const hideOldAssertions = useAppSelector(
    (state) => state.assertions.hideOldAssertions,
  );
  const oldAssertionHours = useAppSelector((state) =>
    hideOldAssertions ? state.assertions.oldAssertionHours : undefined,
  );

  const alertCategories = useAppSelector(
    (state) => state.assertions.alertCategories,
  );

  const selectedEnv = useAppSelector((state) => state.app.selectedEnv);
  const selectedSite = useAppSelector((state) => state.app.selectedSite);

  const workbenchEntities = useAppSelector(
    (state) => state.assertions.workbenchEntities,
  );

  const withRCA = useAppSelector((state) => state.assertions.withRCA);

  const viewType = useAppSelector((state) => state.assertions.viewType);

  const expandedSummaryItems = useAppSelector(
    (state) => state.assertions.expandedSummaryItems,
  );

  const start = useAppSelector((state) => state.app.start);

  const end = useAppSelector((state) => state.app.end);

  const assertionLevels = SAAFE_PROPERTIES.filter((p) =>
    alertCategories.includes(p),
  );

  const {
    definition,
    isFetching: isFetchingDefinition,
  } = useWorkbenchDefinition();

  const query = useQuery(
    [
      USE_ENTITY_ASSERTIONS_SUMMARY_QUERY_KEY,
      definition || workbenchEntities,
      start,
      end,
      assertionLevels,
      hideOldAssertions,
      oldAssertionHours,
      withRCA,
      selectedEnv,
      selectedSite,
    ],

    async () => {
      const data = definition
        ? await fetchAssertionsSummaryByDefinition(
            definition,
            stringToDate(start).valueOf(),
            stringToDate(end).valueOf(),
            assertionLevels,
            withRCA,
            oldAssertionHours,
          )
        : await fetchAssertionsSummary(
            workbenchEntities,
            stringToDate(start).valueOf(),
            stringToDate(end).valueOf(),
            assertionLevels,
            withRCA,
            oldAssertionHours,
          );

      return data;
    },
    {
      enabled:
        Boolean(workbenchEntities.length || definition) &&
        viewType === AssertionsViewType.SUMMARY &&
        !isFetchingDefinition,
      keepPreviousData: true,
      cacheTime: Infinity,
      staleTime: typeof start === 'string' ? 0 : Infinity,
    },
  );

  // in some cases we need to merge state after data is fetched or changed (from query client cache)
  useEffect(() => {
    if (query.data?.summaries.length) {
      dispatch(
        setExpandedSummaryItems(
          unionBy(
            query.data.summaries.map((item) => item.id),
            expandedSummaryItems,
          ),
        ),
      );

      // after data is there we need to update url
      if (definition) {
        const newWorkbenchEntities = query.data.summaries.map((item) =>
          pick(item, 'name', 'type', 'scope'),
        );
        dispatch(setWorkbenchEntities(newWorkbenchEntities));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query?.data?.summaries]);

  return {
    ...query,
    isFetching: query.isFetching || isFetchingDefinition,
  };
}
