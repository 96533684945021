/**
 *
 * HoveredButtons
 *
 */

import React, { memo, FunctionComponent, MouseEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import TagLabel from 'components/TagLabel/TagLabel';
import { addEntityToWorkbench, setSearch, clearWorkbench } from '../../../Assertions/Assertions.slice';
import { IncidentGroup } from 'asserts-types';
import PropertiesPopover from 'components/PropertiesPopover/PropertiesPopover.component';
import ListAltIcon from 'assets/material-icons/list_alt_FILL0_wght400_GRAD0_opsz24.svg';
import { useNavigate } from 'react-router-dom';
import qs from 'qs';
import WbOpenIcon from 'icons/WbOpenIcon';
import { useDispatch } from 'react-redux';
import DashboardOutlinedIcon from 'assets/material-icons/dashboard_FILL0_wght400_GRAD0_opsz24.svg';
import SloOpenIcon from 'icons/SloOpenIcon';
import { setActiveEntityDetails, setTimeRange } from 'features/App/App.slice';
import { prefixRoute } from 'utils/utils.routing';
import { ROUTES, TRACKING_FEATURES } from 'global-constants';
import { Tooltip } from 'components/Tooltip/Tooltip.component';
import { IconButton } from 'components/IconButton/IconButton.component';
import TrackingHelper from 'helpers/Tracking.helper';


interface IProps {
  addEntityToWorkbench: typeof addEntityToWorkbench;
  incidentGroup: IncidentGroup;
  start: number | string;
  end: number | string;
  setActiveEntityDetails: typeof setActiveEntityDetails;
}

const HoveredButtons: FunctionComponent<IProps> = ({
  addEntityToWorkbench,
  incidentGroup,
  start,
  end,
  setActiveEntityDetails,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTooltip, setActiveTooltip] = useState(false);


  const openInWorkbench = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(clearWorkbench());

    if (incidentGroup.type === 'assertion_alert') {
      const entity = {
        name: incidentGroup.detail.name as string,
        type: incidentGroup.detail.type as string,
        //TODO: check scope in detail response
        scope: incidentGroup.detail.scope,
      };

      dispatch(setTimeRange({ start, end }));
      addEntityToWorkbench(entity);
    } else {
      dispatch(setSearch(incidentGroup.detail.action));
      dispatch(setTimeRange({ start, end }));
    }
    TrackingHelper.trackNavigateToWorkbench(TRACKING_FEATURES.INCIDENTS);
    navigate(prefixRoute(ROUTES.RCA_WORKBENCH), { state: { timestamp: Date.now() } });
  };

  const handleInfo = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (incidentGroup.type === 'assertion_alert') {
      dispatch(setTimeRange({ start, end }));
      setActiveEntityDetails({
        name: incidentGroup.detail.name as string,
        type: incidentGroup.detail.type as string,
        scope: incidentGroup.detail.scope,
      });
    } else {
      let queryParams = qs.stringify({
        sloName: incidentGroup.detail.sloName,
        sloTargetName: incidentGroup.detail.sloTargetName,
        sloEnv: incidentGroup.detail.scope.env,
        sloSite: incidentGroup.detail.scope.site,
      });
      navigate(`${prefixRoute(ROUTES.SLO)}?${queryParams}`, { state: { timestamp: Date.now() } });
    }
  };

  return (
    <>
      <div className={`group-hover/item:hidden ${activeTooltip ? '!hidden' : ''}`}>
        <TagLabel
          entityType={
            incidentGroup.type === 'assertion_alert'
              ? (incidentGroup.detail.type as string)
              : intl.formatMessage(messages.slo)
          }
          properties={incidentGroup.detail.labels}
          scope={incidentGroup.detail.scope}
        />
      </div>
      <div className={`hidden whitespace-nowrap group-hover/item:block ${activeTooltip ? '!block' : ''}`}>
        <Tooltip content={intl.formatMessage(messages.open)}>
          <IconButton onClick={openInWorkbench} variant="primary">
            <WbOpenIcon />
          </IconButton>
        </Tooltip>
        {incidentGroup.type === 'assertion_alert' && (
          <Tooltip
            onOpen={() => setActiveTooltip(true)}
            onClose={() => setActiveTooltip(false)}
            placement="top-end"
            content={
              <PropertiesPopover
                type={incidentGroup.detail.type as string}
                properties={incidentGroup.detail.labels as Record<string, string>}
                scope={incidentGroup.detail.scope}
                showAllProperties
              />
            }
          >
            <IconButton onClick={(e) => e.stopPropagation()} variant="primary" active={activeTooltip}>
              <ListAltIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip
          content={intl.formatMessage(
            incidentGroup.type === 'assertion_alert' ? messages.entityDetails : messages.viewSlo
          )}
        >
          <IconButton onClick={handleInfo} variant="primary">
            {incidentGroup.type === 'assertion_alert' ? <DashboardOutlinedIcon /> : <SloOpenIcon />}
          </IconButton>
        </Tooltip>
      </div>
    </>
  );
};

export default memo(HoveredButtons);
