/**
 *
 * AssertionsListByEntityItem
 *
 */

import React, {
  memo,
  FunctionComponent,
  MouseEvent,
  useCallback,
  useRef,
  useEffect,
  useState,
} from 'react';
import TagLabel from 'components/TagLabel/TagLabel';
import { toggleExpandItem, toggleSelectItem, removeEntitiesFromWorkbench } from '../../Assertions.slice';
import AssertionsListItem from '../AssertionsListItem/AssertionsListItem.component';
import AssertionsListItemWrap from '../AssertionsListItemWrap/AssertionsListItemWrap.component';

import { connect, ConnectedProps } from 'react-redux';
import { SAAFE_VALUES } from '../../constants';

import AssertionsDirHoveringButtons from '../AssertionsDirHoveringButtons/AssertionsDirHoveringButtons.component';
import { setActiveEntityDetails } from 'features/App/App.slice';
import { HierarchicalAssertion, SAAFE, Scope } from 'asserts-types';
import EnvSiteTagComponent from 'components/EnvSiteTag/EnvSiteTag.component';
import { useIntl } from 'react-intl';
import messages from './messages';
import { setSelectedEnv } from '../../../App/App.slice';
import PropertiesPopover from 'components/PropertiesPopover/PropertiesPopover.component';
import AssertionsTimelineItemComponent from '../AssertionsTimelineItem/AssertionsTimelineItem.component';
import useSummaryLeftPanel from '../../hooks/useSummaryLeftPanel';
import { Tooltip } from 'components/Tooltip/Tooltip.component';

const connector = connect(
  (state: RootState) => ({
    expandedItems: state.assertions.expandedItems,
    selectedItems: state.assertions.selectedItems,
    linesHeight: state.assertions.linesHeight,
    highlightedItemId: state.assertions.highlightedItemId,
    alertCategories: state.assertions.alertCategories,
    showOnlySelected: state.assertions.showOnlySelected,
  }),
  {
    toggleSelectItem,
    toggleExpandItem,
    removeEntitiesFromWorkbench,
    setActiveEntityDetails,
    setSelectedEnv,
  },
);

interface IProps {
  entityGroupItem: HierarchicalAssertion;
  selected: boolean;
  start: number;
  end: number;
  onMount?: (el: HTMLDivElement | null) => void;
  labelsFilter: Record<string, string[]>;
  maxScoreValue: number;
  timeWindowStart: number | undefined;
  timeWindowEnd: number | undefined;
  timeStepInterval: number | undefined;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

const checkVisible = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect();
  const viewHeight = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight,
  );

  return rect.bottom - viewHeight <= 0 && rect.top - viewHeight <= 0;
};

const AssertionsListByEntityItem: FunctionComponent<
  IProps & PropsFromRedux
> = ({
  entityGroupItem,
  expandedItems,
  toggleExpandItem,
  toggleSelectItem,
  selected,
  selectedItems,
  linesHeight,
  removeEntitiesFromWorkbench,
  setActiveEntityDetails,
  highlightedItemId,
  alertCategories,
  showOnlySelected,
  start,
  end,
  onMount,
  labelsFilter,
  maxScoreValue,
  timeWindowStart,
  timeWindowEnd,
  timeStepInterval,
}) => {
  const intl = useIntl();

  useEffect(() => {
    onMount?.(rootRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const expanded = expandedItems.includes(entityGroupItem.hash);

  const handleSetActiveItem = (addInboundErrorProp?: boolean) => {
    if (entityGroupItem.type) {
      const properties = Object.assign(
        {},
        entityGroupItem.labels,
        addInboundErrorProp ? { panel: 'inbound_client_error' } : {},
      );
      setActiveEntityDetails({
        type: entityGroupItem.type,
        name: entityGroupItem.name,
        scope: entityGroupItem.scope,
        properties,
      });
    }
  };

  const handleRowClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      toggleExpandItem(entityGroupItem);
    },
    [entityGroupItem, toggleExpandItem],
  );

  const openInboundError = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    handleSetActiveItem(true);
  };
  const rootRef = useRef<HTMLDivElement | null>(null);

  // checking if last expanded element if it's visible or not, if not scroll it into view
  const handleMount = (el: HTMLElement | null, index: number) => {
    el &&
      index === entityGroupItem.nestedTimelines.length - 1 &&
      !checkVisible(el) &&
      el.scrollIntoView(false);
  };

  const { width: leftPanelWidth } = useSummaryLeftPanel();

  const [connectedMenuOpened, setConnectedMenuOpened] = useState(false);

  return (
    <>
      <div
        className="grid wb-item"
        style={{
          gridTemplateColumns: `${leftPanelWidth}px auto`,
        }}
      >
        <AssertionsListItemWrap
          ref={rootRef}
          level={entityGroupItem.level}
          item={entityGroupItem}
          itemId={entityGroupItem.hash}
          selected={selected}
          onClick={handleRowClick}
          toggleSelectItem={toggleSelectItem}
          className="group"
          highlightedItemId={highlightedItemId}
          lastUpdateTime={entityGroupItem.lastUpdateTime}
          properties={entityGroupItem.properties}
          showOnlySelected={showOnlySelected}
          start={start}
          end={end}
          labelsFilter={labelsFilter}
        >
          <div className="w-0 flex mr-2 grow" style={{ marginLeft: 16 * entityGroupItem.level }}>
            <i className={`icon-rounded-${expanded ? 'down' : 'right'} text-secondary text-[20px] mr-1 mt-[2px]`} />
            <div className="overflow-hidden">
              <Tooltip
                interactive
                placement="top-end"
                enterDelay={700}
                content={
                  entityGroupItem.type ? (
                    <PropertiesPopover
                      type={entityGroupItem.type}
                      label={entityGroupItem.nameWithNamespace || entityGroupItem.name}
                      properties={entityGroupItem.properties}
                      scope={entityGroupItem.scope}
                      lastUpdateTime={entityGroupItem.lastUpdateTime}
                      disableAssertionsList
                    />
                  ) : (
                    entityGroupItem.nameWithNamespace || entityGroupItem.name
                  )
                }
              >
                <p className="text-ellipsis overflow-hidden whitespace-nowrap font-bold">
                  {entityGroupItem.nameWithNamespace || entityGroupItem.name}
                </p>
              </Tooltip>

              {entityGroupItem?.scope?.env && entityGroupItem.level === 0 && (
                <EnvSiteTagComponent scope={entityGroupItem.scope} />
              )}
              {entityGroupItem.inboundClientErrorsBreached && (
                <a
                  className="absolute bottom-[-16px] text-xs text-primary hover:underline"
                  href="#"
                  onClick={openInboundError}
                >
                  {intl.formatMessage(messages.inboundClientErrors)}
                </a>
              )}
            </div>
          </div>
          {entityGroupItem.type && !connectedMenuOpened && (
            <div className="h-[20px] flex items-center group-hover:hidden">
              <TagLabel
                entityType={entityGroupItem.type}
                properties={entityGroupItem.properties}
                scope={entityGroupItem.scope}
              />
            </div>
          )}
          <AssertionsDirHoveringButtons
            removeEntitiesFromWorkbench={removeEntitiesFromWorkbench}
            entityName={entityGroupItem.name}
            entityType={entityGroupItem.type}
            itemId={entityGroupItem.hash}
            setActiveItem={handleSetActiveItem}
            scope={entityGroupItem.scope}
            start={start}
            end={end}
            onOpenChange={setConnectedMenuOpened}
          />
        </AssertionsListItemWrap>
        <AssertionsTimelineItemComponent
          item={entityGroupItem}
          start={start}
          end={end}
          infoEventsToBottom={entityGroupItem.level <= 1 && !!entityGroupItem.nestedTimelines.length}
          expanded={
            !!entityGroupItem.healthStates.length &&
            !entityGroupItem.nestedTimelines.length &&
            expandedItems.includes(entityGroupItem.hash)
          }
          selected={selectedItems.includes(entityGroupItem.hash)}
          maxScoreValue={maxScoreValue}
          timeWindowStart={timeWindowStart}
          timeWindowEnd={timeWindowEnd}
          timeStepInterval={timeStepInterval}
        />
      </div>
      {(expanded || Boolean(Object.keys(labelsFilter).length)) &&
        entityGroupItem.nestedTimelines.map((item, index) =>
          SAAFE_VALUES.filter((s) => !alertCategories.includes(s)).includes(item.name as SAAFE) ? null : item
              .nestedTimelines.length ? (
            <ConnectedAssertionsListByEntityItem
              key={item.hash}
              onMount={(el) => handleMount(el, index)}
              selected={selectedItems.includes(item.hash)}
              entityGroupItem={item}
              start={start}
              end={end}
              labelsFilter={labelsFilter}
              maxScoreValue={maxScoreValue}
              timeWindowStart={timeWindowStart}
              timeWindowEnd={timeWindowEnd}
              timeStepInterval={timeStepInterval}
            />
          ) : (
            <div
              className="grid wb-item"
              style={{
                gridTemplateColumns: `${leftPanelWidth}px auto`,
              }}
              key={item.hash}
            >
              <AssertionsListItem
                start={start}
                end={end}
                toggleSelectItem={toggleSelectItem}
                expanded={expandedItems.includes(item.hash)}
                selected={selectedItems.includes(item.hash)}
                itemId={item.hash}
                toggleExpandItem={toggleExpandItem}
                height={linesHeight[item.hash]}
                highlightedItemId={highlightedItemId}
                item={item}
                onMount={(el) => handleMount(el, index)}
              />
              <AssertionsTimelineItemComponent
                item={item}
                start={start}
                end={end}
                infoEventsToBottom={item.level <= 1 && !!item.nestedTimelines.length}
                expanded={
                  !!item.healthStates.length && !item.nestedTimelines.length && expandedItems.includes(item.hash)
                }
                selected={selectedItems.includes(item.hash)}
                maxScoreValue={maxScoreValue}
                timeWindowStart={timeWindowStart}
                timeWindowEnd={timeWindowEnd}
                timeStepInterval={timeStepInterval}
              />
            </div>
          )
        )}
    </>
  );
};

const ConnectedAssertionsListByEntityItem = connector(
  memo(AssertionsListByEntityItem),
);

export default ConnectedAssertionsListByEntityItem;
