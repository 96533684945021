import {
  AssertionRollup,
  AssertionRollupProcessed,
  EntityProperties,
  HealthState,
  HierarchicalAssertion,
} from 'asserts-types';
import { orderBy, unionBy } from 'lodash';

// for having unique row identifier to merge rows behaviours like hovering or selecting
export const getHash = (input: string) => {
  let hash = 0,
    len = input.length;
  for (let i = 0; i < len; i++) {
    hash = (hash << 5) - hash + input.charCodeAt(i);
    hash |= 0; // to 32bit integer
  }
  return hash.toString();
};

export const selectRecursively = (
  assertions: HierarchicalAssertion[],
): string[] => {
  let result: string[] = [];

  if (assertions.length) {
    assertions.forEach((item) => {
      result = result
        .concat([item.hash])
        .concat(selectRecursively(item.nestedTimelines));
    });
    return result;
  }
  return [];
};

export const addFieldsRecursive = (
  assertions: Array<
    Omit<
      HierarchicalAssertion,
      'level' | 'rootEntityName' | 'rootEntityType' | 'rootInfoHealthStates' | 'lastUpdateTime'
    >
  >,
  level: number,
  name: string,
  type: string,
  rootInfoHealthStates: HealthState[],
  lastUpdateTime: number,
  properties?: EntityProperties,
): HierarchicalAssertion[] => {
  if (assertions.length) {
    return assertions.map((item) => ({
      ...item,
      level: level + 1,
      nestedTimelines: addFieldsRecursive(
        item.nestedTimelines,
        level + 1,
        name,
        type,
        rootInfoHealthStates,
        lastUpdateTime,
        item.properties || properties,
      ),
      rootEntityName: name,
      rootEntityType: type,
      properties: item.properties || properties,
      rootInfoHealthStates,
      lastUpdateTime,
    }));
  }
  return [];
};

let genId = 0;

export const mergeTreesRecursive = (
  rollup1: AssertionRollup[],
  rollup2: AssertionRollup[],
): AssertionRollup[] => {
  const merged = rollup1.map((item) => {
    const itemFromRollup2 = rollup2.find((a) => a.name === item.name);
    return {
      ...item,
      nextLevel: mergeTreesRecursive(
        item.nextLevel || [],
        itemFromRollup2?.nextLevel || [],
      ),
      assertionCount:
        item.assertionCount + (itemFromRollup2?.assertionCount || 0),
      warningCount: item.warningCount + (itemFromRollup2?.warningCount || 0),
      infoCount: item.infoCount + (itemFromRollup2?.infoCount || 0),
      criticalCount: item.criticalCount + (itemFromRollup2?.criticalCount || 0),
    };
  });
  return unionBy(merged, rollup2, 'name');
};

export const processAssertionsRollupRecursive = (
  items: AssertionRollup[],
  level: number,
): AssertionRollupProcessed[] => {
  if (items.length) {
    return orderBy(
      items.map((item) => ({
        id: `g${++genId}`,
        collapsed: true,
        assertionCount: item.assertionCount,
        criticalCount: item.criticalCount,
        infoCount: item.infoCount,
        warningCount: item.warningCount,
        entityType: item.type,
        name: item.name,
        timelineHashes: item.timelineHashes,
        labels: item.labels,
        pathHashesToLinkedGroups: item.pathHashesToLinkedGroups,
        level,
        scope: item.scope,
        mergedChildren:
          level === 3
            ? processAssertionsRollupRecursive(item.nextLevel || [], level + 1)
            : undefined,
        children:
          level === 3
            ? []
            : processAssertionsRollupRecursive(item.nextLevel || [], level + 1),
      })),
      ['entityType', 'assertionCount'],
      ['desc', 'desc'],
    );
  }
  return [];
};

export const filterRecursive = (
  assertions: HierarchicalAssertion[],
  showWithAssertions: boolean,
): HierarchicalAssertion[] => {
  return assertions
    .filter((item) => (showWithAssertions ? hasAssertion(item) : true))
    .map((item) => ({
      ...item,
      nestedTimelines: filterRecursive(
        item.nestedTimelines,
        showWithAssertions,
      ),
    }));
};

const hasAssertion = (item: HierarchicalAssertion): boolean => {
  return (
    !!item.assertionName ||
    !!item.nestedTimelines.filter((assertion) => hasAssertion(assertion))
      .length ||
    !!item.inboundClientErrorsBreached
  );
};
