import React, { FunctionComponent, memo, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import { useForm, Controller } from 'react-hook-form';
import errorMessages from 'app/errorMessages';
import { CustomRule, AssertionSeverity } from 'asserts-types';
import CheckIcon from 'assets/material-icons/done_FILL0_wght400_GRAD0_opsz24.svg';
import CloseIcon from 'assets/material-icons/close_FILL0_wght400_GRAD0_opsz24.svg';
import DeleteOutlineIcon from 'assets/material-icons/delete_FILL0_wght400_GRAD0_opsz24.svg';

import { saveRule } from 'services/ManageAssertions.service';
import { BracesPlugin, Button, Field, Input, PanelContainer, QueryField, Select, SlatePrism } from '@grafana/ui';
import useEntityTypes from 'hooks/useEntityTypes';
import { IconButton } from 'components/IconButton/IconButton.component';

const categories = ['failure', 'amend'];

interface IProps {
  onSave?: (item: CustomRule) => void;
  onClose?: () => void;
  onDelete?: (item: CustomRule) => void;
  preloadedValue?: CustomRule;
  tableRow?: boolean;
  list?: CustomRule[];
}

interface IForm {
  nameField: string | null;
  queryField: string | null;
  forField: string | null;
  severityField: string | null;
  categoryField: string | null;
  entityType: string;
}

const severityOptions: AssertionSeverity[] = ['critical', 'warning', 'info'];

const CustomRulesForm: FunctionComponent<IProps> = ({
  onSave,
  preloadedValue,
  tableRow,
  onDelete,
  onClose,
  list,
}) => {
  const intl = useIntl();

  const { data: entitiesData, isFetching: isLoadingEntityTypes } = useEntityTypes();

  const { handleSubmit, setValue, control, reset } = useForm<IForm>({
    defaultValues: {
      nameField: '',
      queryField: '',
      forField: '',
      severityField: null,
      categoryField: null,
      entityType: 'Service',
    },
  });

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (!preloadedValue) {
      return;
    }
    setValue('nameField', preloadedValue.alert);
    setValue('queryField', preloadedValue.expr);
    setValue('forField', preloadedValue.for?.replace('m', ''));
    setValue('severityField', preloadedValue.labels.asserts_severity);
    setValue('categoryField', preloadedValue.labels.asserts_alert_category);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = handleSubmit((data) => {
    setSaving(true);

    if (
      data.nameField &&
      data.queryField &&
      data.forField &&
      data.severityField &&
      data.categoryField
    ) {
      const form = {
        alert: data.nameField,
        expr: data.queryField,
        for: data.forField + 'm',
        labels: {
          asserts_severity: data.severityField,
          asserts_alert_category: data.categoryField,
          asserts_entity_type: data.entityType,
        },
      };
      saveRule(form).then(() => {
        onSave && onSave(form);
        setSaving(false);
        preloadedValue && onClose && onClose();
        !preloadedValue && reset();
      });
    }
  });

  const renderNameControl = () => (
    <Controller
      name="nameField"
      control={control}
      rules={{
        required: intl.formatMessage(errorMessages.required),
        validate: (v) => !list?.map((s) => s.alert).includes(String(v)) || intl.formatMessage(messages.uniqName),
      }}
      render={({ field, fieldState }) => (
        <Field
          invalid={!!fieldState.error}
          error={fieldState.error?.message}
          label={intl.formatMessage(messages.name)}
          className="w-full"
        >
          <Input {...field} value={field.value || ''} placeholder="Enter name" disabled={!!preloadedValue || saving} />
        </Field>
      )}
    />
  );

  const renderQueryControl = () => (
    <Controller
      name="queryField"
      control={control}
      rules={{ required: intl.formatMessage(errorMessages.required) }}
      render={({ field, fieldState }) => (
        <Field
          invalid={!!fieldState.error}
          error={fieldState.error?.message}
          label={intl.formatMessage(messages.query)}
          className="w-full"
        >
          <QueryField
            {...field}
            portalOrigin="."
            additionalPlugins={[
              BracesPlugin(),
              SlatePrism({
                onlyIn: (node: any) => node.type === 'code_block',
                getSyntax: () => 'promql',
              }),
            ]}
            placeholder="Enter query"
            query={field.value}
            disabled={saving}
          />
        </Field>
      )}
    />
  );
  const renderForControl = () => (
    <Controller
      name="forField"
      control={control}
      rules={{ required: intl.formatMessage(errorMessages.required) }}
      render={({ field, fieldState }) => (
        <Field label={intl.formatMessage(messages.for)} invalid={!!fieldState.error} error={fieldState.error?.message}>
          <Input
            {...field}
            value={field.value || ''}
            placeholder="Value"
            disabled={saving}
            type="number"
            suffix="min"
          />
        </Field>
      )}
    />
  );

  const renderCategoryControl = () => (
    <Controller
      name="categoryField"
      control={control}
      rules={{ required: intl.formatMessage(errorMessages.required) }}
      render={({ field, fieldState }) => (
        <Field
          invalid={!!fieldState.error}
          error={fieldState.error?.message}
          label={intl.formatMessage(messages.category)}
          className="w-full"
        >
          <Select
            {...field}
            onChange={(v) => field.onChange(v?.value || '')}
            options={categories?.map((o) => ({ label: o, value: o })) || []}
            disabled={saving}
            isClearable
          />
        </Field>
      )}
    />
  );

  const renderEnityTypeControl = () => (
    <Controller
      name="entityType"
      control={control}
      rules={{ required: intl.formatMessage(errorMessages.required) }}
      render={({ field, fieldState }) => (
        <Field
          invalid={!!fieldState.error}
          error={fieldState.error?.message}
          label={intl.formatMessage(messages.entityType)}
          className="w-full"
        >
          <Select
            {...field}
            onChange={(v) => field.onChange(v?.value || '')}
            options={entitiesData?.entities?.map((o) => ({ label: o.entityType, value: o.entityType })) || []}
            disabled={saving}
            isLoading={isLoadingEntityTypes}
            isClearable
          />
        </Field>
      )}
    />
  );

  const renderSeverityControl = () => (
    <Controller
      name="severityField"
      control={control}
      rules={{ required: intl.formatMessage(errorMessages.required) }}
      render={({ field, fieldState }) => (
        <Field
          invalid={!!fieldState.error}
          error={fieldState.error?.message}
          label={intl.formatMessage(messages.severity)}
          className="w-full"
        >
          <Select
            {...field}
            onChange={(v) => field.onChange(v?.value || '')}
            options={severityOptions.map((o) => ({ label: o, value: o })) || []}
            disabled={!severityOptions.length || saving || Boolean(preloadedValue)}
            isClearable
          />
        </Field>
      )}
    />
  );

  if (tableRow) {
    return (
      <tr>
        <td className="p-4">{renderNameControl()}</td>
        <td className="p-4">{renderQueryControl()}</td>
        <td className="p-4">{renderForControl()}</td>
        <td className="p-4">{renderCategoryControl()}</td>
        <td className="p-4">{renderSeverityControl()}</td>
        <td className="p-4">{renderEnityTypeControl()}</td>
        <td className="p-4">
          <div className="flex items-center">
            <IconButton variant="primary" onClick={() => onSubmit()} disabled={saving}>
              <CheckIcon />
            </IconButton>
            <IconButton variant="primary" onClick={onClose} disabled={saving}>
              <CloseIcon />
            </IconButton>
            <IconButton
              variant="destructive"
              onClick={() => preloadedValue && onDelete && onDelete(preloadedValue)}
              disabled={saving}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </div>
        </td>
      </tr>
    );
  }

  return (
    <PanelContainer className="mt-8 p-6">
      <p className="mb-6 text-xl">{intl.formatMessage(messages.addTitle)}</p>
      <form onSubmit={onSubmit}>
        <div className="grid gap-2" style={{ gridTemplateColumns: 'repeat(1, 1fr) repeat(1, 2fr) repeat(4, 1fr)' }}>
          {renderNameControl()}
          {renderQueryControl()}
          {renderForControl()}
          {renderCategoryControl()}
          {renderSeverityControl()}
          {renderEnityTypeControl()}
        </div>
        <Button color="primary" type="submit" disabled={saving}>
          {intl.formatMessage(messages.addNew)}
        </Button>
      </form>
    </PanelContainer>
  );
};

export default memo(CustomRulesForm);
