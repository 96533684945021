/*
 * ConnectedEntitiesMenu Messages
 *
 * This contains all the text for the ConnectedEntitiesMenu component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.ConnectedEntitiesMenu';

export default defineMessages({
  connected: {
    id: `${scope}.connected`,
    defaultMessage: 'Connected',
  },
  noItems: {
    id: `${scope}.noItems`,
    defaultMessage: 'No connected items',
  },
});
