import { useEffect } from 'react';
import { useEntitiesQueryParams } from './useEntitiesQueryParams';
import { useAppSelector } from 'app/store';
import useBackButtonPressed from 'hooks/useBackButtonPressed';
import { useDispatch } from 'react-redux';
import { setQueryParamsWereRead } from 'features/App/App.slice';

export default function useEntitiesHistoryChanges() {
  const dispatch = useDispatch();
  const start = useAppSelector((state) => state.app.start);
  const end = useAppSelector((state) => state.app.end);

  const activeEntityDetails = useAppSelector((state) => state.app.activeEntityDetails);

  const bubbleViewPanels = useAppSelector((state) => state.entities.bubbleViewPanels);
  const activeView = useAppSelector((state) => state.entities.activeView);
  const entityListColumns = useAppSelector((state) => state.entities.entityListColumns);
  const selectedKpi = useAppSelector((state) => state.entities.selectedKpi);

  const env = useAppSelector((state) => state.app.selectedEnv.slice().sort().join(','));
  const site = useAppSelector((state) => state.app.selectedSite.slice().sort().join(','));

  const searchDefinition = useAppSelector((state) => state.entities.searchDefinition);
  const searchObject = useAppSelector((state) => state.entities.searchObject);

  const queryParamsWereRead = useAppSelector((state) => state.app.queryParamsWereRead);

  const { readQueryParams, setQueryStringUrl } = useEntitiesQueryParams();

  useEffect(() => {
    // initial read of query params
    const url = new URL(window.location.href);
    if (!queryParamsWereRead && url.searchParams.size > 0) {
      readQueryParams();
    }
    // if no query params in url, set them and switch mode to using store as a source of truth
    // this is needed for the case when user navigates to the page with no query params and we want reflect the app state in the url after navigation
    if (!queryParamsWereRead && url.searchParams.size === 0) {
      dispatch(setQueryParamsWereRead(true));
      setQueryStringUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // reading query params after browser back button pressed
  useBackButtonPressed(readQueryParams);

  useEffect(() => {
    // updating url query params after state is changed
    if (queryParamsWereRead) {
      setQueryStringUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    start,
    end,
    searchDefinition,
    searchObject,
    bubbleViewPanels,
    activeView,
    entityListColumns,
    selectedKpi,
    env,
    site,
    activeEntityDetails,
  ]);
}
