import { defineMessages } from 'react-intl';

export const scope = 'features.GraphinGraph';

export default defineMessages({
  buildingLayout: {
    id: `${scope}.buildingLayout`,
    defaultMessage: 'Building layout...',
  },
});
