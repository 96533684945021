import { useNamespaceByWorkload } from './useNamespaceByWorkload';
import { useKubeClusterByNamespace } from './useKubeClusterByNamespace';
import { OpenInKubernetesTriggerProps } from '../OpenInKubernetesTrigger';

export function useKubeClusterByWorkload(
  entity: OpenInKubernetesTriggerProps['entity'] | undefined | null,
  start: string | number,
  end: string | number,
  enabled: boolean
) {
  const { data: namespace, isFetching: isFetchingNamespaceData } = useNamespaceByWorkload(entity, start, end, enabled);

  const { data: cluster, isFetching: isFetchingClusterData } = useKubeClusterByNamespace(
    namespace,
    start,
    end,
    enabled
  );

  return { data: cluster, isFetching: isFetchingNamespaceData || isFetchingClusterData };
}
