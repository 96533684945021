import React from 'react';
import HomeLanding from './components/HomeLanding';
import { PluginPage } from '@grafana/runtime';

export default function Home() {
  return (
    <PluginPage subTitle="">
      <HomeLanding />
    </PluginPage>
  );
}
