import { cx } from '@emotion/css';
import { Global } from '@emotion/react';
import SliderComponent from 'rc-slider';
import React, { useCallback, FocusEvent } from 'react';

import { getStyles } from './styles';
import { SliderProps } from './types';
import { useStyles2 } from '@grafana/ui';

/**
 * @public
 */
export const Slider = ({
  min,
  max,
  onChange,
  onAfterChange,
  orientation = 'horizontal',
  reverse,
  step,
  value,
  ariaLabelForHandle,
  marks,
  included,
}: SliderProps) => {
  const isHorizontal = orientation === 'horizontal';
  const styles = useStyles2(getStyles, isHorizontal, Boolean(marks));
  const SliderWithTooltip = SliderComponent;

  const onSliderChange = useCallback(
    (v: number | number[]) => {
      const value = typeof v === 'number' ? v : v[0];

      onChange?.(value);
    },
    [onChange]
  );

  const handleAfterChange = useCallback(
    (v: number | number[]) => {
      const value = typeof v === 'number' ? v : v[0];
      onAfterChange?.(value);
    },
    [onAfterChange]
  );

  const sliderInputClassNames = !isHorizontal ? [styles.sliderInputVertical] : [];

  return (
    <div className={cx(styles.container, styles.slider)}>
      {/** Slider tooltip's parent component is body and therefore we need Global component to do css overrides for it. */}
      <Global styles={styles.tooltip} />
      <div className={cx(styles.sliderInput, ...sliderInputClassNames)}>
        <SliderWithTooltip
          min={min}
          max={max}
          step={step}
          defaultValue={value}
          value={value}
          onChange={onSliderChange}
          onAfterChange={handleAfterChange}
          vertical={!isHorizontal}
          reverse={reverse}
          ariaLabelForHandle={ariaLabelForHandle}
          marks={marks}
          included={included}
        />
      </div>
    </div>
  );
};

Slider.displayName = 'Slider';
