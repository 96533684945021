import React, { FunctionComponent, MouseEvent, useMemo, useState } from 'react';
import TagLabel from 'components/TagLabel/TagLabel';
import { ActiveEntityDetailsPayload, Entity } from 'asserts-types';
import AssertsCircle from '../AssertsCircle/AssertsCircle.component';
import ListAltIcon from 'assets/material-icons/list_alt_FILL0_wght400_GRAD0_opsz24.svg';
import DashboardOutlinedIcon from 'assets/material-icons/dashboard_FILL0_wght400_GRAD0_opsz24.svg';
import PropertiesPopover from 'components/PropertiesPopover/PropertiesPopover.component';
import { useIntl } from 'react-intl';
import messages from './messages';
import EnvSiteTagComponent from 'components/EnvSiteTag/EnvSiteTag.component';

import { ENTITY_OUT_OF_DATE_TIME } from '../../constants';
import { twMerge } from 'tailwind-merge';
import ConnectionTypeComponent from 'components/ConnectionType/ConnectionType.component';
import EntityContextMenuComponent from 'components/EntityContextMenu/EntityContextMenu.component';
import AddToWorkbenchButtonComponent from 'features/Assertions/components/AddToWorkbenchButton/AddToWorkbenchButton.component';
import { Tooltip } from 'components/Tooltip/Tooltip.component';
import { Popover, PopoverContent, PopoverTrigger } from 'components/Popover/Popover.component';
import { IconButton } from 'components/IconButton/IconButton.component';

interface IProps {
  item: Entity;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  onMouseEnter?: () => void;
  onMouseOut?: () => void;
  active?: boolean;
  setActiveEntityDetails?: (value?: ActiveEntityDetailsPayload) => void;
  hideArrow?: boolean;
  className?: string;
  lastUpdateTime: number | undefined;
}


const EntityListItem: FunctionComponent<IProps> = ({
  item,
  onClick,
  active,
  onMouseEnter,
  onMouseOut,
  setActiveEntityDetails,
  hideArrow,
  lastUpdateTime,
  className,
}) => {
  const intl = useIntl();
  const [isContextMenuShown, setIsContextMenuShown] = useState(false);
  const [activeTooltipProperties, setActiveTooltipProperties] = useState(false);
  const [activeTooltipWb, setActiveTooltipWb] = useState(false);
  const [copyActive, setCopyActive] = useState(false);

  const outOfDate = useMemo(() => {
    if (item.properties['Updated'] && lastUpdateTime) {
      return (
        lastUpdateTime - +item.properties['Updated'] > ENTITY_OUT_OF_DATE_TIME
      );
    }
    return false;
  }, [item.properties, lastUpdateTime]);

  const copyText = (e: MouseEvent<HTMLElement>, text: string) => {
    e.stopPropagation();
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text);
      setCopyActive(true);
      setTimeout(() => setCopyActive(false), 1000);
    }
  };

  return (
    <Popover placement="right-start" open={isContextMenuShown} onOpenChange={setIsContextMenuShown}>
      <PopoverContent>
        <EntityContextMenuComponent
          entity={item}
          closeMenu={() => {
            setIsContextMenuShown(false);
          }}
        />
      </PopoverContent>
      <PopoverTrigger asChild>
        <div
          className={twMerge(
            'group relative flex items-center px-4 min-h-[70px] divider-b bg-paper hover:pr-4 hover:cursor-pointer hover:bg-hover',
            className || '',
            outOfDate
              ? "before:content-['_'] before:absolute before:left-0 before:h-full before:w-[3px] before:bg-black/10"
              : '',
            activeTooltipWb || activeTooltipProperties || isContextMenuShown ? 'pr-4 cursor-pointer bg-hover' : ''
          )}
          onClick={(e) => (onClick ? onClick(e) : setIsContextMenuShown(true))}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseOut}
          data-cy="entity-list-item"
          data-intercom-target="entity-list-item"
        >
          <div className="basis-8 mr-7">
            <AssertsCircle size={32} entity={item} />
          </div>
          <div className="grow min-w-0 mr-2.5">
            <Tooltip content={item.name} placement="top">
              <>
                <p className="text-sm whitespace-nowrap text-ellipsis overflow-hidden">
                  <Tooltip show={copyActive} content="Copied" placement="top">
                    <span onClick={(e) => copyText(e, item.name)} className="hover:underline">
                      {item.nameWithNamespace || item.name}
                    </span>
                  </Tooltip>
                </p>
                {item.scope?.env && <EnvSiteTagComponent scope={item.scope} />}
              </>
            </Tooltip>
            {item.edgeWithActiveElement && !active && <ConnectionTypeComponent entity={item} />}
          </div>

          <div
            className={`group-hover:hidden ${
              activeTooltipWb || activeTooltipProperties || isContextMenuShown ? '!hidden' : ''
            }`}
          >
            <TagLabel entityType={item.type} properties={item.properties} scope={item.scope} />
          </div>
          <div
            className={twMerge(
              'hover-buttons hidden whitespace-nowrap items-center group-hover:flex',
              activeTooltipWb || activeTooltipProperties || isContextMenuShown ? '!flex' : ''
            )}
          >
            <AddToWorkbenchButtonComponent
              entityKeys={[{ name: item.name, type: item.type, scope: item.scope }]}
              buttonProps={{
                color: 'primary',
                className: activeTooltipWb ? 'bg-primary/5' : '',
              }}
              onOpen={() => {
                setActiveTooltipWb(true);
              }}
              onClose={() => {
                setActiveTooltipWb(false);
              }}
            />
            <Tooltip
              interactive
              onOpen={() => {
                setActiveTooltipProperties(true);
              }}
              onClose={() => {
                setActiveTooltipProperties(false);
              }}
              enterDelay={200}
              placement="top-end"
              content={
                <PropertiesPopover
                  type={item.type}
                  label={item.name}
                  properties={item.properties}
                  scope={item.scope}
                  entityAssertion={item.assertion}
                  connectedAssertion={item.connectedAssertion}
                  lastUpdateTime={lastUpdateTime}
                  disableAssertionsList
                />
              }
            >
              <IconButton
                variant="primary"
                active={activeTooltipProperties}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <ListAltIcon />
              </IconButton>
            </Tooltip>
            <Tooltip content={intl.formatMessage(messages.kpi)}>
              <IconButton
                variant="primary"
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  setActiveEntityDetails &&
                    setActiveEntityDetails({
                      name: item.name,
                      type: item.type,
                      scope: item.scope,
                    });
                }}
              >
                <DashboardOutlinedIcon />
              </IconButton>
            </Tooltip>
            {!hideArrow && <i className={`icon-rounded-right text-secondary text-2xl mr-[-8px] opacity-80`} />}
          </div>
        </div>
      </PopoverTrigger>
    </Popover>
  );
};

export default EntityListItem;
