/*
 * CustomRulesForm Messages
 *
 * This contains all the text for the CustomRulesForm component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.CustomRulesForm';

export default defineMessages({
  entityType: {
    id: `${scope}.entityType`,
    defaultMessage: 'Entity Type',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Name',
  },
  addNew: {
    id: `${scope}.addNew`,
    defaultMessage: 'Add new',
  },
  query: {
    id: `${scope}.query`,
    defaultMessage: 'Query',
  },
  severity: {
    id: `${scope}.severity`,
    defaultMessage: 'Severity',
  },
  for: {
    id: `${scope}.for`,
    defaultMessage: 'For',
  },
  category: {
    id: `${scope}.category`,
    defaultMessage: 'Category',
  },
  uniqName: {
    id: `${scope}.uniqName`,
    defaultMessage: 'Rule with this name already exists',
  },
  addTitle: {
    id: `${scope}.addTitle`,
    defaultMessage: 'Add new rule',
  },
});
