import React, { FunctionComponent, memo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';

import EntityButtonsGroup from '../EntityButtonsGroup/EntityButtonsGroup.component';
import { setActiveView, setShowAdvancedSearch } from '../../Entities.slice';

import { connect, ConnectedProps } from 'react-redux';
import { GraphCustomData } from 'asserts-types';
import { ButtonGroup, Tab, TabsBar, ToolbarButton } from '@grafana/ui';
import EntitiesSearchQuerySelect from '../EntitiesSearchQuerySelect/EntitiesSearchQuerySelect';
import { useAppSelector } from 'app/store';
import TrackingHelper from 'helpers/Tracking.helper';

const connector = connect(
  (state: RootState) => ({
    activeView: state.entities.activeView,
    showAdvancedSearch: state.entities.showAdvancedSearch,
  }),
  {
    setActiveView,
    setShowAdvancedSearch,
  }
);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface IProps {
  graphData: GraphCustomData;
}

const EntitiesTopMenu: FunctionComponent<IProps & PropsFromRedux> = ({
  activeView,
  setActiveView,
  graphData,
  setShowAdvancedSearch,
  showAdvancedSearch,
}) => {
  const intl = useIntl();

  const advancedSearchObject = useAppSelector((state) => state.entities.searchObject);

  useEffect(() => {
    TrackingHelper.trackViewType('entities_' + activeView);
  }, [activeView]);

  return (
    <div>
      <div className="flex items-center justify-between pb-3 gap-4">
        <ButtonGroup className="flex grow">
          <EntitiesSearchQuerySelect />
          <ToolbarButton
            variant={showAdvancedSearch ? 'active' : 'canvas'}
            icon="sliders-v-alt"
            tooltip="Advanced search"
            isHighlighted={Boolean(advancedSearchObject.filterCriteria.length)}
            onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
          />
        </ButtonGroup>
        <EntityButtonsGroup graphData={graphData} />
      </div>
      <div className="flex items-center justify-between relative">
        <TabsBar className="w-full">
          <Tab
            label={intl.formatMessage(messages.graph)}
            active={activeView === 'graph'}
            onChangeTab={() => setActiveView('graph')}
            value="graph"
          />
          <Tab
            label={intl.formatMessage(messages.list)}
            active={activeView === 'list'}
            onChangeTab={() => setActiveView('list')}
            value="list"
          />
          <Tab
            label="Bubble"
            active={activeView === 'bubble'}
            onChangeTab={() => setActiveView('bubble')}
            value="bubble"
          />
        </TabsBar>
      </div>
    </div>
  );
};

export default connector(memo(EntitiesTopMenu));
