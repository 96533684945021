import React, { FunctionComponent, memo, useMemo } from 'react';
import { Entity } from 'asserts-types';
import { LoadingPlaceholder } from '@grafana/ui';
import { usePluginComponent } from '@grafana/runtime';
import { CspEntityDashboardProps } from 'extensions/csp/types';
import { EXTERNAL_PLUGIN_VIEWS } from 'app/constants';
import { dateTimeParse, TimeRange } from '@grafana/data';

interface Props {
  entity: Entity;
  start: string | number;
  end: string | number;
  metricsDataSourceUID: string | undefined;
  logsDataSourceUID: string | undefined;
  onTimeRangeChange?: (timeRange: TimeRange) => void;
}

const CspEntityDashboardView: FunctionComponent<Props> = ({
  entity,
  start,
  end,
  metricsDataSourceUID,
  logsDataSourceUID,
  onTimeRangeChange
}) => {
  const {
    component: CspEntityDashboardComponent,
    isLoading
  } = usePluginComponent<CspEntityDashboardProps>(EXTERNAL_PLUGIN_VIEWS.CSP_SERVICE_DASHBOARD_EXTENSION_V1);

  const timeRange: TimeRange = useMemo(() => {
    const from = dateTimeParse(start);
    const to = dateTimeParse(end);
    return {
      from,
      to,
      raw: {
        from: typeof start === 'string' ? start : from,
        to: typeof end === 'string' ? end : to,
      },
    };
  }, [start, end]);
  const metadata = useMemo(() => ({ instanceId: entity?.name }), [entity?.name]);

  if (!!entity) {
    if (isLoading) {
      <div className="w-full h-full flex items-center justify-center">
        <LoadingPlaceholder text="Loading..." />
      </div>
    } else if (!!CspEntityDashboardComponent) {
      return <CspEntityDashboardComponent
        serviceId={entity?.scope?.namespace ?? ''}
        provider={entity?.properties?.provider?.toString()}
        metadata={metadata}
        initialTimeRange={timeRange}
        promDatasource={metricsDataSourceUID}
        lokiDatasource={logsDataSourceUID}
        onTimeRangeChange={onTimeRangeChange}
      />
    }
  }
  return null;
}

export default memo(CspEntityDashboardView);
