import React from 'react';
import { Outlet } from 'react-router-dom';

import { PluginPage } from '@grafana/runtime';
import RulesMenu from './components/RulesMenu/RulesMenu';

export default function ManageAssertionsContainer() {
  return (
    <PluginPage renderTitle={() => <></>}>
      <div className="flex items-start gap-8">
        <RulesMenu />
        <div className="grow w-0">
          <Outlet />
        </div>
      </div>
    </PluginPage>
  );
}
