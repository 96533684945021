import { FieldColorModeId, FieldConfigProperty, FieldMatcherID, FieldType } from '@grafana/data';
import {
  EmbeddedScene,
  QueryRunnerState,
  SceneFlexItem,
  SceneFlexLayout,
  SceneQueryRunner,
  SceneTimeRange,
  VizPanel,
} from '@grafana/scenes';
import { LegendDisplayMode, TooltipDisplayMode } from '@grafana/ui';
import { assertsColors } from 'app/constants';
import { setTimeRange } from 'features/App/App.slice';
import useAppTimeRange from 'hooks/useAppTimeRange';
import useDebounceValue from 'hooks/useDebounceValue';
import useDidUpdateEffect from 'hooks/useDidUpdate';
import { useMetricsDataSource } from 'hooks/useMetricsDatasource';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

interface Props {
  continuesFor: string | undefined | null;
  enabled: boolean;
  labels: Record<string, string>;
}

export default function useNotificationHealthScene({ continuesFor, enabled, labels }: Props) {
  const { data: datasource } = useMetricsDataSource();
  const dispatch = useDispatch();

  const labelsQuery = Object.entries(labels)
    .map(([key, value]) => (['alertname', 'asserts_env'].includes(key) ? `${key}=~"${value}"` : `${key}="${value}"`))
    .join(', ');

  const metricQuery = `asserts:alerts{asserts_alert_category=~"failure|amend", ${labelsQuery}}`;

  const { timeRange } = useAppTimeRange();

  const debouncedContinuesfor = useDebounceValue(continuesFor, 300);

  let queries: QueryRunnerState['queries'] = [
    ...(labelsQuery
      ? [
          {
            refId: 'notifications_query',
            expr: `group by()(${metricQuery})`,
          },
        ]
      : []),
  ];

  if (continuesFor) {
    queries = [
      ...(labelsQuery
        ? [
            {
              refId: 'notifications_query',
              expr: `group by()(
                min by(asserts_env, asserts_site, namespace, alertgroup, alertname)(${metricQuery}) 
                ==
                min by(asserts_env, asserts_site, namespace, alertgroup, alertname)(${metricQuery} offset ${continuesFor}m)
            )`,
            },
          ]
        : []),
    ];
  }

  if (!enabled) {
    queries = [];
  }

  const { scene, $data, $timeRange } = useMemo(() => {
    const $timeRange = new SceneTimeRange({ value: timeRange });

    const $data = new SceneQueryRunner({
      datasource: {
        type: 'prometheus',
        uid: datasource.uid,
      },
      $timeRange,
      queries,
    });

    const panel = new VizPanel({
      pluginId: 'timeseries',
      title: 'Notification preview',
      options: {
        legend: { displayMode: LegendDisplayMode.Table },
        tooltip: { mode: TooltipDisplayMode.Multi },
        axes: {},
      },
      fieldConfig: {
        defaults: {},
        overrides: [
          {
            matcher: {
              id: FieldMatcherID.byFrameRefID,
              options: 'notifications_query',
            },
            properties: [
              {
                id: 'custom.lineWidth',
                value: 25,
              },
              {
                id: FieldConfigProperty.Color,
                value: {
                  fixedColor: assertsColors.critical,
                  mode: FieldColorModeId.Shades,
                },
              },
              {
                id: FieldConfigProperty.DisplayName,
                value: 'Notification',
              },
            ],
          },
          {
            matcher: {
              id: FieldMatcherID.byType,
              options: FieldType.number,
            },
            properties: [
              {
                id: 'custom.axisPlacement',
                value: 'hidden',
              },
            ],
          },
        ],
      },
    });

    panel.onTimeRangeChange = (tr) => {
      dispatch(setTimeRange({ start: tr.from, end: tr.to }));
    };

    const scene = new EmbeddedScene({
      $data,
      body: new SceneFlexLayout({
        children: [
          new SceneFlexItem({
            body: panel,
          }),
        ],
      }),
    });

    return { scene, $data, $timeRange };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDidUpdateEffect(() => {
    if (labelsQuery) {
      $data.setState({
        queries,
      });

      $data.runQueries();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricQuery, debouncedContinuesfor]);

  useDidUpdateEffect(() => {
    $timeRange.setState({ value: timeRange });
  }, [
    typeof timeRange.raw.from === 'string' ? timeRange.raw.from : timeRange.raw.from.valueOf(),
    typeof timeRange.raw.to === 'string' ? timeRange.raw.to : timeRange.raw.to.valueOf(),
  ]);

  return { scene };
}
