export const hex2rgba = (hex: string, alpha = 1): string => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
  const hexMatch = hex.match(/\w\w/g);

  if (!hexMatch) {
    return hex;
  }
  const [r, g, b] = hexMatch.map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

export const strToHsl = (str: string) => {
  const saturation = 80;
  const lightness = 35;
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }

  const hue = hash % 360;
  return 'hsla(' + hue + ', ' + saturation + '%, ' + lightness + '%, 0.7 )';
};
