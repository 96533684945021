/**
 *
 * EntityAdvancedSearchBottomPanel
 *
 */

import React, { memo, FunctionComponent, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import useAdvancedSearchModified from '../../hooks/useAdvancedSearchModified';
import useDeleteSavedSearch from '../../hooks/useDeleteSavedSearch';
import { useDispatch } from 'react-redux';
import { Definition, EntitySearchObject, EntityServerError } from 'asserts-types';
import { useQueryClient } from '@tanstack/react-query';
import { useAppSelector } from 'app/store';
import SnackbarHelper from 'helpers/Snackbar.helper';
import { saveEntitySearch, updateEntitySearch } from 'services/Entity.service';
import { clearEntities, setSearch } from 'features/Entities/Entities.slice';
import { USE_DEFINITION_AUTOCOMPLETE_KEY } from 'hooks/useDefinitionAutocomplete';
import { AxiosResponse } from 'axios';
import TooltipConfirmationComponent from 'features/Assertions/components/TooltipConfirmation/TooltipConfirmation.component';
import { Button } from '@grafana/ui';
import { Tooltip } from 'components/Tooltip/Tooltip.component';

interface IProps {
  searchObject: EntitySearchObject;
  searchDefinition?: Omit<Definition, 'id'> | null;
}

const EntityAdvancedSearchBottomPanel: FunctionComponent<IProps> = ({
  searchObject,
  searchDefinition,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSearchSaving, setIsSearchSaving] = useState(false);

  const searchName = useAppSelector((state) => state.entities.searchName);

  const { isModified } = useAdvancedSearchModified();


  const handleSaveSearchClick = useCallback(() => {
    if (!searchName) {
      SnackbarHelper.error(messages.noName);
      return;
    }
    setIsSearchSaving(true);
    if (
      searchDefinition?.definitionId &&
      searchDefinition.filterCriteria?.length
    ) {
      updateEntitySearch(
        searchDefinition.definitionId,
        searchName,
        searchObject,
      )
        .then(
          () => {
            dispatch(clearEntities());
            queryClient.removeQueries([USE_DEFINITION_AUTOCOMPLETE_KEY]);
            dispatch(setSearch(searchName));
            SnackbarHelper.success(messages.updated);
          },
          (err: AxiosResponse<EntityServerError>) => {
            SnackbarHelper.error(
              err.data.subErrors?.[0].message || 'Unexpected error',
            );
          },
        )
        .finally(() => setIsSearchSaving(false));
    } else {
      saveEntitySearch(searchName, searchObject)
        .then(
          (res) => {
            dispatch(clearEntities());
            dispatch(setSearch(searchName));
            SnackbarHelper.success(messages.created);
          },
          (err: AxiosResponse<EntityServerError>) => {
            SnackbarHelper.error(
              err.data.subErrors?.[0].message || 'Unexpected error',
            );
          },
        )
        .finally(() => setIsSearchSaving(false));
    }
  }, [
    searchName,
    searchDefinition?.definitionId,
    searchDefinition?.filterCriteria?.length,
    searchObject,
    dispatch,
    queryClient,
  ]);

  const {
    mutate: handleDeleteSearch,
    isLoading: isDeletingSearch,
  } = useDeleteSavedSearch();

  let saveButtonMessage = messages.save;

  if (isModified) {
    saveButtonMessage = messages.saveChanges;
  }

  if (isSearchSaving) {
    saveButtonMessage = messages.saving;
  }

  return (
    <>
      <div className="p-8 flex items-center bg-paper divider-t space-x-4">
        <Button
          color="primary"
          data-cy="save-search-btn"
          disabled={
            !searchObject.filterCriteria?.length ||
            isSearchSaving ||
            isDeletingSearch ||
            (!isModified && !!searchDefinition?.filterCriteria?.length)
          }
          onClick={handleSaveSearchClick}
        >
          {intl.formatMessage(saveButtonMessage)}
        </Button>
        {searchDefinition?.definitionId && searchDefinition?.filterCriteria && (
          <TooltipConfirmationComponent action={() => handleDeleteSearch(searchDefinition)}>
            <Button
              disabled={isDeletingSearch}
              variant="destructive"
              fill="text"
              tooltip={`${intl.formatMessage(messages.delete)} ${searchDefinition.boundDescription}`}
              tooltipPlacement="top"
            >
              <span>{intl.formatMessage(isDeletingSearch ? messages.deleting : messages.delete)}</span>
            </Button>
          </TooltipConfirmationComponent>
        )}
      </div>
    </>
  );
};

export default memo(EntityAdvancedSearchBottomPanel);
