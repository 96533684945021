/**
 *
 * AssertionsTimelineInteractions
 *
 */

import React, {
  memo,
  FunctionComponent,
  useMemo,
  RefObject,
  ReactElement,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import useRangeSelection from '../AssertionsSummaryView/hooks/useRangeSelection';
import useLinesPosition from '../AssertionsSummaryView/hooks/useLinesPosition';
import { getTimeFromCoordinates } from '../../../../helpers/Time.helper';
import useSelectedTime from '../AssertionsSummaryView/hooks/useSelectedTime';
import CoordinateLinesComponent from '../CoordinateLines/CoordinateLines.component';
import RangePickerLineComponent from '../../../../components/RangePickerLine/RangePickerLine.component';
import useSummaryLeftPanel from '../../hooks/useSummaryLeftPanel';
import { ChartTooltipType } from 'asserts-types';
import { AXIS_PADDING_RIGHT } from '../../../../app/constants';
import { useDispatch } from 'react-redux';
import { setScrollPosition } from '../../Assertions.slice';
import { useAppSelector } from 'app/store';
import { debounce } from 'lodash';

interface IProps {
  timelineRef: RefObject<HTMLDivElement>;
  start: number;
  end: number;
  changeDateRange: (newStart: number | string, newEnd: number | string) => void;
  children: ReactElement<any, any>;
  show: boolean;
}

const AssertionsTimelineInteractions: FunctionComponent<IProps> = ({
  timelineRef,
  start,
  end,
  children,
  show,
  changeDateRange,
}) => {
  const { width: leftPanelWidth } = useSummaryLeftPanel();

  const scrollPosition = useAppSelector(
    (state) => state.assertions.scrollPosition,
  );

  const dispatch = useDispatch();

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = scrollPosition;
    }
    //eslint-disable-next-line
  }, []);

  const handleScrollEnd = useMemo(
    () =>
      debounce(() => {
        dispatch(setScrollPosition(containerRef.current?.scrollTop || 0));
      }, 100),
    [dispatch],
  );

  const handleScroll = useCallback(() => {
    handleScrollEnd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const showLine = useAppSelector(
    (state) => state.assertions.chartTooltip !== ChartTooltipType.DEFAULT,
  );

  const { selectingRange, selectedStartX, selectedEndX } = useRangeSelection({
    start,
    end,
    containerRef,
    leftOffset: leftPanelWidth,
    rightOffset: AXIS_PADDING_RIGHT,
    onChange: changeDateRange,
  });

  const { mouseX, hovered } = useLinesPosition({
    containerRef,
    offsetLeft: leftPanelWidth,
  });

  const currentDate = useMemo(
    () =>
      getTimeFromCoordinates({
        mouseX,
        tapeEl: timelineRef.current,
        start,
        end,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [end, mouseX, start],
  );

  const {
    selectCurrentTime,
    selectedTime,
    selectedMouseX,
    resetSelectedTime,
  } = useSelectedTime({
    currentDate,
    mouseX,
  });


  return (
    <div
      onClick={() => (hovered ? selectCurrentTime() : null)}
      className={`h-full w-full overflow-y-scroll ${show ? 'block' : 'hidden'}`}
      ref={containerRef}
      style={{ userSelect: selectingRange ? 'none' : 'auto' }}
      onScroll={handleScroll}
    >
      {children}
      {!selectingRange && show && (
        <CoordinateLinesComponent
          top={timelineRef.current?.getBoundingClientRect().top || 0}
          left={mouseX}
          show={hovered && showLine}
          selectedMouseX={selectedMouseX}
          resetSelectedTime={resetSelectedTime}
          currentDate={selectedTime || currentDate}
        />
      )}
      {selectingRange && containerRef.current && (
        <RangePickerLineComponent
          start={start}
          end={end}
          mouseX={selectedEndX}
          selectedStartX={selectedStartX}
          tapeEl={containerRef.current}
          leftOffset={leftPanelWidth}
          rightOffset={AXIS_PADDING_RIGHT}
        />
      )}
    </div>
  );
};

export default memo(AssertionsTimelineInteractions);
