import React from 'react';
import useStatefulRef from './useStatefulRef';

export default function useForwardedRef<T>(
  forwardedRef: React.Ref<T>,
): React.MutableRefObject<T> {
  const innerRef = useStatefulRef<T>(null);
  React.useEffect(() => {
    if (!forwardedRef) return;

    if (typeof forwardedRef === 'function') {
      forwardedRef(innerRef.current);
    } else {
      (forwardedRef as React.MutableRefObject<T | null>).current =
        innerRef.current;
    }
  });

  return innerRef;
}
