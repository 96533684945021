/*
 *
 * AssertionSearch constants
 *
 */

export const SLICE_NAME = 'assertionSearch';

export const ASSERTION_NAME = 'asserts_assertion_name';
export const ASSERTION_SEVERITY = 'asserts_severity';
