import { LayoutConfig } from '@antv/g6';
import pluginJson from './plugin.json';
import { GraphCustomNode } from 'asserts-types';

//@ts-ignore
import workerScript from '@antv/layout/dist/layout.min.js';

export const PLUGIN_BASE_URL = `/a/${pluginJson.id}`;

export enum ROUTES {
  HOME = 'home',
  ENTITIES_GRAPH = 'entities',
  TOP_INSIGHTS = 'top-insights',
  RCA_WORKBENCH = 'assertions',
  INCIDENTS = 'incidents',
  SLO = 'slo',
  SLO_ADD = 'slo/add',
  SLO_EDIT = 'slo/:id/edit',

  RULES = 'rules',

  REQUEST = 'request',
  RESOURCE = 'resource',
  HEALTH = 'health',

  RULES_THRESHOLD = 'threshold',

  RULES_NOTIFICATIONS = 'notifications',

  RULES_SUPPRESS = 'suppress',

  RULES_ADD = 'add',
  RULES_ADD_FILE = 'file',
  RULES_ADD_RULE = 'rule',

  RULES_OTEL = 'otel',

  RULES_DISABLE = 'disable',
  RULES_ALERT_MANAGER = 'alertmanager',

  RULES_RELABEL = 'relabel-rules',
  RULES_RELABEL_TYPE = ':type',
  RULES_RELABEL_PROLOGUE = 'prologue',
  RULES_RELABEL_EPILOGUE = 'epilogue',
  RULES_RELABEL_GENERATED = 'generated',

  RULES_CUSTOM_MODEL = 'custom-model-rules',
  CONFIGURATION = 'configuration',

  CONFIGURATION_LINK_DASHBOARDS = 'link-dashboard',
  CONNECT_DATA = 'connect-env',

  CONFIGURATION_LOGS = 'logs',
  CONFIGURATION_LOGS_ADD = 'logs/add',
  CONFIGURATION_LOGS_EDIT = 'logs/:name/edit',

  CONFIGURATION_TRACES = 'traces',
  CONFIGURATION_TRACES_ADD = 'traces/add',
  CONFIGURATION_TRACES_EDIT = 'traces/:name/edit',

  CONFIGURATION_DISPLAY = 'display',
}

export enum TRACKING_EVENTS {
  SLO_CREATED = 'slo_created',
  NAVIGATE_TO_WORKBENCH = 'navigate_to_workbench',
  SEARCH_EXPRESSION_USED = 'search_expression_used',
  KPI_DASHBOARD_OPENED = 'kpi_dashboard_opened',
  ENTITIES_RETURNED_COUNT = 'entities_returned_count',
  ENTITY_ADDED_TO_WORKBENCH = 'entity_added_to_workbench',
  VIEW_VISITED = 'view_visited',
  PAGE_VISITED = 'page_visited',
  DASHBOARD_LINKED = 'dashboard_linked',
  DISPLAY_CONFIG_UPDATED = 'display_config_updated',
  ONBOARDING_OVER_SERIES_LIMIT = 'onboarding_over_series_limit',
  ONBOARDING_FAILED_K8S_CHECK = 'onboarding_failed_k8s_check',
  ONBOARDING_FAILED_ISTIO_CHECK = 'onboarding_failed_istio_check',
  ONBOARDING_FAILED_OTEL_TRACES_CHECK = 'onboarding_failed_otel_traces_check',
  ONBOARDING_SUCCESS = 'onboarding_success',
  EXTERNAL_WIDGET_OPENED = 'external_widget_opened',
  NAVIGATE_TO_ENTITY_GRAPH = 'navigate_to_entity_graph',
  KPI_DISPLAY_CONFIG_UPDATED = 'kpi_display_config_updated',
}

export enum TRACKING_FEATURES {
  SLO = 'slo',
  ENTITIES_EXPLORER = 'entities_explorer',
  RCA_WORKBENCH = 'rca_workbench',
  TROUBLESHOOT_IN_WORKBENCH_BTN = 'troubleshoot_in_workbench_btn',
  ENTITY_DETAILS_DRAWER = 'entity_detais_drawer',
  INCIDENTS = 'incidents',
  TOP_INSIGHTS = 'top_insights',
  ADD_TO_WORKBENCH_BTN = 'add_to_workbench_btn',
  ALERTMANAGER = 'alertmanager',
  WB_VIEW_TIMELINE = 'workbench_view_timeline',
  WB_VIEW_SUMMARY = 'workbench_view_summary',
  WB_VIEW_GRAPH = 'workbench_view_graph',
  WB_VIEW_MIND_MAP = 'workbench_view_mind_map',
  ENTITIES_VIEW_BUBBLE = 'entities_view_bubble',
  ENTITIES_VIEW_GRAPH = 'entities_view_graph',
  ENTITIES_VIEW_LIST = 'entities_view_list',
  ENTITY_ASSERTIONS_WIDGET = 'entity_assertions_widget',
}

export const DEFAULT_FORCE_LAYOUT_OPTIONS: LayoutConfig = {
  type: 'force',
  linkDistance: 100, // Edge length
  edgeStrength: 1,
  nodeStrength: -200,
  preventOverlap: true,
  collideStrength: 2,
  nodeSpacing: 40,
  nodeSize: (node: GraphCustomNode) => node.size,
  alpha: 0.3,
  alphaDecay: 0.028,
  alphaMin: 0.01,
  clustering: true,
  clusterNodeStrength: -5,
  clusterNodeSize: 80,
  clusterFociStrength: 1.2,
  workerEnabled: true,
  workerScriptURL: `${window.location.origin}/${workerScript}`,
};

export const INCIDENTS_MINIMUM_WIDTH = 0.3; // percentage

export const METRICS_DATA_SOURCE_UID = 'grafanacloud-prom';
export const TRACES_DATA_SOURCE_UID = 'grafanacloud-traces';
export const LOGS_DATA_SOURCE_UID = 'grafanacloud-logs';
export const ONBOARDING_ACTIVE_SERIES_LIMIT = 5000000;
