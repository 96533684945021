/*
 * SloAddConfig Messages
 *
 * This contains all the text for the SloAddConfig component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.SloAddConfig';

export default defineMessages({
  sloName: {
    id: `${scope}.sloName`,
    defaultMessage: 'SLO Name',
  },
  sloType: {
    id: `${scope}.sloType`,
    defaultMessage: 'SLO Type',
  },
  allEvents: {
    id: `${scope}.allEvents`,
    defaultMessage: 'All Events Query',
  },
  badEvents: {
    id: `${scope}.badEvents`,
    defaultMessage: 'Bad Events Query',
  },
  searchExpr: {
    id: `${scope}.searchExpr`,
    defaultMessage: 'Search Expression',
  },
  measurementQuery: {
    id: `${scope}.measurementQuery`,
    defaultMessage: 'Measurement Query',
  },
  sliName: {
    id: `${scope}.sliName`,
    defaultMessage: 'SLI Name',
  },
  addSlo: {
    id: `${scope}.addSlo`,
    defaultMessage: 'Add new SLO',
  },
  adding: {
    id: `${scope}.adding`,
    defaultMessage: 'Saving...',
  },
  request: {
    id: `${scope}.request`,
    defaultMessage: 'Availability',
  },
  occurrence: {
    id: `${scope}.occurrence`,
    defaultMessage: 'Latency / Occurrence',
  },
  threshold: {
    id: `${scope}.threshold`,
    defaultMessage: 'Threshold',
  },
  existName: {
    id: `${scope}.existName`,
    defaultMessage: 'Name should be unique',
  },
  rateNotAllowed: {
    id: `${scope}.existName`,
    defaultMessage:
      'Events query is expected to be a counter. rate(...) function not allowed.',
  },
  counter: {
    id: `${scope}.counter`,
    defaultMessage: 'Counter',
  },
  gauge: {
    id: `${scope}.gauge`,
    defaultMessage: 'Gauge',
  },
  eventMetricType: {
    id: `${scope}.eventMetricType`,
    defaultMessage: 'Event Metric Type',
  },
  simple: {
    id: `${scope}.simple`,
    defaultMessage: 'Simple',
  },
  advanced: {
    id: `${scope}.advanced`,
    defaultMessage: 'Advanced',
  },
  job: {
    id: `${scope}.job`,
    defaultMessage: 'Job',
  },
  requestType: {
    id: `${scope}.requestType`,
    defaultMessage: 'Request Type',
  },
  requestContext: {
    id: `${scope}.requestContext`,
    defaultMessage: 'Request Context',
  },
  errorType: {
    id: `${scope}.errorType`,
    defaultMessage: 'Error Type',
  },
  includeInbound: {
    id: `${scope}.includeInbound`,
    defaultMessage: 'Include inbound client errors',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  success: {
    id: `${scope}.success`,
    defaultMessage: 'SLO was saved successfully.',
  },
  help: {
    id: `${scope}.help`,
    defaultMessage: 'How to configure SLO',
  },
  add: {
    id: `${scope}.add`,
    defaultMessage: 'Add Objective',
  },
  parameters: {
    id: `${scope}.parameters`,
    defaultMessage: 'Service and APIs',
  },
  parametersHint: {
    id: `${scope}.parametersHint`,
    defaultMessage: 'Choose the target service and optionally one or more APIs',
  },
  objectives: {
    id: `${scope}.objectives`,
    defaultMessage: 'Target Objectives',
  },
  objectivesHint: {
    id: `${scope}.objectivesHint`,
    defaultMessage: 'Choose the period and the required level of service',
  },
  basics: {
    id: `${scope}.basics`,
    defaultMessage: 'Basics',
  },
  basicsHint: {
    id: `${scope}.basicsHint`,
    defaultMessage: 'Choose the SLO type depending on your needs',
  },
  context: {
    id: `${scope}.context`,
    defaultMessage: 'RCA Workbench Context',
  },
  contextHint: {
    id: `${scope}.contextHint`,
    defaultMessage:
      'Entities that you want to see when troubleshooting SLO violations',
  },
  requestHint: {
    id: `${scope}.requestHint`,
    defaultMessage: 'Level of uptime or accessibility',
  },
  occurrenceHint: {
    id: `${scope}.occurrenceHint`,
    defaultMessage: 'Level of responsiveness or speed',
  },
  sli: {
    id: `${scope}.sli`,
    defaultMessage: 'SLI Details',
  },
  sliHint: {
    id: `${scope}.sliHint`,
    defaultMessage: 'Specify the SLI Name and the Measurement query',
  },
  comparatorHint: {
    id: `${scope}.comparatorHint`,
    defaultMessage: 'Measurement does not meet expectations when value {comparator} threshold.',
  },
  comparator: {
    id: `${scope}.comparator`,
    defaultMessage: 'Comparator',
  },
});
