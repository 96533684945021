/**
 *
 * NotificationsChart
 *
 */

import React, { memo, FunctionComponent } from 'react';
import useNotificationHealthScene from './hooks/useNotificationHealthScene';

interface IProps {
  show: boolean;
  labels: Record<string, string>;
  continuesFor: string | undefined | null;
}

const NotificationsHealthChart: FunctionComponent<IProps> = ({ show, labels, continuesFor }) => {

  const { scene } = useNotificationHealthScene({ labels, continuesFor, enabled: show });

  if (!show) {
    return null;
  }

  return (
    <>
      <div className="h-[380px] relative mb-4 flex items-center justify-center">
        <scene.Component model={scene} />
      </div>
    </>
  );
};

export default memo(NotificationsHealthChart);
