import { useEffect, useState, RefObject } from 'react';
import { getTimeFromCoordinates } from 'helpers/Time.helper';

const START_SELECTION_DELTA = 5;

interface IProps {
  start: number;
  end: number;
  containerRef: RefObject<HTMLDivElement>;
  leftOffset?: number;
  rightOffset?: number;
  onChange: (newStart: number | string, newEnd: number | string) => void;
  onSelectStart?: () => void;
  onSelectEnd?: () => void;
}

export default function useRangeSelection({
  start,
  end,
  containerRef,
  leftOffset = 0,
  rightOffset = 0,
  onChange,
  onSelectStart,
  onSelectEnd,
}: IProps) {
  const [selectingRange, setSelectingRange] = useState(false);
  const [selectedEndX, setSelectedEndX] = useState(0);
  const [selectedStartX, setSelectedStartX] = useState<number | null>(null);

  useEffect(() => {
    const cancelContextMenu = (e: MouseEvent) => {
      e.preventDefault();
    };

    const captureClick = (e: MouseEvent) => {
      e.stopPropagation(); // Stop the click from being propagated.
      window.removeEventListener('click', captureClick, true); // cleanup
    };

    const handleMouseDown = (e: MouseEvent) => {
      const rect = containerRef.current?.getBoundingClientRect();
      if (
        rect &&
        e.clientX > rect.left + leftOffset &&
        e.clientX < rect.right + rightOffset
      ) {
        setSelectedStartX(e.clientX);
      }
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (!selectedStartX) {
        return;
      }
      setSelectedEndX(e.clientX);
      if (
        selectedStartX &&
        e.clientX - selectedStartX > START_SELECTION_DELTA
      ) {
        onSelectStart?.();
        setSelectingRange(true);
      }
    };

    const handleMouseUp = (e: MouseEvent) => {
      onSelectEnd?.();
      setSelectedStartX(null);
      setSelectedEndX(0);
      // set timeout here to delay setting selecting range to next render
      // (for having possibility to prevent click using selectingRange variable for example)
      setTimeout(() => {
        setSelectingRange(false);
      });

      if (!selectedStartX || !selectingRange) {
        return;
      }

      window.addEventListener(
        'click',
        captureClick,
        true, // <-- This registeres this listener for the capture
        //     phase instead of the bubbling phase!
      );

      if (
        selectedStartX &&
        selectedEndX - selectedStartX > START_SELECTION_DELTA &&
        containerRef.current
      ) {
        const newStart = getTimeFromCoordinates({
          start: start.valueOf(),
          end: end.valueOf(),
          tapeEl: containerRef.current,
          mouseX: selectedStartX,
          rightOffset,
          leftOffset,
        });

        const newEnd = getTimeFromCoordinates({
          start: start.valueOf(),
          end: end.valueOf(),
          tapeEl: containerRef.current,
          mouseX: selectedEndX,
          rightOffset,
          leftOffset,
        });

        onChange(newStart, newEnd);
      }
    };

    const tapeEl = containerRef.current;

    if (!tapeEl) {
      return;
    }

    tapeEl.addEventListener<'contextmenu'>('contextmenu', cancelContextMenu);
    tapeEl.addEventListener<'mousedown'>('mousedown', handleMouseDown);
    tapeEl.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      tapeEl.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
      tapeEl.removeEventListener('mousemove', handleMouseMove);

      tapeEl.removeEventListener<'contextmenu'>(
        'contextmenu',
        cancelContextMenu,
      );
    };
  }, [
    containerRef,
    start,
    end,
    selectedStartX,
    selectedEndX,
    rightOffset,
    leftOffset,
    onChange,
    selectingRange,
    onSelectStart,
    onSelectEnd,
  ]);

  return { selectingRange, selectedStartX, selectedEndX };
}
