import { defineMessages } from 'react-intl';

export const scope = 'features.AssertionsListItemActions';

export default defineMessages({
  viewList: {
    id: `${scope}.viewList`,
    defaultMessage: 'View list',
  },
  viewRules: {
    id: `${scope}.viewRules`,
    defaultMessage: 'View rules',
  },
  viewLabels: {
    id: `${scope}.viewLabels`,
    defaultMessage: 'View labels',
  },
  viewConnectedKpi: {
    id: `${scope}.viewConnectedKpi`,
    defaultMessage: 'View connected KPIs',
  },
  expand: {
    id: `${scope}.expand`,
    defaultMessage: 'Expand',
  },
  nullAsNull: {
    id: `${scope}.nullAsNull`,
    defaultMessage: 'Nulls as Nulls',
  },
  nullAsZero: {
    id: `${scope}.nullAsZero`,
    defaultMessage: 'Nulls as Zeros',
  },
  threshold: {
    id: `${scope}.threshold`,
    defaultMessage: 'Update threshold',
  },
  createNotification: {
    id: `${scope}.createNotification`,
    defaultMessage: 'Notify',
  },
  updateNotification: {
    id: `${scope}.updateNotification`,
    defaultMessage: 'Update notification',
  },
  suppress: {
    id: `${scope}.suppress`,
    defaultMessage: 'Suppress',
  },
  showChart: {
    id: `${scope}.showChart`,
    defaultMessage: 'Open chart in Metrics',
  },
});
