/*
 * BubbleViewItem Messages
 *
 * This contains all the text for the BubbleViewItem component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.BubbleViewItem';

export default defineMessages({
  addToBench: {
    id: `${scope}.addToBench`,
    defaultMessage: 'Add to workbench',
  },
  showKpi: {
    id: `${scope}.showKpi`,
    defaultMessage: 'Show KPI',
  },
});
