import { defineMessages } from 'react-intl';

export const scope = 'features.HoveringTag';

export default defineMessages({
  entity: {
    id: `${scope}.entity`,
    defaultMessage: 'Connected Entities',
  },
  kpi: {
    id: `${scope}.kpi`,
    defaultMessage: 'KPI',
  },  
});
