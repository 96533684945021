import { useAppSelector } from './store';
import { useIntl } from 'react-intl';
import { createCaller } from 'react-outside-call';
import { AssertionSeverity, SAAFE } from 'asserts-types';

export const assertsColors = {
  critical: '#ff5151',
  warning: '#f2c222',
  info: '#6cacfd',
};

export const CATEGORY_COLOR: Record<string, string> = {
  saturation: assertsColors.critical,
  failure: assertsColors.critical,
  error: assertsColors.critical,
  anomaly: assertsColors.warning,
  amend: assertsColors.info,
};

export const successColor = '#7EB26D';
export const errorColor = '#FF5151';

export const POLICY_URL = 'https://policies.asserts.ai';

export const AXIS_PADDING_RIGHT = 50;

export const MAX_AUTOCOMPLETE_RESULTS_COUNT = 40;

export const callConfig = createCaller({
  // eslint-disable-next-line react-hooks/rules-of-hooks
  intl: () => useIntl(),
  // eslint-disable-next-line react-hooks/rules-of-hooks
  selectedEnv: () => useAppSelector((state) => state.app.selectedEnv),
  // eslint-disable-next-line react-hooks/rules-of-hooks
  selectedSite: () => useAppSelector((state) => state.app.selectedSite),
});

export const ROOT_ENTITY_HEIGHT = 150;
export const SAAFE_PROPERTIES: SAAFE[] = ['saturation', 'amend', 'anomaly', 'failure', 'error'];

export const SEVERITY_COLORS: Record<AssertionSeverity, string> = {
  critical: assertsColors.critical,
  warning: assertsColors.warning,
  info: assertsColors.info,
};

export const K8S_SUPPORTED_ENTITY_TYPES = ['Node', 'Namespace', 'Service', 'KubeCluster', 'Pod'];
export const CSP_SUPPORTED_SERVICES = ['AWS/RDS', 'AWS/EC2'];
export const CSP_ENTITY_PROVIDERS = ['aws', 'gcp', 'azure'];

export const APP_PLUGIN_IDS = {
  K8S_APP: 'grafana-k8s-app',
  APP_O11Y_APP: 'grafana-app-observability-app',
  FRONTEND_O11Y_APP: 'grafana-kowalski-app',
  CSP_APP: 'grafana-csp-app',
  SLO_APP: 'grafana-slo-app',
};

export const EXTERNAL_PLUGIN_VIEWS = {
  K8S_APP_OBJECT_DETAIL_EXTENSION_V1: `${APP_PLUGIN_IDS.K8S_APP}/object-detail-extension/v1`,
  O11Y_APP_OVERVIEW_EXTENSION_V1: `${APP_PLUGIN_IDS.APP_O11Y_APP}/overview-extension/v1`,
  FRONTEND_O11Y_APP_OVERVIEW_EXTENSION_V1: `${APP_PLUGIN_IDS.FRONTEND_O11Y_APP}/overview-extension/v1`,
  CSP_SERVICE_DASHBOARD_EXTENSION_V1: `${APP_PLUGIN_IDS.CSP_APP}/service-dashboard-extension/v1`,
};
