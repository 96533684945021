/**
 *
 * AssertionsSummaryItemMetric
 *
 */

import React, { memo, FunctionComponent, MouseEvent } from 'react';
import { EntityProperties, SummaryMetric, Scope } from 'asserts-types';
import AssertionsSummaryBarChartComponent from '../AssertionsSummaryBarChart/AssertionsSummaryBarChart.component';
import AssertionsSummaryItemMetricChartComponent from '../AssertionsSummaryItemMetricChart/AssertionsSummaryItemMetricChart.component';
import {
  EXPANDED_SUMMARY_ROW_GAP,
  EXPANDED_SUMMARY_ROW_HEIGHT,
} from '../../constants';
import useExpandedSummaryItems from '../../hooks/useExpandedSummaryItems';
import useSummaryLeftPanel from '../../hooks/useSummaryLeftPanel';
import ChevronRightRoundedIcon from 'assets/material-icons/chevron_right_FILL0_wght400_GRAD0_opsz24.svg';
import ExpandMoreRoundedIcon from 'assets/material-icons/keyboard_arrow_down_FILL0_wght400_GRAD0_opsz24.svg';
import { AXIS_PADDING_RIGHT } from 'app/constants';
import { getIconCodeByType } from 'helpers/Icon.helper';
import { Button, Tag, useTheme2 } from '@grafana/ui';
import { setActiveEntityDetails } from 'features/App/App.slice';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'components/Tooltip/Tooltip.component';

interface IProps {
  metric: SummaryMetric;
  start: number;
  end: number;
  entityName: string;
  entityType: string;
  scope: Scope;
  hasParent?: boolean;
  entityProperties: EntityProperties;
}

const AssertionsSummaryItemMetric: FunctionComponent<IProps> = ({
  metric,
  start,
  end,
  entityName,
  entityType,
  scope,
  hasParent,
  entityProperties,
}) => {
  const theme = useTheme2();

  const { isExpanded, toggleExpand } = useExpandedSummaryItems();
  const expanded = isExpanded(metric.id);
  const isGroup = metric.nestedSummaries.length > 0;

  const leafExpanded = expanded && !isGroup;
  const groupedExpanded = expanded && isGroup;

  const handleExpand = (e: MouseEvent) => {
    e.stopPropagation();
    toggleExpand(metric.id);
  };

  const { width: leftPanelWidth } = useSummaryLeftPanel();

  const Chevron = expanded ? ExpandMoreRoundedIcon : ChevronRightRoundedIcon;
  const healthStates = groupedExpanded ? [] : metric.healthStates;

  const logConfig = {
    entityName,
    entityType,
    labels: metric.labels[0],
    name: entityName,
    entityProperties,
    scope,
  };

  const dispatch = useDispatch();

  return (
    <>
      <div
        className={`grid ${leafExpanded ? 'items-start my-4' : 'items-center my-0'}`}
        style={{ gridTemplateColumns: `${leftPanelWidth}px auto` }}
      >
        <div className={`pr-2 ${hasParent ? 'pl-16' : 'pl-10'}`}>
          <Button
            className="justify-items-start max-w-full relative"
            fill="text"
            style={{ background: leafExpanded ? theme.colors.action.hover : undefined }}
            size="sm"
            onClick={handleExpand}
          >
            <div className="mr-[5px] flex items-center">
              {isGroup && <Chevron className="absolute top-1/2 -translate-x-[30px] -translate-y-1/2 text-secondary svg-icon" />}
              <Tooltip content={metric.alertName} placement="top">
                <i className="text-lg text-primary icon-">
                  {String.fromCharCode(getIconCodeByType(metric.alertName) || getIconCodeByType(metric.category) || 0)}
                </i>
              </Tooltip>
            </div>
            <p
              title={metric.assertionName}
              className={`overflow-hidden text-ellipsis whitespace-nowrap text-md ${
                metric.hovered ? 'font-bold text-primary' : 'text-secondary'
              }`}
            >
              {hasParent ? metric.alertName : metric.assertionName}
            </p>
          </Button>
          {leafExpanded && (
            <div className="ml-4 mt-2 flex gap-2">
              <Button
                size="sm"
                fill="text"
                onClick={() =>
                  dispatch(
                    setActiveEntityDetails({
                      name: entityName,
                      type: entityType,
                      scope: scope,
                      additionalLabels: metric.labels[0],
                      tab: 'logs',
                    })
                  )
                }
              >
                Logs
              </Button>
              <Button
                size="sm"
                fill="text"
                onClick={() =>
                  dispatch(
                    setActiveEntityDetails({
                      name: entityName,
                      type: entityType,
                      scope: scope,
                      additionalLabels: metric.labels[0],
                      tab: 'traces',
                    })
                  )
                }
              >
                Traces
              </Button>
              <Button
                size="sm"
                fill="text"
                onClick={() =>
                  dispatch(
                    setActiveEntityDetails({
                      name: entityName,
                      type: entityType,
                      scope: scope,
                      additionalLabels: metric.labels[0],
                      alertName: metric.labels[0].alertname,
                      tab: 'metrics',
                    })
                  )
                }
              >
                Metrics
              </Button>
            </div>
          )}
          {/* {leafExpanded && (
            <div className="ml-4 mt-2 flex gap-2">
              <ViewLogsButtonComponent
                start={start}
                end={end}
                item={logConfig}
              />
              <ViewTracesButtonComponent
                start={start}
                end={end}
                item={logConfig}
                params={{ threshold: [], values: [] }}
              />
            </div>
          )} */}
        </div>
        <div
          className="relative transition-all"
          style={{
            height: leafExpanded
              ? metric.labels.length * (EXPANDED_SUMMARY_ROW_HEIGHT + EXPANDED_SUMMARY_ROW_GAP) -
                EXPANDED_SUMMARY_ROW_GAP +
                'px'
              : '15px',
          }}
        >
          <AssertionsSummaryBarChartComponent
            start={start}
            end={end}
            healthStates={healthStates}
            expanded={leafExpanded}
            style={{ marginRight: AXIS_PADDING_RIGHT }}
            logConfig={logConfig}
          />
          {leafExpanded && (
            <div
              className="absolute inset-0 grid"
              style={{
                gridTemplateRows: `repeat(${metric.labels.length}, 1fr)`,
                gap: EXPANDED_SUMMARY_ROW_GAP,
              }}
            >
              {metric.labels.map((labelsSet, index) => (
                <div
                  className="relative"
                  key={labelsSet.asserts_entity_type || index}
                  style={{ height: `${EXPANDED_SUMMARY_ROW_HEIGHT}px` }}
                >
                  {metric.labels.length > 1 && (
                    <Tag
                      className="-translate-x-full absolute -left-[20px] bottom-[7px] z-[4]"
                      colorIndex={9}
                      name={labelsSet.asserts_entity_type}
                    />
                  )}
                  <AssertionsSummaryItemMetricChartComponent
                    alertName={metric.alertName}
                    labels={labelsSet}
                    entityProperties={entityProperties}
                    start={start}
                    end={end}
                    entityName={entityName}
                    entityType={entityType}
                    scope={scope}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {groupedExpanded &&
        metric.nestedSummaries.map((metric) => (
          <AssertionsSummaryItemMetric
            key={metric.id}
            metric={metric}
            start={start}
            end={end}
            entityName={entityName}
            entityType={entityType}
            scope={scope}
            entityProperties={entityProperties}
            hasParent
          />
        ))}
    </>
  );
};

export default memo(AssertionsSummaryItemMetric);
