import { useQuery } from '@tanstack/react-query';
import { DashboardConfig } from 'asserts-types';
import { fetchCustomDashboards } from 'services/CustomDashboard.service';

export function useCustomDashboards() {
  return useQuery(['custom-dashboards-list'], () => fetchCustomDashboards(), {
    select: (data) => {
      return data.kpiDashConfigs.reduce(
        (acc: (DashboardConfig & { entityType: string })[], curr) =>
          acc.concat(
            curr.dashConfigDto.map((item) => ({
              ...item,
              entityType: curr.entityType,
            }))
          ),
        []
      );
    },
  });
}
