import { useSelector } from 'react-redux';

import { stringToDate } from '../helpers/Date.helper';
import { useQuery } from '@tanstack/react-query';
import { GrafanaSloType, Slo, SloResponse } from 'asserts-types';
import { fetchGrafanaSloList, fetchTopSlo } from 'services/Slo.service';
import { mergeGrafanaAndAssertsSLOs } from 'features/Slo/slo.utils';
import { useCallback } from 'react';

interface Params {
  enabled: boolean | undefined;
  endTime: number | string;
}
export default function useTopSlo({ enabled, endTime }: Params) {
  const selectedEnv = useSelector((state: RootState) => state.app.selectedEnv);
  const selectedSite = useSelector((state: RootState) => state.app.selectedSite);

  return useQuery<Slo[]>(
    ['topSlo', endTime],
    async () => {
      const grafanaSLOs = fetchGrafanaSloList()
        .then((list) => list.filter((slo) => slo.readOnly.provenance === 'asserts'))
        .then((list) =>
          list.filter((s) => s.query.type === GrafanaSloType.FailureThreshold || s.query.type === GrafanaSloType.FailureRatio)
        );

      const assertsSLOs = fetchTopSlo(stringToDate(endTime).valueOf());

      // merging grafana SLO data with enriched asserts Backend SLO items
      const mergedSloData: SloResponse = mergeGrafanaAndAssertsSLOs({
        assertsSLOs: await assertsSLOs,
        grafanaSLOs: await grafanaSLOs,
      });

      // filtering items that wasn't returned from asserts backend (they are marked as 'processing' in mergeGrafanaAndAssertsSLOs but here asserts BE returns only top 5)
      return mergedSloData.slos.filter((slo) => slo.sloTargetDtos.length > 0);
    },
    {
      select: useCallback(
        (data: Slo[]) => {
          let filteredSlos = data;
          if (selectedSite.length || selectedEnv.length) {
            filteredSlos = filteredSlos.filter((slo) => {
              const site = selectedSite.length ? selectedSite.includes(slo.scope?.site || '') : true;
              const env = selectedEnv.length ? selectedEnv.includes(slo.scope?.env || '') : true;
              return site && env;
            });
          }

          return filteredSlos;
        },
        [selectedEnv, selectedSite]
      ),
      enabled,
      staleTime: typeof endTime === 'string' ? 0 : Infinity,
    }
  );
}
