/**
 *
 * AssertionsTimelineSerifs
 *
 */

import React, { memo, FunctionComponent, useMemo } from 'react';
import moment from 'moment';
import { TIMELINE_STEPS_COUNT } from 'features/Assertions/constants';

interface IProps {
  start: number;
  end: number;
}

const DAY_MS = 86400000;
const HOUR_MS = 3600000;
const MINUTE_MS = 60000;

const getTimeFormat = (timestamp: number) => {
  if (timestamp < MINUTE_MS) {
    return 'h:mm:ss A';
  }
  if (timestamp < HOUR_MS * TIMELINE_STEPS_COUNT) {
    return 'h:mm A';
  }
  if (timestamp < DAY_MS) {
    return 'hA';
  }

  return 'MMM D';
};

const TimelineSerifs: FunctionComponent<IProps> = ({ start, end }) => {
  const SERIF_WIDTH = 80;
  const rangeLengthMs = end - start;

  const timeStep = useMemo(
    () => Math.round((end - start) / TIMELINE_STEPS_COUNT),
    [start, end],
  );

  return (
    <>
      {Array.from(Array(TIMELINE_STEPS_COUNT - 1), (_, i) => (
        <p
          key={`${i}-timeline-serif`}
          className="absolute block text-center text-secondary text-xs"
          // prettier-ignore
          style={{ width:SERIF_WIDTH, left: `calc(${(100 / TIMELINE_STEPS_COUNT) * (i + 1)}% - ${SERIF_WIDTH / 2}px)` }}
        >
          {moment(start + timeStep * (i + 1)).format(getTimeFormat(rangeLengthMs))}
        </p>
      ))}
    </>
  );
};

export default memo(TimelineSerifs);
