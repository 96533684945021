import React, { memo, FunctionComponent, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import YmlFileEditor, { IYmlForm } from 'components/YmlFileEditor/YmlFileEditor.component';

import { fetchRuleFile, saveRuleFile } from 'services/ManageAssertions.service';
import { Button, Tab, TabsBar, useTheme2 } from '@grafana/ui';
import { prefixRoute } from 'utils/utils.routing';
import { ROUTES } from 'global-constants';
import SnackbarHelper from 'helpers/Snackbar.helper';

interface IProps {
  searchQuery: string;
  onSearchChange: (value: string) => void;
  onYmlFileChange: () => void;
}


const AddRuleTopMenu: FunctionComponent<IProps> = ({
  onYmlFileChange,
}) => {
  const intl = useIntl();

  const [showYmlEditor, setShowYmlEditor] = useState(false);
  const [ymlFileText, setYmlFileText] = useState('');
  const [fetchingFile, setFetchingFile] = useState(false);
  const [savingFile, setSavingFile] = useState(false);

  const handleShowYmlFile = () => {
    if (showYmlEditor) {
      setShowYmlEditor(false);
      return;
    }
    setFetchingFile(true);
    fetchRuleFile('user-defined-alerts')
      .then((text) => {
        setYmlFileText(text);
        setShowYmlEditor(true);
      })
      .finally(() => setFetchingFile(false));
  };

  const handleSubmitFile = (data: IYmlForm) => {
    setSavingFile(true);
    saveRuleFile(data.textField)
      .then(
        () => {
          setShowYmlEditor(false);
          SnackbarHelper.success(messages.fileSaved);
          onYmlFileChange();
        },
        (err) => {
          SnackbarHelper.error(messages.fileSaveError);
        },
      )
      .finally(() => setSavingFile(false));
  };

  const theme = useTheme2();

  const newRulePath = prefixRoute([ROUTES.RULES, ROUTES.RULES_ADD, ROUTES.RULES_ADD_RULE].join('/'));
  const newRuleFilePath = prefixRoute([ROUTES.RULES, ROUTES.RULES_ADD, ROUTES.RULES_ADD_FILE].join('/'));

  return (
    <>
      <div>
        <div className="flex items-center justify-between">
          <h1 style={{ fontSize: theme.typography.h1.fontSize }} className="m-0">
            Add new Rule
          </h1>
          <Button fill="outline" onClick={handleShowYmlFile}>
            {fetchingFile ? intl.formatMessage(messages.opening) : intl.formatMessage(messages.viewFile)}
          </Button>
        </div>
        <TabsBar className="w-full mt-2">
          <Tab
            label={intl.formatMessage(messages.newRule)}
            href={newRulePath}
            active={location.pathname.includes(newRulePath)}
          />
          <Tab
            label={intl.formatMessage(messages.newRuleFile)}
            href={newRuleFilePath}
            active={location.pathname.includes(newRuleFilePath)}
          />
        </TabsBar>
      </div>
      {showYmlEditor && (
        <YmlFileEditor
          onClose={() => setShowYmlEditor(false)}
          text={ymlFileText}
          onSubmit={handleSubmitFile}
          saving={savingFile}
        />
      )}
    </>
  );
};

export default memo(AddRuleTopMenu);
