import React, { FunctionComponent, useCallback, useEffect, useRef } from 'react';

import { ConnectedProps, connect, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import messages from './messages';
import { toggleExpandItem, setScrollPosition, setOrder, setOrderBy } from './Slo.slice';
import SloTargetRow from './components/SloTargetRow/SloTargetRow.component';
import { SloTarget, SloType } from 'asserts-types';
import SloTopMenu from './components/SloTopMenu/SloTopMenu.component';
import EnvSiteTagComponent from '../../components/EnvSiteTag/EnvSiteTag.component';
import { setItemToPopulate } from '../ManageAssertions/ManageAssertions.slice';
import { roundTo } from '../../helpers/ValueFormat.helper';
import useSloEndTime from './hooks/useSloEndTime';
import { SLO_ROUND } from './constants';
import SloSortByComponent from './components/SloSortBy/SloSortBy.component';
import useSlos from 'hooks/useSlos';
import {
  Button,
  DateTimePicker,
  InlineField,
  LoadingPlaceholder,
  PanelContainer,
  Badge as GrafanaBadge,
  LoadingBar,
  LinkButton,
  Stack,
  Box,
  EmptyState,
} from '@grafana/ui';
import { prefixRoute } from 'utils/utils.routing';
import { ROUTES } from 'global-constants';
import { PluginPage } from '@grafana/runtime';
import EnvSiteSelectorsComponent from 'components/EnvSiteSelectors/EnvSiteSelectors.component';
import { dateTimeParse } from '@grafana/data';
import DotBadge from 'components/DotBadge/DotBadge.component';
import TableSortButton from 'features/Entities/components/TableSortButton/TableSortButton.component';
import { UserCan, isUserActionAllowed } from 'utils/permissions';
import SloItemActions from './components/SloItemActions/SloItemActions';

const connector = connect(
  (state: RootState) => ({
    expandedItems: state.slo.expandedItems,
    scrollPosition: state.slo.scrollPosition,
    order: state.slo.order,
    orderBy: state.slo.orderBy,
    search: state.slo.search,
  }),
  {
    toggleExpandItem,
    setScrollPosition,
    setOrder,
    setOrderBy,
    setItemToPopulate,
  }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

const SloContainer: FunctionComponent<PropsFromRedux> = ({
  toggleExpandItem,
  expandedItems,
  scrollPosition,
  setScrollPosition,
  order,
  setOrder,
  setOrderBy,
  orderBy,
  setItemToPopulate,
  search,
}) => {
  const intl = useIntl();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = scrollPosition;
    }
    // eslint-disable-next-line
  }, []);

  const { endTime, changeEndTime, endTimeMemoized } = useSloEndTime();

  const { isFetching, data, isError } = useSlos({
    endTime: endTimeMemoized,
  });

  const getMinSli = useCallback((sloTargets: SloTarget[]) => {
    const min = sloTargets.reduce((min, curr) => (min.actualSli < curr.actualSli ? min : curr));
    return {
      isRed: min.actualSli < min.targetSli,
      actualSli: roundTo(min.actualSli, SLO_ROUND) || 0,
    };
  }, []);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const selectedEnv = useSelector((state: RootState) => state.app.selectedEnv).filter((s) => s !== 'all');
  const selectedSite = useSelector((state: RootState) => state.app.selectedSite).filter((s) => s !== 'all');

  const isEnvFilterApplied = selectedEnv.length > 0 || selectedSite.length > 0;

  return (
    <PluginPage
      subTitle="Asserts curated SLOs"
      actions={
        <Stack gap={2}>
          <InlineField label="Compliance window end time">
            <DotBadge show={endTime !== 'now'}>
              <DateTimePicker
                onChange={(v) => {
                  if (!v) {
                    return;
                  }
                  // datepicker ignores exact milliseconds so we need this check for it to not fire onChange when it's just input focus and blur
                  if (v && dateTimeParse(endTimeMemoized).valueOf() - v.valueOf() >= 1000) {
                    changeEndTime(v.valueOf());
                  }
                }}
                date={dateTimeParse(endTimeMemoized)}
                maxDate={new Date()}
              />
            </DotBadge>
          </InlineField>
          {endTime !== 'now' && (
            <InlineField>
              <Button variant="secondary" icon="clock-nine" onClick={() => changeEndTime('now')}>
                Current Time
              </Button>
            </InlineField>
          )}
          <EnvSiteSelectorsComponent start="now-1d" end="now" />
          {isUserActionAllowed(UserCan.WriteRules) && (
            <LinkButton icon="plus" href={prefixRoute(ROUTES.SLO_ADD)}>
              Create SLO
            </LinkButton>
          )}
        </Stack>
      }
    >
      <SloTopMenu changeEndTime={changeEndTime} endTime={endTime} />
      <Box marginY={2}>{!!data?.slos.length && !isFetching && <SloSortByComponent />}</Box>
      <Stack
        direction={'column'}
        gap={3}
        ref={ref}
        onScroll={(e) => {
          setScrollPosition(e.currentTarget.scrollTop);
        }}
      >
        {isFetching && !data && <LoadingPlaceholder text="Loading..." className="text-center py-36" />}
        {isFetching && data && (
          <div className="absolute top-0 inset-x-0 !mt-0">
            <LoadingBar width={300} />
          </div>
        )}

        {data?.slos.map((slo, i) => (
          <PanelContainer
            data-cy="slo-item"
            className="pt-7 pb-0 relative"
            key={`${slo.name}-${slo.scope?.env}-${slo.scope?.site}`}
          >
            <div className="flex items-center pl-8">
              <div className="flex items-center">
                <p data-cy="slo-name" className="font-bold text-base pr-4">
                  {slo.name}
                </p>
                <SloItemActions uuid={slo.uuid} />
              </div>
              <div className="flex items-center gap-2 divider-l pl-4">
                <p className="text-sm text-secondary">
                  {slo.type === SloType.Request ? 'Availability' : 'Latency / Occurrence'}
                </p>
                {!!slo.sloTargetDtos.length && (
                  <GrafanaBadge
                    text={`${getMinSli(slo.sloTargetDtos).actualSli}%`}
                    color={getMinSli(slo.sloTargetDtos).isRed ? 'red' : 'green'}
                  />
                )}
              </div>
            </div>
            <div className="pl-8">{slo.scope && <EnvSiteTagComponent scope={slo.scope} />}</div>
            {!!slo.sloTargetDtos.length && (
              <table>
                <thead>
                  <tr>
                    <td className="divider-b p-4 w-[7%] pl-0">
                      <p className="headerCell">{/* {intl.formatMessage(messages.name)} */}</p>
                    </td>
                    <td className="divider-b p-4 w-[10%]">
                      <p className="headerCell">{intl.formatMessage(messages.complianceWindow)}</p>
                    </td>
                    <td className="divider-b p-4 w-[7%]">
                      <p className="headerCell">{intl.formatMessage(messages.target)}</p>
                    </td>
                    <td className="divider-b p-4 w-[7%]">
                      <TableSortButton
                        active={orderBy === 'actualSli'}
                        direction={orderBy === 'actualSli' ? order : 'asc'}
                        onClick={() => handleRequestSort('actualSli')}
                        className="headerCell"
                      >
                        {intl.formatMessage(messages.actual)}
                      </TableSortButton>
                    </td>
                    <td className="divider-b p-4 w-[10%]">
                      <TableSortButton
                        active={orderBy === 'incidentCount'}
                        direction={orderBy === 'incidentCount' ? order : 'asc'}
                        onClick={() => handleRequestSort('incidentCount')}
                        className="headerCell"
                      >
                        {intl.formatMessage(messages.numberOfIncidents)}
                      </TableSortButton>
                    </td>
                    <td className="divider-b p-4 w-[10%]">
                      <TableSortButton
                        active={orderBy === 'budgetRemainingPersent'}
                        direction={orderBy === 'budgetRemainingPersent' ? order : 'asc'}
                        onClick={() => handleRequestSort('budgetRemainingPersent')}
                        className="headerCell"
                      >
                        {intl.formatMessage(messages.budgetUsed)}
                      </TableSortButton>
                    </td>
                    <td className="divider-b p-4 w-[10%]">
                      <TableSortButton
                        active={orderBy === 'recentBurnRate'}
                        direction={orderBy === 'recentBurnRate' ? order : 'asc'}
                        onClick={() => handleRequestSort('recentBurnRate')}
                        className="headerCell"
                      >
                        {intl.formatMessage(messages.recentBudgetUsage)}
                      </TableSortButton>
                    </td>
                    <td className="divider-b p-4 w-[10%]">
                      <TableSortButton
                        active={orderBy === 'incidentStatus'}
                        direction={orderBy === 'incidentStatus' ? order : 'asc'}
                        onClick={() => handleRequestSort('incidentStatus')}
                        className="headerCell"
                      >
                        {intl.formatMessage(messages.incidentStatus)}
                      </TableSortButton>
                    </td>
                    <td className="p-0 pr-4 divider-b"></td>
                  </tr>
                </thead>
                <tbody>
                  {slo.sloTargetDtos.map((sloTarget, index) => (
                    <SloTargetRow
                      sloTarget={sloTarget}
                      chartNames={data.chartNames}
                      slo={slo}
                      toggleExpandItem={toggleExpandItem}
                      expanded={expandedItems.includes(sloTarget.hash)}
                      key={index.toString()}
                      setItemToPopulate={setItemToPopulate}
                      endTime={endTime}
                    />
                  ))}
                </tbody>
              </table>
            )}
            {!slo.sloTargetDtos.length && (
              <Box padding={4}>
                This SLO item is currently being processed... Please refresh or revisit this page shortly.
              </Box>
            )}
          </PanelContainer>
        ))}

        {!data?.slos.length && !isFetching && !search && !isEnvFilterApplied && !isError && (
          <EmptyState
            variant="call-to-action"
            message={intl.formatMessage(messages.emptySlo)}
            button={
              <LinkButton icon="plus" href={prefixRoute(ROUTES.SLO_ADD)}>
                {intl.formatMessage(messages.addSloBtn)}
              </LinkButton>
            }
          />
        )}

        {!isError && !data?.slos.length && !isFetching && (!!search || isEnvFilterApplied) && (
          <EmptyState variant="not-found" message={intl.formatMessage(messages.noResults)} />
        )}
        {isError && <EmptyState variant="call-to-action" message="Something went wrong." />}
      </Stack>
    </PluginPage>
  );
};

export default connector(SloContainer);
