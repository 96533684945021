import React from 'react';

export default function ConnectedEntitiesIcon() {
  return (
    <svg className="svg-icon" viewBox="0 0 24 24">
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M18 5C18 5.55228 18.4477 6 19 6C19.5523 6 20 5.55228 20 5C20 4.44772 19.5523 4 19 4C18.4477 4 18 4.44772 18 5ZM19 2C17.3431 2 16 3.34315 16 5C16 5.46315 16.105 5.90178 16.2924 6.29342L14.7574 7.8284C13.967 7.30488 13.0191 7 12 7C10.9809 7 10.033 7.30488 9.24261 7.8284L7.70763 6.29342C7.89505 5.90178 8 5.46315 8 5C8 3.34315 6.65685 2 5 2C3.34315 2 2 3.34315 2 5C2 6.65685 3.34315 8 5 8C5.46315 8 5.90178 7.89505 6.29342 7.70763L7.8284 9.24261C7.30488 10.033 7 10.9809 7 12C7 13.0191 7.30488 13.967 7.8284 14.7574L6.29342 16.2924C5.90178 16.105 5.46315 16 5 16C3.34315 16 2 17.3431 2 19C2 20.6569 3.34315 22 5 22C6.65685 22 8 20.6569 8 19C8 18.5369 7.89505 18.0982 7.70763 17.7066L9.24261 16.1716C10.033 16.6951 10.9809 17 12 17C13.0191 17 13.967 16.6951 14.7574 16.1716L16.2924 17.7066C16.105 18.0982 16 18.5369 16 19C16 20.6569 17.3431 22 19 22C20.6569 22 22 20.6569 22 19C22 17.3431 20.6569 16 19 16C18.5369 16 18.0982 16.105 17.7066 16.2924L16.1716 14.7574C16.6951 13.967 17 13.0191 17 12C17 10.9809 16.6951 10.033 16.1716 9.24261L17.7066 7.70763C18.0982 7.89505 18.5369 8 19 8C20.6569 8 22 6.65685 22 5C22 3.34315 20.6569 2 19 2ZM9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12ZM19 20C18.4477 20 18 19.5523 18 19C18 18.4477 18.4477 18 19 18C19.5523 18 20 18.4477 20 19C20 19.5523 19.5523 20 19 20ZM4 19C4 19.5523 4.44772 20 5 20C5.55228 20 6 19.5523 6 19C6 18.4477 5.55228 18 5 18C4.44772 18 4 18.4477 4 19ZM5 6C4.44772 6 4 5.55228 4 5C4 4.44772 4.44772 4 5 4C5.55228 4 6 4.44772 6 5C6 5.55228 5.55228 6 5 6Z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  );
}
