import { IncidentGroup } from 'asserts-types';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { toggleFilterByType } from '../../Incidents.slice';
import messages from './messages';
import { useIntl } from 'react-intl';
import { useAppSelector } from 'app/store';
import { Checkbox } from '@grafana/ui';
import FormControlLabel from 'components/FormControlLabel/FormControlLabel.component';

interface IProps {}

const IncidentsFilterSettings: FC<IProps> = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const filterByType = useAppSelector((state) => state.incidents.filterByType);

  const options: IncidentGroup['type'][] = ['slo_alert', 'assertion_alert'];

  return (
    <div className="flex flex-col gap-2">
      {options.map((optionName) => (
        <FormControlLabel
          key={optionName}
          control={
            <Checkbox
              onClick={() => dispatch(toggleFilterByType(optionName))}
              value={!filterByType.includes(optionName)}
              name={optionName}
            />
          }
          label={formatMessage(messages[optionName])}
        />
      ))}
    </div>
  );
};

export default IncidentsFilterSettings;
