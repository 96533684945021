import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { fetchRequestMetricType } from 'services/ManageAssertions.service';
import { useMetricsDataSource } from './useMetricsDatasource';

interface UseParams {
  job?: string | null;
  requestType?: string | null;
}
export default function useRequestMetricType({
  job,
  requestType,
  enabled,
}: UseParams & Pick<UseQueryOptions, 'enabled'>) {
  const { data: metricsDatasource } = useMetricsDataSource();

  return useQuery<string>(
    ['requestMetricType', metricsDatasource.uid, job, requestType],
    async () => {
      if (job) {
        return await fetchRequestMetricType(metricsDatasource.uid, job, requestType);
      } else {
        return '';
      }
    },
    { enabled }
  );
}
