import { EntityInfo } from 'asserts-types';
import { areEntitiesEqual } from 'helpers/Entity.helper';

export default <
  T extends Pick<EntityInfo, 'scope' | 'name' | 'type' | 'nameWithNamespace'>
>(
  entities: T[],
): T[] => {
  return entities.map((e, index, list) => {
    const entity = { ...e };
    const sameEntityName = list.find(
      (e, indx) => areEntitiesEqual(e, entity) && index !== indx,
    );

    if (sameEntityName) {
      entity.nameWithNamespace = `${
        entity.scope.namespace ? `${entity.scope.namespace}:` : ''
      }${entity.name}`;
    }

    return entity;
  });
};
