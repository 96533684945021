import { useMutation } from '@tanstack/react-query';
import messages from '../messages';
import useCustomModelRulesList from './useCustomModelRulesList';
import { saveCustomModelRule } from 'services/ManageAssertions.service';
import SnackbarHelper from 'helpers/Snackbar.helper';

interface IProps {
  onSuccess?: () => void;
}

export default function useSaveCustomModelRule(props?: IProps) {
  const { refetch } = useCustomModelRulesList();

  const mutation = useMutation({
    mutationFn: (text: string) => saveCustomModelRule(text),
    onSuccess: () => {
      props?.onSuccess?.();
      SnackbarHelper.success(messages.fileSaved);
      refetch();
    },
  });

  return mutation;
}
