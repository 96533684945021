import { G6GraphEvent, Graph } from '@antv/g6';
import { useCallback, useEffect, useState } from 'react';
import GraphHelper from '../../helpers/Graph.helper';
import SnackbarHelper from '../../helpers/Snackbar.helper';
import messages from './messages';
import { GraphCustomData, GraphCustomNode } from 'asserts-types';
import { useMutation } from '@tanstack/react-query';
import { saveCustomJobRelation, saveCustomWorkloadRelation } from 'services/ManageAssertions.service';

interface Props {
  graphData: GraphCustomData;
  graph?: Graph;
}

export default function ({ graphData, graph }: Props) {
  // const dispatch = useDispatch();
  const [isEditRelationsMode, setIsEditMode] = useState(false);

  const [relationType, changeRelationType] = useState('ROUTES');
  const [sourceNode, setSourceNode] = useState<GraphCustomNode | undefined>();
  const [destinationNode, setDestinationNode] = useState<
    GraphCustomNode | undefined
  >();

  const graphDataProcessed = {
    ...graphData,
    nodes: graphData.nodes.map((n) =>
      n.id !== sourceNode?.id && n.id !== destinationNode?.id
        ? {
            ...n,
            disabled: isEditRelationsMode || n.disabled,
            disableTooltip: isEditRelationsMode,
          }
        : n,
    ),
    edges:
      sourceNode && destinationNode
        ? graphData.edges.concat(
            GraphHelper.generateEdge(sourceNode, destinationNode, relationType),
          )
        : graphData.edges,
  };

  const relationMutation = useMutation({
    mutationFn: () => {
      if (sourceNode && destinationNode) {
        if (
          sourceNode.properties.workload &&
          destinationNode.properties.workload
        ) {
          return saveCustomWorkloadRelation({
            workload: sourceNode.properties.workload.toString(),
            dst_workload: destinationNode.properties.workload.toString(),
            namespace: sourceNode.scope?.namespace,
            dst_namespace: destinationNode.scope?.namespace,
            asserts_env: sourceNode.scope?.env,
            asserts_site: sourceNode.scope?.site,
          });
        }
        if (
          sourceNode.properties.job &&
          destinationNode.properties.job
        ) {
          return saveCustomJobRelation({
            job: sourceNode.properties.job.toString(),
            dst_job: destinationNode.properties.job.toString(),
            namespace: sourceNode.scope?.namespace,
            dst_namespace: destinationNode.scope?.namespace,
            asserts_env: sourceNode.scope?.env,
            asserts_site: sourceNode.scope?.site,
          });
        }
      }
      return Promise.reject();
    },
    onError: () => {
      SnackbarHelper.error(messages.saveRelationError);
    },
    onSuccess: () => {
      exitEditMode();
      // dispatch(setGraphData(graphDataProcessed));
    },
  });

  const enterEditMode = () => {
    setIsEditMode(true);
  };

  const exitEditMode = () => {
    setIsEditMode(false);
    setSourceNode(undefined);
    setDestinationNode(undefined);
    changeRelationType('CALLS');
  };

  const handleNodeClick = useCallback(
    (e: G6GraphEvent) => {
      const node = e.item.getModel() as GraphCustomNode;

      if (node.entityType !== 'Service') {
        return;
      }

      if (
        sourceNode?.id &&
        GraphHelper.nodesConnected(
          graphData.edges,
          sourceNode.id,
          e.item.getID(),
        )
      ) {
        return;
      }

      if (!sourceNode) {
        setSourceNode(node);
        return;
      }
      if (!destinationNode && sourceNode.id !== e.item.getID()) {
        setDestinationNode(node);
        return;
      }
      if (sourceNode && destinationNode) {
        setSourceNode(node);
        setDestinationNode(undefined);
      }
    },
    [destinationNode, graphData.edges, sourceNode],
  );

  const handleNodeMouseEnter = useCallback(
    (e: G6GraphEvent) => {
      const node = e.item.getModel() as GraphCustomNode;

      if (node.entityType !== 'Service') {
        return;
      }

      if (
        sourceNode?.id === e.item.getID() ||
        destinationNode?.id === e.item.getID()
      ) {
        return;
      }

      if (
        sourceNode?.id &&
        GraphHelper.nodesConnected(
          graphData.edges,
          sourceNode.id,
          e.item.getID(),
        )
      ) {
        return;
      }

      graph?.updateItem(e.item, {
        style: { opacity: 1, cursor: 'pointer' },
      });
      graph?.paint();
    },
    [destinationNode?.id, graph, graphData.edges, sourceNode?.id],
  );

  const handleNodeMouseLeave = useCallback(
    (e: G6GraphEvent) => {
      graph?.updateItem(e.item, {
        style: { opacity: undefined },
      });
    },
    [graph],
  );

  useEffect(() => {
    if (isEditRelationsMode) {
      graph?.on('node:click', handleNodeClick);
      graph?.on('node:mouseenter', handleNodeMouseEnter);
      graph?.on('node:mouseleave', handleNodeMouseLeave);
    }

    return () => {
      graph?.off('node:mouseenter', handleNodeMouseEnter);
      graph?.off('node:mouseleave', handleNodeMouseLeave);
      graph?.off('node:click', handleNodeClick);
    };
  }, [
    isEditRelationsMode,
    graph,
    handleNodeClick,
    handleNodeMouseEnter,
    handleNodeMouseLeave,
  ]);

  const swapNodes = () => {
    if (sourceNode && destinationNode) {
      setSourceNode(destinationNode);
      setDestinationNode(sourceNode);
    }
  };

  return {
    isEditRelationsMode,
    sourceNode,
    destinationNode,
    enterEditMode,
    exitEditMode,
    setSourceNode,
    setDestinationNode,
    graphDataProcessed,
    swapNodes,
    relationType,
    changeRelationType,
    ...relationMutation,
  };
}
