import { useCallback } from 'react';
import GraphHelper from '../helpers/Graph.helper';
import { getHash } from '../features/Assertions/Assertions.helpers';
import { orderBy } from 'lodash';
import { useSelector } from 'react-redux';

import prepareAssertionEntities from '../features/Assertions/helpers/prepareAssertionEntities';
import { getDashedScopeValues } from '../helpers/Entity.helper';
import { stringToDate } from '../helpers/Date.helper';
import { AssertionScoresRes, GraphCustomData, GraphCustomNode, SAAFE, TopInsightsSort } from 'asserts-types';
import { useQuery } from '@tanstack/react-query';
import { searchScoresByQuery } from 'services/Assertion.service';

// const filterRecursive = (
//   rollup: AssertionRollup[],
//   typeFilter: string[],
// ): AssertionRollup[] => {
//   return rollup
//     .map((item) => ({
//       ...item,
//       nextLevel: item.nextLevel
//         ? filterRecursive(item.nextLevel, typeFilter)
//         : [],
//     }))
//     .filter(
//       (item) =>
//         item.nextLevel?.length ||
//         (item.type && !typeFilter.includes(item.type)),
//     );
// };

const select = (
  sortField: TopInsightsSort | undefined,
  data: AssertionScoresRes & {
    alertCategoriesCountMap: Record<SAAFE, number>;
  }
) => {
  const graphDataParsed: GraphCustomData = {
    nodes: data.graphData.map((entity) => GraphHelper.convertToNode(entity) as GraphCustomNode),
    edges: [],
  };

  let processedScores = data.assertionScoresForTopNEntities.map((item) => ({
    ...item,
    itemId: getHash(`${item.name}-${item.type}-group-${getDashedScopeValues(item.scope)}`),
    percentage: item.percentage ? +item.percentage : 0,
  }));

  processedScores = orderBy(
    processedScores,
    // default behavior of orderBy is to place empty field items at the top, custom sort here to move them down
    sortField?.field ? (item) => item[sortField.field] || '' : undefined,
    sortField?.order
  );

  processedScores = prepareAssertionEntities(processedScores);

  return {
    ...data,
    graphDataParsed,
    assertionScoresForTopNEntities: processedScores,
    treeData: data.assertionRollupDto,
  };
};

interface UseParams {
  query: string;
  start: number | string;
  end: number | string;
  alertCategories: SAAFE[];
  hideAssertionsOlderThanNHours?: number;
  typeFilter: string[];
  sortField?: TopInsightsSort;
}

export default function useTopInsightsSearch({ query, start, end, alertCategories, typeFilter, sortField }: UseParams) {
  const selectedEnv = useSelector((state: RootState) => state.app.selectedEnv);
  const selectedSite = useSelector((state: RootState) => state.app.selectedSite);

  const hideOldAssertions = useSelector((state: RootState) => state.assertions.hideOldAssertions);
  const oldAssertionHours = useSelector((state: RootState) => state.assertions.oldAssertionHours);
  const hideAssertionsOlderThanNHours = hideOldAssertions ? oldAssertionHours : undefined;

  return useQuery<
    AssertionScoresRes & {
      alertCategoriesCountMap: Record<SAAFE, number>;
    },
    unknown,
    ReturnType<typeof select>
  >(
    ['topInsightsSearch', query, start, end, alertCategories, hideAssertionsOlderThanNHours, selectedEnv, selectedSite],
    async () => {
      const res = await searchScoresByQuery(
        query,
        stringToDate(start).valueOf(),
        stringToDate(end).valueOf(),
        alertCategories,
        hideAssertionsOlderThanNHours
      );
      const alertCategoriesCountMap: Record<SAAFE, number> = {
        saturation: 0,
        amend: 0,
        anomaly: 0,
        failure: 0,
        error: 0,
      };
      for (let item of res.assertionRollupDto) {
        alertCategoriesCountMap[item.name as SAAFE] = item.assertionCount;
      }

      // map properties from graphData to assertion scores
      if (res.assertionScoresForTopNEntities.length) {
        res.assertionScoresForTopNEntities.forEach((a) => {
          const entity = res.graphData.find(
            (e) =>
              e.name === a.name && e.type === a.type && e.scope?.env === a.scope?.env && e.scope?.site === a.scope?.site
          );
          if (entity?.properties) {
            a.properties = entity.properties;
          }
        });
      }

      return {
        ...res,
        alertCategoriesCountMap,
      };
    },
    {
      select: useCallback(select, []).bind(null, sortField),
      keepPreviousData: true,
      staleTime: typeof start === 'string' ? 0 : Infinity,
    }
  );
}
