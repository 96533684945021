/**
 *
 * LineRangeChart
 *
 */

import React, {
  memo,
  useRef,
  useEffect,
  useMemo,
  useState,
  ForwardRefRenderFunction,
  forwardRef,
  MouseEvent,
  ReactNode,
} from 'react';
import { Chart, registerTheme } from '@antv/g2';
import { antvDark } from '@antv/g2/lib/theme/style-sheet/dark';
import { createThemeByStyleSheet } from '@antv/g2/lib/theme/util/create-by-style-sheet';
import {
  Threshold,
  Metric,
  ChartPoint,
  ProcessedChartPoint,
  LegendConfig,
  LegendItem,
} from 'asserts-types';
import useResizeObserver from 'use-resize-observer';
import { unionBy, sortBy } from 'lodash';
import { formatLongNumber } from '../../helpers/ValueFormat.helper';
import Element from '@antv/g2/lib/geometry/element';
import pretty from '@antv/scale/lib/util/pretty';
import useDebounceValue from '../../hooks/useDebounceValue';
import { ListItem } from '@antv/g2/lib/dependents';
import moment from 'moment';
import { FORMAT_YEARLESS_lll, FORMAT_YEARLESS_ll_LTS } from '../../app/moment-locales';
import { CHART_COLORS_MAP, CHART_CROSSHAIRS_STYLE, CHART_GEOMETRY_THEME, DEFAULT_CHART_PADDING } from './constants';
import ChartTooltip from 'components/ChartTooltip/ChartTooltip';
import ChartLegendComponent from 'components/ChartLegend/ChartLegend.component';
import { useTheme2 } from '@grafana/ui';

const getMinMax = (arr: (number | null)[]) => {
  let len = arr.length;
  let max = -Infinity;
  let min = Infinity;

  while (len--) {
    const number = arr[len];
    if (number !== null) {
      max = number > max ? number : max;
      min = number < min ? number : min;
    }
  }
  return { min: min === Infinity ? 0 : min, max: max === -Infinity ? 0 : max };
};

export interface LineRangeChartProps {
  data: Metric[];
  timeStepInterval?: number;
  thresholds?: Threshold[];
  hideX?: boolean;
  hideY?: boolean;
  positionX?: 'left' | 'top' | 'bottom' | 'right';
  positionY?: 'left' | 'top' | 'bottom' | 'right';
  padding?: number | number[];
  minX?: number;
  maxX?: number;
  minY?: number;
  maxY?: number;
  showLegend?: boolean;
  type: 'area' | 'line';
  areaUnderLine?: boolean;
  disableGrid?: boolean;
  nullAsZero?: boolean;
  fillOpacity?: number;
  disableTooltip?: boolean;
  crossingRanges?: ProcessedChartPoint[];
  countTicks?: number;
  scaleType?: 'log' | 'linear';
  className?: string;
  customLegendConfig?: LegendConfig;
  showCrosshairs?: boolean;
  disableTooltipPortal?: boolean;
  renderTooltipButtons?: (tooltipTime: number) => ReactNode;
  tooltipMetricActionsMap?: Record<string, { action: (e: MouseEvent, tooltipTime: number) => void; label: string }>;
  classes?: { tooltip?: string };
}

const getRandomColor = () => {
  let letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

registerTheme('dark', createThemeByStyleSheet({ ...antvDark, backgroundColor: 'transparent' }));

const LineRangeChart: ForwardRefRenderFunction<Chart, LineRangeChartProps> = (
  {
    data,
    thresholds,
    hideX,
    hideY,
    positionX,
    positionY,
    padding,
    minX,
    maxX,
    minY,
    maxY,
    showLegend,
    type,
    disableGrid,
    timeStepInterval,
    nullAsZero,
    fillOpacity = 0.25,
    disableTooltip,
    crossingRanges,
    countTicks = 5,
    areaUnderLine,
    scaleType = 'linear',
    className,
    customLegendConfig,
    showCrosshairs,
    disableTooltipPortal,
    renderTooltipButtons,
    tooltipMetricActionsMap,
    classes,
  },
  forwardedRef,
) => {
  const ref = useRef<HTMLDivElement>(null);
  const chartRef = useRef<Chart | null>(null);
  const theme = useTheme2();

  const [legendConfig, setLegendConfig] = useState<LegendConfig>();
  const [size, setSize] = useState<{
    width: number | undefined;
    height: number | undefined;
  }>();
  const debouncedSize = useDebounceValue(size, 300);
  const [activeLegendItem, setActiveLegendItem] = useState<LegendItem>();

  useResizeObserver({
    ref,
    onResize: setSize,
  });

  useEffect(() => {
    if (chartRef.current && debouncedSize?.width && debouncedSize?.height) {
      chartRef.current.changeSize(debouncedSize.width, debouncedSize.height);
    }
  }, [debouncedSize]);

  const ticks = useMemo(() => {
    const interval = ((maxX || 0) - (minX || 0)) / countTicks;
    const tickArr = [(minX || 0) + interval * 0.2];
    [...Array(countTicks - 1)].forEach((_, i) =>
      tickArr.push((minX || 0) + interval * (i + 1)),
    );
    tickArr.push((minX || 0) + interval * (countTicks - 0.2));
    return tickArr;
  }, [maxX, minX, countTicks]);

  useEffect(() => {
    if (!ref.current || !data) {
      return;
    }

    let legendConfig: LegendConfig = {};

    if (chartRef.current) {
      chartRef.current.destroy();
    }

    const chart = new Chart({
      container: ref.current,
      autoFit: true,
      padding: padding || DEFAULT_CHART_PADDING,
      theme: theme.name.toLowerCase(),
    });

    if (typeof forwardedRef === 'function') {
      forwardedRef(chart);
    } else if (forwardedRef) {
      forwardedRef.current = chart;
    }

    chart.legend(false);

    let processedData: ProcessedChartPoint[] = [];

    let defaultTimeValues: (ChartPoint & { name?: string })[] = [];
    if (timeStepInterval) {
      let time = minX ? minX : 0;
      for (; time < (maxX || 0); time += timeStepInterval) {
        defaultTimeValues.push({
          time,
          value: nullAsZero ? 0 : null,
        });
      }
    }

    data.forEach((metric, index) => {
      let fullValues = sortBy(
        unionBy(metric.values, defaultTimeValues, 'time'),
        'time',
      );

      processedData = processedData.concat(
        fullValues.map((a) => ({
          ...a,
          name: metric.name,
        })),
      );

      const color =
        metric.color ||
        CHART_COLORS_MAP.lightTheme.lines[index] ||
        getRandomColor();

      legendConfig[metric.name] = { color, shape: type, field: 'name' };
    });

    let thresholdsMaxValues: {
      singleThresholdName?: string;
      areaThresholdName?: string;
      value: number;
    }[] = [];
    if (thresholds) {
      thresholds.forEach((item) => {
        let fullValues = sortBy(
          unionBy(item.values, defaultTimeValues, 'time'),
          'time',
        );

        if (item.type === 'minmax') {
          processedData = processedData.concat(
            fullValues.map((a) => ({
              time: a.time,
              areaThresholdName: item.name,
              values: a.values ? a.values : nullAsZero ? [0, 0] : [null, null],
            })),
          );
          thresholdsMaxValues.push({
            areaThresholdName: item.name,
            value: fullValues.reduce(
              (prevMax, current) =>
                (current.values?.[1] || 0) > prevMax
                  ? current.values?.[1] || 0
                  : prevMax,
              0,
            ),
          });
        }
        if (item.type === 'single') {
          processedData = processedData.concat(
            fullValues.map((a) => ({
              time: a.time,
              singleThresholdValue: a.value,
              singleThresholdName: item.name,
            })),
          );
          thresholdsMaxValues.push({
            singleThresholdName: item.name,
            value: fullValues.reduce(
              (prevMax, current) =>
                (current.value || 0) > prevMax ? current.value || 0 : prevMax,
              0,
            ),
          });
        }
      });
    }

    const hasAreaThreshold = !!thresholds?.find((t) => t.type === 'minmax');

    if (crossingRanges) {
      processedData = processedData.concat(crossingRanges);
    }

    // this sorting is needed for proper order of applying colors for singleThresholdValue (warning and critical)
    thresholdsMaxValues = thresholdsMaxValues.sort((a, b) => {
      if (a.value && b.value) {
        return a.value - b.value;
      }
      return 0;
    });

    let singleValueCounter = 0;
    let valuesCounter = 0;

    thresholdsMaxValues.forEach((item) => {
      if (item.singleThresholdName && !legendConfig[item.singleThresholdName]) {
        legendConfig[item.singleThresholdName] = {
          color:
            CHART_COLORS_MAP.lightTheme.thresholds.single[singleValueCounter],
          shape: 'dash',
          field: 'singleThresholdName',
        };
        singleValueCounter++;
      }
      if (item.areaThresholdName && !legendConfig[item.areaThresholdName]) {
        legendConfig[item.areaThresholdName] = {
          color: CHART_COLORS_MAP.lightTheme.thresholds.minmax[valuesCounter],
          shape: 'area',
          field: 'areaThresholdName',
        };
        valuesCounter++;
      }
    });

    let dataMinY: number | undefined;
    let dataMaxY: number | undefined;

    if (typeof minY === 'undefined' || typeof maxY === 'undefined') {
      const allValues: (number | null)[] = [];

      processedData.forEach((point) => {
        if (point.value !== undefined) {
          allValues.push(point.value);
          return;
        }
        if (point.singleThresholdValue !== undefined) {
          allValues.push(point.singleThresholdValue);
          return;
        }
        if (point.values?.length) {
          allValues.push(...point.values);
        }
      });
      if (allValues.length) {
        const minMaxValue = getMinMax(allValues);
        dataMinY = minMaxValue.min;
        dataMaxY = minMaxValue.max;
      }
    }

    let finalMinY = typeof minY !== 'undefined' ? minY : dataMinY || 0;
    let finalMaxY = typeof maxY !== 'undefined' ? maxY : dataMaxY || 0;

    if (finalMinY === finalMaxY) {
      // min and max can't be the same since it breaks inside g2 on calculating ticks
      finalMaxY += 1;
    }

    // need for Y Axis zero start line
    const prettyTicks = pretty(finalMinY, finalMaxY);
    finalMinY = prettyTicks.min;

    // add gap to maxY to avoid chart sticking to the top
    const ticksStep =
      prettyTicks.ticks[prettyTicks.ticks.length - 1] -
      prettyTicks.ticks[prettyTicks.ticks.length - 2];
    finalMaxY = finalMaxY + ticksStep / 2;

    chart.scale({
      value: {
        sync: true,
        min: finalMinY,
        max: finalMaxY,
        type: scaleType,
        tickMethod: scaleType === 'log' ? 'log' : 'r-pretty',
        formatter: (value: number) => {
          return formatLongNumber(value);
        },
      },
      values: {
        sync: true,
        min: finalMinY,
        max: finalMaxY,
        formatter: (value: number) => {
          return formatLongNumber(value);
        },
      },
      crossingRangeValues: {
        sync: true,
        min: finalMinY,
        max: finalMaxY,
        formatter: (value: number) => {
          return formatLongNumber(value);
        },
      },
      singleThresholdValue: {
        sync: true,
        min: finalMinY,
        max: finalMaxY,
        formatter: (value: number) => {
          return formatLongNumber(value);
        },
      },
      time: {
        type: 'time',
        mask: FORMAT_YEARLESS_ll_LTS,
        sync: true,
        min: minX,
        max: maxX,
        ticks,
      },
    });

    if (disableTooltip) {
      chart.tooltip(false);
    } else {
      chart.tooltip({
        ...CHART_CROSSHAIRS_STYLE,
        showCrosshairs,
      });
    }

    chart.axis('time', {
      position: positionX,
      label: {
        formatter: (text: string, item: ListItem) => {
          return moment(parseInt(item.id || '', 10)).format(FORMAT_YEARLESS_lll);
        },
      },
    });
    chart.axis('value', {
      position: positionY,
      label: {
        formatter: (text: string) => {
          return text.length > 6 ? text.slice(0, 6) + '...' : text;
        },
        offset: 5,
        style: {
          fontWeight: 400,
          fill: theme.colors.text.primary,
        },
      },
    });

    chart.axis('singleThresholdValue', false);
    chart.axis('values', false);
    chart.axis('crossingRangeValues', false);

    if (hideX) {
      chart.axis('time', { label: null });
    }
    if (hideY) {
      chart.axis('value', { label: null });
    }

    // main chart
    chart[type]({
      theme: CHART_GEOMETRY_THEME,
    })
      .position('time*value')
      .color('name', (name) => legendConfig[name]?.color)
      .style('name', () => ({ lineWidth: 1, fillOpacity }));

    if (areaUnderLine && !hasAreaThreshold) {
      chart
        .area({
          theme: CHART_GEOMETRY_THEME,
        })
        .position('time*value')
        .color('name', (name) => legendConfig[name]?.color)
        .style('name', () => ({ lineWidth: 1, fillOpacity }));
    }

    // area of line chart
    // if (type === 'line') {
    //   chart
    //     .area()
    //     .position('time*value')
    //     .color('name', colors.lightTheme.lines);
    // }

    // min max value threshold area
    chart
      .area({
        theme: CHART_GEOMETRY_THEME,
      })
      .position('time*values')
      .color(
        'areaThresholdName',
        CHART_COLORS_MAP.lightTheme.thresholds.minmax,
      );
    // single value threshold lines
    chart
      .line()
      .position('time*singleThresholdValue')
      .color('singleThresholdName', (name) => legendConfig[name]?.color)
      .shape('dash')
      .style({ lineDash: [7, 7], lineWidth: 2 });

    // range for under and below single threshold value
    if (crossingRanges?.length) {
      chart
        .area()
        .position('time*crossingRangeValues')
        .color('crossingRangeName', (name) => name)
        .style('name', () => ({ fillOpacity }))
        .tooltip(false);
    }

    if (disableGrid) {
      chart.axis('singleThresholdValue', { grid: null, label: null });
      chart.axis('values', { grid: null, label: null });
      chart.axis('value', { grid: null });
      chart.axis('crossingRangeValues', { grid: null, label: null });
    }

    chart.data(processedData);
    minX &&
      maxX &&
      chart.filter('time', (value: any) => value >= minX && value <= maxX);

    chart.render();

    chartRef.current = chart;
    setLegendConfig({ ...legendConfig, ...customLegendConfig });

    // eslint-disable-next-line
  }, [
    data,
    theme.name,
    nullAsZero,
    timeStepInterval,
    scaleType,
    minX,
    maxX,
    ticks,
    thresholds,
  ]);

  const handleLegendItemClick = (item: LegendItem) => {
    if (!item.field) {
      return;
    }

    const chart = chartRef.current;

    if (activeLegendItem) {
      activeLegendItem.field && chart?.filter(activeLegendItem.field, null);
    }

    if (activeLegendItem?.name === item.name) {
      setActiveLegendItem(undefined);
    } else {
      chart?.filter(item.field, (name) => name === item.name);
      setActiveLegendItem(item);
    }
    chart?.render(true);
  };

  const handleItemMouseHover = (active: boolean, item: LegendItem) => {
    const chart = chartRef.current;
    let elementsToHighlight: Element[] = [];

    chart?.geometries.forEach((geometry) => {
      const elementKey = Object.keys(geometry.elementsMap).find((key) =>
        key.includes(item.name),
      );
      if (elementKey) {
        elementsToHighlight.push(geometry.elementsMap[elementKey]);
      }
    });
    elementsToHighlight.forEach((element) =>
      element.setState('active', active),
    );
  };

  return (
    <>
      <div
        ref={ref}
        className={`absolute inset-0 line-range-chart ${className || ''}`}
        style={{ paddingBottom: showLegend ? '25px' : 0 }}
      />
      <ChartTooltip
        chart={chartRef.current}
        disablePortal={disableTooltipPortal}
        renderTooltipButtons={renderTooltipButtons}
        tooltipMetricActionsMap={tooltipMetricActionsMap}
        className={classes?.tooltip}
      />
      {showLegend && legendConfig && (
        <ChartLegendComponent
          legendConfig={legendConfig}
          activeLegendItem={activeLegendItem}
          onItemClick={handleLegendItemClick}
          onItemMouseOver={handleItemMouseHover.bind(null, true)}
          onItemMouseOut={handleItemMouseHover.bind(null, false)}
        />
      )}
    </>
  );
};

const forwardedLineRangeChart = forwardRef(LineRangeChart);

export default memo(forwardedLineRangeChart);
