import { defineMessages } from 'react-intl';

export const scope = 'components.NotificationRequestForm';

export default defineMessages({
  ruleName: {
    id: `${scope}.ruleName`,
    defaultMessage: 'Rule Name',
  },
  group: {
    id: `${scope}.group`,
    defaultMessage: 'Group',
  },
  rules: {
    id: `${scope}.rules`,
    defaultMessage: 'Rules',
  },
  requestType: {
    id: `${scope}.requestType`,
    defaultMessage: 'Request Type',
  },
  requestContext: {
    id: `${scope}.requestContext`,
    defaultMessage: 'Request Context',
  },
  value: {
    id: `${scope}.value`,
    defaultMessage: 'Value',
  },
  addNew: {
    id: `${scope}.addNew`,
    defaultMessage: 'Add new',
  },
  otherLabels: {
    id: `${scope}.otherLabels`,
    defaultMessage: 'Other Labels',
  },
  errorType: {
    id: `${scope}.errorType`,
    defaultMessage: 'Error Type',
  },
  env: {
    id: `${scope}.env`,
    defaultMessage: 'Env',
  },
  for: {
    id: `${scope}.for`,
    defaultMessage: 'Continues for',
  },
  receiver: {
    id: `${scope}.receiver`,
    defaultMessage: 'Receiver',
  },
  addTitle: {
    id: `${scope}.addTitle`,
    defaultMessage: 'Add Health Notification',
  },
  addTitleSuppress: {
    id: `${scope}.addTitle`,
    defaultMessage: 'Add Health Suppression',
  },
});
