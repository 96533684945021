import React from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  control: React.ReactElement<any, any>;
  label: React.ReactNode;
  className?: string;
  classes?: {
    label?: string;
  };
}

export default function FormControlLabel({ control, label, classes, className }: Props) {
  return (
    <label className={twMerge('flex items-center gap-3', className)}>
      {control} <span className={twMerge('text-sm cursor-pointer', classes?.label)}>{label}</span>
    </label>
  );
}
