import qs from 'qs';
import { useDispatch } from 'react-redux';
import { fillSliceWithQueryParams } from '../Entities.slice';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { filterFilterCriteria } from '../../../helpers/FilterCriteria.helper';
import { useAppSelector } from 'app/store';
import { EntitiesQueryParams } from 'asserts-types';
import { prefixRoute } from 'utils/utils.routing';
import { ROUTES } from 'global-constants';
import { fillAppSliceWithQueryParams } from 'features/App/App.slice';
import { omit } from 'lodash';

export function useEntitiesQueryParams() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const search = useAppSelector((state) => state.entities.search);
  const searchDefinition = useAppSelector((state) => state.entities.searchDefinition);

  const searchObject = useAppSelector((state) => state.entities.searchObject);

  const bubbleViewPanels = useAppSelector((state) => state.entities.bubbleViewPanels);
  const entityListColumns = useAppSelector((state) => state.entities.entityListColumns);
  const selectedKpi = useAppSelector((state) => state.entities.selectedKpi);

  const filterCriteria = useMemo(() => {
    const filtered = filterFilterCriteria(searchObject.filterCriteria);
    return filtered.length ? filtered : undefined;
  }, [searchObject.filterCriteria]);

  const start = useAppSelector((state) => state.app.start);
  const end = useAppSelector((state) => state.app.end);
  const activeEntityDetails = useAppSelector((state) => state.app.activeEntityDetails);

  const view = useAppSelector((state) => state.entities.activeView);

  const env = useAppSelector((state) => state.app.selectedEnv.filter((e) => e !== 'all'));
  const site = useAppSelector((state) => state.app.selectedSite.filter((e) => e !== 'all'));

  // reads query string parameters and fill entities store with values and fill start and end
  const readQueryParams = () => {
    const queryParams = qs.parse(window.location.search.slice(1), {
      arrayLimit: 1000,
    }) as unknown as EntitiesQueryParams;

    dispatch(fillSliceWithQueryParams(queryParams));

    // fill common options in app slice
    dispatch(fillAppSliceWithQueryParams(queryParams));

    return queryParams;
  };

  const setQueryStringUrl = () => {
    const params: EntitiesQueryParams = {
      start: start.toString(),
      end: end.toString(),
      site,
      env,
      definitionId: searchDefinition?.definitionId,
      boundDescription: searchDefinition?.boundDescription,
      bindings: searchDefinition?.bindings,
      filterCriteria: searchDefinition?.filterCriteria || filterCriteria,
      view,
      bvp: bubbleViewPanels,
      ec: entityListColumns,
      kc: selectedKpi,
      // omitting values and thresholds since it's overwhelming for url
      ed: omit(activeEntityDetails, ['values', 'threshold']),
    };

    const queryParams = qs.stringify(params);

    const route = prefixRoute(ROUTES.ENTITIES_GRAPH);

    const url = new URL(window.location.href);
    const replace = url.searchParams.size === 0;

    // push only in case url changed
    // don't make extra navigation if search is fetching
    if (`${window.location.origin}${route}?${queryParams}` !== window.location.href && !search) {
      navigate(`${route}?${queryParams}`, { state: { timestamp: Date.now() }, replace });
    }
    return queryParams;
  };

  return {
    readQueryParams,
    setQueryStringUrl,
  };
}
