/*
 * SearchFilterControl Messages
 *
 * This contains all the text for the SearchFilterControl component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.SearchFilterControl';

export default defineMessages({
  all: {
    id: `${scope}.all`,
    defaultMessage: 'Show: All',
  },
  selectedOnly: {
    id: `${scope}.selectedOnly`,
    defaultMessage: 'Show: Selected',
  },
});
