export const LEFT_MENU_WIDTH = 200;
export const LEFT_MENU_WIDTH_COLLAPSED = 72;
export const SLICE_NAME = 'app';

export const envColors = [
  '#673ab7',
  '#00bcd4',
  '#8f9a27',
  '#009688',
  '#4caf50',
  '#f50057',
  '#1565c0',
  '#f9a825',
  '#8F4068',
  '#C7CFB7',
  '#CC7351',
  '#CD5D7D',
];

export const APP_VERSION_KEY = 'appVersion';
export const CHECK_FOR_UPDATES_INTERVAL = 300000; // 5 min
