/*
 * TopInsights Messages
 *
 * This contains all the text for the TopInsights container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'features.TopInsights';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the TopInsights container!',
  },
  viewSettings: {
    id: `${scope}.viewSettings`,
    defaultMessage: 'View settings',
  },

  filterSettings: {
    id: `${scope}.filterSettings`,
    defaultMessage: 'Filter settings',
  },
  savedSearches: {
    id: `${scope}.savedSearch`,
    defaultMessage: 'Saved searches',
  },
  copied: {
    id: `${scope}.copied`,
    defaultMessage: 'Link copied to clipboard',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'My Insights',
  },
  add: {
    id: `${scope}.add`,
    defaultMessage: 'Add new search query (Max: {count})',
  },
  myQueries: {
    id: `${scope}.myQueries`,
    defaultMessage: 'My watched queries',
  },
});
