import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE_NAME } from './constants';
import { IncidentsContainerState, IncidentGroup, IncidentsQueryParams } from 'asserts-types';

const initialState: IncidentsContainerState = {
  expandedItems: [],
  sortBy: 'endTime',
  filterByType: [],
};

export const slice = createSlice({
  name: SLICE_NAME,

  initialState,

  reducers: {
    toggleFilterByType: (
      state,
      action: PayloadAction<IncidentGroup['type']>,
    ) => {
      if (state.filterByType.includes(action.payload)) {
        state.filterByType = state.filterByType.filter(
          (f) => f !== action.payload,
        );
      } else {
        state.filterByType.push(action.payload);
      }
    },
    setSortBy: (
      state,
      action: PayloadAction<IncidentsContainerState['sortBy']>,
    ) => {
      state.sortBy = action.payload;
    },
    setIncidentSearch: (state, action: PayloadAction<string>) => {
      state.incidentSearch = action.payload;
    },
    toggleExpandItem: (state, action: PayloadAction<string>) => {
      if (state.expandedItems.indexOf(action.payload) !== -1) {
        state.expandedItems = state.expandedItems.filter(
          (item) => item !== action.payload,
        );
      } else {
        state.expandedItems.push(action.payload);
      }
    },
    setExpandedItems: (state, action: PayloadAction<string[]>) => {
      state.expandedItems = action.payload;
    },
    fillSliceWithQueryParams: (
      state,
      action: PayloadAction<IncidentsQueryParams>,
    ) => {
      const queryParams = action.payload;

      if (queryParams.search) {
        state.incidentSearch = queryParams.search;
      }
    },
  },
});

export const {
  setIncidentSearch,
  toggleExpandItem,
  setExpandedItems,
  setSortBy,
  toggleFilterByType,
  fillSliceWithQueryParams,
} = slice.actions;

export default slice.reducer;
