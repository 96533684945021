import { Select, SelectCommonProps, Stack } from '@grafana/ui';
import { getIconCodeByType, getIconNames } from 'helpers/Icon.helper';
import React, { useMemo } from 'react';

export function IconSelectionDropdown(props: Omit<SelectCommonProps<string>, 'options'>) {
  const iconOptions = useMemo(() => {
    return getIconNames()
      .map((icon) => {
        return {
          charCode: getIconCodeByType(icon),
          name: icon,
        };
      })
      .filter(({ charCode }) => {
        return !!charCode;
      })
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(({ name, charCode = 0 }) => ({
        label: (
          <Stack direction="row" gap={1} alignItems="center">
            <i className="icon-">{String.fromCharCode(charCode)}</i>
            {name}
          </Stack>
        ),
        value: name,
      }));
  }, []);

  return (
    <Select
      //@ts-ignore the select component type definition wants a string, but it will render an Element
      options={iconOptions}
      {...props}
    />
  );
}
