import { getWebInstrumentations } from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import { config } from '@grafana/runtime';

enum FARO_ENV {
  DEV = 'development',
  STAGING = 'staging',
  PROD = 'production',
}

function getFaroEnv() {
  const appUrl = new URL(config.appUrl).hostname;
  switch (true) {
    case appUrl.endsWith('grafana-ops.net'):
      return FARO_ENV.STAGING;
    case appUrl.endsWith('grafana.net'):
      return FARO_ENV.PROD;
    case appUrl.endsWith('grafana-dev.net'):
    case appUrl.endsWith('localhost'):
    default:
      return FARO_ENV.DEV;
  }
}

export function getFaroConfig() {
  const env = getFaroEnv();
  return {
    url: 'https://faro-collector-ops-us-east-0.grafana-ops.net/collect/e394b82059b0ddf7c5c70b33c828f22a',
    app: {
      name: 'Asserts',
      version: config.apps['grafana-asserts-app'].version,
      environment: env,
    },
    instrumentations: [...getWebInstrumentations(), new TracingInstrumentation()],
  };
}
