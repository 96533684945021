/*
 * ThresholdHealthList Messages
 *
 * This contains all the text for the ThresholdHealthList component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.ThresholdHealthList';

export default defineMessages({
  groupName: {
    id: `${scope}.groupName`,
    defaultMessage: 'Group',
  },
  emptyList: {
    id: `${scope}.emptyList`,
    defaultMessage: 'No items here.',
  },
  asserts_severity: {
    id: `${scope}.asserts_severity`,
    defaultMessage: 'Severity',
  },
  asserts_alert_category: {
    id: `${scope}.asserts_alert_category`,
    defaultMessage: 'Category',
  },
  group: {
    id: `${scope}.asserts_alert_category`,
    defaultMessage: 'Category',
  },
  for: {
    id: `${scope}.for`,
    defaultMessage: 'For',
  },
  expr: {
    id: `${scope}.expr`,
    defaultMessage: 'Query',
  },
  alert: {
    id: `${scope}.alert`,
    defaultMessage: 'Assertion',
  },
  query: {
    id: `${scope}.query`,
    defaultMessage: 'Query',
  },
});
