import { sortBy } from 'lodash';

export default function formatProperties(properties: {}): Array<Array<any>> {
  return sortBy(
    Object.entries(properties)
      .map(([key, val]) => [
        key
          .replace(/_/g, ' ')
          .replace(/asserts/, '')
          .trim(),
        val,
      ]),
    '[0]',
  );
}
