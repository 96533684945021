import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE_NAME } from './constants';
import { ManageAssertionsContainerState } from 'asserts-types';

const initialState: ManageAssertionsContainerState = {
  start: 'now-24h',
  end: 'now',
};

export const slice = createSlice({
  name: SLICE_NAME,

  initialState,

  reducers: {
    setItemToPopulate: (
      state,
      action: PayloadAction<Record<string, string> | undefined>,
    ) => {
      state.itemToPopulate = action.payload;
    },
    setTimeRange: (
      state,
      action: PayloadAction<{ start: number | string; end: number | string }>,
    ) => {
      const { start, end } = action.payload;
      state.start = start;
      state.end = end;
    },
  },
});

export const { setItemToPopulate, setTimeRange } =
  slice.actions;

export default slice.reducer;
