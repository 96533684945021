/*
 * TopSearch Messages
 *
 * This contains all the text for the TopSearch component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.TopSearch';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Frequently used',
  },
  noSearches: {
    id: `${scope}.noSearches`,
    defaultMessage: 'No searches',
  },
});
