/*
 * ThresholdValueTooltip Messages
 *
 * This contains all the text for the ThresholdValueTooltip component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.ThresholdValueTooltip';

export default defineMessages({
  apply: {
    id: `${scope}.apply`,
    defaultMessage: 'Apply',
  },
  loading: {
    id: `${scope}.loading`,
    defaultMessage: 'Loading...',
  },
  noData: {
    id: `${scope}.noData`,
    defaultMessage: 'No suggested values',
  },
});
