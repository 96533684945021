/*
 * IncidentTimelineChart Messages
 *
 * This contains all the text for the IncidentTimelineChart component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.IncidentTimelineChart';

export default defineMessages({
  open: {
    id: `${scope}.open`,
    defaultMessage: 'Open in Workbench',
  },
});
