import { Metric, ProcessedChartPoint, Threshold } from 'asserts-types';
import { errorColor, successColor } from 'app/constants';

export const generateCrossingRange = (thresholds: Threshold[], metrics: Metric[]) => {
  let crossingRanges: ProcessedChartPoint[] = [];
  const threshold = thresholds?.[0];
  const metric = metrics?.[0];
  if (threshold && metric) {
    threshold.values.forEach((tVal, index) => {
      const metricVal = (metric.values.find((m) => m.time === tVal.time) || {}).value;
      if (tVal.value !== null && tVal.value !== undefined && metricVal !== null && metricVal !== undefined) {
        const realVal = {
          crossingRangeName: tVal.value < metricVal ? successColor : errorColor,
          time: tVal.time,
          crossingRangeValues: [tVal.value, metricVal],
        };
        // push real range value
        crossingRanges.push(realVal);
        // need push null value for removing gaps for another color range
        crossingRanges.push({
          crossingRangeName: realVal.crossingRangeName === errorColor ? successColor : errorColor,
          time: tVal.time,
          crossingRangeValues: [null, null],
        });
      }
    });
    return crossingRanges;
  }
};
