/**
 *
 * AssertionsSummaryBarChart
 *
 */

import React, { memo, FunctionComponent, CSSProperties, useState } from 'react';
import { HealthState, AssertionLogsParam } from 'asserts-types';
import { assertsColors } from 'app/constants';
import moment from 'moment';
import { FORMAT_YEARLESS_lll } from 'app/moment-locales';
import { Button } from '@grafana/ui';
import { setActiveEntityDetails } from 'features/App/App.slice';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'components/Tooltip/Tooltip.component';

interface IProps {
  start: number;
  end: number;
  healthStates: HealthState[];
  expanded?: boolean;
  style?: CSSProperties;
  logConfig: AssertionLogsParam;
}

const AssertionsSummaryBarChart: FunctionComponent<IProps> = ({
  start,
  end,
  healthStates,
  expanded,
  style,
  logConfig,
}) => {
  const dispatch = useDispatch();
  const [openedTooltipIndex, setOpenedTooltipIndex] = useState<number | null>(
    null,
  );
  return (
    <div
      className="relative h-full"
      style={{
        ...style,
        opacity: expanded ? 0.1 : 1,
        transition: 'opacity .2s ease-in',
      }}
    >
      {healthStates.map((item, index) => {
        let left = ((item.start - start) * 100) / (end - start);
        left = left < 0 ? 0 : left;
        const width =
          ((item.end - (left === 0 ? start : item.start)) * 100) /
          (end - start);

        return (
          <Tooltip
            key={index}
            interactive
            enterDelay={100}
            onOpen={() => {
              setOpenedTooltipIndex(index);
            }}
            onClose={() => {
              setOpenedTooltipIndex(null);
            }}
            className="max-w-[500px]"
            content={
              <div className="flex justify-between items-center gap-2">
                <div>
                  {`${moment(item.start).format(FORMAT_YEARLESS_lll)} – ${moment(item.end).format(
                    FORMAT_YEARLESS_lll
                  )}`}
                </div>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={() =>
                    dispatch(
                      setActiveEntityDetails({
                        name: logConfig.entityName,
                        type: logConfig.entityType,
                        scope: logConfig.scope,
                        additionalLabels: logConfig.labels,
                        tab: 'logs',
                        start: item.start,
                        end: item.end,
                      })
                    )
                  }
                >
                  Logs
                </Button>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={() =>
                    dispatch(
                      setActiveEntityDetails({
                        name: logConfig.entityName,
                        type: logConfig.entityType,
                        scope: logConfig.scope,
                        additionalLabels: logConfig.labels,
                        tab: 'traces',
                        start,
                        end,
                      })
                    )
                  }
                >
                  Traces
                </Button>
              </div>
            }
            placement="top"
          >
            <div
              style={{
                background: item.severity && assertsColors[item.severity],
                left: `${left}%`,
                width: `${left + width > 100 ? 100 - left : width}%`,
                zIndex: item.severity === 'critical' ? 2 : 1,
                height: '100%',
                top: 0,
                position: 'absolute',
                borderRadius: 5,
              }}
              key={index}
            >
              <div className={`hover-outline pointer-events-none ${openedTooltipIndex === index ? '!block' : ''}`} />
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default memo(AssertionsSummaryBarChart);
