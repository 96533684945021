import React, { useMemo } from 'react';
import {
  EmbeddedScene,
  SceneFlexItem,
  SceneFlexLayout,
  SceneQueryRunner,
  SceneTimeRange,
  VizPanel,
} from '@grafana/scenes';
import { LegendDisplayMode, TooltipDisplayMode } from '@grafana/ui';
import { setTimeRange } from 'features/App/App.slice';
import useAppTimeRange from 'hooks/useAppTimeRange';
import useDidUpdateEffect from 'hooks/useDidUpdate';
import { useDispatch } from 'react-redux';
import useDebounceValue from 'hooks/useDebounceValue';
import { useMetricsDataSource } from 'hooks/useMetricsDatasource';

interface Props {
  query: string;
}

export default function QueryPreviewScene({ query }: Props) {
  const dispatch = useDispatch();
  const { data: datasource } = useMetricsDataSource();
  const { timeRange } = useAppTimeRange();

  const debouncedQuery = useDebounceValue(query, 400);

  const { scene, $data, $timeRange } = useMemo(() => {
    const $timeRange = new SceneTimeRange({ value: timeRange });

    const $data = new SceneQueryRunner({
      datasource: {
        type: 'prometheus',
        uid: datasource?.uid,
      },
      $timeRange,
      queries: [
        {
          refId: 'query',
          expr: query,
        },
      ],
    });

    const panel = new VizPanel({
      pluginId: 'timeseries',
      title: 'Query preview',
      options: {
        legend: { displayMode: LegendDisplayMode.Table },
        tooltip: { mode: TooltipDisplayMode.Multi },
      },
    });

    panel.onTimeRangeChange = (tr) => {
      dispatch(setTimeRange({ start: tr.from, end: tr.to }));
    };

    const scene = new EmbeddedScene({
      $data,
      body: new SceneFlexLayout({
        children: [
          new SceneFlexItem({
            body: panel,
          }),
        ],
      }),
    });

    return { scene, $data, $timeRange };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDidUpdateEffect(() => {
    if (debouncedQuery) {
      $data.setState({
        queries: [
          {
            refId: 'query',
            expr: debouncedQuery,
          },
        ],
      });

      $data.runQueries();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery]);

  useDidUpdateEffect(() => {
    $timeRange.setState({ value: timeRange });
  }, [
    typeof timeRange.raw.from === 'string' ? timeRange.raw.from : timeRange.raw.from.valueOf(),
    typeof timeRange.raw.to === 'string' ? timeRange.raw.to : timeRange.raw.to.valueOf(),
  ]);

  return <scene.Component model={scene} />;
}
