/**
 *
 * CustomModelRulesList
 *
 */

import React, { memo, FunctionComponent, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import useCustomModelRulesList from '../../hooks/useCustomModelRulesList';
import EditIcon from 'assets/material-icons/edit_FILL0_wght400_GRAD0_opsz24.svg';
import DeleteOutlineIcon from 'assets/material-icons/delete_FILL0_wght400_GRAD0_opsz24.svg';
import { Sort } from 'asserts-types';
import TooltipConfirmationComponent from 'features/Assertions/components/TooltipConfirmation/TooltipConfirmation.component';
import { LoadingPlaceholder, PanelContainer } from '@grafana/ui';
import TableSortButton from 'features/Entities/components/TableSortButton/TableSortButton.component';
import { Tooltip } from 'components/Tooltip/Tooltip.component';
import { IconButton } from 'components/IconButton/IconButton.component';

interface IProps {
  onEditClick: (fileName: string) => void;
  onDeleteClick: (fileName: string) => void;
  deletingRule: boolean;
  fetchingRule: boolean;
}

const CustomModelRulesList: FunctionComponent<IProps> = ({
  onEditClick,
  onDeleteClick,
  fetchingRule,
  deletingRule,
}) => {
  const intl = useIntl();

  const [sort, setSort] = useState<Sort>();

  const {
    isFetching: isLoadingList,
    data: filesList,
    remove: clearListCache,
  } = useCustomModelRulesList(sort);


  useEffect(() => {
    return () => clearListCache();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PanelContainer className="p-6">
      {isLoadingList ? (
        <div className="flex items-center justify-center p-8">
          <LoadingPlaceholder text="Loading" />
        </div>
      ) : (
        <>
          <p className="text-xl">{intl.formatMessage(messages.customModelRules)}</p>
          <table className="w-full">
            <thead>
              <tr>
                <th className="p-4 divider-b">
                  <TableSortButton
                    active={sort?.field === 'name'}
                    direction={sort?.order}
                    onClick={() =>
                      setSort({
                        field: 'name',
                        order: sort?.order === 'asc' ? 'desc' : 'asc',
                      })
                    }
                    className="headerCell"
                  >
                    {intl.formatMessage(messages.fileName)}
                  </TableSortButton>
                </th>
              </tr>
            </thead>
            <tbody>
              {filesList?.length ? (
                filesList.map((fileName) => (
                  <tr key={fileName} className="hover:bg-hover">
                    <td className="p-4 divider-b">
                      <div className="flex items-center">
                        <span className="mr-4">{fileName}.yml</span>
                        <Tooltip content={intl.formatMessage(fetchingRule ? messages.opening : messages.viewEdit)}>
                          <IconButton disabled={deletingRule} onClick={() => onEditClick(fileName)} variant="primary">
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <TooltipConfirmationComponent action={() => onDeleteClick(fileName)}>
                          <IconButton variant="destructive" disabled={fetchingRule}>
                            <DeleteOutlineIcon />
                          </IconButton>
                        </TooltipConfirmationComponent>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <div className="p-4">{intl.formatMessage(messages.emptyList)}</div>
              )}
            </tbody>
          </table>
        </>
      )}
    </PanelContainer>
  );
};

export default memo(CustomModelRulesList);
