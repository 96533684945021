/*
 * BubbleViewSettings Messages
 *
 * This contains all the text for the BubbleViewSettings component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.BubbleViewSettings';

export default defineMessages({
  selectPanels: {
    id: `${scope}.selectPanels`,
    defaultMessage: 'Select properties',
  },
  properties: {
    id: `${scope}.properties`,
    defaultMessage: 'Properties',
  },
  columns: {
    id: `${scope}.columns`,
    defaultMessage: 'Columns',
  },
  all: {
    id: `${scope}.all`,
    defaultMessage: 'All',
  },
  none: {
    id: `${scope}.none`,
    defaultMessage: 'None',
  },
  collapse: {
    id: `${scope}.collapse`,
    defaultMessage: 'collapse all',
  },
  expand: {
    id: `${scope}.expand`,
    defaultMessage: 'expand all',
  },
  searchColumn: {
    id: `${scope}.searchColumn`,
    defaultMessage: 'Search column',
  },
});
