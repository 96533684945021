/*
 * ThresholdHealth Messages
 *
 * This contains all the text for the ThresholdHealth component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.ThresholdHealth';

export default defineMessages({
  customRules: {
    id: `${scope}.customRules`,
    defaultMessage: 'Custom Rules',
  },
  defaultGlobal: {
    id: `${scope}.defaultGlobal`,
    defaultMessage: 'Resource Thresholds',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Threshold Health Rules',
  },
});
