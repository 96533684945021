/**
 *
 * NotificationRequestForm
 *
 */

import React, { FunctionComponent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import assertionsOptions from '../../../../config/notifications_request.json';
import useDidUpdateEffect from 'hooks/useDidUpdate';
import { connect, ConnectedProps } from 'react-redux';

import { useForm, Controller } from 'react-hook-form';
import errorMessages from 'app/errorMessages';
import { NotificationConfig } from 'asserts-types';
import CheckIcon from 'assets/material-icons/done_FILL0_wght400_GRAD0_opsz24.svg';
import CloseIcon from 'assets/material-icons/close_FILL0_wght400_GRAD0_opsz24.svg';
import { setItemToPopulate } from '../../../../ManageAssertions.slice';
import tailwindColors from 'tailwindcss/colors';
import { saveNotification, saveSuppress } from 'services/ManageAssertions.service';
import { Button, Field, Input, PanelContainer, Select, Switch } from '@grafana/ui';
import useJobOptions from 'hooks/useJobOptions';
import useRequestTypeOptions from 'hooks/useRequestTypeOptions';
import useRequestContextOptions from 'hooks/useRequestContextOptions';
import useErrorTypeOptions from 'hooks/useErrorTypeOptions';
import NotificationsChartComponent from 'features/ManageAssertions/components/NotificationsChart/NotificationsChart.component';
import { IconButton } from 'components/IconButton/IconButton.component';
import { useMetricsDataSource } from 'hooks/useMetricsDatasource';

interface IProps {
  onAdd?: (item: NotificationConfig) => void;
  onUpdate?: (item: NotificationConfig) => void;
  onClose?: () => void;
  tableRow?: boolean;
  preloadedValue?: NotificationConfig;
  suppress?: boolean;
  active?: boolean;
}

const connector = connect(
  (state: RootState) => ({
    itemToPopulate: state.manageAssertions.itemToPopulate,
  }),
  { setItemToPopulate }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IForm {
  ruleNameField: string;
  assertionField: string | null;
  jobField: string | null;
  requestTypeField: string | null;
  requestContextField: string | null;
  forField: string | null;
  otherLabelsField: string;
  errorTypeField: string | null;
  receiverField: string | null;
  silenced: boolean;
}

const NotificationRequestForm: FunctionComponent<IProps & PropsFromRedux> = ({
  onAdd,
  tableRow,
  preloadedValue,
  onClose,
  onUpdate,
  suppress,
  setItemToPopulate,
  itemToPopulate,
  active,
}) => {
  const intl = useIntl();
  const { handleSubmit, setValue, watch, control, reset } = useForm<IForm>({
    defaultValues: {
      assertionField: null,
      requestContextField: null,
      requestTypeField: null,
      otherLabelsField: '',
      jobField: null,
      errorTypeField: null,
      ruleNameField: '',
      forField: '',
      receiverField: '',
    },
    mode: 'onChange',
  });

  const [saving, setSaving] = useState(false);

  const watchAssertionField = watch('assertionField');
  const watchJobField = watch('jobField');
  const watchRequestType = watch('requestTypeField');
  const watchRequestContext = watch('requestContextField');
  const watchForField = watch('forField');

  const { data: metricsDatasource } = useMetricsDataSource();

  const { data: jobOptions, isFetching: isFetchingJobOptions } = useJobOptions();
  const { data: requestTypeOptions, isFetching: isFetchingRequestTypeOptions } = useRequestTypeOptions({
    job: watchJobField,
  });

  const { data: requestContextOptions, isFetching: isFetchingRequestContextOptions } = useRequestContextOptions({
    job: watchJobField,
    requestType: watchRequestType,
  });

  const { data: errorTypeOptions, isFetching: isFetchingErrorTypeOptions } = useErrorTypeOptions({
    job: watchJobField,
    requestType: watchRequestType,
  });

  useEffect(() => {
    if (itemToPopulate && !itemToPopulate.notificationRuleName) {
      itemToPopulate.alertname && setValue('assertionField', itemToPopulate.alertname);

      itemToPopulate.job && setValue('jobField', itemToPopulate.job);

      itemToPopulate.asserts_request_type && setValue('requestTypeField', itemToPopulate.asserts_request_type);

      itemToPopulate.asserts_request_context && setValue('requestContextField', itemToPopulate.asserts_request_context);

      itemToPopulate.asserts_error_type && setValue('errorTypeField', itemToPopulate.asserts_error_type);

      setItemToPopulate(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemToPopulate]);

  useEffect(() => {
    if (!preloadedValue) {
      return;
    }
    if (preloadedValue.for) {
      setValue('forField', parseInt(preloadedValue.for, 10).toString());
    }
    if (preloadedValue.name) {
      setValue('ruleNameField', preloadedValue.name);
    }

    if (preloadedValue.matchLabels.asserts_error_type) {
      setValue('errorTypeField', preloadedValue.matchLabels.asserts_error_type);
    }

    if (preloadedValue.matchLabels.job) {
      setValue('jobField', preloadedValue?.matchLabels.job);
    }
    if (preloadedValue.matchLabels.additional_labels) {
      setValue('otherLabelsField', preloadedValue.matchLabels.additional_labels);
    }
    if (preloadedValue.matchLabels.asserts_request_type) {
      setValue('requestTypeField', preloadedValue.matchLabels.asserts_request_type);
    }
    if (preloadedValue.matchLabels.asserts_request_context) {
      setValue('requestContextField', preloadedValue.matchLabels.asserts_request_context);
    }
    if (preloadedValue.matchLabels.alertname) {
      setValue('assertionField', preloadedValue.matchLabels.alertname);
    }
    if (preloadedValue.alertLabels?.asserts_receiver) {
      setValue('receiverField', preloadedValue.alertLabels?.asserts_receiver);
    }
    setValue('silenced', Boolean(preloadedValue.silenced));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDidUpdateEffect(() => {
    if (!watchRequestType) {
      setValue('requestContextField', null);
      setValue('errorTypeField', null);
    }
  }, [watchJobField, watchRequestType, metricsDatasource.uid]);

  const onSubmit = handleSubmit((data) => {
    setSaving(true);

    const alertname = data.assertionField;
    if (!alertname) {
      return;
    }
    const job = data.jobField || undefined;
    const asserts_request_type = data.requestTypeField || undefined;
    const asserts_request_context = data.requestContextField || undefined;
    const asserts_error_type = data.errorTypeField || undefined;
    const additional_labels = data.otherLabelsField || undefined;
    const asserts_receiver = data.receiverField || undefined;

    const matchLabels = {
      alertname,
      job,
      asserts_request_type,
      asserts_request_context,
      asserts_error_type,
      additional_labels,
    };

    const alertLabels = {
      asserts_receiver,
    };

    const itemToSave = {
      name: data.ruleNameField.toString(),
      matchLabels,
      alertLabels,
      for: data.forField ? `${data.forField}m` : undefined,
      silenced: data.silenced,
    };

    (suppress ? saveSuppress(itemToSave) : saveNotification(itemToSave))
      .then(() => {
        if (preloadedValue) {
          onClose && onClose();
          onUpdate && onUpdate(itemToSave);
        } else {
          onAdd && onAdd(itemToSave);
          reset();
        }
      })
      .finally(() => setSaving(false));
  });

  const renderRuleNameControl = () => (
    <Controller
      name="ruleNameField"
      control={control}
      rules={{ required: intl.formatMessage(errorMessages.required) }}
      render={({ field, fieldState }) => (
        <Field
          invalid={!!fieldState.error}
          error={fieldState.error?.message}
          label={intl.formatMessage(messages.ruleName)}
        >
          <Input {...field} placeholder="Enter rule name" disabled={!!preloadedValue || saving} />
        </Field>
      )}
    />
  );

  const renderAssertionControl = () => (
    <Controller
      name="assertionField"
      control={control}
      rules={{ required: intl.formatMessage(errorMessages.required) }}
      render={({ field, fieldState }) => (
        <Field
          invalid={!!fieldState.error}
          error={fieldState.error?.message}
          label={intl.formatMessage(messages.assertion)}
        >
          <Select
            {...field}
            onChange={(v) => field.onChange(v?.value || '')}
            options={assertionsOptions?.map((o) => ({ label: o, value: o })) || []}
            disabled={saving}
            allowCustomValue
            isClearable
          />
        </Field>
      )}
    />
  );

  const renderJobControl = () => (
    <Controller
      name="jobField"
      control={control}
      render={({ field, fieldState }) => (
        <Field invalid={!!fieldState.error} error={fieldState.error?.message} label={intl.formatMessage(messages.job)}>
          <Select
            {...field}
            onChange={(v) => field.onChange(v?.value || '')}
            options={jobOptions?.map((o) => ({ label: o, value: o })) || []}
            disabled={saving}
            virtualized
            isLoading={isFetchingJobOptions}
            allowCustomValue
            isClearable
          />
        </Field>
      )}
    />
  );

  const renderRequestTypeControl = () => (
    <Controller
      name="requestTypeField"
      control={control}
      render={({ field }) => (
        <Field label={intl.formatMessage(messages.requestType)}>
          <Select
            {...field}
            onChange={(v) => field.onChange(v?.value || '')}
            options={requestTypeOptions?.map((o) => ({ label: o, value: o })) || []}
            virtualized
            disabled={saving}
            isLoading={isFetchingRequestTypeOptions}
            allowCustomValue
            isClearable
          />
        </Field>
      )}
    />
  );

  const renderRequestContextControl = () => (
    <Controller
      name="requestContextField"
      control={control}
      render={({ field }) => (
        <Field label={intl.formatMessage(messages.requestContext)}>
          <Select
            {...field}
            onChange={(v) => field.onChange(v?.value || '')}
            options={requestContextOptions?.map((o) => ({ label: o, value: o })) || []}
            virtualized
            disabled={saving}
            isLoading={isFetchingRequestContextOptions}
            allowCustomValue
            isClearable
          />
        </Field>
      )}
    />
  );

  const renderErrorTypeControl = () => (
    <Controller
      name="errorTypeField"
      control={control}
      render={({ field }) => (
        <Field label={intl.formatMessage(messages.errorType)}>
          <Select
            {...field}
            onChange={(v) => field.onChange(v?.value || '')}
            options={errorTypeOptions?.map((o) => ({ label: o, value: o })) || []}
            virtualized
            disabled={saving}
            isLoading={isFetchingErrorTypeOptions}
            allowCustomValue
            isClearable
          />
        </Field>
      )}
    />
  );

  const renderReceiver = () => (
    <Controller
      name="receiverField"
      control={control}
      render={({ field }) => (
        <Field label={intl.formatMessage(messages.receiver)}>
          <Input {...field} value={field.value || ''} placeholder="Enter value" disabled={saving} />
        </Field>
      )}
    />
  );

  const renderSilenceSwitch = () => (
    <Controller
      name="silenced"
      control={control}
      render={({ field }) => <Switch value={!field.value} onClick={() => field.onChange(!field.value)} />}
    />
  );

  const renderForControl = () => (
    <Controller
      name="forField"
      control={control}
      render={({ field }) => (
        <Field label={intl.formatMessage(messages.for)}>
          <Input
            {...field}
            value={field.value || ''}
            placeholder="Value"
            disabled={saving}
            type="number"
            suffix="min"
          />
        </Field>
      )}
    />
  );

  const renderOtherLabelsControl = () => (
    <Controller
      name="otherLabelsField"
      control={control}
      render={({ field }) => (
        <Field label={intl.formatMessage(messages.otherLabels)}>
          <Input {...field} value={field.value || ''} placeholder="Enter value" disabled={saving} />
        </Field>
      )}
    />
  );

  const labelsFromForm = {
    ...(watchAssertionField ? { alertname: watchAssertionField } : {}),
    ...(watchJobField ? { job: watchJobField } : {}),
    ...(watchRequestType ? { asserts_request_type: watchRequestType } : {}),
    ...(watchRequestContext ? { asserts_request_context: watchRequestContext } : {}),
  };

  if (tableRow) {
    return (
      <>
        <tr style={active ? { backgroundColor: tailwindColors.yellow[50] } : undefined}>
          <td className="p-4">{renderRuleNameControl()}</td>
          <td className="p-4">{renderAssertionControl()}</td>
          <td className="p-4">{renderJobControl()}</td>
          <td className="p-4">{renderRequestTypeControl()}</td>
          <td className="p-4">{renderRequestContextControl()}</td>
          <td className="p-4">{renderErrorTypeControl()}</td>
          {!suppress && <td className="p-4">{renderReceiver()}</td>}
          {!suppress && <td className="p-4">{renderForControl()}</td>}
          <td className="p-4">{renderOtherLabelsControl()}</td>
          {!suppress && <td className="p-4">{renderSilenceSwitch()}</td>}
          <td className="p-4">
            <div className="flex items-center">
              <IconButton variant="primary" disabled={saving} onClick={() => onSubmit()}>
                <CheckIcon />
              </IconButton>
              <IconButton variant="primary" onClick={onClose} disabled={saving}>
                <CloseIcon />
              </IconButton>
            </div>
          </td>
        </tr>
        {!suppress && (
          <tr>
            <td colSpan={11}>
              <NotificationsChartComponent
                labels={labelsFromForm}
                show={Boolean(watchAssertionField && watchJobField)}
                continuesFor={watchForField || undefined}
                assertion={watchAssertionField || undefined}
              />
            </td>
          </tr>
        )}
      </>
    );
  }

  return (
    <PanelContainer className="mt-8 p-6">
      <p className="mb-6 text-xl">{intl.formatMessage(suppress ? messages.addTitleSuppress : messages.addTitle)}</p>
      <form onSubmit={onSubmit}>
        <div className="flex items-start gap-2.5 [&_>_div]:w-0 [&_>_div]:grow">
          {renderJobControl()}
          {renderAssertionControl()}
          {watchAssertionField === 'ErrorRatioBreach' && renderErrorTypeControl()}
          {renderRequestTypeControl()}
          {renderRequestContextControl()}
          {renderOtherLabelsControl()}
          {!suppress && renderForControl()}
          {!suppress && renderReceiver()}
          {renderRuleNameControl()}
        </div>
        {!suppress && (
          <NotificationsChartComponent
            labels={labelsFromForm}
            show={Boolean(watchAssertionField && watchJobField)}
            continuesFor={watchForField || undefined}
            assertion={watchAssertionField || undefined}
          />
        )}
        <Button color="primary" type="submit" disabled={saving}>
          {intl.formatMessage(messages.addNew)}
        </Button>
      </form>
    </PanelContainer>
  );
};

export default connector(NotificationRequestForm);
