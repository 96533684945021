import { HierarchicalAssertion } from 'asserts-types';
import { useMemo } from 'react';
import { unionBy, omit } from 'lodash';

export const collectLabelsRecursively = (
  assertions: Pick<HierarchicalAssertion, 'nestedTimelines' | 'labels'>[],
  result: Record<string, string[]> = {},
): Record<string, string[]> => {
  if (assertions.length) {
    assertions.forEach((item) => {
      Object.keys(item.labels).forEach((labelKey) => {
        if (result[labelKey]) {
          result[labelKey] = unionBy(result[labelKey], [item.labels[labelKey]]);
        } else {
          result[labelKey] = [item.labels[labelKey]];
        }
      });
      collectLabelsRecursively(item.nestedTimelines, result);
    });
  }
  return result;
};

export default function useAssertionsFilterValues(
  assertions: HierarchicalAssertion[] | undefined,
  searchQuery?: string,
) {
  let filterValues = useMemo(
    () =>
      collectLabelsRecursively(
        assertions?.map((entity) => ({
          labels: entity.labels,
          nestedTimelines: entity.nestedTimelines,
        })) || [],
      ),
    [assertions],
  );

  if (searchQuery) {
    Object.entries(filterValues).forEach(([key, values]) => {
      if (key.toLocaleLowerCase().includes(searchQuery.toLowerCase())) {
        return;
      }
      const filteredValues = values.filter((v) =>
        v.toLowerCase().includes(searchQuery.toLowerCase()),
      );
      if (filteredValues.length) {
        filterValues = { ...filterValues, [key]: filteredValues };
      } else {
        filterValues = omit(filterValues, key);
      }
    });
  }

  return filterValues;
}
