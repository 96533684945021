/*
 * ThresholdTopMenu Messages
 *
 * This contains all the text for the ThresholdTopMenu component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.ThresholdTopMenu';

export default defineMessages({
  fileSaved: {
    id: `${scope}.fileSaved`,
    defaultMessage: 'File was saved successfully',
  },
  fileSaveError: {
    id: `${scope}.fileSaveError`,
    defaultMessage: 'Error while saving a file ',
  },
  opening: {
    id: `${scope}.oepning`,
    defaultMessage: 'Opening...',
  },
  viewFile: {
    id: `${scope}.viewFile`,
    defaultMessage: 'View/Edit YML file',
  },
  threshold: {
    id: `${scope}.threshold`,
    defaultMessage: 'Threshold',
  },
  request: {
    id: `${scope}.request`,
    defaultMessage: 'Request',
  },
  resource: {
    id: `${scope}.resource`,
    defaultMessage: 'Resource',
  },
  search: {
    id: `${scope}.search`,
    defaultMessage: 'Search rule',
  },
  health: {
    id: `${scope}.health`,
    defaultMessage: 'Health',
  },
  help: {
    id: `${scope}.help`,
    defaultMessage: 'Thresholds help',
  },
  
});
