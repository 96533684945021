/**
 *
 * ThresholdHealth
 *
 */

import React, { FunctionComponent, useState, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import ThresholdTopMenu from '../ThresholdTopMenu/ThresholdTopMenu.component';
import { AssertionGroupRule, Sort } from 'asserts-types';
import { connect, ConnectedProps } from 'react-redux';

import { setItemToPopulate } from '../../ManageAssertions.slice';
import ThresholdHealthForm from './components/ThresholdHealthForm/ThresholdHealthForm.component';
import ThresholdHealthListComponent from './components/ThresholdHealthList/ThresholdHealthList.component';
import healthFields from '../../config/threshold_health.json';
import { fetchFailureRules } from 'services/ManageAssertions.service';
import { orderBy } from 'lodash';

interface IProps {}

const connector = connect(
  (state: RootState) => ({
    itemToPopulate: state.manageAssertions.itemToPopulate,
  }),
  {
    setItemToPopulate,
  }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ThresholdHealth: FunctionComponent<IProps & PropsFromRedux> = ({ itemToPopulate, setItemToPopulate }) => {
  const intl = useIntl();
  const [searchQuery, setSearchQuery] = useState('');

  const [customList, setCustomList] = useState<AssertionGroupRule[]>([]);
  const [customSort, setCustomSort] = useState<Sort | null>(null);

  const [fetchingList, setFetchingList] = useState(true);

  useEffect(() => {
    fetchLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (itemToPopulate && itemToPopulate.alertgroup?.includes('custom-')) {
      setSearchQuery(itemToPopulate.alertname || '');
      setItemToPopulate(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemToPopulate]);

  const fetchLists = () => {
    setFetchingList(true);
    fetchFailureRules().then((res) => {
      setCustomList(res);
      setFetchingList(false);
    });
  };

  const handleAddCustomItem = (item: AssertionGroupRule) => {
    setCustomList([item].concat(customList));
  };

  const handleUpdateCustomItem = (item: AssertionGroupRule) => {
    setCustomList(customList.map((r) => (r.alert === item.alert ? item : r)));
  };

  const handleDeleteCustomItem = (item: AssertionGroupRule) => {
    setCustomList(customList.filter((r) => r.alert !== item.alert));
  };

  const customListProcessed = useMemo(
    () =>
      orderBy(
        customList.filter((item) =>
          Object.values({
            ...item,
            ...(item.labels || {}),
          }).some((item) => typeof item === 'string' && item.toLowerCase().includes(searchQuery.toLowerCase()))
        ),
        customSort?.field,
        customSort?.order
      ),
    [customList, customSort?.field, customSort?.order, searchQuery]
  );

  return (
    <div className="h-full">
      <ThresholdTopMenu onSearchChange={setSearchQuery} searchQuery={searchQuery} onYmlFileChange={fetchLists} />
      <div>
        <ThresholdHealthForm onAdd={handleAddCustomItem} />
        <ThresholdHealthListComponent
          title={intl.formatMessage(messages.customRules)}
          fields={healthFields}
          list={customListProcessed}
          onUpdate={handleUpdateCustomItem}
          onDelete={handleDeleteCustomItem}
          fetchingList={fetchingList}
          onSort={setCustomSort}
          orderBy={customSort}
        />
      </div>
    </div>
  );
};

export default connector(ThresholdHealth);
