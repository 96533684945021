import { defineMessages } from 'react-intl';

export const scope = 'components.NotificationRequestForm';

export default defineMessages({
  ruleName: {
    id: `${scope}.ruleName`,
    defaultMessage: 'Rule Name',
  },
  assertion: {
    id: `${scope}.assertion`,
    defaultMessage: 'Assertion',
  },
  job: {
    id: `${scope}.job`,
    defaultMessage: 'Job',
  },
  requestType: {
    id: `${scope}.requestType`,
    defaultMessage: 'Request Type',
  },
  requestContext: {
    id: `${scope}.requestContext`,
    defaultMessage: 'Request Context',
  },
  value: {
    id: `${scope}.value`,
    defaultMessage: 'Value',
  },
  addNew: {
    id: `${scope}.addNew`,
    defaultMessage: 'Add new',
  },
  otherLabels: {
    id: `${scope}.otherLabels`,
    defaultMessage: 'Other Labels',
  },
  errorType: {
    id: `${scope}.errorType`,
    defaultMessage: 'Error Type',
  },
  for: {
    id: `${scope}.for`,
    defaultMessage: 'For',
  },
  receiver: {
    id: `${scope}.receiver`,
    defaultMessage: 'Receiver',
  },
  addTitle: {
    id: `${scope}.addTitle`,
    defaultMessage: 'Add Request Notification',
  },
  addTitleSuppress: {
    id: `${scope}.addTitle`,
    defaultMessage: 'Add Request Suppression',
  },
});
