/*
 * AssertionsSortBy Messages
 *
 * This contains all the text for the AssertionsSortBy component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.AssertionsSortBy';

export default defineMessages({
  score: {
    id: `${scope}.score`,
    defaultMessage: 'Score',
  },
  time: {
    id: `${scope}.time`,
    defaultMessage: 'Time',
  },
  sortBy: {
    id: `${scope}.sortBy`,
    defaultMessage: 'Sort By:',
  },
});
