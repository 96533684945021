/**
 *
 * EnvSiteTag
 *
 */

import { useTheme2 } from '@grafana/ui';
import { Scope } from 'asserts-types';
import React, { FunctionComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';


interface IProps {
  scope: Scope | undefined;
  color?: string;
  fontSize?: number;
}

const connector = connect(
  (state: RootState) => ({
    envColorsMap: state.app.envColorsMap,
    showEnvSiteTag: state.app.showEnvSiteTag,
  }),
  {},
);

type PropsFromRedux = ConnectedProps<typeof connector>;

const EnvSiteTag: FunctionComponent<IProps & PropsFromRedux> = ({
  scope,
  envColorsMap,
  color,
  showEnvSiteTag,
  fontSize = 9,
}) => {
  const theme = useTheme2();
  if (!envColorsMap || !showEnvSiteTag) {
    return null;
  }

  return (
    <div
      style={{
        fontSize,
        color: color ? color : theme.colors.text.primary,
      }}
    >
      <div className="flex items-start leading-none">
        {scope?.env && (
          <span
            className="rounded-full w-[7px] h-[7px] block mr-[5px] mt-[1px]"
            style={{
              background:
                scope.env && envColorsMap ? envColorsMap[scope.env] : '#000000',
            }}
          ></span>
        )}
        <div>
          <p className="text-left m-0">
            {scope?.env ? scope.env : ''} {scope?.site ? `(${scope.site})` : ''}
          </p>
          {scope?.namespace && <p className="text-left m-0">{scope.namespace}</p>}
        </div>
      </div>
    </div>
  );
};

export default connector(EnvSiteTag);
