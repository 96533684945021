
import React from 'react';

export default function NullZeroIcon() {
  return (
    <svg className="svg-icon" viewBox="0 0 25 25" fill="none">
      <path d="M2.25 12.2115H9.25" stroke="currentColor" strokeWidth="2" />
      <path d="M15.25 12.2115H22.75" stroke="currentColor" strokeWidth="2" />
      <path
        d="M20.97 8.46155C20.259 5.80055 18.733 3.96155 16.97 3.96155C10.292 3.96155 14.775 19.7115 8.1 19.7115C7.71999 19.7098 7.34481 19.6263 7 19.4665"
        stroke="currentColor"
        strokeWidth="2"
        fill='none'
      />
      <path
        d="M8.05859 19.7657C8.05859 20.8221 7.82048 21.6367 7.34424 22.2096C6.87158 22.7825 6.20378 23.069 5.34082 23.069C4.4707 23.069 3.79753 22.7807 3.32129 22.2042C2.84505 21.6277 2.60693 20.8149 2.60693 19.7657V18.337C2.60693 17.2807 2.84326 16.4661 3.31592 15.8932C3.79215 15.3203 4.46354 15.0338 5.33008 15.0338C6.19661 15.0338 6.868 15.3221 7.34424 15.8986C7.82048 16.4751 8.05859 17.2897 8.05859 18.3424V19.7657ZM6.24854 18.1061C6.24854 17.5439 6.17513 17.125 6.02832 16.8492C5.88151 16.5699 5.64876 16.4303 5.33008 16.4303C5.01855 16.4303 4.79118 16.5592 4.64795 16.817C4.5083 17.0748 4.43311 17.4651 4.42236 17.9879V19.986C4.42236 20.5696 4.49577 20.9975 4.64258 21.2697C4.78939 21.5382 5.02214 21.6725 5.34082 21.6725C5.64876 21.6725 5.87614 21.5418 6.02295 21.2804C6.16976 21.0154 6.24495 20.6018 6.24854 20.0397V18.1061Z"
        fill="currentColor"
      />
    </svg>
  );
}
