/*
 * SloSortBy Messages
 *
 * This contains all the text for the SloSortBy component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.SloSortBy';

export default defineMessages({
  numberOfIncidents: {
    id: `${scope}.numberOfIncidents`,
    defaultMessage: 'Incidents in Window',
  },
  actual: {
    id: `${scope}.target`,
    defaultMessage: 'Actual',
  },
  budgetUsed: {
    id: `${scope}.budgetUsed`,
    defaultMessage: 'Budget Used',
  },
  recentBudgetUsage: {
    id: `${scope}.recentBudgetUsage`,
    defaultMessage: 'Recent Budget Usage',
  },
  incidentStatus: {
    id: `${scope}.incidentStatus`,
    defaultMessage: 'Current Incident Status',
  },
  sortBy: {
    id: `${scope}.sortBy`,
    defaultMessage: 'Sort by:',
  },
});
