import React, {
  CSSProperties,
  FunctionComponent,
  memo,
  useMemo,
  useState,
} from 'react';
import { Cluster, EntityProperties, HealthState, Scope } from 'asserts-types';
import { assertsColors } from '../../app/constants';
import moment from 'moment';
import GroupedAssertionSummary from '../GroupedAssertionSummary/GroupedAssertionSummary.component';
import { FORMAT_YEARLESS_lll } from '../../app/moment-locales';
import { getEntityTypeLabel } from '../../helpers/Entity.helper';
import { twMerge } from 'tailwind-merge';
import { Button } from '@grafana/ui';
import { useDispatch } from 'react-redux';
import { setActiveEntityDetails } from 'features/App/App.slice';
import { Tooltip } from 'components/Tooltip/Tooltip.component';

interface IProps {
  start: number;
  end: number;
  healthStates: HealthState[];
  clusters: Cluster[];
  className?: string;
  infoEventsToBottom?: boolean;
  expanded?: boolean;
  labels?: Record<string, string>;
  properties?: EntityProperties;
  rootEntityName: string;
  rootEntityType: string;
  entityName?: string;
  entityType?: string;
  assertionNames?: string[];
  scope: Scope | undefined;
  disableTooltips?: boolean;
  height?: number;
  style?: CSSProperties;
}

const isHealthState = (item: HealthState | Cluster): item is HealthState => {
  return (item as HealthState).severity !== undefined;
};

const AssertionsTimelineChart: FunctionComponent<IProps> = ({
  healthStates,
  start,
  end,
  className,
  infoEventsToBottom,
  expanded,
  clusters,
  labels,
  rootEntityName,
  rootEntityType,
  entityName,
  assertionNames,
  entityType,
  scope,
  disableTooltips,
  properties,
  height,
  style,
}) => {
  const dispatch = useDispatch();
  const [openedTooltipIndex, setOpenedTooltipIndex] = useState<number | null>(null);
  let tooltips: (Cluster | HealthState)[] = clusters.length ? clusters : healthStates;
  if (disableTooltips) {
    tooltips = [];
  }

  const rowAssertionSummary = useMemo(() => {
    const processedEntityType = getEntityTypeLabel(entityType || '', scope, properties);
    return `${
      assertionNames && assertionNames.length > 1
        ? `${assertionNames[0]}::${assertionNames[assertionNames.length - 1]} on ${processedEntityType} ${entityName}`
        : assertionNames && assertionNames.length === 1
        ? `${assertionNames[0]} on ${entityType} ${entityName}`
        : ``
    }`;
  }, [entityName, assertionNames, entityType, scope, properties]);

  return (
    <div
      className={twMerge(`relative w-full ${className ? className : ''}`)}
      style={{
        minHeight: expanded ? '100%' : height || 15,
        opacity: expanded ? 0.1 : 1,
        ...(style || {}),
      }}
    >
      {healthStates.map((item, index) => {
        let left = ((item.start - start) * 100) / (end - start);
        left = left < 0 ? 0 : left;
        const width = ((item.end - (left === 0 ? start : item.start)) * 100) / (end - start);

        return (
          <div
            style={{
              background: item.severity && assertsColors[item.severity],
              left: `${left}%`,
              width: `${left + width > 100 ? 100 - left : width}%`,
              zIndex: item.severity === 'critical' ? 2 : 1,
              height: item.severity === 'info' && infoEventsToBottom ? 5 : 'auto',
              top: item.severity === 'info' && infoEventsToBottom ? 'auto' : 0,
              bottom: item.severity === 'info' && infoEventsToBottom ? -11 : 0,
            }}
            className="rounded inset-y-0 absolute"
            key={`${index}-${item.start}`}
          ></div>
        );
      })}
      {tooltips.map((item, index) => {
        let left = ((item.start - start) * 100) / (end - start);
        left = left < 0 ? 0 : left;

        const width = ((item.end - (left === 0 ? start : item.start)) * 100) / (end - start);

        const clusterStates = healthStates.filter((hs) => hs.start >= item.start && hs.end <= item.end);

        const hasInfo = isHealthState(item)
          ? item.severity === 'info'
          : Boolean(clusterStates.find((state) => state.severity === 'info'));

        const hasOnlyInfo = isHealthState(item)
          ? item.severity === 'info'
          : clusterStates.every((state) => state.severity === 'info');

        return (
          <Tooltip
            className="max-w-[500px]"
            onOpen={() => {
              setOpenedTooltipIndex(index);
            }}
            onClose={() => {
              setOpenedTooltipIndex(null);
            }}
            content={
              <div
                className="relative min-h-[30px] flex items-start content-start"
                onMouseDown={(e) => e.stopPropagation()}
              >
                <div className="w-full">
                  <div className="flex justify-between items-center gap-2 pb-[4px]">
                    <strong>
                      {`${moment(item.start).format(FORMAT_YEARLESS_lll)} – ${moment(item.end).format(
                        FORMAT_YEARLESS_lll
                      )}`}
                    </strong>
                    <div className="flex items-center gap-2">
                      <Button
                        size="sm"
                        variant="secondary"
                        onClick={() =>
                          dispatch(
                            setActiveEntityDetails({
                              name: rootEntityName,
                              type: rootEntityType,
                              scope: scope,
                              additionalLabels: labels,
                              tab: 'logs',
                              start,
                              end,
                            })
                          )
                        }
                      >
                        Logs
                      </Button>
                      <Button
                        size="sm"
                        variant="secondary"
                        onClick={() =>
                          dispatch(
                            setActiveEntityDetails({
                              name: rootEntityName,
                              type: rootEntityType,
                              scope: scope,
                              additionalLabels: labels,
                              tab: 'traces',
                              start,
                              end,
                            })
                          )
                        }
                      >
                        Traces
                      </Button>
                      {/* <ViewLogsButtonComponent
                        className="whitespace-nowrap ml-2.5 p-0 px-3 rounded-md"
                        item={{
                          entityName: rootEntityName,
                          entityType: rootEntityType,
                          entityProperties: properties || {},
                          labels: labels || {},
                          name: rootEntityName,
                          scope,
                        }}
                        type="button"
                        start={item.start}
                        end={item.end}
                      />
                      <ViewTracesButtonComponent
                        className="whitespace-nowrap ml-2.5 p-0 px-3 rounded-md"
                        item={{
                          entityName: rootEntityName,
                          entityType: rootEntityType,
                          labels: labels || {},
                          name: rootEntityName,
                          scope,
                        }}
                        type="button"
                        start={item.start}
                        end={item.end}
                        params={{ threshold: [], values: [] }}
                      /> */}
                    </div>
                  </div>
                  {!!rowAssertionSummary && (
                    <div className="break-words w-full max-w-full pt-[4px] text-sm">{rowAssertionSummary}</div>
                  )}
                  {'assertionSummaries' in item && (
                    <GroupedAssertionSummary assertionSummaries={item.assertionSummaries} />
                  )}
                </div>
              </div>
            }
            key={`${index}-${item.start}`}
            placement="top"
            interactive
          >
            <div
              style={{
                left: `${left}%`,
                width: `${left + width > 100 ? 100 - left : width}%`,
                zIndex: 999,
                top: hasOnlyInfo && infoEventsToBottom ? 'auto' : 0,
                height: hasOnlyInfo && infoEventsToBottom ? 5 : 'auto',
                bottom: hasInfo && infoEventsToBottom ? -11 : 0,
              }}
              className="rounded inset-y-0 absolute"
            >
              <div className={`hover-outline pointer-events-none ${openedTooltipIndex === index ? '!block' : ''}`} />
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default memo(AssertionsTimelineChart);
