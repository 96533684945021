import React from 'react';
import { getIconStyles } from './utils';

export default function WbOpenIcon(props: any) {
  return (
    <svg className={getIconStyles()} viewBox="0 0 21 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75195 15.8337V4.16699H11.0674V5.83366H8.86862V9.16699H11.0674V10.8337H8.86029V14.167H11.6353V10.8352H13.3103V14.167H16.0853V10.8352H17.752V15.8337H2.75195ZM7.19362 9.16699V5.83366H4.41862V9.16699H7.19362ZM4.41862 10.8337V14.167H7.19362V10.8337H4.41862Z"
        fill="currentColor"
      />
      <path
        d="M13.627 9.47949L16.0853 7.02116V8.87533H17.752V4.16699H13.0436V5.83366H14.9186L12.4603 8.29199L13.627 9.47949Z"
        fill="currentColor"
      />
    </svg>
  );
}
