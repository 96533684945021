import LegendItemIcon from 'icons/LegendItemIcon';
import React from 'react';
import NotificationsNoneIcon from 'assets/material-icons/notifications_FILL0_wght400_GRAD0_opsz24.svg';
import AddchartRoundedIcon from 'assets/material-icons/add_chart_FILL0_wght400_GRAD0_opsz24.svg';
import LabelOutlinedIcon from 'assets/material-icons/label_FILL0_wght400_GRAD0_opsz24.svg';
import PostAddIcon from 'assets/material-icons/post_add_FILL0_wght400_GRAD0_opsz24.svg';
import IndeterminateCheckBoxRoundedIcon from 'assets/material-icons/indeterminate_check_box_FILL0_wght400_GRAD0_opsz24.svg';

import CompressIcon from 'icons/CompressIcon';
import { ROUTES } from 'global-constants';
import { NavLink } from 'react-router-dom';
import { useTheme2 } from '@grafana/ui';
import { AssertsFeatures, isFeatureToggleEnabled } from 'utils/feature-toggles';

const menuItems = [
  {
    icon: <LegendItemIcon className="svg-icon" />,
    text: 'Threshold',
    navLink: ROUTES.RULES_THRESHOLD,
  },
  {
    icon: <NotificationsNoneIcon className="svg-icon" />,
    text: 'Notify',
    navLink: ROUTES.RULES_NOTIFICATIONS,
  },
  {
    icon: <CompressIcon className="svg-icon" />,
    text: 'Suppress',
    navLink: ROUTES.RULES_SUPPRESS,
  },
  {
    icon: <AddchartRoundedIcon className="svg-icon" />,
    text: 'Entity & Relation',
    navLink: ROUTES.RULES_CUSTOM_MODEL,
  },
  ...(isFeatureToggleEnabled(AssertsFeatures.RELABEL_RULES_UI)
    ? [
        {
          icon: <LabelOutlinedIcon className="svg-icon" />,
          text: 'Relabeling',
          navLink: ROUTES.RULES_RELABEL,
        },
      ]
    : []),
  {
    icon: <PostAddIcon className="svg-icon" />,
    text: 'Bring Your Own',
    navLink: ROUTES.RULES_ADD,
  },
  {
    icon: <IndeterminateCheckBoxRoundedIcon className="svg-icon" />,
    text: 'Disable Rules',
    navLink: ROUTES.RULES_DISABLE,
  },
];

export default function RulesMenu() {
  const theme = useTheme2();

  return (
    <ul className="list-none w-[170px] shrink-0 sticky top-12">
      {menuItems.map((item) => (
        <li key={item.text}>
          <NavLink
            to={item.navLink}
            className={({ isActive }) =>
              `flex items-center gap-1.5 hover:underline p-2 rounded ${isActive ? '[&_svg]:text-orange-500' : ''}`
            }
            style={({ isActive }) => (isActive ? { background: theme.colors.action.selected } : undefined)}
          >
            {item.icon} {item.text}
          </NavLink>
        </li>
      ))}
    </ul>
  );
}
