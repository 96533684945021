import { APP_PLUGIN_IDS } from 'app/constants';
import { Entity } from 'asserts-types';
import { isAppEnabled } from 'utils/app-plugin';

const SERVICE_TYPES_BLACKLIST = ['postgres'];

export function isAppO11yAvailable(entity: Entity | undefined) {
  if (entity?.properties.service_type && SERVICE_TYPES_BLACKLIST.includes(entity.properties.service_type.toString())) {
    return false;
  }

  return (
    entity?.type === 'Service' &&
    !!entity?.properties.otel_namespace &&
    !!entity?.properties.otel_service &&
    isAppEnabled(APP_PLUGIN_IDS.APP_O11Y_APP)
  );
}
