import React from 'react';

export default function RelationIcon() {
  return (
    <svg className="svg-icon" viewBox="0 0 24 24">
      <path
        d="M18.6973 18C17.0522 19.8403 14.6637 21 12 21C9.33633 21 6.9479 19.8403 5.30273 18"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M18.6973 6C17.0522 4.15971 14.6637 3 12 3C9.33633 3 6.9479 4.15971 5.30273 6"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M3 13H2V11H3V13ZM9 11H10V13H9V11ZM3.29289 9.29289L4 8.58579L5.41421 10L4.70711 10.7071L3.29289 9.29289ZM2 12L1.29289 12.7071L0.585787 12L1.29289 11.2929L2 12ZM4.70711 13.2929L5.41421 14L4 15.4142L3.29289 14.7071L4.70711 13.2929ZM3 11H9V13H3V11ZM4.70711 10.7071L2.70711 12.7071L1.29289 11.2929L3.29289 9.29289L4.70711 10.7071ZM2.70711 11.2929L4.70711 13.2929L3.29289 14.7071L1.29289 12.7071L2.70711 11.2929Z"
        fill="currentColor"
      />
      <path
        d="M21 11H22V13H21V11ZM15 13H14V11H15V13ZM20.7071 14.7071L20 15.4142L18.5858 14L19.2929 13.2929L20.7071 14.7071ZM22 12L22.7071 11.2929L23.4142 12L22.7071 12.7071L22 12ZM19.2929 10.7071L18.5858 10L20 8.58579L20.7071 9.29289L19.2929 10.7071ZM21 13H15V11H21V13ZM19.2929 13.2929L21.2929 11.2929L22.7071 12.7071L20.7071 14.7071L19.2929 13.2929ZM21.2929 12.7071L19.2929 10.7071L20.7071 9.29289L22.7071 11.2929L21.2929 12.7071Z"
        fill="currentColor"
      />
    </svg>
  );
}
