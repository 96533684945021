import React, {
  FunctionComponent,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { CustomRule, Sort } from 'asserts-types';
import CustomRulesListComponent from '../CustomRulesList/CustomRulesList.component';
import CustomRulesFormComponent from './components/CustomRulesForm/CustomRulesForm.component';
import AddRuleTopMenu from '../AddRuleTopMenu/AddRuleTopMenu.component';
import { connect, ConnectedProps } from 'react-redux';

import { fetchRulesList } from 'services/ManageAssertions.service';
import { orderBy } from 'lodash';

interface IProps {}

const connector = connect(
  (state: RootState) => ({
  }),
  {  },
);

type PropsFromRedux = ConnectedProps<typeof connector>;

const CustomRules: FunctionComponent<IProps & PropsFromRedux> = ({
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [customList, setCustomList] = useState<CustomRule[]>([]);
  const [customSort, setCustomSort] = useState<Sort | null>(null);

  const [fetchingList, setFetchingList] = useState(true);

  const fetchLists = () => {
    setFetchingList(true);
    fetchRulesList().then((res) => {
      setCustomList(res);
      setFetchingList(false);
    });
  };

  useEffect(fetchLists, []);

  // useEffect(() => {
  //   if (!entityTypes.length) {
  //     fetchAdvancedSearchForm(
  //       stringToDate('now-15m').valueOf(),
  //       stringToDate('now').valueOf(),
  //     ).then((res) => {
  //       setEntityTypes(res.entities.map((e) => e.entityType));
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const handleAddCustomItem = (item: CustomRule) => {
    setCustomList(customList.concat([item]));
  };

  const handleUpdateCustomItem = (updatedItem: CustomRule) => {
    setCustomList(
      customList.map((item) =>
        item.alert === updatedItem.alert ? { ...updatedItem } : item,
      ),
    );
  };

  const handleDeleteCustomItem = (itemToDelete: CustomRule) => {
    setCustomList(
      customList.filter(
        (item) =>
          !(
            item.alert === itemToDelete.alert &&
            item.expr === itemToDelete.expr &&
            item.for === itemToDelete.for &&
            item.labels.asserts_alert_category ===
              itemToDelete.labels.asserts_alert_category &&
            item.labels.asserts_severity ===
              itemToDelete.labels.asserts_severity
          ),
      ),
    );
  };

  const customListProcessed = useMemo(
    () =>
      orderBy(
        customList.filter(
          (item) =>
            item.alert.includes(searchQuery) ||
            item.labels?.asserts_alert_category?.includes(searchQuery) ||
            item.labels?.asserts_severity?.includes(searchQuery),
        ),
        customSort?.field,
        customSort?.order,
      ),
    [customList, customSort?.field, customSort?.order, searchQuery],
  );


  return (
    <div className="h-full">
      <AddRuleTopMenu searchQuery={searchQuery} onSearchChange={setSearchQuery} onYmlFileChange={fetchLists} />
      <div>
        <CustomRulesFormComponent onSave={handleAddCustomItem} list={customList} />
        <CustomRulesListComponent
          fields={['alert', 'expr', 'for', 'asserts_alert_category', 'asserts_severity', 'asserts_entity_type']}
          fetchingList={fetchingList}
          list={customListProcessed}
          onUpdate={handleUpdateCustomItem}
          onDelete={handleDeleteCustomItem}
          onSort={setCustomSort}
          orderBy={customSort}
        />
      </div>
    </div>
  );
};

export default connector(memo(CustomRules));
