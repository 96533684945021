/**
 *
 * TopInsightsSlo
 *
 */

import React, { memo, FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';

import { Link, NavLink } from 'react-router-dom';
import EnvSiteTagComponent from 'components/EnvSiteTag/EnvSiteTag.component';
import { connect, ConnectedProps } from 'react-redux';

import { dateToParams } from 'helpers/Date.helper';
import { roundTo } from 'helpers/ValueFormat.helper';
import ArrowCircleRightOutlinedIcon from 'assets/material-icons/arrow_circle_right_FILL0_wght400_GRAD0_opsz24.svg';
import useTopSlo from 'hooks/useTopSlo';
import { PanelContainer, Spinner } from '@grafana/ui';
import { prefixRoute } from 'utils/utils.routing';
import { ROUTES } from 'global-constants';
const TI_SLO_ROUND = 3;

interface IProps {
  expanded?: boolean;
  endTime: number | string;
}

const connector = connect(
  (state: RootState) => ({
    end: state.app.end,
  }),
  {},
);
type PropsFromRedux = ConnectedProps<typeof connector>;

const TopInsightsSlo: FunctionComponent<IProps & PropsFromRedux> = ({ expanded, endTime, end }) => {
  const intl = useIntl();

  const { data: slos, isFetching } = useTopSlo({
    enabled: true,
    endTime,
  });

  return (
    <PanelContainer className="relative" data-tour-target="top-insights-slo">
      <div className="flex items-center justify-between p-5">
        <p className="flex items-center gap-2 text-base font-bold">
          Service Health
          {Boolean(slos?.length) && (
            <div className="bg-primary/10 text-primary font-bold rounded-full px-2 py-0.5 inline-flex text-sm">
              {slos?.length}
            </div>
          )}
        </p>
        {isFetching && <Spinner size={20} className="leading-none" />}
      </div>
      {!Boolean(slos?.length) && !isFetching && (
        <div className="absolute inset-0 flex items-center justify-center text-secondary text-sm">
          {intl.formatMessage(messages.noData)}
        </div>
      )}

      {expanded && (
        <div className="grid grid-cols-2 gap-px bg-gray-200 dark:bg-neutral-800 divider-t divider-b">
          {slos?.map((slo) => (
            <div
              className="px-5 py-3 flex items-start justify-between bg-paper gap-2 h-[80px]"
              key={`${slo.name}-${slo.scope?.env}-${slo.scope?.site}`}
              data-cy="ti-slo-item"
            >
              <div className="min-w-0 overflow-hidden whitespace-nowrap text-ellipsis">
                <Link
                  to={
                    prefixRoute(ROUTES.SLO) +
                    `?sloName=${slo.name}&sloTargetName=${slo.sloTargetDtos[0].name}${
                      slo.scope?.env ? '&sloEnv=' + slo.scope?.env : ''
                    }${slo.scope?.site ? '&sloSite=' + slo.scope?.site : ''}&endTime=${dateToParams(end)}`
                  }
                  className="text-primary text-sm hover:underline"
                  title={slo.name}
                >
                  {slo.name}
                </Link>
                {slo.scope && <EnvSiteTagComponent scope={slo.scope} />}
              </div>
              <div>
                <p
                  className={`text-xl font-bold leading-none text-right ${
                    slo.sloTargetDtos[0]?.actualSli < slo.sloTargetDtos[0]?.targetSli
                      ? 'text-destructive'
                      : 'text-success'
                  }`}
                >
                  {roundTo(slo.sloTargetDtos[0]?.actualSli || 100, TI_SLO_ROUND)}%
                  <span className="text-secondary block text-xs whitespace-nowrap font-normal">
                    {intl.formatMessage(messages.target)}
                    {': '}
                    {roundTo(slo.sloTargetDtos[0]?.targetSli, TI_SLO_ROUND)}%
                  </span>
                </p>
                <div className="flex justify-end">
                  {!!slo.sloTargetDtos[0]?.incidentCount && (
                    <div
                      className={`text-secondary text-xs text-right font-bold rounded-full py-1 mt-1 ${
                        slo.sloTargetDtos[0]?.actualSli < slo.sloTargetDtos[0]?.targetSli
                          ? 'text-destructive'
                          : 'text-yellow-500'
                      }`}
                    >
                      {intl.formatMessage(messages.incidents, {
                        count: slo.sloTargetDtos[0]?.incidentCount,
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
          {(slos?.length || 0) % 2 !== 0 && (
            <div className="px-5 py-3 flex items-en justify-end bg-paper">
              <NavLink
                className="flex items-center gap-1 text-primary no-underline hover:underline"
                to={prefixRoute(ROUTES.SLO)}
              >
                Open SLO List <ArrowCircleRightOutlinedIcon className="svg-icon" />
              </NavLink>
            </div>
          )}
        </div>
      )}
    </PanelContainer>
  );
};

export default connector(memo(TopInsightsSlo));
