import { defineMessages } from 'react-intl';

export const scope = 'features.AssertionsList';

export default defineMessages({
  entity: {
    id: `${scope}.entity`,
    defaultMessage: 'Entity',
  },
  type: {
    id: `${scope}.type`,
    defaultMessage: 'Type',
  },
  expand: {
    id: `${scope}.expand`,
    defaultMessage: 'Expand',
  },
  collapse: {
    id: `${scope}.collapse`,
    defaultMessage: 'Collapse',
  },
  collapseAll: {
    id: `${scope}.collapseAll`,
    defaultMessage: 'Collapse all',
  },
  summary: {
    id: `${scope}.summary`,
    defaultMessage:
      '{count, plural, =0 {No entities added} one {Summary of # entity:} other {Summary of # entities:}}',
  },
  hint: {
    id: `${scope}.hint`,
    defaultMessage: 'Click on timeline area to lock time',
  },
});
