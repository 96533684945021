import { InlineFieldRow, InlineField, InlineSwitch } from '@grafana/ui';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ViewField } from './KpiViewConfigForm';

export function KpiViewConfigFormItem({ name, label }: ViewField) {
  const { register } = useFormContext();
  return (
    <InlineFieldRow>
      <InlineField label={label}>
        <InlineSwitch {...register(name)} />
      </InlineField>
    </InlineFieldRow>
  );
}
