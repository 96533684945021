/*
 * DisableRules Messages
 *
 * This contains all the text for the DisableRules component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.DisableRules';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Disable Rules',
  },
  enabled: {
    id: `${scope}.enabled`,
    defaultMessage: 'Rule enabled successfuly',
  },
  disabled: {
    id: `${scope}.disabled`,
    defaultMessage: 'Rule disabled successfuly',
  },
  catalog: {
    id: `${scope}.catalog`,
    defaultMessage: 'Assertion catalog',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Name',
  },
  enabledTitle: {
    id: `${scope}.enabledTitle`,
    defaultMessage: 'Enabled',
  },
});
