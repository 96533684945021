/**
 *
 * SaafeButtons
 *
 */

import React, { memo, FunctionComponent } from 'react';
import { twMerge } from 'tailwind-merge';
import { getIconCodeByType } from '../../helpers/Icon.helper';
import { SAAFE } from 'asserts-types';
import { SAAFE_PROPERTIES } from 'app/constants';
import { Button, Checkbox } from '@grafana/ui';
import { Tooltip } from 'components/Tooltip/Tooltip.component';

interface IProps {
  selectedSaafe: SAAFE[];
  setAlertCategories: (option: SAAFE[]) => void;
  countMap?: Record<SAAFE, number>;
}

const SaafeButtons: FunctionComponent<IProps> = ({ setAlertCategories, selectedSaafe, countMap }) => {
  const handleItemClick = (option: SAAFE) => {
    if (selectedSaafe.includes(option)) {
      setAlertCategories(selectedSaafe.filter((p) => p !== option));
    } else {
      setAlertCategories(selectedSaafe.concat(option));
    }
  };

  return (
    <div className="px-4 flex items-center space-x-5">
      {SAAFE_PROPERTIES.map((option) => (
        <Tooltip
          placement="top"
          interactive
          key={option}
          content={
            <div className="flex items-center">
              {!(selectedSaafe.length === 1 && selectedSaafe.includes(option)) && (
                <Button
                  size="sm"
                  icon="eye"
                  fill="text"
                  onClick={(e) => {
                    e.stopPropagation();
                    setAlertCategories([option]);
                  }}
                >
                  Only
                </Button>
              )}
              {SAAFE_PROPERTIES.length !== selectedSaafe.length && (
                <Button
                  size="sm"
                  icon="eye"
                  fill="text"
                  onClick={(e) => {
                    e.stopPropagation();
                    setAlertCategories(SAAFE_PROPERTIES);
                  }}
                >
                  All
                </Button>
              )}
            </div>
          }
        >
          <div
            className="flex items-center py-0.5 rounded capitalize cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              handleItemClick(option);
            }}
          >
            <div
              className={`flex text-2xl items-center space-x-1.5 hover:bg-hover rounded-md px-2 py-0.5 ${
                selectedSaafe.includes(option) ? 'divider' : ''
              }`}
            >
              <Checkbox checked={selectedSaafe.includes(option)} className="mx-0 pointer-events-none" />
              <i
                className={twMerge(
                  'text-base text-secondary icon-',
                  selectedSaafe.includes(option) ? 'text-primary' : ''
                )}
              >
                {String.fromCharCode(getIconCodeByType(option) || 0)}
              </i>
              <p className={`text-sm text-secondary space-x-1.5 ${selectedSaafe.includes(option) ? '' : 'opacity-50'}`}>
                <span>{option}</span> <span className="font-bold">{countMap?.[option] || 0}</span>
              </p>
            </div>
            {/* <div className="flex items-center">
            <p
              className="hidden text-primary pr-2 font-normal"
              onClick={(e) => {
                e.stopPropagation();
                toggleSaafe(
                  selectedSaafe.length === 1 && selectedSaafe[0] === option
                    ? SAAFE_PROPERTIES.filter((o) => o !== option)
                    : selectedSaafe
                        .filter((o) => o !== option)
                        .concat(selectedSaafe.includes(option) ? [] : [option]),
                );
              }}
            >
              {intl.formatMessage(
                selectedSaafe.length === 1 && selectedSaafe[0] === option
                  ? messages.all
                  : messages.only,
              )}
            </p>
          </div> */}
          </div>
        </Tooltip>
      ))}
    </div>
  );
};

export default memo(SaafeButtons);
