import { defineMessages } from 'react-intl';

export const scope = 'features.EntityFilterSettings';

export default defineMessages({
  entityTypes: {
    id: `${scope}.entityTypes`,
    defaultMessage: 'Entity types',
  },
  all: {
    id: `${scope}.entityTypes`,
    defaultMessage: 'All',
  },
});
