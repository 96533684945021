import { useAppSelector } from 'app/store';
import { useEffect } from 'react';

// TODO: remove this when Drawer can be rendered inside #grafana-portal-container
// This hook hides all tooltips that were open before entity details drawer is opened
export function useEntityDetailsOpen() {
  const activeEntityDetails = useAppSelector((state) => state.app.activeEntityDetails);

  useEffect(() => {
    document.querySelector('#grafana-portal-container')?.childNodes.forEach((node) => {
      if (node instanceof Element) {
        activeEntityDetails ? node.classList.add('hidden') : node.classList.remove('hidden');
      }
    });
  }, [activeEntityDetails]);
}
