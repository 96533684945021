/**
 *
 * IncidentListItem
 *
 */

import React, { memo, FunctionComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { toggleExpandItem } from '../../Incidents.slice';
import { IncidentGroup } from 'asserts-types';
import HoveredButtons from '../HoveredButtons/HoveredButtons.component';
import { addEntityToWorkbench } from '../../../Assertions/Assertions.slice';
import EllipsisText from 'components/EllipsisText/ElipsisText.component';
import AssertionsListItemActions from '../../../Assertions/components/AssertionsListItemActions/AssertionsListItemActions.component';
import EnvSiteTagComponent from 'components/EnvSiteTag/EnvSiteTag.component';
import { setActiveEntityDetails, setTimeRange } from 'features/App/App.slice';
import { Tooltip } from 'components/Tooltip/Tooltip.component';

const connector = connect((state: RootState) => ({}), {
  toggleExpandItem,
  addEntityToWorkbench,
  setTimeRange,
  setActiveEntityDetails,
});

interface IProps {
  incidentGroup: IncidentGroup;
  expanded?: boolean;
  level?: number;
  start: number;
  end: number;
}
type PropsFromRedux = ConnectedProps<typeof connector>;

const IncidentListItem: FunctionComponent<IProps & PropsFromRedux> = ({
  incidentGroup,
  expanded,
  toggleExpandItem,
  start,
  end,
  addEntityToWorkbench,
  setTimeRange,
  setActiveEntityDetails,
  level,
}) => {
  const parseName = (name: string) => {
    return name.split('::').map((namePart, index) => (
      <div className="flex items-center" key={index + namePart}>
        {Array.from(Array(index), (_, i) => (
          <span key={i} className="mr-2.5 flex items-center text-gray-300">
            &#8226;
          </span>
        ))}

        <div className="text-ellipsis overflow-hidden whitespace-nowrap flex w-full">
          <EllipsisText>
            <Tooltip content={namePart} placement="top" enterDelay={500}>
              <p>{namePart}</p>
            </Tooltip>
          </EllipsisText>
        </div>
      </div>
    ));
  };

  return (
    <div
      className={`py-4 pr-4 flex flex-col divider-b cursor-pointer relative group/item justify-center bg-default`}
      onClick={() => toggleExpandItem(incidentGroup.id)}
      data-intercom-target="incident-item"
    >
      <div className="flex justify-between items-center w-full">
        <div
          className="mr-2 grow w-0 items-center flex"
          style={{
            marginLeft: 16 * (level ? level + 1 : 0),
          }}
        >
          <i className={`icon-rounded-${expanded ? 'down' : 'right'} text-gray-300 text-2xl mr-1`} />
          <div className="flex flex-col grow w-0">
            <p title={incidentGroup.name}>
              {parseName(
                incidentGroup.type === 'assertion_alert'
                  ? incidentGroup.name
                  : `${incidentGroup.detail.sloName}`
              )}
            </p>
            {incidentGroup.type === 'assertion_alert' && (
              <p
                title={incidentGroup.detail.name}
                className="text-ellipsis overflow-hidden whitespace-nowrap flex w-full text-xs text-secondary"
              >
                {incidentGroup.detail.name}
              </p>
            )}
            {incidentGroup.type !== 'assertion_alert' && <EnvSiteTagComponent scope={incidentGroup.detail.scope} />}
          </div>
        </div>
        <HoveredButtons
          start={start}
          end={end}
          addEntityToWorkbench={addEntityToWorkbench}
          incidentGroup={incidentGroup}
          setActiveEntityDetails={setActiveEntityDetails}
        />
      </div>
      {incidentGroup.type === 'assertion_alert' &&
        incidentGroup.detail.name &&
        incidentGroup.detail.type &&
        expanded && (
          <AssertionsListItemActions
            itemId={incidentGroup.id.toString()}
            level={level || 0}
            start={start}
            end={end}
            labels={incidentGroup.detail.labels || {}}
            name={incidentGroup.detail.name}
            type={incidentGroup.detail.type}
            scope={incidentGroup.detail.scope}
          />
        )}
    </div>
  );
};

export default connector(memo(IncidentListItem));
