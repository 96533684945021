/*
 * TopInsightsIncidents Messages
 *
 * This contains all the text for the TopInsightsIncidents component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.TopInsightsIncidents';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Notifications',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Name',
  },
  count: {
    id: `${scope}.count`,
    defaultMessage: 'Count',
  },
  currentStatus: {
    id: `${scope}.currentStatus`,
    defaultMessage: 'Current status',
  },
  notifications: {
    id: `${scope}.notifications`,
    defaultMessage:
      '{count, plural, =0 {No notifications} one {Notification} other {Notifications}}',
  },
  noData: {
    id: `${scope}.noData`,
    defaultMessage: 'No Data for given time range',
  },
});
