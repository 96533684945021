
import React from 'react';

export default function CompressIcon(props: any) {
  return (
    <svg className="svg-icon">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M8 19h3v3h2v-3h3l-4-4-4 4zm8-15h-3V1h-2v3H8l4 4 4-4zM4 9v2h16V9H4z" />
      <path d="M4 12h16v2H4z" />
    </svg>
  );
}
