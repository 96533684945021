import {
  AssertionsResponseProcessed,
  AssertionsViewType,
  HealthState,
  HierarchicalAssertion,
  SAAFE,
} from 'asserts-types';

import { stringToDate } from '../../../helpers/Date.helper';
import { filterRecursive } from '../Assertions.helpers';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setWorkbenchEntities } from '../Assertions.slice';
import { orderBy, pick } from 'lodash';
import useWorkbenchDefinition from './useWorkbenchDefinition';
import { useAppSelector } from 'app/store';
import { SAAFE_PROPERTIES } from 'app/constants';
import { fetchAssertionsByDefinition, fetchEntityAssertions } from 'services/Assertion.service';
import { useQuery } from '@tanstack/react-query';

const USE_ENTITY_ASSERTIONS_QUERY_KEY = 'entity-assertions-timeline';

export default function useEntityAssertions() {
  const dispatch = useDispatch();
  const hideOldAssertions = useAppSelector(
    (state) => state.assertions.hideOldAssertions,
  );
  const oldAssertionHours = useAppSelector((state) =>
    hideOldAssertions ? state.assertions.oldAssertionHours : undefined,
  );

  const alertCategories = useAppSelector(
    (state) => state.assertions.alertCategories,
  );

  const viewType = useAppSelector((state) => state.assertions.viewType);

  const showWithAssertions = useAppSelector(
    (state) => state.assertions.showWithAssertions,
  );

  const selectedEnv = useAppSelector((state) => state.app.selectedEnv);
  const selectedSite = useAppSelector((state) => state.app.selectedSite);

  const sort = useAppSelector((state) => state.assertions.sort);

  const workbenchEntities = useAppSelector(
    (state) => state.assertions.workbenchEntities,
  );

  const {
    definition,
    isFetching: isFetchingDefinition,
  } = useWorkbenchDefinition();

  const start = useAppSelector((state) => state.app.start);

  const end = useAppSelector((state) => state.app.end);

  const assertionLevels = SAAFE_PROPERTIES.filter((p) =>
    alertCategories.includes(p),
  );

  const select = useCallback(
    (data: AssertionsResponseProcessed): AssertionsResponseProcessed => {
      // composing root health states from nested ones
      let assertions = data.assertions.map((assertion) => {
        const healthStates = assertion.nestedTimelines.reduce(
          (acc: HealthState[], curr) => {
            return acc.concat(
              alertCategories.includes(curr.name as SAAFE)
                ? curr.healthStates
                : [],
            );
          },
          [],
        );

        // filtering nested timelines according saafe filter
        const nestedTimelines = assertion.nestedTimelines.filter((item) =>
          alertCategories.includes(item.name as SAAFE),
        );

        return { ...assertion, healthStates, nestedTimelines };
      });

      // filtering everything by showWithAssertions
      assertions = filterRecursive(assertions, showWithAssertions);

      assertions = orderBy(assertions, sort.field === 'time' ? undefined : [(item) => item.totalScore || '', 'name'], [
        sort.order,
        'asc',
      ]) as HierarchicalAssertion[];

      return { ...data, assertions };
    },
    [alertCategories, showWithAssertions, sort.field, sort.order],
  );

  const query = useQuery(
    [
      USE_ENTITY_ASSERTIONS_QUERY_KEY,
      definition || workbenchEntities,
      start,
      end,
      assertionLevels,
      hideOldAssertions,
      oldAssertionHours,
      selectedEnv,
      selectedSite,
    ],

    async () => {
      const data = definition
        ? await fetchAssertionsByDefinition(
            definition,
            stringToDate(start).valueOf(),
            stringToDate(end).valueOf(),
            assertionLevels,
            oldAssertionHours,
          )
        : await fetchEntityAssertions(
            workbenchEntities,
            stringToDate(start).valueOf(),
            stringToDate(end).valueOf(),
            assertionLevels,
            oldAssertionHours,
          );
      return data;
    },
    {
      select,
      enabled:
        Boolean(workbenchEntities.length || definition) &&
        viewType !== AssertionsViewType.SUMMARY &&
        !isFetchingDefinition,
      keepPreviousData: true,
      cacheTime: Infinity,
      staleTime: typeof start === 'string' ? 0 : Infinity,
    },
  );

  useEffect(() => {
    if (definition && query?.data?.assertions.length) {
      const newWorkbenchEntities = query?.data?.assertions.map((item) =>
        pick(item, 'name', 'type', 'scope'),
      );
      dispatch(setWorkbenchEntities(newWorkbenchEntities));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query?.data?.assertions]);

  return {
    ...query,
    isFetching: query.isFetching || isFetchingDefinition,
  };
}
