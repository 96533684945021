/*
 * ThresholdRequestForm Messages
 *
 * This contains all the text for the ThresholdRequestForm component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.ThresholdRequestForm';

export default defineMessages({
  assertion: {
    id: `${scope}.assertion`,
    defaultMessage: 'Assertion',
  },
  job: {
    id: `${scope}.job`,
    defaultMessage: 'Job',
  },
  requestType: {
    id: `${scope}.requestType`,
    defaultMessage: 'Request Type',
  },
  requestContext: {
    id: `${scope}.requestContext`,
    defaultMessage: 'Request Context',
  },
  value: {
    id: `${scope}.value`,
    defaultMessage: 'Value',
  },
  addNew: {
    id: `${scope}.addNew`,
    defaultMessage: 'Add new',
  },
  secs: {
    id: `${scope}.secs`,
    defaultMessage: 'seconds',
  },
  addTitle: {
    id: `${scope}.addTitle`,
    defaultMessage: 'Add Request Threshold',
  },
});
