/**
 *
 * SloIncidents
 *
 */

import React, { memo, FunctionComponent, useRef, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import IncidentsList from '../IncidentsList/IncidentsList.component';
import { useIntl } from 'react-intl';
import messages from './messages';
import { stringToDate } from 'helpers/Date.helper';
import { toggleExpandItem } from '../../Incidents.slice';
import { setTimeRange } from 'features/App/App.slice';
import { GroupedIncident } from 'asserts-types';
import { LoadingBar, LoadingPlaceholder } from '@grafana/ui';
import RangePickerLineComponent from 'components/RangePickerLine/RangePickerLine.component';
import useRangeSelection from 'features/Assertions/components/AssertionsSummaryView/hooks/useRangeSelection';
import useSummaryLeftPanel from 'features/Assertions/hooks/useSummaryLeftPanel';
import { AXIS_PADDING_RIGHT } from 'app/constants';


interface IProps {
  groupedIncidents: GroupedIncident[];
  isFetching: boolean;
  chartName: string;
}

const connector = connect(
  (state: RootState) => ({
    start: state.app.start,
    end: state.app.end,
    expandedItems: state.incidents.expandedItems,
    search: state.incidents.incidentSearch,
  }),
  {
    toggleExpandItem,
    setTimeRange
  }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

const SloIncidents: FunctionComponent<PropsFromRedux & IProps> = ({
  start,
  end,
  expandedItems,
  groupedIncidents,
  toggleExpandItem,
  search,
  isFetching,
  chartName,
  setTimeRange,
}) => {
  const intl = useIntl();

  const startDate = useMemo(
    () => stringToDate(start),
    // eslint-disable-next-line
    [start]
  );
  const endDate = useMemo(
    () => stringToDate(end),
    // eslint-disable-next-line
    [end]
  );

  const listRef = useRef(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const { width: leftPanelWidth } = useSummaryLeftPanel();

  const { selectingRange, selectedStartX, selectedEndX } = useRangeSelection({
    start: startDate.valueOf(),
    end: endDate.valueOf(),
    containerRef,
    leftOffset: leftPanelWidth,
    rightOffset: AXIS_PADDING_RIGHT,
    onChange: (start, end) => groupedIncidents.length && setTimeRange({ start, end }),
  });

  return (
    <div className="relative h-0 grow" ref={containerRef}>
      {!!groupedIncidents.length && selectingRange && containerRef.current && (
        <RangePickerLineComponent
          start={startDate.valueOf()}
          end={endDate.valueOf()}
          mouseX={selectedEndX}
          selectedStartX={selectedStartX}
          tapeEl={containerRef.current}
          leftOffset={leftPanelWidth}
          rightOffset={AXIS_PADDING_RIGHT}
        />
      )}
      {isFetching && !!groupedIncidents.length && <LoadingBar width={300} />}
      {isFetching && !groupedIncidents.length && <LoadingPlaceholder text="Loading..." className="text-center py-36" />}
      {!!groupedIncidents.length && (
        <IncidentsList
          ref={listRef}
          groupedIncidents={groupedIncidents}
          expandedItems={expandedItems}
          toggleExpandItem={toggleExpandItem}
          start={startDate.valueOf()}
          end={endDate.valueOf()}
          chartName={chartName}
        />
      )}
      {!groupedIncidents.length && !isFetching && !search && (
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-center space-y-2">
            <p className="text-xl">{intl.formatMessage(messages.empty)}</p>
            <div className="text-secondary">{intl.formatMessage(messages.changeTime)}</div>
          </div>
        </div>
      )}
      {!groupedIncidents.length && !isFetching && !!search && (
        <div className="absolute inset-0 flex items-center justify-center">
          <div>
            <span className="text-secondary">{intl.formatMessage(messages.noResults)}</span>{' '}
            <span className="font-bold">&quot;{search}&quot;</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default connector(memo(SloIncidents));
