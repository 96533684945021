import { useQuery } from '@tanstack/react-query';
import { apiHttpService } from 'app/api-http-service';
import { TraceIntegration } from 'asserts-types';

export default function useTraceConfigs() {
  return useQuery(['trace-configs'], () =>
    apiHttpService
      .get<{
        supportedTraceTools: string[];
        traceEnvConfigDtos: TraceIntegration[];
      }>(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/trace`)
      .then((res) => res.data)
  );
}
