import React, { FunctionComponent } from 'react';
import { EntityProperties, Scope } from 'asserts-types';
import { Collapse, InteractiveTable } from '@grafana/ui';
import { deleteSystemEntityProperties } from 'helpers/Entity.helper';

interface IProps {
  properties: EntityProperties;
  scope: Scope | undefined;
}

const EntityPropertiesComponent: FunctionComponent<IProps> = ({ properties, scope }) => {
  // filter out system properties
  const propertiesToShow = deleteSystemEntityProperties(properties);

  const data = Object.entries(propertiesToShow).map(([property, value]) => ({
    property: property
      .toLowerCase()
      .replace(/_/g, ' ')
      .replace(/asserts/, '')
      .trim(),
    value,
  }));

  if (scope?.namespace) {
    data.push({ property: 'namespace', value: scope.namespace });
  }

  const columns = [
    { id: 'property', header: 'Property' },
    { id: 'value', header: 'Value' },
  ];

  return (
    <Collapse isOpen label="Properties">
      <InteractiveTable
        columns={columns}
        data={data}
        getRowId={(r: { property: any }) => r.property}
        className="[&_tr_td:first-child]:capitalize"
      />
    </Collapse>
  );
};
export default EntityPropertiesComponent;
