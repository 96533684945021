import {
  GrafanaSlo,
  GrafanaSloType,
  Slo,
  SloFailureRatioQuery,
  SloResponse,
  SloFailureThresholdQuery,
  SloType,
} from 'asserts-types';

function isOccurrence(slo: GrafanaSlo): slo is GrafanaSlo & {
  query: SloFailureThresholdQuery;
} {
  return slo.query.type === GrafanaSloType.FailureThreshold;
}

function isFailureRequest(slo: GrafanaSlo): slo is GrafanaSlo & {
  query: SloFailureRatioQuery;
} {
  return slo.query.type === GrafanaSloType.FailureRatio;
}

export function mergeGrafanaAndAssertsSLOs({
  grafanaSLOs,
  assertsSLOs,
}: {
  grafanaSLOs: GrafanaSlo[];
  assertsSLOs: SloResponse;
}) {
  let slos: Slo[] = [];

  assertsSLOs.slos.forEach((assertsSloItem) => {
    const grafanaSloItem = grafanaSLOs.find((s) => assertsSloItem.sloTargetDtos.find(t => s.uuid === t.name));

    if (grafanaSloItem) {
      slos.push({
        uuid: grafanaSloItem.uuid, // attention: uuid here cannot be used as a key in the UI since it can be several items with the same uuid but with different scope
        scope: assertsSloItem.scope,
        name: grafanaSloItem.name,
        action: grafanaSloItem.searchExpression,
        badEventQuery: isFailureRequest(grafanaSloItem)
          ? grafanaSloItem.query.failureRatio.failureMetric.prometheusMetric
          : '',
        totalEventQuery: isFailureRequest(grafanaSloItem)
          ? grafanaSloItem.query.failureRatio.totalMetric.prometheusMetric
          : '',
        measurementQuery: isOccurrence(grafanaSloItem) ? grafanaSloItem.query.failureThreshold.failureThresholdExpression : '',
        type: isOccurrence(grafanaSloItem) ? SloType.Occurrence : SloType.Request,
        sloTargetDtos: grafanaSloItem.objectives.map((objective) => ({
          ...assertsSloItem.sloTargetDtos[0],
          description: '',
          targetSli: 100 * objective.value,
          threshold: isOccurrence(grafanaSloItem) ? grafanaSloItem.query.failureThreshold.threshold.value : 0,
          hash: '',
        })),
      });
    }
  });

  const inactiveSlos = grafanaSLOs.filter((grafanaSlo) => !slos.find((slo) => slo.uuid === grafanaSlo.uuid));

  inactiveSlos.forEach((inactiveSlo) => {
    slos.push({
      uuid: inactiveSlo.uuid,
      name: inactiveSlo.name,
      action: inactiveSlo.searchExpression,
      type: isOccurrence(inactiveSlo) ? SloType.Occurrence : SloType.Request,
      sloTargetDtos: [],
    });
  });

  return {
    chartNames: assertsSLOs.chartNames,
    slos,
  };
}
