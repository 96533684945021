import React, { useEffect, useCallback, useRef, memo, useState } from 'react';

import { Graph, GraphOptions, LayoutConfig } from '@antv/g6';
import Toolbar from './components/toolbar/Toolbar.component';
import { MIN_ZOOM, MAX_ZOOM } from './constants';
import { Entity, GraphCustomData } from 'asserts-types';

import GraphContextMenu, { CustomContextMenuItem } from './components/GraphContextMenu/GraphContextMenu.component';
import './components/DashEdge';
import G6GraphWrapper from '../G6GraphWrapper/G6GraphWrapper.component';
import { useIntl } from 'react-intl';
import messages from './messages';
import useDisplayConfig from './hooks/useDisplayConfig';
import GraphTooltipComponent from './components/GraphTooltip/GraphTooltip.component';

interface IProps {
  data: GraphCustomData;
  layout: LayoutConfig;
  onRef?: (ref: Graph) => void;
  contextMenuOptions?: CustomContextMenuItem[];
  showConnectedItemsInContextMenu?: boolean;
  onConnectedItemClick?: (entity: Entity | undefined) => void;
  lastUpdateTime?: number;
  disableContextMenu?: boolean;
  fitView?: boolean;
  disableToolbar?: boolean;
  options?: Partial<GraphOptions>;
  disableTooltip?: boolean;
  disableZoom?: boolean;
  // afterLayoutConfig is used for setting after layout options
  // initial layout options are used for quick render of needed layout but they are pretty aggressive
  // and can't be used for user interactions
  afterLayoutConfig?: LayoutConfig;
}

export const setTooltipDisplay = (show: boolean) => {
  if (show) {
    document.querySelector('#grafana-portal-container')?.classList.remove('hide-graph-tooltip');
  } else {
    document.querySelector('#grafana-portal-container')?.classList.add('hide-graph-tooltip');
  }
};

const GraphinGraph = ({
  data,
  onRef,
  contextMenuOptions,
  layout,
  lastUpdateTime,
  disableContextMenu,
  fitView,
  disableToolbar,
  options,
  disableTooltip,
  disableZoom,
  afterLayoutConfig,
  onConnectedItemClick,
}: IProps) => {
  const graphRef = useRef<Graph | null>(null);
  const [showLabels, setShowLabels] = useState(true);
  const [buildingLayout, setBuildingLayout] = useState(true);

  const intl = useIntl();

  const defaultOptions: Partial<GraphOptions> = {
    minZoom: MIN_ZOOM,
    maxZoom: MAX_ZOOM,
    modes: {
      default: ['drag-canvas', 'drag-node'],
    },
    plugins: [],
  };

  if (!disableZoom) {
    defaultOptions.modes?.default?.push('zoom-canvas');
  }

  const handleDragStart = useCallback(() => {
    setTooltipDisplay(false);
  }, []);

  const handleDragEnd = useCallback(() => setTooltipDisplay(true), []);

  const handleAfterLayout = useCallback(() => {
    if (graphRef.current?.get('layout').clustering) {
      graphRef.current?.updateLayout({
        workerEnabled: false,
        clustering: false,
        nodeStrength: 30,
        edgeStrength: 0.1,
        linkDistance: 50,
        collideStrength: 0.8,
        ...(afterLayoutConfig || {}),
      });
      setTimeout(() => {
        setBuildingLayout(false);
        if (data.nodes.length > 15 || fitView) {
          graphRef.current?.fitView();
        }
      }, 200);
    }
  }, [graphRef, data, fitView, afterLayoutConfig]);

  useEffect(() => {
    if (!graphRef.current) {
      return;
    }
    onRef && onRef(graphRef.current);
    const graph: Graph = graphRef.current;

    graph?.on('node:mousedown', handleDragStart);
    graph?.on('node:dragend', handleDragEnd);
    graph?.on('afterlayout', handleAfterLayout);

    return () => {
      graph?.off('node:dragend', handleDragEnd);
      graph?.off('afterlayout', handleAfterLayout);
      graph?.off('node:mousedown', handleDragStart);
    };
    //eslint-disable-next-line
  }, []);

  const handleZoomChange = useCallback(() => {
    if (!graphRef.current) {
      return;
    }
    const graph: Graph = graphRef.current;

    if (showLabels && graph.getZoom() <= 0.5) {
      setShowLabels(false);
    }
    if (!showLabels && graph.getZoom() > 0.5) {
      setShowLabels(true);
    }
  }, [graphRef, showLabels]);

  const dataWithDisplayConfig = useDisplayConfig({
    data,
    showLabels,
    lastUpdateTime,
  });

  return (
    <>
      {buildingLayout && (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-[1] text-secondary">
          {intl.formatMessage(messages.buildingLayout)}
        </div>
      )}
      <GraphTooltipComponent
        graph={graphRef.current}
        disableTooltip={disableTooltip}
        lastUpdateTime={lastUpdateTime}
        onConnectedItemClick={onConnectedItemClick}
      />
      <G6GraphWrapper
        style={{ visibility: buildingLayout ? 'hidden' : 'visible' }}
        ref={(c) => {
          c && (graphRef.current = c);
        }}
        layout={layout}
        data={dataWithDisplayConfig}
        options={{ ...defaultOptions, ...options }}
      >
        {!disableToolbar && <Toolbar onChange={handleZoomChange} />}
        {!disableContextMenu && (
          <GraphContextMenu
            options={contextMenuOptions}
            setTooltipDisplay={setTooltipDisplay}
            onConnectedItemClick={onConnectedItemClick}
          />
        )}
      </G6GraphWrapper>
    </>
  );
};

export default memo(GraphinGraph);
