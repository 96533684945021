import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'app/queryClient';
import store from 'app/store';
import React, { PropsWithChildren } from 'react';
import { Provider } from 'react-redux';

export function ExternalComponentWrapper({ children }: PropsWithChildren<{}>) {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>{children}</Provider>
    </QueryClientProvider>
  );
}
