/**
 *
 * SloObjectives
 *
 */

import React, { memo, FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import { Controller, useFieldArray, UseFormSetValue, UseFormStateProps } from 'react-hook-form';
import { SloConfigModel, SloType } from 'asserts-types';
import errorMessages from 'app/errorMessages';
import { Field, Input } from '@grafana/ui';
import Decimal from 'decimal.js';

interface IProps {
  kind: string;
  editing?: boolean;
  isSimple?: boolean;
  setValue: UseFormSetValue<SloConfigModel>;
}

const daysMap: Record<string, number | undefined> = {
  Weekly: 7,
  Monthly: 31,
  Yearly: 365,
};

const SloObjectives: FunctionComponent<
  IProps & UseFormStateProps<SloConfigModel>
> = ({ control, kind, editing = true, isSimple, setValue }) => {
  const intl = useIntl();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'objectives',
  });
  return (
    <div>
      {fields.map((item, index) => (
        <div className={`pt-4 flex gap-2`} key={item.id}>
          {/* {editing && (
            <div className="px-2">
              <IconButton
                onClick={() => {
                  remove(index);
                }}
                variant="destructive"
              >
                <CloseIcon />
              </IconButton>
            </div>
          )}      */}
          <Controller
            name={`objectives.${index}.window.days`}
            control={control}
            rules={{
              validate: (numberOfDays) =>
                  // Grafana SLO API limits
                  (numberOfDays >= 7 && numberOfDays <= 32) ||
                  intl.formatMessage(errorMessages.range, {
                    min: 7,
                    max: 32,
                  })
            }}
            render={({ field, fieldState }) => (
              <Field
                invalid={!!fieldState.error}
                error={fieldState.error?.message}
                label={intl.formatMessage(messages.complianceWindow)}
                className="w-[200px]"
              >
                <Input {...field} placeholder="Enter value" type="number" disabled={!editing} />
              </Field>
            )}
          />
          {kind === SloType.Occurrence && (
            <Controller
              name={`objectives.${index}.value`}
              control={control}
              render={({ field, fieldState }) => (
                <Field
                  invalid={!!fieldState.error}
                  error={fieldState.error?.message}
                  label={
                    intl.formatMessage(messages.threshold) +
                    (kind === SloType.Occurrence && isSimple ? intl.formatMessage(messages.secs) : '')
                  }
                  className="w-[140px]"
                >
                  <Input
                    {...field}
                    onChange={(e) => field.onChange(e.currentTarget.valueAsNumber)}
                    placeholder="Enter value"
                    type="number"
                    disabled={!editing}
                  />
                </Field>
              )}
            />
          )}

          <Controller
            name={`objectives.${index}.ratio`}
            control={control}
            rules={{
              validate: (v) =>
                (v > 0 && v < 100) ||
                intl.formatMessage(errorMessages.range, {
                  min: 0,
                  max: 100,
                }),
            }}
            render={({ field, fieldState }) => (
              <Field
                invalid={!!fieldState.error}
                error={fieldState.error?.message}
                label={intl.formatMessage(messages.targetRatio)}
                className="w-[140px]"
              >
                <Input
                  {...field}
                  onChange={(e) => {
                    // remove all characters from string and replace commas with dots
                    const processedValue = e.currentTarget.value.replace(/[^0-9\,\.]/g, '').replace(/\,/g, '.');
                    const firstDotIndex = processedValue.indexOf('.');
                    // remove all dots except first one
                    const result =
                      processedValue.slice(0, firstDotIndex + 1) +
                      processedValue.slice(firstDotIndex + 1).replace(/\./g, '');

                    field.onChange(result);
                  }}
                  placeholder="Enter value"
                  onBlur={() => field.onChange(new Decimal(field.value).toNumber())}
                  disabled={!editing}
                />
              </Field>
            )}
          />
        </div>
      ))}
      {/* {editing && (
        <Button
          onClick={() => append(cloneDeep(DEFAULT_OBJECTIVE))}
          icon="plus"
          variant="secondary"
          className="mt-4"
          size="sm"
        >
          Add Objective
        </Button>
      )} */}
    </div>
  );
};

export default memo(SloObjectives);
