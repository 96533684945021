import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Button, ComponentSize, Icon, Toggletip, useStyles2 } from '@grafana/ui';
import { AssertionSeverity, EntityAssertion } from 'asserts-types';
import useEntity, { UseEntityParams } from 'hooks/useEntity';
import React from 'react';
import EntityAssertionsPopover from './EntityAssertionsPopover';
import TrackingHelper from 'helpers/Tracking.helper';

type Severity = AssertionSeverity | 'none';

export interface EntityAssertionsWidgetProps {
  query: UseEntityParams;
  size?: ComponentSize;
  source?: string;
}

export function EntityAssertionsWidget({ query, size, source }: EntityAssertionsWidgetProps) {
  const { isLoading, data } = useEntity(query);
  const severity = getMaxSeverity(data?.assertion?.assertions);
  const styles = useStyles2(getStyles(severity));

  if (isLoading || !data) {
    return null;
  }

  return (
    <Toggletip
      onOpen={() => {
        TrackingHelper.trackExternalWidgetOpened(source ?? 'unset');
      }}
      content={
        <EntityAssertionsPopover
          entity={data}
          scope={query.scope}
          start={query.start}
          end={query.end}
          source={source}
        />
      }
    >
      <Button className={styles.button} fill="outline" size={size}>
        {severity !== 'none' && (
          <Icon name={severity === 'warning' ? 'exclamation-triangle' : 'exclamation-circle'} className={styles.icon} />
        )}
        Assertions ({data?.assertion?.assertions?.length ?? 0})
        <Icon name={'angle-down'} className={styles.icon} />
      </Button>
    </Toggletip>
  );
}

function getStyles(severity: Severity) {
  return function (theme: GrafanaTheme2) {
    const color = getColorFromSeverity(severity, theme);
    return {
      button: css({
        borderColor: color,
        color: color,
        '&:hover': {
          borderColor: `color-mix(in srgb, ${color} 50%, ${color} 50%)`,
          color: `color-mix(in srgb, ${color} 50%, ${color} 50%)`,
          background: 'transparent',
        },
      }),
      icon: css({
        color: color,
        marginRight: theme.spacing(1),
      }),
    };
  };
}

function getMaxSeverity(assertions?: EntityAssertion['assertions']) {
  return (
    assertions?.reduce<AssertionSeverity>((sev, assertion) => {
      if (assertion.severity === 'critical') {
        return 'critical';
      }
      if (assertion.severity === 'warning' && sev !== 'critical') {
        return 'warning';
      }
      return sev;
    }, 'info') ?? 'none'
  );
}

function getColorFromSeverity(severity: Severity, theme: GrafanaTheme2) {
  switch (severity) {
    case 'critical':
      return theme.colors.error.text;
    case 'warning':
      return theme.colors.warning.text;
    case 'info':
      return theme.colors.info.text;
    case 'none':
      return theme.colors.text.secondary;
  }
}
