/**
 *
 * AssertionSearchRules
 *
 */

import React, { memo, FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import { addRule, setEntityType } from '../../AssertionSearch.slice';
import AssertionSearchRuleItem from '../AssertionSearchRuleItem/AssertionSearchRuleItem.component';
import {
  AssertionEntityTypes,
  AssertionSearchForm,
} from 'asserts-types';
import { setShowSearch } from '../../../Assertions/Assertions.slice';
import { Button, Legend, RadioButtonList } from '@grafana/ui';
import CloseRoundedIcon from 'assets/material-icons/close_FILL0_wght400_GRAD0_opsz24.svg';
import useAssertionsSearch from 'features/AssertionSearch/hooks/useAssertionsSearch';
import { IconButton } from 'components/IconButton/IconButton.component';

interface IProps {
  addRule: typeof addRule;
  setEntityType: typeof setEntityType;
  assertionSearch: AssertionSearchForm;
  searchAssertions: () => void;
  setShowSearch: typeof setShowSearch;
}

const AssertionSearchRules: FunctionComponent<IProps> = ({
  assertionSearch,
  addRule,
  setEntityType,
  searchAssertions,
  setShowSearch,
}) => {
  const intl = useIntl();

  const entityTypesOptions = [
    {
      value: AssertionEntityTypes.SERVICE,
      label: intl.formatMessage(messages.service),
    },
    {
      value: AssertionEntityTypes.NAMESPACE,
      label: intl.formatMessage(messages.namespace),
    },
    {
      value: AssertionEntityTypes.NODE,
      label: intl.formatMessage(messages.node),
    },
  ];

  const { isFetching } = useAssertionsSearch();

  return (
    <div className="p-8 pt-0">
      <div className="flex items-start justify-between sticky top-0 pt-8 bg-paper z-[10]">
        <Legend>Entities with Assertions</Legend>
        <IconButton onClick={() => setShowSearch(false)}>
          <CloseRoundedIcon />
        </IconButton>
      </div>
      <div className="flex items-center justify-between pb-8">
        <p className="text-primary block uppercase">{intl.formatMessage(messages.assertionName)}</p>
        <Button size="sm" fill="text" onClick={() => addRule()}>
          {intl.formatMessage(messages.addRule)}
        </Button>
      </div>
      {assertionSearch.searchCriteria.map((criteria, index) => (
        <AssertionSearchRuleItem key={index} criteriaIndex={index} labelMatchers={criteria.labelMatchers} />
      ))}
      <div className="pt-4 pb-8">
        <p className="mb-4 uppercase text-primary block">{intl.formatMessage(messages.rootEntity)}</p>
        <RadioButtonList
          name="assertionsType"
          options={entityTypesOptions}
          value={assertionSearch.entityType}
          onChange={(v) => setEntityType(v)}
        />
      </div>

      <div>
        <Button color="primary" data-cy="wb-search-btn" onClick={searchAssertions} disabled={isFetching}>
          {intl.formatMessage(messages.search)}
        </Button>
        <Button variant="secondary" fill="text" onClick={() => setShowSearch(false)} className="ml-4">
          {intl.formatMessage(messages.close)}
        </Button>
      </div>
    </div>
  );
};

export default memo(AssertionSearchRules);
