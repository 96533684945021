import { useForm } from 'react-hook-form';
import messages from '../messages';
import useNotificationsSloList, {
  USE_NOTIFICATIONS_SLO_LIST_KEY,
} from './useNotificationsSloList';
import { NotificationConfig } from 'asserts-types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { saveNotification } from 'services/ManageAssertions.service';
import SnackbarHelper from 'helpers/Snackbar.helper';

interface IProps {
  preloadedValue: NotificationConfig;
  onSuccess: () => void;
}

interface IForm {
  receiverField?: string;
  forField?: number | string;
  otherLabelsField?: string;
  silenced?: boolean;
  name: string;
}

export default function useNotificationsSloForm(props?: IProps) {
  const queryClient = useQueryClient();
  const { preloadedValue, onSuccess } = props || {};

  const { handleSubmit, control } = useForm<IForm>({
    defaultValues: {
      name: preloadedValue?.name || '',
      otherLabelsField: preloadedValue?.matchLabels.additional_labels || '',
      forField: preloadedValue?.for ? parseInt(preloadedValue?.for, 10) : '',
      silenced: preloadedValue?.silenced || false,
      receiverField: preloadedValue?.alertLabels?.asserts_receiver || '',
    },
  });

  const convertToListItem = (form: IForm): NotificationConfig => {
    const additional_labels = form.otherLabelsField || undefined;
    const asserts_receiver = form.receiverField || undefined;

    const matchLabels = {
      additional_labels,
      asserts_slo_name: form.name,
    };

    const alertLabels = {
      asserts_receiver,
    };

    return {
      name: form.name,
      matchLabels,
      alertLabels,
      for: form.forField ? `${form.forField}m` : undefined,
      silenced: form.silenced,
    };
  };

  const onUpdate = (updatedData: NotificationConfig) => {
    queryClient.setQueryData<
      ReturnType<typeof useNotificationsSloList>['data']
    >([USE_NOTIFICATIONS_SLO_LIST_KEY], (data) =>
      data?.map((item) =>
        item.name === updatedData.name ? updatedData : item,
      ),
    );
  };

  const mutation = useMutation({
    mutationFn: (form: IForm) => {
      return saveNotification(convertToListItem(form));
    },
    onSuccess: (_, form) => {
      onUpdate(convertToListItem(form));
      onSuccess?.();
      SnackbarHelper.success(messages.saveSuccess);
    },
  });

  const submit = (data: IForm) => {
    mutation.mutateAsync(data);
  };

  return {
    onSubmit: handleSubmit(submit),
    onUpdate,
    control,
    isSaving: mutation.isLoading,
  };
}
