import React, {
  FunctionComponent,
  CSSProperties,
  useState,
  useRef,
  MouseEvent as RCTMouseEvent,
} from 'react';
import MoreVertIcon from 'assets/material-icons/more_vert_FILL0_wght400_GRAD0_opsz24.svg';
import MoreHorizIcon from 'assets/material-icons/more_horiz_FILL0_wght400_GRAD0_opsz24.svg';
import ChevronLeftRoundedIcon from 'assets/material-icons/chevron_left_FILL0_wght400_GRAD0_opsz24.svg';
import ChevronRightRoundedIcon from 'assets/material-icons/chevron_right_FILL0_wght400_GRAD0_opsz24.svg';
import useDidUpdateEffect from 'hooks/useDidUpdate';

import useWindowSize from 'hooks/useWindowSize';

import { twMerge } from 'tailwind-merge';
import { IconButton } from 'components/IconButton/IconButton.component';

interface IProps {
  maxSize?: string;
  minSize?: string;
  initialSize: string;
  onResize?: (newSize?: number) => void;
  disabled?: boolean;
  style?: CSSProperties;
  className?: string;
  type: 'flex' | 'width' | 'height';
  handle?: 'top' | 'left' | 'right' | 'bottom';
  handleColor?: string;
  collapsable?: boolean;
  collapsedTitle?: string;
}

const ResizableBox: FunctionComponent<IProps> = ({
  children,
  maxSize,
  initialSize,
  onResize,
  disabled,
  style,
  className,
  type,
  minSize,
  handle = 'left',
  handleColor,
  collapsable,
  collapsedTitle,
}) => {
  const defaultSize = parseInt(initialSize, 10) > 100 ? 100 : parseInt(initialSize, 10);
  const { width, height } = useWindowSize();
  let maxSizeParsed = maxSize && parseInt(maxSize, 10);
  let minSizeParsed = minSize && parseInt(minSize, 10);
  const [drawerSize, setDrawerSize] = useState(defaultSize);

  const drawerSizePx =
    handle === 'top' ? (height * drawerSize) / 100 : (width * drawerSize) / 100;
  const initialPosition = useRef({ x: 0, y: 0 });
  const lastSizeRef = useRef(defaultSize);

  const handleMouseDown = (e: RCTMouseEvent<SVGSVGElement>) => {
    initialPosition.current = { x: e.clientX, y: e.clientY };

    document.addEventListener('mouseup', handleMouseUp, true);
    document.addEventListener('mousemove', handleMouseMove, true);
    document.body.style.pointerEvents = 'none';
  };

  const handleMouseUp = () => {
    document.removeEventListener('mouseup', handleMouseUp, true);
    document.removeEventListener('mousemove', handleMouseMove, true);
    document.body.style.pointerEvents = 'auto';
  };

  const handleMouseMove = (e: MouseEvent) => {
    const dx = e.clientX - initialPosition.current.x;
    const dy = e.clientY - initialPosition.current.y;

    let newSize = 0;

    if (handle === 'top') {
      newSize = drawerSizePx - dy;
    }

    if (handle === 'bottom') {
      newSize = drawerSizePx + dy;
    }

    if (handle === 'left') {
      newSize = drawerSizePx - dx;
    }

    if (handle === 'right') {
      newSize = drawerSizePx + dx;
    }
    const newSizePercent =
      handle === 'top' ? (newSize / height) * 100 : (newSize / width) * 100;

    if (maxSizeParsed && maxSizeParsed <= newSizePercent) {
      return;
    }
    if (minSizeParsed && minSizeParsed >= newSizePercent) {
      return;
    }
    onResize && onResize(newSize);
    setDrawerSize(newSizePercent);
  };

  useDidUpdateEffect(() => {
    if (maxSizeParsed && drawerSize > maxSizeParsed) {
      setDrawerSize(maxSizeParsed);
    }
    //eslint-disable-next-line
  }, [maxSize]);

  const paddingHandleClass = {
    left: 'pl-2',
    right: 'pr-2',
    top: 'pt-2',
    bottom: 'pb-2',
  };

  const positionHandleClass = {
    left: 'left-0',
    right: 'right-0',
    top: 'top-0',
    bottom: 'bottom-0',
  }

  return (
    <div
      style={
        disabled
          ? { paddingLeft: 0, width: '100%', ...style }
          : {
              [type]: type === 'flex' ? `0 0 ${drawerSizePx}px` : drawerSizePx,
              ...style,
            }
      }
      className={twMerge(`relative w-0 h-full ${paddingHandleClass[handle]}`, className ? className : '')}
    >
      <div
        style={disabled ? { display: 'none' } : {}}
        className={twMerge(
          `absolute flex items-center justify-center z-[9] ${
            handle === 'top' || handle === 'bottom'
              ? 'bottom-auto left-0 right-0 top-auto h-[8px]'
              : 'left-auto right-auto top-0 bottom-0 w-[8px]'
          }`,
          positionHandleClass[handle],
          handleColor || 'bg-panel'
        )}
      >
        {handle === 'top' || handle === 'bottom' ? (
          <MoreHorizIcon onMouseDown={handleMouseDown} className="svg-icon text-gray-400 cursor-ns-resize" />
        ) : (
          <MoreVertIcon onMouseDown={handleMouseDown} className="svg-icon text-gray-400 cursor-ew-resize" />
        )}
        {!disabled && collapsable && (
          <IconButton
            //TODO: adjust for different parameters (now works only for handle: right)
            className="absolute top-[15px] bg-panel hover:bg-primary hover:text-white shadow-sm"
            onClick={() => {
              if (drawerSize) {
                lastSizeRef.current = drawerSize;
                setDrawerSize(0);
              } else {
                setDrawerSize(lastSizeRef.current);
              }
            }}
          >
            {drawerSize ? <ChevronLeftRoundedIcon /> : <ChevronRightRoundedIcon />}
          </IconButton>
        )}
      </div>
      {Boolean(drawerSize) && children}
      {!disabled && !!collapsedTitle && !drawerSize && (
        <div className="absolute inset-0 flex items-center justify-center">
          <span className="-rotate-90 font-normal text-sm text-gray-500 whitespace-nowrap">{collapsedTitle}</span>
        </div>
      )}
    </div>
  );
};

export default ResizableBox;
