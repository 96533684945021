/**
 *
 * ThresholdItemList
 *
 */

import React, { memo, FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import { NotificationConfig, Sort } from 'asserts-types';
import NotificationListItem from '../NotificationListItem/NotificationListItem.component';
import { LoadingPlaceholder, PanelContainer } from '@grafana/ui';
import TableSortButton from 'features/Entities/components/TableSortButton/TableSortButton.component';

interface IProps {
  fields: string[];
  fetchingList: boolean;
  list: NotificationConfig[];
  onUpdate: (item: NotificationConfig) => void;
  onDelete?: (item: NotificationConfig) => void;
  onSort: (sort: Sort | null) => void;
  orderBy: Sort | null;
  suppress?: boolean;
}

const isFieldFromMessages = (
  messagesObj: typeof messages,
  field: string,
): field is keyof typeof messages => messagesObj.hasOwnProperty(field);

const NotificationList: FunctionComponent<IProps> = ({
  orderBy,
  fetchingList,
  list,
  onUpdate,
  onDelete,
  fields,
  onSort,
  suppress,
}) => {
  const intl = useIntl();

  const handleSort = (fieldToSort: string) => {
    const order = orderBy?.order === 'asc' ? 'desc' : 'asc';
    let field = `matchLabels.${fieldToSort}`;

    if (fieldToSort === 'for') {
      field = 'for';
    }
    if (fieldToSort === 'asserts_receiver') {
      field = `alertLabels.${fieldToSort}`;
    }

    onSort({ field, order });
  };

  return (
    <PanelContainer className="p-6 my-8">
      {fetchingList ? (
        <div className="flex items-center justify-center p-8">
          <LoadingPlaceholder text="Loading" />
        </div>
      ) : (
        <>
          <p className="mb-4 text-xl">{intl.formatMessage(messages.title)}</p>
          <table className="w-full table-fixed">
            <thead>
              <tr>
                <td className="p-4 divider-b">
                  <TableSortButton
                    className="headerCell"
                    active={orderBy?.field === 'name'}
                    direction={orderBy?.order}
                    onClick={() =>
                      onSort({
                        field: 'name',
                        order: orderBy?.order === 'asc' ? 'desc' : 'asc',
                      })
                    }
                  >
                    {intl.formatMessage(messages.ruleName)}
                  </TableSortButton>
                </td>

                {fields.map((field) => (
                  <td className="p-4 divider-b" key={field}>
                    <TableSortButton
                      className="headerCell"
                      active={
                        orderBy?.field === field ||
                        orderBy?.field === `matchLabels.${field}` ||
                        orderBy?.field === `alertLabels.${field}`
                      }
                      direction={orderBy?.order}
                      onClick={() => handleSort(field)}
                    >
                      {isFieldFromMessages(messages, field) ? intl.formatMessage(messages[field]) : ''}
                    </TableSortButton>
                  </td>
                ))}
                {!suppress && (
                  <td className="w-[5%] p-4 divider-b">
                    <TableSortButton
                      className="headerCell"
                      active={orderBy?.field === 'silenced'}
                      direction={orderBy?.order}
                      onClick={() =>
                        onSort({
                          field: 'silenced',
                          order: orderBy?.order === 'asc' ? 'desc' : 'asc',
                        })
                      }
                    >
                      {intl.formatMessage(messages.enabled)}
                    </TableSortButton>
                  </td>
                )}
                <td className="w-[10%] p-4 divider-b" width="10%"></td>
              </tr>
            </thead>
            <tbody>
              {list.length ? (
                list.map((item) => (
                  <NotificationListItem
                    key={item.name}
                    fields={fields}
                    item={item}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                    suppress={suppress}
                  />
                ))
              ) : (
                <div className="p-4">{intl.formatMessage(messages.emptyList)}</div>
              )}
            </tbody>
          </table>
        </>
      )}
    </PanelContainer>
  );
};

export default memo(NotificationList);
