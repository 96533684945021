import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SloContainerState, Order } from 'asserts-types'
import moment from 'moment';
import { stringToDate } from 'helpers/Date.helper';


const initialState: SloContainerState = {
  endTime: 'now',
  isLoading: false,
  expandedItems: [],
  itemsZoomedLeft: {},
  itemsZoomedWidth: {},
  scrollPosition: 0,
  search: '',
  order: 'desc',
  orderBy: 'budgetRemainingPersent',
};

export const slice = createSlice({
  name: 'slo',

  initialState,

  reducers: {
    setEndTime: (state, action: PayloadAction<number | string>) => {
      const newEndTime = stringToDate(action.payload);

      if (newEndTime.isAfter(moment())) {
        state.endTime = 'now';
      } else {
        state.endTime = action.payload;
      }
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    toggleExpandItem: (state, action: PayloadAction<string>) => {
      if (state.expandedItems.indexOf(action.payload) !== -1) {
        state.expandedItems = state.expandedItems.filter((item) => item !== action.payload);
      } else {
        state.expandedItems.push(action.payload);
      }
    },
    setItemsZoomedLeft: (
      state,
      action: PayloadAction<{
        itemHash: string;
        left: number;
      }>
    ) => {
      const { left, itemHash } = action.payload;
      state.itemsZoomedLeft[itemHash] = left;
    },
    setItemsZoomedWidth: (
      state,
      action: PayloadAction<{
        itemHash: string;
        width: number;
      }>
    ) => {
      const { width, itemHash } = action.payload;
      state.itemsZoomedWidth[itemHash] = width;
    },
    setScrollPosition: (state, action: PayloadAction<number>) => {
      state.scrollPosition = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setOrderBy: (state, action: PayloadAction<string>) => {
      state.orderBy = action.payload;
    },
    setOrder: (state, action: PayloadAction<Order>) => {
      state.order = action.payload;
    },
  },
});

export const {
  setEndTime,
  setIsLoading,
  toggleExpandItem,
  setItemsZoomedLeft,
  setItemsZoomedWidth,
  setScrollPosition,
  setSearch,
  setOrderBy,
  setOrder,
} = slice.actions;

export default slice.reducer;
