/**
 *
 * HoveredButtons
 *
 */

import React, { memo, FunctionComponent, MouseEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import TagLabel from 'components/TagLabel/TagLabel';
import DashboardOutlinedIcon from 'assets/material-icons/dashboard_FILL0_wght400_GRAD0_opsz24.svg';
import AddToWorkbenchButtonComponent from '../../../Assertions/components/AddToWorkbenchButton/AddToWorkbenchButton.component';
import { AssertionScore } from 'asserts-types';
import { setActiveEntityDetails, setTimeRange } from 'features/App/App.slice';
import { Tooltip } from 'components/Tooltip/Tooltip.component';
import ConnectedEntitiesMenuPopover from 'features/Assertions/components/ConnectedEntitiesMenu/ConnectedEntitiesMenuPopover.component';
import { IconButton } from 'components/IconButton/IconButton.component';

interface IProps {
  score: AssertionScore;
  start: number | string;
  end: number | string;
  setTimeRange: typeof setTimeRange;
  setActiveEntityDetails: typeof setActiveEntityDetails;
}

const HoveredButtons: FunctionComponent<IProps> = ({ score, start, end, setTimeRange, setActiveEntityDetails }) => {
  const intl = useIntl();

  const openDetails = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setTimeRange({ start, end });
    setActiveEntityDetails({
      type: score.type,
      name: score.name,
      scope: score.scope,
    });
  };

  const [tooltipOpened, setTooltipOpened] = useState(false);
  const [connectedOpened, setConnectedOpened] = useState(false);

  return (
    <>
      <div
        className={`group-hover/item:hidden absolute right-0 h-8 ${tooltipOpened || connectedOpened ? '!hidden' : ''}`}
      >
        <TagLabel entityType={score.type} properties={score.properties} scope={score.scope} />
      </div>
      <div
        className={`whitespace-nowrap group-hover/item:!visible invisible ${
          tooltipOpened || connectedOpened ? '!visible' : ''
        }`}
      >
        <AddToWorkbenchButtonComponent
          active={tooltipOpened}
          entityKeys={[{ name: score.name, type: score.type, scope: score.scope }]}
          onOpen={() => setTooltipOpened(true)}
          onClose={() => setTooltipOpened(false)}
        />
        <ConnectedEntitiesMenuPopover
          name={score.name}
          type={score.type}
          scope={score.scope}
          onOpenChange={setConnectedOpened}
        />

        <Tooltip content={intl.formatMessage(messages.individualStat)} placement="top">
          <IconButton variant="primary" onClick={openDetails}>
            <DashboardOutlinedIcon />
          </IconButton>
        </Tooltip>
      </div>
    </>
  );
};

export default memo(HoveredButtons);
