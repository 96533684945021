import { useMemo } from 'react';
import qs from 'qs';
import { SloQueryParams } from 'asserts-types'
import { setEndTime } from '../Slo.slice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { dateToParams, stringToDate } from 'helpers/Date.helper';


export default function useSlosEndTime() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const queryParams = (qs.parse(
    window.location.search.slice(1),
  ) as unknown) as SloQueryParams;

  let endTime = useSelector((state: RootState) => state.slo.endTime);

  if(queryParams.endTime) {
    endTime = queryParams.endTime;
  }

  const endTimeMemoized = useMemo(() => stringToDate(endTime).valueOf(), [
    endTime,
  ]);

  const changeEndTime = (value: number | string) => {
    dispatch(setEndTime(value));

    const queryParams = qs.stringify({
      endTime: dateToParams(value),
    } as SloQueryParams);

    navigate(`?${queryParams}`, { state: { timestamp: Date.now() } });
  };

  return { endTime, endTimeMemoized, changeEndTime };
}
