import { TimeRange, dateTimeParse } from '@grafana/data';
import { usePluginComponent, config } from '@grafana/runtime';
import { LoadingPlaceholder } from '@grafana/ui';
import { APP_PLUGIN_IDS, EXTERNAL_PLUGIN_VIEWS } from 'app/constants';
import { Entity } from 'asserts-types';
import React, { memo, useMemo } from 'react';
import { isAppEnabled } from 'utils/app-plugin';

interface Props {
  entity: Pick<Entity, 'scope' | 'type' | 'properties' | 'name'> | undefined;
  initialStart: string | number;
  initialEnd: string | number;
  tracesDataSourceUID: string | undefined;
  logsDataSourceUID: string | undefined;
  onTimeRangeChange?: (timeRange: TimeRange) => void;
}

interface FrontendO11yAppViewProps {
  appId: string;
  initialTimeRange: TimeRange;
  tracesDataSourceUID: string | undefined;
  logsDataSourceUID: string | undefined;
  onTimeRangeChange?: (timeRange: TimeRange) => void;
}

const FrontendO11yEntityView: React.FC<Props> = ({
  entity,
  initialStart,
  initialEnd,
  tracesDataSourceUID,
  logsDataSourceUID,
  onTimeRangeChange,
}) => {
  const { component: FrontendO11yAppView, isLoading } = usePluginComponent<FrontendO11yAppViewProps>(
    EXTERNAL_PLUGIN_VIEWS.FRONTEND_O11Y_APP_OVERVIEW_EXTENSION_V1
  );

  const timeRange: TimeRange = useMemo(() => {
    const from = dateTimeParse(initialStart);
    const to = dateTimeParse(initialEnd);
    return {
      from,
      to,
      raw: {
        from: typeof initialStart === 'string' ? initialStart : from,
        to: typeof initialEnd === 'string' ? initialEnd : to,
      },
    };
  }, [initialStart, initialEnd]);

  const isFrontendO11yEnabled = isAppEnabled(APP_PLUGIN_IDS.FRONTEND_O11Y_APP);

  const { app_id } = entity?.properties ?? {};

  if (app_id && isFrontendO11yEnabled && FrontendO11yAppView) {
    return (
      <FrontendO11yAppView
        appId={app_id.toString()}
        initialTimeRange={timeRange}
        onTimeRangeChange={onTimeRangeChange}
        tracesDataSourceUID={tracesDataSourceUID}
        logsDataSourceUID={logsDataSourceUID}
      />
    );
  }

  if (isLoading) {
    return <LoadingPlaceholder text="Loading..." />;
  }

  return <div>No data</div>;
};

export default memo(FrontendO11yEntityView);
