/**
 *
 * EmptyAssertions
 *
 */

import React, { memo, FunctionComponent, MouseEvent } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import TopSearch from 'components/TopSearch/TopSearch.component';
import useTopServices from '../../hooks/useTopServices';
import { LoadingBar } from '@grafana/ui';

interface IProps {
  showNoResultsFound: boolean;
}

const EmptyAssertions: FunctionComponent<IProps> = ({ showNoResultsFound }) => {
  const intl = useIntl();
  const { refetch: fetchTopServices, isFetching } = useTopServices();

  return (
    <div className="mx-auto my-0 p-8 h-full flex flex-col gap-8 overflow-y-auto">
      {isFetching && (
        <div className="absolute top-0 inset-x-0">
          <LoadingBar width={300} />
        </div>
      )}

      <div className="text-center pt-8 space-y-8">
        {showNoResultsFound && (
          <div className="divider-b">
            <div className="text-center pb-8">
              <p className="text-xl pb-1">{intl.formatMessage(messages.noAssertions)}</p>
              <p className="text-secondary">{intl.formatMessage(messages.noAssertionsHint)}</p>
            </div>
          </div>
        )}
        <div>
          <p className="text-2xl pb-1">{intl.formatMessage(messages.emptyHint)}</p>
          <p className="text-secondary mt-4 text-sm">
            {intl.formatMessage(messages.emptyHint2, {
              top: (
                <a
                  href="#"
                  className={`${
                    isFetching ? 'text-secondary pointer-events-none animate-pulse' : 'text-primary hover:underline'
                  }`}
                  onClick={(e: MouseEvent) => {
                    e.preventDefault();
                    fetchTopServices();
                  }}
                >
                  {intl.formatMessage(messages.viewTopServices)}
                </a>
              ),

              br: <br />,
            })}
          </p>
        </div>
      </div>
      <TopSearch />
    </div>
  );
};

export default memo(EmptyAssertions);
