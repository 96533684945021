/*
 * TopInsightsSlo Messages
 *
 * This contains all the text for the TopInsightsSlo component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.TopInsightsSlo';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'SLO',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Name',
  },
  target: {
    id: `${scope}.target`,
    defaultMessage: 'Target',
  },
  actual: {
    id: `${scope}.actual`,
    defaultMessage: 'Actual',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status',
  },
  noData: {
    id: `${scope}.noData`,
    defaultMessage: 'No Data for given time range',
  },
  incidents: {
    id: `${scope}.incidents`,
    defaultMessage:
      '{count, plural, =0 {No incidents} one {# Incident} other {# Incidents}}',
  },
});
