/**
 *
 * GraphTooltip
 *
 */

import { G6GraphEvent, Graph } from '@antv/g6';
import React, { memo, FunctionComponent, useEffect, useState, useCallback } from 'react';
import PropertiesPopoverComponent from '../../../PropertiesPopover/PropertiesPopover.component';
import { Entity, EntityProperties, GraphCustomNode, Scope } from 'asserts-types';
import useAppDisplayConfig from 'hooks/useAppDisplayConfig';
import { useTheme2 } from '@grafana/ui';
import { Popover, PopoverContent } from 'components/Popover/Popover.component';

interface IProps {
  graph: Graph | null | undefined;
  disableTooltip?: boolean;
  propertyName?: string;
  lastUpdateTime?: number;
  CustomTooltip?: FunctionComponent<{
    properties: EntityProperties;
    tagColor: string;
    type: string;
    label: string;
    scope: Scope;
    itemId?: string;
  }>;
  forceActiveNodeId?: string;
  onConnectedItemClick?: (entity: Entity | undefined) => void;
}

const GraphTooltip: FunctionComponent<IProps> = ({
  graph,
  disableTooltip,
  propertyName,
  lastUpdateTime,
  CustomTooltip,
  forceActiveNodeId,
  onConnectedItemClick,
}) => {
  const theme = useTheme2();
  const [position, setPosition] = useState<{
    x: number;
    y: number;
    width: number;
    height: number;
  } | null>(null);
  const [activeNode, setActiveNode] = useState<GraphCustomNode | null>(null);
  const [activeNodeId, setActiveNodeID] = useState<string | null>(null);
  const { data: displayConfig } = useAppDisplayConfig();

  // open tooltip if it was forced outside
  useEffect(() => {
    if (forceActiveNodeId && graph) {
      const activeItem = graph.findById(forceActiveNodeId);
      setActiveNode(activeItem.getModel() as GraphCustomNode);
      setActiveNodeID(forceActiveNodeId);
      const coordinates = graph.getClientByPoint(activeItem.getBBox().x || 0, activeItem.getBBox().y || 0);
      const width = activeItem.getBBox().width * graph.getZoom();
      const height = activeItem.getBBox().height * graph.getZoom();

      setPosition({
        ...coordinates,
        width,
        height,
      });
    } else {
      setActiveNodeID(null);
      setActiveNode(null);
    }
  }, [forceActiveNodeId, graph]);

  useEffect(() => {
    if (!graph || disableTooltip) {
      return;
    }
    let timeoutId: number | null = null;

    const handleMouseEnter = (e: G6GraphEvent) => {
      const coordinates = graph.getClientByPoint(e.item.getBBox().x || 0, e.item.getBBox().y || 0);
      const width = e.item.getBBox().width * graph.getZoom();
      const height = e.item.getBBox().height * graph.getZoom();

      setPosition({
        ...coordinates,
        width,
        height,
      });

      timeoutId = window.setTimeout(() => {
        setActiveNode(e.item.getModel() as GraphCustomNode);
        setActiveNodeID(e.item.getID());
      }, 300);
    };

    const handleMouseMove = (e: G6GraphEvent) => {
      const coordinates = graph.getClientByPoint(e.item.getBBox().x || 0, e.item.getBBox().y || 0);
      const width = e.item.getBBox().width * graph.getZoom();
      const height = e.item.getBBox().height * graph.getZoom();

      setPosition({
        ...coordinates,
        width,
        height,
      });
    };

    const handleMouseLeave = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      setActiveNode(null);
      setActiveNodeID(null);
    };

    graph?.on('node:mouseenter', handleMouseEnter);
    graph?.on('node:mousemove', handleMouseMove);
    graph?.on('node:mouseleave', handleMouseLeave);

    return () => {
      graph?.off('node:mouseleave', handleMouseLeave);
      graph?.off('node:mousemove', handleMouseMove);
      graph?.off('node:mouseenter', handleMouseEnter);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graph]);

  const propertiesToRender = propertyName
    ? { [propertyName]: activeNode?.properties[propertyName] }
    : activeNode?.properties;

  const tagColor =
    displayConfig?.graph?.overrides?.entities?.[activeNode?.entityType as string]?.color ||
    displayConfig?.graph?.entities?.[activeNode?.entityType as string]?.color ||
    displayConfig?.graph?.defaultEntity?.color ||
    theme.colors.primary.main;

  const getBoundingClientRect = useCallback(
    () => new DOMRect(position?.x, position?.y, position?.width, position?.height),
    [position]
  );

  return (
    <Popover
      open={Boolean(activeNode && !disableTooltip)}
      getBoundingClientRect={getBoundingClientRect}
      placement="right-start"
      flipOptions={{ crossAxis: false }}
      offset={{ mainAxis: -10 * (graph?.getZoom() || 1) }}
    >
      <PopoverContent>
        <div
          className="bg-panel px-4 pb-4"
          id="graph-tooltip"
          style={{ boxShadow: theme.shadows.z2, borderRadius: theme.shape.radius.default }}
        >
          {CustomTooltip ? (
            <CustomTooltip
              itemId={activeNodeId || ''}
              properties={propertiesToRender || {}}
              tagColor={tagColor}
              type={activeNode?.entityType || ''}
              label={activeNode?.fullLabel || activeNode?.label || ''}
              scope={activeNode?.scope || {}}
            />
          ) : (
            <PropertiesPopoverComponent
              connectedEntityTypes={activeNode?.connectedEntityTypes}
              properties={propertiesToRender || {}}
              type={activeNode?.entityType || ''}
              label={activeNode?.fullLabel || activeNode?.label}
              scope={activeNode?.scope || {}}
              entityAssertion={activeNode?.assertion}
              connectedAssertion={activeNode?.connectedAssertion}
              lastUpdateTime={lastUpdateTime}
              activeNode={activeNode ? activeNode : undefined}
              onConnectedItemClick={onConnectedItemClick}
              onKpiOpen={() => {
                setActiveNode(null);
                setActiveNodeID(null);
              }}
            />
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default memo(GraphTooltip);
