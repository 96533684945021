/**
 *
 * AssertionsSortBy
 *
 */

import React, { memo, FunctionComponent, useMemo } from 'react';
import { useIntl } from 'react-intl';

import messages from './messages';
import { setSort } from '../../Assertions.slice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'app/store';
import { Dropdown, Icon, Menu } from '@grafana/ui';

interface IProps {}

const AssertionsSortBy: FunctionComponent<IProps> = () => {
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const sort = useAppSelector((state) => state.assertions.sort);

  const menuItems = useMemo(
    () => [
      {
        label: formatMessage(messages.time),
        orderBy: 'time',
        action: () => {
          dispatch(setSort({ field: 'time', order: 'asc' }));
        },
      },
      {
        label: formatMessage(messages.score),
        orderBy: 'totalScore',
        action: () => {
          dispatch(setSort({ field: 'totalScore', order: 'desc' }));
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <Dropdown
        overlay={
          <Menu>
            {menuItems.map((item) => (
              <Menu.Item
                label={item.label}
                active={sort.field === item.orderBy}
                key={item.label}
                onClick={item.action}
              />
            ))}
          </Menu>
        }
      >
        <div className="text-xs flex items-center cursor-pointer space-x-2 text-secondary hover:underline">
          <Icon name={sort.order === 'asc' ? 'sort-amount-up' : 'sort-amount-down'} />
          <div>
            <span>{formatMessage(messages.sortBy)} </span>
            <span className="capitalize">{menuItems.find((o) => o.orderBy === sort.field)?.label}</span>
          </div>
        </div>
      </Dropdown>
    </>
  );
};

export default memo(AssertionsSortBy);
