/*
 * ThresholdResource Messages
 *
 * This contains all the text for the ThresholdResource component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.ThresholdResource';

export default defineMessages({
  customThresholds: {
    id: `${scope}.customThresholds`,
    defaultMessage: 'Custom Resource Thresholds',
  },
  defaultGlobal: {
    id: `${scope}.defaultGlobal`,
    defaultMessage: 'Resource Thresholds',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Resource Thresholds',
  },
});
