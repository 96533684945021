import { useQuery } from '@tanstack/react-query';
import { apiHttpService } from 'app/api-http-service';

export const USE_VENDOR_RULES_QUERY_KEY = 'vendor-rules';

export default function useVendorRules() {
  return useQuery([USE_VENDOR_RULES_QUERY_KEY], async () => {
    const vendorRulesListPromise = apiHttpService
      .get<{ vendorNames: string[] }>('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/vendor-names')
      .then((res) => res.data);

    const disabledRulesPromise = apiHttpService
      .get<{ disabled: string[] }>('/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/config/prom-rules-disable')
      .then((res) => res.data);

    const [vendorRulesListData, disabledRulesData] = await Promise.all([vendorRulesListPromise, disabledRulesPromise]);

    return vendorRulesListData.vendorNames.map((name) => ({
      name,
      enabled: !disabledRulesData.disabled.includes(name),
    }));
  });
}
