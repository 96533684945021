/*
 * AddTopInsightsSearchQuery Messages
 *
 * This contains all the text for the AddTopInsightsSearchQuery component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.AddTopInsightsSearchQuery';

export default defineMessages({
  write: {
    id: `${scope}.write`,
    defaultMessage: 'Write a search query',
  },
  savedSearch: {
    id: `${scope}.savedSearch`,
    defaultMessage: 'Saved search',
  },
  selectHint: {
    id: `${scope}.selectHint`,
    defaultMessage: 'Select to add',
  },
});
