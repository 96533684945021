import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Box, Button, Card, Grid, Stack, Tab, TabsBar, Text, useStyles2 } from '@grafana/ui';
import { ROUTES } from 'global-constants';
import React, { useState } from 'react';
import { prefixRoute } from 'utils/utils.routing';
import entitiesImageSrc from '../images/entities.png';
import wbImageSrc from '../images/wb.png';
import dashboardsImageSrc from '../images/dashboards.png';
import assertionsImageSrc from '../images/assertions.png';
import { useNavigate } from 'react-router-dom';
import { isAdmin } from 'utils/permissions';

const TABS = [
  {
    label: 'Entity Explorer',
    value: 'entities',
    image: entitiesImageSrc,
    hint: 'See a comprehensive view of your stack and understand how connected entities affect each other.',
  },
  {
    label: 'RCA Workbench',
    value: 'wb',
    image: wbImageSrc,
    hint: 'Determine the root cause of issues by comparing related events.',
  },
  {
    label: 'Pre-built dashboards',
    value: 'dashboards',
    image: dashboardsImageSrc,
    hint: 'Drill in to learn more about a specific entity with updated real-time dashboards and views.',
  },
  {
    label: 'Assertions',
    value: 'assertions',
    image: assertionsImageSrc,
    hint: 'Assertions distinguish between issues that require immediate attention and harmless events',
  },
];

const STEPS = [
  { label: 'Check compatibility', hint: 'Asserts automatically checks your telemetry data' },
  { label: 'Configure', hint: 'Choose to monitor common frameworks like Kubernetes, OpenTelemetry, Java, and MySQL' },
  { label: 'Enable', hint: 'Generate metrics that populate insights about your applications and services' },
];

function StepCircle({ index }: { index: number }) {
  const styles = useStyles2(getStyles);
  return (
    <div className={styles.circle}>
      <svg xmlns="http://www.w3.org/2000/svg" width="65" height="64" viewBox="0 0 65 64" fill="none">
        <circle cx="32.5" cy="32" r="32" fill="url(#paint0_linear_4_302)" />
        <defs>
          <linearGradient
            id="paint0_linear_4_302"
            x1="64.5"
            y1="31.5077"
            x2="0.500008"
            y2="31.5077"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F55F3E" />
            <stop offset="1" stopColor="#FF8833" />
          </linearGradient>
        </defs>
      </svg>
      <h1 className={styles.index}>{index}</h1>
    </div>
  );
}

export default function HomeLanding() {
  const styles = useStyles2(getStyles);

  const connectDataPath = prefixRoute([ROUTES.CONFIGURATION, ROUTES.CONNECT_DATA].join('/'));

  const [activeTab, setActiveTab] = useState<'entities' | 'wb' | 'dashboards' | 'assertions'>('entities');
  const navigate = useNavigate();


  return (
    <Stack direction="column" gap={2}>
      <Card className={styles.card}>
        <Box display="flex" direction="column" gap={3} maxWidth="828px" marginTop={0.5} marginBottom={4}>
          <Text element="h2" textAlignment="center">
            Asserts adds intelligence to telemetry data to help you understand the behavior of your applications and
            services.
          </Text>
          <Text element="h2" textAlignment="center">
            Uncover root causes quickly by automatically correlating related issues.
          </Text>
        </Box>
        <Button
          onClick={() => navigate(connectDataPath)}
          size="lg"
          disabled={!isAdmin}
          tooltip={!isAdmin ? 'Only Admin users can enable Asserts' : undefined}
        >
          Get started
        </Button>
      </Card>
      <Card className={styles.card}>
        <Text element="h2" textAlignment="center">
          Get started in 3 easy steps:
        </Text>

        {/* @ts-ignore */}
        <Grid columns={STEPS.length} gap={4}>
          {STEPS.map((step, index) => (
            <div className={styles.step} key={index}>
              <StepCircle index={index + 1} />
              <Text element="h3" textAlignment="center">
                {step.label}
              </Text>
              <Text element="p" textAlignment="center">
                {step.hint}
              </Text>
            </div>
          ))}
        </Grid>
      </Card>
      <Card className={styles.card}>
        <TabsBar>
          {TABS.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              active={activeTab === tab.value}
              onChangeTab={() => {
                setActiveTab(tab.value as any);
              }}
            />
          ))}
        </TabsBar>
        {TABS.map((tab) => (
          <div key={tab.value} style={{ display: tab.value === activeTab ? 'block' : 'none' }}>
            <Box paddingY={4}>
              <Text element="p" textAlignment="center">
                {tab.hint}
              </Text>
            </Box>
            <div>
              <img src={tab.image} alt={tab.label} className={styles.image} width={721} height={426} />
            </div>
          </div>
        ))}
      </Card>
    </Stack>
  );
}

const getStyles = (theme: GrafanaTheme2) => ({
  circle: css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  index: css`
    position: absolute;
    margin: 0;
    display: block;
  `,
  card: css`
    padding: ${theme.spacing(4)};
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > h2:first-of-type {
      display: none;
    }
  `,
  step: css`
    margin-top: ${theme.spacing(5)};
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    max-width: 400px;
  `,
  image: css`
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: block;
    max-width: 100%;
    height: auto;
    apsect-ratio: 16/9;
  `,
});
