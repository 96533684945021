/*
 * IncidentsTopMenu Messages
 *
 * This contains all the text for the IncidentsTopMenu component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.IncidentsTopMenu';

export default defineMessages({
  showSlo: {
    id: `${scope}.showSlo`,
    defaultMessage: 'Show SLOs',
  },
  showIncident: {
    id: `${scope}.showIncident`,
    defaultMessage: 'Show Incidents',
  },
  search: {
    id: `${scope}.search`,
    defaultMessage: 'Search by name',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Notifications',
  },
  filter: {
    id: `${scope}.filter`,
    defaultMessage: 'Filter',
  },
});
