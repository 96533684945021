/*
 * AddToWorkbenchButton Messages
 *
 * This contains all the text for the AddToWorkbenchButton component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.AddToWorkbenchButton';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the AddToWorkbenchButton component!',
  },
  add: {
    id: `${scope}.add`,
    defaultMessage: 'Add to Workbench for inspecting',
  },
  added: {
    id: `${scope}.added`,
    defaultMessage: 'Added to Workbench',
  },
  itemsAdded: {
    id: `${scope}.itemsAdded`,
    defaultMessage: '{count, plural, =0 {Zero entities added} one {entity added} other {entities added}}',
  },
  go: {
    id: `${scope}.go`,
    defaultMessage: 'Open Workbench',
  },
  delete: {
    id: `${scope}.delete`,
    defaultMessage: 'Delete',
  },
});
