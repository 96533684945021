import { useQuery } from '@tanstack/react-query';
import { Entity, TraceUrlParams } from 'asserts-types';
import { fetchAssertionTracesUrl } from 'services/Assertion.service';

interface UseOpenTracesParams {
  start: number;
  end: number;
  additionalLabels?: Record<string, string>;
  params?: TraceUrlParams;
  entity: Entity | undefined;
}

const TRACES_METRICS_ASSERTIONS = ['LatencyAverageBreach', 'LatencyAverageAnomaly', 'LatencyP99ErrorBuildup'];

export default function useTracesUrl({ start, end, additionalLabels, entity, params }: UseOpenTracesParams) {
  const labels = {
    ...(entity?.properties || {}),
    ...(entity
      ? {
          asserts_entity_name: entity.name,
          asserts_entity_type: entity.type,
          asserts_env: entity.scope?.env,
          asserts_site: entity.scope?.site,
          namespace: entity.scope?.namespace,
        }
      : {}),
    ...(additionalLabels || {}),
  };
  const shouldSendMetrics = additionalLabels?.alertname
    ? TRACES_METRICS_ASSERTIONS.includes(additionalLabels?.alertname)
    : false;

  const values =
    shouldSendMetrics && params?.values
      ? params.values.filter((m) => m.time >= start.valueOf() && m.time <= end.valueOf())
      : [];

  const threshold =
    shouldSendMetrics && params?.threshold
      ? params.threshold.filter((v) => v.time >= start.valueOf() && v.time <= end.valueOf())
      : [];

  return useQuery(
    ['traces-url', start, end, entity],
    () => fetchAssertionTracesUrl(start, end, labels, { values, threshold }),
    {
      enabled: Boolean(start && end && entity),
      keepPreviousData: true,
    }
  );
}
