import { defineMessages } from 'react-intl';

export const scope = 'features.AssertionsListByEntityItem';

export default defineMessages({
  entities: {
    id: `${scope}.entity`,
    defaultMessage: `{itemCount, plural,
      =0 {no entities}
      one {# entity}
      other {# entities}
  }`,
  },
  inboundClientErrors: {
    id: `${scope}.inboundClientErrors`,
    defaultMessage: `View Inbound Client Errors`,
  },
});
