import { useQuery } from '@tanstack/react-query';
import { apiHttpService } from 'app/api-http-service';
import { ONBOARDING_ACTIVE_SERIES_LIMIT } from 'global-constants';
import TrackingHelper from 'helpers/Tracking.helper';
import { useDataSources } from 'hooks/useDataSources';
import { useMetricsDataSource } from 'hooks/useMetricsDatasource';

interface Props {
  enabled?: boolean;
}

export const useCardinalityCheck = ({ enabled }: Props) => {
  const { data: promDatasource } = useMetricsDataSource();

  return useQuery(
    ['cardinality-check'],
    () =>
      apiHttpService
        .get<{ series_count_total: number }>(
          `/api/datasources/proxy/uid/${promDatasource.uid}/api/v1/cardinality/label_values?count_method=active&label_names[]=__name__&selector={}`
        )
        .then((res) => {
          const overLimit = res.data.series_count_total > ONBOARDING_ACTIVE_SERIES_LIMIT;
          if (overLimit) {
            TrackingHelper.trackOnboardingOverSeriesLimit();
          }
          return { cardinalityCheckPassed: !overLimit };
        }),
    { enabled }
  );
};
