/*
 * IncidentTimeLineItem Messages
 *
 * This contains all the text for the IncidentTimeLineItem component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.IncidentTimeLineItem';

export default defineMessages({
  badEventQuery: {
    id: `${scope}.badEventQuery`,
    defaultMessage: 'Bad Events Query',
  },
  totalEventQuery: {
    id: `${scope}.totalEventQuery`,
    defaultMessage: 'Total Events Query',
  },
  measurementQuery: {
    id: `${scope}.measurementQuery`,
    defaultMessage: 'Measurement Query',
  },
  view: {
    id: `${scope}.view`,
    defaultMessage: 'View',
  },
  close: {
    id: `${scope}.close`,
    defaultMessage: 'Close',
  },
});
