/**
 *
 * ThresholdListItem
 *
 */

import React, { memo, FunctionComponent, useState } from 'react';
import { CustomRule } from 'asserts-types';
import EditIcon from 'assets/material-icons/edit_FILL0_wght400_GRAD0_opsz24.svg';
import DeleteOutlineIcon from 'assets/material-icons/delete_FILL0_wght400_GRAD0_opsz24.svg';
import has from '../../../../helpers/has.helper';
import CustomRulesFormComponent from '../CustomRules/components/CustomRulesForm/CustomRulesForm.component';
import { connect, ConnectedProps } from 'react-redux';
import { deleteRule } from 'services/ManageAssertions.service';
import TooltipConfirmationComponent from 'features/Assertions/components/TooltipConfirmation/TooltipConfirmation.component';
import { IconButton } from 'components/IconButton/IconButton.component';

interface IProps {
  item: CustomRule;
  fields: string[];
  onUpdate: (item: CustomRule) => void;
  onDelete: (item: CustomRule) => void;
}

const connector = connect(null);

type PropsFromRedux = ConnectedProps<typeof connector>;

const CustomRulesListItem: FunctionComponent<IProps & PropsFromRedux> = ({
  item,
  onUpdate,
  onDelete,
  fields,
}) => {
  const [editing, setEditing] = useState(false);
  const [updating, setUpdating] = useState(false);

  const handleDelete = () => {
    setUpdating(true);
    deleteRule(item).then(() => {
      onDelete(item);
      setEditing(false);
      setUpdating(false);
    });
  };

  if (editing) {
    return (
      <CustomRulesFormComponent
        onDelete={handleDelete}
        onSave={onUpdate}
        tableRow
        onClose={() => setEditing(false)}
        preloadedValue={item}
      />
    );
  }

  return (
    <tr className="hover:bg-hover transition-all">
      {fields.map((field) => (
        <td className="p-4 divider-b" key={field}>
          {has<typeof item, string, string>(item, field) && item[field]}
          {has<typeof item.labels, string, string>(item.labels, field) && item.labels[field]}
        </td>
      ))}
      <td className="p-4 divider-b">
        <div className="flex">
          {!editing && (
            <>
              <IconButton onClick={() => setEditing(true)} variant="primary" disabled={updating}>
                <EditIcon />
              </IconButton>
              <TooltipConfirmationComponent action={handleDelete}>
                <IconButton variant="destructive" disabled={updating}>
                  <DeleteOutlineIcon />
                </IconButton>
              </TooltipConfirmationComponent>
            </>
          )}
        </div>
      </td>
    </tr>
  );
};

export default connector(memo(CustomRulesListItem));
