import React from 'react';
import { DataSourceApi } from '@grafana/data';
import {
  QueryRunnerState,
  SceneComponentProps,
  SceneObjectBase,
  SceneObjectRef,
  SceneObjectState,
  SceneQueryRunner,
} from '@grafana/scenes';
import { DataQuery } from '@grafana/schema';
import { Button } from '@grafana/ui';

export interface QueryEditorSceneState extends SceneObjectState {
  dataSource: DataSourceApi;
  queryRunner: SceneQueryRunner;
  query: QueryRunnerState['queries'][number];
}

interface InternalQueryEditorSceneState extends SceneObjectState {
  dataSource: DataSourceApi;
  query: QueryRunnerState['queries'][number];
}

export class QueryEditorScene extends SceneObjectBase<InternalQueryEditorSceneState> {
  static Component = QueryEditorSceneRenderer;

  dataSource: DataSourceApi;
  queryRunnerRef: SceneObjectRef<SceneQueryRunner>;

  constructor(state: QueryEditorSceneState) {
    super({
      query: state.query,
      dataSource: state.dataSource,
    });

    this.dataSource = state.dataSource;

    this.onChange = this.onChange.bind(this);
    this.onRunQuery = this.onRunQuery.bind(this);

    this.queryRunnerRef = state.queryRunner.getRef();
  }

  onChange(query: DataQuery): void {
    this.setState({
      query,
    });
  }

  onRunQuery(): void {
    const queryRunner = this.queryRunnerRef.resolve();

    queryRunner.cancelQuery();
    queryRunner.setState({ queries: [this.state.query] });
    queryRunner.runQueries();
  }
}

export function QueryEditorSceneRenderer({ model }: SceneComponentProps<QueryEditorScene>) {
  const QueryEditor = model.dataSource?.components?.QueryEditor;
  const { query } = model.useState();
  const showRunQueryBtn = ['traceqlSearch', 'serviceMap', 'traceql', 'search'].includes(query.queryType || '');

  if (QueryEditor) {
    return (
      <div className="relative">
        <QueryEditor
          datasource={model.dataSource}
          query={query}
          onRunQuery={model.onRunQuery}
          onChange={model.onChange}
        />
        {showRunQueryBtn && (
          <Button onClick={model.onRunQuery} size="sm" className="absolute top-[4px] right-0 px-[16px]">
            Run query
          </Button>
        )}
      </div>
    );
  }
}
