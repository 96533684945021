import React from 'react';
import { Tab, TabsBar } from '@grafana/ui';
import { ROUTES } from 'global-constants';
import { prefixRoute } from 'utils/utils.routing';
import useBackendStatus from 'hooks/useBackendStatus';
import { isAdmin } from 'utils/permissions';

const linkDashPath = prefixRoute([ROUTES.CONFIGURATION, ROUTES.CONFIGURATION_LINK_DASHBOARDS].join('/'));
const connectDataPath = prefixRoute([ROUTES.CONFIGURATION, ROUTES.CONNECT_DATA].join('/'));
const logsPath = prefixRoute([ROUTES.CONFIGURATION, ROUTES.CONFIGURATION_LOGS].join('/'));
const tracesPath = prefixRoute([ROUTES.CONFIGURATION, ROUTES.CONFIGURATION_TRACES].join('/'));
const displayPath = prefixRoute([ROUTES.CONFIGURATION, ROUTES.CONFIGURATION_DISPLAY].join('/'));

export default function ConfigurationTopMenu() {
  const { data: backendStatus } = useBackendStatus();

  return (
    <TabsBar>
      {/* <Tab
        label="Settings"
        active={location.pathname === prefixRoute(ROUTES.CONFIGURATION)}
        href={prefixRoute(ROUTES.CONFIGURATION)}
      /> */}
      {isAdmin && (
        <Tab label="Connect environment" active={location.pathname.includes(connectDataPath)} href={connectDataPath} />
      )}
      {backendStatus?.enabled && (
        <>
          <Tab label="Link dashboards" active={location.pathname.includes(linkDashPath)} href={linkDashPath} />
          <Tab label="Logs" active={location.pathname.includes(logsPath)} href={logsPath} />
          <Tab label="Traces" active={location.pathname.includes(tracesPath)} href={tracesPath} />
          <Tab label="Display" active={location.pathname.includes(displayPath)} href={displayPath} />
        </>
      )}
    </TabsBar>
  );
}
