import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import { connect, ConnectedProps } from 'react-redux';
import AddRuleTopMenuComponent from '../AddRuleTopMenu/AddRuleTopMenu.component';
import EditIcon from 'assets/material-icons/edit_FILL0_wght400_GRAD0_opsz24.svg';
import DeleteOutlineIcon from 'assets/material-icons/delete_FILL0_wght400_GRAD0_opsz24.svg';
import SnackbarHelper from 'helpers/Snackbar.helper';

import YmlFileEditorComponent, {
  IYmlForm,
} from 'components/YmlFileEditor/YmlFileEditor.component';
import { Sort } from 'asserts-types';
import { deleteRuleFile, fetchAssertionRuleFilesList, fetchRuleFile, saveRuleFile } from 'services/ManageAssertions.service';
import { orderBy } from 'lodash';
import TooltipConfirmationComponent from 'features/Assertions/components/TooltipConfirmation/TooltipConfirmation.component';
import { Button, LoadingPlaceholder, PanelContainer } from '@grafana/ui';
import TableSortButton from 'features/Entities/components/TableSortButton/TableSortButton.component';
import { IconButton } from 'components/IconButton/IconButton.component';

interface IProps {}

const connector = connect(null);

type PropsFromRedux = ConnectedProps<typeof connector>;

const AddRuleFile: FunctionComponent<IProps & PropsFromRedux> = () => {
  const intl = useIntl();

  const [searchQuery, setSearchQuery] = useState('');
  const [filesList, setFilesList] = useState<string[]>([]);
  const [sort, setSort] = useState<Sort | null>(null);
  const [showFileEditor, setShowFileEditor] = useState(false);
  const [activeFile, setActiveFile] = useState<
    | {
        name: string;
        text: string;
      }
    | undefined
  >();

  const [fetchingList, setFetchingList] = useState(true);
  const [fetchingFile, setFetchingFile] = useState(false);
  const [savingFile, setSavingFile] = useState(false);
  const [deletingFile, setDeletingFile] = useState(false);

  const fetchList = () => {
    setFetchingList(true);
    fetchAssertionRuleFilesList().then((res) => {
      setFilesList(res);
      setFetchingList(false);
    });
  };

  useEffect(fetchList, []);

  const handleDeleteFileItem = (fileName: string) => {
    setDeletingFile(true);

    deleteRuleFile(fileName)
      .then(() => {
        setFilesList(filesList.filter((item) => item !== fileName));
      })
      .finally(() => setDeletingFile(false));
  };

  const filesListProcessed = useMemo(
    () =>
      orderBy(
        filesList.filter(
          (item) =>
            item.toLowerCase().includes(searchQuery.toLowerCase()) &&
            item !== 'user-defined-alerts',
        ),
        [],
        sort?.order,
      ),
    [filesList, sort?.order, searchQuery],
  );

  const handleSaveFile = (data: IYmlForm) => {
    setSavingFile(true);
    saveRuleFile(data.textField)
      .then(
        () => {
          fetchList();
          setShowFileEditor(false);
          setActiveFile(undefined);
          SnackbarHelper.success(messages.fileSaved);
        },
        () => SnackbarHelper.error(messages.fileSaveError),
      )
      .finally(() => setSavingFile(false));
  };

  const handleViewEditFile = (fileName: string) => {
    setFetchingFile(true);
    fetchRuleFile(fileName)
      .then((text) => {
        setShowFileEditor(true);
        setActiveFile({ text, name: fileName });
      })
      .finally(() => setFetchingFile(false));
  };

  return (
    <div className="h-full">
      <AddRuleTopMenuComponent searchQuery={searchQuery} onSearchChange={setSearchQuery} onYmlFileChange={fetchList} />
      <div>
        <Button className="mt-8" icon="plus" color="primary" onClick={() => setShowFileEditor(true)}>
          {intl.formatMessage(messages.newRuleFile)}
        </Button>

        <PanelContainer className="my-8 p-6">
          {fetchingList ? (
            <div className="flex items-center justify-center p-8">
              <LoadingPlaceholder text="Loading" />
            </div>
          ) : (
            <>
              <p className="mb-4 text-xl">{intl.formatMessage(messages.customFiles)}</p>
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="p-4 divider-b">
                      <TableSortButton
                        active={sort?.field === 'name'}
                        direction={sort?.order}
                        onClick={() =>
                          setSort({
                            field: 'name',
                            order: sort?.order === 'asc' ? 'desc' : 'asc',
                          })
                        }
                        className="headerCell"
                      >
                        {intl.formatMessage(messages.fileName)}
                      </TableSortButton>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filesListProcessed.length ? (
                    filesListProcessed.map((fileName) => (
                      <tr key={fileName} className="hover:bg-hover">
                        <td className="p-4 divider-b">
                          <div className="flex items-center">
                            <span>{fileName}.yml</span>
                            <IconButton className="ml-6" variant="primary" onClick={() => handleViewEditFile(fileName)}>
                              <EditIcon />
                            </IconButton>
                            <TooltipConfirmationComponent action={() => handleDeleteFileItem(fileName)}>
                              <IconButton variant="destructive" disabled={fetchingFile || deletingFile}>
                                <DeleteOutlineIcon />
                              </IconButton>
                            </TooltipConfirmationComponent>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <div className="p-1">{intl.formatMessage(messages.emptyList)}</div>
                  )}
                </tbody>
              </table>
            </>
          )}
        </PanelContainer>
      </div>
      {showFileEditor && (
        <YmlFileEditorComponent
          onClose={() => {
            setShowFileEditor(false);
            setActiveFile(undefined);
          }}
          text={activeFile?.text}
          onSubmit={handleSaveFile}
          saving={savingFile || fetchingFile}
        />
      )}
    </div>
  );
};

export default connector(AddRuleFile);
