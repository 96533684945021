import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SloResponse } from 'asserts-types';
import SnackbarHelper from 'helpers/Snackbar.helper';
import { USE_SLOS_QUERY_KEY } from 'hooks/useSlos';
import { deleteGrafanaSlo } from 'services/Slo.service';

const useDeleteGrafanaSlo = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (uuid: string) => deleteGrafanaSlo(uuid),
    onSuccess: (_, uuid) => {
      const activeSloQuery = queryClient.getQueryCache().findAll({ queryKey: [USE_SLOS_QUERY_KEY], type: 'active' });
      const activeSloQueryData = activeSloQuery[0].state.data as SloResponse;
      activeSloQuery[0].setData({
        ...activeSloQueryData,
        slos: activeSloQueryData.slos.filter((slo) => slo.uuid !== uuid),
      });

      SnackbarHelper.success('SLO deleted successfully');
    },
  });
};

export default useDeleteGrafanaSlo;
