import { AssertsAppJsonData, KpiDisplayConfig } from 'asserts-types';
import { TRACKING_EVENTS, TRACKING_FEATURES } from 'global-constants';
import { ENV } from './Environment.helper';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const TrackingHelper = {
  stackId: '',
  orgId: '',

  initialize(data: AssertsAppJsonData) {
    this.stackId = data.stackId;
    this.orgId = data.orgId;
  },

  trackEvent(eventName: TRACKING_EVENTS, attributes?: Record<string, any>) {
    const finalEventName = `asserts_${eventName}`;

    const attrs = {
      ...attributes,
    };

    if (this.orgId) {
      attrs.org_id = this.orgId;
    }

    if (this.stackId) {
      attrs.stack_id = this.stackId;
    }

    if (!ENV.LOCAL) {
      (window as any).rudderanalytics?.track(finalEventName, attrs);
    } else {
      // eslint-disable-next-line no-console
      console.debug(`[TRACKING] ${finalEventName}`, attrs);
    }
  },

  trackSloCreated() {
    this.trackEvent(TRACKING_EVENTS.SLO_CREATED);
  },
  trackNavigateToWorkbench(feature_name: TRACKING_FEATURES, source?: string) {
    this.trackEvent(TRACKING_EVENTS.NAVIGATE_TO_WORKBENCH, { feature_name, source });
  },
  trackSearchExpression(name: string, feature_name: TRACKING_FEATURES) {
    this.trackEvent(TRACKING_EVENTS.SEARCH_EXPRESSION_USED, { name, feature_name });
  },
  trackKpiDashboard(name: string, entity_type?: string) {
    this.trackEvent(TRACKING_EVENTS.KPI_DASHBOARD_OPENED, { name, entity_type });
  },
  trackEntitiesReturnedCount(count: number) {
    this.trackEvent(TRACKING_EVENTS.ENTITIES_RETURNED_COUNT, { count });
  },
  trackViewType(view_type: string) {
    this.trackEvent(TRACKING_EVENTS.VIEW_VISITED, { view_type });
  },
  trackDashboardLinked(entity_type: string) {
    this.trackEvent(TRACKING_EVENTS.DASHBOARD_LINKED, { entity_type });
  },
  trackPageVisited(path: string) {
    this.trackEvent(TRACKING_EVENTS.PAGE_VISITED, { path });
  },
  trackDisplayConfigUpdated(entity_type: string) {
    this.trackEvent(TRACKING_EVENTS.DISPLAY_CONFIG_UPDATED, { entity_type });
  },
  trackOnboardingOverSeriesLimit() {
    this.trackEvent(TRACKING_EVENTS.ONBOARDING_OVER_SERIES_LIMIT);
  },
  trackAddToWorkbench(feature_name: TRACKING_FEATURES, entity_type: string) {
    this.trackEvent(TRACKING_EVENTS.ENTITY_ADDED_TO_WORKBENCH, { feature_name, entity_type });
  },
  trackExternalWidgetOpened(source: string) {
    this.trackEvent(TRACKING_EVENTS.EXTERNAL_WIDGET_OPENED, { source });
  },
  trackNavigateToEntityGraph(feature_name: TRACKING_FEATURES, source: string) {
    this.trackEvent(TRACKING_EVENTS.NAVIGATE_TO_ENTITY_GRAPH, { feature_name, source });
  },
  trackKpiDisplayConfigUpdated(displayConfig: KpiDisplayConfig) {
    this.trackEvent(TRACKING_EVENTS.KPI_DISPLAY_CONFIG_UPDATED, { displayConfig });
  },
};

export default TrackingHelper;

const TRACKING_PATHS_BLACKLIST = [
  // this set of rules is blocked because all these pages redirect to nested page
  '/rules',
  '/rules/threshold',
  '/rules/notifications',
  '/rules/suppress',
  '/rules/relabel-rules',
  '/rules/add',
];

export function useTrackLocation() {
  const location = useLocation();
  useEffect(() => {
    const path = location.pathname.replace('/a/grafana-asserts-app', '');
    if (!TRACKING_PATHS_BLACKLIST.includes(path)) {
      TrackingHelper.trackPageVisited(path);
    }
  }, [location.pathname]);
}
