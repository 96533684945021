import React, { FunctionComponent, memo, useCallback, useEffect, useMemo } from 'react';

import { ConnectedProps, connect } from 'react-redux';
import AssertionsTopMenu from './components/AssertionsTopMenu/AssertionsTopMenu.component';
import AssertionsList from './components/AssertionsList/AssertionsList.component';
import { setShowSearch, setViewType, setExpandedSaafeFilter, clearWorkbench } from './Assertions.slice';
import { useIntl } from 'react-intl';
import { stringToDate } from '../../helpers/Date.helper';
import EmptyAssertions from './components/EmptyAssertions/EmptyAssertions.component';
import AssertionsSummaryViewComponent from './components/AssertionsSummaryView/AssertionsSummaryView.component';
import AssertionsGraphComponent from './components/AssertionsGraph/AssertionsGraph.component';
import useEntityAssertions from './hooks/useEntityAssertions';
import useEntityAssertionsSummary from './hooks/useEntityAssertionsSummary';
import useAssertionsHistoryChanges from './hooks/useAssertionsHistoryChanges';
import { AssertionsViewType } from 'asserts-types';
import { PluginPage } from '@grafana/runtime';
import EnvSiteSelectorsComponent from 'components/EnvSiteSelectors/EnvSiteSelectors.component';
import { Button, LoadingBar, LoadingPlaceholder } from '@grafana/ui';
import AssertionsTreeComponent from 'components/AssertionsTree/AssertionsTree.component';
import EntityDetailsContainer from 'features/EntityDetails/EntityDetails.container';
import { DEFAULT_FORCE_LAYOUT_OPTIONS } from 'global-constants';
import { setTimeRange } from 'features/App/App.slice';
import AssertionSearchContainer from 'features/AssertionSearch/AssertionSearch.container';
import GlobalDateRangePicker from 'features/App/components/GlobalDateRangePicker/GlobalDateRangePicker';
import TrackingHelper from 'helpers/Tracking.helper';

const connector = connect(
  (state: RootState) => ({
    workbenchEntities: state.assertions.workbenchEntities,
    start: state.app.start,
    end: state.app.end,
    viewType: state.assertions.viewType,
    selectedItems: state.assertions.selectedItems,
    activeEntityDetails: state.app.activeEntityDetails,
    showSearch: state.assertions.showSearch,
    showRelationships: state.entities.showRelationships,
    showNodeNames: state.entities.showNodeNames,
    expandedSaafeFilter: state.assertions.expandedSaafeFilter,
    search: state.assertions.search,
  }),
  {
    setShowSearch,
    setViewType,
    setExpandedSaafeFilter,
    setTimeRange,
    clearWorkbench,
  }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Assertions: FunctionComponent<PropsFromRedux> = ({
  workbenchEntities,
  start,
  end,
  viewType,
  selectedItems,
  activeEntityDetails,
  showSearch,
  setShowSearch,
  setViewType,
  setExpandedSaafeFilter,
  expandedSaafeFilter,
  search,
  setTimeRange,
  clearWorkbench,
}) => {
  const intl = useIntl();
  useAssertionsHistoryChanges();

  const {
    data: assertionsData,
    isFetching: isFetchingAssertions,
    isPreviousData: isPrevAssertionsData,
    isFetched: isEntityAssertionsFetched,
  } = useEntityAssertions();

  const {
    data: assertionsSummaryData,
    isFetching: isFetchingAssertionsSummary,
    isPreviousData: isPrevAssertionsSummaryData,
    isFetched: isEntityAssertionsSummaryFetched,
  } = useEntityAssertionsSummary();

  const changeDateRange = useCallback(
    (start: number | string, end: number | string) => {
      // setSloEndTime(stringToDate(end).endOf('day').valueOf());
      setTimeRange({ start, end });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const startDate = useMemo(
    () => stringToDate(start).valueOf(),
    // eslint-disable-next-line
    [start]
  );
  const endDate = useMemo(
    () => stringToDate(end).valueOf(),
    // eslint-disable-next-line
    [end]
  );

  const isFetching = viewType === AssertionsViewType.SUMMARY ? isFetchingAssertionsSummary : isFetchingAssertions;

  const noResultsFound =
    viewType === AssertionsViewType.SUMMARY
      ? isEntityAssertionsSummaryFetched && assertionsSummaryData?.summaries.length === 0
      : isEntityAssertionsFetched && assertionsData?.assertions.length === 0;

  const isEmpty = !Boolean(search || workbenchEntities.length) || noResultsFound;

  // we need to hide previous data from cache (from prev search) to not confuse users
  // for example we have loaded Show all pods
  // then we try to load Show all nodes through global search and while it's loading it will hide Show all pods data
  // if we work with workbench in workbench entity keys added mode (no search) the prev data should be shown
  const isPrevious =
    viewType === AssertionsViewType.SUMMARY ? isPrevAssertionsSummaryData && search : isPrevAssertionsData && search;

  return (
    <PluginPage
      renderTitle={(title) => (
        <div>
          <h1>{title}</h1>
          <div className="flex items-center gap-1 mt-2 h-[24px]">
            <span className="text-secondary">
              (<span className="font-bold">{workbenchEntities.length}</span>{' '}
              {workbenchEntities.length === 1 ? 'Entity' : 'Entities'} added)
            </span>
            {!!workbenchEntities.length && (
              <Button size="sm" fill="text" icon="trash-alt" variant="destructive" onClick={() => clearWorkbench()}>
                Clear
              </Button>
            )}
          </div>
        </div>
      )}
      actions={
        <div className="flex items-start gap-4">
          <EnvSiteSelectorsComponent start={start} end={end} />
          <GlobalDateRangePicker showRefreshButton isFetching={isFetching} />
        </div>
      }
    >
      <div className="flex flex-col h-full">
        <AssertionsTopMenu assertions={assertionsData?.assertions} />
        <div className="relative h-0 grow flex flex-col">
          {isFetching && (
            <div className="absolute -top-[1px] inset-x-0">
              <LoadingBar width={300} />
            </div>
          )}
          <div className="flex h-0 flex-1">
            {isFetching && isPrevious && !isEmpty && (
              <div className="w-full h-full flex flex-col items-center justify-center">
                <LoadingPlaceholder text="Loading..." />
              </div>
            )}
            {viewType === AssertionsViewType.SUMMARY && !isEmpty && !isPrevious && (
              <AssertionsSummaryViewComponent
                start={start}
                end={end}
                changeDateRange={changeDateRange}
                data={assertionsSummaryData}
              />
            )}
            {viewType === AssertionsViewType.GRAPH && !isEmpty && !isPrevious && (
              <AssertionsGraphComponent
                start={start}
                end={end}
                layout={DEFAULT_FORCE_LAYOUT_OPTIONS}
                isFetchingWorkbench={isFetchingAssertions}
              />
            )}
            <AssertionsList
              show={viewType === AssertionsViewType.BY_ENTITY && !isEmpty && !isPrevious}
              selectedItems={selectedItems}
              expandedSaafeFilter={expandedSaafeFilter}
              setExpandedSaafeFilter={setExpandedSaafeFilter}
              start={startDate}
              end={endDate}
              changeDateRange={changeDateRange}
              assertionsData={assertionsData}
              isFetching={isFetchingAssertions}
            />
            {viewType === AssertionsViewType.MIND_MAP && !isEmpty && !isPrevious && (
              <AssertionsTreeComponent
                show
                hideResizer
                onLinkClick={() => {
                  setViewType(AssertionsViewType.BY_ENTITY);
                }}
                data={assertionsData?.assertionRollupDto}
              />
            )}
            {isEmpty && <EmptyAssertions showNoResultsFound={noResultsFound} />}
          </div>
          {showSearch && <AssertionSearchContainer />}
        </div>
      </div>
      {activeEntityDetails && <EntityDetailsContainer />}
    </PluginPage>
  );
};

export default connector(memo(Assertions));
