import React, { FunctionComponent } from 'react';

import { ConnectedProps, connect } from 'react-redux';
import SloIncidents from './components/SloIncidents/SloIncidents.component';
import IncidentsTopMenu from './components/IncidentsTopMenu/IncidentsTopMenu.component';
import EntityDetailsContainer from '../EntityDetails/EntityDetails.container';
import { SLICE_NAME } from './constants';
import { setTimeRange as changeEntitiesDateRange } from '../App/App.slice';
import { setEndTime as setSloEndTime } from '../Slo/Slo.slice';
import { stringToDate } from '../../helpers/Date.helper';
import useIncidentsHistoryChanges from './hooks/useIncidentsHistoryChanges';
import useIncidents from './hooks/useIncidents';
import useExpandRows from './hooks/useExpandRows';
import EnvSiteSelectorsComponent from 'components/EnvSiteSelectors/EnvSiteSelectors.component';
import { PluginPage } from '@grafana/runtime';
import { RefreshPicker } from '@grafana/ui';
import GlobalDateRangePicker from 'features/App/components/GlobalDateRangePicker/GlobalDateRangePicker';
import { useQueryClient } from '@tanstack/react-query';

const connector = connect(
  (state: RootState) => ({
    activeEntityDetails: state.app.activeEntityDetails,
  }),
  { setSloEndTime, changeEntitiesDateRange }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Incidents: FunctionComponent<PropsFromRedux> = ({
  activeEntityDetails,
  changeEntitiesDateRange,
  setSloEndTime,
}) => {
  const { data, isFetching } = useIncidents();

  const queryClient = useQueryClient();

  useExpandRows({ groupedItems: data?.incidentGroups });
  useIncidentsHistoryChanges();

  const handleDateRangeChange = (start: number | string | null, end: number | string | null) => {
    if (start && end) {
      changeEntitiesDateRange({ start, end });
      setSloEndTime(stringToDate(end).endOf('day').valueOf());
    }
  };

  return (
    <PluginPage
      actions={
        <div className="flex items-center gap-8">
          <div className="flex items-center gap-1">
            <GlobalDateRangePicker />
            <RefreshPicker
              isOnCanvas={true}
              noIntervalPicker={true}
              onRefresh={() => queryClient.refetchQueries({ stale: true, type: 'active' })}
              onIntervalChanged={() => null}
              isLoading={isFetching}
            />
          </div>
          <EnvSiteSelectorsComponent start="now-1d" end="now" />
        </div>
      }
    >
      <div className="h-full flex flex-col">
        <IncidentsTopMenu />
        <SloIncidents
          groupedIncidents={data?.incidentGroups || []}
          isFetching={isFetching}
          chartName={data?.chartName || ''}
        />
      </div>
      {activeEntityDetails && <EntityDetailsContainer />}
    </PluginPage>
  );
};

export default connector(Incidents);
