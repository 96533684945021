import React from 'react';
import { PluginPage } from '@grafana/runtime';
import { useCustomDashboards } from './hooks/useCustomDashboards';
import ConfigurationTopMenu from 'features/Configuration/components/ConfigurationTopMenu/ConfigurationTopMenu';
import LinkCustomDashboardForm from './components/LinkCustomDashboardForm/LinkCustomDashboardForm';
import GlobalDateRangePicker from 'features/App/components/GlobalDateRangePicker/GlobalDateRangePicker';
import { LoadingBar, LoadingPlaceholder, PanelContainer } from '@grafana/ui';
import { useIntl } from 'react-intl';
import messages from './messages';
import CustomDashboardCardComponent from './components/CustomDashboardCard/CustomDashboardCard.component';

export default function CustomDashboards() {
  const { data, isFetching, isLoading } = useCustomDashboards();
  const intl = useIntl();
  return (
    <PluginPage
      actions={<GlobalDateRangePicker />}
      renderTitle={() => <h1>Configuration</h1>}
      pageNav={{ text: 'Link dashboards' }}
    >
      <ConfigurationTopMenu />
      <div className="divide-y dark:divide-neutral-700">
        <div className="pb-16 pt-6">
          <h4>Add Custom Dashboard</h4>
          <p className="text-secondary pb-8 text-lg">
            Link your dashboard to an entity type, and it will appear in the KPI settings drawer.
          </p>
          <LinkCustomDashboardForm />
        </div>
        <div className="py-16">
          <h4>Dashboards</h4>
          <p className="text-secondary pb-8 text-lg">Dashboards you already linked.</p>
          <PanelContainer>
            {isFetching && <LoadingBar width={300} />}
            {isLoading ? (
              <div className="flex items-center justify-center p-8">
                <LoadingPlaceholder text="Loading" />
              </div>
            ) : (
              <div className="p-8">
                <table className="w-full">
                  <thead>
                    <td className="headerCell p-4 divider-b">{intl.formatMessage(messages.dashName)}</td>
                    <td className="headerCell p-4 divider-b">{intl.formatMessage(messages.primary)}</td>
                    <td className="headerCell p-4 divider-b">{intl.formatMessage(messages.query)}</td>
                    <td className="headerCell p-4 divider-b">{intl.formatMessage(messages.entityType)}</td>
                    <td width="10%" className="divider-b"></td>
                  </thead>

                  {data?.map((dash, index) => (
                    <CustomDashboardCardComponent key={index} customDash={dash} />
                  ))}
                  {!data?.length && !isFetching && <div className="p-4">{intl.formatMessage(messages.emptyList)}</div>}
                </table>
              </div>
            )}
          </PanelContainer>
        </div>
      </div>
    </PluginPage>
  );
}
