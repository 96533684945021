/*
 * ShowEnvSiteTagSetting Messages
 *
 * This contains all the text for the ShowEnvSiteTagSetting component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.ShowEnvSiteTagSetting';

export default defineMessages({
  showEnvSiteTag: {
    id: `${scope}.showEnvSiteTag`,
    defaultMessage: 'Display env (site) label',
  },
});
