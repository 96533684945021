/**
 *
 * BubbleView
 *
 */

import React, { FunctionComponent, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import BubbleViewItem from '../BubbleViewItem/BubbleViewItem.component';
import useDefaultColumns from '../BubbleViewSettings/hooks/useDefaultColumns';
import BubbleViewSettingsComponent from '../BubbleViewSettings/BubbleViewSettings.component';
import { setBubbleViewActiveNodeName } from '../../Entities.slice';
import { GraphCustomData, KpiGroup } from 'asserts-types';

interface IProps {
  numericProperties: string[];
  bubbleViewPanels: string[];
  listKpis: KpiGroup['kpis'];
  filteredKpis: string[];
  graphData: GraphCustomData;
}

const connector = connect((state: RootState) => ({}), {
  setBubbleViewActiveNodeName,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

const BubbleView: FunctionComponent<IProps & PropsFromRedux> = ({
  bubbleViewPanels,
  setBubbleViewActiveNodeName,
  numericProperties,
  listKpis,
  filteredKpis,
  graphData,
}) => {
  useDefaultColumns(graphData, bubbleViewPanels);


  useEffect(() => {
    // disabling body scroll since it calls useResizeObserver when body scroll appears (happens when scroll bars are always visible setting is enabled on mac)
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, []);

  return (
    <div
      className="overflow-scroll flex flex-1 w-0"
      onScroll={() => setBubbleViewActiveNodeName(undefined)}
    >
      <BubbleViewSettingsComponent
        properties={numericProperties}
        listKpis={listKpis}
        filteredKpis={filteredKpis}
        bubbleViewPanels={bubbleViewPanels}
      />
      {[...bubbleViewPanels].reverse().map((property) => (
        <BubbleViewItem
          key={property}
          property={property}
          properties={numericProperties}
          graphDataRaw={graphData}
        />
      ))}
    </div>
  );
};

export default connector(BubbleView);
