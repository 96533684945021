/**
 *
 * TopInsightsList
 *
 */

import React, { memo, forwardRef, ForwardRefRenderFunction } from 'react';
import { useIntl } from 'react-intl';
import TableHeader from 'components/TableHeader/TableHeader.component';
import messages from './messages';
import TopInsightsListItem from '../TopInsightsListItem/TopInsightsListItem.component';
import { SCRORE_H_WIDTH, TOPINSIGHT_LINE_HEIGHT } from '../../constants';
import { AssertionScore, Sort, TopInsightsSort } from 'asserts-types';
import TableSortButton from 'features/Entities/components/TableSortButton/TableSortButton.component';

interface IProps {
  assertionScores: (AssertionScore & { itemId: string })[];
  setSortField: (s: TopInsightsSort) => void;
  sortField?: Sort;
  expanded?: boolean;
  lastUpdateTime: number;
  showAll: boolean;
}

const TopInsightsList: ForwardRefRenderFunction<HTMLDivElement, IProps> = (
  { assertionScores, setSortField, sortField, expanded, lastUpdateTime, showAll },
  ref
) => {
  const intl = useIntl();

  return (
    <>
      <div className="basis-[450px] grow-0 shrink-0 flex">
        <div className="h-full w-full" ref={ref}>
          {expanded && (
            <TableHeader className="px-4 sticky top-0 z-[2]">
              <div className="flex items-center">
                <TableSortButton
                  className="text-secondary text-xs whitespace-nowrap"
                  style={{ width: SCRORE_H_WIDTH }}
                  active={sortField?.field === 'percentage'}
                  direction={sortField?.order}
                  onClick={() =>
                    setSortField({
                      field: 'percentage',
                      order: sortField?.order === 'asc' ? 'desc' : 'asc',
                    })
                  }
                >
                  {intl.formatMessage(messages.score)}
                </TableSortButton>
                <TableSortButton
                  className="text-secondary text-xs px-8"
                  active={sortField?.field === 'name'}
                  direction={sortField?.order}
                  onClick={() =>
                    setSortField({
                      field: 'name',
                      order: sortField?.order === 'asc' ? 'desc' : 'asc',
                    })
                  }
                >
                  {intl.formatMessage(messages.entity)}
                </TableSortButton>
              </div>
              <TableSortButton
                className="text-secondary text-xs"
                active={sortField?.field === 'type'}
                direction={sortField?.order}
                onClick={() =>
                  setSortField({
                    field: 'type',
                    order: sortField?.order === 'asc' ? 'desc' : 'asc',
                  })
                }
              >
                {intl.formatMessage(messages.type)}
              </TableSortButton>
            </TableHeader>
          )}
          {expanded &&
            assertionScores.slice(0, showAll ? assertionScores.length : 3).map((score) => (
              <div key={score.itemId} className="pr-4 divider-b" style={{ height: TOPINSIGHT_LINE_HEIGHT }}>
                <TopInsightsListItem itemId={score.itemId} score={score} lastUpdateTime={lastUpdateTime} />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

const forwarderTopInsightsList = forwardRef(TopInsightsList);

export default memo(forwarderTopInsightsList);
