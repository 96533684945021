/**
 *
 * SloAddConfig
 *
 */

import React, { memo, FunctionComponent, useState, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import { Controller, useForm } from 'react-hook-form';
import errorMessages from 'app/errorMessages';
import { Comparator, SloType, SloConfigModel } from 'asserts-types';
import SearchExpression from '../SearchExpression/SearchExpression.component';
import SloObjectives from '../SloObjectives/SloObjectives.component';
import { DEFAULT_OBJECTIVE, THRESHOLD_COMPARATOR_OPTIONS, rateRegex } from '../../constants';
import { addSloConfig } from '../../ManageSlo.slice';
import { cloneDeep } from 'lodash';
import Decimal from 'decimal.js';
import PreviewSloChartComponent from '../PreviewSloChart/PreviewSloChart.component';
import SnackbarHelper from 'helpers/Snackbar.helper';
import useQueryForPreview from '../../hooks/useQueryForPreview';
import useDebounceValue from 'hooks/useDebounceValue';
import useJobOptions from 'hooks/useJobOptions';
import useRequestTypeOptions from 'hooks/useRequestTypeOptions';
import useRequestContextOptions from 'hooks/useRequestContextOptions';
import useErrorTypeOptions from 'hooks/useErrorTypeOptions';
import useRequestMetricType from 'hooks/useRequestMetricType';
import { createGrafanaSloConfig, updateGrafanaSloConfig } from 'services/Slo.service';
import {
  Box,
  BracesPlugin,
  Button,
  Divider,
  Field,
  Input,
  LinkButton,
  LoadingPlaceholder,
  MultiSelect,
  QueryField,
  RadioButtonGroup,
  Select,
  SlatePrism,
  Stack,
  Tab,
  TabsBar,
  Text,
  useStyles2,
} from '@grafana/ui';
import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import CodeBlockComponent from 'components/CodeBlock/CodeBlock.component';
import TrackingHelper from 'helpers/Tracking.helper';
import { PluginPage } from '@grafana/runtime';
import generateBreadcrumbs from 'helpers/Breadcrumbs.helper';
import { useNavigate, useParams } from 'react-router-dom';
import { prefixRoute } from 'utils/utils.routing';
import { METRICS_DATA_SOURCE_UID, ROUTES } from 'global-constants';
import { css } from '@emotion/css';
import useGrafanaSlo from 'features/ManageSlo/hooks/useGrafanaSlo';
import { isAxiosError } from 'axios';
import { useMetricsDataSource } from 'hooks/useMetricsDatasource';

const defaultState: Omit<SloConfigModel, 'uuid' | 'readOnly'> = {
  name: '',
  active: true,
  kind: 'SLO',
  indicator: {
    kind: SloType.Request,
    badEventCount: '',
    totalEventCount: '',
    name: '',
    measurement: '',
    gauge: false,
    thresholdComparator: Comparator.GreaterThan,
  },
  entitySearch: '',
  objectives: [cloneDeep(DEFAULT_OBJECTIVE)],
};

interface IProps {}

const SloAddConfig: FunctionComponent<IProps> = () => {
  const intl = useIntl();
  const styles = useStyles2(getStyles);
  const { id } = useParams();

  const navigate = useNavigate();

  const [saving, setSaving] = useState(false);
  const [isSimple, setIsSimple] = useState(!id);
  const [job, setJob] = useState<string | null>(null);
  const [isTouchedJob, setIsTouchedJob] = useState(false);
  const [requestType, setRequestType] = useState<string | null>(null);
  const [requestContext, setRequestContext] = useState<string | string[] | null>(null);
  const [errorType, setErrorType] = useState<string[] | null>(null);
  const [includeInbound, setIncludeInbound] = useState(false);
  const { data: metricsDatasouce } = useMetricsDataSource();
  const { data: jobOptions = [], isFetching: isFetchingJobOptions } = useJobOptions();
  const { data: requestTypeOptions = [], isFetching: isFetchingRequestTypeOptions } = useRequestTypeOptions({
    job,
    enabled: !!job,
  });
  const { data: requestMetricType = '' } = useRequestMetricType({
    job,
    requestType,
    enabled: Boolean(job && requestType),
  });
  const { data: requestContextOptions = [], isFetching: isFetchingRequestContextOptions } = useRequestContextOptions({
    job,
    requestType,
    enabled: Boolean(job && requestType),
  });
  const { data: errorTypeOptions = [], isFetching: isFetchingErrorTypeOptions } = useErrorTypeOptions({
    job,
    requestType,
    enabled: Boolean(job && requestType),
  });

  const { handleSubmit, setValue, watch, control, reset, getValues } = useForm<SloConfigModel>({
    defaultValues: cloneDeep(defaultState),
    mode: 'onChange',
  });

  const watchKind = watch('indicator.kind', SloType.Request);

  useEffect(() => {
    if (preloadedSlo) {
      return;
    }
    if (job) {
      const type = requestMetricType === 'gauge' ? 'gauge' : 'total';
      const requestTypeStr = requestType ? `asserts_request_type="${requestType}"` : '';
      const requestContextStr =
        requestContext && requestContext.length
          ? `asserts_request_context=~"${Array.isArray(requestContext) ? requestContext.join('|') : requestContext}"`
          : '';
      const errorTypeStr = errorType && errorType.length ? `asserts_error_type=~"${errorType.join('|')}"` : '';
      const paramsToStr = [`job="${job}"`, requestTypeStr, requestContextStr].filter((s) => s).join(', ');
      const badEventParamsToStr = [`job="${job}"`, requestTypeStr, requestContextStr, errorTypeStr]
        .filter((s) => s)
        .join(', ');
      const badEventErrorParamsToStr = [`job="${job}"`, requestTypeStr, requestContextStr].filter((s) => s).join(', ');
      const totalEventCount = `{asserts_metric_request="${type}", ${paramsToStr}}`;
      const badEventCount = `{asserts_metric_error="${type}", ${badEventParamsToStr}}${
        includeInbound ? ` or {asserts_metric_error="client_${type}", ${badEventErrorParamsToStr}}` : ''
      }`;
      const measurement =
        job && !requestContext && !requestType
          ? `asserts:latency:service:p99{${paramsToStr}}`
          : `asserts:latency:p99{${paramsToStr}}`;
      setValue('indicator.totalEventCount', totalEventCount);
      setValue('indicator.badEventCount', badEventCount);
      setValue('indicator.measurement', measurement);
    } else {
      setValue('indicator.totalEventCount', '');
      setValue('indicator.badEventCount', '');
      setValue('indicator.measurement', '');
    }
    //eslint-disable-next-line
  }, [job, requestType, requestContext, errorType, requestMetricType, includeInbound, setValue]);

  useEffect(() => {
    if (watchKind === SloType.Request) {
      setValue('indicator.gauge', 'gauge' === requestMetricType);
    }
    if (watchKind === SloType.Occurrence && Array.isArray(requestContext)) {
      setRequestContext('');
    }
    // eslint-disable-next-line
  }, [watchKind, requestMetricType, isSimple, setValue]);

  useEffect(() => {
    if (requestType && !requestTypeOptions.includes(requestType || '')) {
      setRequestType('');
    }
    // eslint-disable-next-line
  }, [requestTypeOptions]);

  useEffect(() => {
    if (Array.isArray(requestContext)) {
      const filteredRequestContext = requestContext?.filter((rc) => requestContextOptions.includes(rc)) || [];
      if (filteredRequestContext?.length !== requestContext?.length) {
        setRequestContext(filteredRequestContext);
      }
    } else if (requestContext && !requestContextOptions.includes(requestContext || '')) {
      setRequestContext('');
    }

    // eslint-disable-next-line
  }, [requestContextOptions, requestContext]);

  useEffect(() => {
    const filteredErrorType = errorType?.filter((rc) => errorTypeOptions.includes(rc)) || [];
    if (filteredErrorType?.length !== errorType?.length) {
      setErrorType(filteredErrorType);
    }
    // eslint-disable-next-line
  }, [errorTypeOptions, errorType]);

  const onSubmit = handleSubmit((data) => {
    setIsTouchedJob(true);
    if (data.name) {
      setSaving(true);
      const processedData: SloConfigModel = {
        ...data,
        objectives: data.objectives.map((item) => ({
          ...item,
          ratio: new Decimal(item.ratio).dividedBy(100).toNumber(),
        })),
      };
      (id
        ? updateGrafanaSloConfig(id, processedData, metricsDatasouce.uid)
        : createGrafanaSloConfig(processedData, metricsDatasouce.uid)
      )
        .then(() => {
          addSloConfig(cloneDeep(data));
          setIsSimple(true);
          setJob('');
          setIsTouchedJob(false);
          setRequestType('');
          setRequestContext([]);
          setErrorType([]);
          setIncludeInbound(false);
          reset(cloneDeep(defaultState));
          SnackbarHelper.success(messages.success);
          TrackingHelper.trackSloCreated();
          navigate(prefixRoute(ROUTES.SLO));
        })
        .catch((err) => {
          if (isAxiosError<{ error?: string }>(err)) {
            SnackbarHelper.error(err.response?.data.error || 'Errors while saving SLO');
          }
        })
        .finally(() => setSaving(false));
    }
  });

  const handleJobSelect = (value: string) => {
    setIsTouchedJob(true);
    setJob(value);
    value && setValue('entitySearch', `${value.replace('/istio-proxy', '')} connected services`);
  };

  const renderDropdowns = () => (
    <>
      <Field
        invalid={isTouchedJob && !job}
        error={isTouchedJob && !job && intl.formatMessage(errorMessages.required)}
        label={intl.formatMessage(messages.job)}
        className={styles.field}
      >
        <Select
          onChange={(v) => handleJobSelect(v?.value || '')}
          options={jobOptions?.map((o) => ({ label: o, value: o })) || []}
          virtualized
          disabled={saving}
          isLoading={isFetchingJobOptions}
          isClearable
        />
      </Field>
      <Field label={intl.formatMessage(messages.requestType)} className={styles.field}>
        <Select
          onChange={(v) => setRequestType(v?.value || '')}
          options={requestTypeOptions?.map((o) => ({ label: o, value: o })) || []}
          virtualized
          disabled={saving}
          isLoading={isFetchingRequestTypeOptions}
          isClearable
        />
      </Field>
      <Field label={intl.formatMessage(messages.requestContext)} className={styles.field}>
        {watchKind === SloType.Request ? (
          <MultiSelect
            onChange={(values) => setRequestContext(values?.map((v) => v.value || '') || [])}
            closeMenuOnSelect={false}
            value={typeof requestContext === 'string' ? [requestContext] : requestContext || []}
            options={requestContextOptions?.map((o) => ({ label: o, value: o })) || []}
            virtualized
            disabled={saving}
            isLoading={isFetchingRequestContextOptions}
            isClearable
          />
        ) : (
          <Select
            onChange={(v) => setRequestContext(v?.value || '')}
            options={requestContextOptions?.map((o) => ({ label: o, value: o })) || []}
            virtualized
            disabled={saving}
            isLoading={isFetchingRequestContextOptions}
            isClearable
          />
        )}
      </Field>
    </>
  );

  const allEventsQuery = watch('indicator.totalEventCount');
  const badEventsQuery = watch('indicator.badEventCount');
  const measurementQuery = watch('indicator.measurement');
  const isGauge = watch('indicator.gauge');

  const queryForPreview = useQueryForPreview({
    allEventsQuery,
    badEventsQuery,
    measurementQuery,
    isGauge,
    kind: watchKind,
  });

  const objectives = watch('objectives');
  const objectivesJson = JSON.stringify(objectives);
  // dirty hack to debounce watch value
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const objectivesMemoized = useMemo(() => objectives, [objectivesJson]);
  const objectivesDebounced = useDebounceValue(objectivesMemoized, 600);

  const sloTypeOptions: SelectableValue<SloType>[] = [
    {
      value: SloType.Request,
      component: () => (
        <Box display="flex" direction="column" alignItems="start" paddingY={1}>
          <Text>Availability</Text>
          <Text element="p" textAlignment="left" color="secondary">
            Level of uptime or accessability
          </Text>
        </Box>
      ),
    },
    {
      value: SloType.Occurrence,
      component: () => (
        <Box display="flex" direction="column" alignItems="start" paddingY={1}>
          <Text>Latency / Occurrence</Text>
          <Text element="p" textAlignment="left" color="secondary">
            Level of responsiveness or speed
          </Text>
        </Box>
      ),
    },
  ];

  const metricTypeOptions: SelectableValue<boolean>[] = [
    {
      value: false,
      label: 'Counter',
    },
    {
      value: true,
      label: 'Gauge',
    },
  ];

  const { data: preloadedSlo, isError, isFetching } = useGrafanaSlo(id);

  // fill form with preloaded data
  useEffect(() => {
    if (preloadedSlo) {
      reset(preloadedSlo);
    }
  }, [preloadedSlo, reset]);

  if (id && isError) {
    return (
      <PluginPage pageNav={generateBreadcrumbs('Edit SLO', [{ text: 'SLO', url: prefixRoute(ROUTES.SLO) }])}>
        Failed to load SLO
      </PluginPage>
    );
  }

  if (isFetching) {
    return (
      <PluginPage pageNav={generateBreadcrumbs('Edit SLO', [{ text: 'SLO', url: prefixRoute(ROUTES.SLO) }])}>
        <LoadingPlaceholder text="Loading SLO..." />
      </PluginPage>
    );
  }

  return (
    <PluginPage
      pageNav={generateBreadcrumbs(id ? 'Edit SLO' : 'Create SLO', [{ text: 'SLO', url: prefixRoute(ROUTES.SLO) }])}
    >
      {!id && (
        <TabsBar>
          <Tab label={intl.formatMessage(messages.simple)} active={isSimple} onChangeTab={() => setIsSimple(true)} />
          <Tab
            label={intl.formatMessage(messages.advanced)}
            active={!isSimple}
            onChangeTab={() => setIsSimple(false)}
          />
        </TabsBar>
      )}
      <>
        <Box marginY={4}>
          <Box gap={10} display="flex">
            <Box width={'25%'} shrink={0} display="flex" direction="column" gap={0.5}>
              <Text element="h4">{intl.formatMessage(messages.basics)}</Text>
              <Text element="p" color="secondary">
                {intl.formatMessage(messages.basicsHint)}
              </Text>
            </Box>
            <Box display="flex" direction="column" grow={1} gap={6}>
              <Controller
                name="indicator.kind"
                control={control}
                rules={{
                  required: intl.formatMessage(errorMessages.required),
                }}
                render={({ field }) => (
                  <Box display="flex" alignItems="center" gap={4}>
                    <RadioButtonGroup
                      {...field}
                      options={sloTypeOptions.filter((o) =>
                        preloadedSlo ? o.value === preloadedSlo.indicator.kind : true
                      )}
                      className={styles.radio}
                      disabled={!!id}
                    />
                  </Box>
                )}
              />
              <Controller
                name="name"
                control={control}
                rules={{
                  required: intl.formatMessage(errorMessages.required),
                  // validate: (v) =>
                  //   !allSloConfigs.map((s) => s.name).includes(v) || intl.formatMessage(messages.existName),
                }}
                render={({ field, fieldState }) => (
                  <Field
                    invalid={!!fieldState.error}
                    error={fieldState.error?.message}
                    label={intl.formatMessage(messages.sloName)}
                  >
                    <Input {...field} placeholder="Enter SLO name" />
                  </Field>
                )}
              />
            </Box>
          </Box>
          <Divider />
          <Box gap={10} display="flex" marginY={4}>
            <Box width={'25%'} shrink={0} display="flex" direction="column" gap={0.5}>
              {watchKind === SloType.Occurrence && !isSimple ? (
                <>
                  <Text element="h4">{intl.formatMessage(messages.sli)}</Text>
                  <Text element="p" color="secondary">
                    {intl.formatMessage(messages.sliHint)}
                  </Text>
                </>
              ) : (
                <>
                  <Text element="h4">{intl.formatMessage(messages.parameters)}</Text>
                  <Text element="p" color="secondary">
                    {intl.formatMessage(messages.parametersHint)}
                  </Text>
                </>
              )}
            </Box>
            <Box display="flex" direction="column" grow={1} gap={0}>
              {watchKind === SloType.Request && (
                <>
                  {isSimple && (
                    <Box display="flex" direction={'column'} gap={2}>
                      <Box display="flex" gap={3} alignItems="start" grow={1}>
                        {renderDropdowns()}
                        <Field label={intl.formatMessage(messages.errorType)} className={styles.field}>
                          <MultiSelect
                            onChange={(values) => setErrorType(values?.map((v) => v.value || '') || [])}
                            closeMenuOnSelect={false}
                            value={errorType || []}
                            options={errorTypeOptions?.map((o) => ({ label: o, value: o })) || []}
                            disabled={saving}
                            isLoading={isFetchingErrorTypeOptions}
                            isClearable
                          />
                        </Field>
                      </Box>

                      {/* <InlineField label={intl.formatMessage(messages.includeInbound)}>
                        <InlineSwitch value={includeInbound} onClick={() => setIncludeInbound(!includeInbound)} />
                      </InlineField> */}
                    </Box>
                  )}
                  {!isSimple && (
                    <>
                      <Controller
                        name="indicator.gauge"
                        control={control}
                        render={({ field }) => (
                          <Field>
                            <RadioButtonGroup {...field} options={metricTypeOptions} />
                          </Field>
                        )}
                      />

                      <Controller
                        name="indicator.totalEventCount"
                        control={control}
                        rules={{
                          required: intl.formatMessage(errorMessages.required),
                          validate: (v) => !rateRegex.test(v || '') || intl.formatMessage(messages.rateNotAllowed),
                        }}
                        render={({ field, fieldState }) => (
                          <Field
                            invalid={!!fieldState.error}
                            error={fieldState.error?.message}
                            label={intl.formatMessage(messages.allEvents)}
                            className={styles.codeField}
                          >
                            <QueryField
                              {...field}
                              additionalPlugins={[
                                BracesPlugin(),
                                SlatePrism({
                                  onlyIn: (node: any) => node.type === 'code_block',
                                  getSyntax: () => 'promql',
                                }),
                              ]}
                              portalOrigin="."
                              placeholder="Enter query"
                              query={field.value}
                            />
                          </Field>
                        )}
                      />
                      <Controller
                        name="indicator.badEventCount"
                        control={control}
                        rules={{
                          required: intl.formatMessage(errorMessages.required),
                          validate: (v) => !rateRegex.test(v || '') || intl.formatMessage(messages.rateNotAllowed),
                        }}
                        render={({ field, fieldState }) => (
                          <Field
                            invalid={!!fieldState.error}
                            error={fieldState.error?.message}
                            label={intl.formatMessage(messages.badEvents)}
                            className={styles.codeField}
                          >
                            <QueryField
                              {...field}
                              additionalPlugins={[
                                BracesPlugin(),
                                SlatePrism({
                                  onlyIn: (node: any) => node.type === 'code_block',
                                  getSyntax: () => 'promql',
                                }),
                              ]}
                              portalOrigin="."
                              placeholder="Enter query"
                              query={field.value}
                            />
                          </Field>
                        )}
                      />
                    </>
                  )}
                </>
              )}
              {watchKind === SloType.Occurrence && (
                <>
                  {isSimple && (
                    <Box display="flex" grow={1} gap={3}>
                      {renderDropdowns()}
                    </Box>
                  )}
                  {!isSimple && (
                    <>
                      {/* <Controller
                        name="indicator.name"
                        control={control}
                        render={({ field, fieldState }) => (
                          <Field
                            invalid={!!fieldState.error}
                            error={fieldState.error?.message}
                            label={intl.formatMessage(messages.sliName)}
                          >
                            <Input {...field} placeholder="Enter SLI name" />
                          </Field>
                        )}
                      /> */}
                      <Controller
                        name="indicator.measurement"
                        control={control}
                        rules={{
                          required: intl.formatMessage(errorMessages.required),
                        }}
                        render={({ field, fieldState }) => (
                          <Field
                            invalid={!!fieldState.error}
                            error={fieldState.error?.message}
                            label={intl.formatMessage(messages.measurementQuery)}
                          >
                            <QueryField
                              {...field}
                              additionalPlugins={[
                                BracesPlugin(),
                                SlatePrism({
                                  onlyIn: (node: any) => node.type === 'code_block',
                                  getSyntax: () => 'promql',
                                }),
                              ]}
                              portalOrigin="."
                              placeholder="Enter query"
                              query={field.value}
                            />
                          </Field>
                        )}
                      />
                      <Stack alignItems="center" gap={2}>
                        <Text element="p" color="secondary">
                          Measurement does not meet expectations when value
                        </Text>
                        <Controller
                          name="indicator.thresholdComparator"
                          control={control}
                          render={({ field }) => (
                            <Field label={intl.formatMessage(messages.comparator)}>
                              <Select
                                {...field}
                                value={field.value}
                                onChange={(v) => field.onChange(v?.value)}
                                options={
                                  THRESHOLD_COMPARATOR_OPTIONS?.map((o) => ({
                                    label: o.label.toString(),
                                    value: o.value.toString(),
                                  })) || []
                                }
                                disabled={saving}
                              />
                            </Field>
                          )}
                        />
                        <Text element="p" color="secondary">
                          threshold.
                        </Text>
                      </Stack>
                    </>
                  )}
                </>
              )}

              {allEventsQuery && badEventsQuery && watchKind === SloType.Request && isSimple && (
                <Box marginTop={3}>
                  <Field label={intl.formatMessage(messages.allEvents)}>
                    <CodeBlockComponent>{allEventsQuery}</CodeBlockComponent>
                  </Field>
                  <Field label={intl.formatMessage(messages.badEvents)}>
                    <CodeBlockComponent>{badEventsQuery}</CodeBlockComponent>
                  </Field>
                </Box>
              )}

              {measurementQuery && watchKind === SloType.Occurrence && isSimple && (
                <Field label={intl.formatMessage(messages.measurementQuery)}>
                  <CodeBlockComponent>{measurementQuery}</CodeBlockComponent>
                </Field>
              )}

              {queryForPreview && (
                <Box width="100%">
                  <PreviewSloChartComponent
                    query={queryForPreview}
                    sloType={watchKind}
                    objectives={objectivesDebounced}
                    kind={watchKind}
                  />
                </Box>
              )}
            </Box>
          </Box>
          <Divider />
          <Box gap={10} display="flex" marginTop={4}>
            <Box width={'25%'} shrink={0} display="flex" direction="column" gap={0.5}>
              <Text element="h4">{intl.formatMessage(messages.context)}</Text>
              <Text element="p" color="secondary">
                {intl.formatMessage(messages.contextHint)}
              </Text>
            </Box>
            <Box display="flex" direction="column" grow={1} gap={6}>
              <SearchExpression control={control} editing />
            </Box>
          </Box>
          <Divider />
          <Box gap={10} display="flex" marginTop={4}>
            <Box width={'25%'} shrink={0} display="flex" direction="column" gap={0.5}>
              <Text element="h4">{intl.formatMessage(messages.objectives)}</Text>
              <Text element="p" color="secondary">
                {intl.formatMessage(messages.objectivesHint)}
              </Text>
            </Box>
            <Box grow={1}>
              <SloObjectives {...{ control, setValue, watch }} kind={watchKind} isSimple={isSimple} />
            </Box>
          </Box>
        </Box>
      </>
      <Stack>
        {preloadedSlo ? (
          <Button color="primary" type="submit" disabled={saving} onClick={onSubmit}>
            {saving ? 'Updating...' : 'Update SLO'}
          </Button>
        ) : (
          <Button icon="plus" color="primary" type="submit" disabled={saving} onClick={onSubmit}>
            {saving ? 'Saving...' : 'Save SLO'}
          </Button>
        )}
        <LinkButton href={prefixRoute(ROUTES.SLO)} variant="secondary">
          Back
        </LinkButton>
      </Stack>
    </PluginPage>
  );
};

export default memo(SloAddConfig);

const getStyles = (theme: GrafanaTheme2) => ({
  radio: css({
    '& label': {
      height: '100%',
    },
  }),
  field: css({
    flexGrow: 1,
    flexShrink: 0,
    width: 0,
  }),
  codeField: css({
    width: '100%',
  }),
});
