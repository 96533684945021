import React, { useMemo, useState } from 'react';

import { SceneComponentProps, sceneGraph, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import { Button } from '@grafana/ui';
import { stringToDate } from 'helpers/Date.helper';
import { DataQuery } from '@grafana/schema';

export interface CopyLinkButtonState {
  query: DataQuery;
  datasourceUid: string;
}

export type InternalCopyLinkButtonState = SceneObjectState & CopyLinkButtonState;

export class CopyLinkButton extends SceneObjectBase<InternalCopyLinkButtonState> {
  static Component = CopyLinkButtonRenderer;

  constructor(state: CopyLinkButtonState) {
    super(state);
  }
}

function CopyLinkButtonRenderer({ model }: SceneComponentProps<CopyLinkButton>) {
  const { query, datasourceUid } = model.useState();

  const { value } = sceneGraph.getTimeRange(model).useState();

  const [copied, setCopied] = useState(false);

  const left = useMemo(
    () => [
      encodeURIComponent(
        JSON.stringify({
          datasource: datasourceUid,
          queries: [query],
          range: {
            from: value.from.utc().toString(),
            to: value.to.utc().toString(),
          },
        })
      ),
    ],
    [value, datasourceUid, query]
  );

  const handleCopyLink = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(`${window.location.host}/explore?left=${left}`);
      setCopied(true);
      setTimeout(() => setCopied(false), 1000);
    }
  };

  

  return (
    <Button icon={copied ? 'check' : 'link'} variant="secondary" onClick={() => handleCopyLink()}>
      {copied ? 'Copied!' : 'Copy link'}
    </Button>
  );
}
