/*
 * RelabelConfigList Messages
 *
 * This contains all the text for the RelabelConfigList component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.RelabelConfigList';

export default defineMessages({
  source_labels: {
    id: `${scope}.source`,
    defaultMessage: 'Source Labels',
  },
  customItems: {
    id: `${scope}.customItems`,
    defaultMessage: 'Custom Items',
  },
  separator: {
    id: `${scope}.separator`,
    defaultMessage: 'Separator',
  },
  regex: {
    id: `${scope}.regex`,
    defaultMessage: 'Regex',
  },
  target_label: {
    id: `${scope}.targetLabel`,
    defaultMessage: 'Target Label',
  },
  replacement: {
    id: `${scope}.replacement`,
    defaultMessage: 'Replacement',
  },
  action: {
    id: `${scope}.action`,
    defaultMessage: 'Action',
  },
  modulus: {
    id: `${scope}.modulus`,
    defaultMessage: 'Modulus',
  },
  emptyList: {
    id: `${scope}.emptyList`,
    defaultMessage: 'No rules added',
  },
  drop: {
    id: `${scope}.drop`,
    defaultMessage: 'Drop',
  },
  selector: {
    id: `${scope}.selector`,
    defaultMessage: 'Selector',
  },
  join_labels: {
    id: `${scope}.join_labels`,
    defaultMessage: 'Join labels',
  },
  ranked_choice: {
    id: `${scope}.ranked_choice`,
    defaultMessage: 'Ranked choice',
  },
});
