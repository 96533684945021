import { stringToDate } from '../helpers/Date.helper';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { fetchErrorTypeOptions } from 'services/ManageAssertions.service';
import { useMetricsDataSource } from './useMetricsDatasource';

interface UseParams {
  job: string | null;
  requestType: string | null;
}
export default function useErrorTypeOptions({
  job,
  requestType,
  enabled,
}: UseParams & Pick<UseQueryOptions, 'enabled'>) {
  const { data: metricsDatasource } = useMetricsDataSource();

  return useQuery<string[]>(
    ['error-type-options', metricsDatasource.uid, job, requestType],
    () => {
      if (metricsDatasource.uid && requestType) {
        return fetchErrorTypeOptions(metricsDatasource.uid, job, requestType);
      } else {
        return [];
      }
    },
    {
      enabled,
      select: (data) => data.filter((o) => o),
      staleTime: Infinity,
    }
  );
}
