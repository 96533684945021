import { useQuery } from '@tanstack/react-query';
import { Entity, Scope } from 'asserts-types';
import { fetchConnectedEntities } from 'services/Entity.service';

interface UseEntityParams {
  entityId: number;
  type: string;
  start: number;
  end: number;
  scope?: Scope;
  enabled?: boolean;
}
export default function useConnectedEntities({ entityId, type, start, end, scope, enabled = true }: UseEntityParams) {
  return useQuery<Entity[]>(
    ['connectedEntities', entityId, type, start, end, scope],
    async () => {
      const { data } = await fetchConnectedEntities(entityId, type, start, end, {
        nameAndValues: {
          env: scope?.env ? [scope?.env] : undefined,
          site: scope?.site ? [scope?.site] : undefined,
        },
      });
      data.entities.forEach((entity) => {
        data.edges.forEach((edge) => {
          if (edge.destination === entity.id || edge.source === entity.id) {
            entity.edgeWithActiveElement = edge;
          }
        });
      });

      return data.entities;
    },
    { enabled }
  );
}
