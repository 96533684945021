import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActiveEntityDetailsPayload, AppContainerState, AppQueryParams, ListTabs } from 'asserts-types';
import { envColors } from './constants';
import { MessageDescriptor } from 'react-intl';
import { parseStartAndEndDates } from 'helpers/Date.helper';
import { areEntitiesEqual } from 'helpers/Entity.helper';

const initialState: AppContainerState = {
  menuExpanded: true,
  showEnvSiteTag: true,
  selectedEnv: [],
  selectedSite: [],
  globalSearchOpened: false,
  start: 'now-24h',
  end: 'now',
  queryParamsWereRead: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setShowEnvSiteTag: (state, action: PayloadAction<boolean>) => {
      state.showEnvSiteTag = action.payload;
    },

    setGlobalSearchOpened: (state, action: PayloadAction<boolean>) => {
      state.globalSearchOpened = action.payload;
    },

    mapEnvSiteColors: (state, action: PayloadAction<{ envList?: string[] }>) => {
      const colorMap: Record<string, string> = {};
      action.payload.envList?.forEach((item, index) => {
        colorMap[item] = envColors[index];
      });

      state.envColorsMap = colorMap;
    },

    toggleMenu: (state) => {
      state.menuExpanded = !state.menuExpanded;
    },

    setSelectedEnv: (state, action: PayloadAction<string[]>) => {
      state.selectedEnv = action.payload;
    },

    setSelectedSite: (state, action: PayloadAction<string[]>) => {
      state.selectedSite = action.payload;
    },

    setLicenseLimitBanner: (
      state,
      action: PayloadAction<{ title: MessageDescriptor; content: MessageDescriptor } | undefined>
    ) => {
      state.licenseLimitBanner = action.payload;
    },

    setTimeRange: (state, action: PayloadAction<{ start: number | string; end: number | string }>) => {
      const { start, end } = action.payload;
      state.start = start;
      state.end = end;
    },

    fillAppSliceWithQueryParams: (state, action: PayloadAction<AppQueryParams>) => {
      state.queryParamsWereRead = true;
      const queryParams = action.payload;

      if (queryParams?.env) {
        state.selectedEnv = Object.values(queryParams?.env);
      } else {
        state.selectedEnv = [];
      }
      if (queryParams?.site) {
        state.selectedSite = Object.values(queryParams?.site);
      } else {
        state.selectedSite = [];
      }

      const { start, end } = parseStartAndEndDates(queryParams?.start, queryParams?.end);

      if (start && end) {
        state.start = start;
        state.end = end;
      }

      if (queryParams?.ed) {
        const { start: edStart, end: edEnd } = parseStartAndEndDates(queryParams.ed.start, queryParams.ed.end);
        state.activeEntityDetails = {
          ...queryParams.ed,
          start: edStart || state.start,
          end: edEnd || state.end,
          tab: queryParams.ed.tab || 'kpi',
        };
      } else {
        state.activeEntityDetails = undefined;
      }
    },
    setQueryParamsWereRead: (state, action: PayloadAction<boolean>) => {
      state.queryParamsWereRead = action.payload;
    },
    setActiveEntityDetails: (state, action: PayloadAction<ActiveEntityDetailsPayload | undefined>) => {
      if (state.activeEntityDetails && action.payload && areEntitiesEqual(state.activeEntityDetails, action.payload)) {
        state.activeEntityDetails = undefined;
      } else if (action.payload?.type && action.payload?.name) {
        state.activeEntityDetails = {
          ...action.payload,
          start: action.payload.start || state.start,
          end: action.payload.end || state.end,
          tab: action.payload.tab || 'kpi',
        };
        if (action.payload.start && action.payload.end) {
        }
      } else {
        state.activeEntityDetails = undefined;
      }
    },
    setDrawerStart: (state, action: PayloadAction<string | number>) => {
      state.activeEntityDetails && (state.activeEntityDetails.start = action.payload);
    },
    setDrawerEnd: (state, action: PayloadAction<string | number>) => {
      state.activeEntityDetails && (state.activeEntityDetails.end = action.payload);
    },
    setDrawerTab: (state, action: PayloadAction<ListTabs>) => {
      state.activeEntityDetails && (state.activeEntityDetails.tab = action.payload);
    },
  },
});

export const {
  setSelectedSite,
  setSelectedEnv,
  toggleMenu,
  mapEnvSiteColors,
  setShowEnvSiteTag,
  setGlobalSearchOpened,
  setLicenseLimitBanner,
  setTimeRange,
  fillAppSliceWithQueryParams,
  setQueryParamsWereRead,
  setActiveEntityDetails,
  setDrawerEnd,
  setDrawerStart,
  setDrawerTab,
} = appSlice.actions;

export default appSlice.reducer;
