import { defineMessages } from 'react-intl';

export const scope = 'features.EntitiesGraph';

export default defineMessages({
  addToWorkbench: {
    id: `${scope}.addToWorkbench`,
    defaultMessage: 'Add to workbench',
  },
  showKPI: {
    id: `${scope}.showKPI`,
    defaultMessage: 'Show KPI',
  },
  showConnectedAssertions: {
    id: `${scope}.showConnectedAssertions`,
    defaultMessage: 'Show assertions',
  },
});
