/*
 * HoveredButtons Messages
 *
 * This contains all the text for the HoveredButtons component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.HoveredButtons';

export default defineMessages({
  addToWorkbench: {
    id: `${scope}.addToWorkbench`,
    defaultMessage: 'Add to workbench',
  },
  addedToWorkbench: {
    id: `${scope}.addedToWorkbench`,
    defaultMessage: 'Added to workbench',
  },
  entity: {
    id: `${scope}.entity`,
    defaultMessage: 'Connected Entities',
  },
  individualStat: {
    id: `${scope}.individualStat`,
    defaultMessage: 'KPI',
  },
});
