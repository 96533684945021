import { useIntl } from 'react-intl';
import { IYmlForm } from 'components/YmlFileEditor/YmlFileEditor.component';
import messages from '../messages';
import { useParams } from 'react-router-dom';
import { RelabelRuleType } from 'asserts-types';
import useRelabelRulesList from './useRelabelRulesList';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { saveRelabelRulesFile } from 'services/RelabelRules.service';
import SnackbarHelper from 'helpers/Snackbar.helper';

interface IProps {
  onSuccess?: () => void;
}

export default function (props?: IProps) {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { type } = useParams();

  const { refetch: refetchList } = useRelabelRulesList();

  const mutation = useMutation({
    mutationFn: (data: IYmlForm) => {
      return saveRelabelRulesFile(data.textField, type as RelabelRuleType);
    },
    onSuccess: (_, data) => {
      queryClient.setQueryData<string>(
        ['relabelRulesFile', type],
        data.textField,
      );

      SnackbarHelper.success(messages.saved);

      refetchList();

      props?.onSuccess && props.onSuccess();
    },
  });

  return mutation;
}
