/*
 * PropertiesPopover Messages
 *
 * This contains all the text for the PropertiesPopover component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.PropertiesPopover';

export default defineMessages({
  copied: {
    id: `${scope}.copied`,
    defaultMessage: 'Copied',
  },
  assertions: {
    id: `${scope}.assertions`,
    defaultMessage: 'Assertions',
  },
  connected: {
    id: `${scope}.connected`,
    defaultMessage: 'Connected Entities',
  },
  open: {
    id: `${scope}.open`,
    defaultMessage: 'Open',
  },
  showKpi: {
    id: `${scope}.showKpi`,
    defaultMessage: 'KPI',
  },
  warningText: {
    id: `${scope}.warningText`,
    defaultMessage: 'Entity is not reporting for last 5 min',
  },
  showMore: {
    id: `${scope}.showMore`,
    defaultMessage: 'Show more',
  },
});
