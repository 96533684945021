/*
 * AssertionsFilter Messages
 *
 * This contains all the text for the AssertionsFilter component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.AssertionsFilter';

export default defineMessages({
  search: {
    id: `${scope}.search`,
    defaultMessage: 'Search filter',
  },
  noResults: {
    id: `${scope}.noResults`,
    defaultMessage: 'No results for',
  },
  filter: {
    id: `${scope}.filter`,
    defaultMessage: 'Filter',
  },
  clear: {
    id: `${scope}.clear`,
    defaultMessage: 'Clear All',
  },
});
