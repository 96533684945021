/**
 *
 * TimelineTicks
 *
 */

import React, { memo, FunctionComponent } from 'react';

interface IProps {
  count: number;
}

const TimelineTicks: FunctionComponent<IProps> = ({ count }) => {
  return (
    <>
      {Array.from(Array(count - 1), (_, i) => (
        <div
          key={`${i}-timeline-divider`}
          className="w-[1px] top-0 bottom-0 absolute bg-panel"
          style={{ left: `${(100 / count) * (i + 1)}%` }}
        />
      ))}
    </>
  );
};

export default memo(TimelineTicks);
