/**
 *
 * RelabelConfigList
 *
 */

import React, { memo, FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import useRelabelRulesList from '../../hooks/useRelabelRulesList';
import { RelabelRule } from 'asserts-types';
import RelabelConfigListItemComponent from '../RelabelConfigListItem/RelabelConfigListItem.component';
import { LoadingPlaceholder, PanelContainer } from '@grafana/ui';

const TABLE_FIELDS: (keyof RelabelRule)[] = [
  'selector',
  'replacement',
  'join_labels',
  'ranked_choice',
  'target_label',
  'drop',
];

const isFieldFromMessages = (messagesObj: typeof messages, field: string): field is keyof typeof messages =>
  messagesObj.hasOwnProperty(field);

interface IProps {}

const RelabelConfigList: FunctionComponent<IProps> = () => {
  const intl = useIntl();

  const { data: rulesList, isFetching: isFetchingList } = useRelabelRulesList();

  return (
    <PanelContainer className="p-6 mt-8">
      <div className="flex items-center justify-between pb-1">
        <p className="text-xl">{intl.formatMessage(messages.customItems)}</p>
      </div>
      {isFetchingList ? (
        <div className="flex items-center justify-center p-8">
          <LoadingPlaceholder text="Loading" />
        </div>
      ) : (
        <table className="table-fixed w-full">
          <thead>
            <tr>
              {TABLE_FIELDS.map((field) => (
                <td key={field} className="headerCell p-4 divider-b">
                  {isFieldFromMessages(messages, field) ? intl.formatMessage(messages[field]) : ''}
                </td>
              ))}
              <td width="10%" className="p-4 divider-b"></td>
            </tr>
          </thead>
          <tbody>
            {rulesList?.length ? (
              rulesList?.map((item, index) => (
                <RelabelConfigListItemComponent fields={TABLE_FIELDS} item={item} key={index} />
              ))
            ) : (
              <div className="p-4">{intl.formatMessage(messages.emptyList)}</div>
            )}
          </tbody>
        </table>
      )}
    </PanelContainer>
  );
};

export default memo(RelabelConfigList);
