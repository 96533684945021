import { useQuery, useQueryClient } from '@tanstack/react-query';
import { apiHttpService } from 'app/api-http-service';
import { ConnectedDataItem } from 'asserts-types';
//TODO: get back to this type when UI will support multiple groups
interface ConnectedDataConfigRes {
  vendors: string[];
  groups: Pick<ConnectedDataItem, 'filters' | 'envLabel' | 'siteLabel' | 'envLabelValues' | 'siteLabelValues'>[];
}

export function useConnectedDataConfig() {
  const queryClient = useQueryClient();
  const isSetupDone = queryClient.getQueryData(['setup-asserts']) !== undefined;

  return useQuery(
    ['connected-data-config'],
    () =>
      apiHttpService
        .get<Partial<ConnectedDataConfigRes>>(
          '/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/stack/enable'
        )
        .then((res) => {
          return {
            vendors: res.data.vendors || [],
            filters: res.data.groups?.[0]?.filters || [],
            envLabel: res.data.groups?.[0]?.envLabel || '',
            siteLabel: res.data.groups?.[0]?.siteLabel || '',
            envLabelValues: res.data.groups?.[0]?.envLabelValues || [],
            siteLabelValues: res.data.groups?.[0]?.siteLabelValues || [],
          };
        }),
    { enabled: isSetupDone, staleTime: 0 }
  );
}
