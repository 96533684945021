/**
 *
 * HealthRulesItem
 *
 */

import React, { memo, FunctionComponent, useState, MouseEvent } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import { AssertionGroupRule } from 'asserts-types';
import { assertsColors } from 'app/constants';
import { Checkbox } from '@grafana/ui';
import { Tooltip } from 'components/Tooltip/Tooltip.component';

interface IProps {
  option?: AssertionGroupRule;
  start: number | string;
  end: number | string;
  onChange?: (option: AssertionGroupRule) => void;
  selected: boolean;
}

const HealthRulesItem: FunctionComponent<IProps> = ({
  option,
  onChange,
  selected,
}) => {
  const intl = useIntl();
  const [expanded, setExpanded] = useState(false);
  const [copiedUrl, setCopiedUrl] = useState(false);

  if (!option) {
    return null;
  }

  const handleItemClick = (e: MouseEvent) => {
    if (onChange) {
      e.stopPropagation();
      e.preventDefault();
      onChange(option);
    }
  };

  const handleCopyExpr = (e: MouseEvent<HTMLSpanElement>, expr: string) => {
    e.stopPropagation();
    e.preventDefault();
    if (navigator.clipboard) {
      navigator.clipboard.writeText(expr);
      setCopiedUrl(true);
      setTimeout(() => setCopiedUrl(false), 2000);
    }
  };

  return (
    <div className="w-full">
      <div className="flex items-center justify-between" onMouseDown={handleItemClick}>
        <div className="min-w-0">
          <div className="flex items-center justify-between gap-3 min-w-0 mr-[30px]">
            <i
              onMouseDown={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setExpanded(!expanded);
              }}
              className={`icon-rounded-${expanded ? 'down' : 'right'} text-primary text-2xl mr-1 cursor-pointer
              `}
            />
            <Checkbox checked={selected} color="primary" className="pointer-events-none" />
            <p className="text-ellipsis overflow-hidden cursor-pointer whitespace-nowrap" title={option.alert}>
              {option.alert}
            </p>
            <div
              style={{ background: option?.dot ? assertsColors[option.dot] : undefined }}
              className="w-[8px] h-[8px] bg-gray-200 left-2 rounded-full"
            ></div>
            {option.count && option.count > 1 && (
              <div className="text-xs uppercase text-secondary ml-[20px]">{option.labels.asserts_severity}</div>
            )}
          </div>
        </div>

        {/* <Button
          size="small"
          color="primary"
          className="shrink-0"
          onClick={(e) => {
            e.stopPropagation();            
            const url = new URL(`${window.location.origin}/metrics`);
            url.searchParams.set(
              'iframeUrl',
              `${GRAFANA_METRICS_URL}?${getGrafanaMetricsUrlParams({
                from: stringToDate(start).valueOf(),
                to: stringToDate(end).valueOf(),
                expr: option.expr,
              })}`,
            );
            const win = window.open(url.toString(), '_blank');
            win?.focus();
          }}
          endIcon={<OpenInNewIcon />}
        >
          {intl.formatMessage(messages.metrics)}
        </Button> */}
      </div>
      {expanded && (
        <div className="pl-[42px]">
          <div className="py-[5px]">
            <Tooltip content={intl.formatMessage(copiedUrl ? messages.copied : messages.copyExpr)}>
              <span
                className="hover:underline hover:cursor-pointer"
                onMouseDown={(e) => handleCopyExpr(e, option.expr)}
              >
                <strong className="dark:text-neutral-500">expr:</strong> {option.expr}
              </span>
            </Tooltip>
          </div>
          <div className="py-[5px]">
            <strong className="dark:text-neutral-500">for:</strong> {option.for}
          </div>
          <div className="py-[5px]">
            <strong className="dark:text-neutral-500">labels:</strong>
          </div>
          <div className="pl-[3px] pb-[10px]">
            {Object.keys(option.labels).map((key) => (
              <div key={key}>
                {key}: {option.labels[key as keyof AssertionGroupRule['labels']]}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(HealthRulesItem);
