import { AssertsBackendStatus, AssertsSanityCheckNames, SanityCheckResults } from 'asserts-types';
import useBackendStatus from 'hooks/useBackendStatus';
import { useCardinalityCheck } from './useCardinalityCheck';
import { useQuery } from '@tanstack/react-query';
import { apiHttpService } from 'app/api-http-service';
import TrackingHelper from 'helpers/Tracking.helper';
import { TRACKING_EVENTS } from 'global-constants';

export const usePreOnboardingCheck = () => {
  const { data: backendStatus } = useBackendStatus();

  const enabled =
    !!backendStatus?.status &&
    [AssertsBackendStatus.NOT_INITIALIZED, AssertsBackendStatus.INCOMPLETE].includes(backendStatus?.status);

  const { data: cardinalityCheckData, isFetching: isFetchingCardinality } = useCardinalityCheck({
    enabled,
  });

  const { data, isFetching: isFetchingSanityCheck } = useQuery(['sanity-check'], () =>
    apiHttpService
      .get<SanityCheckResults>(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/stack/sanity`)
      .then((res) => res.data)
  );

  const k8sCheck = data?.failedSanityCheckSteps.find((c) => c.checkName === AssertsSanityCheckNames.KUBERNETES);
  const istioCheck = data?.failedSanityCheckSteps.find((c) => c.checkName === AssertsSanityCheckNames.ISTIO);
  const otelTracesCheck = data?.failedSanityCheckSteps.find((c) => c.checkName === AssertsSanityCheckNames.OTEL_TRACES);

  if (hasBlockers(k8sCheck) || !k8sCheck?.dataPresent) {
    TrackingHelper.trackEvent(TRACKING_EVENTS.ONBOARDING_FAILED_K8S_CHECK);
  }

  if (hasBlockers(istioCheck)) {
    TrackingHelper.trackEvent(TRACKING_EVENTS.ONBOARDING_FAILED_ISTIO_CHECK);
  }

  if (hasBlockers(otelTracesCheck)) {
    TrackingHelper.trackEvent(TRACKING_EVENTS.ONBOARDING_FAILED_OTEL_TRACES_CHECK);
  }

  return {
    isFetching: isFetchingCardinality || isFetchingSanityCheck,
    data: {
      k8sCheck,
      istioCheck,
      otelTracesCheck,
      ...(cardinalityCheckData || {}),
    },
  };
};

function hasBlockers(failedStep?: SanityCheckResults['failedSanityCheckSteps'][0]) {
  if (!failedStep) {
    return false;
  }
  return failedStep.stepResults.some((sr) => sr.blockers?.length);
}
