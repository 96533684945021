/**
 *
 * ThresholdRequestForm
 *
 */

import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import assertionsOptions from '../../../../config/assertions_resource.json';
import useDidUpdateEffect from 'hooks/useDidUpdate';
import { connect, ConnectedProps } from 'react-redux';

import { useForm, Controller } from 'react-hook-form';
import errorMessages from 'app/errorMessages';
import { ThresholdItem, AssertionSeverity } from 'asserts-types';
import thresholdAssertionMap from '../../../../config/threshold_assertion_map.json';
import has from 'helpers/has.helper';
import { setItemToPopulate } from '../../../../ManageAssertions.slice';
import ThresholdChartComponent from '../../../ThresholdChart/ThresholdChart.component';
import { stringToDate } from 'helpers/Date.helper';
import ThresholdValueTooltipComponent from '../../../ThresholdValueTooltip/ThresholdValueTooltip.component';
import { exporterQueryMap, saveThreshold } from 'services/ManageAssertions.service';
import { Button, Field, Input, PanelContainer, Select } from '@grafana/ui';
import { SelectableValue } from '@grafana/data';
import { omit } from 'lodash';
import { getThreholdValueQueryForResource } from 'helpers/Query.helper';
import useGrafanaQuery from 'hooks/useGrafanaQuery';
import { getQueryTimeRange } from 'helpers/Time.helper';
import { Tooltip } from 'components/Tooltip/Tooltip.component';

export const severityOptions: AssertionSeverity[] = ['warning', 'critical'];

interface IProps {
  onAdd: (item: ThresholdItem) => void;
  globalList: ThresholdItem[];
  customList: ThresholdItem[];
  fetchingLists: boolean;
}

const connector = connect(
  (state: RootState) => ({
    itemToPopulate: state.manageAssertions.itemToPopulate,
    start: state.app.start,
    end: state.app.end,
  }),
  { setItemToPopulate }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IForm {
  assertionField: SelectableValue<string> | null;
  exporterField: string | null;
  resourceTypeField: string | null;
  containerField: string | null;
  topicField: string | null;
  severityField: string | null;
  jobField: string | null;
  valueField: string;
}

const EMPTY_OPTION = 'none';

const getLabelsFromForm = (form: IForm) => {
  let labels: Record<string, string> = {};

  if (form.exporterField) {
    labels.asserts_source = form.exporterField;
  }
  if (form.resourceTypeField) {
    labels.asserts_resource_type = form.resourceTypeField;
  }
  if (form.containerField) {
    labels.container = form.containerField === EMPTY_OPTION ? '' : form.containerField;
  }
  if (form.topicField) {
    labels.topic = form.topicField;
  }
  if (form.severityField) {
    labels.asserts_severity = form.severityField;
  }

  if (form.jobField) {
    labels.job = form.jobField;
  }
  return labels;
};

const ThresholdResourceForm: FunctionComponent<IProps & PropsFromRedux> = ({
  onAdd,
  globalList,
  customList,
  fetchingLists,
  itemToPopulate,
  setItemToPopulate,
  start,
  end,
}) => {
  const intl = useIntl();

  const startMs = useMemo(() => stringToDate(start).valueOf(), [start]);
  const endMs = useMemo(() => stringToDate(end).valueOf(), [end]);

  const { handleSubmit, setValue, watch, control, reset, getValues } = useForm<IForm>({
    defaultValues: {
      assertionField: null,
      valueField: '',
      exporterField: null,
      resourceTypeField: null,
      severityField: null,
      containerField: null,
      topicField: null,
      jobField: null,
    },
    mode: 'onChange',
  });

  const [saving, setSaving] = useState(false);

  const watchAssertionField = watch('assertionField');
  const watchExporterField = watch('exporterField');
  const watchResourceTypeField = watch('resourceTypeField');
  const watchSeverityField = watch('severityField');
  const watchContainerField = watch('containerField');

  const currentFormData = watch();

  const assertionQueryPart =
    exporterQueryMap[watchAssertionField?.value?.toString() as keyof typeof exporterQueryMap] || null;

  const timeWindowQuery = start && end && getQueryTimeRange(startMs, endMs);

  const { data: resourceTypeData, isFetching: isFetchingResourceTypeOptions } = useGrafanaQuery({
    query: `group by(asserts_resource_type) (last_over_time(${assertionQueryPart}${timeWindowQuery || '[2m]'}))`,
    enabled: Boolean(assertionQueryPart),
  });

  const resourceTypeOptions = resourceTypeData?.map((o) =>
    typeof o.metric.asserts_resource_type === 'string' ? o.metric.asserts_resource_type : 'none'
  );

  const { data: containerData, isFetching: isFetchingContainerOptions } = useGrafanaQuery({
    query: `group by(container) (last_over_time(${assertionQueryPart}{asserts_resource_type="${watchResourceTypeField}"}${
      timeWindowQuery || '[2m]'
    }))`,
    enabled: Boolean(watchResourceTypeField),
  });

  const containerOptions = containerData
    ?.map((o) => (typeof o.metric.container === 'string' ? o.metric.container : ''))
    .filter((c) => c);

  const { data: exporterData, isFetching: isFetchingExporterOptions } = useGrafanaQuery({
    query: `group by(asserts_source) (last_over_time(${assertionQueryPart}{asserts_resource_type="${watchResourceTypeField}", container="${watchContainerField}"}${
      timeWindowQuery || '[2m]'
    }))`,
    enabled: Boolean(watchResourceTypeField && watchContainerField),
  });

  const exporterOptions = exporterData
    ?.map((o) => (typeof o.metric.asserts_source === 'string' ? o.metric.asserts_source : ''))
    .filter((c) => c);

  const { data: jobData, isFetching: isFetchingJobOptions } = useGrafanaQuery({
    query: `group by(job) (last_over_time(${assertionQueryPart}{asserts_resource_type="${watchResourceTypeField}", container="${watchContainerField}", asserts_source="${watchExporterField}"}${
      timeWindowQuery || '[2m]'
    }))`,
    enabled: Boolean(watchResourceTypeField && watchContainerField && watchExporterField),
  });

  const jobOptions = jobData?.map((o) => (typeof o.metric.job === 'string' ? o.metric.job : '')).filter((c) => c);

  const { data: topicData, isFetching: isFetchingTopicOptions } = useGrafanaQuery({
    query: `group by(topic) (last_over_time(${assertionQueryPart}{asserts_resource_type="${watchResourceTypeField}", container="${watchContainerField}", asserts_source="${watchExporterField}"}${
      timeWindowQuery || '[2m]'
    }))`,
    enabled: Boolean(watchResourceTypeField && watchContainerField && watchExporterField),
  });

  const topicOptions = topicData?.map((o) => (typeof o.metric.job === 'string' ? o.metric.job : '')).filter((c) => c);

  useDidUpdateEffect(() => {
    exporterOptions?.length && resetIfNotInOptions('exporterField', exporterOptions);

    resourceTypeOptions?.length && resetIfNotInOptions('resourceTypeField', resourceTypeOptions);

    containerOptions?.length && resetIfNotInOptions('containerField', containerOptions);

    jobOptions?.length && resetIfNotInOptions('jobField', jobOptions);

    topicOptions?.length && resetIfNotInOptions('topicField', topicOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exporterData, resourceTypeData, containerData, jobData, topicData]);

  const resetIfNotInOptions = (field: keyof IForm, options: string[]) => {
    const value = getValues(field);
    if (typeof value === 'string' && !options.find((option) => option === value)) {
      setValue(field, null);
    }
  };

  const severityOptionsFiltered = useMemo(
    () =>
      severityOptions.filter(
        (option) =>
          !(
            option === 'warning' &&
            (watchAssertionField?.value === 'asserts:resource:rate:critical' ||
              watchAssertionField?.value === 'asserts:resource:usage:limit')
          )
      ),
    [watchAssertionField?.value]
  );

  useEffect(() => {
    const list = globalList.concat(customList);
    if (
      !fetchingLists &&
      itemToPopulate &&
      !list.some(
        (item) =>
          itemToPopulate.asserts_source === item.labels?.asserts_source &&
          itemToPopulate.asserts_resource_type === item.labels?.asserts_resource_type &&
          itemToPopulate.container === item.labels?.container &&
          itemToPopulate.topic === item.labels?.topic &&
          itemToPopulate.asserts_severity === item.labels?.asserts_severity
      ) &&
      assertionsOptions.find(
        (item) =>
          has(thresholdAssertionMap, item.value) && thresholdAssertionMap[item.value] === itemToPopulate.alertname
      )
    ) {
      const assertionFieldValue = Object.entries(thresholdAssertionMap).find(
        ([key, value]) => value === itemToPopulate.alertname
      )?.[0];

      assertionFieldValue &&
        itemToPopulate.alertname &&
        setValue('assertionField', {
          label: itemToPopulate.alertname,
          value: assertionFieldValue,
        });

      itemToPopulate.asserts_source && setValue('exporterField', itemToPopulate.asserts_source);

      itemToPopulate.asserts_resource_type && setValue('resourceTypeField', itemToPopulate.asserts_resource_type);

      itemToPopulate.container && setValue('containerField', itemToPopulate.container);

      if (itemToPopulate.container === '') {
        setValue('containerField', EMPTY_OPTION);
      }

      itemToPopulate.topic && setValue('topicField', itemToPopulate.topic);

      itemToPopulate.asserts_severity && setValue('severityField', itemToPopulate.asserts_severity);

      setItemToPopulate(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customList, globalList, itemToPopulate, fetchingLists]);

  const onSubmit = handleSubmit((data) => {
    if (data.assertionField?.value) {
      setSaving(true);

      const labels = getLabelsFromForm(data);

      saveThreshold(data.assertionField.value.toString(), data.valueField, labels)
        .then(() => {
          onAdd({
            expr: data.valueField,
            record: data.assertionField?.value?.toString() || '',
            labels,
          });
          reset();
        })
        .finally(() => setSaving(false));
    }
  });

  const defaultValue = useMemo(
    () =>
      globalList.find(
        (item) =>
          item.labels?.asserts_severity === watchSeverityField &&
          item.labels?.asserts_resource_type === watchResourceTypeField &&
          watchResourceTypeField &&
          watchSeverityField
      )?.expr,
    [globalList, watchResourceTypeField, watchSeverityField]
  );

  const labels = omit(getLabelsFromForm(currentFormData), 'asserts_severity');

  const thresholdQuery = labels.asserts_resource_type
    ? getThreholdValueQueryForResource(labels as Record<string, string> & { asserts_resource_type: string })
    : undefined;

  const { data: currentThresholdData } = useGrafanaQuery({
    query: thresholdQuery || '',
    enabled: !!labels.asserts_resource_type,
  });

  const thresholdLevel =
    typeof currentThresholdData?.[0]?.metric.asserts_threshold_level === 'string'
      ? currentThresholdData?.[0]?.metric.asserts_threshold_level
      : undefined;

  return (
    <PanelContainer className="mt-8 p-6">
      <p className="mb-6 text-xl">{intl.formatMessage(messages.addTitle)}</p>
      <form onSubmit={onSubmit}>
        <div className="flex items-start gap-4">
          <Controller
            name="assertionField"
            control={control}
            rules={{ required: intl.formatMessage(errorMessages.required) }}
            render={({ field, fieldState }) => (
              <Field
                invalid={!!fieldState.error}
                error={fieldState.error?.message}
                label={intl.formatMessage(messages.assertion)}
                className="w-0 grow"
              >
                <Select {...field} isClearable options={assertionsOptions} disabled={saving} />
              </Field>
            )}
          />
          <Controller
            name="resourceTypeField"
            control={control}
            rules={{ required: intl.formatMessage(errorMessages.required) }}
            render={({ field, fieldState }) => (
              <Field
                invalid={!!fieldState.error}
                error={fieldState.error?.message}
                label={intl.formatMessage(messages.resourceType)}
                className="w-0 grow"
              >
                <Select
                  {...field}
                  isClearable
                  onChange={(v) => field.onChange(v?.value || '')}
                  options={
                    resourceTypeOptions?.map((o) => ({
                      label: o,
                      value: o,
                    })) || []
                  }
                  virtualized
                  disabled={saving}
                  isLoading={isFetchingResourceTypeOptions}
                />
              </Field>
            )}
          />
          <Controller
            name="containerField"
            control={control}
            render={({ field }) => (
              <Field label={intl.formatMessage(messages.container)} className="w-0 grow">
                <Select
                  {...field}
                  isClearable
                  onChange={(v) => field.onChange(v?.value || '')}
                  options={containerOptions?.map((o) => ({ label: o, value: o })) || []}
                  virtualized
                  disabled={saving}
                  isLoading={isFetchingContainerOptions}
                />
              </Field>
            )}
          />
          <Controller
            name="exporterField"
            control={control}
            rules={{ required: intl.formatMessage(errorMessages.required) }}
            render={({ field, fieldState }) => (
              <Field
                invalid={!!fieldState.error}
                error={fieldState.error?.message}
                label={intl.formatMessage(messages.exporter)}
                className="w-0 grow"
              >
                <Select
                  {...field}
                  isClearable
                  onChange={(v) => field.onChange(v?.value || '')}
                  options={exporterOptions?.map((o) => ({ label: o, value: o })) || []}
                  virtualized
                  disabled={saving}
                  isLoading={isFetchingExporterOptions}
                />
              </Field>
            )}
          />
          {watchAssertionField?.value === 'asserts:resource:threshold' && (
            <Controller
              name="jobField"
              control={control}
              render={({ field }) => (
                <Field label={intl.formatMessage(messages.job)} className="w-0 grow">
                  <Select
                    {...field}
                    isClearable
                    onChange={(v) => field.onChange(v?.value || '')}
                    options={jobOptions?.map((o) => ({ label: o, value: o })) || []}
                    virtualized
                    disabled={saving}
                    isLoading={isFetchingJobOptions}
                  />
                </Field>
              )}
            />
          )}

          {!!topicOptions?.length && (
            <Controller
              name="topicField"
              control={control}
              render={({ field }) => (
                <Field label={intl.formatMessage(messages.topic)} className="w-0 grow">
                  <Select
                    {...field}
                    isClearable
                    onChange={(v) => field.onChange(v?.value || '')}
                    options={topicOptions?.map((o) => ({ label: o, value: o })) || []}
                    virtualized
                    disabled={!topicOptions?.length || saving}
                    isLoading={isFetchingTopicOptions}
                  />
                </Field>
              )}
            />
          )}

          <Controller
            name="severityField"
            control={control}
            rules={{ required: intl.formatMessage(errorMessages.required) }}
            render={({ field, fieldState }) => (
              <Field
                className="w-0 grow"
                invalid={!!fieldState.error}
                error={fieldState.error?.message}
                label={intl.formatMessage(messages.severity)}
              >
                <Select
                  {...field}
                  isClearable
                  onChange={(v) => field.onChange(v?.value || '')}
                  options={severityOptionsFiltered?.map((o) => ({ label: o, value: o })) || []}
                  disabled={saving}
                  isLoading={isFetchingResourceTypeOptions}
                />
              </Field>
            )}
          />
          <Controller
            name="valueField"
            control={control}
            rules={{ required: intl.formatMessage(errorMessages.required) }}
            render={({ field, fieldState }) => (
              <Tooltip
                content={
                  <ThresholdValueTooltipComponent
                    labels={getLabelsFromForm(currentFormData)}
                    assertion={watchAssertionField?.value?.toString()}
                    start={start}
                    end={end}
                    onApply={(value) => setValue('valueField', value)}
                  />
                }
                show={Boolean(watchResourceTypeField && watchExporterField && watchAssertionField)}
                placement="top"
              >
                <Field
                  invalid={!!fieldState.error}
                  className="w-0 grow"
                  error={fieldState.error?.message}
                  label={intl.formatMessage(messages.value) + (defaultValue ? ` (${defaultValue})` : '')}
                >
                  <Input {...field} placeholder="Enter value" type="number" disabled={saving} />
                </Field>
              </Tooltip>
            )}
          />
        </div>
        <ThresholdChartComponent
          show={Boolean(watchResourceTypeField && watchExporterField && watchAssertionField)}
          labels={labels}
          assertion={watchAssertionField?.value?.toString()}
          value={currentFormData.valueField ? +currentFormData.valueField : undefined}
          thresholdLevel={thresholdLevel}
          thresholdQuery={thresholdQuery}
        />
        <Button color="primary" type="submit" disabled={saving}>
          {intl.formatMessage(messages.addNew)}
        </Button>
      </form>
    </PanelContainer>
  );
};

export default connector(ThresholdResourceForm);
