import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MAX_SEARCHES_COUNT, SLICE_NAME } from './constants';
import { TopInsightsContainerState, TopInsightsViewType } from 'asserts-types';

const includes = (arr: string[], query: string) =>
  arr.map((q) => q.toLowerCase()).includes(query.toLowerCase());

const initialState: TopInsightsContainerState = {
  assertionStatUrl: '',
  scaleType: 'linear',
  topInsightsSearches: ['Show all Services'],
  pinnedSearches: [],
  expandedSearches: [],
  selectedViewTypeMap: {},
};

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,

  reducers: {
    setAssertionStatUrl: (state, action: PayloadAction<string>) => {
      state.assertionStatUrl = action.payload;
    },

    setScaleType: (state, action: PayloadAction<'log' | 'linear'>) => {
      state.scaleType = action.payload;
    },

    toggleSearch: (state, action: PayloadAction<string>) => {
      const searchToToggle = action.payload.toLowerCase();
      const currentSearches = state.topInsightsSearches.map((s) =>
        s.toLowerCase(),
      );

      if (currentSearches.includes(searchToToggle)) {
        state.topInsightsSearches = state.topInsightsSearches.filter(
          (item) => item.toLowerCase() !== searchToToggle,
        );
      } else {
        state.topInsightsSearches = [action.payload].concat(
          state.topInsightsSearches,
        );
      }
    },

    removeSearch: (state, action: PayloadAction<string>) => {
      state.topInsightsSearches = state.topInsightsSearches.filter(
        (item) => item.toLowerCase() !== action.payload.toLowerCase(),
      );
      state.pinnedSearches = state.pinnedSearches.filter(
        (item) => item.toLowerCase() !== action.payload.toLowerCase(),
      );
    },

    addSearch: (state, action: PayloadAction<string>) => {
      const searchQuery = action.payload;
      let updatedSearches = state.topInsightsSearches.slice();

      if (
        state.topInsightsSearches.length === MAX_SEARCHES_COUNT &&
        !includes(state.topInsightsSearches, searchQuery)
      ) {
        const notPinnedSearch = state.topInsightsSearches.find(
          (s) => !includes(state.pinnedSearches, s),
        );
        // removing not pinned search if MAX_SEARCHES_COUNT exceeded
        if (notPinnedSearch) {
          updatedSearches = state.topInsightsSearches.filter(
            (item) => item.toLowerCase() !== notPinnedSearch.toLowerCase(),
          );
        }
      }

      if (includes(state.topInsightsSearches, searchQuery)) {
        updatedSearches = [searchQuery].concat(
          updatedSearches.filter(
            (item) => item.toLowerCase() !== searchQuery.toLowerCase(),
          ),
        );
      } else {
        updatedSearches = [searchQuery].concat(updatedSearches);
      }

      state.topInsightsSearches = updatedSearches;
      state.expandedSearches = [searchQuery];
    },

    toggleExpandSearch: (state, action: PayloadAction<string>) => {
      if (
        state.expandedSearches
          .map((s) => s.toLowerCase())
          .indexOf(action.payload.toLowerCase()) !== -1
      ) {
        state.expandedSearches = state.expandedSearches.filter(
          (item) => item.toLowerCase() !== action.payload.toLowerCase(),
        );
      } else {
        state.expandedSearches.push(action.payload);
      }
    },

    resetExpandedSearches: (state) => {
      state.expandedSearches = [];
    },

    togglePinnedSearch: (state, action: PayloadAction<string>) => {
      const searchToToggle = action.payload.toLowerCase();
      const currentSearches = state.pinnedSearches.map((s) => s.toLowerCase());

      if (currentSearches.includes(searchToToggle)) {
        state.pinnedSearches = state.pinnedSearches.filter(
          (item) => item.toLowerCase() !== searchToToggle,
        );
      } else {
        state.pinnedSearches = state.pinnedSearches.concat(action.payload);
      }
    },

    setSelectedViewTypeMap: (
      state,
      action: PayloadAction<{
        topInsightsSearch: string;
        type: TopInsightsViewType;
      }>,
    ) => {
      state.selectedViewTypeMap[action.payload.topInsightsSearch] =
        action.payload.type;
    },
  },
});

export const {
  setAssertionStatUrl,
  setScaleType,
  toggleSearch,
  togglePinnedSearch,
  toggleExpandSearch,
  resetExpandedSearches,
  setSelectedViewTypeMap,
  removeSearch,
  addSearch,
} = slice.actions;

export default slice.reducer;
