/*
 * HealthRulesItem Messages
 *
 * This contains all the text for the HealthRulesItem component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.HealthRulesItem';

export default defineMessages({
  metrics: {
    id: `${scope}.metrics`,
    defaultMessage: 'metrics',
  },
  copyExpr: {
    id: `${scope}.copyExpr`,
    defaultMessage: 'Copy expr',
  },
  copied: {
    id: `${scope}.copied`,
    defaultMessage: 'Copied.',
  },
});
