/*
 * ZoomableLineRangeChart Messages
 *
 * This contains all the text for the ZoomableLineRangeChart component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.ZoomableLineRangeChart';

export default defineMessages({
  incidentTooltip: {
    id: `${scope}.incidentTooltip`,
    defaultMessage: 'Click to view in Workbench',
  },
});
