/**
 *
 * IncidentsList
 *
 */

import React, { memo, ForwardRefRenderFunction, forwardRef, Fragment } from 'react';
import useSharedRef from 'hooks/useSharedRef';
import IncidentListItem from '../IncidentListItem/IncidentListItem.component';
import IncidentGroupItem from '../IncidentGroupItem/IncidentGroupItem.component';
import { GroupedIncident } from 'asserts-types';
import { toggleExpandItem } from '../../Incidents.slice';
import IncidentsSortByComponent from '../IncidentsSortBy/IncidentsSortBy.component';
import useSummaryLeftPanel from 'features/Assertions/hooks/useSummaryLeftPanel';
import { AXIS_PADDING_RIGHT } from 'app/constants';
import TableHeaderComponent from 'components/TableHeader/TableHeader.component';
import TimelineSerifsComponent from 'components/TimelineSerifs/TimelineSerifs.component';
import IncidentTimeLineItemComponent from '../IncidentTimeLineItem/IncidentTimeLineItem.component';
import AssertionsSummaryResizeHandleComponent from 'features/Assertions/components/AssertionsSummaryResizeHandle/AssertionsSummaryResizeHandle.component';

interface IProps {
  groupedIncidents: GroupedIncident[];
  expandedItems: string[];
  toggleExpandItem: typeof toggleExpandItem;
  start: number;
  end: number;
  chartName: string;
}

const IncidentsList: ForwardRefRenderFunction<HTMLDivElement, IProps> = (
  { groupedIncidents, expandedItems, toggleExpandItem, start, end, chartName },
  refToForward
) => {
  const ref = useSharedRef<HTMLDivElement | null>(null, [refToForward]);
  const { width: leftPanelWidth } = useSummaryLeftPanel();

  return (
    <div className="overflow-overlay h-full">
      <div
        className="grid sticky top-0 z-[10]"
        style={{
          gridTemplateColumns: `${leftPanelWidth}px auto ${AXIS_PADDING_RIGHT}px`,
        }}
      >
        <TableHeaderComponent className="py-0 pl-2 pr-4 sticky top-0 z-[10] flex items-center justify-between">
          <IncidentsSortByComponent />
        </TableHeaderComponent>
        <div>
          <TableHeaderComponent>
            <TimelineSerifsComponent start={start} end={end} />
          </TableHeaderComponent>
        </div>
        <div className="bg-panel" />
      </div>
      <div ref={ref} data-tour-target="notifications-list">
        {groupedIncidents.map((groupedIncident) =>
          !!groupedIncident.nestedItems?.length ? (
            <Fragment key={groupedIncident.id}>
              <div
                className="grid"
                style={{
                  gridTemplateColumns: `${leftPanelWidth}px auto`,
                }}
              >
                <IncidentGroupItem
                  groupedIncident={groupedIncident}
                  expanded={expandedItems.includes(groupedIncident.id)}
                  toggleExpandItem={toggleExpandItem}
                />
                <IncidentTimeLineItemComponent
                  incidentGroup={groupedIncident}
                  expanded={!groupedIncident.nestedItems?.length && expandedItems.includes(groupedIncident.id)}
                  start={start}
                  end={end}
                  isLeaf={!groupedIncident.nestedItems?.length}
                  chartName={chartName}
                />
              </div>
              {expandedItems.includes(groupedIncident.id) &&
                groupedIncident.nestedItems?.map((incidentGroup) => (
                  <div
                    className="grid"
                    style={{
                      gridTemplateColumns: `${leftPanelWidth}px auto`,
                    }}
                    key={incidentGroup.id}
                  >
                    <IncidentListItem
                      incidentGroup={incidentGroup}
                      expanded={expandedItems.includes(incidentGroup.id)}
                      level={1}
                      start={start}
                      end={end}
                    />
                    <IncidentTimeLineItemComponent
                      incidentGroup={incidentGroup}
                      expanded={expandedItems.includes(incidentGroup.id)}
                      start={start}
                      end={end}
                      isLeaf
                      chartName={chartName}
                    />
                  </div>
                ))}
            </Fragment>
          ) : (
            <div
              className="grid"
              style={{
                gridTemplateColumns: `${leftPanelWidth}px auto`,
              }}
              key={groupedIncident.id}
            >
              <IncidentListItem
                key={groupedIncident.id}
                incidentGroup={groupedIncident}
                expanded={expandedItems.includes(groupedIncident.id)}
                start={start}
                end={end}
              />
              <IncidentTimeLineItemComponent
                incidentGroup={groupedIncident}
                expanded={expandedItems.includes(groupedIncident.id)}
                start={start}
                end={end}
                isLeaf
                chartName={chartName}
              />
            </div>
          )
        )}
      </div>
      {groupedIncidents.length && <AssertionsSummaryResizeHandleComponent />}
    </div>
  );
};

export default memo(forwardRef(IncidentsList));
