import { SelectableValue } from '@grafana/data';
import { Select } from '@grafana/ui';
import { MAX_AUTOCOMPLETE_RESULTS_COUNT } from 'app/constants';
import { useAppSelector } from 'app/store';
import { Definition } from 'asserts-types';
import { addToTopSearches, clearEntities, setSearchDefinition } from 'features/Entities/Entities.slice';
import { TRACKING_FEATURES } from 'global-constants';
import TrackingHelper from 'helpers/Tracking.helper';
import useDebounceValue from 'hooks/useDebounceValue';
import useDefinitionAutocomplete from 'hooks/useDefinitionAutocomplete';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export default function EntitiesSearchQuerySelect() {
  const [query, setQuery] = useState('');

  const dispatch = useDispatch();

  const searchDefinition = useAppSelector((state) => state.entities.searchDefinition);

  const debouncedQuery = useDebounceValue(query, 300);

  const { data, isFetching } = useDefinitionAutocomplete({
    query: debouncedQuery,
    max: MAX_AUTOCOMPLETE_RESULTS_COUNT,
    enabled: Boolean(debouncedQuery),
  });

  const options: SelectableValue<Definition>[] = data?.map((d) => ({ value: d, label: d.boundDescription })) || [];
  const value = searchDefinition ? { value: searchDefinition, label: searchDefinition.boundDescription } : null;

  useEffect(() => {
    if (searchDefinition?.boundDescription) {
      TrackingHelper.trackSearchExpression(searchDefinition?.boundDescription, TRACKING_FEATURES.ENTITIES_EXPLORER);
    }
  }, [searchDefinition?.boundDescription])

  return (
    <Select
      value={value}
      options={options}
      className="grow"
      isLoading={isFetching}
      filterOption={() => true}
      onChange={(v) => {
        dispatch(clearEntities());
        dispatch(setSearchDefinition(v?.value || null));
        if (v?.value?.boundDescription) {
          dispatch(addToTopSearches(v.value.boundDescription));
        }
      }}
      noOptionsMessage={!query ? 'Start typing query (e.g. Show all...)' : undefined}
      onInputChange={(v) => setQuery(v)}
      isClearable
      placeholder="Enter search query (e.g. Show all Services)"
    />
  );
}
