import { useQuery } from '@tanstack/react-query';
import { fetchGrafanaQuery } from 'services/ManageAssertions.service';
import { useMetricsDataSource } from './useMetricsDatasource';

interface IProps {
  enabled: boolean;
  query: string;
}

export default function useGrafanaQuery({ query, enabled }: IProps) {
  const { data: metricsDatasoure } = useMetricsDataSource();

  return useQuery(['grafana-query', query], () => fetchGrafanaQuery(metricsDatasoure.uid, query), {
    enabled: Boolean(metricsDatasoure.uid) && enabled,
  });
}
