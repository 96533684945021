/*
 * CustomModelRules Messages
 *
 * This contains all the text for the CustomModelRules component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.CustomModelRules';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Custom Model Rules',
  },
  addNew: {
    id: `${scope}.addNew`,
    defaultMessage: 'New rule file',
  },
  fileSaved: {
    id: `${scope}.fileSaved`,
    defaultMessage: 'File was saved successfully',
  },
  fileRemoved: {
    id: `${scope}.fileSaved`,
    defaultMessage: 'File was removed successfully',
  },
  help: {
    id: `${scope}.help`,
    defaultMessage: 'How to configure Custom Model Rules',
  },
});
