/*
 * AssertionsSummaryGraph Messages
 *
 * This contains all the text for the AssertionsSummaryGraph component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.AssertionsSummaryGraph';

export default defineMessages({
  minimize: {
    id: `${scope}.minimize`,
    defaultMessage: 'Minimize',
  },
  expand: {
    id: `${scope}.expand`,
    defaultMessage: 'Expand',
  },
  empty: {
    id: `${scope}.empty`,
    defaultMessage: 'Hover over the timeline to see a live view of the Entity Graph',
  },
});
