import { stringToDate } from '../helpers/Date.helper';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { fetchRequestContextOptions } from 'services/ManageAssertions.service';
import { useMetricsDataSource } from './useMetricsDatasource';

interface UseParams {
  job: string | null;
  requestType: string | null;
  start?: number | string;
  end?: number | string;
}
export default function useRequestContextOptions({
  job,
  requestType,
  enabled,
  start,
  end,
}: UseParams & Pick<UseQueryOptions, 'enabled'>) {
  const { data: metricsDatasource } = useMetricsDataSource();

  return useQuery<string[]>(
    ['requestContextOptions', metricsDatasource.uid, job, requestType, start, end],
    () => {
      if (requestType) {
        return fetchRequestContextOptions(
          metricsDatasource.uid,
          job,
          requestType,
          start ? stringToDate(start).valueOf() : undefined,
          end ? stringToDate(end).valueOf() : undefined
        );
      } else {
        return [];
      }
    },
    {
      enabled,
      select: (data) => data.filter((o) => o),
      staleTime: Infinity,
    }
  );
}
