/*
 * SloObjectives Messages
 *
 * This contains all the text for the SloObjectives component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.SloObjectives';

export default defineMessages({
  targetObjectives: {
    id: `${scope}.targetObjectives`,
    defaultMessage: 'Target Objectives',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Name',
  },
  complianceWindow: {
    id: `${scope}.complianceWindow`,
    defaultMessage: 'Compliance Window (days)',
  },
  targetRatio: {
    id: `${scope}.targetRatio`,
    defaultMessage: 'Target (%)',
  },
  threshold: {
    id: `${scope}.threshold`,
    defaultMessage: 'Threshold',
  },
  secs: {
    id: `${scope}.secs`,
    defaultMessage: ' (seconds)',
  },
});
