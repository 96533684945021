/**
 *
 * YmlFileEditor
 *
 */

import React, { memo, FunctionComponent, useEffect, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import { useForm, Controller } from 'react-hook-form';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-yaml.js';
import 'ace-builds/src-noconflict/theme-github_dark';
import errorMessages from 'app/errorMessages';
import { Button, Drawer, useTheme2 } from '@grafana/ui';

// ace.config.setModuleUrl('ace/mode/yaml_worker', yamlWorkerUrl);


export interface IYmlForm {
  textField: string;
}

interface IProps {
  onClose?: () => void;
  text?: string;
  onSubmit: (data: IYmlForm) => void;
  saving: boolean;
  readOnly?: boolean;
  formOnly?: boolean;
  actions?: ReactNode;
  label?: string;
}

const YmlFileEditor: FunctionComponent<IProps> = ({
  onClose,
  text,
  onSubmit,
  saving,
  readOnly,
  formOnly,
  actions,
  label,
}) => {
  const intl = useIntl();
  const theme = useTheme2();
  const grafanaTheme = useTheme2();

  const isDarkMode = grafanaTheme.isDark;

  const { handleSubmit, setValue, control } = useForm<IYmlForm>({
    defaultValues: {
      textField: '',
    },
  });

  useEffect(() => {
    if (text !== undefined) {
      setValue('textField', text);
    }
  }, [setValue, text]);

  const renderForm = () => (
    <form className="h-full flex flex-col" onSubmit={handleSubmit(onSubmit)}>
      <div className="divider-t divider-b grow" style={{ opacity: saving ? 0.2 : 1 }}>
        <Controller
          name="textField"
          control={control}
          rules={{ required: intl.formatMessage(errorMessages.required) }}
          render={({ field, fieldState }) => (
            <>
              {fieldState.error && (
                <div className="absolute right-[10px] z-[99]">
                  <p className="text-xs text-destructive">{fieldState.error?.message}</p>
                </div>
              )}
              <AceEditor
                {...field}
                mode="yaml"
                theme={isDarkMode ? 'github_dark' : undefined}
                key={isDarkMode.toString()}
                fontSize={14}
                showPrintMargin
                highlightActiveLine
                showGutter
                width="100%"
                height="100%"
                setOptions={{
                  enableBasicAutocompletion: false,
                  enableLiveAutocompletion: false,
                  enableSnippets: false,
                  showLineNumbers: false,
                  tabSize: 2,
                  useWorker: false,
                }}
                readOnly={readOnly || saving}
              />
            </>
          )}
        />
      </div>
      {!readOnly && (
        <div className="w-full pt-5 bg-paper flex items-center gap-4">
          <Button type="submit" disabled={saving}>
            {intl.formatMessage(messages.save)}
          </Button>
          {!formOnly && (
            <Button variant="secondary" fill="text" disabled={saving} onClick={onClose}>
              {intl.formatMessage(messages.close)}
            </Button>
          )}
          {actions}
        </div>
      )}
    </form>
  );

  if (formOnly) {
    return renderForm();
  }
  return (
    <Drawer
      onClose={onClose ? onClose : () => {}}
      title={formOnly ? undefined : label || intl.formatMessage(messages.viewFile)}
    >
      {renderForm()}
    </Drawer>
  );
};

export default memo(YmlFileEditor);
