import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { apiHttpService } from 'app/api-http-service';
import { AssertionsBoardEntity } from 'asserts-types';

export const USE_PROBLEMATIC_CONNECTIONS_KEY = 'problematic-connections';

type ProblematiConnectionsRes =
  | {
      entities: AssertionsBoardEntity[] | undefined;
    }
  | undefined;

interface IProps {
  entityType: string;
  entityName: string;
  env?: string;
  site?: string;
  namespace?: string;
  start: number;
  end: number;
}

export default function useProblematicConnections(
  params: IProps,
  options?: Partial<Pick<UseQueryOptions<ProblematiConnectionsRes>, 'onSuccess' | 'onSettled' | 'enabled'>>
) {
  return useQuery(
    [USE_PROBLEMATIC_CONNECTIONS_KEY, params],
    () =>
      apiHttpService
        .post<ProblematiConnectionsRes>(
          '/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/patterns/search',
          params
        )
        .then((res) => res.data),
    {
      ...options,
    }
  );
}
