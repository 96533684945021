import { Button, Stack } from '@grafana/ui';
import { LabelFilter } from 'asserts-types';
import React from 'react';
import { Control, useFieldArray, useWatch } from 'react-hook-form';
import { LabelsFilterItem } from './LabelsFilterItem';

type Props<T> = {
  control: T extends {
    filters: LabelFilter[];
  }
    ? Control<T>
    : never;
};

export function LabelsFilter<
  T extends {
    filters: LabelFilter[];
  }
>({ control }: Props<T>) {
  const { fields, append, remove } = useFieldArray({ control, name: 'filters' });

  const filters = useWatch({ control, name: 'filters' });

  const lastField = filters.length ? filters[fields.length - 1] : undefined;
  const lastFieldFilled = lastField?.values?.length && lastField.name && lastField.operator;

  return (
    <Stack direction="column" alignItems="start" gap={1}>
      <Stack direction="column" alignItems="start">
        {fields.map((item, index) => (
          <LabelsFilterItem key={item.id} index={index} control={control} onRemove={() => remove(index)} />
        ))}
      </Stack>

      {(lastFieldFilled || fields.length === 0) && (
        <Button icon="plus" variant="secondary" onClick={() => append({ values: [], name: '', operator: '=' })}>
          Add filter
        </Button>
      )}
    </Stack>
  );
}
