import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ManageSloContainerState, SloConfigModel } from 'asserts-types';

const initialState: ManageSloContainerState = {
  sloConfigs: [],
  search: '',
};

export const slice = createSlice({
  name: 'manageSlo',

  initialState,

  reducers: {
    setSloConfig: (state, action: PayloadAction<SloConfigModel[]>) => {
      state.sloConfigs = action.payload;
    },
    addSloConfig: (state, action: PayloadAction<SloConfigModel>) => {
      state.sloConfigs.push(action.payload);
    },
    updateSloConfig: (state, action: PayloadAction<SloConfigModel>) => {
      const updatedConfig = action.payload;
      const sloConfig = state.sloConfigs.find(
        (s) => s.name === updatedConfig.name,
      );
      if (sloConfig) {
        Object.assign(sloConfig, updatedConfig);
      }
    },
    deleteSloConfig: (state, action: PayloadAction<SloConfigModel>) => {
      state.sloConfigs = state.sloConfigs.filter(
        (s) => s.name !== action.payload.name,
      );
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    }
  },
});

export const {
  setSloConfig,
  addSloConfig,
  updateSloConfig,
  deleteSloConfig,
  setSearch,
} = slice.actions;

export default slice.reducer;
