import { stringToDate } from '../helpers/Date.helper';
import { fetchRequestTypeOptions } from 'services/ManageAssertions.service';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useMetricsDataSource } from './useMetricsDatasource';

interface UseParams {
  job?: string | null;
  start?: number | string;
  end?: number | string;
}
export default function useRequestTypeOptions({
  job,
  enabled,
  start,
  end,
}: UseParams & Pick<UseQueryOptions, 'enabled'>) {
  const { data: metricsDatasource } = useMetricsDataSource();

  return useQuery<string[]>(
    ['requestTypeOptions', metricsDatasource.uid, job, start, end],
    () => {
      return fetchRequestTypeOptions(
        metricsDatasource.uid,
        job,
        start ? stringToDate(start).valueOf() : undefined,
        end ? stringToDate(end).valueOf() : undefined
      );
    },
    {
      enabled,
      select: (data) => data.filter((o) => o),
      staleTime: Infinity,
    }
  );
}
