/**
 *
 * AssertionsListItemWrap
 *
 */

import React, {
  memo,
  useCallback,
  MouseEvent,
  useEffect,
  ForwardRefRenderFunction,
  ReactNode,
  forwardRef,
  useMemo,
} from 'react';

import { toggleSelectItem } from '../../Assertions.slice';
import AssertionsListItemActions from '../AssertionsListItemActions/AssertionsListItemActions.component';
import { EntityProperties, HierarchicalAssertion } from 'asserts-types';
import { LEAF_LINE_HEIGHT, LINE_HEIGHT, MIDDLE_LEVEL_LINE_HEIGHT } from '../../constants';
import useSharedRef from 'hooks/useSharedRef';
import { ENTITY_OUT_OF_DATE_TIME } from 'features/Entities/constants';
import { Checkbox } from '@grafana/ui';

interface IProps {
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  toggleSelectItem: typeof toggleSelectItem;
  selected: boolean;
  itemId: string;
  expanded?: boolean;
  height?: number;
  className?: string;
  level: number;
  highlightedItemId?: string;
  activeTooltip?: boolean;
  labels?: Record<string, string>;
  properties?: EntityProperties;
  lastUpdateTime?: number;
  item: HierarchicalAssertion;
  labelsFilter: Record<string, string[]>;
  children?: ReactNode;
  showOnlySelected: boolean;
  start: number;
  end: number;
}

const AssertionsListItemWrap: ForwardRefRenderFunction<
  HTMLDivElement,
  IProps
> = (
  {
    children,
    onClick,
    toggleSelectItem,
    selected,
    itemId,
    expanded,
    height,
    className,
    level,
    highlightedItemId,
    activeTooltip,
    labels,
    properties,
    lastUpdateTime,
    item,
    showOnlySelected,
    start,
    end,
    labelsFilter,
  },
  refToForward,
) => {
  const ref = useSharedRef<HTMLDivElement | null>(null, [refToForward]);

  useEffect(() => {
    if (highlightedItemId === itemId && ref.current) {
      setTimeout(() => {
        ref.current?.scrollIntoView();
      }, 200);
    }
    //eslint-disable-next-line
  }, [highlightedItemId]);

  const handleClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      document.getSelection()?.removeAllRanges();
      onClick && onClick(e);
    },
    [onClick],
  );

  const outOfDate = useMemo(() => {
    if (properties && properties['Updated'] && lastUpdateTime) {
      return lastUpdateTime - +properties['Updated'] > ENTITY_OUT_OF_DATE_TIME;
    }
    return false;
  }, [properties, lastUpdateTime]);


  if (
    Boolean(Object.keys(labelsFilter).length) &&
    !Object.entries(labelsFilter).every(([label, values]) =>
      labels?.[label] ? values.includes(labels?.[label]) : false,
    )
  ) {
    return null;
  }

  if (showOnlySelected && !selected) {
    return null;
  }

  let backgroundClass = 'bg-transparent';
  const isLeaf = !item.nestedTimelines.length && !!level;
  let lineHeight = expanded
    ? height || LINE_HEIGHT
    : isLeaf
    ? LEAF_LINE_HEIGHT
    : LINE_HEIGHT;

  if (level !== 0 && !isLeaf) {
    lineHeight = MIDDLE_LEVEL_LINE_HEIGHT;
  }

  if (selected && !showOnlySelected) {
    backgroundClass = 'bg-primary/10';
  }

  return (
    <div
      className={`py-4 pr-4 pl-8 flex flex-col divider-b cursor-pointer group relative ${
        expanded ? 'justify-start' : 'justify-center'
      } ${backgroundClass} ${activeTooltip ? 'pr-4 cursor-pointer bg-black/5' : ''} ${
        outOfDate
          ? "before:content-['_'] before:absolute before:left-0 before:h-full before:w-[3px] before:bg-black/10"
          : ''
      } ${className ? className : ''}`}
      style={{ height: lineHeight }}
      onClick={handleClick}
      ref={ref}
      data-cy="wb-list-item"
    >
      <div className="flex justify-between items-center w-full relative">
        <div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            toggleSelectItem(item);
          }}
        >
          <Checkbox
            data-cy="wb-list-item-cbx"
            className={`absolute left-[-20px] top-0 ${selected ? 'block' : 'hidden'} group-hover:block`}
            checked={selected}
          />
        </div>
        {children}
      </div>
      {expanded && (
        <AssertionsListItemActions
          itemId={itemId}
          level={level}
          showNullsBtn
          labels={labels || {}}
          start={start}
          end={end}
          name={item.name}
          type={item.type}
          rootEntityName={item.rootEntityName}
          rootEntityType={item.rootEntityType}
          scope={item.scope}
        />
      )}
    </div>
  );
};

const forwardedAssertionsListItemWrap = forwardRef(AssertionsListItemWrap);

export default memo(forwardedAssertionsListItemWrap);
