/**
 *
 * AssertionsProblematicConnectionsButton
 *
 */

import React, { memo, FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import AddConnectionsIcon from 'icons/AddConnectionsIcon';
import { AssertionsBoardEntity, Scope } from 'asserts-types';
import { useDispatch } from 'react-redux';
import { addEntitiesToWorkbench } from '../../Assertions.slice';
import { areEntitiesEqual } from 'helpers/Entity.helper';
import CheckRoundedIcon from 'assets/material-icons/done_FILL0_wght400_GRAD0_opsz24.svg';
import { useAppSelector } from 'app/store';
import useProblematicConnections from 'hooks/useProblematicConnections';
import SnackbarHelper from 'helpers/Snackbar.helper';
import { Tooltip } from 'components/Tooltip/Tooltip.component';
import { IconButton } from 'components/IconButton/IconButton.component';

interface IProps {
  name: string;
  type: string;
  scope: Scope | undefined;
  start: number;
  end: number;
}

const AssertionsProblematicConnectionsButton: FunctionComponent<IProps> = ({
  name,
  type,
  scope,
  start,
  end,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const workbenchEntities = useAppSelector((state) => state.assertions.workbenchEntities);

  

  const {
    data: problematicConnections,
    isFetching,
    isFetched,
    refetch: fetchProblematicConnections,
  } = useProblematicConnections(
    {
      entityName: name,
      entityType: type,
      env: scope?.env || '',
      site: scope?.site || '',
      namespace: scope?.namespace || '',
      start,
      end,
    },
    {
      enabled: false,
      onSuccess: (data) => {
        handleAddProblematicConnections(data?.entities);
      }
    },
  );

  const addedToWorkbench = problematicConnections?.entities?.length
    ? problematicConnections.entities.every((item) =>
        workbenchEntities.find((wbItem) => areEntitiesEqual(item, wbItem)),
      )
    : false;

  const handleAddProblematicConnections = (
    entities?: AssertionsBoardEntity[],
  ) => {
    if (entities?.length) {
      dispatch(addEntitiesToWorkbench(entities));
      SnackbarHelper.success(messages.connectionsLoaded);
    }
  };
  let tooltipMessage = messages.connections;

  if (isFetching) {
    tooltipMessage = messages.loading;
  }

  if (addedToWorkbench) {
    tooltipMessage = messages.added;
  }

  if (isFetched && !problematicConnections?.entities?.length) {
    tooltipMessage = messages.notFound;
  }

  let variant: 'primary' | 'secondary' = 'primary';

  if (isFetching) {
    variant = 'secondary';
  }

  if (isFetched && !problematicConnections?.entities?.length) {
    variant = 'secondary';
  }

  return (
    <Tooltip
      placement="top"
      content={
        <>
          {formatMessage(tooltipMessage, {
            name: <span className="font-bold text-primary">{name}&apos;s</span>,
          })}
          {addedToWorkbench &&
            !!problematicConnections?.entities?.length &&
            problematicConnections?.entities.map(({ name }) => (
              <div className="flex items-center gap-2" key={name}>
                <CheckRoundedIcon className="svg-icon text-success" />
                <div className="text-primary">{name}</div>
              </div>
            ))}
        </>
      }
    >
      <IconButton
        variant={variant}
        onClick={(e) => {
          e.stopPropagation();
          if (addedToWorkbench) {
            return;
          }
          if (problematicConnections) {
            handleAddProblematicConnections(problematicConnections.entities);
          } else {
            fetchProblematicConnections();
          }
        }}
      >
        {addedToWorkbench ? <CheckRoundedIcon className="text-success" /> : <AddConnectionsIcon />}
      </IconButton>
    </Tooltip>
  );
};

export default memo(AssertionsProblematicConnectionsButton);
