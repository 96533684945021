/**
 *
 * EntitySearchRuleItem
 *
 */

import React, { memo, FunctionComponent, ChangeEvent, useMemo } from 'react';
import { useIntl } from 'react-intl';
import CloseIcon from 'assets/material-icons/close_FILL0_wght400_GRAD0_opsz24.svg';
import {
  EntityAdvancedSearchFormEntityItem,
  EntityFilterPropertyMatcher,
  EntityPropertyTypes,
  NumberRules,
  StringRules,
} from 'asserts-types';
import messages from './messages';
import { changeRule, deleteRule } from 'features/Entities/Entities.slice';
import { Input, Select } from '@grafana/ui';
import { debounce } from 'lodash';
import { IconButton } from 'components/IconButton/IconButton.component';

interface IProps {
  ruleItem: EntityFilterPropertyMatcher;
  formItem: EntityAdvancedSearchFormEntityItem;
  propertyName: string;
  deleteRule: typeof deleteRule;
  changeRule: typeof changeRule;
}

const EntitySearchRuleItem: FunctionComponent<IProps> = ({
  ruleItem,
  formItem,
  propertyName,
  deleteRule,
  changeRule,
}) => {
  const intl = useIntl();

  const ruleOptions = {
    [EntityPropertyTypes.DOUBLE]: [
      {
        value: NumberRules.EQUALS,
        label: NumberRules.EQUALS,
      },
      {
        value: NumberRules.NOT_EQUALS,
        label: NumberRules.NOT_EQUALS,
      },
      {
        value: NumberRules.GREATER,
        label: NumberRules.GREATER,
      },
      {
        value: NumberRules.GREATER_OR_EQUAL,
        label: NumberRules.GREATER_OR_EQUAL,
      },
      {
        value: NumberRules.LESS,
        label: NumberRules.LESS,
      },
      {
        value: NumberRules.LESS_OR_EQUAL,
        label: NumberRules.LESS_OR_EQUAL,
      },
      {
        value: NumberRules.IS_NOT_NULL,
        label: intl.formatMessage(messages.isNotNull),
      },
      {
        value: NumberRules.IS_NULL,
        label: intl.formatMessage(messages.isNull),
      },
    ],
    [EntityPropertyTypes.STRING]: [
      {
        value: StringRules.IS_NULL,
        label: intl.formatMessage(messages.isNull),
      },
      {
        value: StringRules.CONTAINS,
        label: intl.formatMessage(messages.contains),
      },
      {
        value: StringRules.STARTS_WITH,
        label: intl.formatMessage(messages.startsWith),
      },
      {
        value: StringRules.IS_NOT_NULL,
        label: intl.formatMessage(messages.isNotNull),
      },
      {
        value: StringRules.EQUALS,
        label: intl.formatMessage(messages.equals),
      },
      {
        value: StringRules.NOT_EQUALS,
        label: intl.formatMessage(messages.notEquals),
      },
    ],
  };

  const ruleType = formItem.properties.find(
    (item) => item.name === propertyName,
  )?.type;

  const uom = formItem.properties.find((item) => item.name === propertyName)
    ?.uom;

  const options = ruleType ? ruleOptions[ruleType] : [];

  const handleInputChange = useMemo(
    () =>
      debounce((e: ChangeEvent<HTMLInputElement>) => {
        changeRule({
          id: ruleItem.id,
          formItem,
          patchObj: {
            value: e.target.value as string,
          },
        });
      }, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );


  return (
    <div className="flex items-center mb-4 gap-2">
      <IconButton onClick={() => deleteRule({ id: ruleItem.id, formItem })}>
        <CloseIcon />
      </IconButton>
      <div className="grid grid-cols-2 gap-4">
        <Select
          onChange={(v) =>
            changeRule({
              id: ruleItem.id,
              formItem,
              patchObj: {
                op: v.value,
              },
            })
          }
          value={ruleItem.op}
          options={options}
          placeholder={intl.formatMessage(messages.rule)}
        />
        {/* <SelectInput
          onChange={(e) =>
            changeRule({
              id: ruleItem.id,
              formItem,
              patchObj: {
                op: e.target.value as NumberRules | NumberRules,
              },
            })
          }
          value={ruleItem.op}
          options={options}
          label={intl.formatMessage(messages.rule)}
        /> */}
        <Input
          className="bg-paper"
          disabled={ruleItem.op === StringRules.IS_NULL || ruleItem.op === StringRules.IS_NOT_NULL}
          data-cy="edit-rule-input-value"
          onChange={handleInputChange}
          type={ruleType === EntityPropertyTypes.DOUBLE ? 'number' : 'text'}
          defaultValue={ruleItem.value}
          // prettier-ignore
          placeholder={`${intl.formatMessage(messages.value)} ${uom ? `(${uom})` : ''}`}
        />
      </div>
    </div>
  );
};

export default memo(EntitySearchRuleItem);
