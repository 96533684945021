import { defineMessages } from 'react-intl';

export const scope = 'components.TopInsightsListItem';

export default defineMessages({
  inboundClientErrors: {
    id: `${scope}.inboundClientErrors`,
    defaultMessage: `View Inbound Client Errors`,
  },
  scoreHint: {
    id: `${scope}.scoreHint`,
    defaultMessage:
      'Asserts score is a composite measure of the # of assertions, their duration and severity reported by an entity.',
  },
  assertsScore: {
    id: `${scope}.assertsScore`,
    defaultMessage: `Asserts score`,
  },
});
