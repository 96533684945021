import { GroupedIncident, ProcessedIncidentResponse } from 'asserts-types';
import { stringToDate } from '../../../helpers/Date.helper';
import { useCallback } from 'react';
import { orderBy } from 'lodash';
import useDebounceValue from '../../../hooks/useDebounceValue';
import { useAppSelector } from 'app/store';
import { useQuery } from '@tanstack/react-query';
import { fetchIncidents } from 'services/Slo.service';

export const USE_INCIDENTS_QUERY_KEY = 'incidents-list';

export default function useIncidents() {
  const start = useAppSelector((state) => state.app.start);
  const end = useAppSelector((state) => state.app.end);
  const incidentSearch = useAppSelector(
    (state) => state.incidents.incidentSearch,
  );

  const debouncedSearch = useDebounceValue(incidentSearch, 500);

  const selectedEnv = useAppSelector((state) => state.app.selectedEnv);
  const selectedSite = useAppSelector((state) => state.app.selectedSite);

  const sortBy = useAppSelector((state) => state.incidents.sortBy);

  const filterByType = useAppSelector((state) => state.incidents.filterByType);

  const select = useCallback(
    (data: ProcessedIncidentResponse): ProcessedIncidentResponse => {
      let { incidentGroups, chartName } = data;
      if (sortBy === 'endTime') {
        incidentGroups = incidentGroups.sort(
          (a, b) => Math.max(...a.incidents.map((i) => i.endTime)) - Math.max(...b.incidents.map((i) => i.endTime))
        );
      }

      if (sortBy === 'type') {
        incidentGroups = orderBy(incidentGroups, 'type');
      }

      incidentGroups = incidentGroups.filter((r) => !filterByType.includes(r.type));

      return { incidentGroups: incidentGroups.reverse(), chartName };
    },
    [filterByType, sortBy]
  );

  const query = useQuery(
    [
      USE_INCIDENTS_QUERY_KEY,
      start,
      end,
      incidentSearch ? debouncedSearch : '',
      selectedEnv,
      selectedSite,
    ],
    () => fetchIncidents(stringToDate(start).valueOf(), stringToDate(end).valueOf(), incidentSearch),
    {
      cacheTime: Infinity,
      staleTime: typeof start === 'string' ? 0 : Infinity,
      select,
      keepPreviousData: true,
    }
  );

  return query;
}
