/**
 *
 * TopInsightTimelineItem
 *
 */
import { Moment } from 'moment';
import React, { memo, FunctionComponent, useMemo } from 'react';
import { assertsColors } from '../../../../app/constants';
import LineRangeChart from '../../../../components/LineRangeChart/LineRangeChart';
import TimelineTicks from '../../../../components/TimelineTicks/TimelineTicks.component';
import { TIMELINE_STEPS_COUNT } from '../../../Assertions/constants';
import { TOPINSIGHT_LINE_HEIGHT } from '../../constants';
import { AssertionScore, Scope } from 'asserts-types';
import TopInsightsTimelineClustersComponent from 'components/TopInsightsTimelineClusters/TopInsightsTimelineClusters.component';

interface IProps {
  start: Moment;
  end: Moment;
  itemId: string;
  metrics: AssertionScore['metrics'];
  maxY: number;
  timeStepIntervalMs: number;
  assertionClusters: AssertionScore['assertionClusters'];
  entityName: string;
  entityType: string;
  scope: Scope;
  scaleType: 'log' | 'linear';
  clustersVisible: boolean;
}

const TopInsightsTimelineItem: FunctionComponent<IProps> = ({
  start,
  end,
  itemId,
  metrics,
  maxY,
  timeStepIntervalMs,
  assertionClusters,
  entityName,
  entityType,
  scope,
  scaleType,
  clustersVisible,
}) => {
  const chartScoreData = useMemo(
    () =>
      metrics.map((item) => ({
        name: item.metric.asserts_severity,
        values: item.values,
        color: assertsColors[item.metric.asserts_severity],
      })),
    [metrics],
  );

  return (
    <div style={{ height: TOPINSIGHT_LINE_HEIGHT }} className="flex items-center divider-l relative bg-paper">
      <TimelineTicks count={TIMELINE_STEPS_COUNT} />

      <LineRangeChart
        data={chartScoreData}
        minX={start.valueOf()}
        maxX={end.valueOf()}
        hideX
        hideY
        disableGrid
        padding={[0, 0, 0, 0]}
        type="area"
        minY={0}
        maxY={maxY}
        nullAsZero={false}
        timeStepInterval={timeStepIntervalMs}
        fillOpacity={0.6}
        disableTooltip
        scaleType={scaleType}
      />
      {clustersVisible && (
        <TopInsightsTimelineClustersComponent
          clusters={assertionClusters}
          start={start.valueOf()}
          end={end.valueOf()}
          entityName={entityName}
          entityType={entityType}
          scope={scope}
        />
      )}
    </div>
  );
};

export default memo(TopInsightsTimelineItem);
