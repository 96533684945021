/*
 * EntityAdvancedSearch Messages
 *
 * This contains all the text for the EntityAdvancedSearch container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'features.EntitySearchProperty';

export default defineMessages({
  addRule: {
    id: `${scope}.addRule`,
    defaultMessage: 'Add rule',
  },
});
