import { FeatureToggles } from '@grafana/data';
import { config } from '@grafana/runtime';

export enum AssertsFeatures {
  RELABEL_RULES_UI = 'relabelRulesUi',
}

type AssertsFeatureToggles = FeatureToggles & {
  [key in AssertsFeatures]: boolean | undefined;
};

export function isFeatureToggleEnabled(featureName: AssertsFeatures): boolean {
  return Boolean((config.featureToggles as AssertsFeatureToggles)[featureName]);
}
