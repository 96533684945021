import { defineMessages } from 'react-intl';

export const scope = 'features.GraphContextMenu';

export default defineMessages({
  connected: {
    id: `${scope}.connected`,
    defaultMessage: 'Connected',
  },
  noConnected: {
    id: `${scope}.noConnected`,
    defaultMessage: 'No connected entities',
  },
});
