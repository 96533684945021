/*
 * CustomRulesList Messages
 *
 * This contains all the text for the CustomRulesList component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.CustomRulesList';

export default defineMessages({
  emptyList: {
    id: `${scope}.emptyList`,
    defaultMessage: 'No items here.',
  },
  asserts_severity: {
    id: `${scope}.asserts_severity`,
    defaultMessage: 'Severity',
  },
  asserts_alert_category: {
    id: `${scope}.asserts_alert_category`,
    defaultMessage: 'Category',
  },
  asserts_entity_type: {
    id: `${scope}.asserts_entity_type`,
    defaultMessage: 'Entity Type',
  },
  for: {
    id: `${scope}.for`,
    defaultMessage: 'For',
  },
  expr: {
    id: `${scope}.expr`,
    defaultMessage: 'Query',
  },
  alert: {
    id: `${scope}.alert`,
    defaultMessage: 'Name',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Custom Assertion Files',
  },
});
