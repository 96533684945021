/*
 * RelabelConfigForm Messages
 *
 * This contains all the text for the RelabelConfigForm component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.RelabelConfigForm';

export default defineMessages({
  source: {
    id: `${scope}.source`,
    defaultMessage: 'Source Labels',
  },
  separator: {
    id: `${scope}.separator`,
    defaultMessage: 'Separator',
  },
  regex: {
    id: `${scope}.regex`,
    defaultMessage: 'Regex',
  },
  targetLabel: {
    id: `${scope}.targetLabel`,
    defaultMessage: 'Target Label',
  },
  replacement: {
    id: `${scope}.replacement`,
    defaultMessage: 'Replacement',
  },
  action: {
    id: `${scope}.action`,
    defaultMessage: 'Action',
  },
  modulus: {
    id: `${scope}.modulus`,
    defaultMessage: 'Modulus',
  },
  addNew: {
    id: `${scope}.addNew`,
    defaultMessage: 'Add New',
  },
  saving: {
    id: `${scope}.saving`,
    defaultMessage: 'Adding...',
  },
});
