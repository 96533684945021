import { AssertionsQueryParams } from 'asserts-types';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import { fillSliceWithQueryParams } from '../Assertions.slice';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'app/store';
import { prefixRoute } from 'utils/utils.routing';
import { ROUTES } from 'global-constants';
import { fillAppSliceWithQueryParams } from 'features/App/App.slice';
import useAssertionsQueryParamsResolver from './useAssertionsQueryParamsResolver';
import { omit } from 'lodash';

export function useAssertionsQueryParams() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const workbenchEntities = useAppSelector((state) => state.assertions.workbenchEntities);

  const start = useAppSelector((state) => state.app.start);
  const end = useAppSelector((state) => state.app.end);
  const activeEntityDetails = useAppSelector((state) => state.app.activeEntityDetails);
  const env = useAppSelector((state) => state.app.selectedEnv);
  const site = useAppSelector((state) => state.app.selectedSite);
  const view = useAppSelector((state) => state.assertions.viewType);
  const search = useAppSelector((state) => state.assertions.search);

  const { resolveQueryParams } = useAssertionsQueryParamsResolver();

  // reads query string parameters and fill assertions store with values and fill common values in App.slice
  const readQueryParams = async () => {
    let queryParams = qs.parse(window.location.search.slice(1), {
      // decoder for reading minified query string params
      decoder: (str, defaultDecoder, charset, type) => {
        if (type === 'key') {
          const normalStr = decodeURIComponent(str)
            .replace('[sc]', '[scope]')
            .replace('we', 'workbenchEntities')
            .replace('[ns]', '[namespace]')
            .replace('[n]', '[name]')
            .replace('[tp]', '[type]');

          return normalStr;
        }
        return defaultDecoder(str);
      },
    }) as unknown as AssertionsQueryParams;

    // resolver for different case scenarios of reading url (for example link from alertmanager)
    queryParams = await resolveQueryParams(queryParams);

    // filling assertions store with values
    dispatch(fillSliceWithQueryParams(queryParams));

    // fill common options in app slice
    dispatch(fillAppSliceWithQueryParams(queryParams));

    return queryParams;
  };

  const setQueryStringUrl = () => {
    const queryParams = qs.stringify(
      {
        start,
        end,
        env,
        site,
        workbenchEntities: search ? undefined : workbenchEntities,
        search,
        view,
        // omitting values and thresholds since it's overwhelming for url
        activeEntityDetails: omit(activeEntityDetails, ['values', 'threshold']),
      },
      {
        // encoder for minifying query string params
        encoder: (str, defaultEncoder, charset, type) => {
          if (type === 'key') {
            const minifiedStr = str
              .replace('activeEntityDetails', 'ed')
              .replace('workbenchEntities', 'we')
              .replace('[scope]', '[sc]')
              .replace('[namespace]', '[ns]')
              .replace('[name]', '[n]')
              .replace('[type]', '[tp]');
            return minifiedStr;
          }
          return defaultEncoder(str);
        },
      }
    );

    const route = prefixRoute(ROUTES.RCA_WORKBENCH);

    const url = new URL(window.location.href);
    const replace = url.searchParams.size === 0;

    // push only in case url changed
    if (`${window.location.origin}${route}?${queryParams}` !== window.location.href) {
      navigate(`${route}?${queryParams}`, { state: { timestamp: Date.now() }, replace });
    }
    return queryParams;
  };

  return {
    readQueryParams,
    setQueryStringUrl,
  };
}
