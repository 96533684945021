import React, { useCallback, useEffect, useState } from 'react';
import { PluginPage } from '@grafana/runtime';

import EntitiesTopMenuComponent from './components/EntitiesTopMenu/EntitiesTopMenu.component';
import EnvSiteSelectorsComponent from 'components/EnvSiteSelectors/EnvSiteSelectors.component';
import { useAppSelector } from 'app/store';
import { useDispatch } from 'react-redux';
import { resetHistory, setActiveEntity, setShowAdvancedSearch } from './Entities.slice';
import useEntitiesHistoryChanges from './hooks/useEntitiesHistoryChanges';
import useEntities from './hooks/useEntities';
import useActiveEntityConnections from './hooks/useActiveEntityConnections';
import useMergeGraphData from './hooks/useMergeGraphData';
import useKpiSelector from './hooks/useKpiSelector';
import { useIntl } from 'react-intl';
import errorMessages from 'app/errorMessages';
import TopSearchComponent from 'components/TopSearch/TopSearch.component';
import EntitiesGraphComponent from './components/EntitiesGraph/EntitiesGraph.component';
import EntitiesNavigatorComponent from './components/EntitiesNavigator/EntitiesNavigator.component';
import { ActiveEntityDetailsPayload } from 'asserts-types';
import BubbleViewComponent from './components/BubbleView/BubbleView.component';
import { LoadingBar } from '@grafana/ui';
import EntityAdvancedSearch from './components/EntityAdvancedSearch/EntityAdvancedSearch';
import EntityDetailsContainer from 'features/EntityDetails/EntityDetails.container';
import { Graph } from '@antv/g6';
import GlobalDateRangePicker from 'features/App/components/GlobalDateRangePicker/GlobalDateRangePicker';
import TrackingHelper from 'helpers/Tracking.helper';
import { setActiveEntityDetails } from 'features/App/App.slice';

export default function EntitiesContainer() {
  const start = useAppSelector((state) => state.app.start);
  const end = useAppSelector((state) => state.app.end);
  const searchDefinition = useAppSelector((state) => state.entities.searchDefinition);
  const searchObject = useAppSelector((state) => state.entities.searchObject);
  const activeView = useAppSelector((state) => state.entities.activeView);
  const activeEntity = useAppSelector((state) => state.entities.activeEntity);
  const order = useAppSelector((state) => state.entities.order);
  const orderBy = useAppSelector((state) => state.entities.orderBy);
  const showAdvancedSearch = useAppSelector((state) => state.entities.showAdvancedSearch);
  const activeEntityDetails = useAppSelector((state) => state.app.activeEntityDetails);

  const [graphRef, setGraphRef] = useState<Graph>();

  const dispatch = useDispatch();
  const intl = useIntl();

  const handleEnvChange = () => {
    dispatch(setActiveEntity({ entity: undefined }));
    dispatch(resetHistory());
  };

  useEntitiesHistoryChanges();

  const { data, isFetching: isFetchingEntities, isPreviousData, dataUpdatedAt } = useEntities();
  const { data: connectedEntitiesData, isFetching: isFetchingConnections } = useActiveEntityConnections();

  const graphData = useMergeGraphData({
    graphData: data?.graphData,
    connections: connectedEntitiesData?.graphData,
  });

  const isFetching = isFetchingEntities || isFetchingConnections;
  const isNotEmpty = Boolean(searchDefinition || searchObject.filterCriteria.length) && !isPreviousData;

  const {
    entityListColumns,
    listKpis,
    kpiListColumns,
    filteredKpis,
    columnOptions,
    numericProperties,
    bubbleViewPanels,
  } = useKpiSelector({
    graphData,
    activeEntityConnections: connectedEntitiesData?.data.entities || [],
    entityList: data?.data.entities || [],
  });

  const handleSetActiveEntityDetails = useCallback(
    (value?: ActiveEntityDetailsPayload) => {
      dispatch(setActiveEntityDetails(value));
      dispatch(setShowAdvancedSearch(false));
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    if (isNotEmpty && !isFetchingEntities && data?.graphData.nodes.length === 0) {
      TrackingHelper.trackEntitiesReturnedCount(0);
    } else if (data?.graphData?.nodes?.length) {
      TrackingHelper.trackEntitiesReturnedCount(data?.graphData?.nodes?.length);
    }
    // eslint-disable-next-line
  }, [data?.graphData.nodes.length]);

  return (
    <PluginPage
      actions={
        <div className="flex items-start gap-4">
          <EnvSiteSelectorsComponent start={start} end={end} onChange={handleEnvChange} />
          <GlobalDateRangePicker showRefreshButton isFetching={isFetching} />
        </div>
      }
    >
      <div className="flex flex-col h-full">
        <EntitiesTopMenuComponent graphData={graphData} />
        <div className="flex grow relative h-0">
          {isFetching && (
            <div className="absolute top-0 inset-x-0">
              <LoadingBar width={300} />
            </div>
          )}
          {(activeView === 'graph' || activeView === 'list') && !!data?.graphData.nodes.length && isNotEmpty && (
            <EntitiesNavigatorComponent
              activeView={activeView}
              setActiveEntityDetails={handleSetActiveEntityDetails}
              graphRef={graphRef}
              isFetchingEntities={isFetching}
              activeEntityConnections={connectedEntitiesData?.data.entities || []}
              entityListColumns={entityListColumns}
              entityList={data?.data.entities || []}
              activeEntity={activeEntity}
              order={order}
              orderBy={orderBy}
              listKpis={listKpis}
              kpiListColumns={kpiListColumns}
              filteredKpis={filteredKpis}
              columnsOptions={columnOptions}
              lastUpdateTime={dataUpdatedAt}
            />
          )}
          {activeView === 'bubble' && !!data?.graphData.nodes.length && (
            <BubbleViewComponent
              numericProperties={numericProperties}
              bubbleViewPanels={bubbleViewPanels}
              listKpis={listKpis}
              filteredKpis={filteredKpis}
              graphData={graphData}
            />
          )}
          {activeView === 'graph' && !!data?.graphData.nodes.length && isNotEmpty && (
            <EntitiesGraphComponent
              graphData={graphData}
              isFetching={isFetchingEntities}
              lastUpdateTime={dataUpdatedAt}
              setGraphRef={setGraphRef}
              graphRef={graphRef}
            />
          )}
          {!isNotEmpty && !isFetchingEntities && (
            <div className="flex flex-col gap-8 h-full w-full items-center justify-center p-6">
              <TopSearchComponent />
            </div>
          )}
          {isNotEmpty && !isFetchingEntities && data?.graphData.nodes.length === 0 && (
            <div className="h-full w-full flex items-center justify-center">
              <div className="h-full flex flex-col">
                <div className="text-center py-8">
                  <p className="pt-4 pb-1 text-xl">{intl.formatMessage(errorMessages.noEntitiesFound)}</p>
                  <p className="text-secondary">{intl.formatMessage(errorMessages.noEntitiesFoundHint)}</p>
                </div>
                <TopSearchComponent />
              </div>
            </div>
          )}
          {showAdvancedSearch && <EntityAdvancedSearch />}
        </div>
      </div>
      {activeEntityDetails && <EntityDetailsContainer />}
    </PluginPage>
  );
}
