/**
 *
 * DisableRules
 *
 */

import React, { memo, FunctionComponent, useEffect } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import useVendorRules from './hooks/useVendorRules';
import PromRuleSwitchComponent from './components/PromRuleSwitch/PromRuleSwitch.component';
import { LoadingPlaceholder, PanelContainer, useTheme2 } from '@grafana/ui';

interface IProps {}

const DisableRules: FunctionComponent<IProps> = () => {
  const { formatMessage } = useIntl();
  const { data: rulesList, refetch, isFetching } = useVendorRules();

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const theme = useTheme2();

  return (
    <div className="h-full flex flex-col">
      <h1 style={{ fontSize: theme.typography.h1.fontSize }} className="m-0">
        Disable Rules
      </h1>
      <PanelContainer className="my-8 relative grow flex flex-col px-6 pb-6 gap-2">
        <p className="pt-6 text-xl">{formatMessage(messages.catalog)}</p>
        {isFetching ? (
          <div className="flex items-center justify-center p-8 grow">
            <LoadingPlaceholder text="Loading" />
          </div>
        ) : (
          <table className="w-full">
            <thead>
              <tr>
                <td className="headerCell divider-b p-4" width="20%">
                  {formatMessage(messages.name)}
                </td>
                <td className="headerCell divider-b p-4">{formatMessage(messages.enabledTitle)}</td>
              </tr>
            </thead>
            <tbody>
              {rulesList?.map((rule) => (
                <PromRuleSwitchComponent key={rule.name} ruleName={rule.name} enabled={rule.enabled} />
              ))}
            </tbody>
          </table>
        )}
      </PanelContainer>
    </div>
  );
};

export default memo(DisableRules);
