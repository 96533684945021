import { useDispatch } from 'react-redux';
import { setKpisByPropName as setKpisByPropNameAction } from '../Entities.slice';
import { stringToDate } from '../../../helpers/Date.helper';
import { useQueries } from '@tanstack/react-query';
import { fetchKpiSummary } from 'services/Entity.service';
import { KpiSummary, Scope } from 'asserts-types';

interface IProps {
  kpis: string[];
  rows: { name: string; type: string; scope: Scope | undefined }[];
  start: number | string;
  end: number | string;
}

export default function ({ kpis, rows, start, end }: IProps) {
  const dispatch = useDispatch();

  const results = useQueries({
    queries: kpis.map((kpi) => ({
      queryKey: ['kpi', kpi, rows, start, end],
      queryFn: () => fetchKpiSummary(rows, stringToDate(start).valueOf(), stringToDate(end).valueOf(), kpi),
      staleTime: typeof start === 'string' ? 0 : Infinity,
    })),
  });

  if (results.every((result) => result.data)) {
    results.forEach((result, index) => {
      if (result.data) {
        const propName = kpis[index];
        dispatch(
          setKpisByPropNameAction({
            propName,
            kpis: result.data,
          }),
        );
      }
    });
  }

  let mappedData: Record<string, Record<string, KpiSummary>> = {};

  if (results.every((result) => result.data)) {
    results.forEach((result, index) => {
      const propName = kpis[index];
      mappedData[propName] = result.data || {};
    });
  }

  return {
    data: mappedData,
    isLoading: results.some((result) => result.isLoading),
  };
}
