/**
 *
 * AssertionsViewSettings
 *
 */

import React, { memo, FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import {
  setShowWithAssertions,
  setHideOldAssertions,
  setOldAssertionHours,
  setWithRCA,
  setChartTooltip,
} from '../../Assertions.slice';
import { AssertionsViewType, ChartTooltipType } from 'asserts-types';
import GraphViewSettings from '../../../Entities/components/GraphViewSettings/GraphViewSettings.component';
import {
  setShowRelationships,
  setShowNodeNames,
  setShowFullNodeNameText,
  setHighlightSelected,
  setShowCallRates,
} from '../../../Entities/Entities.slice';
import { connect, ConnectedProps } from 'react-redux';

import ShowEnvSiteTagSettingComponent from 'components/ShowEnvSiteTagSetting/ShowEnvSiteTagSetting.component';
import { Switch, RadioButtonGroup, Input } from '@grafana/ui';
import FormControlLabel from 'components/FormControlLabel/FormControlLabel.component';

const connector = connect(
  (state: RootState) => ({
    viewType: state.assertions.viewType,
    showRelationships: state.entities.showRelationships,
    showNodeNames: state.entities.showNodeNames,
    showFullNodeNameText: state.entities.showFullNodeNameText,
    showCallRates: state.entities.showCallRates,
    highlightSelected: state.entities.highlightSelected,
    showWithAssertions: state.assertions.showWithAssertions,
    hideOldAssertions: state.assertions.hideOldAssertions,
    oldAssertionHours: state.assertions.oldAssertionHours,
    withRCA: state.assertions.withRCA,
    chartTooltip: state.assertions.chartTooltip,
  }),
  {
    setShowRelationships,
    setShowNodeNames,
    setShowFullNodeNameText,
    setShowCallRates,
    setHighlightSelected,
    setShowWithAssertions,
    setHideOldAssertions,
    setOldAssertionHours,
    setWithRCA,
    setChartTooltip,
  }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IProps {}

const AssertionsViewSettings: FunctionComponent<IProps & PropsFromRedux> = ({
  viewType,
  showRelationships,
  setShowRelationships,
  showNodeNames,
  setShowNodeNames,
  showFullNodeNameText,
  setShowFullNodeNameText,
  showCallRates,
  setShowCallRates,
  highlightSelected,
  setHighlightSelected,
  setShowWithAssertions,
  showWithAssertions,
  hideOldAssertions,
  setHideOldAssertions,
  oldAssertionHours,
  setOldAssertionHours,
  withRCA,
  setWithRCA,
  chartTooltip,
  setChartTooltip,
}) => {
  const intl = useIntl();

  const tooltipOptions = Object.values(ChartTooltipType).map((value) => ({
    label: intl.formatMessage(messages[value]),
    value,
  }));

  return (
    <>
      {viewType !== AssertionsViewType.GRAPH && (
        <div className="flex flex-col gap-1">
          <FormControlLabel
            control={
              <Switch
                onClick={() => setShowWithAssertions(!showWithAssertions)}
                color="primary"
                value={showWithAssertions}
              />
            }
            label={intl.formatMessage(messages.showWithAssertions)}
          />
          <div className="flex items-center gap-2">
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  value={hideOldAssertions}
                  onChange={() => setHideOldAssertions(!hideOldAssertions)}
                />
              }
              label={intl.formatMessage(messages.hideAssertionsOld)}
            />
            <Input
              type="number"
              value={oldAssertionHours}
              onChange={(e) => setOldAssertionHours(parseInt(e.currentTarget.value, 10))}
              className="w-[80px]"
              suffix="hrs"
            />
          </div>
          {viewType === AssertionsViewType.SUMMARY && (
            <FormControlLabel
              control={<Switch onClick={() => setWithRCA(!withRCA)} color="primary" checked={withRCA} />}
              label={intl.formatMessage(messages.showRCA)}
            />
          )}
          <div className={viewType === AssertionsViewType.SUMMARY ? 'mt-2' : ''}>
            <ShowEnvSiteTagSettingComponent />
          </div>
        </div>
      )}
      {[AssertionsViewType.BY_ENTITY, AssertionsViewType.SUMMARY].includes(viewType) && (
        <div className="pt-4">
          <p className="font-bold">{intl.formatMessage(messages.chartTooltip)}</p>
          <p className="text-sm text-secondary pb-3">{intl.formatMessage(messages.chartTooltipHint)}</p>
          <RadioButtonGroup
            options={tooltipOptions}
            value={chartTooltip}
            onChange={(v) => setChartTooltip(v)}
            fullWidth
          />
        </div>
      )}
      {viewType === AssertionsViewType.GRAPH && (
        <GraphViewSettings
          showRelationships={showRelationships}
          setShowRelationships={setShowRelationships}
          showNodeNames={showNodeNames}
          setShowNodeNames={setShowNodeNames}
          highlightSelected={highlightSelected}
          setHighlightSelected={setHighlightSelected}
          showCallRates={showCallRates}
          setShowCallRates={setShowCallRates}
          setShowFullNodeNameText={setShowFullNodeNameText}
          showFullNodeNameText={showFullNodeNameText}
        />
      )}
    </>
  );
};

export default connector(memo(AssertionsViewSettings));
