import React, { FunctionComponent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import useDidUpdateEffect from 'hooks/useDidUpdate';
import { connect, ConnectedProps } from 'react-redux';

import { useForm, Controller } from 'react-hook-form';
import { AssertionGroupRule } from 'asserts-types';
import HealthRulesItemComponent from '../../../NotificationHealth/components/HealthRulesItem/HealthRulesItem.component';
import ThresholdHealthList from '../ThresholdHealthList/ThresholdHealthList.component';
import healthFields from '../../../../config/threshold_health.json';
import { setItemToPopulate } from '../../../../ManageAssertions.slice';
import { fetchDotColorsForGroup } from 'services/ManageAssertions.service';
import { Button, Field, MultiSelect, PanelContainer, Select, useTheme2 } from '@grafana/ui';
import useGroupRuleData from 'hooks/useGroupRuleData';
import { SelectableValue } from '@grafana/data';
import { useMetricsDataSource } from 'hooks/useMetricsDatasource';

interface IProps {
  onAdd?: (item: AssertionGroupRule) => void;
  onClose?: () => void;
}

const connector = connect(
  (state: RootState) => ({
    start: state.app.start,
    end: state.app.end,
    itemToPopulate: state.manageAssertions.itemToPopulate,
  }),
  { setItemToPopulate }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IForm {
  groupField: string | null;
  rulesField: SelectableValue<string>[];
}

const ThresholdHealthForm: FunctionComponent<IProps & PropsFromRedux> = ({
  onAdd,
  onClose,
  start,
  end,
  itemToPopulate,
  setItemToPopulate,
}) => {
  const intl = useIntl();
  const { data: metricsDatasource } = useMetricsDataSource();

  const { setValue, watch, control, reset } = useForm<IForm>({
    defaultValues: {
      rulesField: [],
      groupField: null,
    },
    mode: 'onChange',
  });

  const theme = useTheme2();

  const { data: groupRuleData, isFetching } = useGroupRuleData({ allFailureRuleGroups: false });

  const [saving] = useState(false);
  const [ruleOptions, setRuleOptions] = useState<AssertionGroupRule[]>([]);

  const watchGroupField = watch('groupField');
  const watchRulesField = watch('rulesField');

  useEffect(() => {
    setValue('groupField', null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupRuleData]);

  useEffect(() => {
    if (itemToPopulate && groupRuleData?.length) {
      itemToPopulate.alertgroup && setValue('groupField', itemToPopulate.alertgroup);

      const rule = groupRuleData
        ?.find((g) => g.name === itemToPopulate.alertgroup)
        ?.rules.find((r) => r.alert === itemToPopulate.alertname);

      rule && setValue('rulesField', [rule]);

      setItemToPopulate(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemToPopulate, groupRuleData]);

  useDidUpdateEffect(() => {
    if (watchGroupField && metricsDatasource.uid) {
      const optionsToSet = groupRuleData?.find((group) => group.name === watchGroupField)?.rules || [];

      fetchDotColorsForGroup(
        metricsDatasource.uid,
        optionsToSet.map((o) => o.alert)
      ).then((res) => {
        setRuleOptions(
          optionsToSet.map((item) => ({
            ...item,
            dot: res.find((r) => r.alertname === item.alert)?.asserts_severity,
          }))
        );
      });
      setRuleOptions(optionsToSet);
    }
    if (!watchGroupField) {
      setRuleOptions([]);
    }
  }, [watchGroupField]);

  const handleDelete = (item: AssertionGroupRule) => {
    setValue(
      'rulesField',
      watchRulesField.filter((r: any) => r.alert !== item.alert)
    );
  };

  const handleAdd = (item: AssertionGroupRule) => {
    setValue('groupField', null);
    handleDelete(item);
    onAdd && onAdd({ ...item, editing: false });
  };

  const renderGroupControl = () => (
    <Controller
      name="groupField"
      control={control}
      render={({ field }) => (
        <Field label={intl.formatMessage(messages.group)} className="grow w-0 max-w-[200px]">
          <Select
            {...field}
            onChange={(v) => field.onChange(v?.value || '')}
            options={groupRuleData?.map((o) => ({ label: o.name, value: o.name })) || []}
            disabled={saving}
            isLoading={isFetching}
            isClearable
          />
        </Field>
      )}
    />
  );

  const renderRuleControl = () => (
    <Controller
      name="rulesField"
      control={control}
      render={({ field }) => (
        <Field label={intl.formatMessage(messages.group)} className="grow-[2] w-0 min-w-[500px]">
          <MultiSelect
            {...field}
            options={ruleOptions.map((rule) => ({ label: rule.alert, value: rule.alert })) || []}
            disabled={saving}
            isLoading={isFetching}
            closeMenuOnSelect={false}
            isClearable
            hideSelectedOptions={false}
            components={{
              Option: ({
                isSelected,
                data,
                innerProps,
                isFocused,
              }: {
                isSelected: boolean;
                data: SelectableValue<string>;
                isFocused: boolean;
                innerProps: { onClick: () => void };
              }) => {
                const option = ruleOptions.find((o) => o.alert === data.value);
                let background = undefined;
                if (isSelected) {
                  background = theme.colors.action.selected;
                }
                if (isFocused) {
                  background = theme.colors.action.focus;
                }
                return (
                  <div
                    {...innerProps}
                    onClick={undefined}
                    onMouseDown={() => innerProps.onClick()}
                    className="px-4 py-2.5"
                    style={{ background }}
                  >
                    {option && (
                      <HealthRulesItemComponent option={option} start={start} end={end} selected={isSelected} />
                    )}
                  </div>
                );
              },
            }}
          />
        </Field>
      )}
    />
  );

  return (
    <PanelContainer className="mt-8 p-6">
      <div className="flex items-center pb-5">
        <p className="text-xl">{intl.formatMessage(messages.selectRules)}</p>
        <Button fill="text" color="primary" className="normal-case ml-6" onClick={() => reset()}>
          {intl.formatMessage(messages.clearSelected)}
        </Button>
      </div>
      <form>
        <div className="flex items-start gap-4">
          {renderGroupControl()}
          {renderRuleControl()}
        </div>
      </form>
      {!!watchRulesField.length && (
        <ThresholdHealthList
          fields={healthFields}
          list={watchRulesField
            .filter((item) => ruleOptions.find((o) => o.alert === item.value))
            .map((item) => ruleOptions.find((o) => o.alert === item.value) as AssertionGroupRule)}
          onUpdate={handleAdd}
          fetchingList={false}
        />
      )}
    </PanelContainer>
  );
};

export default connector(ThresholdHealthForm);
